<template lang="pug">
  div.gen-container
    .content()
      .header מחוללים
      .row
        .product-container
          .product-name
            a(href="javascript:void(0)", @click="makeFile('/tachi1.docx', 'tachi')") תח"י דגם 1
          a.product-image(href="javascript:void(0)", @click="makeFile('/tachi1.docx', 'tachi')")
            img(src="@/assets/img/tachi1.png")
        .product-container
          .product-name
            a(href="javascript:void(0)", @click="makeFile('/tachi2.docx', 'tachi')") תח"י דגם 2
          a.product-image(href="javascript:void(0)", @click="makeFile('/tachi2.docx', 'tachi')")
            img(src="@/assets/img/tachi2.png")
      .row
        .product-container
          .product-name
            a(href="javascript:void(0)", @click="makeFile('/tala1.docx', 'tala')") תל"א דגם 1
          a.product-image(href="javascript:void(0)", @click="makeFile('/tala1.docx', 'tala')")
            img(src="@/assets/img/tala1.png")
        .product-container
          .product-name
            a(href="javascript:void(0)", @click="makeFile('/tala2.docx', 'tala')") תל"א דגם 2
          a.product-image(href="javascript:void(0)", @click="makeFile('/tala2.docx', 'tala')")
            img(src="@/assets/img/tala2.png")
</template>

<script>
import arrowMap from '@/config/questions-map-arrow-diractions.json5';
// import profileForm from '@/config/profile-form.json5';
import mapDocxStrings from '@/config/map-for-generator.json5';
import advices from '@/config/advices.json5';
import JSZip from "jszip";
import axios from "axios";
import $ from "jquery";
import store from "@/store";

export default {
  name: 'Generator',
  data: ()=>({
    arrowMap,
    // profileForm,
    answers: [],
    questions: null,
    mapStrings: [],
    studentTz: null,
    student: null,
    notPermitted: null,
    mapAdviseCategories: {
      'עבודת כיתה - תפקודים אקדמיים': 'ע0כ0אקדמי',
      'עבודת כיתה - תהליכי למידה': 'ע0כ0למידה',
      'עבודת כיתה - תפקוד אישיותי': 'ע0כ0אישיותי',
      'מבחנים': 'מבחנים',
      'שיעורי בית': 'שיעורי0בית',
      'התארגנות': 'התארגנות',
      'התנהגותי': 'התנהגותי',
      'העצמה': 'העצמה',
      'שיתוף הורים': 'שיתוף0הורים',
      'הפניות': 'הפניות',
    }
  }),
  mounted() {
    // this.answers = {"0":{"IsStudDiagnosed":"NO"},"1":{"IsRestless":"HARD","IsDayDream":"HARD","IsImpulsive":"HARD","IsSlowToAct":"HARD","IsAssociative":"HARD","IsHardToFinish":"HARD","IsVentilation":"HARD","IsNeuroDiagnosed":"YES_CARED","IsInfoFlood":"HARD"},"2":{"ByHeartParticipation":"ATA","Vocabulary":"HARD","WordRecall":"HARD"},"6":{"AudibleTextPace":"YES","IsTextReadAccurate":"YES","SilentTextPace":"YES"},"7":{"ListeningCompStoryTextLow":"YES","ListeningCompInformativeTextLow":"YES","ListeningCompInformativeTextHigh":"YES","ListeningCompStoryTextHigh":"YES","HearingCompInformativeTextLow":"YES","HearingCompInformativeTextHigh":"YES","HearingCompStoryTextLow":"YES","HearingCompStoryTextHigh":"YES"},"8":{"IsPageOrganised":"YES","IsHandWriteStyle":"YES","IsPencilHold":"YES","IsTireFactor":"YES","IsCommonSpelling":"YES","IsSevereSpelling":"YES","IsMessageOrganised":"YES","IsLinguisticHigh":"YES","IsWritePace":"YES","IsOpenQuestion":"YES","IsClosedQuestion":"YES"},"9":{"PeerContact":"HARD","Cooperation":"HARD","SocialEtiquette":"HARD","ClassAttendance":"HARD","AdultsRelations":"HARD","ClassParticipate":"HARD","ChangeCope":"HARD","Anxiety":"HARD","FailureCope":"HARD","SelfConfident":"HARD","FrustrationThreshold":"HARD","LearningMotivated":"HARD","ClassEquipment":"HARD","EnvironmentOrganize":"HARD","TaskOrganize":"HARD","AfterHoursLearn":"YES","ClassWorkIndependant":"HARD","Achievements":"HARD"},"10":{"IsAuditoryMultiple":"0","IsOpticMultiple":"0","IsOpticFocusMultiple":"0"}}
    this.answers = this.$store.getters['teacher/getAnswersAboutStudentObj']
    this.studentTz = this.$store.getters['teacher/getSearchedTz']
    this.student = this.$store.getters['teacher/getSelectedStudent']
    window.$ = $
    if(!this.checkPermission()){
      this.notPermitted = true
    }
  },
  methods: {
    async checkPermission(){
      console.log('1')
      if (store.getters['teacher/isSysAdmin']) {
        return true;
      }
      const student = this.$store.getters['teacher/getSelectedStudent'];
      if (!student) return false
      const schoolList = this.$store.getters['teacher/getSchoolList'];
      const semelMosad = student.schoolClass.semelMosad
      const school = schoolList.find(x=>x.semelMosad === semelMosad)
      console.log('1')

      return school.generator || this.$store.getters['getDevMode']
    },
    async getTemplate(template){
      const res = await axios.get(template, { responseType: 'blob' })
      return await JSZip.loadAsync(res.data)
    },
    replaceText(doc, term, replaceRows){
      let p = $(doc).find(`w\\:p:contains('${term}')`)
      let run = p.find('w\\:r').first()
      p.find('w\\:r').each((k,v)=>{
        if(!$(v).is(run)) $(v).remove()
      })
      // let run = $(doc).find(`w\\:r:contains('${term}')`)
      if (replaceRows.length === 0){
        run.find('w\\:t').text('-')
      } else {
        run.find('w\\:t').text(replaceRows[0])
        if(replaceRows.length > 1){
          let br = run.clone()
          br.find('w\\:t').remove('w\\:t')
          br.append('<w:br/>')
          for (let i = 1; i < replaceRows.length; i++) {
            let run2 = run.clone()
            run2.find('w\\:t').text(replaceRows[i])
            run.parent().append(br.clone())
            run.parent().append(run2)
          }
        }
      }
      return doc
    },
    async saveFile(file, fileName){
      const blob = await file.generateAsync({
        type: "blob",
        compression: "DEFLATE"
      })
      const a = document.createElement('a');
      const blobURL = window.URL.createObjectURL(blob)
      const tempLink = document.createElement('a');
      tempLink.style.display = 'none';
      tempLink.href = blobURL;
      tempLink.setAttribute('download', fileName);
      document.body.appendChild(tempLink);
      tempLink.click();
      document.body.removeChild(tempLink);
    },
    async makeFile(template, type){
      const file = await this.getTemplate(template)

      const xml = await file.file('word/document.xml').async("string")
      let doc = $.parseXML(xml)

      let replaceRows = this.getReplaceRows()
      for(let term in replaceRows){
        doc = this.replaceText(doc, term, replaceRows[term])
      }

      const serializer = new XMLSerializer();
      const newXML = serializer.serializeToString(doc)
      file.file('word/document.xml', newXML)

      await this.saveFile(file, `${this.studentTz}-${type}.docx`)
    },
    getMetaInfo(){
      const student = this.$store.getters['teacher/getSelectedStudent']
      return {
        '0שם0': ["שם התלמיד/ה: "+student.firstName+' '+student.lastName],
        '0ביס0': ["בית ספר: "+student.schoolClass.schoolName],
        '0תז0': ["מספר ת.ז: "+this.$store.getters['teacher/getSearchedTz']],
        '0כיתה0': ["כיתה: "+student.schoolClass.classGrade+' '+student.schoolClass.classNumber],
        '0מחנך0': ["מחנך: "+this.$store.getters['teacher/getTeacherName']],
        '0תאריך0': ["תאריך: "+new Date().toJSON().slice(0,10).split('-').reverse().join('/')],
      }
    },
    getQuestions(){
      let questions = {}
      for(let category in this.answers){
        for(let question in this.answers[category]){
          const answer = this.answers[category][question];
          if (this.arrowMap[question]
              && ['UP', 'DOWN'].includes(this.arrowMap[question][answer])){
            questions[question] = this.arrowMap[question][answer]
          }
        }
      }
      this.questions = questions
      let mapStrings = []
      let mapUpDown = {'UP': 'כ0', 'DOWN': 'ח0'}
      for (let str in mapDocxStrings){
        mapStrings[str+'ח0'] = []
        mapStrings[str+'כ0'] = []
        for(let question of mapDocxStrings[str]){
          if(Object.keys(this.questions).includes(question)){
            const text = this.$t('functionalMap.' + question)
            const key = str + (mapUpDown[this.questions[question]])
            mapStrings[key].push('• '+text);
          }
        }
      }
      return mapStrings
    },
    getAdvises(){
      const advicesCodes = this.student.recommendationsData.selected
      let replaceList = {}
      for(let category of Object.values(advices)){
        const found = Object.keys(category).filter(x=>advicesCodes.includes(parseInt(x)))
        replaceList[`0${this.mapAdviseCategories[category.t]}0`] = Object.entries(category)
          .map(x=>found.includes(x[0]) ? '• '+x[1] : '')
          .filter(x=>!!x)
      }
      return replaceList
    },
    getReplaceRows(){
      return {...this.getQuestions(), ...this.getAdvises(),...this.getMetaInfo()}
    },

  },
};
</script>

<style lang="scss" scoped>
.gen-container{
  direction: rtl;
  /*text-align: right;*/
}

.content{
  display: flex;
  justify-content: center;
  flex-direction: column;
  //height: calc(100vh - 56px);
  width: 100%;
  padding-top: 1em;
  .header{
    font-size: 2rem;
    font-weight: bold;
    margin-top: 1.43rem;
  }
  .row{
    display: flex;
    margin-top: 2.43rem;
    text-align: center;
    .product-container{
      width: 50%;
      display: flex;
      align-items: center;
      flex-direction: column;
      a:link, a:visited{
        text-decoration: none;
        color: #2c3e50;
      }
      .product-name{
        font-size: 1.28rem;
        padding: 0 20px 0 20px;
        text-align: center;
      }
      .product-image {
        width: 75%;
        img{
          aspect-ratio: 16/9;
          object-fit: contain;
          width: 100%;
        }
      }
    }
  }

  .text{
    text-align: center;
    font-size: 1.28rem;
    white-space: pre-line;
  }
  .image{
    width: 100%;
    margin-top: 3.57rem;
    display: flex;
    justify-content: center;
    align-items: center;
    img{
      //width: 700px;
      //height: 300px;
      //aspect-ratio: 16/9;
      //object-fit: contain;
      border: 4px solid;
      max-width: 700px;
      max-height: 400px;
    }
  }
}
</style>
