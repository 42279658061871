import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
// import './registerServiceWorker';
import i18n from './i18n';
import Vuetify from 'vuetify';
import VueGtag from "vue-gtag";
import VueFullPage from 'vue-fullpage.js'


import '@/assets/scss/style.scss';
import 'vuetify/dist/vuetify.min.css';

Vue.config.productionTip = false;
Vue.use(Vuetify);
Vue.use(VueFullPage);

Vue.use(VueGtag, {
  config: { id: "G-N7GZBD09S9" }
});

// router.beforeEach((to, from, next) => {
//   console.log(to.matched.some(record => record.meta.fullpageapi), to);
//   if (to.matched.some(record => record.meta.fullpageapi)) {
//     next((vm) => {
//       console.log('GOURD', vm)
//       // vm.$refs.fullpage.api.moveTo(to.meta.fullpageapi+1);
//     });
//   } else {
//     next()
//   }
// })
window.EventsBus = new Vue();

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app');