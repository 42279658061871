module.exports = {
	"new-student": {
		"side": "IMG"
	},
	"edit-student": {
		"side": "IMG"
	},
	"search-student": {
		"side": "IMG"
	},
	"edit-profile-form": {
		"side": "NAV",
		"NAV": {
			"student": true,
			"advisor": false,
			"links": false,
			"bullets": {
				"type": "profile",
				"checks": true
			}
		}
	},
	"view-profile-form": {
		"side": "NAV",
		"NAV": {
			"student": true,
			"advisor": false,
			"links": true,
			"bullets": {
				"type": "profile"
			}
		}
	},
	"edit-advice": {
		"side": "NAV",
		"NAV": {
			"student": true,
			"advisor": true,
			"links": true,
			"bullets": {
				"type": null
			}
		}
	},
	"view-advice": {
		"side": "NAV",
		"NAV": {
			"student": true,
			"advisor": false,
			"links": true,
			"bullets": {
				"type": "advice"
			}
		}
	},
	"generator": {
		"side": "NAV",
		"NAV": {
			"student": true,
			"advisor": false,
			"links": true,
			"bullets": {
				"type": null
			}
		}
	},
	"alt-advice": {
		"side": "NAV",
		"NAV": {
			"student": true,
			"advisor": false,
			"links": true,
			"bullets": {
				"type": null
			}
		}
	},
	"map": {
		"side": "NAV",
		"NAV": {
			"student": true,
			"advisor": false,
			"links": true,
			"bullets": {
				"type": null
			}
		}
	},
	"contact": {
		"side": "IMG"
	},
	"new-contact": {
		"side": "IMG"
	},
	"qna": {
		"side": "IMG"
	},
	"knowledge-base": {
		"side": "IMG"
	},
	"/": {
		"side": "IMG"
	},
	"mock-login": {
		"side": "IMG"
	},
	"tos": {
		"side": "IMG"
	},
	"register-teacher": {
		"side": "IMG"
	},
	"schools-management": {
		"side": "IMG"
	},
	"finish": {
		"side": "NAV",
		"NAV": {
			"student": true,
			"advisor": true,
			"links": true,
			"bullets": {
				"type": null
			}
		}
	}
}