<template lang="pug">
  .modular-side-bar
    .wrapper
      div(v-if="configMethod('advisor')")
        AdvisorCard(:config="config")
      .hr-wrapper(v-if="configMethod('advisor')")
        hr(noshade)
      div(v-if="configMethod('student')")
        StudentCard(:config="config")
      .hr-wrapper(v-if="configMethod('student')")
        hr(noshade)
      div(v-if="configMethod('links')")
        LinksCard
      .hr-wrapper(v-if="configMethod('links')")
        hr(noshade)
      .vertical-bullets-wrapper(v-if="config.NAV.bullets")
        VerticalBullets(:config="config")
</template>

<script>
import StudentCard from '../cards/StudentCard';
import AdvisorCard from '../cards/AdvisorCard';
import LinksCard from '../cards/LinksCard';
import VerticalBullets from './VerticalBullets';

export default {
  components: {
    StudentCard,
    AdvisorCard,
    LinksCard,
    VerticalBullets
  },
  props: {
    config: {
      type: Object,
      required: true
    }
  },
  methods: {
    configMethod(itemToShow) {
      // if (this.config.admin) return true;
      return this.config.NAV[itemToShow];
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/style.scss';

.wrapper {
  // padding-top: 3em;
  padding-top: 4vh;
}

.modular-side-bar {
  padding: 0 2em;
  @media (max-height: 700px) {
    height: calc(100vh - 112px);
    overflow: auto;
  }
}

hr {
  border-color: $color-lightblue;
}

.hr-wrapper {
  // padding: 2em 0;
  padding: 2vh 0;
}
</style>

<style lang="scss">
@import '@/assets/scss/style.scss';

.side-image {
  ::-webkit-scrollbar-track {
    background-color: transperent;
  }
  ::-webkit-scrollbar {
    display: block;
    width: 4px;
    background-color: transperent;
  }
  ::-webkit-scrollbar-thumb {
    display: block;
    border-radius: 10px;
    background-color: $color-peach;
  }
}
</style>
