module.exports = {
	"1": {
		"Cooperation": {
			"answers": [
				{
					"value": 1,
					"labelCode": "HARD"
				},
				{
					"value": 0,
					"labelCode": "ATA"
				},
				{
					"value": 0,
					"labelCode": "STG"
				}
			],
			"qNum": 78
		},
		"PeerContact": {
			"answers": [
				{
					"value": 1,
					"labelCode": "HARD"
				},
				{
					"value": 0,
					"labelCode": "ATA"
				},
				{
					"value": 0,
					"labelCode": "STG"
				}
			],
			"qNum": 79
		}
	},
	"2": {
		"ChangeCope": {
			"answers": [
				{
					"value": 1,
					"labelCode": "HARD"
				},
				{
					"value": 0,
					"labelCode": "ATA"
				},
				{
					"value": 0,
					"labelCode": "STG"
				},
				{
					"value": 0,
					"labelCode": "IDK"
				}
			],
			"qNum": 84
		},
		"Anxiety": {
			"answers": [
				{
					"value": 1,
					"labelCode": "HARD"
				},
				{
					"value": 0,
					"labelCode": "ATA"
				},
				{
					"value": 0,
					"labelCode": "STG"
				},
				{
					"value": 0,
					"labelCode": "IDK"
				}
			],
			"qNum": 85
		},
		"FailureCope": {
			"answers": [
				{
					"value": 1,
					"labelCode": "HARD"
				},
				{
					"value": 0,
					"labelCode": "ATA"
				},
				{
					"value": 0,
					"labelCode": "STG"
				},
				{
					"value": 0,
					"labelCode": "IDK"
				}
			],
			"qNum": 86
		},
		"FrustrationThreshold": {
			"answers": [
				{
					"value": 1,
					"labelCode": "HARD"
				},
				{
					"value": 0,
					"labelCode": "ATA"
				},
				{
					"value": 0,
					"labelCode": "STG"
				},
				{
					"value": 0,
					"labelCode": "IDK"
				}
			],
			"qNum": 87
		},
		"SelfConfident": {
			"answers": [
				{
					"value": 1,
					"labelCode": "HARD"
				},
				{
					"value": 0,
					"labelCode": "ATA"
				},
				{
					"value": 0,
					"labelCode": "STG"
				}
			],
			"qNum": 88
		},
		"LearningMotivated": {
			"answers": [
				{
					"value": 1,
					"labelCode": "HARD"
				},
				{
					"value": 0,
					"labelCode": "ATA"
				},
				{
					"value": 0,
					"labelCode": "STG"
				}
			],
			"qNum": 89
		}
	},
	"3": {
		"ClassEquipment": {
			"answers": [
				{
					"value": 1,
					"labelCode": "HARD"
				},
				{
					"value": 0,
					"labelCode": "ATA"
				},
				{
					"value": 0,
					"labelCode": "STG"
				},
				{
					"value": 0,
					"labelCode": "IDK"
				}
			],
			"qNum": 90
		},
		"EnvironmentOrganize": {
			"answers": [
				{
					"value": 1,
					"labelCode": "HARD"
				},
				{
					"value": 0,
					"labelCode": "ATA"
				},
				{
					"value": 0,
					"labelCode": "STG"
				}
			],
			"qNum": 91
		},
		"TaskOrganize": {
			"answers": [
				{
					"value": 1,
					"labelCode": "HARD"
				},
				{
					"value": 0,
					"labelCode": "ATA"
				},
				{
					"value": 0,
					"labelCode": "STG"
				},
				{
					"value": 0,
					"labelCode": "IDK"
				}
			],
			"qNum": 92
		},
		"AfterHoursLearn": {
			"answers": [
				{
					"value": 1,
					"labelCode": "YES"
				},
				{
					"value": 0,
					"labelCode": "NO"
				}
			],
			"qNum": 93
		},
		"ClassWorkIndependant": {
			"answers": [
				{
					"value": 1,
					"labelCode": "HARD"
				},
				{
					"value": 0,
					"labelCode": "ATA"
				},
				{
					"value": 0,
					"labelCode": "STG"
				},
				{
					"value": 0,
					"labelCode": "IDK"
				}
			],
			"qNum": 94
		},
		"Achievements": {
			"answers": [
				{
					"value": 1,
					"labelCode": "HARD"
				},
				{
					"value": 0,
					"labelCode": "ATA"
				},
				{
					"value": 0,
					"labelCode": "STG"
				},
				{
					"value": 1,
					"labelCode": "IDK"
				}
			],
			"qNum": 95
		}
	},
	"4": {
		"SocialEtiquette": {
			"answers": [
				{
					"value": 1,
					"labelCode": "HARD"
				},
				{
					"value": 0,
					"labelCode": "ATA"
				},
				{
					"value": 0,
					"labelCode": "STG"
				}
			],
			"qNum": 80
		},
		"AdultsRelations": {
			"answers": [
				{
					"value": 1,
					"labelCode": "HARD"
				},
				{
					"value": 0,
					"labelCode": "ATA"
				},
				{
					"value": 0,
					"labelCode": "STG"
				}
			],
			"qNum": 81
		},
		"ClassAttendance": {
			"answers": [
				{
					"value": 1,
					"labelCode": "HARD"
				},
				{
					"value": 0,
					"labelCode": "ATA"
				},
				{
					"value": 0,
					"labelCode": "STG"
				},
				{
					"value": 0,
					"labelCode": "IDK"
				}
			],
			"qNum": 82
		},
		"ClassParticipate": {
			"answers": [
				{
					"value": 1,
					"labelCode": "HARD"
				},
				{
					"value": 0,
					"labelCode": "ATA"
				},
				{
					"value": 0,
					"labelCode": "STG"
				},
				{
					"value": 0,
					"labelCode": "IDK"
				}
			],
			"qNum": 83
		}
	},
	"questionsWithSubHdr": [
		"HearingCompInformativeTextLow",
		"IsCommonSpelling",
		"IsMessageOrganised",
		"AutoQuickNaming",
		"IsGeneralIntl",
		"AudibleTextPace"
	]
}