import advicesJson from '@/config/advices.json5';

export default {
  methods: {
    advicesTopicList() {
      let student = this.$store.getters['teacher/getSelectedStudent'];
      if (student) {
        if (student.recommendationsData) {
          let topicList = Object.keys(advicesJson).filter(advicesTopic => {
            let codeAdvicesArrFromJson = Object.keys(advicesJson[advicesTopic]);
            let studentAdviceCodes =
              student.recommendationsData.recommendationCodes;
            return this.adviceCodesInterSection(
              codeAdvicesArrFromJson,
              studentAdviceCodes
            );
          });
          if (
            student.recommendationsData.adminComments &&
            student.recommendationsData.adminComments.length > 0
          ) {
            topicList.unshift('adminComments');
          }
          return topicList;
        }
      }
      // if there is not student for some reason or there is not recommendation data, dont render the bullets.
      return [];
    },
    adviceCodesInterSection(codeAdvicesArrFromJson, studentAdviceCodes) {
      return (
        _.intersectionWith(
          codeAdvicesArrFromJson,
          studentAdviceCodes,
          (adviceCode, studentAdviceCode) =>
            Number(adviceCode) === studentAdviceCode
        ).length > 0
      );
    }
  }
};
