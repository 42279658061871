<template lang="pug">
.sidebar
  slot
  .contact-us
    .button-container
      .button ליצירת קשר
    .bytel-container
      .whatsapp.text-center(@click="goTo('https://web.whatsapp.com/send?phone=972528454140&text=%D7%A9%D7%9C%D7%95%D7%9D.%20%D7%90%D7%A0%D7%99%20%D7%A8%D7%95%D7%A6%D7%94%20%D7%9C%D7%A9%D7%9E%D7%95%D7%A2%20%D7%95%D7%9C%D7%A7%D7%91%D7%9C%20%D7%A4%D7%A8%D7%98%D7%99%D7%9D%20%D7%A0%D7%95%D7%A1%D7%A4%D7%99%D7%9D%20%D7%A2%D7%9C-Edvise')")
        span.material-icons whatsapp
        br
        | שלחו לנו הודעה
        button.button 0528454140
      .tel.text-center
        span.material-icons local_phone
        br
        | התקשרו אלינו
        button.button 0528454140
</template>

<script>
export default {
  name: "contactus-sidebar",
  methods:{
    goTo(loc){
      window.location.href = loc
    }
  }
}
</script>

<style lang="scss" scoped>
.sidebar {
  width: 30%;
  min-width: 300px;
  height: 100%;
  background-color: #545871;
  //color: #f6e4cc;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  -webkit-box-align: center;
  padding-top: 20px;
  .contact-us {
    margin-top:45vh;
    display: flex;
    flex-direction: column;
    align-content: center;
    width: 100%;
    .button-container{
      margin: auto;
      .button{
        background-color: #48cfae;
        padding: 10px 20px;
        border-radius: 8px;
        font-size: 23px;

      }
    }
    .bytel-container{
      display: flex;
      justify-content: space-evenly;
      width: 100%;
      margin-top: 10px;
      :not(.button){
        color: #f6e4cc;
      }
      .whatsapp{
        cursor: pointer;
      }
      .button{
        background-color: #48cfae;
        padding: 5px 10px;
        border-radius: 3px;
        font-size: 15px;
        color: #545871;
        font-weight: bold;
      }
    }
  }
}
</style>