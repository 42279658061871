module.exports = [
	{
		"schoolName": "אמיר\" ע\"ש ג'אק אמיר",
		"semelMosad": 645820,
		"settlement": "דימונה",
		"manager": "ליאת אמויאל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "צליל\" למצוינות",
		"semelMosad": 414482,
		"settlement": "נתניה",
		"manager": "מיכל גלבר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "א-שורק לחינוך מיוחד",
		"semelMosad": 424556,
		"settlement": "עראבה",
		"manager": "הואזן מוסא",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "א. ארנון",
		"semelMosad": 510784,
		"settlement": "רמת גן",
		"manager": "אורנה בן זקן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "א. ד. גורדון",
		"semelMosad": 511212,
		"settlement": "תל אביב - יפו",
		"manager": "ענת זיידמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "א.ד.גורדון",
		"semelMosad": 510503,
		"settlement": "חולון",
		"manager": "עמיס רות יעקובי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "א.ד.גורדון",
		"semelMosad": 510255,
		"settlement": "בת ים",
		"manager": "ענת ממן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "א.ד.גורדון",
		"semelMosad": 510339,
		"settlement": "גבעתיים",
		"manager": "יעל אהרונוביץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "א.ד.רוטשילד",
		"semelMosad": 110619,
		"settlement": "ירושלים",
		"manager": "יוסף אוחנה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אבדאע",
		"semelMosad": 420497,
		"settlement": "יפיע",
		"manager": "ניווה עואד",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אבדאע לאומניות.",
		"semelMosad": 641464,
		"settlement": "ירושלים",
		"manager": "מלחם בדר",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אבו אלרומאן טמרה ב",
		"semelMosad": 218297,
		"settlement": "טמרה",
		"manager": "אילאנה זידאן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אבו בכר א'",
		"semelMosad": 618223,
		"settlement": "תל שבע",
		"manager": "מחמד אבו עסה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "אבו גוש",
		"semelMosad": 118018,
		"settlement": "אבו גוש",
		"manager": "חולוד ג'בר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אבו חאמד אלגזאלי",
		"semelMosad": 338483,
		"settlement": "ירושלים",
		"manager": "שירין אבראהים",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אבו כף",
		"semelMosad": 618017,
		"settlement": "אום בטין",
		"manager": "עלי אבו כף",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "אבו עוביידה",
		"semelMosad": 618363,
		"settlement": "רהט",
		"manager": "סלימאן כמלאת",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "אבו ערר",
		"semelMosad": 618280,
		"settlement": "ערערה-בנגב",
		"manager": "סמיר אבו עראר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "אבו קרינאת",
		"semelMosad": 618033,
		"settlement": "אבו קרינאת (יישוב)",
		"manager": "נביל אבו קרינאת",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "אבו-ואדי",
		"semelMosad": 618488,
		"settlement": "כסיפה",
		"manager": "גומעה אלסבאבחה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "אבו-סלים סלמאן אלשיך",
		"semelMosad": 248559,
		"settlement": "אבו סנאן",
		"manager": "רידאן זינאתי",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "דרוזי"
	},
	{
		"schoolName": "אביב",
		"semelMosad": 514513,
		"settlement": "תל אביב - יפו",
		"manager": "מירב חזות",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אביבים",
		"semelMosad": 413344,
		"settlement": "ראשון לציון",
		"manager": "טלי יונית חמדני",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אביגדור ורשה",
		"semelMosad": 515494,
		"settlement": "קרית אונו",
		"manager": "צופיה כהן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אביגור",
		"semelMosad": 513358,
		"settlement": "רמת גן",
		"manager": "אורלי לאלו",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אביחי",
		"semelMosad": 512582,
		"settlement": "נתניה",
		"manager": "יפה רכאח",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אביטל-מרום גולן",
		"semelMosad": 213132,
		"settlement": "מרום גולן",
		"manager": "הילה גיזל קודיש",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אביר יעקב",
		"semelMosad": 214544,
		"settlement": "נהריה",
		"manager": "כרמלה בן חמו",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אביר יעקב",
		"semelMosad": 612945,
		"settlement": "אופקים",
		"manager": "ליאורה איפרגן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אבירים",
		"semelMosad": 410795,
		"settlement": "יבנה",
		"manager": "שלום (שי) עובדיה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אבישור",
		"semelMosad": 612549,
		"settlement": "ערד",
		"manager": "מרים קידר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אבן האזל אלעד",
		"semelMosad": 729095,
		"settlement": "אלעד",
		"manager": "ישראל אברהם רבי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אבן ח'ולדון",
		"semelMosad": 418426,
		"settlement": "כפר קאסם",
		"manager": "עלאא צרצור",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אבן ח'לדון",
		"semelMosad": 442830,
		"settlement": "סח'נין",
		"manager": "כמאל אבו יונס",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אבן חלדון",
		"semelMosad": 418236,
		"settlement": "טייבה",
		"manager": "עותמאן מסארוה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אבן חלדון",
		"semelMosad": 318428,
		"settlement": "אום אל-פחם",
		"manager": "בלאל אגבאריה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אבן חלדון",
		"semelMosad": 618553,
		"settlement": "ערערה-בנגב",
		"manager": "באסם אבו עראר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "אבן חלדון",
		"semelMosad": 318592,
		"settlement": "באקה אל-גרביה",
		"manager": "מוחמד קעאדנה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אבן חן(מוסד בני ציון)",
		"semelMosad": 420513,
		"settlement": "ראש העין",
		"manager": "ליאור ביבס",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אבן סינא",
		"semelMosad": 318030,
		"settlement": "אום אל-פחם",
		"manager": "מוחמד אגבאריה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אבן סינא",
		"semelMosad": 418145,
		"settlement": "רמלה",
		"manager": "נוהא אלגמל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אבן סינא",
		"semelMosad": 618447,
		"settlement": "רהט",
		"manager": "סלימאן אלעביד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "אבן סינא",
		"semelMosad": 347112,
		"settlement": "באקה אל-גרביה",
		"manager": "נאדרה ותד",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אבן סינא -חטב ג'",
		"semelMosad": 249052,
		"settlement": "נצרת",
		"manager": "אימאן פאהום",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אבן סינא כפר מנדא",
		"semelMosad": 219204,
		"settlement": "כפר מנדא",
		"manager": "סוהיל עיסאוי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אבן סינא סכנין",
		"semelMosad": 219121,
		"settlement": "סח'נין",
		"manager": "כמאל ח'לאילה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אבן סינא עראבה",
		"semelMosad": 218834,
		"settlement": "עראבה",
		"manager": "ח'תאם עליאן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אבן סינא-מכר",
		"semelMosad": 218511,
		"settlement": "ג'דיידה-מכר",
		"manager": "ברלנטי אל חאג'",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אבן עאמר(אבו אלג'יש)",
		"semelMosad": 219295,
		"settlement": "נצרת",
		"manager": "רנדה עבד אלהאדי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אבן רושד",
		"semelMosad": 418137,
		"settlement": "קלנסווה",
		"manager": "אלהאם מרעי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אבן רושד",
		"semelMosad": 318105,
		"settlement": "ג'ת",
		"manager": "מיסון גרה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אבן רושד",
		"semelMosad": 418152,
		"settlement": "טייבה",
		"manager": "תאופיק עאזם",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אבן רשד",
		"semelMosad": 418392,
		"settlement": "כפר קאסם",
		"manager": "אימאן בדיר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אבן-ח'לדון",
		"semelMosad": 618231,
		"settlement": "רהט",
		"manager": "בוסרה אלהזייל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "אבני החושן",
		"semelMosad": 191015,
		"settlement": "מודיעין-מכבים-רעות",
		"manager": "מיכל פרומה חילקו",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אבני חן",
		"semelMosad": 359240,
		"settlement": "מודיעין-מכבים-רעות",
		"manager": "עידית ניב",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אבני מודיעין עילית",
		"semelMosad": 747717,
		"settlement": "מודיעין עילית",
		"manager": "רחל הרצל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אבראהים טוקאן",
		"semelMosad": 218636,
		"settlement": "נצרת",
		"manager": "דיאנא לואבנה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אג'אל",
		"semelMosad": 353441,
		"settlement": "נצרת",
		"manager": "סאוסן חאמד",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אג'יאל",
		"semelMosad": 548404,
		"settlement": "תל אביב - יפו",
		"manager": "ספייה סטל",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אג'יאל",
		"semelMosad": 418418,
		"settlement": "ג'לג'וליה",
		"manager": "האלה עודה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אג'יאל",
		"semelMosad": 412577,
		"settlement": "טייבה",
		"manager": "מוסטפא חאג יחיא",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אג'יאל",
		"semelMosad": 641050,
		"settlement": "ערערה-בנגב",
		"manager": "דיאב אבו צעלוק",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "אג'יאל - ערבי יסודי",
		"semelMosad": 518050,
		"settlement": "תל אביב - יפו",
		"manager": "ניהאיה יחיא",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אג'יאל אלקודס",
		"semelMosad": 460550,
		"settlement": "ירושלים",
		"manager": "גמאל ג'בארין",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אגודת אוהל זהר התורה",
		"semelMosad": 758177,
		"settlement": "אשדוד",
		"manager": "יעקב חריר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אגם",
		"semelMosad": 420257,
		"settlement": "רעננה",
		"manager": "אפרת ויינברג",
		"level": "יסודי חט\"ב ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אגמי\"ם",
		"semelMosad": 212035,
		"settlement": "צפת",
		"manager": "רונית מלכה מימון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אגמים",
		"semelMosad": 616904,
		"settlement": "אשקלון",
		"manager": "ליאת (זהבה) רביעי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אגמים מ\"מ",
		"semelMosad": 630152,
		"settlement": "אשקלון",
		"manager": "קרן אברהם",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אגרון גרשון",
		"semelMosad": 110106,
		"settlement": "ירושלים",
		"manager": "דינה יריב",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אדוות ים",
		"semelMosad": 384396,
		"settlement": "עתלית",
		"manager": "אורלי ריבנפלד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אדם ואדמה גולן",
		"semelMosad": 631309,
		"settlement": "אורטל",
		"manager": "רן קמינסקי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אדם ואדמה ניצנים",
		"semelMosad": 634261,
		"settlement": "כפר סילבר",
		"manager": "גל תורגמן פלום",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אדם ואדמה תבור",
		"semelMosad": 636019,
		"settlement": "כדורי",
		"manager": "שכניה נחשון זיוון",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אדם ואדמה- ערבה",
		"semelMosad": 741033,
		"settlement": "חצבה",
		"manager": "יוסף מונטג",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אדם וסביבה",
		"semelMosad": 410431,
		"settlement": "געש",
		"manager": "יובל מנחם כנען",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אדם ועולמו",
		"semelMosad": 160481,
		"settlement": "גבע בנימין",
		"manager": "דקלה מתתיהו",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אדם חברה וטבע",
		"semelMosad": 339408,
		"settlement": "כרמיאל",
		"manager": "סתו הרצוג",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אדרת אליהו",
		"semelMosad": 499707,
		"settlement": "ביתר עילית",
		"manager": "אלימלך איזנר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אדרת אליהו",
		"semelMosad": 142158,
		"settlement": "ירושלים",
		"manager": "ירמיהו זילברמן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אהבה",
		"semelMosad": 320150,
		"settlement": "כפר ביאליק",
		"manager": "עינת קפון",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אהבת יצחק",
		"semelMosad": 616532,
		"settlement": "כוכב השחר",
		"manager": "אברהם טובי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אהבת ישראל",
		"semelMosad": 246041,
		"settlement": "ירושלים",
		"manager": "שרה אבישי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אהבת ישראל",
		"semelMosad": 353599,
		"settlement": "בית שמש",
		"manager": "מרדכי פייג",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אהבת ישראל",
		"semelMosad": 734103,
		"settlement": "נחליאל",
		"manager": "איתיאל רבקה לאה וינשטוק",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אהבת ישראל (חרד\"ל)",
		"semelMosad": 112953,
		"settlement": "ירושלים",
		"manager": "בנימין נתאי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אהבת ישראל אור מלכה",
		"semelMosad": 615310,
		"settlement": "אשדוד",
		"manager": "רחל אסרף",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אהבת ישראל בנות",
		"semelMosad": 246033,
		"settlement": "בית שמש",
		"manager": "פזיה ברכה ראשי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אהבת ישראל בנים",
		"semelMosad": 194472,
		"settlement": "בית שמש",
		"manager": "יהודה מסינגר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אהבת משה",
		"semelMosad": 362632,
		"settlement": "ביתר עילית",
		"manager": "נסים כלפא",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אהבת ציון",
		"semelMosad": 510982,
		"settlement": "תל אביב - יפו",
		"manager": "טלי תמרי גבע",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אהבת ציון",
		"semelMosad": 415836,
		"settlement": "פתח תקווה",
		"manager": "מרים הכהן רבין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אהבת תורה",
		"semelMosad": 353615,
		"settlement": "גבע בנימין",
		"manager": "אוהד וינברג",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אהבת תורה",
		"semelMosad": 729004,
		"settlement": "ביתר עילית",
		"manager": "צבי לוי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אהבת תורה המסורה",
		"semelMosad": 160192,
		"settlement": "ירושלים",
		"manager": "ברוך לוי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אהוד",
		"semelMosad": 312108,
		"settlement": "חיפה",
		"manager": "סיגל מרים אזולאי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אהוד מנור",
		"semelMosad": 417071,
		"settlement": "גן יבנה",
		"manager": "איריס לבנה הראל צוקרמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אהוד מנור",
		"semelMosad": 514059,
		"settlement": "אור יהודה",
		"manager": "עידית איריס בלכר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אהל אברהם",
		"semelMosad": 758052,
		"settlement": "בני ברק",
		"manager": "אהוד כהן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אהל חנה",
		"semelMosad": 541789,
		"settlement": "קרית אונו",
		"manager": "עליזה ביטון",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אהל משה בנות",
		"semelMosad": 613711,
		"settlement": "נתיבות",
		"manager": "לאה אריה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אהל רחל",
		"semelMosad": 723684,
		"settlement": "בני ברק",
		"manager": "מרים איזנטל",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אהל שילה בנות",
		"semelMosad": 112128,
		"settlement": "שילה",
		"manager": "אפרת אסתר מליק",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אהל שילה בנים",
		"semelMosad": 143404,
		"settlement": "שילה",
		"manager": "רפאל ממן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אהל שמעון-כתב סופר",
		"semelMosad": 112656,
		"settlement": "ירושלים",
		"manager": "יואל אלחנן קלמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אהל שרה",
		"semelMosad": 312991,
		"settlement": "רכסים",
		"manager": "רות עדיקה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אהל שרה בית חנוך בנו",
		"semelMosad": 525113,
		"settlement": "בני ברק",
		"manager": "חיה יהודית שטיינמץ גולדברג",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אהל שרה מרכז שלמה",
		"semelMosad": 525089,
		"settlement": "בני ברק",
		"manager": "יונית אסנת וינר",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אהל תורה",
		"semelMosad": 747618,
		"settlement": "ירושלים",
		"manager": "אליה רפאל בוסקילה",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אהלי חיים",
		"semelMosad": 366864,
		"settlement": "ירושלים",
		"manager": "ישראל מאיר דרוק",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אהלי יעקב",
		"semelMosad": 646604,
		"settlement": "בית שמש",
		"manager": "קלמן לוי קזוקין",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אהלי שמואל",
		"semelMosad": 340810,
		"settlement": "רכסים",
		"manager": "אבנר עמיר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אהלי תורה",
		"semelMosad": 414649,
		"settlement": "כפר חב\"ד",
		"manager": "בן ציון וישדסקי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אהלי תפארת משה",
		"semelMosad": 124222,
		"settlement": "ביתר עילית",
		"manager": "חיים יוסף וינר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אהלים של תורה",
		"semelMosad": 747469,
		"settlement": "בית שמש",
		"manager": "מרדכי גראומן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אהרון הראה",
		"semelMosad": 310797,
		"settlement": "חיפה",
		"manager": "מזל מלי עובדיה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אוהל אשר",
		"semelMosad": 420273,
		"settlement": "אלעד",
		"manager": "מיכל רווח",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אוהל ברכה",
		"semelMosad": 162057,
		"settlement": "ירושלים",
		"manager": "ליזה סוויאד",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אוהל יהושע",
		"semelMosad": 340679,
		"settlement": "חיפה",
		"manager": "ישראל פרידמן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אוהל יוסף יצחק",
		"semelMosad": 611046,
		"settlement": "קרית גת",
		"manager": "חנה פרסמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אוהל מאיר מד-עפולה",
		"semelMosad": 211946,
		"settlement": "עפולה",
		"manager": "אבנר קובי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אוהל רחל",
		"semelMosad": 410472,
		"settlement": "עמנואל",
		"manager": "חנה חיה ממן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אוהל תורה",
		"semelMosad": 513986,
		"settlement": "בני ברק",
		"manager": "חיים נודלר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אוהל תורה",
		"semelMosad": 758185,
		"settlement": "ירושלים",
		"manager": "אברהם בוסקילה",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אוהל תמר",
		"semelMosad": 611194,
		"settlement": "נתיבות",
		"manager": "יהודית לוגר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אוהלי מנחם חב\"ד",
		"semelMosad": 611236,
		"settlement": "קרית גת",
		"manager": "יובל חבקין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אוטיסטים",
		"semelMosad": 610287,
		"settlement": "חורה",
		"manager": "דאהר עסיבי",
		"level": "יסודי חט\"ב ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "אולפ' בנ\"ע מ. ברוך",
		"semelMosad": 344200,
		"settlement": "חדרה",
		"manager": "דן פנחס דינר",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אולפנא אמי\"ת",
		"semelMosad": 353581,
		"settlement": "צפת",
		"manager": "יפה שמש",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אולפנא בהר\"ן",
		"semelMosad": 440834,
		"settlement": "גדרה",
		"manager": "יצחק משה אהרן נוימן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אולפנא בני עקיבא נריה",
		"semelMosad": 186908,
		"settlement": "טלמון",
		"manager": "יניב כהן",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אולפנא בני עקיבא צפירה",
		"semelMosad": 440479,
		"settlement": "צפריה",
		"manager": "אמיר אדרעי",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אולפנא הר חברון",
		"semelMosad": 581231,
		"settlement": "שמעה",
		"manager": "מלכה ויסמן",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אולפנא השומרון",
		"semelMosad": 440883,
		"settlement": "אלקנה",
		"manager": "דבורה בר און",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אולפנא טל תחיה",
		"semelMosad": 656165,
		"settlement": "פסגות",
		"manager": "ענבר אסתר פלדמן",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אולפנא ירושלים",
		"semelMosad": 140525,
		"settlement": "ירושלים",
		"manager": "יוכבד לאגוסקער",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אולפנא לבנות",
		"semelMosad": 140665,
		"settlement": "קרית ארבע",
		"manager": "שילה דגנית הלוי",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אולפנא ע\"ש הרב שאולי",
		"semelMosad": 640581,
		"settlement": "אשדוד",
		"manager": "שלומית אשוול",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אולפנא עופרה",
		"semelMosad": 140970,
		"settlement": "עופרה",
		"manager": "אביה זאב ששר",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אולפנא צביה הר ברכה",
		"semelMosad": 482166,
		"settlement": "ברכה",
		"manager": "שיר טובה לב",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אולפנא ראשון לציון",
		"semelMosad": 442392,
		"settlement": "ראשון לציון",
		"manager": "רותי פרץ",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אולפנא ת. לאומנויות",
		"semelMosad": 141390,
		"settlement": "ירושלים",
		"manager": "הדס גודמן",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אולפנא תורנית חורב",
		"semelMosad": 140012,
		"settlement": "ירושלים",
		"manager": "עודד עוזיאל מיזליש",
		"level": "יסודי ועליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אולפנאת זבולון",
		"semelMosad": 440990,
		"settlement": "ראש העין",
		"manager": "רחל רדא",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אולפנה יחודית לבנות",
		"semelMosad": 841247,
		"settlement": "נוב",
		"manager": "לאה ירחי נעמן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אולפנה לבנות",
		"semelMosad": 444620,
		"settlement": "גבעת שמואל",
		"manager": "אורית קאופמן",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אולפנה מדעים ואמנויות",
		"semelMosad": 640474,
		"settlement": "אשדוד",
		"manager": "תמר בן זקן",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אולפנה תורנית",
		"semelMosad": 650515,
		"settlement": "ירושלים",
		"manager": "",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אולפנית אמונה אלישבע",
		"semelMosad": 370304,
		"settlement": "פרדס חנה-כרכור",
		"manager": "יעל דוקוב",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אולפנית אמי\"ת או\"ע",
		"semelMosad": 342170,
		"settlement": "אור עקיבא",
		"manager": "רני אופנהימר",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אולפנית בנ\"ע תל אביב",
		"semelMosad": 540898,
		"settlement": "תל אביב - יפו",
		"manager": "חנה גולדמן",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אולפנית הדר גנים",
		"semelMosad": 540542,
		"settlement": "פתח תקווה",
		"manager": "אמיר סנדלר",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אולפנית ישורון",
		"semelMosad": 440081,
		"settlement": "פתח תקווה",
		"manager": "מבשרת ניצה יעקבס",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אולפנית לבנות",
		"semelMosad": 240093,
		"settlement": "טבריה",
		"manager": "שמעיה פנחס כרמל",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אולפנית קרית שמונה",
		"semelMosad": 442897,
		"settlement": "קרית שמונה",
		"manager": "אורטל יהודה",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אולפנית שחם",
		"semelMosad": 340125,
		"settlement": "קרית אתא",
		"manager": "ליאת מרק",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אולפנת - לבנות ולהבנות",
		"semelMosad": 410076,
		"settlement": "יבנה",
		"manager": "ענת כהן",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אולפנת אבן שמואל",
		"semelMosad": 640532,
		"settlement": "אבן שמואל",
		"manager": "שלמה יוסף וייס",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אולפנת אהבת ישראל",
		"semelMosad": 460667,
		"settlement": "בית שמש",
		"manager": "ורדית כהנא",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אולפנת אוריה",
		"semelMosad": 560508,
		"settlement": "אלון שבות",
		"manager": "יונת למברגר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אולפנת אורית אמי\"ת",
		"semelMosad": 640045,
		"settlement": "באר שבע",
		"manager": "נורית דוידי",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אולפנת אמי\"ת להבה",
		"semelMosad": 440909,
		"settlement": "קדומים",
		"manager": "אפרים זיק",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אולפנת בית אל",
		"semelMosad": 650523,
		"settlement": "בית אל",
		"manager": "ליאורה מרסל אדרי",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אולפנת בית שאן",
		"semelMosad": 640466,
		"settlement": "בית שאן",
		"manager": "מירב אורית ירקוני",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אולפנת בנ\"ע אורות מודיעין",
		"semelMosad": 144717,
		"settlement": "מודיעין-מכבים-רעות",
		"manager": "סגלית נחום",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אולפנת בנ\"ע חן במדבר",
		"semelMosad": 640359,
		"settlement": "באר שבע",
		"manager": "יונתן סמואל",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אולפנת בנ\"ע טל רמות",
		"semelMosad": 770727,
		"settlement": "ירושלים",
		"manager": "תהילה ווילף",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אולפנת בנ\"ע כפר פינס",
		"semelMosad": 340109,
		"settlement": "כפר פינס",
		"manager": "אורטל אילוז",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אולפנת בנ\"ע סגולה",
		"semelMosad": 340380,
		"settlement": "קרית מוצקין",
		"manager": "רוחמה בת שבע חזות",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אולפנת בנ\"ע ערד",
		"semelMosad": 640508,
		"settlement": "ערד",
		"manager": "סיגלית קודיש",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אולפנת בנ\"ע פסגת זאב",
		"semelMosad": 160259,
		"settlement": "ירושלים",
		"manager": "ענבל ישראלי",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אולפנת בנ\"ע ראש צורים",
		"semelMosad": 160580,
		"settlement": "ראש צורים",
		"manager": "יהודה פלס",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אולפנת בנ\"ע רעות",
		"semelMosad": 470088,
		"settlement": "פתח תקווה",
		"manager": "מתן אדלר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אולפנת בנ\"ע שילה",
		"semelMosad": 641480,
		"settlement": "שילה",
		"manager": "נעמה לוטוק",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אולפנת בנ\"ע שירת הים",
		"semelMosad": 521476,
		"settlement": "נוה צוף",
		"manager": "אביגיל גולדשטין",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אולפנת בני עקיבא אמנה",
		"semelMosad": 440388,
		"settlement": "כפר סבא",
		"manager": "יוסף כהנא",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אולפנת בני עקיבא לכי",
		"semelMosad": 641548,
		"settlement": "בני דקלים",
		"manager": "אורית אזוגי",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אולפנת בקעת הירדן",
		"semelMosad": 713743,
		"settlement": "חמרה",
		"manager": "שגית מרגלית גינצבורג",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אולפנת דולב",
		"semelMosad": 160374,
		"settlement": "דולב",
		"manager": "שרית סמחי",
		"level": "חט\"ב + עליונה",
		"special": "מיוחד",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אולפנת דרכי נעם",
		"semelMosad": 444703,
		"settlement": "פתח תקווה",
		"manager": "גילה שרים",
		"level": "יסודי ועליונה",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אולפנת הללי לאמנות",
		"semelMosad": 508424,
		"settlement": "בית רבן",
		"manager": "תירצה בן יצחק",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אולפנת הרא\"ה תורני מדעי",
		"semelMosad": 540252,
		"settlement": "רמת גן",
		"manager": "ישראל איסר שפירא",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אולפנת השומרון",
		"semelMosad": 482653,
		"settlement": "אלקנה",
		"manager": "שקמה כהן ששון",
		"level": "חט\"ב + עליונה",
		"special": "מיוחד",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אולפנת חלוצה",
		"semelMosad": 640391,
		"settlement": "נווה",
		"manager": "איל תם",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אולפנת חפץ חיים",
		"semelMosad": 460154,
		"settlement": "חפץ חיים",
		"manager": "נתלי חיה יוסף",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אולפנת חריש",
		"semelMosad": 344259,
		"settlement": "חריש",
		"manager": "עידית הנדל",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אולפנת טהר",
		"semelMosad": 473017,
		"settlement": "יד בנימין",
		"manager": "נעם כרמיאל",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אולפנת לבונה",
		"semelMosad": 142166,
		"settlement": "מעלה לבונה",
		"manager": "נורית בן זמרה",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אולפנת למרחב",
		"semelMosad": 440503,
		"settlement": "פתח תקווה",
		"manager": "ורד שורצבוים",
		"level": "יסודי ועליונה",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אולפנת מבשרת",
		"semelMosad": 756403,
		"settlement": "קרית ארבע",
		"manager": "עידית רגינה אליגל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אולפנת מרכז שפירא",
		"semelMosad": 680082,
		"settlement": "מרכז שפירא",
		"manager": "בן ציון שטראוסמן",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אולפנת נוגה",
		"semelMosad": 238121,
		"settlement": "בית שמש",
		"manager": "מרים לאה קורן",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אולפנת נוה דקלים",
		"semelMosad": 640649,
		"settlement": "ניצן",
		"manager": "שימרית קליין",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אולפנת נווה שרה הרצוג",
		"semelMosad": 770222,
		"settlement": "בני ברק",
		"manager": "רחל כרמון",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אולפנת נחלה דרכא",
		"semelMosad": 189001,
		"settlement": "אלעד",
		"manager": "סיגלית שינדלר",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אולפנת עטרת רחל",
		"semelMosad": 757666,
		"settlement": "ירושלים",
		"manager": "שיבת שילה",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אולפנת צביה",
		"semelMosad": 244228,
		"settlement": "עפולה",
		"manager": "רויטל ווינטר",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אולפנת צביה אשקלון",
		"semelMosad": 644443,
		"settlement": "אשקלון",
		"manager": "ינון בן דוד",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אולפנת צביה בני ברק",
		"semelMosad": 540385,
		"settlement": "בני ברק",
		"manager": "לירן צבר",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אולפנת צביה בת ים",
		"semelMosad": 544668,
		"settlement": "בת ים",
		"manager": "מירב קרימר",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אולפנת צביה דימונה",
		"semelMosad": 644666,
		"settlement": "דימונה",
		"manager": "אילן שמעון דניאלי",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אולפנת צביה הרצליה",
		"semelMosad": 544312,
		"settlement": "הרצליה",
		"manager": "אפרת שינמן",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אולפנת צביה חיפה",
		"semelMosad": 340737,
		"settlement": "חיפה",
		"manager": "רחל שינמן",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אולפנת צביה יצירתית",
		"semelMosad": 168419,
		"settlement": "ירושלים",
		"manager": "צפורה כהן",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אולפנת צביה ירושלים",
		"semelMosad": 140491,
		"settlement": "ירושלים",
		"manager": "צפורה מלכה אברמזון",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אולפנת צביה כוכב יעקב",
		"semelMosad": 234021,
		"settlement": "כוכב יעקב",
		"manager": "תהילה ביטי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אולפנת צביה לוד",
		"semelMosad": 441584,
		"settlement": "לוד",
		"manager": "גלית זאגא",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אולפנת צביה מ. אדומים",
		"semelMosad": 141176,
		"settlement": "מעלה אדומים",
		"manager": "שלמה נריה ירושלים",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אולפנת צביה מעלות",
		"semelMosad": 244111,
		"settlement": "מעלות-תרשיחא",
		"manager": "רחל ויצמן",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אולפנת צביה עפולה",
		"semelMosad": 214007,
		"settlement": "עפולה",
		"manager": "שמחה בתיה מסעודי שטמלר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אולפנת צביה רבבה",
		"semelMosad": 441980,
		"settlement": "רבבה",
		"manager": "שמואל ויס",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אולפנת צביה רחובות",
		"semelMosad": 441014,
		"settlement": "רחובות",
		"manager": "זאב כהן",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אולפנת צביה-עזתה",
		"semelMosad": 841213,
		"settlement": "בית הגדי",
		"manager": "טוניה מרים מיטלמן",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אולפנת קצרין",
		"semelMosad": 715920,
		"settlement": "קצרין",
		"manager": "ורדית טפרברג",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אולפנת קרית גת",
		"semelMosad": 640383,
		"settlement": "קרית גת",
		"manager": "סגלית יעקב",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אולפנת רעות",
		"semelMosad": 416362,
		"settlement": "פתח תקווה",
		"manager": "הדר שרה ברודמן",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אולפנת רעיה",
		"semelMosad": 144337,
		"settlement": "בית אל",
		"manager": "פייר חיים סולטן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אולפנת שעלבים",
		"semelMosad": 460428,
		"settlement": "שעלבים",
		"manager": "מיכל ורד",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אולפנת שעלי תורה",
		"semelMosad": 193185,
		"settlement": "בית שמש",
		"manager": "אלעד בן אליהו",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אולפנת תהילה",
		"semelMosad": 338095,
		"settlement": "ירושלים",
		"manager": "צפורת לין",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אולפנת תהילת ישראל",
		"semelMosad": 670208,
		"settlement": "אילת",
		"manager": "תמר יצחקי חיים",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אולפנת תפארת",
		"semelMosad": 742163,
		"settlement": "מגדל העמק",
		"manager": "סמדר קץ",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אולפת אמי\"ת עירוני ו",
		"semelMosad": 340240,
		"settlement": "חיפה",
		"manager": "מוריה שפירא",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אום טובא",
		"semelMosad": 118042,
		"settlement": "ירושלים",
		"manager": "וליד אבו טיר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אום טובה לבנות",
		"semelMosad": 511550,
		"settlement": "ירושלים",
		"manager": "מחמד אבו טיר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אום כלתום",
		"semelMosad": 417527,
		"settlement": "סח'נין",
		"manager": "ח'אלד אבו ריא",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אום ליסון",
		"semelMosad": 630178,
		"settlement": "ירושלים",
		"manager": "גיהאן חלאק",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אומנויות",
		"semelMosad": 644831,
		"settlement": "אשקלון",
		"manager": "שושי בויטוים",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אומנויות",
		"semelMosad": 613448,
		"settlement": "אשקלון",
		"manager": "מירב בן ישי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "און",
		"semelMosad": 520387,
		"settlement": "תל אביב - יפו",
		"manager": "שנהב ברמן ארנון",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אוסישקין",
		"semelMosad": 511006,
		"settlement": "תל אביב - יפו",
		"manager": "טלי ינאי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אוסישקין",
		"semelMosad": 410852,
		"settlement": "כפר סבא",
		"manager": "טלי שוורצשטיין בסר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אוסישקין-נהריה",
		"semelMosad": 211466,
		"settlement": "נהריה",
		"manager": "אילנית בנית פיטרושקה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אוסקפויה שפרעם",
		"semelMosad": 217232,
		"settlement": "שפרעם",
		"manager": "ניבין עבוד מבאריכי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אופיר",
		"semelMosad": 612374,
		"settlement": "אילת",
		"manager": "ארן דולב",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אופירה נבון",
		"semelMosad": 414854,
		"settlement": "כפר סבא",
		"manager": "ענבל סעד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אופק",
		"semelMosad": 113811,
		"settlement": "גבעת זאב",
		"manager": "שרית דיין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אופק",
		"semelMosad": 614370,
		"settlement": "אשדוד",
		"manager": "סימה מכלוף",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אופק",
		"semelMosad": 116012,
		"settlement": "מודיעין-מכבים-רעות",
		"manager": "הדס רפאלי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אופק",
		"semelMosad": 437319,
		"settlement": "אלישיב",
		"manager": "מיטל גרוס",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אופק",
		"semelMosad": 318410,
		"settlement": "דאלית אל-כרמל",
		"manager": "ניגם קרא",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "דרוזי"
	},
	{
		"schoolName": "אופק",
		"semelMosad": 414847,
		"settlement": "רמלה",
		"manager": "דורית נבו",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אופק",
		"semelMosad": 520056,
		"settlement": "הרצליה",
		"manager": "אתי גואטה",
		"level": "חט\"ב + עליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אופק",
		"semelMosad": 260125,
		"settlement": "עברון",
		"manager": "לילך רחמים",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אופק כרמיאל",
		"semelMosad": 214718,
		"settlement": "כרמיאל",
		"manager": "שלומית עקריש",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אופקים",
		"semelMosad": 413633,
		"settlement": "ראשון לציון",
		"manager": "לירון מאור",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אופקים",
		"semelMosad": 210419,
		"settlement": "מרחביה (קיבוץ)",
		"manager": "אבנר גרוסר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אופקים",
		"semelMosad": 320390,
		"settlement": "חיפה",
		"manager": "סוזנה רייף רוזנברג",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אוצר החיים",
		"semelMosad": 514216,
		"settlement": "בני ברק",
		"manager": "זכריה שלום",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אוצר החיים",
		"semelMosad": 615260,
		"settlement": "באר שבע",
		"manager": "ליזה ביטון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אוצר החיים בנות",
		"semelMosad": 514349,
		"settlement": "בני ברק",
		"manager": "מרים יאיר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אוצר החיים מעורב",
		"semelMosad": 613695,
		"settlement": "באר שבע",
		"manager": "שרלי רות ספקטור",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אוצר התורה",
		"semelMosad": 632356,
		"settlement": "קרית אתא",
		"manager": "שלום מיראי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אוצר התורה - שיח סוד",
		"semelMosad": 743807,
		"settlement": "ירושלים",
		"manager": "אברהם יעקב קאופמן",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אוצר התורה בית שמש",
		"semelMosad": 632208,
		"settlement": "בית שמש",
		"manager": "חוה קסטליניץ",
		"level": "עליונה בלבד",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אוצר ירושלים שיח סוד",
		"semelMosad": 120485,
		"settlement": "ירושלים",
		"manager": "שמעון יוסף לוי",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אוצרות הגדולים",
		"semelMosad": 747444,
		"settlement": "אשדוד",
		"manager": "אליהו קרימולובסקי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אור אבנר למצוינות",
		"semelMosad": 384172,
		"settlement": "קרית ים",
		"manager": "אלכסנדרה ראקליאר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אור אברהם",
		"semelMosad": 541771,
		"settlement": "בני ברק",
		"manager": "לוי פנחסי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אור אברהם טשכנוב",
		"semelMosad": 221945,
		"settlement": "ירושלים",
		"manager": "שמעון פרוכטהנדלר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אור אלחנן",
		"semelMosad": 190108,
		"settlement": "מודיעין עילית",
		"manager": "ברוך יעקב סומפולינסקי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אור אליעזר",
		"semelMosad": 165738,
		"settlement": "בית שמש",
		"manager": "חנוך הבלין",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אור אפרים אלעד",
		"semelMosad": 758110,
		"settlement": "אלעד",
		"manager": "איתמר פרץ גוטל",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אור ברוך - כרם שערי",
		"semelMosad": 631317,
		"settlement": "ירושלים",
		"manager": "שלמה איצקוביץ",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אור ברמה - בנים",
		"semelMosad": 461996,
		"settlement": "ירושלים",
		"manager": "משה יהודה רבינוביץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אור דוד",
		"semelMosad": 742171,
		"settlement": "יבנה",
		"manager": "בן ציון מזוז",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אור האמת",
		"semelMosad": 442129,
		"settlement": "אלעד",
		"manager": "אבישג חפץ",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אור האמת - בנות",
		"semelMosad": 481952,
		"settlement": "אלעד",
		"manager": "ורד גספן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אור הגנוז בנים",
		"semelMosad": 511964,
		"settlement": "אור הגנוז",
		"manager": "אליהו אשר גרליץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אור החינוך",
		"semelMosad": 748301,
		"settlement": "אלעד",
		"manager": "חנה קסוס",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אור השחר",
		"semelMosad": 513564,
		"settlement": "רמת השרון",
		"manager": "עוזי חסון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אור התורה",
		"semelMosad": 723874,
		"settlement": "בית שמש",
		"manager": "רחל-רייזל שבדרון",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אור וערכים",
		"semelMosad": 163022,
		"settlement": "ביתר עילית",
		"manager": "אברהם אורן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אור וערכים - בנות",
		"semelMosad": 442616,
		"settlement": "ביתר עילית",
		"manager": "מלכה בן יעקב",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אור זורח",
		"semelMosad": 513887,
		"settlement": "בני ברק",
		"manager": "עזריה כהן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אור חדש - בנות",
		"semelMosad": 340711,
		"settlement": "רכסים",
		"manager": "מזל קדוש",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אור חנה",
		"semelMosad": 541656,
		"settlement": "אור יהודה",
		"manager": "לאה אשכנזי",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אור חנה",
		"semelMosad": 441618,
		"settlement": "לוד",
		"manager": "לאה וישדסקי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אור יאיר הר סיני",
		"semelMosad": 741041,
		"settlement": "סוסיה",
		"manager": "יותם שלום סלע",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אור יהושע נתיבות התו",
		"semelMosad": 728741,
		"settlement": "בית שמש",
		"manager": "יוסף דוד רובינשטיין",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אור יחזקאל מודיעין ע",
		"semelMosad": 460121,
		"settlement": "מודיעין עילית",
		"manager": "אליהו דוד אוחנה",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אור ליובאוויטש",
		"semelMosad": 642819,
		"settlement": "כפר חב\"ד",
		"manager": "חיים יעיש מאיר ועקנין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אור ליובאוויטש צעירים",
		"semelMosad": 634246,
		"settlement": "כפר חב\"ד",
		"manager": "נועם מרדכי אליס",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אור לנוער חב\"ד",
		"semelMosad": 620088,
		"settlement": "קרית מלאכי",
		"manager": "מרדכי מוסאי",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אור לציון",
		"semelMosad": 237743,
		"settlement": "כוכב יעקב",
		"manager": "מתניה וינברגר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אור לציון בנות",
		"semelMosad": 414789,
		"settlement": "רחובות",
		"manager": "ליאת סלם",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אור מזל וברכה",
		"semelMosad": 338160,
		"settlement": "ירושלים",
		"manager": "דוד חיאב",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אור מלכה",
		"semelMosad": 481945,
		"settlement": "ראשון לציון",
		"manager": "בת שבע זילברשטרום",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אור מנחם",
		"semelMosad": 508101,
		"settlement": "אור יהודה",
		"manager": "יחזקאל חנונו",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אור מנחם",
		"semelMosad": 510065,
		"settlement": "אור יהודה",
		"manager": "ענבל רחימי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אור מנחם חב\"ד בנות",
		"semelMosad": 611145,
		"settlement": "ערד",
		"manager": "צלה סלפוצ'ניק",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אור משה",
		"semelMosad": 742916,
		"settlement": "תל אביב - יפו",
		"manager": "מרדכי חוברה",
		"level": "עליונה בלבד",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אור משה",
		"semelMosad": 722272,
		"settlement": "בית שמש",
		"manager": "אלעזר חנן",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אור פנחס",
		"semelMosad": 430140,
		"settlement": "גבע בנימין",
		"manager": "מיכל רם",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אור שבעת הימים",
		"semelMosad": 512269,
		"settlement": "בית שמש",
		"manager": "פנחס נפתלי ברנדר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אור שלום",
		"semelMosad": 313544,
		"settlement": "אור עקיבא",
		"manager": "יואל קקון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אור שמחה חנמ בנים",
		"semelMosad": 482497,
		"settlement": "כפר חב\"ד",
		"manager": "שמעון-כדורי ידגר",
		"level": "יסודי חט\"ב ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אור שערים",
		"semelMosad": 460543,
		"settlement": "מודיעין עילית",
		"manager": "שרה חוה הירשמן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אור שערים-קרלין סטול",
		"semelMosad": 288167,
		"settlement": "מודיעין עילית",
		"manager": "איטה טננהויז",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אור שרגא",
		"semelMosad": 169680,
		"settlement": "ירושלים",
		"manager": "ירון נאמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אור תורה",
		"semelMosad": 338756,
		"settlement": "בית שמש",
		"manager": "מאיר אהרון פרץ",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אור תורה סטון לבנות",
		"semelMosad": 141549,
		"settlement": "ירושלים",
		"manager": "חנה עסיס",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אור תורה סטון לבנים",
		"semelMosad": 141499,
		"settlement": "ירושלים",
		"manager": "יובל פרזון",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אור-מנחם חב\"ד בנות",
		"semelMosad": 214114,
		"settlement": "צפת",
		"manager": "נחמה חיה נבון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אור-מנחם חב\"ד בנים",
		"semelMosad": 213561,
		"settlement": "צפת",
		"manager": "מרדכי ידגר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אור-ע'ש זבולון המר",
		"semelMosad": 614701,
		"settlement": "באר שבע",
		"manager": "מיטל רוזוליו",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אורה ברמה (בנות)",
		"semelMosad": 113944,
		"settlement": "ירושלים",
		"manager": "לאה גלבוע",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אורה ושמחה",
		"semelMosad": 166538,
		"settlement": "ירושלים",
		"manager": "נעימה אריה",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אורות",
		"semelMosad": 614966,
		"settlement": "אשדוד",
		"manager": "יואל רפאל דרמון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אורות בנות",
		"semelMosad": 514968,
		"settlement": "אור יהודה",
		"manager": "דבורה לאה מלוב",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אורות התורה מד",
		"semelMosad": 513671,
		"settlement": "בת ים",
		"manager": "יוסף אופנהיימר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אורות התלמוד",
		"semelMosad": 560680,
		"settlement": "ביתר עילית",
		"manager": "נפתלי צבי אייזנשטיין",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אורות ירושלים",
		"semelMosad": 442632,
		"settlement": "ביתר עילית",
		"manager": "חנה דייטש",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אורות מרגלית",
		"semelMosad": 610832,
		"settlement": "אשדוד",
		"manager": "אשר מאיר קרסינטה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אורות עציון בנות",
		"semelMosad": 114249,
		"settlement": "אפרת",
		"manager": "שרה קופר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אורות עציון בנות גוש עציון",
		"semelMosad": 616151,
		"settlement": "נווה דניאל",
		"manager": "טליה נחמה שקד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אורות עציון בנים",
		"semelMosad": 114173,
		"settlement": "אפרת",
		"manager": "אשר צבי נאמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אורות עציון בנים גוש עציון",
		"semelMosad": 511618,
		"settlement": "נווה דניאל",
		"manager": "דוד עסיס",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אורות-נתיבות משה מעו",
		"semelMosad": 214627,
		"settlement": "עפולה",
		"manager": "הדסה שרה רוטנברג",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אורחות איש",
		"semelMosad": 142935,
		"settlement": "ירושלים",
		"manager": "אביעזר גליקסברג",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אורחות אמותינו(בנות)",
		"semelMosad": 499731,
		"settlement": "בית שמש",
		"manager": "חנה גינזבורג",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אורחות חיים",
		"semelMosad": 520296,
		"settlement": "בני ברק",
		"manager": "שלמה שטרן",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אורחות חיים",
		"semelMosad": 338517,
		"settlement": "ביתר עילית",
		"manager": "חיים ברקאי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אורחות חיים אברהם",
		"semelMosad": 362236,
		"settlement": "אשדוד",
		"manager": "אלעזר חדד",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אורחות חיים החדשה",
		"semelMosad": 633255,
		"settlement": "בני ברק",
		"manager": "צבי לבקוביץ",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אורחות יושר",
		"semelMosad": 646018,
		"settlement": "בית שמש",
		"manager": "מירה חשין",
		"level": "יסודי בלבד",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אורחות יצחק שערי ציון",
		"semelMosad": 412221,
		"settlement": "נתניה",
		"manager": "ישראל מאיר דדון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אורחות שרה",
		"semelMosad": 578583,
		"settlement": "נתניה",
		"manager": "אודליה קריאף",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אורט אבו קוידר נווה",
		"semelMosad": 761643,
		"settlement": "אבו ג'ווייעד (שבט)",
		"manager": "פאוזי אבו קוידר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "אורט אדיבי אשקלון",
		"semelMosad": 660092,
		"settlement": "אשקלון",
		"manager": "אליקי אלקובי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אורט אלהאלל נווה מדב",
		"semelMosad": 643577,
		"settlement": "אבו תלול",
		"manager": "זאיד הואשלה",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "אורט אלון עפולה",
		"semelMosad": 420042,
		"settlement": "עפולה",
		"manager": "איריס קרל",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אורט אלמותנבי כסייפה",
		"semelMosad": 610006,
		"settlement": "כסיפה",
		"manager": "חליל דהאבשה",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "אורט אמירים",
		"semelMosad": 240127,
		"settlement": "בית שאן",
		"manager": "מירב אלבז",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אורט אפרידר רונסון",
		"semelMosad": 670067,
		"settlement": "אשקלון",
		"manager": "דבורה בוסקילה",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אורט דתי לבנות",
		"semelMosad": 570176,
		"settlement": "בני ברק",
		"manager": "עירית דר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אורט דתי ע\"ש ספניאן",
		"semelMosad": 170514,
		"settlement": "ירושלים",
		"manager": "שלום אדמון וייל",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אורט התעשיה האווירית",
		"semelMosad": 471037,
		"settlement": "לוד",
		"manager": "רוית כהן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אורט ח\"א תל נוף",
		"semelMosad": 471045,
		"settlement": "מחנה תל נוף",
		"manager": "אבי בזה",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אורט טכנולוגי קרית ג",
		"semelMosad": 729285,
		"settlement": "קרית גת",
		"manager": "אברהם רפאל חג'ג'",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אורט טכניקום גבעתיים",
		"semelMosad": 570200,
		"settlement": "גבעתיים",
		"manager": "בתיה כץ",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אורט יד סינגאלובסקי",
		"semelMosad": 570077,
		"settlement": "תל אביב - יפו",
		"manager": "מייקל מאירוביץ",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אורט ימי",
		"semelMosad": 670331,
		"settlement": "אשדוד",
		"manager": "חנה אייזנר צ'נאק",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אורט מלטון",
		"semelMosad": 570713,
		"settlement": "בת ים",
		"manager": "עליזה אפרים",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אורט מעין חי ישיבה ט",
		"semelMosad": 755074,
		"settlement": "רחובות",
		"manager": "יואח יהודה שאער",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אורט משכן משה ביתר ע",
		"semelMosad": 755397,
		"settlement": "ביתר עילית",
		"manager": "עמרם אייזן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אורט ע\"ש אבין",
		"semelMosad": 570135,
		"settlement": "רמת גן",
		"manager": "רותם לצטר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אורט ע\"ש ה. רונסון",
		"semelMosad": 640060,
		"settlement": "אשקלון",
		"manager": "אורה גול",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אורט ע\"ש מיטרני",
		"semelMosad": 770750,
		"settlement": "חולון",
		"manager": "אורנה איזנמן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אורט ע\"ש מקס שיין",
		"semelMosad": 470054,
		"settlement": "רחובות",
		"manager": "אברהם סער",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אורט עירוני ד",
		"semelMosad": 344911,
		"settlement": "מודיעין-מכבים-רעות",
		"manager": "צופית חדד",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אורט פסגות",
		"semelMosad": 244285,
		"settlement": "כרמיאל",
		"manager": "שרה עובד",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אורט פסגות מגדל העמק",
		"semelMosad": 729053,
		"settlement": "מגדל העמק",
		"manager": "רבקה אלימלך",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אורט פסגות תיכון דתי ישראלי",
		"semelMosad": 270157,
		"settlement": "בית שאן",
		"manager": "אורי יוסף דויטש",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אורט צור ערד",
		"semelMosad": 729293,
		"settlement": "ערד",
		"manager": "ברוך אביבי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אורט רונסון עוספיה",
		"semelMosad": 344234,
		"settlement": "עספיא",
		"manager": "סנא אבו רוקן",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "דרוזי"
	},
	{
		"schoolName": "אורט רמת יוסף",
		"semelMosad": 571174,
		"settlement": "בת ים",
		"manager": "חדוה גלזר",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אורט שלומי",
		"semelMosad": 227694,
		"settlement": "שלומי",
		"manager": "אופיר אפרים יהודה גליק",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אורט שפירא אזורי למנהיגות",
		"semelMosad": 470112,
		"settlement": "כפר סבא",
		"manager": "ורד ורדה אסולין",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אורט תעופה וחלל",
		"semelMosad": 170803,
		"settlement": "מעלה אדומים",
		"manager": "שי שמעון אש",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אורי אורבך קשת",
		"semelMosad": 416677,
		"settlement": "מזכרת בתיה",
		"manager": "דורית רודן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אוריין",
		"semelMosad": 612978,
		"settlement": "באר שבע",
		"manager": "מיכל אורה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אורייתא",
		"semelMosad": 757591,
		"settlement": "ירושלים",
		"manager": "יהודה ווגשל",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אורייתא",
		"semelMosad": 142372,
		"settlement": "ירושלים",
		"manager": "ראובן קפלן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אורים",
		"semelMosad": 514661,
		"settlement": "רמת השרון",
		"manager": "רעות רוזנברג",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אורים",
		"semelMosad": 644054,
		"settlement": "אילת",
		"manager": "תומר גולדפרב",
		"level": "יסודי חט\"ב ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אורים",
		"semelMosad": 320077,
		"settlement": "חיפה",
		"manager": "מלכה מלי ממן",
		"level": "יסודי בלבד",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אורים",
		"semelMosad": 311563,
		"settlement": "קרית ים",
		"manager": "חגית שוסטיאל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אורים",
		"semelMosad": 620419,
		"settlement": "באר שבע",
		"manager": "מיטל מירה דלמר-טובלי",
		"level": "חט\"ב + עליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אורים ע\"ז לשם",
		"semelMosad": 480053,
		"settlement": "עלי זהב",
		"manager": "יאיר זוסמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אורנים",
		"semelMosad": 482885,
		"settlement": "ראשון לציון",
		"manager": "מיכל קניג בן שלמה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אורנים",
		"semelMosad": 512699,
		"settlement": "רמת השרון",
		"manager": "רון סמואל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אורנים",
		"semelMosad": 411702,
		"settlement": "פתח תקווה",
		"manager": "יעל הרשטיק",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אורנים-יקנעם עלית",
		"semelMosad": 210880,
		"settlement": "יקנעם עילית",
		"manager": "ליאורה סגל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אורתודוקסי ערבי",
		"semelMosad": 347013,
		"settlement": "חיפה",
		"manager": "שחאדה שחאדה",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אושא מד",
		"semelMosad": 510875,
		"settlement": "רמת גן",
		"manager": "זאב בר לב",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אזורי אבן שמואל",
		"semelMosad": 610014,
		"settlement": "אבן שמואל",
		"manager": "הדסה מיכאליס",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אזורי אלונה",
		"semelMosad": 311324,
		"settlement": "עמיקם",
		"manager": "יפית הדר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אזורי בן שמן",
		"semelMosad": 410258,
		"settlement": "בן שמן (כפר נוער)",
		"manager": "טל דורון לולו",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אזורי דקלים-חמדיה",
		"semelMosad": 210641,
		"settlement": "חמדיה",
		"manager": "שרה דגן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אזורי חופי-הגליל",
		"semelMosad": 210468,
		"settlement": "גשר הזיו",
		"manager": "שגיא קדר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אזורי ירדן",
		"semelMosad": 240440,
		"settlement": "מעלה אפרים",
		"manager": "אילנית הללי יהודה",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אזורי כדורי",
		"semelMosad": 210948,
		"settlement": "כדורי",
		"manager": "יהודית הרשקוביץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אזורי מקיף עין כרם",
		"semelMosad": 180018,
		"settlement": "עין כרם-בי\"ס חקלאי",
		"manager": "אסף שמר",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אזורי ע\"ש הרצוג",
		"semelMosad": 441089,
		"settlement": "בית חשמונאי",
		"manager": "יצחק שחר",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אזורי\"עלי גבעה",
		"semelMosad": 214247,
		"settlement": "גבעת אבני",
		"manager": "יעקב וקנין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אח\"י תקוע 2",
		"semelMosad": 615955,
		"settlement": "תקוע",
		"manager": "אופיר נחמיה לוגסי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אחד העם",
		"semelMosad": 310227,
		"settlement": "חדרה",
		"manager": "צלילה ויתקין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אחדות",
		"semelMosad": 311605,
		"settlement": "קרית מוצקין",
		"manager": "מור אורן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אחדות",
		"semelMosad": 611368,
		"settlement": "אשדוד",
		"manager": "איילה שקד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אחוה יפו",
		"semelMosad": 518035,
		"settlement": "תל אביב - יפו",
		"manager": "חיאת אבו שמיס",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אחוה כפר מנדא",
		"semelMosad": 228262,
		"settlement": "כפר מנדא",
		"manager": "מונא זידאן",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אחווה",
		"semelMosad": 615203,
		"settlement": "דימונה",
		"manager": "גלית ווקנין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אחוות אחים",
		"semelMosad": 611053,
		"settlement": "קרית מלאכי",
		"manager": "חני צאנו",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אחוזה",
		"semelMosad": 310375,
		"settlement": "חיפה",
		"manager": "ענת חנה דניאל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אחוזת ילדים",
		"semelMosad": 344465,
		"settlement": "חיפה",
		"manager": "אולגה יליזרוב",
		"level": "חט\"ב + עליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אחינועם",
		"semelMosad": 630186,
		"settlement": "ירושלים",
		"manager": "יהושע אפרים שטרית",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אחמד סאמח אלחלדי",
		"semelMosad": 118067,
		"settlement": "ירושלים",
		"manager": "ווסים רישק",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אחמדיה כבאביר חיפה",
		"semelMosad": 318543,
		"settlement": "חיפה",
		"manager": "נסרין עודה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אטור יסודי בנים",
		"semelMosad": 178160,
		"settlement": "ירושלים",
		"manager": "ואיל אבו אלהוא",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "איבדאע",
		"semelMosad": 482984,
		"settlement": "זמר",
		"manager": "סאאידה גאנם",
		"level": "חט\"ב בלבד",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "איבטין",
		"semelMosad": 318055,
		"settlement": "אבטין",
		"manager": "סעיד מסרי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "איבן אל-היית'ם",
		"semelMosad": 118158,
		"settlement": "ירושלים",
		"manager": "מחמוד חוסיין",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "איבן סינא א'",
		"semelMosad": 418053,
		"settlement": "טייבה",
		"manager": "חלוד חאג' יחיא",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "איבן סינא ב'",
		"semelMosad": 418368,
		"settlement": "טייבה",
		"manager": "היבה אבו ראס",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "איבן סינא ערערה",
		"semelMosad": 618314,
		"settlement": "ערערה-בנגב",
		"manager": "סלמאן אבו גויעד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "איברהמיה",
		"semelMosad": 118364,
		"settlement": "ירושלים",
		"manager": "הנאדי הדמי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "איזורי מעונה",
		"semelMosad": 212936,
		"settlement": "מעונה",
		"manager": "גילה חדד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "איילות",
		"semelMosad": 480152,
		"settlement": "גני תקווה",
		"manager": "נופר אלפי לס",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "איילים -טבריה",
		"semelMosad": 213223,
		"settlement": "טבריה",
		"manager": "אורלי סוסן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "איילים בנות",
		"semelMosad": 413807,
		"settlement": "אלעד",
		"manager": "צפורית בתיה אייזיק",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "איילים בנים",
		"semelMosad": 415653,
		"settlement": "אלעד",
		"manager": "עופרה ויזל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "איילת השחר",
		"semelMosad": 756585,
		"settlement": "בית שמש",
		"manager": "מיכל זייבלד",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "איילת השחר",
		"semelMosad": 756627,
		"settlement": "בני ברק",
		"manager": "אשר בנדיקט",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "איילת השחר",
		"semelMosad": 353573,
		"settlement": "ירושלים",
		"manager": "רות טבול",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "איילת השחר בני ברק",
		"semelMosad": 642900,
		"settlement": "בני ברק",
		"manager": "אריה שפירו",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "איילת השחר חיפה",
		"semelMosad": 642314,
		"settlement": "חיפה",
		"manager": "אהרן דוד קורלנסקי",
		"level": "יסודי בלבד",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "איילת השחר ירושלים",
		"semelMosad": 634337,
		"settlement": "ירושלים",
		"manager": "יהושוע צבי אדם",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "איל יסודי עכו",
		"semelMosad": 220038,
		"settlement": "עכו",
		"manager": "אפרת ספיר",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אילון",
		"semelMosad": 484014,
		"settlement": "בית חשמונאי",
		"manager": "ענת דימנט",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אילון",
		"semelMosad": 511030,
		"settlement": "תל אביב - יפו",
		"manager": "אסתר בנימין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אילן רמון",
		"semelMosad": 416008,
		"settlement": "גן יבנה",
		"manager": "יעל אוריאל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אילן רמון",
		"semelMosad": 416396,
		"settlement": "נתניה",
		"manager": "רחל שמואלי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אילנות",
		"semelMosad": 310383,
		"settlement": "חיפה",
		"manager": "שלומי אלון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אילנות",
		"semelMosad": 482018,
		"settlement": "באר יעקב",
		"manager": "מיכל דמתי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אילנות",
		"semelMosad": 513648,
		"settlement": "הרצליה",
		"manager": "גלית אראל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אילנות",
		"semelMosad": 511048,
		"settlement": "תל אביב - יפו",
		"manager": "יעל מזור",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אילנות",
		"semelMosad": 620203,
		"settlement": "באר שבע",
		"manager": "מדלין מרגלית אהרון",
		"level": "יסודי בלבד",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אילנות",
		"semelMosad": 620070,
		"settlement": "אשדוד",
		"manager": "גנדי מלמד",
		"level": "יסודי בלבד",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אילנות",
		"semelMosad": 611830,
		"settlement": "אשקלון",
		"manager": "נורית חכמון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אילנות מ\"מ",
		"semelMosad": 120287,
		"settlement": "ירושלים",
		"manager": "שרונה מאיר",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אילנות נהריה",
		"semelMosad": 220020,
		"settlement": "נהריה",
		"manager": "רות פיסאחוב",
		"level": "יסודי חט\"ב ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אילת השחר",
		"semelMosad": 612853,
		"settlement": "אילת",
		"manager": "יואב חנו",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אינשטיין",
		"semelMosad": 312439,
		"settlement": "חיפה",
		"manager": "חגית סלע",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אית\"ן",
		"semelMosad": 511691,
		"settlement": "ירושלים",
		"manager": "ורדה שרה אדלר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "איתמר",
		"semelMosad": 413070,
		"settlement": "ראשון לציון",
		"manager": "פופה אסא",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "איתמר",
		"semelMosad": 413104,
		"settlement": "איתמר",
		"manager": "משה כהניאן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "איתמר בן אב\"י",
		"semelMosad": 411355,
		"settlement": "נתניה",
		"manager": "אילת שוצמן זינגר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "איתמר בן אבי",
		"semelMosad": 511196,
		"settlement": "תל אביב - יפו",
		"manager": "ענת יצחקי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "איתן",
		"semelMosad": 620112,
		"settlement": "אשקלון",
		"manager": "ירדנה צלח",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אל - אמל",
		"semelMosad": 618462,
		"settlement": "עוקבי (בנו עוקבה)",
		"manager": "חליל אלכרם",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "אל אוסקופיה",
		"semelMosad": 248690,
		"settlement": "שפרעם",
		"manager": "פארוק פרהוד",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אל אמל",
		"semelMosad": 618876,
		"settlement": "ביר הדאג'",
		"manager": "איברהים אבו עסא",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "אל אמל",
		"semelMosad": 128033,
		"settlement": "ירושלים",
		"manager": "סאמר ג'אעוני",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אל אנדלוס",
		"semelMosad": 618157,
		"settlement": "חורה",
		"manager": "טאלב אלנבארי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "אל אעצם ב'",
		"semelMosad": 618355,
		"settlement": "אעצם (שבט)",
		"manager": "יונס אל אעסם",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "אל ארצי",
		"semelMosad": 441220,
		"settlement": "אלון מורה",
		"manager": "אריאל שיוביץ",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אל אשראק",
		"semelMosad": 384230,
		"settlement": "עספיא",
		"manager": "אדם חוסיסי",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "דרוזי"
	},
	{
		"schoolName": "אל בוחארי",
		"semelMosad": 418251,
		"settlement": "טייבה",
		"manager": "פתחיה מסארוה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אל בירוני",
		"semelMosad": 148270,
		"settlement": "ירושלים",
		"manager": "צלאח מחיסן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אל ג'ואריש",
		"semelMosad": 418038,
		"settlement": "רמלה",
		"manager": "נואל אבו עאמר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אל גזאלי",
		"semelMosad": 418061,
		"settlement": "טייבה",
		"manager": "ממדוח עאזם",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אל גזאלי",
		"semelMosad": 418087,
		"settlement": "טירה",
		"manager": "נורס סולטאן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אל גזאלי",
		"semelMosad": 418111,
		"settlement": "כפר קאסם",
		"manager": "כאותר שיך עיסא",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אל זהרא",
		"semelMosad": 418079,
		"settlement": "טייבה",
		"manager": "בדרה עבד אל קאדר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אל זהרא",
		"semelMosad": 418269,
		"settlement": "לוד",
		"manager": "שרין מחארב",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אל זהרא",
		"semelMosad": 418095,
		"settlement": "טירה",
		"manager": "פאתנה בשארה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אל זהראא - ב'",
		"semelMosad": 249045,
		"settlement": "נצרת",
		"manager": "לילא אבו אחמד",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אל זהרה",
		"semelMosad": 195412,
		"settlement": "ירושלים",
		"manager": "מוחמד עליאן",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אל זייתון",
		"semelMosad": 217547,
		"settlement": "כפר מנדא",
		"manager": "ח'יתאם שנאוי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אל זייתון",
		"semelMosad": 219568,
		"settlement": "בועיינה-נוג'ידאת",
		"manager": "אבראהים נוג'ידאת",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "אל חיואר",
		"semelMosad": 318659,
		"settlement": "חיפה",
		"manager": "ריהאם ח'ורי פאר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אל חנאן",
		"semelMosad": 627000,
		"settlement": "רהט",
		"manager": "סמין אלהזייל",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "אל מוקבר תיכון לבנות",
		"semelMosad": 178061,
		"settlement": "ירושלים",
		"manager": "דימה עלייאן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אל מנאר",
		"semelMosad": 418277,
		"settlement": "כפר קאסם",
		"manager": "עאידה אבו גאבר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אל מנאר",
		"semelMosad": 418129,
		"settlement": "לוד",
		"manager": "עביר אזברגה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אל מנאר",
		"semelMosad": 641597,
		"settlement": "רהט",
		"manager": "סלימאן אלטורי",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "אל מנארה",
		"semelMosad": 219287,
		"settlement": "עכו",
		"manager": "אוסאמה עבד אל פתאח",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אל נג'אח",
		"semelMosad": 417048,
		"settlement": "טירה",
		"manager": "מחמוד פדילה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אל נג'אח בועינה",
		"semelMosad": 218099,
		"settlement": "בועיינה-נוג'ידאת",
		"manager": "אחמד סלימאן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "אל נור",
		"semelMosad": 228569,
		"settlement": "אעבלין",
		"manager": "ג'ומאנה נשאשיבי",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אל סביל",
		"semelMosad": 482141,
		"settlement": "רמלה",
		"manager": "ראשה אלולו",
		"level": "יסודי חט\"ב ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אל סייד ג",
		"semelMosad": 618926,
		"settlement": "אל סייד",
		"manager": "סאלח סייד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "אל סלאם",
		"semelMosad": 195404,
		"settlement": "ירושלים",
		"manager": "אחמד נאסר",
		"level": "חט\"ב + עליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אל סלאם",
		"semelMosad": 618249,
		"settlement": "רהט",
		"manager": "אבראהים קרינאוי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "אל עומריה",
		"semelMosad": 418160,
		"settlement": "טירה",
		"manager": "מיסון קאסם",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אל עומריה",
		"semelMosad": 418186,
		"settlement": "כפר קאסם",
		"manager": "מוחמד צרצור",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אל עזאזמה א",
		"semelMosad": 618702,
		"settlement": "ביר הדאג'",
		"manager": "חמאד אבן חמאד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "אל פורעה ראש זוהר",
		"semelMosad": 618207,
		"settlement": "דריג'את",
		"manager": "חאבס גברין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "אל ראשידיה",
		"semelMosad": 418178,
		"settlement": "קלנסווה",
		"manager": "עומר סלאמה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אל ראשידיה",
		"semelMosad": 418228,
		"settlement": "לוד",
		"manager": "אמין אלגמל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אל-אח'א",
		"semelMosad": 618348,
		"settlement": "רהט",
		"manager": "דיאב אל אפיניש",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "אל-אמל",
		"semelMosad": 372946,
		"settlement": "משהד",
		"manager": "ח'אלד סאלח",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אל-זהרא",
		"semelMosad": 618264,
		"settlement": "רהט",
		"manager": "אחמד אבו מדיעם",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "אל-חנן מעון שקומי",
		"semelMosad": 428037,
		"settlement": "טירה",
		"manager": "סנא פדילה",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אל-טלאאע",
		"semelMosad": 635284,
		"settlement": "ירושלים",
		"manager": "מופיד אבו גוש",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אל-מג'ד (מסלך)",
		"semelMosad": 218651,
		"settlement": "נצרת",
		"manager": "רים הרייש",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אל-מוטראן",
		"semelMosad": 117002,
		"settlement": "ירושלים",
		"manager": "חוסאם נעום",
		"level": "יסודי ועליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אל-מנאר",
		"semelMosad": 328070,
		"settlement": "ערערה",
		"manager": "מוחמד יונס",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אל-סדיק",
		"semelMosad": 318345,
		"settlement": "פוריידיס",
		"manager": "מאגד דראוושה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אל-פארוק",
		"semelMosad": 318600,
		"settlement": "פוריידיס",
		"manager": "מרוה בריה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אל-קסטל (קשלה א')",
		"semelMosad": 219527,
		"settlement": "נצרת",
		"manager": "סירין מג'לי-כנאנה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אל-רואאה",
		"semelMosad": 218073,
		"settlement": "מנשית זבדה",
		"manager": "פריד גרייפה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "אל-שאפעי",
		"semelMosad": 318360,
		"settlement": "פוריידיס",
		"manager": "הישאם דקנאש",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלאהליה אלשאמלה קלנס",
		"semelMosad": 478263,
		"settlement": "קלנסווה",
		"manager": "מאמון אבו אל היגא",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלאהליה אלשאמלה קלנס",
		"semelMosad": 522367,
		"settlement": "קלנסווה",
		"manager": "מאמון אבו אל היגא",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלאוסופיה",
		"semelMosad": 217265,
		"settlement": "נצרת",
		"manager": "ראניה סאבא",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלאחוה",
		"semelMosad": 318550,
		"settlement": "אום אל-פחם",
		"manager": "מוחמד אגבאריה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלאימאן",
		"semelMosad": 630020,
		"settlement": "אום בטין",
		"manager": "סלמאן אבו כף",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "אלאימאן",
		"semelMosad": 428011,
		"settlement": "טייבה",
		"manager": "מונה עבד אל קאדר",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלאימאן",
		"semelMosad": 618819,
		"settlement": "ביר הדאג'",
		"manager": "סאלם אבן חמיד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "אלאמאל",
		"semelMosad": 715219,
		"settlement": "ירושלים",
		"manager": "מוחמד ג'אבר",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלאמל",
		"semelMosad": 618678,
		"settlement": "לקיה",
		"manager": "מחמד אבו סעד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "אלאמל",
		"semelMosad": 618827,
		"settlement": "כסיפה",
		"manager": "חאתם אבו עגאג",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "אלאמל",
		"semelMosad": 418350,
		"settlement": "רמלה",
		"manager": "עבד חטיב",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלאמל",
		"semelMosad": 268011,
		"settlement": "משהד",
		"manager": "ח'אלד סאלח",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלאמל בית ג'אן ג'",
		"semelMosad": 217331,
		"settlement": "בית ג'ן",
		"manager": "פאיד דיב",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "דרוזי"
	},
	{
		"schoolName": "אלאמל חינוך מיוחד",
		"semelMosad": 328039,
		"settlement": "אום אל-פחם",
		"manager": "דונא אלמלק",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלאמל ירכא",
		"semelMosad": 218347,
		"settlement": "ירכא",
		"manager": "שירין ג'מאל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "דרוזי"
	},
	{
		"schoolName": "אלאמל ע\"ש חוסין אבריק",
		"semelMosad": 218024,
		"settlement": "אבו סנאן",
		"manager": "ראאף ג'ומעה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "דרוזי"
	},
	{
		"schoolName": "אלאמל עכו",
		"semelMosad": 218800,
		"settlement": "עכו",
		"manager": "עבדאללה זידאן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלאנדלוס",
		"semelMosad": 618868,
		"settlement": "כסיפה",
		"manager": "מוסה אבו סיאח",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "אלאנדלוס",
		"semelMosad": 618470,
		"settlement": "תל שבע",
		"manager": "עיד אבו עסא",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "אלאנדלוס",
		"semelMosad": 348326,
		"settlement": "באקה אל-גרביה",
		"manager": "אשרף מקלד",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלאנואר מסעדה",
		"semelMosad": 217844,
		"settlement": "מסעדה",
		"manager": "אומימה אבו שאהין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "דרוזי"
	},
	{
		"schoolName": "אלאשראק",
		"semelMosad": 318857,
		"settlement": "עספיא",
		"manager": "איכראם להיאני",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "דרוזי"
	},
	{
		"schoolName": "אלבאט",
		"semelMosad": 616565,
		"settlement": "מכחול",
		"manager": "מחמד אבו צבייח",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "אלבאשאר",
		"semelMosad": 338053,
		"settlement": "סח'נין",
		"manager": "ערפאת שלאעטה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלביאדר",
		"semelMosad": 618520,
		"settlement": "חורה",
		"manager": "חמאד אלגואעין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "אלביאדר",
		"semelMosad": 218784,
		"settlement": "עין מאהל",
		"manager": "סוהא אבו ליל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלביאדר עין אלאסד",
		"semelMosad": 218776,
		"settlement": "עין אל-אסד",
		"manager": "סאלח בדר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "דרוזי"
	},
	{
		"schoolName": "אלביאן",
		"semelMosad": 618694,
		"settlement": "אל סייד",
		"manager": "סייד סייד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "אלביאן",
		"semelMosad": 618736,
		"settlement": "רהט",
		"manager": "סלימאן אלמטאלקה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "אלביאן טמרה",
		"semelMosad": 248203,
		"settlement": "טמרה",
		"manager": "סאלח טאהא",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלביארוני",
		"semelMosad": 218321,
		"settlement": "טמרה",
		"manager": "אחמד דיאב",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלביירוני",
		"semelMosad": 618397,
		"settlement": "לקיה",
		"manager": "מאהר אבו עמאר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "אלביירוני העירוני",
		"semelMosad": 520346,
		"settlement": "נצרת",
		"manager": "תהאני מרעי שחאדה",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלבירוני עראבה",
		"semelMosad": 227488,
		"settlement": "עראבה",
		"manager": "אחמד נסאר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלבסמאת",
		"semelMosad": 620302,
		"settlement": "ירושלים",
		"manager": "סאלח זגל",
		"level": "יסודי חט\"ב ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלבסמה",
		"semelMosad": 228361,
		"settlement": "כסרא-סמיע",
		"manager": "אדמא קבלאן",
		"level": "יסודי חט\"ב ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "דרוזי"
	},
	{
		"schoolName": "אלבסמה-לחינוך מיוחד.",
		"semelMosad": 338012,
		"settlement": "ירושלים",
		"manager": "ראמי בדארנה",
		"level": "יסודי חט\"ב ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלברט אינשטיין",
		"semelMosad": 482117,
		"settlement": "יבנה",
		"manager": "דקלה שגיא",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אלג'נאן",
		"semelMosad": 428078,
		"settlement": "כפר קאסם",
		"manager": "כפאיה בדיר",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלגדיר סכנין",
		"semelMosad": 217422,
		"settlement": "סח'נין",
		"manager": "כמאל גנאים",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלגזאלי",
		"semelMosad": 318063,
		"settlement": "באקה אל-גרביה",
		"manager": "כרם אבו מוך",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלגזאלי טמרה ו'",
		"semelMosad": 219543,
		"settlement": "טמרה",
		"manager": "יוסף חג'אזי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלגזאלי עראבה",
		"semelMosad": 219402,
		"settlement": "עראבה",
		"manager": "לינה חלו",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלה חנ\"מ",
		"semelMosad": 620450,
		"settlement": "גבעת שמש",
		"manager": "",
		"level": "יסודי חט\"ב ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אלהדאיה אלנמודג'יה",
		"semelMosad": 411157,
		"settlement": "ירושלים",
		"manager": "כוותר פרעון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלהודא",
		"semelMosad": 618256,
		"settlement": "רהט",
		"manager": "גמאל אל הוזייל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "אלהודא",
		"semelMosad": 448324,
		"settlement": "רמלה",
		"manager": "עבד אל אילה עאזם",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלהודא",
		"semelMosad": 418467,
		"settlement": "רמלה",
		"manager": "וופא אבו סלימאן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלהושאלה",
		"semelMosad": 618199,
		"settlement": "קצר א-סר",
		"manager": "עאמר אבו עסא",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "אלהלאל",
		"semelMosad": 318469,
		"settlement": "ערערה",
		"manager": "ח'אלד יונס",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלהלאל",
		"semelMosad": 618900,
		"settlement": "רהט",
		"manager": "אמיר נסאסרה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "אלואדי",
		"semelMosad": 317032,
		"settlement": "ג'סר א-זרקא",
		"manager": "נג'ם ג'ורבאן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלוואדי למצוינות",
		"semelMosad": 160473,
		"settlement": "ירושלים",
		"manager": "סועאד דעאס",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלומות",
		"semelMosad": 620484,
		"settlement": "ירושלים",
		"manager": "דגנית הראל",
		"level": "חט\"ב + עליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אלומות",
		"semelMosad": 411363,
		"settlement": "נתניה",
		"manager": "אורית מוקה בליטנטל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אלומות",
		"semelMosad": 614180,
		"settlement": "באר שבע",
		"manager": "עליזה שלום",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אלומות",
		"semelMosad": 482000,
		"settlement": "מרכז אזורי שוהם",
		"manager": "רינת שטרום",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אלומות -עפולה",
		"semelMosad": 211904,
		"settlement": "עפולה",
		"manager": "מיכל כהן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אלומות מד",
		"semelMosad": 511998,
		"settlement": "תל אביב - יפו",
		"manager": "ענת בינה בלייער",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אלומות רחל נתיבות משה",
		"semelMosad": 416404,
		"settlement": "ראש העין",
		"manager": "שרה גורפינקל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אלומים",
		"semelMosad": 616938,
		"settlement": "אפרת",
		"manager": "נטעיה צבי יחזקאל פרומן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אלון",
		"semelMosad": 313767,
		"settlement": "חיפה",
		"manager": "ענת כח",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אלון",
		"semelMosad": 513523,
		"settlement": "הרצליה",
		"manager": "מורן מזל אבני",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אלון",
		"semelMosad": 614172,
		"settlement": "שדרות",
		"manager": "טליה פריאנטה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אלון",
		"semelMosad": 112037,
		"settlement": "מעלה החמישה",
		"manager": "דרור איוניר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אלון",
		"semelMosad": 414243,
		"settlement": "רחובות",
		"manager": "רונית מוסלר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אלון",
		"semelMosad": 410324,
		"settlement": "גבעת שמואל",
		"manager": "נועם אהרוני",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אלון יזרעאל-עפולה",
		"semelMosad": 211912,
		"settlement": "עפולה",
		"manager": "מיכל ברובן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אלוני ממרא",
		"semelMosad": 131201,
		"settlement": "קרית ארבע",
		"manager": "יעל קליין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אלונים",
		"semelMosad": 581348,
		"settlement": "תל אביב - יפו",
		"manager": "ליאור ישראלי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אלונים",
		"semelMosad": 120527,
		"settlement": "ירושלים",
		"manager": "הילה גלבוע",
		"level": "חט\"ב + עליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אלונים",
		"semelMosad": 510024,
		"settlement": "אור יהודה",
		"manager": "לימור ברקת",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אלונים",
		"semelMosad": 311357,
		"settlement": "פרדס חנה-כרכור",
		"manager": "לימור סמואלוב",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אלונים",
		"semelMosad": 241034,
		"settlement": "מודיעין-מכבים-רעות",
		"manager": "גלית טורג'מן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אלונים מיוחד",
		"semelMosad": 420299,
		"settlement": "פתח תקווה",
		"manager": "זהבית מאיה הרשקו",
		"level": "יסודי בלבד",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אלונים ע\"ש שולמית אלוני",
		"semelMosad": 480228,
		"settlement": "ראשון לציון",
		"manager": "שרית צור",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אלונים שער הנגב",
		"semelMosad": 610493,
		"settlement": "גבים",
		"manager": "שלומציון כהן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אלזהרא",
		"semelMosad": 618751,
		"settlement": "חורה",
		"manager": "ופאא אבו שארב",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "אלזהרא (ג) טמרה",
		"semelMosad": 219089,
		"settlement": "טמרה",
		"manager": "מוחמד עואד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלזהרא אלנמודג'יה",
		"semelMosad": 460311,
		"settlement": "ירושלים",
		"manager": "תגריד אבו אלפילאת ברקאת",
		"level": "יסודי ועליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלזהראא",
		"semelMosad": 318014,
		"settlement": "אום אל-פחם",
		"manager": "ח'אלדיה מחאמיד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלזהראא",
		"semelMosad": 418459,
		"settlement": "קלנסווה",
		"manager": "נסרין רעד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלזהראא עראבה",
		"semelMosad": 219584,
		"settlement": "עראבה",
		"manager": "עבדאללה עאסלה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלח'וארזמי",
		"semelMosad": 338327,
		"settlement": "באקה אל-גרביה",
		"manager": "ואאל מוואסי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלחדיקה",
		"semelMosad": 218354,
		"settlement": "כאבול",
		"manager": "חתאם אשקר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלחואר",
		"semelMosad": 218941,
		"settlement": "פסוטה",
		"manager": "תירייז חדאד האשול",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלחיאה",
		"semelMosad": 359034,
		"settlement": "מג'ד אל-כרום",
		"manager": "מיאדה אבו רומחין",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלחיאם",
		"semelMosad": 318477,
		"settlement": "אום אל-פחם",
		"manager": "עימאד אגבאריה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלחיה מעורב כפר עקב",
		"semelMosad": 186809,
		"settlement": "ירושלים",
		"manager": "מוניר אבו עסבה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלחייאת",
		"semelMosad": 650010,
		"settlement": "ערערה-בנגב",
		"manager": "עבד אל עזיז אבו קוש",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "אלחכים",
		"semelMosad": 318576,
		"settlement": "כפר קרע",
		"manager": "מהדיה זחאלקה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלחכמה",
		"semelMosad": 318501,
		"settlement": "באקה אל-גרביה",
		"manager": "חסן ביאדסי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלחכמה",
		"semelMosad": 418327,
		"settlement": "טייבה",
		"manager": "חאלד עאזם",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלחכמה ג'ת",
		"semelMosad": 218156,
		"settlement": "יאנוח-ג'ת",
		"manager": "נאדיא ביבאר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "דרוזי"
	},
	{
		"schoolName": "אלחכמה פקיעין",
		"semelMosad": 219253,
		"settlement": "פקיעין (בוקייעה)",
		"manager": "נג'את בכריה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "דרוזי"
	},
	{
		"schoolName": "אלחלאן סכנין",
		"semelMosad": 248716,
		"settlement": "סח'נין",
		"manager": "עומר בדארנה",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלחנן לנכויות פיזיות קשות",
		"semelMosad": 719740,
		"settlement": "אום אל-פחם",
		"manager": "שאדיה ג'בארין",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלחנסאא",
		"semelMosad": 318493,
		"settlement": "אום אל-פחם",
		"manager": "עואטף אגבאריה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלחריזי",
		"semelMosad": 511071,
		"settlement": "תל אביב - יפו",
		"manager": "רויטל אבלס",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אלטור דבוריה",
		"semelMosad": 218164,
		"settlement": "דבוריה",
		"manager": "היפא מסאלחה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלטורשאן",
		"semelMosad": 618181,
		"settlement": "דריג'את",
		"manager": "חאלד אטרש",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "אלי כהן",
		"semelMosad": 611798,
		"settlement": "קרית מלאכי",
		"manager": "אירית טיירו",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אלי\"ן",
		"semelMosad": 120030,
		"settlement": "ירושלים",
		"manager": "יעקב בן חמו",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אליאב",
		"semelMosad": 480210,
		"settlement": "ראשון לציון",
		"manager": "סימה ברנדשפיגל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אליה",
		"semelMosad": 476796,
		"settlement": "ביתר עילית",
		"manager": "תומר ווקנין",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אלימלך כנר",
		"semelMosad": 416438,
		"settlement": "פתח תקווה",
		"manager": "איריס גלאי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אליעזר בן יהודה",
		"semelMosad": 442061,
		"settlement": "נס ציונה",
		"manager": "נטלי שלום",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אלירמוק",
		"semelMosad": 618538,
		"settlement": "כסיפה",
		"manager": "איוב אמטיראת",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "אליתים אלערבי",
		"semelMosad": 378125,
		"settlement": "ירושלים",
		"manager": "זיאד גוילס",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלכנדי דבוריה",
		"semelMosad": 219170,
		"settlement": "דבוריה",
		"manager": "אחלאם זועבי מסאלחה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלכרמה",
		"semelMosad": 462218,
		"settlement": "חיפה",
		"manager": "יסרין דקואר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלכרמה",
		"semelMosad": 318568,
		"settlement": "חיפה",
		"manager": "מרים שייך מוחמד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלמג'ד",
		"semelMosad": 618074,
		"settlement": "חורה",
		"manager": "כאמל אבו קיעאן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "אלמג'ד",
		"semelMosad": 522482,
		"settlement": "ירושלים",
		"manager": "אשרף אבו אל ואליה",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלמג'ד",
		"semelMosad": 600007,
		"settlement": "אבו קרינאת (יישוב)",
		"manager": "חסן אלגנאמי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "אלמג'ד-ע\"ש המנוח אחמד אבו זאיד",
		"semelMosad": 418285,
		"settlement": "טירה",
		"manager": "הונידה סמארה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלמוג",
		"semelMosad": 241042,
		"settlement": "מעלה אדומים",
		"manager": "ציפי יעקב",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אלמוג",
		"semelMosad": 610030,
		"settlement": "אילת",
		"manager": "מולי ניר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אלמוגים",
		"semelMosad": 313684,
		"settlement": "קרית ים",
		"manager": "ליאורה אביב",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אלמוטראן תימותיוס",
		"semelMosad": 217554,
		"settlement": "כפר יאסיף",
		"manager": "נג'ם פרח",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלמוסתקבאל רוואד יסו",
		"semelMosad": 430348,
		"settlement": "ירושלים",
		"manager": "רודינה אל חטיב",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלמוסתקבל",
		"semelMosad": 418335,
		"settlement": "ג'לג'וליה",
		"manager": "פאתנה חגלה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלמוסתקבל",
		"semelMosad": 318188,
		"settlement": "כפר קרע",
		"manager": "מונתהא מסארוה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלמוסתקבל",
		"semelMosad": 618645,
		"settlement": "חורה",
		"manager": "פואד אבו קיעאן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "אלמוסתקבל אלאהלייה",
		"semelMosad": 267930,
		"settlement": "ירושלים",
		"manager": "ענאיה אבו סנינה",
		"level": "יסודי ועליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלמותנבי",
		"semelMosad": 218586,
		"settlement": "מג'ד אל-כרום",
		"manager": "מנאל זרקאוי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלמותנבי",
		"semelMosad": 317065,
		"settlement": "אום אל-פחם",
		"manager": "מונתהא אגבאריה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלמותנבי בסחנין",
		"semelMosad": 217315,
		"settlement": "סח'נין",
		"manager": "חמד סייד אחמד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלמחבה ע\"ש נגיב ניקולא",
		"semelMosad": 219220,
		"settlement": "אבו סנאן",
		"manager": "מונא ח'יר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "דרוזי"
	},
	{
		"schoolName": "אלמנאהל",
		"semelMosad": 217836,
		"settlement": "מג'דל שמס",
		"manager": "ג'האן ספדי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "דרוזי"
	},
	{
		"schoolName": "אלמנארה",
		"semelMosad": 618025,
		"settlement": "לקיה",
		"manager": "חמד אלאסד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "אלמנארה",
		"semelMosad": 520270,
		"settlement": "ירושלים",
		"manager": "הייסם חוסיין",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלמנארה -כסרא",
		"semelMosad": 218370,
		"settlement": "כסרא-סמיע",
		"manager": "מאלכ נסר אלדין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "דרוזי"
	},
	{
		"schoolName": "אלמנרה",
		"semelMosad": 348201,
		"settlement": "ג'סר א-זרקא",
		"manager": "שאדי עמאש",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלמסאר",
		"semelMosad": 318121,
		"settlement": "דאלית אל-כרמל",
		"manager": "רושדי חסון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "דרוזי"
	},
	{
		"schoolName": "אלמסתקבל",
		"semelMosad": 618496,
		"settlement": "שגב-שלום",
		"manager": "עבדאללה אלגרגאוי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "אלמסתקבל",
		"semelMosad": 618165,
		"settlement": "אעצם (שבט)",
		"manager": "קמאל אבו הדובה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "אלמעאלי",
		"semelMosad": 343913,
		"settlement": "כפר כנא",
		"manager": "מועין עואודה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלמעראג ע\"ש שיח' פראג",
		"semelMosad": 618843,
		"settlement": "לקיה",
		"manager": "מאג'ד אל אסד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "אלמתנבי",
		"semelMosad": 641407,
		"settlement": "ירושלים",
		"manager": "עאסם חסונה",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלנבראס",
		"semelMosad": 328187,
		"settlement": "זמר",
		"manager": "חאלד סורה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלנג'אח",
		"semelMosad": 648204,
		"settlement": "רהט",
		"manager": "סעיד אלקרינאוי",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "אלנג'אח",
		"semelMosad": 618777,
		"settlement": "ערערה-בנגב",
		"manager": "נביל אבו גאמע",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "אלנג'אח",
		"semelMosad": 611152,
		"settlement": "קצר א-סר",
		"manager": "אחמיד הואשלה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "אלנג'אח טכנולוגי פרדיס",
		"semelMosad": 348334,
		"settlement": "פוריידיס",
		"manager": "פראס עבדאללה כליל",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלנג'אח סכנין",
		"semelMosad": 219360,
		"settlement": "סח'נין",
		"manager": "אבראהים זבידאת",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלנהדה",
		"semelMosad": 228577,
		"settlement": "טמרה",
		"manager": "אפנאן עואד",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלנהדה",
		"semelMosad": 318147,
		"settlement": "ערערה",
		"manager": "סוהיל כבהא",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלנהדה ירכא",
		"semelMosad": 217448,
		"settlement": "ירכא",
		"manager": "פארס חרבאוי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "דרוזי"
	},
	{
		"schoolName": "אלנור",
		"semelMosad": 370544,
		"settlement": "ירכא",
		"manager": "ואיל מעדי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "דרוזי"
	},
	{
		"schoolName": "אלנור - ג'דידה",
		"semelMosad": 219469,
		"settlement": "ג'דיידה-מכר",
		"manager": "אמין כיאל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלנור מעון יום טמרה",
		"semelMosad": 228130,
		"settlement": "טמרה",
		"manager": "לינה אבו-אל היג'א",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלנור מעון יום ים",
		"semelMosad": 128017,
		"settlement": "ירושלים",
		"manager": "סנא מוסא",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלנור סכנין מעון יום",
		"semelMosad": 228205,
		"settlement": "סח'נין",
		"manager": "יוסף גנאים",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלנור ע\"ש אברהים",
		"semelMosad": 618330,
		"settlement": "שגב-שלום",
		"manager": "טלל אבו מעמר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "אלנור שפרעם",
		"semelMosad": 228254,
		"settlement": "שפרעם",
		"manager": "רבאב לוסיא דאמוני",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלנוריין",
		"semelMosad": 410571,
		"settlement": "ירושלים",
		"manager": "מוראד אנסארי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלסאלם",
		"semelMosad": 648337,
		"settlement": "חורה",
		"manager": "מתעב אבו אלקיעאן",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "אלסביל",
		"semelMosad": 219634,
		"settlement": "חורפיש",
		"manager": "עלי סלאמה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "דרוזי"
	},
	{
		"schoolName": "אלסביל",
		"semelMosad": 318535,
		"settlement": "דאלית אל-כרמל",
		"manager": "דליה נסראלדין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "דרוזי"
	},
	{
		"schoolName": "אלסדיק",
		"semelMosad": 641654,
		"settlement": "כסיפה",
		"manager": "",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "אלסהל",
		"semelMosad": 520320,
		"settlement": "ירושלים",
		"manager": "ענאן תותנגי",
		"level": "יסודי בלבד",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלסונה",
		"semelMosad": 239202,
		"settlement": "ירושלים",
		"manager": "ופאא גדה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלסופרא",
		"semelMosad": 616508,
		"settlement": "ירושלים",
		"manager": "רובא אבו דייה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלסייד",
		"semelMosad": 618090,
		"settlement": "אל סייד",
		"manager": "עבד אל חפד אל סייד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "אלסלאם",
		"semelMosad": 618413,
		"settlement": "תל שבע",
		"manager": "סאלם אבו עצא",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "אלסלאם",
		"semelMosad": 615294,
		"settlement": "סעווה",
		"manager": "יונס אל אטרש",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "אלסלאם",
		"semelMosad": 630012,
		"settlement": "אבו תלול",
		"manager": "ג'האן אבו הדובה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "אלסלאם",
		"semelMosad": 618504,
		"settlement": "ערערה-בנגב",
		"manager": "ג'הד אלטלקאת",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "אלסלאם",
		"semelMosad": 384040,
		"settlement": "ג'סר א-זרקא",
		"manager": "בהא אל דין ג'רבאן",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלסלאם סכנין",
		"semelMosad": 218719,
		"settlement": "סח'נין",
		"manager": "עבדאללה מיעארי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלסנאבל",
		"semelMosad": 618850,
		"settlement": "אום בטין",
		"manager": "מחמד אבו כף",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "אלסנדיאנה",
		"semelMosad": 744722,
		"settlement": "גבעת חביבה",
		"manager": "אנדירה חדיג'ה ביאדסה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלספא סכנין",
		"semelMosad": 219519,
		"settlement": "סח'נין",
		"manager": "טאהר אבו ריא",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלע'זאלי ע\"ש שייך אחמד אלטורי",
		"semelMosad": 618629,
		"settlement": "רהט",
		"manager": "סעד אבו מעדיאן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "אלעהד",
		"semelMosad": 618389,
		"settlement": "לקיה",
		"manager": "בסמה אלסאנע",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "אלעולא",
		"semelMosad": 642223,
		"settlement": "ירושלים",
		"manager": "רים עודה",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלעומריה",
		"semelMosad": 418384,
		"settlement": "רמלה",
		"manager": "עמאד אזברגה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלעזאזמה ב'",
		"semelMosad": 618108,
		"settlement": "ביר הדאג'",
		"manager": "סעיד אבו עפאש",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "אלעזאזמה ג'",
		"semelMosad": 618892,
		"settlement": "ביר הדאג'",
		"manager": "מוניר אבו חמאד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "אלעזיזה",
		"semelMosad": 460477,
		"settlement": "ירושלים",
		"manager": "נור חמידה",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלעטאא",
		"semelMosad": 338046,
		"settlement": "כפר קרע",
		"manager": "נרימאן מחאמיד",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלעטאונה",
		"semelMosad": 618066,
		"settlement": "חורה",
		"manager": "פארוק אלעטאונה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "אלעין",
		"semelMosad": 219246,
		"settlement": "דייר אל-אסד",
		"manager": "ח'אלד אל אסדי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלעין",
		"semelMosad": 318840,
		"settlement": "דאלית אל-כרמל",
		"manager": "עליה חלבי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "דרוזי"
	},
	{
		"schoolName": "אלעין",
		"semelMosad": 219212,
		"settlement": "עין מאהל",
		"manager": "סאיד חביב אללה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלעלא",
		"semelMosad": 148262,
		"settlement": "ירושלים",
		"manager": "סלאם עיד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלפא",
		"semelMosad": 634857,
		"settlement": "ירושלים",
		"manager": "אקרם אבראהים",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלפאראבי",
		"semelMosad": 235051,
		"settlement": "ריינה",
		"manager": "באסל מופלח טאטור",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלפארוק",
		"semelMosad": 318139,
		"settlement": "ג'ת",
		"manager": "אנית עתאמנה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלפארוק",
		"semelMosad": 511592,
		"settlement": "ירושלים",
		"manager": "נאדיה עווידה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלפוראת",
		"semelMosad": 618918,
		"settlement": "רהט",
		"manager": "עישה אל טורי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "אלפורעה ב",
		"semelMosad": 618769,
		"settlement": "דריג'את",
		"manager": "מחמד אבו עייאדה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "אלפורקא",
		"semelMosad": 338632,
		"settlement": "ירושלים",
		"manager": "פאטמה מוחמד עלי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלפורקאן",
		"semelMosad": 189910,
		"settlement": "ירושלים",
		"manager": "סוהיר מקאווי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלפנאר יסודי מעורב",
		"semelMosad": 347187,
		"settlement": "ירושלים",
		"manager": "סמאח עותמאן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלפקיה",
		"semelMosad": 522441,
		"settlement": "ירושלים",
		"manager": "רוזאן פקייה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלפרדוס",
		"semelMosad": 618744,
		"settlement": "רהט",
		"manager": "מחמוד אבו עאבד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "אלקאדיסיה",
		"semelMosad": 618405,
		"settlement": "שגב-שלום",
		"manager": "פאיזה אבו שריפה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "אלקבץ",
		"semelMosad": 213868,
		"settlement": "צפת",
		"manager": "אוריאל דבוש",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אלקבץ",
		"semelMosad": 213033,
		"settlement": "צפת",
		"manager": "אוריאל דבוש",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אלקיאן לקשר ותקשורת",
		"semelMosad": 384099,
		"settlement": "כפר קרע",
		"manager": "סאוסן ג'ראיסי",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלקימה",
		"semelMosad": 650028,
		"settlement": "ירושלים",
		"manager": "אמל עאמר",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלקלעה - קשלה ב'",
		"semelMosad": 219097,
		"settlement": "נצרת",
		"manager": "מונתהא עבאס",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלקנדיל",
		"semelMosad": 353169,
		"settlement": "ג'סר א-זרקא",
		"manager": "וג'וד דראוושה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלראזי",
		"semelMosad": 440693,
		"settlement": "ירושלים",
		"manager": "באסם דוויק",
		"level": "יסודי ועליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלראזי",
		"semelMosad": 618561,
		"settlement": "ערערה-בנגב",
		"manager": "עיד אבו עשיבה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "אלראזי",
		"semelMosad": 318667,
		"settlement": "באקה אל-גרביה",
		"manager": "מוניר ענאבוסי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלראזי",
		"semelMosad": 417170,
		"settlement": "לוד",
		"manager": "זהר אלזינאתי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלראזי ב",
		"semelMosad": 218610,
		"settlement": "נצרת",
		"manager": "שירין שחאדה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלראזי טמרה",
		"semelMosad": 219451,
		"settlement": "טמרה",
		"manager": "סמאח עואד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלראזי כפר מנדא(ד')",
		"semelMosad": 219667,
		"settlement": "כפר מנדא",
		"manager": "מחמוד זועבי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלראזי- ג'דידה",
		"semelMosad": 218123,
		"settlement": "ג'דיידה-מכר",
		"manager": "חסן ח'רינו",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלרואד",
		"semelMosad": 618546,
		"settlement": "כסיפה",
		"manager": "ווליד אבו חאמד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "אלרואד יסודי",
		"semelMosad": 362186,
		"settlement": "שפרעם",
		"manager": "ראשא שלון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלרחמה",
		"semelMosad": 618512,
		"settlement": "רהט",
		"manager": "סלטאן אלעמור",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "אלרחמה",
		"semelMosad": 328096,
		"settlement": "באקה אל-גרביה",
		"manager": "רים קעדאן",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלרחמה-ג'דידה",
		"semelMosad": 228296,
		"settlement": "ג'דיידה-מכר",
		"manager": "דלאל מלחם",
		"level": "יסודי חט\"ב ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלרסאלה",
		"semelMosad": 630145,
		"settlement": "רהט",
		"manager": "פאיק חסנאת",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "אלרסאלה",
		"semelMosad": 618421,
		"settlement": "תל שבע",
		"manager": "סאלם אלאעסם",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "אלרשיד",
		"semelMosad": 338731,
		"settlement": "אום אל-פחם",
		"manager": "סמאהר אגבאריה",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלשאטאא",
		"semelMosad": 318097,
		"settlement": "ג'סר א-זרקא",
		"manager": "עבד גרבאן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלשאפעי",
		"semelMosad": 410449,
		"settlement": "קלנסווה",
		"manager": "ראבעה זמירו",
		"level": "יסודי בלבד",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלשאפעי",
		"semelMosad": 318402,
		"settlement": "באקה אל-גרביה",
		"manager": "ג'מאל אשקר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלשריף",
		"semelMosad": 219113,
		"settlement": "טמרה",
		"manager": "ערין מג'דוב",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלתורי לבנות",
		"semelMosad": 118299,
		"settlement": "ירושלים",
		"manager": "סועאד עבאסי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אלתסאמוח ע\"ש כמאל ג'ונבלאט",
		"semelMosad": 217299,
		"settlement": "אבו סנאן",
		"manager": "ריאד עזאם",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "דרוזי"
	},
	{
		"schoolName": "אמונה אולפנית טכנולוגית",
		"semelMosad": 541862,
		"settlement": "בני ברק",
		"manager": "מיכל פרנק",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אמונים מד",
		"semelMosad": 510362,
		"settlement": "גבעתיים",
		"manager": "אור יזדי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אמונת ציון",
		"semelMosad": 359224,
		"settlement": "ירושלים",
		"manager": "איילה אלטה צלר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אמי\"ת אורגד",
		"semelMosad": 530048,
		"settlement": "נתניה",
		"manager": "אורית רחל מילגרום",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אמי\"ת איתן",
		"semelMosad": 160549,
		"settlement": "מעלה אדומים",
		"manager": "דניאל יעקב בארי",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אמי\"ת בנות מודיעין",
		"semelMosad": 442780,
		"settlement": "מודיעין-מכבים-רעות",
		"manager": "ליאת כהן וגשל",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אמי\"ת בנים מודיעין",
		"semelMosad": 450270,
		"settlement": "מודיעין-מכבים-רעות",
		"manager": "ליאור הלוי",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אמי\"ת גוש דן",
		"semelMosad": 571109,
		"settlement": "רמת גן",
		"manager": "תומר שופר",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אמי\"ת דביר בנים",
		"semelMosad": 338418,
		"settlement": "בית שמש",
		"manager": "אברהם שושן",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אמי\"ת המר רחובות",
		"semelMosad": 444364,
		"settlement": "רחובות",
		"manager": "רפאל מימון",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אמי\"ת טכנולוגי דתי",
		"semelMosad": 170456,
		"settlement": "ירושלים",
		"manager": "אלישע פלג",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אמי\"ת כפר בתיה",
		"semelMosad": 480012,
		"settlement": "רעננה",
		"manager": "אבינועם אלמגור",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אמי\"ת מנורת המאור פ\"ת",
		"semelMosad": 472555,
		"settlement": "פתח תקווה",
		"manager": "אילן חממי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אמי\"ת עמיטל",
		"semelMosad": 413260,
		"settlement": "נתניה",
		"manager": "יפית מזרחי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אמי\"ת פתח-תקוה",
		"semelMosad": 480020,
		"settlement": "פתח תקווה",
		"manager": "מאיה אביבי",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אמי\"ת שחר",
		"semelMosad": 140202,
		"settlement": "בית שמש",
		"manager": "אלינור ביטון",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אמי\"ת תורני מדעי",
		"semelMosad": 244103,
		"settlement": "כרמיאל",
		"manager": "גאי חיים דקל",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אמיר",
		"semelMosad": 414433,
		"settlement": "פתח תקווה",
		"manager": "טלי פארי-תשובה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אמירה בסמא",
		"semelMosad": 178137,
		"settlement": "ירושלים",
		"manager": "שירין סעיד",
		"level": "יסודי ועליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אמירים",
		"semelMosad": 482661,
		"settlement": "באר יעקב",
		"manager": "נעה חייט",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אמירים",
		"semelMosad": 620443,
		"settlement": "ירושלים",
		"manager": "נטע בראל",
		"level": "יסודי בלבד",
		"special": "מיוחד",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אמירים",
		"semelMosad": 620377,
		"settlement": "אשקלון",
		"manager": "מימי קולקטאר",
		"level": "יסודי וחט\"ב",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אמירים",
		"semelMosad": 582015,
		"settlement": "נתניה",
		"manager": "טל פלוטקין",
		"level": "יסודי וחט\"ב",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אמירים",
		"semelMosad": 514125,
		"settlement": "חולון",
		"manager": "שגית אתי בן חמו-גמליאל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אמירים",
		"semelMosad": 614040,
		"settlement": "אשדוד",
		"manager": "כרמית שני",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אמירים",
		"semelMosad": 512798,
		"settlement": "רמת השרון",
		"manager": "לילך חפץ עבודי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אמירים",
		"semelMosad": 482877,
		"settlement": "גני תקווה",
		"manager": "הדר צדיק",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אמירים",
		"semelMosad": 312173,
		"settlement": "קרית ים",
		"manager": "אסנת שואף",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אמירים",
		"semelMosad": 313478,
		"settlement": "בנימינה-גבעת עדה",
		"manager": "איילת ארבל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אמירים תל\"י",
		"semelMosad": 612275,
		"settlement": "באר שבע",
		"manager": "ירונה פרץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אמית אורי",
		"semelMosad": 338616,
		"settlement": "מעלה אדומים",
		"manager": "רוחמה ווגל",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אמית בר אילן",
		"semelMosad": 453605,
		"settlement": "נתניה",
		"manager": "אליהו-שמואל ניסנהולץ",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אמית מדעי תורני כפר בתיה",
		"semelMosad": 442376,
		"settlement": "רעננה",
		"manager": "יונתן ברלין",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אמרי בינה",
		"semelMosad": 142216,
		"settlement": "בית שמש",
		"manager": "יוסף חיים תייר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אמרי דוד",
		"semelMosad": 442541,
		"settlement": "אלעד",
		"manager": "ישראל טחלוב",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אמרי דוד",
		"semelMosad": 515452,
		"settlement": "תל אביב - יפו",
		"manager": "אהרון-חי טוויג",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אמרי חיים",
		"semelMosad": 112615,
		"settlement": "ירושלים",
		"manager": "ישעיהו יעקב ורטהימר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אמרי חיים ויזניץ",
		"semelMosad": 712794,
		"settlement": "בית שמש",
		"manager": "יצחק אייזיק רובין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אמרי מלך",
		"semelMosad": 412247,
		"settlement": "ירושלים",
		"manager": "דב מאיר מילר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אמרי מרדכי",
		"semelMosad": 442152,
		"settlement": "אלעד",
		"manager": "מאיר אבוחצירה",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אמרי משה",
		"semelMosad": 732024,
		"settlement": "ירושלים",
		"manager": "יהושוע מאיר מישקובסקי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אמרי צבי",
		"semelMosad": 441964,
		"settlement": "מודיעין עילית",
		"manager": "יוסף שלמה הקשר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אמת ואמונה",
		"semelMosad": 513762,
		"settlement": "בני ברק",
		"manager": "אלתר חיים ברנשטיין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אמת ואמונה חניכי הישיבות",
		"semelMosad": 610105,
		"settlement": "קרית גת",
		"manager": "ישראל גדליהו ולעס",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אמת ואמונה-סלונים",
		"semelMosad": 112680,
		"settlement": "ירושלים",
		"manager": "אברהם לוריא",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אמת ושלום",
		"semelMosad": 613778,
		"settlement": "קרית גת",
		"manager": "חגי יהודה אברגיל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אנוך",
		"semelMosad": 520171,
		"settlement": "תל אביב - יפו",
		"manager": "עליזה יעלה סלע",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אנקורי",
		"semelMosad": 444687,
		"settlement": "ראשון לציון",
		"manager": "משה גרדוס",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אנקורי",
		"semelMosad": 540807,
		"settlement": "תל אביב - יפו",
		"manager": "סיון ליפשיץ",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אנקורי",
		"semelMosad": 141978,
		"settlement": "ירושלים",
		"manager": "בועז עילם",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אנקורי סניף רעננה",
		"semelMosad": 441188,
		"settlement": "רעננה",
		"manager": "יואב לביא",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אנקורי פתח תקווה",
		"semelMosad": 442004,
		"settlement": "פתח תקווה",
		"manager": "יוסף שפרבר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אנקורי קרית ביאליק",
		"semelMosad": 340778,
		"settlement": "קרית ביאליק",
		"manager": "ורדית הלוי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אנתרופוסופי",
		"semelMosad": 581363,
		"settlement": "פתח תקווה",
		"manager": "אמירה רחל טייכנר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אסיף",
		"semelMosad": 420133,
		"settlement": "באר יעקב",
		"manager": "מילכה מלכה רוזן",
		"level": "יסודי חט\"ב ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אסיף",
		"semelMosad": 131300,
		"settlement": "מודיעין-מכבים-רעות",
		"manager": "ענת גדליהו",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אסיף משגב",
		"semelMosad": 240481,
		"settlement": "עצמון שגב",
		"manager": "דוד פרידמן",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אסף-מימון",
		"semelMosad": 611392,
		"settlement": "אשקלון",
		"manager": "מאיר אברגל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אפאק",
		"semelMosad": 348102,
		"settlement": "אום אל-פחם",
		"manager": "ג'האן מחאמיד",
		"level": "חט\"ב + עליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אפאק",
		"semelMosad": 616227,
		"settlement": "שגב-שלום",
		"manager": "פאוזי אלסופי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "אפאק",
		"semelMosad": 627067,
		"settlement": "תל שבע",
		"manager": "עאיש אבו עמרה",
		"level": "יסודי חט\"ב ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "אפיק",
		"semelMosad": 526970,
		"settlement": "פתח תקווה",
		"manager": "שרית אורן",
		"level": "יסודי חט\"ב ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אפיק",
		"semelMosad": 614057,
		"settlement": "באר שבע",
		"manager": "איילת טסלר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אפיק נהריה",
		"semelMosad": 221937,
		"settlement": "נהריה",
		"manager": "יעל קופל",
		"level": "יסודי חט\"ב ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אפיקי מים בנות",
		"semelMosad": 633834,
		"settlement": "נתיבות",
		"manager": "חפציבה מרים מלכה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אפיקי-ירדן",
		"semelMosad": 210120,
		"settlement": "אפיקים",
		"manager": "דקלה פרץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אפיקים",
		"semelMosad": 412197,
		"settlement": "ירושלים",
		"manager": "אסתר בלומה ארנסטר",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אפיקים בנגב",
		"semelMosad": 620146,
		"settlement": "קוממיות",
		"manager": "יהושע בצלאל ונגר",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אפיקים בנגב",
		"semelMosad": 614602,
		"settlement": "באר שבע",
		"manager": "יעל זיו",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אפיקים סולם",
		"semelMosad": 162628,
		"settlement": "ירושלים",
		"manager": "דבורה פליגלמן",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אפק-יסודי",
		"semelMosad": 210138,
		"settlement": "אפק",
		"manager": "יריב גל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אפרים בן דוד-ז\"ל",
		"semelMosad": 612028,
		"settlement": "קרית גת",
		"manager": "קרן בן אבי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אפרתה",
		"semelMosad": 112227,
		"settlement": "ירושלים",
		"manager": "גילה סדון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אקדמיה למוסיקה",
		"semelMosad": 140053,
		"settlement": "ירושלים",
		"manager": "טל דברה",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אקדמיה ע ש רובין",
		"semelMosad": 112243,
		"settlement": "ירושלים",
		"manager": "טל דברה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אקדמיית אלמסתקבאל",
		"semelMosad": 419242,
		"settlement": "ירושלים",
		"manager": "רתיבה פראח",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אקליריקיה נצרת",
		"semelMosad": 217067,
		"settlement": "נצרת",
		"manager": "הנאדה טנוס שמשום",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אקרא ע\"ש עאמר צאנע",
		"semelMosad": 648261,
		"settlement": "לקיה",
		"manager": "מוסא אלצאנע",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "ארבל מד",
		"semelMosad": 212589,
		"settlement": "נוף הגליל",
		"manager": "אבידן מעודה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ארגמן",
		"semelMosad": 416651,
		"settlement": "נס ציונה",
		"manager": "תמר דוד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ארגנטינה",
		"semelMosad": 110312,
		"settlement": "ירושלים",
		"manager": "גילי טלמור דוד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ארזים",
		"semelMosad": 425900,
		"settlement": "ראשון לציון",
		"manager": "טלי וולף-צוקרמן",
		"level": "יסודי חט\"ב ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ארזים",
		"semelMosad": 120303,
		"settlement": "ירושלים",
		"manager": "ראובן בבאין",
		"level": "יסודי בלבד",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ארזים",
		"semelMosad": 512905,
		"settlement": "תל אביב - יפו",
		"manager": "אורי פרלמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ארזים",
		"semelMosad": 613208,
		"settlement": "אשדוד",
		"manager": "אושרית זוהר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ארזים -מעלות תרשיחא",
		"semelMosad": 211375,
		"settlement": "מעלות-תרשיחא",
		"manager": "רונית מרום",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ארזים רמת ישי",
		"semelMosad": 212217,
		"settlement": "רמת ישי",
		"manager": "אורית קובו הייט",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אריאל",
		"semelMosad": 112185,
		"settlement": "ירושלים",
		"manager": "טליה ברוך",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אריאל",
		"semelMosad": 614123,
		"settlement": "אשדוד",
		"manager": "יפה חזות",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אריאל שרון",
		"semelMosad": 482174,
		"settlement": "ראשון לציון",
		"manager": "אילנה רות חנני",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אריק איינשטיין",
		"semelMosad": 512608,
		"settlement": "פתח תקווה",
		"manager": "פזית רייכר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ארלוזורוב",
		"semelMosad": 310235,
		"settlement": "חדרה",
		"manager": "בטינה גולדמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ארלוזורוב",
		"semelMosad": 610162,
		"settlement": "אשקלון",
		"manager": "אריאל סעדון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ארלוזורוב",
		"semelMosad": 310391,
		"settlement": "חיפה",
		"manager": "חני סביון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ארליך - טבריה",
		"semelMosad": 210690,
		"settlement": "טבריה",
		"manager": "סורית אור קבבגי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ארן",
		"semelMosad": 513077,
		"settlement": "תל אביב - יפו",
		"manager": "שרון דיין בן מאיר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ארנון",
		"semelMosad": 573063,
		"settlement": "תל אביב - יפו",
		"manager": "נעה שריד הראל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ארנונה",
		"semelMosad": 647552,
		"settlement": "ירושלים",
		"manager": "קרן סאייג",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ארץ הצבי",
		"semelMosad": 160598,
		"settlement": "ירושלים",
		"manager": "יוסף חיים גרינפלד",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ארץ יהודה",
		"semelMosad": 470013,
		"settlement": "כפר זוהרים",
		"manager": "יוחנן כהן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ארתיקאא לפות",
		"semelMosad": 616961,
		"settlement": "ירושלים",
		"manager": "מלכה עזאם",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אש דת",
		"semelMosad": 613521,
		"settlement": "אשדוד",
		"manager": "משולם פיש גרינבוים",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אשבאל אלקודס",
		"semelMosad": 617019,
		"settlement": "ירושלים",
		"manager": "מחמד הרבוק",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "אשירה",
		"semelMosad": 630194,
		"settlement": "ירושלים",
		"manager": "תחיה וולך",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אשירה בנות",
		"semelMosad": 649962,
		"settlement": "בית שמש",
		"manager": "בטחה מרים אורן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אשירה-שיח סוד",
		"semelMosad": 455147,
		"settlement": "ירושלים",
		"manager": "עליזה חודר",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אשכול",
		"semelMosad": 413161,
		"settlement": "לוד",
		"manager": "גלית גרשום אברהם",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אשכול",
		"semelMosad": 411264,
		"settlement": "נס ציונה",
		"manager": "אהובי-גלאור משה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אשכול",
		"semelMosad": 614313,
		"settlement": "אשדוד",
		"manager": "רינת ברקוביץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אשכול",
		"semelMosad": 613315,
		"settlement": "באר שבע",
		"manager": "אושרית פרץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אשכול עכו",
		"semelMosad": 213751,
		"settlement": "עכו",
		"manager": "סילביה חסיבה ווקנין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אשכולות",
		"semelMosad": 413401,
		"settlement": "ראשון לציון",
		"manager": "שירי אדרי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אשכולות",
		"semelMosad": 310078,
		"settlement": "בנימינה-גבעת עדה",
		"manager": "פרדי גרוס",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אשל הנשיא",
		"semelMosad": 680025,
		"settlement": "אשל הנשיא",
		"manager": "בת חן פלד כהן",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אשלים",
		"semelMosad": 513713,
		"settlement": "חולון",
		"manager": "טוני סאסי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אשלים",
		"semelMosad": 610063,
		"settlement": "אופקים",
		"manager": "עינת ביטון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אשרינו",
		"semelMosad": 721563,
		"settlement": "ירושלים",
		"manager": "מיכל רדליך",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אתרוג בנות",
		"semelMosad": 582510,
		"settlement": "גבעת זאב",
		"manager": "מרים אריאלה מתתיהו",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "אתרוג בנים",
		"semelMosad": 112490,
		"settlement": "גבעת זאב",
		"manager": "אריה יצחק וולף",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ב\"ח הרצוג",
		"semelMosad": 620393,
		"settlement": "ירושלים",
		"manager": "מיכל יאיר",
		"level": "יסודי חט\"ב ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ב\"י אהבת שלום",
		"semelMosad": 312926,
		"settlement": "זכרון יעקב",
		"manager": "מלכה אילה דומיניץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ב\"י אהלי שמעון",
		"semelMosad": 514034,
		"settlement": "בני ברק",
		"manager": "רוחמה אלפא",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ב\"י אוהל אסתר",
		"semelMosad": 614800,
		"settlement": "אשדוד",
		"manager": "טובה חנה הוס",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ב\"י אוהל חסיה טלזסטו",
		"semelMosad": 112326,
		"settlement": "קרית יערים",
		"manager": "שינא גיטל זלזניק",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ב\"י אוהלי אליעזר",
		"semelMosad": 512103,
		"settlement": "בני ברק",
		"manager": "נעמי(דבורה) כץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ב\"י אוהלי ספר",
		"semelMosad": 114314,
		"settlement": "מודיעין עילית",
		"manager": "מרים פוזן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ב\"י באר יעקב",
		"semelMosad": 412429,
		"settlement": "באר יעקב",
		"manager": "צפורה הרציג",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ב\"י בובוב",
		"semelMosad": 514133,
		"settlement": "בת ים",
		"manager": "שרה לאה טויבה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ב\"י בית דוד חולון",
		"semelMosad": 513432,
		"settlement": "חולון",
		"manager": "אנט שטרית",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ב\"י בית הילד",
		"semelMosad": 512301,
		"settlement": "תל אביב - יפו",
		"manager": "רחל פריד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ב\"י בית חנה",
		"semelMosad": 759092,
		"settlement": "נתניה",
		"manager": "סימה צורטל שווארץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ב\"י בית מרגלית",
		"semelMosad": 131227,
		"settlement": "ירושלים",
		"manager": "טובה רוחמה העברי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ב\"י ברסלב באר מרים",
		"semelMosad": 165126,
		"settlement": "ירושלים",
		"manager": "גולדה וינשטוק",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ב\"י גור חיפה",
		"semelMosad": 712695,
		"settlement": "חיפה",
		"manager": "רחל מרים ויצמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ב\"י גור רובע ז'",
		"semelMosad": 612648,
		"settlement": "אשדוד",
		"manager": "יהודית פייבוש",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ב\"י דרך החיים",
		"semelMosad": 113761,
		"settlement": "ירושלים",
		"manager": "שושנה קוט",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ב\"י האחוד החסידי",
		"semelMosad": 612838,
		"settlement": "אשדוד",
		"manager": "רבקה וורנר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ב\"י הגבעה המזרחית",
		"semelMosad": 430520,
		"settlement": "מודיעין עילית",
		"manager": "תמר בלה מונק",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ב\"י החדש",
		"semelMosad": 338285,
		"settlement": "אלעד",
		"manager": "דינה אטינגר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ב\"י הצפון",
		"semelMosad": 111641,
		"settlement": "ירושלים",
		"manager": "שרה מרים זוננפלד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ב\"י הרב כהנמן",
		"semelMosad": 514620,
		"settlement": "בני ברק",
		"manager": "מרים גורביץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ב\"י הרמה",
		"semelMosad": 165605,
		"settlement": "בית שמש",
		"manager": "בתיה וייס",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ב\"י ויזניץ בית לאה",
		"semelMosad": 512640,
		"settlement": "בני ברק",
		"manager": "פערל פלדמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ב\"י זכרון מאיר ב\"ב",
		"semelMosad": 512152,
		"settlement": "בני ברק",
		"manager": "שרה פוברסקי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ב\"י חורב אשדוד",
		"semelMosad": 611947,
		"settlement": "אשדוד",
		"manager": "עטרה קצנלבוגן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ב\"י חסידי אידיש",
		"semelMosad": 284042,
		"settlement": "ביתר עילית",
		"manager": "אסתר מלכה קירשנבוים",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ב\"י חסידי ירושלים",
		"semelMosad": 113332,
		"settlement": "ירושלים",
		"manager": "חיה תחיה וייס",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ב\"י חסידי רמות",
		"semelMosad": 131060,
		"settlement": "ירושלים",
		"manager": "לאה-ברינה קופרברג",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ב\"י יהודה הנשיא",
		"semelMosad": 512848,
		"settlement": "בני ברק",
		"manager": "אסתר הוכלרר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ב\"י יסודי רכסים",
		"semelMosad": 312314,
		"settlement": "רכסים",
		"manager": "תחיה מרים קרמר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ב\"י ישמח משה",
		"semelMosad": 413112,
		"settlement": "גני תקווה",
		"manager": "מרים קסלר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ב\"י מאור חיים",
		"semelMosad": 214064,
		"settlement": "צפת",
		"manager": "רחל חנה ברלינסקי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ב\"י מכתב מאליהו",
		"semelMosad": 514018,
		"settlement": "אור יהודה",
		"manager": "פנינה טבצ'ניק",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ב\"י מנוחה ונחלה",
		"semelMosad": 114348,
		"settlement": "בית שמש",
		"manager": "מירה פורוש",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ב\"י מסורת ישראל",
		"semelMosad": 514331,
		"settlement": "בני ברק",
		"manager": "שרה ליברמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ב\"י מעלה עמוס",
		"semelMosad": 112235,
		"settlement": "מעלה עמוס",
		"manager": "רות שושן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ב\"י מעלות חיה",
		"semelMosad": 613950,
		"settlement": "אופקים",
		"manager": "סימה צביה לוי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ב\"י משכנות תמר אחוזת ברכפלד",
		"semelMosad": 199539,
		"settlement": "מודיעין עילית",
		"manager": "נחמה כהן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ב\"י נאות הפסגה",
		"semelMosad": 511428,
		"settlement": "מודיעין עילית",
		"manager": "טובה ליבוביץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ב\"י נאות רחל",
		"semelMosad": 267922,
		"settlement": "ביתר עילית",
		"manager": "אורה קרח",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ב\"י נוה שלום רמלה",
		"semelMosad": 412692,
		"settlement": "רמלה",
		"manager": "רחל זילברברג",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ב\"י נצח ישראל",
		"semelMosad": 611277,
		"settlement": "אשקלון",
		"manager": "יהושע בוכניק",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ב\"י נר שמואל",
		"semelMosad": 311738,
		"settlement": "חדרה",
		"manager": "ישראל אברהם שחטר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ב\"י נר שמואל",
		"semelMosad": 722785,
		"settlement": "חדרה",
		"manager": "חוה פכטהלט",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ב\"י נתיבות נוה יעקב",
		"semelMosad": 611822,
		"settlement": "נתיבות",
		"manager": "ברוריה קדוש",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ב\"י סיני מעלות רחל",
		"semelMosad": 612168,
		"settlement": "אופקים",
		"manager": "פיבי אמסלם",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ב\"י עזרת תורה",
		"semelMosad": 112540,
		"settlement": "ירושלים",
		"manager": "צפורה קאופמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ב\"י עטרת שיח",
		"semelMosad": 132282,
		"settlement": "ביתר עילית",
		"manager": "פסיה לודמיר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ב\"י עכו ציבורי",
		"semelMosad": 212530,
		"settlement": "עכו",
		"manager": "דינה מזרחי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ב\"י קוממיות",
		"semelMosad": 611319,
		"settlement": "קוממיות",
		"manager": "נעמי זר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ב\"י קרית הרים לוין",
		"semelMosad": 512350,
		"settlement": "תל אביב - יפו",
		"manager": "רות ברלינר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ב\"י ראשל\"צ",
		"semelMosad": 412643,
		"settlement": "ראשון לציון",
		"manager": "ליבע אסתר שפירא",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ב\"י רזאל",
		"semelMosad": 414128,
		"settlement": "ראשון לציון",
		"manager": "יהודית יפרח",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ב\"י רמת אהרון",
		"semelMosad": 513267,
		"settlement": "בני ברק",
		"manager": "בתיה ארלנגר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ב\"י רמת אשכול נוה אחיעזר",
		"semelMosad": 512186,
		"settlement": "בני ברק",
		"manager": "חדוה טביב",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ב\"י רמת שלמה",
		"semelMosad": 124149,
		"settlement": "ירושלים",
		"manager": "אילה רוטנברג",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ב\"י שערי שמחה",
		"semelMosad": 214643,
		"settlement": "נהריה",
		"manager": "מרים ליס",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ב\"י תבונת יעקב",
		"semelMosad": 160614,
		"settlement": "ירושלים",
		"manager": "רחל שור",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ב\"ס חנוך מיוחד-מולדה",
		"semelMosad": 640144,
		"settlement": "סעווה",
		"manager": "אברהים אבו כף",
		"level": "יסודי חט\"ב ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "ב\"ס טכנולוגי ב.חנינה",
		"semelMosad": 661884,
		"settlement": "ירושלים",
		"manager": "האני סנדוקה",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "ב\"ס טכנולוגי נעמת",
		"semelMosad": 278275,
		"settlement": "טמרה",
		"manager": "עאידה עואד",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "ב\"ס יסודי סווא רבינא",
		"semelMosad": 560383,
		"settlement": "ירושלים",
		"manager": "אמל עסילה",
		"level": "יסודי ועליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "ב. יעקב בנות כה תאמר",
		"semelMosad": 610626,
		"settlement": "קרית גת",
		"manager": "חגית אריאלי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ב.חינוך לבנות באידיש",
		"semelMosad": 131037,
		"settlement": "מודיעין עילית",
		"manager": "רוזי רייזל בירנבאום",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "באבוב",
		"semelMosad": 513911,
		"settlement": "בני ברק",
		"manager": "משה בן-ציון לנדאו",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "באבוב",
		"semelMosad": 112755,
		"settlement": "ירושלים",
		"manager": "יצחק משולם פרוכטהנדלר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "באר",
		"semelMosad": 630244,
		"settlement": "ירושלים",
		"manager": "אורית לסר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "באר אברהם",
		"semelMosad": 540708,
		"settlement": "אלעד",
		"manager": "אברהם לידר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "באר החיים",
		"semelMosad": 632034,
		"settlement": "מעלות-תרשיחא",
		"manager": "נחמה רחל מאיר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "באר החיים",
		"semelMosad": 499590,
		"settlement": "מודיעין עילית",
		"manager": "אברהם איצקוביץ",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "באר החיים",
		"semelMosad": 214312,
		"settlement": "מעלות-תרשיחא",
		"manager": "בני אהרון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "באר יהושע",
		"semelMosad": 514091,
		"settlement": "בני ברק",
		"manager": "ישראל קלמן פניאל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "באר מרים",
		"semelMosad": 513895,
		"settlement": "בני ברק",
		"manager": "אבלין פוזן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "באר מרים",
		"semelMosad": 384198,
		"settlement": "חדרה",
		"manager": "מיכל-אסתר מור",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "באר מרים",
		"semelMosad": 610824,
		"settlement": "נתיבות",
		"manager": "יפית מזייאנה אטלן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "באר-חייל ביר הדג'ג",
		"semelMosad": 618595,
		"settlement": "ביר הדאג'",
		"manager": "עיד אבן חזאעל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "בארי",
		"semelMosad": 411371,
		"settlement": "נתניה",
		"manager": "עמליה אמיר הולובינגר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בארי",
		"semelMosad": 610246,
		"settlement": "באר שבע",
		"manager": "שמחה לביא",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בארי",
		"semelMosad": 411876,
		"settlement": "ראשון לציון",
		"manager": "חגית בר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בארי בשדה",
		"semelMosad": 482331,
		"settlement": "רחובות",
		"manager": "שלמה אלבז",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בבלי",
		"semelMosad": 513051,
		"settlement": "תל אביב - יפו",
		"manager": "דנה אייוון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בדרך אבותינו",
		"semelMosad": 430223,
		"settlement": "בית שמש",
		"manager": "שמואל איידנסון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בדרך אימותינו",
		"semelMosad": 478784,
		"settlement": "ביתר עילית",
		"manager": "חיה פרימן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בדרך אל בי\"ס בטב\"ע",
		"semelMosad": 442442,
		"settlement": "בית עובד",
		"manager": "עדי בוטבין-דיסקין",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בה\"ס הניסויי תפן",
		"semelMosad": 349910,
		"settlement": "כפר ורדים",
		"manager": "גלי רז",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בה\"ס נעמי שמר",
		"semelMosad": 210955,
		"settlement": "כנרת (קבוצה)",
		"manager": "רינת בר חיים",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בה\"ס סאמי עבדאלסלאם",
		"semelMosad": 218552,
		"settlement": "נחף",
		"manager": "אשרף פטום",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "בוסתן במדבר",
		"semelMosad": 741009,
		"settlement": "ניצנה (קהילת חינוך)",
		"manager": "מילי רומי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בורוכוב",
		"semelMosad": 510313,
		"settlement": "גבעתיים",
		"manager": "מרים לוקסנבורג",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בי סמינר רמות שמסיאב",
		"semelMosad": 141861,
		"settlement": "ירושלים",
		"manager": "יסמינה שמילה",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בי\" סולם יעקב",
		"semelMosad": 646562,
		"settlement": "בית שמש",
		"manager": "צפורה שפירא",
		"level": "יסודי בלבד",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בי\"ח אסותא",
		"semelMosad": 620468,
		"settlement": "אשדוד",
		"manager": "ירדנה אלדר",
		"level": "יסודי חט\"ב ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בי\"ח גהה נעורים",
		"semelMosad": 420521,
		"settlement": "פתח תקווה",
		"manager": "נעה עופר",
		"level": "יסודי חט\"ב ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בי\"ח הדסה - מ. ילדים",
		"semelMosad": 120105,
		"settlement": "ירושלים",
		"manager": "מירה אסולין",
		"level": "יסודי חט\"ב ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בי\"ח מאיר מח ילדים",
		"semelMosad": 420448,
		"settlement": "כפר סבא",
		"manager": "ליאורה פרלברג",
		"level": "יסודי חט\"ב ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בי\"ח פורייה",
		"semelMosad": 645499,
		"settlement": "פוריה עילית",
		"manager": "גליה רונן",
		"level": "יסודי וחט\"ב",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בי\"ס אזורי חנ\"מ שחף",
		"semelMosad": 225888,
		"settlement": "דגניה א'",
		"manager": "בקי חפץ",
		"level": "יסודי בלבד",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בי\"ס איריס",
		"semelMosad": 643189,
		"settlement": "בת ים",
		"manager": "צפורה ברכה אינדיק",
		"level": "יסודי בלבד",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בי\"ס אלכרום",
		"semelMosad": 422592,
		"settlement": "מג'ד אל-כרום",
		"manager": "מונא שעבאן חלאילי",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "בי\"ס אלמוסתקבל",
		"semelMosad": 747345,
		"settlement": "ירושלים",
		"manager": "מלכה אבו עומר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "בי\"ס אלמטאר",
		"semelMosad": 746735,
		"settlement": "ירושלים",
		"manager": "עביר עלקם",
		"level": "יסודי ועליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "בי\"ס אלראזי",
		"semelMosad": 217372,
		"settlement": "בועיינה-נוג'ידאת",
		"manager": "טאלב חמודה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "בי\"ס אלראזי",
		"semelMosad": 374751,
		"settlement": "פוריידיס",
		"manager": "רינה מרעי",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "בי\"ס אמריקאי בנלאומי",
		"semelMosad": 770529,
		"settlement": "אבן יהודה",
		"manager": "מייקל דאונס",
		"level": "יסודי ועליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בי\"ס אשרקאת אלג'ד",
		"semelMosad": 499897,
		"settlement": "ירושלים",
		"manager": "מאגדה חדר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "בי\"ס בבי\"ח לוינשטיין",
		"semelMosad": 421123,
		"settlement": "רעננה",
		"manager": "דפנה מנס",
		"level": "יסודי חט\"ב ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בי\"ס בינלאומי למזרח ים תיכון",
		"semelMosad": 476325,
		"settlement": "רמת השרון",
		"manager": "הדס-יפה גוטליב בורשטין",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בי\"ס בית אקשטיין",
		"semelMosad": 620435,
		"settlement": "אופקים",
		"manager": "אבייה אזולאי",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בי\"ס בית אקשטיין",
		"semelMosad": 421180,
		"settlement": "יד רמב\"ם",
		"manager": "דניאל עוזיה",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בי\"ס בית אקשטיין",
		"semelMosad": 338384,
		"settlement": "פרדס חנה-כרכור",
		"manager": "לאורה כהן",
		"level": "יסודי בלבד",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בי\"ס בית אקשטיין",
		"semelMosad": 320440,
		"settlement": "פרדס חנה-כרכור",
		"manager": "יעל עמילני",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בי\"ס בית אקשטיין",
		"semelMosad": 520262,
		"settlement": "גבעתיים",
		"manager": "אייל הופמן",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בי\"ס בית אקשטיין",
		"semelMosad": 320200,
		"settlement": "גן שמואל",
		"manager": "דקלה וייצמן מן",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בי\"ס בית אקשטיין",
		"semelMosad": 442350,
		"settlement": "מדרשת רופין",
		"manager": "רונן רגב",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בי\"ס בית אקשטיין",
		"semelMosad": 420786,
		"settlement": "נעורים",
		"manager": "דפנה אבן",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בי\"ס בנות תורת חיים",
		"semelMosad": 442509,
		"settlement": "יד בנימין",
		"manager": "עמליה הלל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בי\"ס דו-לשוני",
		"semelMosad": 162594,
		"settlement": "ירושלים",
		"manager": "ג'והינה דכוור סלים",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בי\"ס הלן קלר",
		"semelMosad": 328179,
		"settlement": "ירושלים",
		"manager": "איברהים פלתס",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "בי\"ס העמק",
		"semelMosad": 220509,
		"settlement": "עפולה",
		"manager": "יעקב פרידמן",
		"level": "יסודי וחט\"ב",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בי\"ס חב\"ד לבנות",
		"semelMosad": 416131,
		"settlement": "פתח תקווה",
		"manager": "נחמה דינה דייטש",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בי\"ס חכמת רחמים",
		"semelMosad": 641092,
		"settlement": "ברכיה",
		"manager": "משה נעמן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בי\"ס חנ\"מ נווה מדבר",
		"semelMosad": 610360,
		"settlement": "אבו קרינאת (יישוב)",
		"manager": "ראזי אבו עשיבה",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "בי\"ס יוסף ה' עליכם",
		"semelMosad": 179770,
		"settlement": "ירושלים",
		"manager": "שלמה עבד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בי\"ס יסודי אלקודס",
		"semelMosad": 338376,
		"settlement": "באקה אל-גרביה",
		"manager": "רים חוואג'ה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "בי\"ס יסודי מדל איסט",
		"semelMosad": 511808,
		"settlement": "ירושלים",
		"manager": "ולאא אמבייד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "בי\"ס ישיבת צפת",
		"semelMosad": 430058,
		"settlement": "צפת",
		"manager": "יהושע זליג דיסקין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בי\"ס כפר דרום",
		"semelMosad": 615070,
		"settlement": "ניר עקיבא",
		"manager": "יונתן אטלן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בי\"ס לאמנויות",
		"semelMosad": 544619,
		"settlement": "תל אביב - יפו",
		"manager": "אהוד קליין",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בי\"ס לב טהור",
		"semelMosad": 338061,
		"settlement": "ירושלים",
		"manager": "טלי בצלאל",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בי\"ס לבנות ב\"י אידיש",
		"semelMosad": 615112,
		"settlement": "אשדוד",
		"manager": "אסתר הירש",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בי\"ס לבנות ירים משה",
		"semelMosad": 227421,
		"settlement": "ירושלים",
		"manager": "מיכל נינו",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בי\"ס לבנות כלל חסידי",
		"semelMosad": 416560,
		"settlement": "פתח תקווה",
		"manager": "אהובה ליבא זילברשטין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בי\"ס לח. מיוחד אלאמל",
		"semelMosad": 627034,
		"settlement": "רהט",
		"manager": "אסמאעיל אזברגה",
		"level": "חט\"ב + עליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "בי\"ס לחינוך מיוחד",
		"semelMosad": 627018,
		"settlement": "כסיפה",
		"manager": "נאיף אבו עגאג",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "בי\"ס לחינוך מיוחד",
		"semelMosad": 228445,
		"settlement": "מגאר",
		"manager": "בושרה כנג' סירחאן",
		"level": "יסודי חט\"ב ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "דרוזי"
	},
	{
		"schoolName": "בי\"ס לחינוך מיוחד",
		"semelMosad": 627026,
		"settlement": "ערערה-בנגב",
		"manager": "ריאד אלטלקאת",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "בי\"ס לחנ\"מ אדנים",
		"semelMosad": 649137,
		"settlement": "חדרה",
		"manager": "אירנה נחמני",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בי\"ס לחנ\"מ אדנים",
		"semelMosad": 421172,
		"settlement": "חדרה",
		"manager": "נועם ליס",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בי\"ס לחנ\"מ אלביק",
		"semelMosad": 267211,
		"settlement": "כפר כנא",
		"manager": "אמאני טאהא",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "בי\"ס לחנ\"מ אלחקמה",
		"semelMosad": 267476,
		"settlement": "מג'ד אל-כרום",
		"manager": "גנאן ג'אבר",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "בי\"ס לחנ\"מ נווה אתגר",
		"semelMosad": 320598,
		"settlement": "להבות חביבה",
		"manager": "איריס פרשטר",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בי\"ס לייף ליצרתיות ומצויינות",
		"semelMosad": 430082,
		"settlement": "ראמה",
		"manager": "מנאר קאסם מעדי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "בי\"ס לליקויי למידה",
		"semelMosad": 338038,
		"settlement": "ירושלים",
		"manager": "סוזאן שחאדה",
		"level": "יסודי חט\"ב ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "בי\"ס ללמוד וללמד",
		"semelMosad": 732271,
		"settlement": "צפת",
		"manager": "אסתר בילא בן שם",
		"level": "יסודי בלבד",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בי\"ס ללקויות למידה",
		"semelMosad": 520353,
		"settlement": "טמרה",
		"manager": "עולא דיאב",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "בי\"ס מח\"ט עראבה",
		"semelMosad": 650127,
		"settlement": "עראבה",
		"manager": "מונזר נעאמנה",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "בי\"ס מיוחד זיו",
		"semelMosad": 220459,
		"settlement": "צפת",
		"manager": "סמדר ברקוביץ",
		"level": "יסודי חט\"ב ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בי\"ס מקיף השלום דנון",
		"semelMosad": 247221,
		"settlement": "שייח' דנון",
		"manager": "מוחמד אסלאן",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "בי\"ס מרכז אלון",
		"semelMosad": 331017,
		"settlement": "אלונים",
		"manager": "מירב שרון",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בי\"ס נזירות סליזיאן",
		"semelMosad": 472332,
		"settlement": "נצרת",
		"manager": "חנאן נימרי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "בי\"ס סוואחרה בנים",
		"semelMosad": 714204,
		"settlement": "ירושלים",
		"manager": "ספיאן זחאיקה",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "בי\"ס סמיע ע\"ש ד\"ר סלמאן פלאח",
		"semelMosad": 218446,
		"settlement": "כסרא-סמיע",
		"manager": "סועאד מדאח",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "דרוזי"
	},
	{
		"schoolName": "בי\"ס ע\"י אלאספניול",
		"semelMosad": 368084,
		"settlement": "ירושלים",
		"manager": "אלווירה אבו עיסה",
		"level": "יסודי ועליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "בי\"ס ע\"ש גניגר",
		"semelMosad": 320036,
		"settlement": "חיפה",
		"manager": "תמי מנור",
		"level": "יסודי חט\"ב ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בי\"ס ע\"ש סמיח אלקאסם",
		"semelMosad": 218966,
		"settlement": "ראמה",
		"manager": "חאתם דאו",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "בי\"ס עודד",
		"semelMosad": 338590,
		"settlement": "יודפת",
		"manager": "עופרה מתן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בי\"ס עוזי חיטמן",
		"semelMosad": 430546,
		"settlement": "פתח תקווה",
		"manager": "רוית מוררו שרמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בי\"ס שחר",
		"semelMosad": 220426,
		"settlement": "כרמיאל",
		"manager": "אילונה טרנרידר",
		"level": "חט\"ב + עליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בי\"ס תיכון אורנית",
		"semelMosad": 482059,
		"settlement": "אורנית",
		"manager": "ענת דובר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ביאליק",
		"semelMosad": 311431,
		"settlement": "קרית ביאליק",
		"manager": "עינת ברקו",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ביאליק",
		"semelMosad": 511113,
		"settlement": "תל אביב - יפו",
		"manager": "עדית הפטול",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ביאליק",
		"semelMosad": 414003,
		"settlement": "פתח תקווה",
		"manager": "רינת ריינה אלמוזלינוס",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ביאליק",
		"semelMosad": 411389,
		"settlement": "נתניה",
		"manager": "איילת חורב",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ביאליק",
		"semelMosad": 410779,
		"settlement": "יבנה",
		"manager": "דליה אסתר גבריאלי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ביאליק",
		"semelMosad": 510487,
		"settlement": "חולון",
		"manager": "טליה הוכמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ביה\"ס אור חיה",
		"semelMosad": 614842,
		"settlement": "אשקלון",
		"manager": "חנה וולף",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ביה\"ס אל-פריר",
		"semelMosad": 117010,
		"settlement": "ירושלים",
		"manager": "דאוד כסאברי",
		"level": "יסודי ועליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "ביה\"ס אמליסון",
		"semelMosad": 267955,
		"settlement": "ירושלים",
		"manager": "וסים עלי",
		"level": "יסודי ועליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "ביה\"ס גולן-חיספין",
		"semelMosad": 213157,
		"settlement": "חספין",
		"manager": "יצחק אליעזר פריש",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ביה\"ס גת המשולש",
		"semelMosad": 348185,
		"settlement": "ג'ת",
		"manager": "סאלח גרה",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "ביה\"ס דרכי יצחק",
		"semelMosad": 641308,
		"settlement": "אשדוד",
		"manager": "חיים אפללו",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ביה\"ס האיטלקי",
		"semelMosad": 347039,
		"settlement": "חיפה",
		"manager": "גיזל אבו איוב",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "ביה\"ס הדמוקרטי",
		"semelMosad": 581355,
		"settlement": "הרצליה",
		"manager": "עמית נגדימן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ביה\"ס הדמוקרטי-חדרה",
		"semelMosad": 312660,
		"settlement": "חדרה",
		"manager": "ליאור מרקוס בכר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ביה\"ס היסודי כמאנה",
		"semelMosad": 217489,
		"settlement": "כמאנה",
		"manager": "עומר סואעד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "ביה\"ס הלטיני ראמה",
		"semelMosad": 217224,
		"settlement": "ראמה",
		"manager": "אימאן סמארה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "ביה\"ס הפתוח",
		"semelMosad": 514240,
		"settlement": "תל אביב - יפו",
		"manager": "יוני קונר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ביה\"ס השכונתי שפירא",
		"semelMosad": 581173,
		"settlement": "תל אביב - יפו",
		"manager": "שחר פיינשטיין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ביה\"ס וולדרוף שפרעם",
		"semelMosad": 361501,
		"settlement": "שפרעם",
		"manager": "דיאב עכרייה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "ביה\"ס למלאכה חב\"ד",
		"semelMosad": 670562,
		"settlement": "קרית מלאכי",
		"manager": "משה יניב עקיבא",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ביה\"ס ממ\"ד דרור",
		"semelMosad": 113076,
		"settlement": "ירושלים",
		"manager": "שרית לנדאו",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ביה\"ס משותף עומרים",
		"semelMosad": 214437,
		"settlement": "עין השופט",
		"manager": "אורה שומרון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ביה\"ס נאות אילנה",
		"semelMosad": 420539,
		"settlement": "ראש העין",
		"manager": "גתית ברסטל",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ביה\"ס ע\"ש דביקו",
		"semelMosad": 176156,
		"settlement": "ירושלים",
		"manager": "שלום ביטאן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ביה\"ס עמלני נצרת",
		"semelMosad": 228247,
		"settlement": "נצרת",
		"manager": "ופא חאמד",
		"level": "חט\"ב + עליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "ביה\"ס קהילה",
		"semelMosad": 514943,
		"settlement": "תל אביב - יפו",
		"manager": "יעל ס\"ט-רשף",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ביה\"ס קפלן מח'ילדים",
		"semelMosad": 420166,
		"settlement": "רחובות",
		"manager": "סיגלית צדוק שאקי",
		"level": "יסודי חט\"ב ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ביהס אוהל פייגא",
		"semelMosad": 733899,
		"settlement": "טבריה",
		"manager": "שולמית קלפהולץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ביחד",
		"semelMosad": 313700,
		"settlement": "חיפה",
		"manager": "בת שבע קופפר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ביכורים",
		"semelMosad": 212167,
		"settlement": "רחוב",
		"manager": "צופית שריג",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ביכורים - המכיל",
		"semelMosad": 579268,
		"settlement": "תל אביב - יפו",
		"manager": "רוית לוי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בין ההדרים",
		"semelMosad": 362061,
		"settlement": "חירות",
		"manager": "שרונה קדוש",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בינתחומי הדר",
		"semelMosad": 411272,
		"settlement": "נס ציונה",
		"manager": "ענת ורשביאק לוז",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ביס חבד ק חיים",
		"semelMosad": 312603,
		"settlement": "חיפה",
		"manager": "משה אסולין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ביס יסודי",
		"semelMosad": 288191,
		"settlement": "ביר אל-מכסור",
		"manager": "עאטף גדיר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "ביס לבנות אורה ושמחה",
		"semelMosad": 131185,
		"settlement": "ירושלים",
		"manager": "נעימה אריה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ביס לחינוך מיוחד חדש",
		"semelMosad": 610444,
		"settlement": "רהט",
		"manager": "רים עמארנה",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "ביס לטבע סביבה וחברה",
		"semelMosad": 544627,
		"settlement": "תל אביב - יפו",
		"manager": "ורד גצלר",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ביס קהילתי נאות סמדר",
		"semelMosad": 614446,
		"settlement": "נאות סמדר",
		"manager": "כרמלה אנגלסרט",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ביס שמידט קולג' בנות",
		"semelMosad": 187575,
		"settlement": "ירושלים",
		"manager": "אווה שונימאן",
		"level": "יסודי ועליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "ביר אל מכסור ב",
		"semelMosad": 218230,
		"settlement": "ביר אל-מכסור",
		"manager": "מוסא גדיר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "ביר אל סיכה",
		"semelMosad": 418020,
		"settlement": "זמר",
		"manager": "האלא דקה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "ביר אלמשוש",
		"semelMosad": 618785,
		"settlement": "אעצם (שבט)",
		"manager": "חליל אל וקילי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "ביר עסלוג'",
		"semelMosad": 630087,
		"settlement": "ביר הדאג'",
		"manager": "סאלם טוחי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "בית אב\"י",
		"semelMosad": 417105,
		"settlement": "אבן יהודה",
		"manager": "קרן אמיתי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית אהרון וישראל",
		"semelMosad": 758201,
		"settlement": "טבריה",
		"manager": "משה אייזנשטיין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית אהרון וישראל",
		"semelMosad": 725572,
		"settlement": "ירושלים",
		"manager": "שלום גנץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית אור",
		"semelMosad": 411835,
		"settlement": "קדרון",
		"manager": "שירלי גלמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית אורי",
		"semelMosad": 220202,
		"settlement": "עפולה",
		"manager": "יוסף שחר",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית איזי שפירא",
		"semelMosad": 420950,
		"settlement": "רעננה",
		"manager": "איריס רד וינשטין",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית אל",
		"semelMosad": 343905,
		"settlement": "זכרון יעקב",
		"manager": "טליה קרנץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית אל-חכמה",
		"semelMosad": 618298,
		"settlement": "רהט",
		"manager": "סאלם אבו מדיעם",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "בית אליהו",
		"semelMosad": 440008,
		"settlement": "בני ברק",
		"manager": "מרדכי פורייס",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית אקשטיין אור יהוד",
		"semelMosad": 510008,
		"settlement": "אור יהודה",
		"manager": "מרדכי גיגי",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית אקשטיין בית ברל",
		"semelMosad": 654962,
		"settlement": "בית ברל",
		"manager": "מירה מור",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית אקשטיין דרור",
		"semelMosad": 384164,
		"settlement": "פרדס חנה-כרכור",
		"manager": "מזל שינדר",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית אקשטיין חטיבת בי",
		"semelMosad": 655019,
		"settlement": "מדרשת רופין",
		"manager": "עירן נגר",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית אקשטיין יד רמבם",
		"semelMosad": 741140,
		"settlement": "יד רמב\"ם",
		"manager": "יהונתן בן סימון",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית אקשטיין יסודי ספ",
		"semelMosad": 731687,
		"settlement": "רמת גן",
		"manager": "דגנית מלל-שחר",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית אקשטיין נצר",
		"semelMosad": 759233,
		"settlement": "נצר סרני",
		"manager": "מיכל ביטון",
		"level": "יסודי בלבד",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית אקשטיין פרדס חנה",
		"semelMosad": 384123,
		"settlement": "פרדס חנה-כרכור",
		"manager": "יניב בן ציון",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית אקשטיין רמת גן",
		"semelMosad": 526889,
		"settlement": "רמת גן",
		"manager": "אורנה סרור הדר",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית אקשטיין-חוףהשרון",
		"semelMosad": 456616,
		"settlement": "תל יצחק",
		"manager": "ורדה קרמר",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית אקשטיין-כפר מל\"ל",
		"semelMosad": 460725,
		"settlement": "כפר מל\"ל",
		"manager": "דותן מימון",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית בנימין",
		"semelMosad": 723437,
		"settlement": "צפת",
		"manager": "שלום לבקיבקר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית ברכה",
		"semelMosad": 112789,
		"settlement": "ירושלים",
		"manager": "דינה גרליץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית ג'אן ממלכתי ב'",
		"semelMosad": 219196,
		"settlement": "בית ג'ן",
		"manager": "מוניר זויהד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "דרוזי"
	},
	{
		"schoolName": "בית דוד",
		"semelMosad": 739011,
		"settlement": "בית שמש",
		"manager": "מאיר שמעון בידרמן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית דוד",
		"semelMosad": 541920,
		"settlement": "חולון",
		"manager": "לאה קראמר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית הגדי",
		"semelMosad": 610428,
		"settlement": "בית הגדי",
		"manager": "נפתלי סבן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית החינוך -דגניה א",
		"semelMosad": 210500,
		"settlement": "דגניה א'",
		"manager": "זאב בילר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית החינוך ע\"ש שמעון פרס",
		"semelMosad": 480137,
		"settlement": "ראש העין",
		"manager": "מרים ניצני",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית הילד נווה מיכאל",
		"semelMosad": 384438,
		"settlement": "פרדס חנה-כרכור",
		"manager": "מיטל פלד",
		"level": "יסודי בלבד",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית הכרם",
		"semelMosad": 110155,
		"settlement": "ירושלים",
		"manager": "ורד ברוש",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית המעיין",
		"semelMosad": 616722,
		"settlement": "חוקוק",
		"manager": "צבי ניר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית הספר אורט סאג'ור",
		"semelMosad": 247213,
		"settlement": "סאג'ור",
		"manager": "עופרה דאהר",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "דרוזי"
	},
	{
		"schoolName": "בית הספר אמירים כפר ורדים",
		"semelMosad": 244483,
		"settlement": "כפר ורדים",
		"manager": "שרון בינדר",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית הספר האזורי משגב",
		"semelMosad": 213660,
		"settlement": "עצמון שגב",
		"manager": "לאה שמיר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית הספר הארצי להנדס",
		"semelMosad": 872531,
		"settlement": "חיפה",
		"manager": "רומן קרופקו",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית הספר הבינלאומי ב",
		"semelMosad": 744565,
		"settlement": "גבעת חביבה",
		"manager": "יובל דביר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית הספר השיטה",
		"semelMosad": 711770,
		"settlement": "רחובות",
		"manager": "גילי אלון",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית הספר רעות לאמנויות",
		"semelMosad": 370049,
		"settlement": "חיפה",
		"manager": "אופיר שדמי",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית הצייר",
		"semelMosad": 146878,
		"settlement": "ירושלים",
		"manager": "איתן שלמה צדוק הופמן",
		"level": "חט\"ב + עליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית השם נהלך",
		"semelMosad": 755157,
		"settlement": "בית שמש",
		"manager": "יהודה אריה נוימן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית התלמוד שערי תורה",
		"semelMosad": 728857,
		"settlement": "ירושלים",
		"manager": "אליהו נחום פרנקל",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית וגן דרכא",
		"semelMosad": 540369,
		"settlement": "בת ים",
		"manager": "טובה ברק",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית זאב-גאולים עפולה",
		"semelMosad": 213637,
		"settlement": "עפולה",
		"manager": "שרון שרה מלכה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית חדש",
		"semelMosad": 142133,
		"settlement": "ירושלים",
		"manager": "חיים אילוז",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית חוה",
		"semelMosad": 141929,
		"settlement": "ירושלים",
		"manager": "הניה אקער",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית חולים שלוותה",
		"semelMosad": 421040,
		"settlement": "הוד השרון",
		"manager": "איריס נמרי בורשטיין",
		"level": "חט\"ב + עליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית חורון",
		"semelMosad": 111724,
		"settlement": "בית חורון",
		"manager": "נריה הלוי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית חיה",
		"semelMosad": 313585,
		"settlement": "חיפה",
		"manager": "ורדה בת ציון אקסלרוט",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית חיה לבנות",
		"semelMosad": 114132,
		"settlement": "ביתר עילית",
		"manager": "שרה ריבה האגר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית חיה מושקא",
		"semelMosad": 512145,
		"settlement": "ירושלים",
		"manager": "שטרנה שרה קנטורוביץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית חיה מושקא",
		"semelMosad": 344150,
		"settlement": "חיפה",
		"manager": "חנה לאה למברג",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית חיה מושקא",
		"semelMosad": 641969,
		"settlement": "מגדל העמק",
		"manager": "חנה מיכלא מושקוביץ",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית חיל",
		"semelMosad": 642074,
		"settlement": "בני ברק",
		"manager": "מיכל אלקריף",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית חינוך אביב",
		"semelMosad": 510479,
		"settlement": "תל אביב - יפו",
		"manager": "דנה ניצברג",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית חינוך אוסישקין",
		"semelMosad": 510925,
		"settlement": "רמת השרון",
		"manager": "רונית רחל בלום דוד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית חינוך אמית רננים",
		"semelMosad": 444679,
		"settlement": "רעננה",
		"manager": "עפרה פאר",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית חינוך בנים",
		"semelMosad": 520106,
		"settlement": "בני ברק",
		"manager": "חנה אנתבי",
		"level": "יסודי בלבד",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית חינוך בראשית בני",
		"semelMosad": 616524,
		"settlement": "מרכז אזור מרום הגליל",
		"manager": "ליאת שמואלי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית חינוך גלילי",
		"semelMosad": 616177,
		"settlement": "לביא",
		"manager": "אריאל שלמוביץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית חינוך טכנולוגי אמית רמלה",
		"semelMosad": 431254,
		"settlement": "רמלה",
		"manager": "יזהר אפגן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית חינוך יסמין",
		"semelMosad": 758326,
		"settlement": "בנימינה-גבעת עדה",
		"manager": "שרון פינס",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית חינוך יצחק נבון",
		"semelMosad": 338467,
		"settlement": "יקנעם עילית",
		"manager": "שושנה בן יוסף",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית חינוך ירקון",
		"semelMosad": 841254,
		"settlement": "נווה ירק",
		"manager": "עליזה מגן",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית חינוך כלל חסידי",
		"semelMosad": 412312,
		"settlement": "אלעד",
		"manager": "פרידה גילה טישלר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית חינוך כרמים",
		"semelMosad": 361493,
		"settlement": "מודיעין-מכבים-רעות",
		"manager": "רוזי ברקוביץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית חינוך לבנות",
		"semelMosad": 724302,
		"settlement": "בני ברק",
		"manager": "אסתר מירל הלברשטם",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית חינוך לבנות ביידיש",
		"semelMosad": 733774,
		"settlement": "בני ברק",
		"manager": "ריקל פינקלשטיין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית חינוך מונטסורי יער",
		"semelMosad": 756882,
		"settlement": "חולון",
		"manager": "קרן אשוש",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית חינוך ע\"ש רבין",
		"semelMosad": 441279,
		"settlement": "תל מונד",
		"manager": "חגית לוי-קדמי",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית חינוך ע\"ש רונה רמון",
		"semelMosad": 480111,
		"settlement": "ראש העין",
		"manager": "רוית לבנה קזס טירם",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית חינוך פאול קור",
		"semelMosad": 430462,
		"settlement": "פתח תקווה",
		"manager": "אסנת סחייק",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית חינוך קהילתי גאולה",
		"semelMosad": 570085,
		"settlement": "תל אביב - יפו",
		"manager": "דנה מולכו",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית חינוך קהילתי\"קשת",
		"semelMosad": 213736,
		"settlement": "כפר ורדים",
		"manager": "מירב לבקוביץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית חינוך רונסון לטבע וקהילה",
		"semelMosad": 646158,
		"settlement": "גורנות הגליל",
		"manager": "תומר פרידמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית חנה",
		"semelMosad": 770495,
		"settlement": "ירושלים",
		"manager": "פרץ אוריאל בלויא",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית חנה",
		"semelMosad": 270801,
		"settlement": "צפת",
		"manager": "שרה חיה ווילשנסקי",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית חנה חוף הכרמל",
		"semelMosad": 384214,
		"settlement": "בית צבי",
		"manager": "סיגלית אורלי קרמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית חנוך עוורים מד",
		"semelMosad": 120055,
		"settlement": "ירושלים",
		"manager": "מנוחה טרופ",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית חנוך קהלתי חוב\"ב",
		"semelMosad": 410241,
		"settlement": "בני ציון",
		"manager": "טל בהרב",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית חנינה לבנות",
		"semelMosad": 118083,
		"settlement": "ירושלים",
		"manager": "חנאן אבו דלו",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "בית חנינה לבנים",
		"semelMosad": 118075,
		"settlement": "ירושלים",
		"manager": "רידא אבו דלו",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "בית טראני",
		"semelMosad": 470484,
		"settlement": "ירושלים",
		"manager": "יהודית ריכטר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יהושע",
		"semelMosad": 311217,
		"settlement": "נשר",
		"manager": "גלית שרה סדצקי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יוכבד חסידי גור",
		"semelMosad": 610865,
		"settlement": "קרית גת",
		"manager": "מאיר שלמה גרינברג",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יוסף",
		"semelMosad": 514653,
		"settlement": "חולון",
		"manager": "יוסי אילנתנוב",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יחזקאל",
		"semelMosad": 610121,
		"settlement": "אשקלון",
		"manager": "איל הנרי חסאן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יעקב",
		"semelMosad": 570606,
		"settlement": "תל אביב - יפו",
		"manager": "מלכה רובינשטיין",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יעקב",
		"semelMosad": 141689,
		"settlement": "מודיעין עילית",
		"manager": "אהרן פוירשטין",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יעקב \"יצהר",
		"semelMosad": 499921,
		"settlement": "כפר סבא",
		"manager": "בתיה שפיצר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יעקב - אידיש גור",
		"semelMosad": 511014,
		"settlement": "בית שמש",
		"manager": "יונית שארפר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יעקב אבות",
		"semelMosad": 770412,
		"settlement": "אשדוד",
		"manager": "חבצלת מדמוני",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יעקב אביעזרי",
		"semelMosad": 189944,
		"settlement": "מודיעין עילית",
		"manager": "גיטה גוטליב",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יעקב אהבת חסד",
		"semelMosad": 113134,
		"settlement": "ביתר עילית",
		"manager": "רייצה גולדה שטיין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יעקב אהל חיה",
		"semelMosad": 770503,
		"settlement": "נתיבות",
		"manager": "רחל ארליך",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יעקב אוהל רחל",
		"semelMosad": 113043,
		"settlement": "ירושלים",
		"manager": "צביה ישינסקי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יעקב אור המאיר",
		"semelMosad": 410555,
		"settlement": "אלעד",
		"manager": "חנה שרף",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יעקב אור רחל",
		"semelMosad": 241026,
		"settlement": "כפר גדעון",
		"manager": "כנרת יצחקי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יעקב אלשיך",
		"semelMosad": 212746,
		"settlement": "צפת",
		"manager": "דימינטה רותי רביבו",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יעקב אפיקי מים",
		"semelMosad": 734467,
		"settlement": "נתיבות",
		"manager": "אליהו מרדכי מניר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יעקב באידיש",
		"semelMosad": 160341,
		"settlement": "ירושלים",
		"manager": "יחזקאל חיים הלפרין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יעקב באר מרים",
		"semelMosad": 240531,
		"settlement": "חצור הגלילית",
		"manager": "ישראל הרשקוביץ",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יעקב בית בינה",
		"semelMosad": 226688,
		"settlement": "ירושלים",
		"manager": "משה ברוך אינהורן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יעקב בית וגן",
		"semelMosad": 167015,
		"settlement": "ירושלים",
		"manager": "חסיה יונגרייז",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יעקב בית ישראל",
		"semelMosad": 110957,
		"settlement": "ירושלים",
		"manager": "יהודית ורנר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יעקב בית שמש",
		"semelMosad": 267963,
		"settlement": "בית שמש",
		"manager": "רבקה צביה גינצברג",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יעקב בית שמש",
		"semelMosad": 652651,
		"settlement": "בית שמש",
		"manager": "חיה חנון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יעקב ביתר",
		"semelMosad": 284091,
		"settlement": "ביתר עילית",
		"manager": "חוה רייזיל קנר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יעקב בנות אלעד",
		"semelMosad": 415638,
		"settlement": "אלעד",
		"manager": "חיה כהן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יעקב בנות הרמה",
		"semelMosad": 717769,
		"settlement": "בית שמש",
		"manager": "חנה גילה פאקס",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יעקב בנות שרה",
		"semelMosad": 514703,
		"settlement": "בני ברק",
		"manager": "רוזה רייזל שושנה בורנשטיין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יעקב גבעת שאול",
		"semelMosad": 111138,
		"settlement": "ירושלים",
		"manager": "הדסה פוברסקי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יעקב גור",
		"semelMosad": 188060,
		"settlement": "בית שמש",
		"manager": "מרים נוסבוים",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יעקב גור",
		"semelMosad": 360842,
		"settlement": "ירושלים",
		"manager": "אדל בינה ברוידא",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יעקב גור",
		"semelMosad": 513507,
		"settlement": "בני ברק",
		"manager": "שרה ברלינר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יעקב גור ערד",
		"semelMosad": 613968,
		"settlement": "ערד",
		"manager": "שרה אידלמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יעקב גני איילון",
		"semelMosad": 642124,
		"settlement": "לוד",
		"manager": "בינה לאה דרבקין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יעקב דרכי רחל",
		"semelMosad": 141432,
		"settlement": "ירושלים",
		"manager": "יחיאל מנדלזון",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יעקב דרכי רחל",
		"semelMosad": 560300,
		"settlement": "ביתר עילית",
		"manager": "שולמית קראוס",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יעקב הארי",
		"semelMosad": 110981,
		"settlement": "ירושלים",
		"manager": "מרים כהנוביץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יעקב הדסים",
		"semelMosad": 614743,
		"settlement": "תפרח",
		"manager": "שרון דיין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יעקב החדיש",
		"semelMosad": 612424,
		"settlement": "אשדוד",
		"manager": "תקוה כהן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יעקב החדש",
		"semelMosad": 560045,
		"settlement": "בני ברק",
		"manager": "רות פנחסי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יעקב החדש",
		"semelMosad": 760710,
		"settlement": "ירושלים",
		"manager": "עדינה בוסו",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יעקב החדש אשדוד",
		"semelMosad": 611533,
		"settlement": "אשדוד",
		"manager": "מרים שוב",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יעקב החדש ביתר ע",
		"semelMosad": 741348,
		"settlement": "ביתר עילית",
		"manager": "פנינה פערל ברנשטיין",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יעקב החדש עפולה",
		"semelMosad": 720011,
		"settlement": "עפולה",
		"manager": "שרה גרטש",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יעקב החדש תפארת",
		"semelMosad": 519215,
		"settlement": "בני ברק",
		"manager": "פראדל רחל רבינוביץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יעקב היובל",
		"semelMosad": 742254,
		"settlement": "ירושלים",
		"manager": "הדסה זוסמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יעקב הר נוף",
		"semelMosad": 112433,
		"settlement": "ירושלים",
		"manager": "אסתר רבינוביץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יעקב ויזניץ",
		"semelMosad": 416412,
		"settlement": "אלעד",
		"manager": "חנה וויס",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יעקב ולאה בנות",
		"semelMosad": 511774,
		"settlement": "אור הגנוז",
		"manager": "איטה הענא ברסקי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יעקב זהרי תורה",
		"semelMosad": 760041,
		"settlement": "עפולה",
		"manager": "יפה ויצמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יעקב חדש",
		"semelMosad": 639930,
		"settlement": "ביתר עילית",
		"manager": "שולמית בתיה פוסטבסקי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יעקב חורב",
		"semelMosad": 722207,
		"settlement": "בני ברק",
		"manager": "מרגליה רוזנגרטן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יעקב חסידי",
		"semelMosad": 343988,
		"settlement": "ביתר עילית",
		"manager": "חיה שרה פרידמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יעקב חסידי",
		"semelMosad": 541235,
		"settlement": "בני ברק",
		"manager": "מרים צפורה פנסטר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יעקב חסידי",
		"semelMosad": 160283,
		"settlement": "ביתר עילית",
		"manager": "צירל הולצמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יעקב חסידי",
		"semelMosad": 640755,
		"settlement": "אשדוד",
		"manager": "חוה אסתר וייטמן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יעקב חסידי אשדוד",
		"semelMosad": 613661,
		"settlement": "אשדוד",
		"manager": "רבקה שושנה מנדל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יעקב טבריה",
		"semelMosad": 212464,
		"settlement": "טבריה",
		"manager": "חנה סבו",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יעקב יסודות",
		"semelMosad": 412494,
		"settlement": "יסודות",
		"manager": "רבקה פריידא פרידמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יעקב כה תאמר",
		"semelMosad": 166769,
		"settlement": "בית שמש",
		"manager": "מרים סולטן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יעקב לבנדה",
		"semelMosad": 512418,
		"settlement": "תל אביב - יפו",
		"manager": "רחל רבקה קליין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יעקב מאורות",
		"semelMosad": 442137,
		"settlement": "ראשון לציון",
		"manager": "שירה חיה שפרה פולק",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יעקב מגן אבות",
		"semelMosad": 124057,
		"settlement": "בית שמש",
		"manager": "דבורה סיימון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יעקב מטרסדורף",
		"semelMosad": 110023,
		"settlement": "ירושלים",
		"manager": "עטרה וולפא",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יעקב מעיין החנוך",
		"semelMosad": 440586,
		"settlement": "פתח תקווה",
		"manager": "רחל לוגסי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יעקב מעלות הדסה",
		"semelMosad": 716597,
		"settlement": "צפת",
		"manager": "צביה גוטרמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יעקב מרגלית",
		"semelMosad": 160648,
		"settlement": "ירושלים",
		"manager": "שפרה יוכבד סננס",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יעקב מרכז",
		"semelMosad": 512111,
		"settlement": "בני ברק",
		"manager": "חיה רות כהן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יעקב מרכז",
		"semelMosad": 311753,
		"settlement": "חיפה",
		"manager": "רות גלברט",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יעקב מרכז",
		"semelMosad": 110866,
		"settlement": "ירושלים",
		"manager": "שרה ספראי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יעקב נוה יעקב",
		"semelMosad": 111633,
		"settlement": "ירושלים",
		"manager": "פנינה וולפסון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יעקב נווה יוכבד",
		"semelMosad": 640664,
		"settlement": "אופקים",
		"manager": "בסי בן מנחם",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יעקב נווה מאיר",
		"semelMosad": 514984,
		"settlement": "בני ברק",
		"manager": "שרה גרידינגר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יעקב נפש",
		"semelMosad": 113498,
		"settlement": "ירושלים",
		"manager": "גיטי גולדברג",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יעקב נתיב",
		"semelMosad": 267971,
		"settlement": "ירושלים",
		"manager": "שרה צדקה",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יעקב נתיב בתיה קארו",
		"semelMosad": 110973,
		"settlement": "ירושלים",
		"manager": "אסתר בן חיים",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יעקב נתיבות משה",
		"semelMosad": 416172,
		"settlement": "קדימה-צורן",
		"manager": "נחמה זלדה סטל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יעקב נתניה",
		"semelMosad": 412866,
		"settlement": "נתניה",
		"manager": "שרה איטה אידלשטין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יעקב סיני יבנה",
		"semelMosad": 416552,
		"settlement": "יבנה",
		"manager": "שרה ישראלי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יעקב סלונים",
		"semelMosad": 716506,
		"settlement": "ביתר עילית",
		"manager": "צביה גולינסקי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יעקב סלונים",
		"semelMosad": 749010,
		"settlement": "קרית גת",
		"manager": "מרגלית רוזנברג",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יעקב ע\"ש י' וולף",
		"semelMosad": 540401,
		"settlement": "בני ברק",
		"manager": "שלמה וולף",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יעקב עוז והדר",
		"semelMosad": 615005,
		"settlement": "אשדוד",
		"manager": "רבקה בן יעקב",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יעקב עוז והדר",
		"semelMosad": 511402,
		"settlement": "בית שמש",
		"manager": "רבקה אדמוני",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יעקב עלי באר",
		"semelMosad": 142190,
		"settlement": "ירושלים",
		"manager": "בן ציון קוק",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יעקב עמנואל",
		"semelMosad": 414391,
		"settlement": "עמנואל",
		"manager": "בתיה קרימולובסקי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יעקב פ\"ת",
		"semelMosad": 415968,
		"settlement": "פתח תקווה",
		"manager": "נעמי מרים רוזנטל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יעקב פתח תקוה",
		"semelMosad": 412585,
		"settlement": "פתח תקווה",
		"manager": "רבקה קילא וינגרטן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יעקב קרטשנוף",
		"semelMosad": 414755,
		"settlement": "רחובות",
		"manager": "סימה ליכטנשטיין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יעקב קרית אתא",
		"semelMosad": 313551,
		"settlement": "קרית אתא",
		"manager": "אביטל נחמה רוטנר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יעקב קרית ספר",
		"semelMosad": 138602,
		"settlement": "מודיעין עילית",
		"manager": "נחמה אסתר רבינוביץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יעקב ראש העין",
		"semelMosad": 412635,
		"settlement": "ראש העין",
		"manager": "גיטל וייס",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יעקב רחובות",
		"semelMosad": 412650,
		"settlement": "רחובות",
		"manager": "מרים פרל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יעקב רמב\"ש",
		"semelMosad": 273086,
		"settlement": "בית שמש",
		"manager": "בלומה מרים אידל האגר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יעקב רמות פולין",
		"semelMosad": 112300,
		"settlement": "ירושלים",
		"manager": "פרומה לאה הלפרין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יעקב רמת בית שמש",
		"semelMosad": 730010,
		"settlement": "בית שמש",
		"manager": "תמר מרים הימלפרב",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יעקב רסג ת\"א",
		"semelMosad": 512392,
		"settlement": "תל אביב - יפו",
		"manager": "עדה טיפנברון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יעקב תבונות",
		"semelMosad": 412403,
		"settlement": "גבעת זאב",
		"manager": "הדסה ברמץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יעקב תהילה",
		"semelMosad": 344580,
		"settlement": "חיפה",
		"manager": "נחום מישקובסקי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יעקב תל ציון",
		"semelMosad": 227017,
		"settlement": "כוכב יעקב",
		"manager": "יוסף שפירא",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יעקב-בית יפה",
		"semelMosad": 344499,
		"settlement": "חדרה",
		"manager": "צפורה פייגע קריגר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית יצחק",
		"semelMosad": 747584,
		"settlement": "ירושלים",
		"manager": "יהושע אייזנבך",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית ירח א'",
		"semelMosad": 860361,
		"settlement": "כנרת (קבוצה)",
		"manager": "מיכל ליאני",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית ישראל",
		"semelMosad": 650069,
		"settlement": "ירושלים",
		"manager": "רן סרלין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית ישראל אשדוד",
		"semelMosad": 640698,
		"settlement": "אשדוד",
		"manager": "יוסף מנחם הרש קפלושניק",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית לוי יצחק חב\"ד",
		"semelMosad": 240739,
		"settlement": "צפת",
		"manager": "שניאור זלמן לבקיבקר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית מיתר",
		"semelMosad": 728881,
		"settlement": "צופית",
		"manager": "איילת גדעון הראל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית מלכה",
		"semelMosad": 140822,
		"settlement": "ירושלים",
		"manager": "פייגי אוסטרליץ",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית מלכה",
		"semelMosad": 614198,
		"settlement": "אשדוד",
		"manager": "שרה וייס",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית מלכה",
		"semelMosad": 360768,
		"settlement": "יבנאל",
		"manager": "עידית סולימן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית מלכה",
		"semelMosad": 640953,
		"settlement": "אשדוד",
		"manager": "רבקה שרה זלפרינד",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית מלכה ב\"ב",
		"semelMosad": 513622,
		"settlement": "בני ברק",
		"manager": "אטיל פולק",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית מלכה בית שמש",
		"semelMosad": 474734,
		"settlement": "בית שמש",
		"manager": "רחל פנט",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית מלכה חיפה",
		"semelMosad": 722728,
		"settlement": "חיפה",
		"manager": "נחמה קוזלובסקי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית מלכה י-ם",
		"semelMosad": 112292,
		"settlement": "ירושלים",
		"manager": "מרים ברינדל קרניאל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית מנחם מענדל",
		"semelMosad": 733956,
		"settlement": "צפת",
		"manager": "שניאור זלמן שטילרמן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית מנחם תורני(מעורב)",
		"semelMosad": 415695,
		"settlement": "לוד",
		"manager": "שולמית הרטמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית מצודות שיח סוד",
		"semelMosad": 160754,
		"settlement": "ירושלים",
		"manager": "אלה בידרמן",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית מרגלית",
		"semelMosad": 415059,
		"settlement": "אלעד",
		"manager": "אסנת נטלי דוידוב",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית מרגלית",
		"semelMosad": 113514,
		"settlement": "ביתר עילית",
		"manager": "יפה בראל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית מרגלית",
		"semelMosad": 442186,
		"settlement": "נתניה",
		"manager": "סגלית תורג'מן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית מרגלית החדש",
		"semelMosad": 338749,
		"settlement": "אלעד",
		"manager": "חנה כהן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית ספר אור חדש נתני",
		"semelMosad": 656827,
		"settlement": "נתניה",
		"manager": "חוה גיטל שמרלר",
		"level": "יסודי בלבד",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית ספר אלבשאאר",
		"semelMosad": 248278,
		"settlement": "סח'נין",
		"manager": "מנסור טאהא",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "בית ספר אלזהרא",
		"semelMosad": 338699,
		"settlement": "ירושלים",
		"manager": "מאגידה עגלוני",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "בית ספר אלטפל אלמובד",
		"semelMosad": 757633,
		"settlement": "ירושלים",
		"manager": "רגא גוויחאן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "בית ספר אלמעאלי המעו",
		"semelMosad": 477257,
		"settlement": "ירושלים",
		"manager": "נסרין אבראהים",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "בית ספר אלסראג",
		"semelMosad": 746503,
		"settlement": "ירושלים",
		"manager": "נאדיה בדריה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "בית ספר אלעזיזה",
		"semelMosad": 430355,
		"settlement": "ירושלים",
		"manager": "זינב הנדי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "בית ספר אלעמליה אלנמ",
		"semelMosad": 725309,
		"settlement": "ירושלים",
		"manager": "באסימה קראבסה",
		"level": "יסודי ועליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "בית ספר אמסח דמעת אל",
		"semelMosad": 729145,
		"settlement": "ירושלים",
		"manager": "חמזה קיסי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "בית ספר בית ברל",
		"semelMosad": 747949,
		"settlement": "בית ברל",
		"manager": "מוחמד קונדס",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית ספר בכפר",
		"semelMosad": 649947,
		"settlement": "בית שמש",
		"manager": "נטע יעקב קוקה",
		"level": "יסודי חט\"ב ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית ספר בנות נווה",
		"semelMosad": 740712,
		"settlement": "נווה",
		"manager": "מירב-רות סבן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית ספר השלום",
		"semelMosad": 218487,
		"settlement": "מגאר",
		"manager": "נהיל יוסף",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "דרוזי"
	},
	{
		"schoolName": "בית ספר יחיאל",
		"semelMosad": 722298,
		"settlement": "טל שחר",
		"manager": "יוסף סופר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית ספר יסודי אאפאק",
		"semelMosad": 219808,
		"settlement": "דייר אל-אסד",
		"manager": "ספא אסדי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "בית ספר יסודי אלאנגא",
		"semelMosad": 756718,
		"settlement": "ירושלים",
		"manager": "נורה תופגזי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "בית ספר יסודי אלסלאם",
		"semelMosad": 747360,
		"settlement": "ירושלים",
		"manager": "אמאני אלדיכ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "בית ספר יסודי אלפארו",
		"semelMosad": 746529,
		"settlement": "ירושלים",
		"manager": "גאדה בשיתי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "בית ספר יסודי נור אל",
		"semelMosad": 478909,
		"settlement": "ירושלים",
		"manager": "נדאא אבו כף",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "בית ספר מעיין",
		"semelMosad": 476267,
		"settlement": "נען",
		"manager": "תמר לאור",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית ספר סמירא מיס",
		"semelMosad": 757583,
		"settlement": "ירושלים",
		"manager": "מונה נאבולסי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "בית ספר ע\"ש נמר עראידה",
		"semelMosad": 218479,
		"settlement": "מגאר",
		"manager": "אסעד אבו זידאן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "דרוזי"
	},
	{
		"schoolName": "בית ספר עהדונא",
		"semelMosad": 746313,
		"settlement": "ירושלים",
		"manager": "דלאל קאסם",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "בית ספר פרח",
		"semelMosad": 759415,
		"settlement": "ביתר עילית",
		"manager": "רחל חזן",
		"level": "יסודי בלבד",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית ספר רואא",
		"semelMosad": 430439,
		"settlement": "ירושלים",
		"manager": "ענאן נתשה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "בית ספר תיכון אלאנגא",
		"semelMosad": 756742,
		"settlement": "ירושלים",
		"manager": "עלי קאדי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "בית ספר תיכון אלפורק",
		"semelMosad": 756775,
		"settlement": "ירושלים",
		"manager": "חיאת מחמד עלי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "בית ספר תיכון אלפורק",
		"semelMosad": 755587,
		"settlement": "ירושלים",
		"manager": "הנאא סלים",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "בית ספר תיכון מקצועי",
		"semelMosad": 120113,
		"settlement": "ירושלים",
		"manager": "רות פן בן אריה",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית ספר תיכון סמירא",
		"semelMosad": 757609,
		"settlement": "ירושלים",
		"manager": "מונה נאבולסי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "בית ספר תיכון רואא",
		"semelMosad": 756692,
		"settlement": "ירושלים",
		"manager": "מוניר חאמד",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "בית עדנאן לאוטיסטים",
		"semelMosad": 410480,
		"settlement": "ירכא",
		"manager": "נביל עטאללה",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "דרוזי"
	},
	{
		"schoolName": "בית עדנאן לאוטיסטים",
		"semelMosad": 647545,
		"settlement": "ירכא",
		"manager": "היבה גביש",
		"level": "יסודי בלבד",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "דרוזי"
	},
	{
		"schoolName": "בית עולות",
		"semelMosad": 170282,
		"settlement": "ירושלים",
		"manager": "שרה קרוק",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית עזריה",
		"semelMosad": 560573,
		"settlement": "בני ברק",
		"manager": "לאה קוט",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית פייגא לבנות",
		"semelMosad": 214361,
		"settlement": "יבנאל",
		"manager": "רויטל גרמה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית צורי - רמת אביב",
		"semelMosad": 579250,
		"settlement": "תל אביב - יפו",
		"manager": "רחלי סילבי קריפלד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית צפאפא יסודי",
		"semelMosad": 118091,
		"settlement": "ירושלים",
		"manager": "חאתם סלמאן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "בית רבקה",
		"semelMosad": 440214,
		"settlement": "כפר חב\"ד",
		"manager": "לאה חפר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית רבקה",
		"semelMosad": 441899,
		"settlement": "נתניה",
		"manager": "אסתר חיה בן מעש",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית רבקה חב\"ד",
		"semelMosad": 240838,
		"settlement": "נוף הגליל",
		"manager": "חיה מושקא ריניץ",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית רבקה צעירות",
		"semelMosad": 482240,
		"settlement": "כפר חב\"ד",
		"manager": "רחל ברינה הוניג",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית רחל שטראוס",
		"semelMosad": 120212,
		"settlement": "ירושלים",
		"manager": "סימונה רחל קוהל",
		"level": "חט\"ב + עליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית שאן אדיר-מעורב",
		"semelMosad": 212902,
		"settlement": "בית שאן",
		"manager": "רות שוקרון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית שי",
		"semelMosad": 530741,
		"settlement": "בני ברק",
		"manager": "אלי מושקוביץ",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית שכונת זגיר",
		"semelMosad": 440685,
		"settlement": "ירושלים",
		"manager": "רבא ארנאוט",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "בית-אל בנות",
		"semelMosad": 111849,
		"settlement": "בית אל",
		"manager": "מיכל צרום",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בית-אל בנים",
		"semelMosad": 113324,
		"settlement": "בית אל",
		"manager": "ירון גיאת",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בכור לוי",
		"semelMosad": 413351,
		"settlement": "רחובות",
		"manager": "עינב בעהם",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בכריה אלוין",
		"semelMosad": 128041,
		"settlement": "ירושלים",
		"manager": "ערפאת ערפאת",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "בכרייה אלוין ב'",
		"semelMosad": 520304,
		"settlement": "ירושלים",
		"manager": "מרים פרג",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "בלז מחזיקי הדת אשדוד",
		"semelMosad": 613539,
		"settlement": "אשדוד",
		"manager": "יעקב דב מן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בלפור",
		"semelMosad": 511121,
		"settlement": "תל אביב - יפו",
		"manager": "הילה שמעוני חן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בלקינד",
		"semelMosad": 420158,
		"settlement": "ראשון לציון",
		"manager": "אסנה יעקובוב",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בן גוריון",
		"semelMosad": 513606,
		"settlement": "חולון",
		"manager": "כוכבית בן שושן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בן גוריון",
		"semelMosad": 541037,
		"settlement": "הרצליה",
		"manager": "ענת פרי",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בן גוריון",
		"semelMosad": 614495,
		"settlement": "באר שבע",
		"manager": "ויקטוריה קוטרו",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בן גוריון",
		"semelMosad": 413682,
		"settlement": "יבנה",
		"manager": "ניצה ויקטוריה סולומון כהן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בן גוריון",
		"semelMosad": 412262,
		"settlement": "ראשון לציון",
		"manager": "ורד הראל עציץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בן גוריון",
		"semelMosad": 312611,
		"settlement": "קרית מוצקין",
		"manager": "קרן מרגלית דדיה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בן גוריון",
		"semelMosad": 312371,
		"settlement": "חיפה",
		"manager": "איריס כהן דור",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בן גוריון",
		"semelMosad": 513101,
		"settlement": "בת ים",
		"manager": "יפית יחיא חן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בן גוריון",
		"semelMosad": 410373,
		"settlement": "גן יבנה",
		"manager": "רינת כהן-רג'ואן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בן גוריון",
		"semelMosad": 413443,
		"settlement": "גבעת שמואל",
		"manager": "תמר טל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בן גוריון",
		"semelMosad": 413880,
		"settlement": "רמלה",
		"manager": "גלעד שמילוביץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בן גוריון",
		"semelMosad": 510859,
		"settlement": "רמת גן",
		"manager": "איריס מינדלביץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בן גוריון",
		"semelMosad": 512996,
		"settlement": "גבעתיים",
		"manager": "כרמית גלעדי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בן גוריון",
		"semelMosad": 411983,
		"settlement": "רחובות",
		"manager": "אורנה בן דוד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בן גוריון",
		"semelMosad": 413856,
		"settlement": "כפר סבא",
		"manager": "מאיה גנון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בן יהודה מ\"מ",
		"semelMosad": 120071,
		"settlement": "ירושלים",
		"manager": "שושנה אור",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בן עטר",
		"semelMosad": 611780,
		"settlement": "דימונה",
		"manager": "רויטל עובד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בן צבי",
		"semelMosad": 411959,
		"settlement": "רחובות",
		"manager": "נעמי אביעד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בן צבי",
		"semelMosad": 412858,
		"settlement": "רמלה",
		"manager": "רחל אביבית אלקיים-זיו",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בן צבי",
		"semelMosad": 413666,
		"settlement": "נס ציונה",
		"manager": "ליאורה ברוק",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בן צבי",
		"semelMosad": 513242,
		"settlement": "חולון",
		"manager": "אלי גבאי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בן צבי-עפולה",
		"semelMosad": 212738,
		"settlement": "עפולה",
		"manager": "אורלי חן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בן-גוריון נס-ציונה",
		"semelMosad": 440248,
		"settlement": "נס ציונה",
		"manager": "איריס דורון (והבה)",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בן-יקיר",
		"semelMosad": 480129,
		"settlement": "כפר הרא\"ה",
		"manager": "ענת הראל",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בן-צבי",
		"semelMosad": 611699,
		"settlement": "קרית גת",
		"manager": "רחל אשכנזי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בנות אלישיב",
		"semelMosad": 461855,
		"settlement": "ירושלים",
		"manager": "הדסה זעפראני",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בנות אסתר",
		"semelMosad": 732214,
		"settlement": "נהריה",
		"manager": "מרים גיבלי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בנות אסתר",
		"semelMosad": 477679,
		"settlement": "ירושלים",
		"manager": "דבורה רייצא שינברגר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בנות באיאן רוזין",
		"semelMosad": 114009,
		"settlement": "ירושלים",
		"manager": "צפורה דבורה פיירשטיין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בנות בית יעקב",
		"semelMosad": 643593,
		"settlement": "ירושלים",
		"manager": "יהודית קרייסמן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בנות בת שבע",
		"semelMosad": 741710,
		"settlement": "מודיעין עילית",
		"manager": "יעל וייס",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בנות הדסה",
		"semelMosad": 511469,
		"settlement": "בית שמש",
		"manager": "חנה איפרגן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בנות הינדא",
		"semelMosad": 755447,
		"settlement": "בית שמש",
		"manager": "רבקה ליבוביץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בנות ובית יעקב",
		"semelMosad": 338129,
		"settlement": "ביתר עילית",
		"manager": "שיינדל רחל מוזסון",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בנות ובית יעקב ביתר",
		"semelMosad": 124206,
		"settlement": "ביתר עילית",
		"manager": "חנה ברים",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בנות ויזניץ",
		"semelMosad": 112797,
		"settlement": "ירושלים",
		"manager": "מרים הירשמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בנות ויזניץ",
		"semelMosad": 455154,
		"settlement": "בית שמש",
		"manager": "פייגא גשטטנר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בנות ויזניץ",
		"semelMosad": 362582,
		"settlement": "בית שמש",
		"manager": "מנחם גרינבוים",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בנות ויזניץ",
		"semelMosad": 141895,
		"settlement": "ירושלים",
		"manager": "מנחם גרינבוים",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בנות ויזניץ-מודיעין",
		"semelMosad": 410670,
		"settlement": "מודיעין עילית",
		"manager": "חיים מאיר ויזל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בנות חברון",
		"semelMosad": 634121,
		"settlement": "קרית ארבע",
		"manager": "דבורה לאה זיגמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בנות חייל",
		"semelMosad": 614149,
		"settlement": "באר שבע",
		"manager": "עשירה עומיסי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בנות חייל",
		"semelMosad": 761734,
		"settlement": "רעננה",
		"manager": "פנינה פרץ",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בנות חיל",
		"semelMosad": 740779,
		"settlement": "ירושלים",
		"manager": "שירה רוזנבלום",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בנות חנה",
		"semelMosad": 755868,
		"settlement": "ביתר עילית",
		"manager": "דבורה בלשניקוב",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בנות יעקב חריש",
		"semelMosad": 639617,
		"settlement": "חריש",
		"manager": "רחל בראל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בנות יצהר",
		"semelMosad": 636902,
		"settlement": "יצהר",
		"manager": "שימרית ספיר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בנות ירושלים",
		"semelMosad": 611541,
		"settlement": "ירושלים",
		"manager": "בתיה מלאך",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בנות לאה",
		"semelMosad": 441816,
		"settlement": "יבנה",
		"manager": "רונית כהן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בנות מלך",
		"semelMosad": 442582,
		"settlement": "נתניה",
		"manager": "אריה מאיר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בנות מלך",
		"semelMosad": 745901,
		"settlement": "נתניה",
		"manager": "הדס כהן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בנות מלכים",
		"semelMosad": 741892,
		"settlement": "ירושלים",
		"manager": "חנה וואלדמאן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בנות מנחם חב\"ד",
		"semelMosad": 512228,
		"settlement": "חולון",
		"manager": "בלה רוחמה שטיינברגר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בנות מרגלית- ויזניץ",
		"semelMosad": 759316,
		"settlement": "עפולה",
		"manager": "ניצה בילה כהנא",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בנות צאנז",
		"semelMosad": 756510,
		"settlement": "טבריה",
		"manager": "חנה גולדה כהנא",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בנות צופיה",
		"semelMosad": 412619,
		"settlement": "ירושלים",
		"manager": "תהילה פרידמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בנות ציון",
		"semelMosad": 514422,
		"settlement": "חולון",
		"manager": "אביגיל מרסל ורון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בנות ציון",
		"semelMosad": 749895,
		"settlement": "בני ברק",
		"manager": "מתנה שלום",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בנות ציון",
		"semelMosad": 540005,
		"settlement": "בני ברק",
		"manager": "מתנה שלום",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בנות ציון",
		"semelMosad": 359331,
		"settlement": "ירושלים",
		"manager": "לאה רחל נקי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בנות שרה",
		"semelMosad": 758102,
		"settlement": "אלעד",
		"manager": "שירה כהן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בנות שרה",
		"semelMosad": 732750,
		"settlement": "אלעד",
		"manager": "שירה כהן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בנות תפארת",
		"semelMosad": 244608,
		"settlement": "טבריה",
		"manager": "דבורה ברזילי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בני אהרן",
		"semelMosad": 513929,
		"settlement": "בני ברק",
		"manager": "מרדכי דוד ברגר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בני ארזים",
		"semelMosad": 420364,
		"settlement": "ראשון לציון",
		"manager": "רותם אייש זבודיבקר",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בני ברית",
		"semelMosad": 120063,
		"settlement": "קרית יערים",
		"manager": "אפרת חוזה",
		"level": "יסודי בלבד",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בני הישיבות",
		"semelMosad": 412338,
		"settlement": "רחובות",
		"manager": "נחמיה שלום",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בני חיל שומרון",
		"semelMosad": 460410,
		"settlement": "קדומים",
		"manager": "משה תומר רבי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בני יוסף",
		"semelMosad": 739037,
		"settlement": "לוד",
		"manager": "יהודה עומר זמיר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בני יוסף",
		"semelMosad": 759001,
		"settlement": "קרית עקרון",
		"manager": "מתניה דוד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בני יוסף שדרות",
		"semelMosad": 759704,
		"settlement": "שדרות",
		"manager": "אברהם ויצמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בני יששכר",
		"semelMosad": 521112,
		"settlement": "חדרה",
		"manager": "יניב תוהמי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בני מלכים ישיבה",
		"semelMosad": 722116,
		"settlement": "אלעד",
		"manager": "אמנון הלוי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בני מנחם חב\"ד",
		"semelMosad": 512137,
		"settlement": "חולון",
		"manager": "יהושע שמעוני",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בני משה",
		"semelMosad": 414615,
		"settlement": "רחובות",
		"manager": "משה שפיצר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בני נצרים בנים",
		"semelMosad": 616466,
		"settlement": "בני נצרים",
		"manager": "אליעזר לוינגר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בני ציון",
		"semelMosad": 631119,
		"settlement": "ערד",
		"manager": "יניב רפאל קרייף",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בניה",
		"semelMosad": 734913,
		"settlement": "חיפה",
		"manager": "תמיר כהן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בניה",
		"semelMosad": 734855,
		"settlement": "חיפה",
		"manager": "תמיר כהן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בנימין רוטמן אשל הנשיא",
		"semelMosad": 641431,
		"settlement": "אשל הנשיא",
		"manager": "מרצדס מרסל דניאלי",
		"level": "חט\"ב + עליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בנימין רוטמן כדורי",
		"semelMosad": 512491,
		"settlement": "כדורי",
		"manager": "טלי גניאל",
		"level": "חט\"ב + עליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בנימין רוטמן- שבילי העמק",
		"semelMosad": 480004,
		"settlement": "גבעת חיים (איחוד)",
		"manager": "אורית מליחי",
		"level": "חט\"ב + עליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בנין אב בית אהרון",
		"semelMosad": 633776,
		"settlement": "אופקים",
		"manager": "משה שמול",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בנין שלם",
		"semelMosad": 734582,
		"settlement": "ירושלים",
		"manager": "נפתלי פרידמן",
		"level": "עליונה בלבד",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בנציון נתניהו",
		"semelMosad": 482380,
		"settlement": "רמלה",
		"manager": "רויטל פישר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בס תכון אבו טור בנות",
		"semelMosad": 560177,
		"settlement": "ירושלים",
		"manager": "אלהאם נבהאן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "בסמת טבעון",
		"semelMosad": 248674,
		"settlement": "בסמת טבעון",
		"manager": "אבראהים סעדי",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "בעל שם טוב מד",
		"semelMosad": 511790,
		"settlement": "תל אביב - יפו",
		"manager": "רונית קרונפלד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בעלזא",
		"semelMosad": 112623,
		"settlement": "ירושלים",
		"manager": "עזריאל ישעיה זיכרמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בעלזא קרית גת",
		"semelMosad": 747600,
		"settlement": "קרית גת",
		"manager": "אהרן אליהו פולק",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בעלזא קרית גת",
		"semelMosad": 741322,
		"settlement": "קרית גת",
		"manager": "טעמא רחל גרוס",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בפטיסטים",
		"semelMosad": 217174,
		"settlement": "נצרת",
		"manager": "אן חדאד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "בפי ישרים",
		"semelMosad": 113183,
		"settlement": "ירושלים",
		"manager": "יוסף גאריביאן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בצוותא מ\"מ",
		"semelMosad": 384669,
		"settlement": "חריש",
		"manager": "אביבית לוי משה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בצוותא ממ\"ד",
		"semelMosad": 384651,
		"settlement": "חריש",
		"manager": "שולמית אילוז",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בר אילן",
		"semelMosad": 581223,
		"settlement": "פתח תקווה",
		"manager": "ינון אליהו ורהפטיג",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בר אילן מד",
		"semelMosad": 510453,
		"settlement": "הרצליה",
		"manager": "גלית סגל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בר לב",
		"semelMosad": 415430,
		"settlement": "פתח תקווה",
		"manager": "דולי אדלה דינה מוטולה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בראשית",
		"semelMosad": 476929,
		"settlement": "קרית אונו",
		"manager": "אדוארד גטלובסקי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בראשית",
		"semelMosad": 384248,
		"settlement": "בנימינה-גבעת עדה",
		"manager": "יגאל שבאר-שפירא",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בראשית",
		"semelMosad": 482588,
		"settlement": "רחובות",
		"manager": "אופיר יוסף ביכלר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בראשית",
		"semelMosad": 417238,
		"settlement": "בית אלעזרי",
		"manager": "אמירה ביבי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בראשית",
		"semelMosad": 482679,
		"settlement": "באר יעקב",
		"manager": "טל גנוסר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בראשית בנות",
		"semelMosad": 616540,
		"settlement": "מרכז אזור מרום הגליל",
		"manager": "איריס לוי קזנטיני",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ברויאר חנ\"מ",
		"semelMosad": 482463,
		"settlement": "יד בנימין",
		"manager": "רבקה רחל סילבר",
		"level": "יסודי בלבד",
		"special": "מיוחד",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ברושים",
		"semelMosad": 120295,
		"settlement": "ירושלים",
		"manager": "תמר חיה רלבג",
		"level": "יסודי בלבד",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ברושים",
		"semelMosad": 520205,
		"settlement": "תל אביב - יפו",
		"manager": "הדס רבאני",
		"level": "חט\"ב + עליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ברטוב",
		"semelMosad": 412213,
		"settlement": "רעננה",
		"manager": "סיגל כהן לויטס",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ברטעה",
		"semelMosad": 318089,
		"settlement": "בסמ\"ה",
		"manager": "סוהיר כבהא",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "ברידג' אינטרנשיונל",
		"semelMosad": 338434,
		"settlement": "ירושלים",
		"manager": "שירין שחאדה",
		"level": "יסודי ועליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "ברכ\"ה - כתות חולים",
		"semelMosad": 320119,
		"settlement": "חיפה",
		"manager": "אילנה לוי",
		"level": "יסודי חט\"ב ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ברכפלד חדש",
		"semelMosad": 410654,
		"settlement": "מודיעין עילית",
		"manager": "יפה אינהורן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ברכת אברהם פתח תקווה",
		"semelMosad": 460360,
		"settlement": "פתח תקווה",
		"manager": "זהר עוקשי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ברכת הנחל",
		"semelMosad": 359794,
		"settlement": "מודיעין עילית",
		"manager": "נחמן קניג",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ברכת יעקב (דרך אמת)",
		"semelMosad": 414656,
		"settlement": "פתח תקווה",
		"manager": "יהושע דוד דבורץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ברכת ישראל",
		"semelMosad": 214296,
		"settlement": "עכו",
		"manager": "עידן יעקב דיין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ברכת ישראל בנות",
		"semelMosad": 499673,
		"settlement": "עכו",
		"manager": "ברוריה אילוז",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ברכת ישראל מאיר",
		"semelMosad": 366880,
		"settlement": "ירושלים",
		"manager": "שמואל אביגדור אריאלי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ברכת שמואל",
		"semelMosad": 739185,
		"settlement": "בית שמש",
		"manager": "שינדיל שיינברגר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ברל כצנלסון נהריה",
		"semelMosad": 211490,
		"settlement": "נהריה",
		"manager": "נורית וולף",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ברנדט ממ\"ד נוה יעקב",
		"semelMosad": 111591,
		"settlement": "ירושלים",
		"manager": "ורדית אבני",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ברנדיס",
		"semelMosad": 510388,
		"settlement": "הרצליה",
		"manager": "מור תירוש",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ברנע",
		"semelMosad": 613505,
		"settlement": "אשקלון",
		"manager": "דביר נחום שפרן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ברנקו וייס אופק מעלה אדומים",
		"semelMosad": 260497,
		"settlement": "מעלה אדומים",
		"manager": "אפרת ביטון",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ברנקו וייס אור יהודה",
		"semelMosad": 508093,
		"settlement": "אור יהודה",
		"manager": "אמיר סער",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ברנקו וייס אתגרי בית שמש",
		"semelMosad": 226670,
		"settlement": "בית שמש",
		"manager": "טליה אהרון אזולאי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ברנקו וייס באר שבע",
		"semelMosad": 641118,
		"settlement": "באר שבע",
		"manager": "כפיר אריאל מושליון",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ברנקו וייס גולן",
		"semelMosad": 240655,
		"settlement": "רמות",
		"manager": "הילה חורי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ברנקו וייס טבריה",
		"semelMosad": 240796,
		"settlement": "טבריה",
		"manager": "דוד ישועה מואטי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ברנקו וייס מודיעין",
		"semelMosad": 440594,
		"settlement": "מודיעין-מכבים-רעות",
		"manager": "מיכל סטון סהר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ברנקו וייס מקיף בית שמש",
		"semelMosad": 160432,
		"settlement": "בית שמש",
		"manager": "יואב חגבי",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ברנקו וייס מקיף ע\"ש רבין",
		"semelMosad": 444935,
		"settlement": "מזכרת בתיה",
		"manager": "גיא יחזקאל לוי",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ברנקו וייס מרום גליל",
		"semelMosad": 244566,
		"settlement": "מירון",
		"manager": "רבקה אהלי ריקהויז",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ברנקו וייס עין נקובה",
		"semelMosad": 442822,
		"settlement": "עין נקובא",
		"manager": "איהאב אלזיאדנה",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "ברנקו וייס פתח תקווה",
		"semelMosad": 470534,
		"settlement": "פתח תקווה",
		"manager": "גיא דוד אלוש",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ברנקו וייס קריית שמונה",
		"semelMosad": 240804,
		"settlement": "קרית שמונה",
		"manager": "אביחי גולן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ברנקו וייס רמלה",
		"semelMosad": 441949,
		"settlement": "רמלה",
		"manager": "ארז פנחס וינטנר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ברנר",
		"semelMosad": 410860,
		"settlement": "כפר סבא",
		"manager": "איריס אברהם",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ברסלב",
		"semelMosad": 630004,
		"settlement": "חצור הגלילית",
		"manager": "יהונתן שמשון טסלר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ברסלב בוגרי ישיבות",
		"semelMosad": 759191,
		"settlement": "ביתר עילית",
		"manager": "יחיאל יהושע בריכטא",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ברקת",
		"semelMosad": 652594,
		"settlement": "חדרה",
		"manager": "טלי אוחיון",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בת מלך",
		"semelMosad": 214049,
		"settlement": "טבריה",
		"manager": "יעל ויזאן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בת מלך",
		"semelMosad": 214270,
		"settlement": "צפת",
		"manager": "אילת בשירי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בת מלך",
		"semelMosad": 362178,
		"settlement": "רכסים",
		"manager": "שרה נחמה וינבך",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בת מלך",
		"semelMosad": 641381,
		"settlement": "בית הגדי",
		"manager": "רבקה נהרי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בת מלך",
		"semelMosad": 711648,
		"settlement": "טבריה",
		"manager": "אסתר כדר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "בת רא\"ם פרמישלאן",
		"semelMosad": 511832,
		"settlement": "בני ברק",
		"manager": "שרה שטרן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ג'בל סיך",
		"semelMosad": 217406,
		"settlement": "עין מאהל",
		"manager": "חארת אבו ליל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "ג'ובראן",
		"semelMosad": 618140,
		"settlement": "תל שבע",
		"manager": "אמירה אלאסד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "ג'רוזלם סקול יסודי",
		"semelMosad": 239905,
		"settlement": "ירושלים",
		"manager": "מג'ד גאסר רביע",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "ג'רוזלם סקול עי\"ס",
		"semelMosad": 239897,
		"settlement": "ירושלים",
		"manager": "ניר דעקה",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "ג.יוספטל",
		"semelMosad": 512814,
		"settlement": "אזור",
		"manager": "אילנית נימני גורגי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "גאולים",
		"semelMosad": 610097,
		"settlement": "אשדוד",
		"manager": "לידיה כהן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "גאון הירדן",
		"semelMosad": 260133,
		"settlement": "נווה איתן",
		"manager": "יצחק מאיר גולן",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "גבור גבור שפרעם",
		"semelMosad": 219022,
		"settlement": "שפרעם",
		"manager": "אימאן אעמר חלאחלה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "גבורי ישראל",
		"semelMosad": 511170,
		"settlement": "תל אביב - יפו",
		"manager": "קרן פרברוב",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "גבים",
		"semelMosad": 612788,
		"settlement": "באר שבע",
		"manager": "יפעת מנשקו",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "גבע- גבעת עדה",
		"semelMosad": 310128,
		"settlement": "בנימינה-גבעת עדה",
		"manager": "עינב צילה קולטון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "גבעול",
		"semelMosad": 310268,
		"settlement": "חדרה",
		"manager": "ליאור לב-רן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "גבעולים",
		"semelMosad": 510685,
		"settlement": "רמת גן",
		"manager": "טל עמרני",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "גבעון",
		"semelMosad": 530402,
		"settlement": "תל אביב - יפו",
		"manager": "יונתן שלמה עוזיאל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "גבעות עולם",
		"semelMosad": 511105,
		"settlement": "גבעת זאב",
		"manager": "אברהם זוהר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "גבעת אלונים",
		"semelMosad": 384446,
		"settlement": "קרית אתא",
		"manager": "לימור בן יוסף",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "גבעת גונן",
		"semelMosad": 111203,
		"settlement": "ירושלים",
		"manager": "יפעת ספיר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "גבעת רם",
		"semelMosad": 617050,
		"settlement": "ירושלים",
		"manager": "נורית פרידמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "גבריאל",
		"semelMosad": 610881,
		"settlement": "דימונה",
		"manager": "שולי לוק",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "גבריאלי",
		"semelMosad": 312132,
		"settlement": "חיפה",
		"manager": "אברהם ויינברג",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "גבריאלי-הכרמל",
		"semelMosad": 511352,
		"settlement": "תל אביב - יפו",
		"manager": "מרים מירי רובין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "גדרות -עשרת",
		"semelMosad": 411553,
		"settlement": "עשרת",
		"manager": "אושרת רינגל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "גואטמלה",
		"semelMosad": 110320,
		"settlement": "ירושלים",
		"manager": "רוני אוחנה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "גובראן חליל גובראן",
		"semelMosad": 218602,
		"settlement": "נצרת",
		"manager": "תגריד ח'טיב",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "גוונים",
		"semelMosad": 581199,
		"settlement": "תל אביב - יפו",
		"manager": "סבטלנה פודוקסיק",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "גוונים",
		"semelMosad": 620252,
		"settlement": "אשדוד",
		"manager": "סיון סיביליה עיני",
		"level": "יסודי חט\"ב ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "גוונים",
		"semelMosad": 620385,
		"settlement": "קרית שמונה",
		"manager": "חמוטל נחמה אופק",
		"level": "יסודי חט\"ב ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "גוונים",
		"semelMosad": 420224,
		"settlement": "נתניה",
		"manager": "אפרת כהן-וגנר",
		"level": "יסודי חט\"ב ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "גוונים",
		"semelMosad": 442905,
		"settlement": "כפר סבא",
		"manager": "פנינה פזית יפה",
		"level": "יסודי וחט\"ב",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "גוונים",
		"semelMosad": 414409,
		"settlement": "אורנית",
		"manager": "גלית רויטל אידלמן קסוס",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "גוונים",
		"semelMosad": 413799,
		"settlement": "סתריה",
		"manager": "מלי שושן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "גוונים",
		"semelMosad": 413963,
		"settlement": "גדרה",
		"manager": "יעל רבקה קסלסי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "גוונים חנ\"מ",
		"semelMosad": 266809,
		"settlement": "מודיעין-מכבים-רעות",
		"manager": "ליאורה ולר",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "גוונים מ\"מ",
		"semelMosad": 111450,
		"settlement": "קרית ארבע",
		"manager": "ענבל גונן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "גוונים-עין המפרץ",
		"semelMosad": 211714,
		"settlement": "עין המפרץ",
		"manager": "איריס אלטיט",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "גולדה מאיר",
		"semelMosad": 613216,
		"settlement": "באר שבע",
		"manager": "אינסה קוזינץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "גולדה מאיר",
		"semelMosad": 414029,
		"settlement": "כפר סבא",
		"manager": "יעל אילון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "גולדטק",
		"semelMosad": 566885,
		"settlement": "חולון",
		"manager": "אורית כהן בן עזרא",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "גולן",
		"semelMosad": 510933,
		"settlement": "רמת השרון",
		"manager": "אורנה נמיר צבי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "גולן ע\"ש שמעון פרס",
		"semelMosad": 213306,
		"settlement": "נוף הגליל",
		"manager": "פני נוימן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "גורדון",
		"semelMosad": 510404,
		"settlement": "הרצליה",
		"manager": "נאוה טל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "גורדון",
		"semelMosad": 310458,
		"settlement": "חיפה",
		"manager": "גליה רחל אביר-גיל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "גורדון",
		"semelMosad": 310979,
		"settlement": "קרית אתא",
		"manager": "ליאורה בנארי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "גורדון",
		"semelMosad": 411603,
		"settlement": "פתח תקווה",
		"manager": "רחל ברמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "גורדון",
		"semelMosad": 410878,
		"settlement": "כפר סבא",
		"manager": "עמית שמעון יצחאיק",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "גורדון - עכו",
		"semelMosad": 211797,
		"settlement": "עכו",
		"manager": "יעל אריש",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "גורן",
		"semelMosad": 641670,
		"settlement": "ירושלים",
		"manager": "עמית בר טוב",
		"level": "חט\"ב + עליונה",
		"special": "מיוחד",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "גיל",
		"semelMosad": 520890,
		"settlement": "תל אביב - יפו",
		"manager": "נורית אבני שפירא",
		"level": "חט\"ב + עליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "גיל",
		"semelMosad": 320051,
		"settlement": "חיפה",
		"manager": "עדנה פניכל",
		"level": "יסודי חט\"ב ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "גיל אלמוסתקבל",
		"semelMosad": 430363,
		"settlement": "ירושלים",
		"manager": "ליאלי גומעה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "גיל אלמוסתקבל",
		"semelMosad": 342394,
		"settlement": "ירושלים",
		"manager": "עבד אל גני גמגום",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "גילה ממלכתי א",
		"semelMosad": 111500,
		"settlement": "ירושלים",
		"manager": "דורון מתן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "גילה ממלכתי ב",
		"semelMosad": 111906,
		"settlement": "ירושלים",
		"manager": "חגית כהן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "גילה ממלכתי תל\"י",
		"semelMosad": 112359,
		"settlement": "ירושלים",
		"manager": "ליאת אלהרר כהן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "גימנסיה באר שבע",
		"semelMosad": 641142,
		"settlement": "באר שבע",
		"manager": "יעל לזמי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "גימנסיה דרכא",
		"semelMosad": 670422,
		"settlement": "קרית מלאכי",
		"manager": "רחל מיכל חזן",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "גימנסיה חדרה",
		"semelMosad": 340653,
		"settlement": "חדרה",
		"manager": "קטי אמתיי קקון",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "גימנסיה כרמל אלנאג'אח",
		"semelMosad": 340604,
		"settlement": "חיפה",
		"manager": "נשאת אבו דאהש",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "גימנסיה נתניה",
		"semelMosad": 442418,
		"settlement": "נתניה",
		"manager": "אמירה רוזין",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "גימס רוטשילד",
		"semelMosad": 310011,
		"settlement": "אור עקיבא",
		"manager": "עדי ישי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "גל אור",
		"semelMosad": 320457,
		"settlement": "חדרה",
		"manager": "לימור בונשטיין",
		"level": "יסודי חט\"ב ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "גלבוע",
		"semelMosad": 616789,
		"settlement": "בית אלפא",
		"manager": "שירלי שבת ספיר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "גלבוע -בית שאן",
		"semelMosad": 210260,
		"settlement": "בית שאן",
		"manager": "לימור אהרן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "גלי עתלית",
		"semelMosad": 311332,
		"settlement": "עתלית",
		"manager": "ורד פישר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "גליל",
		"semelMosad": 213090,
		"settlement": "אשבל",
		"manager": "מנאר חיאדרי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "גליל ים",
		"semelMosad": 581413,
		"settlement": "הרצליה",
		"manager": "איריס אורן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "גלילות",
		"semelMosad": 311225,
		"settlement": "נשר",
		"manager": "מירב אפלויג",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "גלים",
		"semelMosad": 620187,
		"settlement": "אשדוד",
		"manager": "שירית זיברט",
		"level": "יסודי חט\"ב ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "גלים",
		"semelMosad": 482802,
		"settlement": "רחובות",
		"manager": "רוית חדד-חכמו",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "גלים",
		"semelMosad": 614628,
		"settlement": "אילת",
		"manager": "אורלית אפניאר זיקרי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "גלים",
		"semelMosad": 320010,
		"settlement": "חדרה",
		"manager": "אביגיל גמליאל",
		"level": "יסודי בלבד",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "גלים",
		"semelMosad": 220475,
		"settlement": "עין המפרץ",
		"manager": "ויקטוריה בונדר",
		"level": "יסודי בלבד",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "גלעד",
		"semelMosad": 220434,
		"settlement": "חמדיה",
		"manager": "אלכסנדר קליין",
		"level": "חט\"ב + עליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "גמלא -קצרין",
		"semelMosad": 213389,
		"settlement": "קצרין",
		"manager": "הגר גילה הוד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "גמנסיה הגליל הנוצרית",
		"semelMosad": 247072,
		"settlement": "עיילבון",
		"manager": "עזמי סרור",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "גמנסיה מודיעים",
		"semelMosad": 540443,
		"settlement": "רמת גן",
		"manager": "סימה מאור",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "גמנסיה עברית הרצליה",
		"semelMosad": 540062,
		"settlement": "תל אביב - יפו",
		"manager": "זאב דגני",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "גמנסיה עתידים לילנטל",
		"semelMosad": 440545,
		"settlement": "רמלה",
		"manager": "אביגיל עוז",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "גמנסיה קריות",
		"semelMosad": 344572,
		"settlement": "קרית מוצקין",
		"manager": "בר ירון חריר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "גמנסיה ראלית עש קררי",
		"semelMosad": 440065,
		"settlement": "ראשון לציון",
		"manager": "איריס רון",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "גן ונוף",
		"semelMosad": 480038,
		"settlement": "פתח תקווה",
		"manager": "שחר הרשקוביץ",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "גנאור",
		"semelMosad": 320531,
		"settlement": "קרית אתא",
		"manager": "רנין חוראני",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "גנזי המלך",
		"semelMosad": 611558,
		"settlement": "צפת",
		"manager": "מישל מיכל ליברמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "גני אביב",
		"semelMosad": 415364,
		"settlement": "לוד",
		"manager": "מרגלית בדש",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "גני איריס",
		"semelMosad": 412726,
		"settlement": "נס ציונה",
		"manager": "ורד ניר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "גני יער",
		"semelMosad": 416040,
		"settlement": "לוד",
		"manager": "ענת אסף",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "גפנים",
		"semelMosad": 112599,
		"settlement": "משואה",
		"manager": "רעות חן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "גרץ",
		"semelMosad": 511220,
		"settlement": "תל אביב - יפו",
		"manager": "שירה ערד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "גשר",
		"semelMosad": 514232,
		"settlement": "כפר שמריהו",
		"manager": "דלית קירלי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "גשר",
		"semelMosad": 581330,
		"settlement": "תל אביב - יפו",
		"manager": "ריקי שלנגר ברזילי",
		"level": "יסודי בלבד",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "גשר",
		"semelMosad": 620336,
		"settlement": "באר שבע",
		"manager": "אוסנת שירי חקשירי",
		"level": "חט\"ב + עליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "גשר ממ\"ד",
		"semelMosad": 641639,
		"settlement": "בית שמש",
		"manager": "תניא וקנין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "גת אלזהראא",
		"semelMosad": 318436,
		"settlement": "ג'ת",
		"manager": "סלמה ערו",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "דאוחת אלאבדאע",
		"semelMosad": 747402,
		"settlement": "ירושלים",
		"manager": "מואייד אלעוקבי",
		"level": "יסודי ועליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "דאר אל חכמה",
		"semelMosad": 412676,
		"settlement": "ירושלים",
		"manager": "אברהים דוויק",
		"level": "יסודי ועליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "דאר אל חכמה",
		"semelMosad": 754721,
		"settlement": "ירושלים",
		"manager": "רים תמימי",
		"level": "יסודי ועליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "דאר אלקלם",
		"semelMosad": 648238,
		"settlement": "רהט",
		"manager": "מגיד אבו סכות",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "דארנא",
		"semelMosad": 646844,
		"settlement": "מדרשת רופין",
		"manager": "ליסה לאה פלג גולן",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דבורה עומר",
		"semelMosad": 511683,
		"settlement": "נתניה",
		"manager": "יעל אבן חן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דבורה עומר",
		"semelMosad": 512434,
		"settlement": "פתח תקווה",
		"manager": "רוית גלעדי גטניו",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דבורה עומר",
		"semelMosad": 456624,
		"settlement": "כפר סבא",
		"manager": "אור-לי עדי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דביר",
		"semelMosad": 614511,
		"settlement": "אשדוד",
		"manager": "אברהם דהן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דביר",
		"semelMosad": 613901,
		"settlement": "עתניאל",
		"manager": "אוריאל פישר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דביר",
		"semelMosad": 513382,
		"settlement": "חולון",
		"manager": "טל כהן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דגל ירושלים",
		"semelMosad": 733691,
		"settlement": "נחלים",
		"manager": "משה יונגשטין",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דגן",
		"semelMosad": 344507,
		"settlement": "חיפה",
		"manager": "מיכל זוהר",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דגניה",
		"semelMosad": 610253,
		"settlement": "באר שבע",
		"manager": "אביטל בנשלום",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דגניה",
		"semelMosad": 310466,
		"settlement": "חיפה",
		"manager": "רינת תמיר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דו\"ר לתפארת",
		"semelMosad": 338558,
		"settlement": "מודיעין עילית",
		"manager": "אהרן סומך",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דוד אלעזר",
		"semelMosad": 614289,
		"settlement": "קרית גת",
		"manager": "נירה גלעם",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דוד אלעזר",
		"semelMosad": 513549,
		"settlement": "בת ים",
		"manager": "רונית לוי כהן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דוד בלוך",
		"semelMosad": 511261,
		"settlement": "תל אביב - יפו",
		"manager": "שרון בצלאלי שלומן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דוד בן גוריון",
		"semelMosad": 613265,
		"settlement": "אופקים",
		"manager": "תקוה יפרח",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דוד ילין",
		"semelMosad": 511246,
		"settlement": "תל אביב - יפו",
		"manager": "ענבל שרף",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דוד ילין",
		"semelMosad": 310474,
		"settlement": "חיפה",
		"manager": "אילנית מועלם אביב",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דולב",
		"semelMosad": 114082,
		"settlement": "דולב",
		"manager": "חגית אברגל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דון בוסקו",
		"semelMosad": 277012,
		"settlement": "נצרת",
		"manager": "אבראהים אנדריא",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "דורון ע\"ש מאיר דורון",
		"semelMosad": 417089,
		"settlement": "רמת השרון",
		"manager": "רינת חכמי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דורות",
		"semelMosad": 115550,
		"settlement": "מודיעין-מכבים-רעות",
		"manager": "שולמית גנון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דורשי ציון",
		"semelMosad": 747329,
		"settlement": "ביתר עילית",
		"manager": "זאב כץ",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דחסידי גור",
		"semelMosad": 142075,
		"settlement": "ירושלים",
		"manager": "שרה גיטל וסרצוג",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דיזנגוף",
		"semelMosad": 511782,
		"settlement": "תל אביב - יפו",
		"manager": "רלי רחל אמסלם",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דינור",
		"semelMosad": 312355,
		"settlement": "חיפה",
		"manager": "רונית ברזני",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דליות -יקנעם",
		"semelMosad": 214494,
		"settlement": "יקנעם עילית",
		"manager": "עופרה הירש",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דמוקרטי",
		"semelMosad": 482612,
		"settlement": "יבנה",
		"manager": "מירב קביזון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דמוקרטי",
		"semelMosad": 415893,
		"settlement": "כפר סבא",
		"manager": "דנה הירשברג",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דמוקרטי אל מרחב",
		"semelMosad": 616367,
		"settlement": "בית השיטה",
		"manager": "אורי ברזק",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דמוקרטי בלב השרון",
		"semelMosad": 442772,
		"settlement": "אבן יהודה",
		"manager": "אורית פולק",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דמוקרטי בעמק",
		"semelMosad": 430173,
		"settlement": "נהלל",
		"manager": "גלי רייפר שירן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דמוקרטי בעמק- התיכון",
		"semelMosad": 733584,
		"settlement": "נהלל",
		"manager": "אורי שמעון אלסר",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דמוקרטי הוד השרון",
		"semelMosad": 416289,
		"settlement": "הוד השרון",
		"manager": "ויויאן חביבה משאל-מנדלווי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דמוקרטי ע\"ש יעקב חזן",
		"semelMosad": 441972,
		"settlement": "כפר סבא",
		"manager": "דלית סמואל",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דמוקרטי ע\"ש נדב",
		"semelMosad": 188086,
		"settlement": "מודיעין-מכבים-רעות",
		"manager": "ירון הרניק",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דמוקרטי ע\"ש נדב",
		"semelMosad": 241067,
		"settlement": "מודיעין-מכבים-רעות",
		"manager": "מירב ניר הוד",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דמוקרטי קרית אונו",
		"semelMosad": 515395,
		"settlement": "קרית אונו",
		"manager": "אהוד הוכרמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דמשק אליעזר ויזניץ",
		"semelMosad": 141911,
		"settlement": "ירושלים",
		"manager": "אפרים טסלר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דנה",
		"semelMosad": 520395,
		"settlement": "תל אביב - יפו",
		"manager": "אורית לוכטנשטיין",
		"level": "יסודי חט\"ב ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דנון",
		"semelMosad": 218198,
		"settlement": "שייח' דנון",
		"manager": "סאפי נסאר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "דעת",
		"semelMosad": 610956,
		"settlement": "סעד",
		"manager": "שושנה רחל פרג'ון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דעת אהרון",
		"semelMosad": 758193,
		"settlement": "ירושלים",
		"manager": "ניסן קפלן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דעת ותבונה",
		"semelMosad": 711564,
		"settlement": "ביתר עילית",
		"manager": "סימה לאה הראל",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דעת יוסף",
		"semelMosad": 350983,
		"settlement": "אלעד",
		"manager": "יעקב פרטוש",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דעת משה",
		"semelMosad": 741157,
		"settlement": "תל אביב - יפו",
		"manager": "יצחק גרבי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דעת משה",
		"semelMosad": 360545,
		"settlement": "ירושלים",
		"manager": "יחזקאל אהרן פרצוביץ",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דקל",
		"semelMosad": 414078,
		"settlement": "רעננה",
		"manager": "ענבל פליישמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דקל",
		"semelMosad": 612903,
		"settlement": "אשקלון",
		"manager": "סיגלית ישר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דקל - וילנאי",
		"semelMosad": 140863,
		"settlement": "מעלה אדומים",
		"manager": "דינה זייצב",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דקל -תוחלת",
		"semelMosad": 420331,
		"settlement": "לוד",
		"manager": "יפעת תחיה ארליך",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דקלים",
		"semelMosad": 220376,
		"settlement": "טבריה",
		"manager": "מיכל פרי מור",
		"level": "יסודי חט\"ב ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דקלים",
		"semelMosad": 612218,
		"settlement": "דימונה",
		"manager": "נאוה בלול דיין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דקלים",
		"semelMosad": 420083,
		"settlement": "נס ציונה",
		"manager": "בת חן צברי",
		"level": "יסודי חט\"ב ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דקלים",
		"semelMosad": 644211,
		"settlement": "באר שבע",
		"manager": "אסתר גואחניש",
		"level": "חט\"ב + עליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דקלים ממ\"ד",
		"semelMosad": 120493,
		"settlement": "ירושלים",
		"manager": "יוחאי יושעי",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דרגות",
		"semelMosad": 618611,
		"settlement": "דריג'את",
		"manager": "מנסור אבו חמאד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "דרוזי למדעים ומנהיגות דרכא",
		"semelMosad": 247387,
		"settlement": "ירכא",
		"manager": "כמיל שלה",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "דרוזי"
	},
	{
		"schoolName": "דרויינוב",
		"semelMosad": 530394,
		"settlement": "תל אביב - יפו",
		"manager": "גלית גוטרמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דרור",
		"semelMosad": 420679,
		"settlement": "מסילות",
		"manager": "נעמה בוכריס",
		"level": "יסודי בלבד",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דרור",
		"semelMosad": 310482,
		"settlement": "חיפה",
		"manager": "גלית כהן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דרור טכנולוגי רמלה",
		"semelMosad": 460642,
		"settlement": "רמלה",
		"manager": "מלכה ערפאת",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "דרורים",
		"semelMosad": 645655,
		"settlement": "אשקלון",
		"manager": "טרויה יפית מהרט",
		"level": "חט\"ב + עליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דרך אמותינו",
		"semelMosad": 359190,
		"settlement": "ביתר עילית",
		"manager": "מרים פיליפ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דרך החינוך",
		"semelMosad": 475616,
		"settlement": "מודיעין עילית",
		"manager": "אסתר לינר",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דרך הילד",
		"semelMosad": 510651,
		"settlement": "רמת השרון",
		"manager": "עפר מרבך",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דרך המלך פרי תואר",
		"semelMosad": 416545,
		"settlement": "אלעד",
		"manager": "רחל קנפו",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דרך יהודית",
		"semelMosad": 454314,
		"settlement": "צפת",
		"manager": "שרה רייזיל קניג",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דרך ע\"ש רונה רמון",
		"semelMosad": 480160,
		"settlement": "כפר יונה",
		"manager": "גילה נלי יסינסקי-קמה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דרך פיקודיך",
		"semelMosad": 731901,
		"settlement": "ביתר עילית",
		"manager": "שמעון שלום יעקובוביץ",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דרך תבונות",
		"semelMosad": 758169,
		"settlement": "בני ברק",
		"manager": "קלמן גורביץ",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דרכא ע\"ש אילן ואסף רמון",
		"semelMosad": 442236,
		"settlement": "גדרה",
		"manager": "רונית מור",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דרכא ע\"ש מנחם בגין",
		"semelMosad": 440198,
		"settlement": "גדרה",
		"manager": "אסתר חדד",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דרכא ע\"ש מקסים לוי",
		"semelMosad": 444612,
		"settlement": "לוד",
		"manager": "ליטל לוי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דרכה של תורה מודיעין",
		"semelMosad": 733451,
		"settlement": "מודיעין עילית",
		"manager": "אפרים צבי אמזל",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דרכי אבות",
		"semelMosad": 361519,
		"settlement": "אלעד",
		"manager": "גבריאל שנק",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דרכי אבות בנות",
		"semelMosad": 761221,
		"settlement": "ירושלים",
		"manager": "פרימט לאנגסם",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דרכי אבות צאנז",
		"semelMosad": 646612,
		"settlement": "בית שמש",
		"manager": "מאיר ראב",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דרכי אבות צאנז",
		"semelMosad": 756502,
		"settlement": "טבריה",
		"manager": "משה אהרן כהנא",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דרכי אבות צאנז",
		"semelMosad": 112722,
		"settlement": "ירושלים",
		"manager": "גדליה דוד שוורץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דרכי אבות צאנז",
		"semelMosad": 513796,
		"settlement": "בני ברק",
		"manager": "יחזקאל שר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דרכי אבות צאנז ביתר",
		"semelMosad": 176792,
		"settlement": "ביתר עילית",
		"manager": "משה שלזינגר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דרכי אבות צאנז חיפה",
		"semelMosad": 734939,
		"settlement": "חיפה",
		"manager": "דוד הרשקוביץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דרכי אבותינו",
		"semelMosad": 186379,
		"settlement": "מודיעין עילית",
		"manager": "חיים שרגא פייבל שוב",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דרכי איש אוהל אשר",
		"semelMosad": 520411,
		"settlement": "בית שמש",
		"manager": "ברוך רפאל טולידנו",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דרכי הלל",
		"semelMosad": 742759,
		"settlement": "חדרה",
		"manager": "אריה סילבר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דרכי חיים",
		"semelMosad": 420638,
		"settlement": "ירושלים",
		"manager": "ישראל יוסקוביץ",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דרכי חיים",
		"semelMosad": 747592,
		"settlement": "ירושלים",
		"manager": "מרדכי שמואל קולר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דרכי חינוך",
		"semelMosad": 520767,
		"settlement": "בני ברק",
		"manager": "ישראל צבי שטינברג",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דרכי חנה",
		"semelMosad": 711903,
		"settlement": "אלעד",
		"manager": "לאה זלזניק",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דרכי יהודה",
		"semelMosad": 232884,
		"settlement": "מודיעין-מכבים-רעות",
		"manager": "אילנה רוזן (רוזנברג)",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דרכי יושר בית שמש",
		"semelMosad": 733675,
		"settlement": "בית שמש",
		"manager": "אוריאל ישעיהו זנגר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דרכי יושר גבעת זאב",
		"semelMosad": 731513,
		"settlement": "גבעת זאב",
		"manager": "אורי הלר בקנרוט",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דרכי יושר גני איילון",
		"semelMosad": 761569,
		"settlement": "לוד",
		"manager": "ישראל קליין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דרכי יושר חפציבה",
		"semelMosad": 724450,
		"settlement": "מודיעין עילית",
		"manager": "יהושע מרדכי ורקר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דרכי מרדכי",
		"semelMosad": 726612,
		"settlement": "ירושלים",
		"manager": "שלמה סירוטה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דרכי משה",
		"semelMosad": 640169,
		"settlement": "אשדוד",
		"manager": "ישראל שפרנוביץ",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דרכי נעם",
		"semelMosad": 414664,
		"settlement": "פתח תקווה",
		"manager": "חגי אונגר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דרכי נעם",
		"semelMosad": 719971,
		"settlement": "פתח תקווה",
		"manager": "ליאת חגבי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דרכי רחל",
		"semelMosad": 346437,
		"settlement": "בית שמש",
		"manager": "יחיאל מנדלזון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דרכי שלום",
		"semelMosad": 757815,
		"settlement": "ירושלים",
		"manager": "מרדכי פוקסברומר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דרכי שלמה",
		"semelMosad": 560169,
		"settlement": "בני ברק",
		"manager": "שמואל לייב רוזנברג",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דרכי שמואל",
		"semelMosad": 631424,
		"settlement": "בית שמש",
		"manager": "שמעון כהן-קובלסקי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דרכי שרה",
		"semelMosad": 440677,
		"settlement": "ירושלים",
		"manager": "הדס ניימן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דרכים",
		"semelMosad": 566943,
		"settlement": "חולון",
		"manager": "חנית זלדיס",
		"level": "חט\"ב + עליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "דתי מיצד",
		"semelMosad": 142406,
		"settlement": "אספר",
		"manager": "יוסף זילברמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "האיריסים-כרמיאל",
		"semelMosad": 213892,
		"settlement": "כרמיאל",
		"manager": "מיכל קמינקר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "האלה",
		"semelMosad": 615559,
		"settlement": "כפר מנחם",
		"manager": "סיסי אדר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "האלה",
		"semelMosad": 172197,
		"settlement": "מודיעין-מכבים-רעות",
		"manager": "בתיה מועלם",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "האלה ממ\"ד",
		"semelMosad": 410266,
		"settlement": "נחושה",
		"manager": "שרה דמסקי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "האנתרופוסופי תמר",
		"semelMosad": 412460,
		"settlement": "הוד השרון",
		"manager": "מיטל טבק-שמש",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הארי מד צפת",
		"semelMosad": 212688,
		"settlement": "צפת",
		"manager": "עלי צרפתי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הארי ממד מגדל העמק",
		"semelMosad": 211193,
		"settlement": "מגדל העמק",
		"manager": "אליעזר שושני",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הבאר",
		"semelMosad": 414672,
		"settlement": "רחובות",
		"manager": "מרדכי יעקבזון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הבונים",
		"semelMosad": 310490,
		"settlement": "חיפה",
		"manager": "זיווה אמויאל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הבונים",
		"semelMosad": 311456,
		"settlement": "קרית ביאליק",
		"manager": "אסנת לוי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הביל\"ויים",
		"semelMosad": 442483,
		"settlement": "גדרה",
		"manager": "שלהבת בני",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הבילויים",
		"semelMosad": 512780,
		"settlement": "רמת גן",
		"manager": "בת שבע בר כהן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הבית העגול",
		"semelMosad": 474833,
		"settlement": "פרדס חנה-כרכור",
		"manager": "בוריס גורביץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הבן יקיר לי",
		"semelMosad": 132241,
		"settlement": "ירושלים",
		"manager": "דוד חי מולא",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הגבעה",
		"semelMosad": 610071,
		"settlement": "אופקים",
		"manager": "אסתר אטלי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הגומא אזורי כפר בלום",
		"semelMosad": 210963,
		"settlement": "כפר בלום",
		"manager": "אורנה וינברג",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הגורן",
		"semelMosad": 312397,
		"settlement": "חדרה",
		"manager": "נורית פריימן סבג",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הגליל",
		"semelMosad": 511279,
		"settlement": "תל אביב - יפו",
		"manager": "סיגלית ימין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הגליל נהריה",
		"semelMosad": 220392,
		"settlement": "נהריה",
		"manager": "אלון בודנשטיין",
		"level": "יסודי בלבד",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הגמנסיה העברית",
		"semelMosad": 140079,
		"settlement": "ירושלים",
		"manager": "דניאל לייבוביץ",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הגפן",
		"semelMosad": 510693,
		"settlement": "רמת גן",
		"manager": "ענת ליס",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הדביר החדש",
		"semelMosad": 223362,
		"settlement": "ירושלים",
		"manager": "רננה יצחקוב",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הדמוקרטי בלב השרון",
		"semelMosad": 413864,
		"settlement": "אבן יהודה",
		"manager": "יובל שפירא",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הדמוקרטי חדרה",
		"semelMosad": 340596,
		"settlement": "חדרה",
		"manager": "רותם רונן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הדמוקרטי כנף",
		"semelMosad": 611756,
		"settlement": "אלי-עד",
		"manager": "מנחם גורן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הדמוקרטי כנף",
		"semelMosad": 214601,
		"settlement": "אלי-עד",
		"manager": "עידו ברפמן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הדסה נעורים",
		"semelMosad": 470146,
		"settlement": "נעורים",
		"manager": "שלומית כהן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הדסים-יקנעם עלית",
		"semelMosad": 210906,
		"settlement": "יקנעם עילית",
		"manager": "רות כרמית פרינס",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הדקל",
		"semelMosad": 511162,
		"settlement": "תל אביב - יפו",
		"manager": "דפנה כהן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הדקל -כרמיאל",
		"semelMosad": 213124,
		"settlement": "כרמיאל",
		"manager": "עמית יניב",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הדר",
		"semelMosad": 410712,
		"settlement": "פתח תקווה",
		"manager": "חני גאן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הדר",
		"semelMosad": 413914,
		"settlement": "רעננה",
		"manager": "סגלית יהודאי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הדר",
		"semelMosad": 513325,
		"settlement": "רמת השרון",
		"manager": "יפעת-יפית שויקי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הדר",
		"semelMosad": 620344,
		"settlement": "שדרות",
		"manager": "מרים פרוכטר",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הדר ישראל",
		"semelMosad": 530014,
		"settlement": "נתניה",
		"manager": "יוסף שוקרון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הדר ציון",
		"semelMosad": 112664,
		"settlement": "ירושלים",
		"manager": "נתן אריה לוי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הדרור",
		"semelMosad": 114199,
		"settlement": "מבשרת ציון",
		"manager": "יעל אוברגוט",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הדרים",
		"semelMosad": 413831,
		"settlement": "ראשון לציון",
		"manager": "סימה זוסמן חן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הדרת משה",
		"semelMosad": 760116,
		"settlement": "ירושלים",
		"manager": "שמשון פרלמן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הוברמן",
		"semelMosad": 412841,
		"settlement": "פתח תקווה",
		"manager": "איילת אלון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הודיות",
		"semelMosad": 270223,
		"settlement": "הודיות",
		"manager": "עופר יהודה ירושלמי",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הזורע -פלגים",
		"semelMosad": 210575,
		"settlement": "הזורע",
		"manager": "הדס ורהפטיג",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "החדש",
		"semelMosad": 722231,
		"settlement": "ירושלים",
		"manager": "אהרן רפאלי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "החורש",
		"semelMosad": 312710,
		"settlement": "זכרון יעקב",
		"manager": "רונן וייס",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "החותרים",
		"semelMosad": 383927,
		"settlement": "טירת כרמל",
		"manager": "שושנה אבידן כהן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "החטיבה לחדשנות ויזמות ע\"ש יגאל",
		"semelMosad": 338392,
		"settlement": "יקנעם עילית",
		"manager": "אביבה סבג",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "החיטה ע\"ש אהרונסון",
		"semelMosad": 312793,
		"settlement": "זכרון יעקב",
		"manager": "רוחמה צדקה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "החיידר החסידי",
		"semelMosad": 761015,
		"settlement": "ירושלים",
		"manager": "שלום איינהורן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "החשמונאים",
		"semelMosad": 510701,
		"settlement": "רמת גן",
		"manager": "סגלית וינוקור",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "החשמונאים",
		"semelMosad": 512913,
		"settlement": "תל אביב - יפו",
		"manager": "עינת רויטר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הטנה",
		"semelMosad": 120436,
		"settlement": "ירושלים",
		"manager": "אמל חמאתי",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "היובל",
		"semelMosad": 614644,
		"settlement": "באר שבע",
		"manager": "מירב גורן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "היובל",
		"semelMosad": 614537,
		"settlement": "אילת",
		"manager": "שרון אסתר בניטה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "היובל",
		"semelMosad": 614503,
		"settlement": "אשדוד",
		"manager": "פנינה ויינשטיין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "היובל",
		"semelMosad": 511329,
		"settlement": "תל אביב - יפו",
		"manager": "שנית מזור",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "היובל",
		"semelMosad": 115642,
		"settlement": "מודיעין-מכבים-רעות",
		"manager": "רונית רבי זאדה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "היובל",
		"semelMosad": 413674,
		"settlement": "רעננה",
		"manager": "מיכל כרמלי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "היובל",
		"semelMosad": 415299,
		"settlement": "יהוד-מונוסון",
		"manager": "אודליה אלישע",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הייטק היי",
		"semelMosad": 540450,
		"settlement": "חולון",
		"manager": "גלי חנציס פריידין",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "היכל אברהם",
		"semelMosad": 519702,
		"settlement": "ביתר עילית",
		"manager": "חיים אלטר פריד",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "היכל משה",
		"semelMosad": 757625,
		"settlement": "ירושלים",
		"manager": "משה בוסו",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "היכל משה",
		"semelMosad": 519926,
		"settlement": "בית שמש",
		"manager": "יעקב חננ פלבני",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "היל\"ה מלכישוע-חסות",
		"semelMosad": 961003,
		"settlement": "מלכישוע",
		"manager": "דפנה ניומן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "היל\"ה רטורנו-חסות",
		"semelMosad": 961011,
		"settlement": "גבעת שמש",
		"manager": "רות פלצ'י",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "היסודי אור",
		"semelMosad": 175794,
		"settlement": "כפר האורנים",
		"manager": "הדס רכלבסקי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הירדן",
		"semelMosad": 511337,
		"settlement": "תל אביב - יפו",
		"manager": "יהודה פרידמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הירוק ע\"ש אריאל שרון",
		"semelMosad": 410084,
		"settlement": "הוד השרון",
		"manager": "רעות פישר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הירוק ע\"ש יצחק רבין",
		"semelMosad": 416586,
		"settlement": "יבנה",
		"manager": "עינת פוטרמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הירוק ע\"ש פרס",
		"semelMosad": 410118,
		"settlement": "כפר סבא",
		"manager": "עליזה סוקל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הישיבה אורט טבריה",
		"semelMosad": 244384,
		"settlement": "טבריה",
		"manager": "גלעד מסינג",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הישיבה הגדולה עטרת ש",
		"semelMosad": 722058,
		"settlement": "ראשון לציון",
		"manager": "יהונתן פוקסמן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הישיבה התיכונית ברוכין",
		"semelMosad": 482265,
		"settlement": "ברוכין",
		"manager": "יאיר אשר שטבון",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הישיבה לצעירים",
		"semelMosad": 755231,
		"settlement": "אשדוד",
		"manager": "יעקב אמסלם",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הישיבה לצעירים בית ש",
		"semelMosad": 758342,
		"settlement": "בית שמש",
		"manager": "רחל ויצמן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הלב הקדוש",
		"semelMosad": 328088,
		"settlement": "חיפה",
		"manager": "דמלזה סלאמה",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "הליכות שלמה",
		"semelMosad": 718932,
		"settlement": "אלעד",
		"manager": "יוסף עטרי הלוי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הליכות שרה",
		"semelMosad": 731943,
		"settlement": "חדרה",
		"manager": "רבקה עשורי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הלל",
		"semelMosad": 510719,
		"settlement": "רמת גן",
		"manager": "יורם בר קובץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הלל חמ\"ד ניסויי",
		"semelMosad": 616268,
		"settlement": "ירושלים",
		"manager": "יעל בירן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הלל יפה",
		"semelMosad": 384487,
		"settlement": "חדרה",
		"manager": "מזל עפרה אברגיל",
		"level": "יסודי חט\"ב ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הלל צור",
		"semelMosad": 414425,
		"settlement": "נתניה",
		"manager": "אמי לוי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "המאוחד",
		"semelMosad": 610709,
		"settlement": "ירוחם",
		"manager": "מינה אשטמקר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "המגינים",
		"semelMosad": 611962,
		"settlement": "אשדוד",
		"manager": "סמדר ווקנין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "המגינים",
		"semelMosad": 510511,
		"settlement": "חולון",
		"manager": "מורן לוי זאובר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "המגינים -קרית שמונה",
		"semelMosad": 212084,
		"settlement": "קרית שמונה",
		"manager": "שרית כרסנטי אוחנה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "המגן",
		"semelMosad": 415257,
		"settlement": "הוד השרון",
		"manager": "עינת אמויאל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "המגשימים",
		"semelMosad": 358127,
		"settlement": "ירושלים",
		"manager": "חגית רוזנהיים",
		"level": "חט\"ב + עליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "המדרשה החסידית",
		"semelMosad": 144196,
		"settlement": "ביתר עילית",
		"manager": "משה מלמוד",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "המדרשיה ישמח משה",
		"semelMosad": 637470,
		"settlement": "באר שבע",
		"manager": "מיכאל להיאני",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "המכינה התורנית מגיני",
		"semelMosad": 758136,
		"settlement": "בת ים",
		"manager": "דוד בטיש",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "המכללה לטכנולוגיה",
		"semelMosad": 171017,
		"settlement": "ירושלים",
		"manager": "דוד הנרי גרקו",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "המכללה למינהל מיסודה",
		"semelMosad": 755173,
		"settlement": "אילת",
		"manager": "עפר הרשקוביץ",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הממלכתי בכר",
		"semelMosad": 410027,
		"settlement": "אבן יהודה",
		"manager": "אסתר חודידה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הממלכתי צוקים",
		"semelMosad": 413096,
		"settlement": "שוהם",
		"manager": "לאה דיאמנט",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הממלכתי רבין",
		"semelMosad": 415265,
		"settlement": "שוהם",
		"manager": "טטיאנה דורנשטיין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "המנחיל",
		"semelMosad": 510727,
		"settlement": "רמת גן",
		"manager": "אילה אלבז",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "המסילה -נתיבות משה",
		"semelMosad": 613026,
		"settlement": "אשקלון",
		"manager": "דוד אופק",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "המסלה",
		"semelMosad": 113126,
		"settlement": "ירושלים",
		"manager": "משה כץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "המעין",
		"semelMosad": 120428,
		"settlement": "ירושלים",
		"manager": "יעל אביטל",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "המעפילים",
		"semelMosad": 412825,
		"settlement": "לוד",
		"manager": "מלי זריהן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "המרכז הישראלי למצוינות בחינוך",
		"semelMosad": 144097,
		"settlement": "ירושלים",
		"manager": "דן שטייניץ",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "המרכז להכשרה",
		"semelMosad": 362574,
		"settlement": "ירושלים",
		"manager": "יוסף שאליאן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "המרכז לעיצוב ולטכנול",
		"semelMosad": 521898,
		"settlement": "אריאל",
		"manager": "יוכבד יוכי פנחסי אדיב",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "המשותף",
		"semelMosad": 581165,
		"settlement": "תל אביב - יפו",
		"manager": "תקוה אלקלעי מימרן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "המשותף חוף הכרמל",
		"semelMosad": 770040,
		"settlement": "מעגן מיכאל",
		"manager": "שירלי וילסון",
		"level": "חט\"ב + עליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "המשיח האונגלית",
		"semelMosad": 217281,
		"settlement": "נצרת",
		"manager": "גריס מרג'יה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "המשיח האנגליקאני",
		"semelMosad": 248724,
		"settlement": "נצרת",
		"manager": "וסאם תלחמי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "המשלב קרית טבעון",
		"semelMosad": 311548,
		"settlement": "קרית טבעון",
		"manager": "תמר בן אלחנן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "המתמיד",
		"semelMosad": 510743,
		"settlement": "רמת גן",
		"manager": "שרון חיון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הנדיב",
		"semelMosad": 513093,
		"settlement": "הרצליה",
		"manager": "ציפי סויסה קהל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הנדיב",
		"semelMosad": 310086,
		"settlement": "בנימינה-גבעת עדה",
		"manager": "חיה קוגן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הנדסאים הרצליה",
		"semelMosad": 570358,
		"settlement": "הרצליה",
		"manager": "אורית רוזן",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הניסויי",
		"semelMosad": 111344,
		"settlement": "ירושלים",
		"manager": "עידית טבת",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הניסויי כפר אדומים",
		"semelMosad": 512459,
		"settlement": "כפר אדומים",
		"manager": "עמית טריסטר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הנסיוני",
		"semelMosad": 312322,
		"settlement": "חיפה",
		"manager": "גילה שויקה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הנריטה סאלד",
		"semelMosad": 320069,
		"settlement": "חיפה",
		"manager": "מאיה הרשקוביץ",
		"level": "יסודי בלבד",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הנשיא-מ\"מ. י.",
		"semelMosad": 512822,
		"settlement": "בת ים",
		"manager": "לבנה דבש",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הס",
		"semelMosad": 411611,
		"settlement": "פתח תקווה",
		"manager": "מסדה נחמד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הסמינר הירושלמי ביתר",
		"semelMosad": 460048,
		"settlement": "ביתר עילית",
		"manager": "לאה אנשין",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "העוגן לב השרון",
		"semelMosad": 482505,
		"settlement": "צור משה",
		"manager": "בתיה לאה אשכנזי",
		"level": "חט\"ב + עליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "העמק",
		"semelMosad": 761767,
		"settlement": "יפעת",
		"manager": "שימרית פולט אפרתי",
		"level": "חט\"ב בלבד",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "העמר",
		"semelMosad": 311092,
		"settlement": "כרם מהר\"ל",
		"manager": "סיגל רמון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הפארק",
		"semelMosad": 645853,
		"settlement": "באר שבע",
		"manager": "רמית שיבת",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הפטריאכלי הלטיני",
		"semelMosad": 217026,
		"settlement": "יפיע",
		"manager": "אלי קורזום",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "הפטריאכלי הלטיני",
		"semelMosad": 247205,
		"settlement": "ריינה",
		"manager": "איבית סאיג",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "הפטריארכה הלטינית",
		"semelMosad": 378117,
		"settlement": "ראמה",
		"manager": "נימר בראנסי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "הפטריארכט הלטינית",
		"semelMosad": 460626,
		"settlement": "יפיע",
		"manager": "קייסר ראשד",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "הפטריארכיה היונית האורתודוקסי",
		"semelMosad": 518076,
		"settlement": "תל אביב - יפו",
		"manager": "נג'לה חנחן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "הפתוח הדמוקרטי",
		"semelMosad": 541581,
		"settlement": "תל אביב - יפו",
		"manager": "תומר פרידמן שיינפיין",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הצבי ישראל",
		"semelMosad": 312504,
		"settlement": "קרית אתא",
		"manager": "רועי ווידלר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הצנע לכת",
		"semelMosad": 113746,
		"settlement": "ירושלים",
		"manager": "צירל כהן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הצרי",
		"semelMosad": 520130,
		"settlement": "תל אביב - יפו",
		"manager": "גלית ספוזניק",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הקריה למנהיגות ולמדעים לוד",
		"semelMosad": 470120,
		"settlement": "לוד",
		"manager": "אפרת שלום",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הקרייה",
		"semelMosad": 614552,
		"settlement": "אשדוד",
		"manager": "אסתר ראובני",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הר חומה ממ\"ד",
		"semelMosad": 267344,
		"settlement": "ירושלים",
		"manager": "הודיה עמירה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הר טוב",
		"semelMosad": 110734,
		"settlement": "צרעה",
		"manager": "דנה צבטלר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הר נבו",
		"semelMosad": 531905,
		"settlement": "תל אביב - יפו",
		"manager": "שירה לוי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הר נוף",
		"semelMosad": 213918,
		"settlement": "טבריה",
		"manager": "משה אליהו",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הר נוף ממ\"ד בנים",
		"semelMosad": 112391,
		"settlement": "ירושלים",
		"manager": "בנימין אפרגן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הר נוף ממד בנות",
		"semelMosad": 113217,
		"settlement": "ירושלים",
		"manager": "ברוריה בס",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הר-אדר",
		"semelMosad": 113084,
		"settlement": "הר אדר",
		"manager": "בתיה סמדר פינקלשטיין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הר-שכניה",
		"semelMosad": 213934,
		"settlement": "שכניה",
		"manager": "אילנה זוהר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הראה מד",
		"semelMosad": 510883,
		"settlement": "רמת גן",
		"manager": "לאה לימור חורי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הראל",
		"semelMosad": 613356,
		"settlement": "אשקלון",
		"manager": "סיגלית אביב",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הראל",
		"semelMosad": 612283,
		"settlement": "אשדוד",
		"manager": "איריס דדון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הראל",
		"semelMosad": 415489,
		"settlement": "לוד",
		"manager": "חנה מסטורוב",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הראל",
		"semelMosad": 612051,
		"settlement": "קרית מלאכי",
		"manager": "אבי חדד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הראל מד",
		"semelMosad": 512889,
		"settlement": "בת ים",
		"manager": "דניאלה פרץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הראל ממ\"ד",
		"semelMosad": 172023,
		"settlement": "בית שמש",
		"manager": "יאיר מרדכי בלולו",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הראשונים",
		"semelMosad": 474684,
		"settlement": "אבן יהודה",
		"manager": "עדי בירן פגץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הראשונים מעלות",
		"semelMosad": 214221,
		"settlement": "מעלות-תרשיחא",
		"manager": "ורוניקה שמאינוק גולברג",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הרב מאיר עוזיאל",
		"semelMosad": 113522,
		"settlement": "בית שמש",
		"manager": "אוריאל שמעון פדידה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הרב מימון ממ\"ד שלומי",
		"semelMosad": 212365,
		"settlement": "שלומי",
		"manager": "דקלה דהן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הרב קוק",
		"semelMosad": 611244,
		"settlement": "תלמים",
		"manager": "מירב סעדון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הרב תחומי עמק חרוד",
		"semelMosad": 260091,
		"settlement": "עין חרוד (מאוחד)",
		"manager": "לילך שטיין",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הרואה",
		"semelMosad": 613455,
		"settlement": "אשדוד",
		"manager": "אסתר עמר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הרואה",
		"semelMosad": 611178,
		"settlement": "שדרות",
		"manager": "אריק אזולאי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הרובע היהודי ממ\"ד",
		"semelMosad": 111690,
		"settlement": "ירושלים",
		"manager": "גרמי אדם טננבאום",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הרטמן בנות",
		"semelMosad": 442855,
		"settlement": "ירושלים",
		"manager": "עמיאל לנדאו",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הרי אילת",
		"semelMosad": 613919,
		"settlement": "אילת",
		"manager": "חגית איס",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הריאלי העברי חיפה",
		"semelMosad": 384255,
		"settlement": "חיפה",
		"manager": "אורן יהודה בן ג'ויה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הריאלי העברי חיפה",
		"semelMosad": 384263,
		"settlement": "חיפה",
		"manager": "עומר משה בן-ארי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הריאלי העברי חיפה",
		"semelMosad": 340216,
		"settlement": "חיפה",
		"manager": "מנדי מנחם רבינוביץ",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הריאלי העברי חיפה",
		"semelMosad": 310516,
		"settlement": "חיפה",
		"manager": "זהבית זליג",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הרים",
		"semelMosad": 353706,
		"settlement": "בנימינה-גבעת עדה",
		"manager": "אורנית אביבי עידן",
		"level": "חט\"ב + עליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הרכס",
		"semelMosad": 616714,
		"settlement": "לבון",
		"manager": "אליה זריהן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הרעות",
		"semelMosad": 213959,
		"settlement": "כרמיאל",
		"manager": "מיכל זינגר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הרצוג",
		"semelMosad": 416669,
		"settlement": "רחובות",
		"manager": "ציפי זלסקי בירמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הרצוג",
		"semelMosad": 412239,
		"settlement": "נתניה",
		"manager": "לימור בר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הרצוג",
		"semelMosad": 610527,
		"settlement": "באר שבע",
		"manager": "שושנה ששי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הרצוג מד-עכו",
		"semelMosad": 211854,
		"settlement": "עכו",
		"manager": "צופיה משה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הרצל",
		"semelMosad": 310524,
		"settlement": "חיפה",
		"manager": "תמר רבינר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הרצל",
		"semelMosad": 613299,
		"settlement": "קרית גת",
		"manager": "תמי רויזמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הרצל",
		"semelMosad": 513002,
		"settlement": "בת ים",
		"manager": "צור חביב",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "הרצל",
		"semelMosad": 410605,
		"settlement": "יהוד-מונוסון",
		"manager": "גלית אסא",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "השבעה",
		"semelMosad": 510073,
		"settlement": "אזור",
		"manager": "קרן בן כורש דנינו",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "השחף",
		"semelMosad": 310219,
		"settlement": "חדרה",
		"manager": "קרן דרורי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "השחר",
		"semelMosad": 110098,
		"settlement": "טל שחר",
		"manager": "חוה מגדל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "השיטה",
		"semelMosad": 413971,
		"settlement": "רחובות",
		"manager": "ליאור זליגמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "השלום ע\"ש יצחק רבין",
		"semelMosad": 640490,
		"settlement": "מצפה רמון",
		"manager": "מרים חוה אורנשטיין דה מלאך",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "השקמים",
		"semelMosad": 482356,
		"settlement": "הוד השרון",
		"manager": "אורית קורן",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "התבור",
		"semelMosad": 513150,
		"settlement": "חולון",
		"manager": "רווית מיכלס",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "התומר",
		"semelMosad": 420265,
		"settlement": "נס ציונה",
		"manager": "איילת חיה ביטון",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "התומר -עכו",
		"semelMosad": 211813,
		"settlement": "עכו",
		"manager": "פולה בן גוריון חדגאג",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "התיכון החברתי",
		"semelMosad": 370833,
		"settlement": "קרית אתא",
		"manager": "קרן ברנס",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "התיכון המקיף נשר",
		"semelMosad": 340091,
		"settlement": "נשר",
		"manager": "עידו שמש",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "התיכון הניסויי",
		"semelMosad": 771089,
		"settlement": "ירושלים",
		"manager": "גיל שלה",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "התיכון העירוני החדש",
		"semelMosad": 570192,
		"settlement": "הרצליה",
		"manager": "יהונתן אפרים אורון",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "התיכון התורני",
		"semelMosad": 142323,
		"settlement": "ירושלים",
		"manager": "בלה שרה וייס",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "התיכון התורני החדש-",
		"semelMosad": 742189,
		"settlement": "רחובות",
		"manager": "גאולה בן ברוך",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "התמר ממ\"ד",
		"semelMosad": 114058,
		"settlement": "אפרת",
		"manager": "שגיא סימון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ואדי אלחמאם",
		"semelMosad": 218206,
		"settlement": "חמאם",
		"manager": "איימן מוחמד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "ואת עלית",
		"semelMosad": 455477,
		"settlement": "בית שמש",
		"manager": "בת ציון פיירמן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "וולדורף אדם",
		"semelMosad": 114215,
		"settlement": "ירושלים",
		"manager": "אלינור אברהם",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "וזאת ליהודה",
		"semelMosad": 343921,
		"settlement": "בית שמש",
		"manager": "יוסף שלום גרמה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ויזניץ",
		"semelMosad": 513788,
		"settlement": "בני ברק",
		"manager": "וינברגר דוד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "וייצמן-נהריה",
		"semelMosad": 211474,
		"settlement": "נהריה",
		"manager": "יפית שניצר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "וייצמן-עכו",
		"semelMosad": 211821,
		"settlement": "עכו",
		"manager": "יוטה דוידוביץ מועטי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "וילקומיץ-ראש פינה",
		"semelMosad": 212142,
		"settlement": "ראש פינה",
		"manager": "ויקי מודלינגר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ויצמן",
		"semelMosad": 510412,
		"settlement": "הרצליה",
		"manager": "חגית איפרגן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ויצמן",
		"semelMosad": 513168,
		"settlement": "רמת גן",
		"manager": "מורן ארמי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ויצמן",
		"semelMosad": 311613,
		"settlement": "קרית מוצקין",
		"manager": "אלי הבר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ויצמן",
		"semelMosad": 412023,
		"settlement": "רחובות",
		"manager": "אילנית חן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ויצמן",
		"semelMosad": 410191,
		"settlement": "בית יהושע",
		"manager": "דפנה פרטוש",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ויתקין",
		"semelMosad": 411892,
		"settlement": "ראשון לציון",
		"manager": "זוהר ון לובין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ולדורף-הרדוף",
		"semelMosad": 213884,
		"settlement": "הרדוף",
		"manager": "אורי בדרי עברי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ונצואלה",
		"semelMosad": 525915,
		"settlement": "תל אביב - יפו",
		"manager": "רונה דולמן",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ופרצת-מוסדות ליו",
		"semelMosad": 733907,
		"settlement": "בית שמש",
		"manager": "משה לידר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ורודת אלעהד אלאסאסיה",
		"semelMosad": 238063,
		"settlement": "ירושלים",
		"manager": "פאתן דאגר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "ותתפלל חנה - בנות",
		"semelMosad": 514471,
		"settlement": "בני ברק",
		"manager": "שושנה חיה מעודד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ז'בוטינסקי",
		"semelMosad": 612846,
		"settlement": "שדרות",
		"manager": "ורד חנגלי משיח",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ז.שזר-עכו",
		"semelMosad": 213405,
		"settlement": "עכו",
		"manager": "כרמית אבי זמר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "זאב זבוטינסקי",
		"semelMosad": 112078,
		"settlement": "בית שמש",
		"manager": "ברכה ביטון גדנקן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "זאב זבוטינסקי",
		"semelMosad": 544171,
		"settlement": "הרצליה",
		"manager": "דנה מזרחי",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "זהרת אלמדאן",
		"semelMosad": 616201,
		"settlement": "ירושלים",
		"manager": "בשאאר נירוך סנדוקה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "זו אלנוריין",
		"semelMosad": 718924,
		"settlement": "ירושלים",
		"manager": "עבדלנאצר סלים",
		"level": "יסודי ועליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "זומר",
		"semelMosad": 510735,
		"settlement": "רמת גן",
		"manager": "אליה אלון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "זומר",
		"semelMosad": 560136,
		"settlement": "רמת גן",
		"manager": "ישראל אדם פיגנבלט",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "זיו אור",
		"semelMosad": 519223,
		"settlement": "כרמיאל",
		"manager": "יהודה ניסן שורץ",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "זיו אור נתניה",
		"semelMosad": 729574,
		"settlement": "נתניה",
		"manager": "נועם מנחם גרינבוים",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "זיו אור רמלה",
		"semelMosad": 524157,
		"settlement": "רמלה",
		"manager": "ישעיהו איסר שפריי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "זיו בו ציון",
		"semelMosad": 413906,
		"settlement": "רעננה",
		"manager": "כוכבה כהן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "זכרון ישראל",
		"semelMosad": 640342,
		"settlement": "נתיבות",
		"manager": "ראובן יוסף פילו",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "זכרון מאיר",
		"semelMosad": 213843,
		"settlement": "טבריה",
		"manager": "דוד אוחנה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "זכרון עולם",
		"semelMosad": 643833,
		"settlement": "ירושלים",
		"manager": "שלמה לנגנוער",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "זלמן ארן",
		"semelMosad": 111112,
		"settlement": "ירושלים",
		"manager": "ליזי עליזה אלמודאי אילוז",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "זלמן שז\"ר",
		"semelMosad": 620039,
		"settlement": "באר שבע",
		"manager": "מיכל לאה סלע",
		"level": "יסודי בלבד",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "זלפה",
		"semelMosad": 318154,
		"settlement": "מעלה עירון",
		"manager": "תיסיר ג'בארין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "זמרת הארץ",
		"semelMosad": 630251,
		"settlement": "עמיחי",
		"manager": "ערן הנדל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ח.ברנר",
		"semelMosad": 510396,
		"settlement": "הרצליה",
		"manager": "טרזה תמר מישאל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חב\"ד",
		"semelMosad": 615021,
		"settlement": "אופקים",
		"manager": "רינה אלהרר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חב\"ד",
		"semelMosad": 613653,
		"settlement": "נתיבות",
		"manager": "דוניז מלכה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חב\"ד",
		"semelMosad": 614255,
		"settlement": "אילת",
		"manager": "לאה הינדא אייזנבך",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חב\"ד",
		"semelMosad": 613828,
		"settlement": "אשדוד",
		"manager": "פנחס צבי מנדלזון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חב\"ד",
		"semelMosad": 313502,
		"settlement": "חדרה",
		"manager": "לאה בוטמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חב\"ד אור מנחם",
		"semelMosad": 412296,
		"settlement": "עמנואל",
		"manager": "לוי כהן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חב\"ד בית חנה",
		"semelMosad": 111260,
		"settlement": "ירושלים",
		"manager": "רבקה בלויא",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חב\"ד בנות",
		"semelMosad": 412791,
		"settlement": "רחובות",
		"manager": "קיילא רבקה גורביץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חב\"ד בנות",
		"semelMosad": 482695,
		"settlement": "ראשון לציון",
		"manager": "חנה אשכנזי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חב\"ד בנות",
		"semelMosad": 512087,
		"settlement": "תל אביב - יפו",
		"manager": "מיכאל בן ארוש",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חב\"ד בנות",
		"semelMosad": 615971,
		"settlement": "בית שמש",
		"manager": "חנה אריה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חב\"ד בנות",
		"semelMosad": 612234,
		"settlement": "קרית מלאכי",
		"manager": "שטרנה שרה כוכבה שוויכה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חב\"ד בנים",
		"semelMosad": 384495,
		"settlement": "חדרה",
		"manager": "מנחם מנדל סטזגובסקי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חב\"ד בנים",
		"semelMosad": 612994,
		"settlement": "קרית מלאכי",
		"manager": "אהרן מנגמי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חב\"ד בנים",
		"semelMosad": 236299,
		"settlement": "בית שמש",
		"manager": "יחיאל יהודה קופצ'יק",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חב\"ד חיה מושקא",
		"semelMosad": 416727,
		"settlement": "עמנואל",
		"manager": "רחל קיז'נר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חב\"ד חריש",
		"semelMosad": 384644,
		"settlement": "חריש",
		"manager": "סיגל מסעודה קידר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חב\"ד טבריה",
		"semelMosad": 630228,
		"settlement": "טבריה",
		"manager": "שרה רבקה זלמנוב",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חב\"ד לבנות בית מושקא",
		"semelMosad": 410530,
		"settlement": "מגדל העמק",
		"manager": "נחמה גורביץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חב\"ד לבנים ביתר עלית",
		"semelMosad": 112102,
		"settlement": "ביתר עילית",
		"manager": "יוסף הנריקה סירוצקי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חב\"ד מ\"ד",
		"semelMosad": 213165,
		"settlement": "נוף הגליל",
		"manager": "שטערנא שרה נפרסטק",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חב\"ד עטרת חי'ה מושקא",
		"semelMosad": 430124,
		"settlement": "ביתר עילית",
		"manager": "נחמה שחר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חבד מד -אביטל",
		"semelMosad": 212019,
		"settlement": "אביטל",
		"manager": "רחל אפרת קיל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חביב",
		"semelMosad": 411900,
		"settlement": "ראשון לציון",
		"manager": "ורד אבינל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חברון בנות",
		"semelMosad": 111195,
		"settlement": "קרית ארבע",
		"manager": "אלמוג גמדני",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חדוות התורה-ישיבת אמ",
		"semelMosad": 560441,
		"settlement": "ירושלים",
		"manager": "אהרן יצחק ברנדוין-חבצלת",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חדוות התלמוד",
		"semelMosad": 234054,
		"settlement": "מודיעין עילית",
		"manager": "יצחק רוזנבלט",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חדר ראשון לציון",
		"semelMosad": 411512,
		"settlement": "ראשון לציון",
		"manager": "אביגדור סילבר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חדרי תורה",
		"semelMosad": 760611,
		"settlement": "בני ברק",
		"manager": "אריה לייב קרמר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חדש אל זהרא",
		"semelMosad": 418343,
		"settlement": "כפר קאסם",
		"manager": "צפואת טאהא",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "חדשני",
		"semelMosad": 646166,
		"settlement": "שדרות",
		"manager": "נתנאל לוי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חדשני - ירכא",
		"semelMosad": 217018,
		"settlement": "ירכא",
		"manager": "נהיל עטאללה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "דרוזי"
	},
	{
		"schoolName": "חדשני בגין",
		"semelMosad": 413526,
		"settlement": "רחובות",
		"manager": "רוית קונקס-בן ציון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חדשני בן גוריון",
		"semelMosad": 415273,
		"settlement": "מזכרת בתיה",
		"manager": "אורית ארצי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חוג חתם סופר יסודי ה",
		"semelMosad": 513812,
		"settlement": "בני ברק",
		"manager": "יוסף צבי בנדיקט",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חוות דעת",
		"semelMosad": 142083,
		"settlement": "ירושלים",
		"manager": "בן ציון קוגלר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חוות דעת",
		"semelMosad": 112748,
		"settlement": "ירושלים",
		"manager": "בן ציון קוגלר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חוות הנוער הציוני",
		"semelMosad": 140095,
		"settlement": "ירושלים",
		"manager": "ליליה רייכמן",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חוט השני",
		"semelMosad": 172205,
		"settlement": "מודיעין-מכבים-רעות",
		"manager": "אילנה רחמילביץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חומת שמואל מ\"מ",
		"semelMosad": 192849,
		"settlement": "ירושלים",
		"manager": "שרון ברינה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חוני המעגל",
		"semelMosad": 210682,
		"settlement": "חצור הגלילית",
		"manager": "ענת ברוך",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חופים",
		"semelMosad": 631796,
		"settlement": "מודיעין-מכבים-רעות",
		"manager": "טלי שילטון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חופים",
		"semelMosad": 610659,
		"settlement": "יד מרדכי",
		"manager": "רחל כהן מצליח",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חופית",
		"semelMosad": 312421,
		"settlement": "חיפה",
		"manager": "זיוה טלבי-סולומון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חופית",
		"semelMosad": 414151,
		"settlement": "ראשון לציון",
		"manager": "אורית לובין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חופית",
		"semelMosad": 613703,
		"settlement": "אשדוד",
		"manager": "קרן לוין גפן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חוקי חיים",
		"semelMosad": 728865,
		"settlement": "ירושלים",
		"manager": "יהושע גלדציילר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חוקר יוצר",
		"semelMosad": 647578,
		"settlement": "ירושלים",
		"manager": "חגי אהרוני",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חורב",
		"semelMosad": 430884,
		"settlement": "ירושלים",
		"manager": "דליה רייך",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חורב בנות",
		"semelMosad": 447466,
		"settlement": "ירושלים",
		"manager": "דורית קליין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חורב מד",
		"semelMosad": 520577,
		"settlement": "תל אביב - יפו",
		"manager": "גלית ליאור ליפשיץ",
		"level": "יסודי בלבד",
		"special": "מיוחד",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חורפיש א-סולטאן אלאט",
		"semelMosad": 218248,
		"settlement": "חורפיש",
		"manager": "עמאד חוסין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "דרוזי"
	},
	{
		"schoolName": "חורשה ע\"ש יהודה שביט",
		"semelMosad": 220491,
		"settlement": "נעמן (מילואות)",
		"manager": "מירה ברקן",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חזון בית יעקב",
		"semelMosad": 660209,
		"settlement": "אלומה",
		"manager": "הדס מרציאנו",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חזון דוד",
		"semelMosad": 724062,
		"settlement": "מודיעין עילית",
		"manager": "אפרים מוסאי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חזון יעקב",
		"semelMosad": 612929,
		"settlement": "אשדוד",
		"manager": "תמיר רענן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חזון ישעיה",
		"semelMosad": 288100,
		"settlement": "ירושלים",
		"manager": "אברהם שריקי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חזון מאיר ובית ישראל",
		"semelMosad": 731745,
		"settlement": "ירושלים",
		"manager": "ליאור שמעון וזאנה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חזון עובדיה",
		"semelMosad": 610386,
		"settlement": "באר שבע",
		"manager": "דורון עודד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חזון שמעון",
		"semelMosad": 748731,
		"settlement": "חריש",
		"manager": "דוד פרטוש",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חט\"ב \"אלרואיא",
		"semelMosad": 247478,
		"settlement": "ירכא",
		"manager": "אנור גביש",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "דרוזי"
	},
	{
		"schoolName": "חט\"ב - שכונה צפונית",
		"semelMosad": 359489,
		"settlement": "טורעאן",
		"manager": "ואיל דחלה",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "חט\"ב א' אבן ח'לדון",
		"semelMosad": 249029,
		"settlement": "נצרת",
		"manager": "אמיר עאבד",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "חט\"ב א' אבן סינא",
		"semelMosad": 448217,
		"settlement": "קלנסווה",
		"manager": "נהאד זמירו",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "חט\"ב א' טירה",
		"semelMosad": 448118,
		"settlement": "טירה",
		"manager": "סאמיה עראקי",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "חט\"ב א' כפר מנדא",
		"semelMosad": 248815,
		"settlement": "כפר מנדא",
		"manager": "מוחמד זיאדנה",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "חט\"ב אבן ח'לדון",
		"semelMosad": 248682,
		"settlement": "טמרה",
		"manager": "מוחמד חג'אזי",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "חט\"ב אבן סינא",
		"semelMosad": 448167,
		"settlement": "כפר קאסם",
		"manager": "עדנאן סרסור",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "חט\"ב אבן סינא",
		"semelMosad": 348029,
		"settlement": "כפר קרע",
		"manager": "נואל כנאענה אעלמי",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "חט\"ב אבן רושד",
		"semelMosad": 248856,
		"settlement": "ג'דיידה-מכר",
		"manager": "בלאל כיאל",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "חט\"ב אדיגה כפר קמא",
		"semelMosad": 841155,
		"settlement": "כפר כמא",
		"manager": "גאולה אביב",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "צרקסי"
	},
	{
		"schoolName": "חט\"ב אורט אפק",
		"semelMosad": 344218,
		"settlement": "קרית ביאליק",
		"manager": "יורם רוזנברג",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חט\"ב אילן רמון",
		"semelMosad": 480103,
		"settlement": "כפר סבא",
		"manager": "עידו לביא",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חט\"ב אכסאל",
		"semelMosad": 249151,
		"settlement": "אכסאל",
		"manager": "מאג'ד אבו אלהיג'ה",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "חט\"ב אל גזאלי",
		"semelMosad": 348235,
		"settlement": "אום אל-פחם",
		"manager": "ריאד ג'בארין",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "חט\"ב אלון",
		"semelMosad": 444208,
		"settlement": "כפר סבא",
		"manager": "לבנה נוידרפר",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חט\"ב אלון",
		"semelMosad": 444133,
		"settlement": "רעננה",
		"manager": "מתי בורושק",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חט\"ב אלחיאת",
		"semelMosad": 448274,
		"settlement": "כפר קאסם",
		"manager": "זאהר עאמר",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "חט\"ב אלחכמה",
		"semelMosad": 453910,
		"settlement": "כפר מנדא",
		"manager": "איהאב עבד אל חלים",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "חט\"ב אלמוסרארה",
		"semelMosad": 641472,
		"settlement": "עין מאהל",
		"manager": "חוסיין אבו ליל",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "חט\"ב אלמותנבי",
		"semelMosad": 288209,
		"settlement": "טמרה",
		"manager": "מוחמד חג'אזי",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "חט\"ב אלנהדא",
		"semelMosad": 448191,
		"settlement": "זמר",
		"manager": "נגואן גרה",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "חט\"ב אלסלאם",
		"semelMosad": 448209,
		"settlement": "טייבה",
		"manager": "אברהים עבד אל קאדר",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "חט\"ב אלעין",
		"semelMosad": 247841,
		"settlement": "כפר יאסיף",
		"manager": "סאמור מסאעדה",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "חט\"ב אלפורעה",
		"semelMosad": 640607,
		"settlement": "דריג'את",
		"manager": "מוסה אלחמאיסה",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "חט\"ב אלפראבי",
		"semelMosad": 249169,
		"settlement": "טמרה",
		"manager": "טאהר דיאב",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "חט\"ב אלקלעה",
		"semelMosad": 641555,
		"settlement": "דייר חנא",
		"manager": "נפוז ח'טיב",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "חט\"ב ב' - אלראזי",
		"semelMosad": 347047,
		"settlement": "אום אל-פחם",
		"manager": "חנאן מחאג'נה",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "חט\"ב ב' טירה",
		"semelMosad": 448183,
		"settlement": "טירה",
		"manager": "כמאל בשארה",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "חט\"ב ב' ירכא",
		"semelMosad": 248187,
		"settlement": "ירכא",
		"manager": "סולטאן רמאל",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "דרוזי"
	},
	{
		"schoolName": "חט\"ב ב' כפר כנא",
		"semelMosad": 247304,
		"settlement": "כפר כנא",
		"manager": "בסאם ח'טיב",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "חט\"ב ב' כפר מנדא",
		"semelMosad": 248765,
		"settlement": "כפר מנדא",
		"manager": "מחמוד סמחאת",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "חט\"ב ב' ע\"ש שיח' עאטף משלב",
		"semelMosad": 640615,
		"settlement": "אבו סנאן",
		"manager": "לואי מרזוק",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "דרוזי"
	},
	{
		"schoolName": "חט\"ב ביחד -דה שליט ב",
		"semelMosad": 444216,
		"settlement": "רחובות",
		"manager": "קרן יעקב שגיא",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חט\"ב בן גוריון",
		"semelMosad": 444901,
		"settlement": "פתח תקווה",
		"manager": "ארני מרקוס",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חט\"ב בן צבי",
		"semelMosad": 544155,
		"settlement": "קרית אונו",
		"manager": "ורד אלבז",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חט\"ב בן צבי",
		"semelMosad": 444109,
		"settlement": "פתח תקווה",
		"manager": "מלכה פורמן",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חט\"ב בנים קרית ארבע",
		"semelMosad": 140889,
		"settlement": "קרית ארבע",
		"manager": "אבינועם טוביה הורוביץ",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חט\"ב ברזילי- קציר א",
		"semelMosad": 444166,
		"settlement": "רחובות",
		"manager": "מרסל שמואלי",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חט\"ב ברטעה",
		"semelMosad": 348292,
		"settlement": "בסמ\"ה",
		"manager": "ראיד כבהא",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "חט\"ב ברנר",
		"semelMosad": 444919,
		"settlement": "פתח תקווה",
		"manager": "סגלית לוי",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חט\"ב ג'לג'וליה",
		"semelMosad": 448316,
		"settlement": "ג'לג'וליה",
		"manager": "ג'יהן אעראר",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "חט\"ב גוונים",
		"semelMosad": 441444,
		"settlement": "ראש העין",
		"manager": "אורית דוידי",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חט\"ב גולדה מאיר",
		"semelMosad": 444463,
		"settlement": "נס ציונה",
		"manager": "אורלי פורת",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חט\"ב גורדון",
		"semelMosad": 544163,
		"settlement": "תל אביב - יפו",
		"manager": "ענת זיידמן",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חט\"ב דיר חנא",
		"semelMosad": 248252,
		"settlement": "דייר חנא",
		"manager": "סעיד ח'טיב",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "חט\"ב דפנה",
		"semelMosad": 370999,
		"settlement": "קרית ביאליק",
		"manager": "רעיה טובול",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חט\"ב היובל",
		"semelMosad": 441451,
		"settlement": "ראש העין",
		"manager": "לילך חוגי",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חט\"ב היובל",
		"semelMosad": 244194,
		"settlement": "רמת ישי",
		"manager": "שרה וקנין",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חט\"ב הרצוג",
		"semelMosad": 442046,
		"settlement": "קדימה-צורן",
		"manager": "יהונתן אנזל",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חט\"ב השחר",
		"semelMosad": 444224,
		"settlement": "הוד השרון",
		"manager": "פרח אהרונהיים",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חט\"ב השרון",
		"semelMosad": 444190,
		"settlement": "רעננה",
		"manager": "רעיה לוי",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חט\"ב זלמן ארן",
		"semelMosad": 540435,
		"settlement": "חולון",
		"manager": "חגית כראדי גואטה",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חט\"ב חדשה",
		"semelMosad": 420000,
		"settlement": "אכסאל",
		"manager": "סעיד דראושה",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "חט\"ב חדשה ברמות יצחק",
		"semelMosad": 344291,
		"settlement": "נשר",
		"manager": "רון שבתאי זאב פלד",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חט\"ב חיים בר-לב",
		"semelMosad": 444729,
		"settlement": "כפר סבא",
		"manager": "איתי דניאל קנטור",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חט\"ב חמישית",
		"semelMosad": 384628,
		"settlement": "טמרה",
		"manager": "סמיה יאסין",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "חט\"ב חצב",
		"semelMosad": 440966,
		"settlement": "אלפי מנשה",
		"manager": "ליאורה שימשוביץ",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חט\"ב יהונתן נתניהו",
		"semelMosad": 344374,
		"settlement": "קרית מוצקין",
		"manager": "קרן פנינה בבלי",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חט\"ב יהלו\"ם",
		"semelMosad": 441469,
		"settlement": "שוהם",
		"manager": "אתי ליסאני",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חט\"ב יונתן",
		"semelMosad": 444414,
		"settlement": "רעננה",
		"manager": "סוזי סיבוני",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חט\"ב יפיע",
		"semelMosad": 249144,
		"settlement": "יפיע",
		"manager": "שוכרי דהאמשה",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "חט\"ב יפיע",
		"semelMosad": 247338,
		"settlement": "יפיע",
		"manager": "מוחמד אנעים",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "חט\"ב יצחק רבין",
		"semelMosad": 141002,
		"settlement": "גבעת זאב",
		"manager": "מזל רחל שמש",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חט\"ב ישיבת הדרום",
		"semelMosad": 444497,
		"settlement": "רחובות",
		"manager": "יעקב ארביב",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חט\"ב כפר כנא",
		"semelMosad": 247262,
		"settlement": "כפר כנא",
		"manager": "עאמר עבאבסה",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "חט\"ב כפר קרע ב'",
		"semelMosad": 348284,
		"settlement": "כפר קרע",
		"manager": "סולטן אסמאעיל",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "חט\"ב לבונה",
		"semelMosad": 338509,
		"settlement": "בית אריה",
		"manager": "רן לבינסקי",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חט\"ב מבשרת",
		"semelMosad": 140855,
		"settlement": "מבשרת ציון",
		"manager": "גליה שניידר",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חט\"ב מוסמוס",
		"semelMosad": 344556,
		"settlement": "מעלה עירון",
		"manager": "חסן מחאג'נה",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "חט\"ב מוקייבלה",
		"semelMosad": 540617,
		"settlement": "מוקייבלה",
		"manager": "תאייד אל ג'מאל",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "חט\"ב מזרעה",
		"semelMosad": 640458,
		"settlement": "מזרעה",
		"manager": "פאדי מסרי",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "חט\"ב ממ\"ד בנים המר",
		"semelMosad": 441667,
		"settlement": "כפר סבא",
		"manager": "חיים חי חדד",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חט\"ב ממ\"ד סעד דע\"ת",
		"semelMosad": 660365,
		"settlement": "סעד",
		"manager": "יצחק שלומי",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חט\"ב ממ' ראשונים",
		"semelMosad": 444422,
		"settlement": "גני תקווה",
		"manager": "עינת לביא שבש",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חט\"ב מסעדה",
		"semelMosad": 249177,
		"settlement": "מסעדה",
		"manager": "זיד בטחיש",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "דרוזי"
	},
	{
		"schoolName": "חט\"ב מערבי",
		"semelMosad": 420570,
		"settlement": "בועיינה-נוג'ידאת",
		"manager": "רוחי דלאשה",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "חט\"ב מערבית",
		"semelMosad": 384412,
		"settlement": "ג'ת",
		"manager": "נביל ותד",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "חט\"ב משהד",
		"semelMosad": 442848,
		"settlement": "משהד",
		"manager": "חאלד שחאדה",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "חט\"ב נוה יונתן",
		"semelMosad": 441352,
		"settlement": "רמלה",
		"manager": "אתי בן חיים",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חט\"ב סביון-ני יהודה",
		"semelMosad": 482687,
		"settlement": "סביון",
		"manager": "אביטל קליין",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חט\"ב ע\"ש אילן רמון",
		"semelMosad": 444455,
		"settlement": "כוכב יאיר",
		"manager": "צפורה סוקולסקי",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חט\"ב ע\"ש דר' ג.חורי",
		"semelMosad": 247155,
		"settlement": "טורעאן",
		"manager": "נארימאן אבו ג'בל",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "חט\"ב ע\"ש יצחק רבין",
		"semelMosad": 344366,
		"settlement": "קרית מוצקין",
		"manager": "ליאורה מרים הגין",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חט\"ב ע\"ש מחמוד דרויש",
		"semelMosad": 249136,
		"settlement": "מג'ד אל-כרום",
		"manager": "חוסין סריס",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "חט\"ב ע\"ש עאטף חטיב",
		"semelMosad": 249268,
		"settlement": "שפרעם",
		"manager": "מועמר סגיר",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "חט\"ב ע\"ש קלמן",
		"semelMosad": 544080,
		"settlement": "רמת השרון",
		"manager": "אורית רוגובסקי",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חט\"ב ע\"ש שמעון פרס",
		"semelMosad": 573006,
		"settlement": "קרית אונו",
		"manager": "קרן הרוסי",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חט\"ב ע\"ש תאופיק זיאד",
		"semelMosad": 248146,
		"settlement": "נצרת",
		"manager": "חסן הייב",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "חט\"ב עארה",
		"semelMosad": 384461,
		"settlement": "ערערה",
		"manager": "סימאת יונס",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "חט\"ב עידנים",
		"semelMosad": 441733,
		"settlement": "רמלה",
		"manager": "שרונה שלו",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חט\"ב עילבון",
		"semelMosad": 249011,
		"settlement": "עיילבון",
		"manager": "ביאן נגידאת",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "חט\"ב עין ג'ראר",
		"semelMosad": 347062,
		"settlement": "אום אל-פחם",
		"manager": "אחמד סעאדה",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "חט\"ב עיסוואיה בנות",
		"semelMosad": 193003,
		"settlement": "ירושלים",
		"manager": "יאסמין בלעום",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "חט\"ב עיסוויה בנים",
		"semelMosad": 192997,
		"settlement": "ירושלים",
		"manager": "חדר עביד",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "חט\"ב עירוני ב-ג בנות",
		"semelMosad": 544577,
		"settlement": "תל אביב - יפו",
		"manager": "אביבה איטח",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חט\"ב עתיד",
		"semelMosad": 479428,
		"settlement": "כפר יונה",
		"manager": "כוכבית לנקרי",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חט\"ב עתידים",
		"semelMosad": 444877,
		"settlement": "הוד השרון",
		"manager": "טל ברגר",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חט\"ב פיאד אסדי דיר א",
		"semelMosad": 247189,
		"settlement": "דייר אל-אסד",
		"manager": "חוסיין אסדי",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "חט\"ב פסגות",
		"semelMosad": 444695,
		"settlement": "יהוד-מונוסון",
		"manager": "ליאת זולטה",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חט\"ב ק.הסביונים א'",
		"semelMosad": 444752,
		"settlement": "יהוד-מונוסון",
		"manager": "מיכל מיסה רבינוביץ'",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חט\"ב קלנסווה",
		"semelMosad": 448175,
		"settlement": "קלנסווה",
		"manager": "עבדאלה מרעי",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "חט\"ב קרית חינוך",
		"semelMosad": 244616,
		"settlement": "עכו",
		"manager": "מינה פרפרה",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חט\"ב ראס אל-עמוד",
		"semelMosad": 198085,
		"settlement": "ירושלים",
		"manager": "סאלח סאלח",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "חט\"ב ראשונים",
		"semelMosad": 444349,
		"settlement": "הוד השרון",
		"manager": "אביגיל קרפל רפפורט",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חט\"ב רבין",
		"semelMosad": 421149,
		"settlement": "פתח תקווה",
		"manager": "אסתר ביטון",
		"level": "חט\"ב בלבד",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חט\"ב רבין אזור",
		"semelMosad": 544676,
		"settlement": "אזור",
		"manager": "מירב אמדורסקי",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חט\"ב רוגוזין א'",
		"semelMosad": 344408,
		"settlement": "קרית אתא",
		"manager": "אילנית צמח ישראל",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חט\"ב רימון",
		"semelMosad": 444398,
		"settlement": "רעננה",
		"manager": "ארזה מיה",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חט\"ב שזר",
		"semelMosad": 444059,
		"settlement": "כפר סבא",
		"manager": "אבי אברהם גרצמן",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חט\"ב שניה- רמות יצחק",
		"semelMosad": 344333,
		"settlement": "נשר",
		"manager": "רמי רחמים בן אהרן",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חט\"ב שרת",
		"semelMosad": 444075,
		"settlement": "כפר סבא",
		"manager": "רימונה בנדיבה",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חט\"ב תיכון ריגלר",
		"semelMosad": 441212,
		"settlement": "נתניה",
		"manager": "אורי אהרון כהן",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חט\"ע ביר הדאג'",
		"semelMosad": 648287,
		"settlement": "ביר הדאג'",
		"manager": "סעיד אלגדיפי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "חט\"ע מכר-ג'דידה",
		"semelMosad": 248757,
		"settlement": "ג'דיידה-מכר",
		"manager": "ח'ליל מיעארי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "חט\"ע שפרירים-מסגרת מקדמת",
		"semelMosad": 770230,
		"settlement": "גבעת חיים (איחוד)",
		"manager": "אורלי יוסיפוביץ",
		"level": "עליונה בלבד",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חטב גת",
		"semelMosad": 349027,
		"settlement": "ג'ת",
		"manager": "עדנאן ותד",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "חטב ואדי אלנסור",
		"semelMosad": 348243,
		"settlement": "אום אל-פחם",
		"manager": "מוחמד ג'בארין",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "חטב ליאו בק",
		"semelMosad": 344093,
		"settlement": "חיפה",
		"manager": "ערן יוסף ברסלר",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חטב מכללת אורט גבעת רם ירושלים",
		"semelMosad": 141010,
		"settlement": "ירושלים",
		"manager": "מיכל דלאל",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חטב עלומים",
		"semelMosad": 544072,
		"settlement": "רמת השרון",
		"manager": "נתנה ברכר נפתלי",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חטב עמל' אבן גבירול",
		"semelMosad": 344044,
		"settlement": "חיפה",
		"manager": "יפעת שחר",
		"level": "חט\"ב + עליונה",
		"special": "מיוחד",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חטב עמלנית קורצ'אק",
		"semelMosad": 444372,
		"settlement": "פתח תקווה",
		"manager": "מרים לייבנר אקנין",
		"level": "יסודי חט\"ב ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חטב עמלנית רגבים",
		"semelMosad": 344028,
		"settlement": "חיפה",
		"manager": "אילנה לוי",
		"level": "חט\"ב + עליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חטב עמלנית שלום",
		"semelMosad": 444141,
		"settlement": "פתח תקווה",
		"manager": "שלומית-שלום ציון הגלילי וייל",
		"level": "חט\"ב + עליונה",
		"special": "מיוחד",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חטב ערערה משותף",
		"semelMosad": 348060,
		"settlement": "ערערה",
		"manager": "אבראהים אבו מולחם",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "חטב שזר",
		"semelMosad": 542282,
		"settlement": "קרית אונו",
		"manager": "שושנה גרגי",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חטיב אורט מינקןף",
		"semelMosad": 170399,
		"settlement": "ירושלים",
		"manager": "תמר כספי",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חטיבה 60",
		"semelMosad": 482786,
		"settlement": "כפר סבא",
		"manager": "מיכל ניסן",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חטיבה חריש",
		"semelMosad": 646588,
		"settlement": "חריש",
		"manager": "אביגיל פיית פסנטין",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חטיבה לגיל הצעיר",
		"semelMosad": 111294,
		"settlement": "ירושלים",
		"manager": "עינת סימן טוב",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חטיבה צעירה אורות עציון",
		"semelMosad": 111385,
		"settlement": "אפרת",
		"manager": "נגה פרץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חטיבת ביניים אלאהליה",
		"semelMosad": 463042,
		"settlement": "אום אל-פחם",
		"manager": "אימן ג'בארין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "חטיבת בראשית",
		"semelMosad": 482570,
		"settlement": "ראש העין",
		"manager": "מיכל רם מנשה",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חיואר",
		"semelMosad": 379842,
		"settlement": "חיפה",
		"manager": "אימאן דאוד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "חיואר",
		"semelMosad": 634840,
		"settlement": "ירושלים",
		"manager": "מנאל דראושה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "חיי שרה",
		"semelMosad": 482455,
		"settlement": "נצר חזני",
		"manager": "לימור פריד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חיי שרה בנות",
		"semelMosad": 114397,
		"settlement": "בית שמש",
		"manager": "עופרה סבאן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חיי תורה",
		"semelMosad": 476093,
		"settlement": "מודיעין עילית",
		"manager": "אשר זליג שוב",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חיידר אידיש חב\"ד",
		"semelMosad": 229617,
		"settlement": "ביתר עילית",
		"manager": "משה דוד מרקובסקי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חיים גורי",
		"semelMosad": 482414,
		"settlement": "נתניה",
		"manager": "אייל מוזיקנט",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חיים חפר",
		"semelMosad": 410316,
		"settlement": "נתניה",
		"manager": "רחלי סלייטר צרפתי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חינוך ברמה לבנות",
		"semelMosad": 174391,
		"settlement": "ירושלים",
		"manager": "רחל רוטנברג",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חינוך מיוחד",
		"semelMosad": 384529,
		"settlement": "קרית ביאליק",
		"manager": "אדוה מור",
		"level": "יסודי חט\"ב ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חינוך מיוחד",
		"semelMosad": 383943,
		"settlement": "באקה אל-גרביה",
		"manager": "שהנאז מסארווה ג'ורבאן",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "חינוך מיוחד יבנה",
		"semelMosad": 482638,
		"settlement": "יבנה",
		"manager": "דיקלה אסרף",
		"level": "יסודי בלבד",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חיפה אחוה",
		"semelMosad": 318162,
		"settlement": "חיפה",
		"manager": "מרי סעאדה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "חכמי לב",
		"semelMosad": 471995,
		"settlement": "ירושלים",
		"manager": "יונה הוכמן",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חכמת דוד",
		"semelMosad": 722124,
		"settlement": "אלעד",
		"manager": "שלמה בורנשטיין",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חכמת התורה",
		"semelMosad": 362103,
		"settlement": "מודיעין עילית",
		"manager": "יצחק ויס",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חכמת ישראל",
		"semelMosad": 633248,
		"settlement": "רכסים",
		"manager": "חנה משי",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חכמת ישראל",
		"semelMosad": 379693,
		"settlement": "רכסים",
		"manager": "רבקה רחמוט",
		"level": "יסודי בלבד",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חכמת שלמה",
		"semelMosad": 541409,
		"settlement": "בני ברק",
		"manager": "בנימין בירנבוים",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חכמת שלמה דבאבוב",
		"semelMosad": 542050,
		"settlement": "בני ברק",
		"manager": "יוסף יצחק פרנס",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חכמת שלמה רמות א",
		"semelMosad": 748459,
		"settlement": "ירושלים",
		"manager": "ישראל איכלר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חליל אלסכאכיני לבנות",
		"semelMosad": 148072,
		"settlement": "ירושלים",
		"manager": "סנא עטארי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "חלמיש",
		"semelMosad": 613323,
		"settlement": "ערד",
		"manager": "אילנה רוז",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חמ\"ד אהרון הרא\"ה בנים",
		"semelMosad": 355578,
		"settlement": "חיפה",
		"manager": "עידן בוסקילה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חמ\"ד אורי אורבך",
		"semelMosad": 411710,
		"settlement": "פתח תקווה",
		"manager": "ליאת אילון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חמ\"ד איתן",
		"semelMosad": 520015,
		"settlement": "בני ברק",
		"manager": "אסתר פורת",
		"level": "יסודי בלבד",
		"special": "מיוחד",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חמ\"ד אמית הלל",
		"semelMosad": 440040,
		"settlement": "רחובות",
		"manager": "מרים גיל",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חמ\"ד בורג",
		"semelMosad": 416982,
		"settlement": "מזכרת בתיה",
		"manager": "ליאור שיליאן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חמ\"ד תורני חבל יבנה",
		"semelMosad": 482158,
		"settlement": "ניר גלים",
		"manager": "דניאל יעקב מרקס",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חמ\"ד תיכון יבנה אולפנת חולון",
		"semelMosad": 540302,
		"settlement": "חולון",
		"manager": "שרה קניגסברג",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חמ\"ד תירוש",
		"semelMosad": 112284,
		"settlement": "ירושלים",
		"manager": "ברוריה מנדל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חמד אבו רביעה",
		"semelMosad": 618041,
		"settlement": "כסיפה",
		"manager": "אברהים אבו רביעה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "חמד אזורי ממד מירון",
		"semelMosad": 211276,
		"settlement": "מירון",
		"manager": "לירז נחמיאס",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חמד\"ת השקד",
		"semelMosad": 358655,
		"settlement": "מבשרת ציון",
		"manager": "רבקה קלרמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חמדת",
		"semelMosad": 613513,
		"settlement": "מיתר",
		"manager": "יאיר טוביה רוזנברג",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חמדת התורה",
		"semelMosad": 499756,
		"settlement": "בית שמש",
		"manager": "יצחק זולדן",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חמדת התורה",
		"semelMosad": 134692,
		"settlement": "ירושלים",
		"manager": "דב קוק",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חן השומרון",
		"semelMosad": 420208,
		"settlement": "רבבה",
		"manager": "אתי אסתר הדסה זהבי",
		"level": "יסודי וחט\"ב",
		"special": "מיוחד",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חן נריה",
		"semelMosad": 417832,
		"settlement": "אלון שבות",
		"manager": "אילנה סולטן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חן עפרה",
		"semelMosad": 113696,
		"settlement": "עופרה",
		"manager": "אודליה אלקסלסי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חנ\"מ",
		"semelMosad": 482729,
		"settlement": "ירושלים",
		"manager": "בילאל אבו רומי",
		"level": "יסודי בלבד",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "חנ\"מ חדש",
		"semelMosad": 623975,
		"settlement": "אשדוד",
		"manager": "מעין מסודי אברג'ל",
		"level": "יסודי בלבד",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חנה סנש",
		"semelMosad": 482810,
		"settlement": "באר יעקב",
		"manager": "חגית סקנדרני",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חנה סנש",
		"semelMosad": 313619,
		"settlement": "אור עקיבא",
		"manager": "מיטל משומר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חנוך לנער",
		"semelMosad": 741173,
		"settlement": "פתח תקווה",
		"manager": "דב טוקר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חנוך לנער שומרי אמון",
		"semelMosad": 500009,
		"settlement": "בני ברק",
		"manager": "שמעון מרדכי פרלמוטר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חניכי הישיבות",
		"semelMosad": 746354,
		"settlement": "בית שמש",
		"manager": "שלמה אשכנזי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חניכי הישיבות",
		"semelMosad": 611103,
		"settlement": "אשדוד",
		"manager": "יהודה דווידי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חניכי הישיבות",
		"semelMosad": 114306,
		"settlement": "ביתר עילית",
		"manager": "דוד בר ששת",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חניכי הישיבות - משך",
		"semelMosad": 761312,
		"settlement": "ירושלים",
		"manager": "אהרון נתנאל אלמלך",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חניכי הישיבות בנות",
		"semelMosad": 164186,
		"settlement": "ביתר עילית",
		"manager": "יעל רובידה בר ששת",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חניכי הישיבות-אור חדש",
		"semelMosad": 734616,
		"settlement": "ירושלים",
		"manager": "שמעון גרשון ישראל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חסד ורחמים",
		"semelMosad": 214403,
		"settlement": "בית שאן",
		"manager": "שמעון נגר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חסדי אבות",
		"semelMosad": 112763,
		"settlement": "ירושלים",
		"manager": "מנחם שלמה בקרמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חסדי אבות",
		"semelMosad": 344051,
		"settlement": "מודיעין עילית",
		"manager": "צבי ליפשיץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חסידי בעלזא",
		"semelMosad": 346742,
		"settlement": "בית שמש",
		"manager": "אליהו שלום ברקוביץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חסידי עמנואל",
		"semelMosad": 410381,
		"settlement": "עמנואל",
		"manager": "שמואל שוורצמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חסן ערפה יפו",
		"semelMosad": 518019,
		"settlement": "תל אביב - יפו",
		"manager": "שויקאר סקאללה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "חפץ חיים",
		"semelMosad": 655936,
		"settlement": "קרית שמונה",
		"manager": "תומר מיכאל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חפץ חיים",
		"semelMosad": 499749,
		"settlement": "קרית שמונה",
		"manager": "צופנת בן יחיאל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חפץ חיים",
		"semelMosad": 352716,
		"settlement": "חדרה",
		"manager": "יקותיאל יקיר כהן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חפץ חיים",
		"semelMosad": 363135,
		"settlement": "ביתר עילית",
		"manager": "לאה ישראל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חפץ חיים",
		"semelMosad": 359943,
		"settlement": "צפת",
		"manager": "צפורה פייגה שנלזון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חפץ חיים",
		"semelMosad": 647974,
		"settlement": "שדרות",
		"manager": "ידידיה דרשן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חפץ חיים אהל אשר-אופ",
		"semelMosad": 615773,
		"settlement": "אופקים",
		"manager": "חיים פרץ",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חצב",
		"semelMosad": 420828,
		"settlement": "ראשון לציון",
		"manager": "מורן אחיטוב",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חקלאי הכפר הירוק",
		"semelMosad": 580019,
		"settlement": "רמת השרון",
		"manager": "נעמי אלישיב",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חקלאי כנות",
		"semelMosad": 680066,
		"settlement": "כנות",
		"manager": "זהבית רותם",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חקלאי נחלת יהודה",
		"semelMosad": 480087,
		"settlement": "ראשון לציון",
		"manager": "יצחק צור טל",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חקלאי עיינות",
		"semelMosad": 480061,
		"settlement": "עיינות",
		"manager": "דנה אלוני יעקב",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חקלאי פרדס-חנה",
		"semelMosad": 380030,
		"settlement": "פרדס חנה-כרכור",
		"manager": "נטע קוסובסקי",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "חרובית כסרא",
		"semelMosad": 217166,
		"settlement": "כסרא-סמיע",
		"manager": "סקר שקור",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "דרוזי"
	},
	{
		"schoolName": "חתני פרס נובל",
		"semelMosad": 415497,
		"settlement": "ראשון לציון",
		"manager": "רויטל יחזקאל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "טאהא חוסין ערערה",
		"semelMosad": 318485,
		"settlement": "ערערה",
		"manager": "סמאהר אבו שרקיה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "טביטה הכנסיה הסקוטית",
		"semelMosad": 518043,
		"settlement": "תל אביב - יפו",
		"manager": "מונה אשקר",
		"level": "יסודי ועליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "טבנקין",
		"semelMosad": 513036,
		"settlement": "בת ים",
		"manager": "מרסלה זהר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "טהא חוסין סכנין",
		"semelMosad": 248708,
		"settlement": "סח'נין",
		"manager": "סלוא אבו ריא",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "טומשין אלביאן תלשבע",
		"semelMosad": 648089,
		"settlement": "תל שבע",
		"manager": "עאטף אבו רקייק",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "טומשין הבית של תמר",
		"semelMosad": 421198,
		"settlement": "ראשון לציון",
		"manager": "שירן ורדית גלזר רינג",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "טומשין הבית של תמר משמר",
		"semelMosad": 420372,
		"settlement": "משמר השרון",
		"manager": "תמר הוניג-חיות",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "טומשין הבית של תמר ר",
		"semelMosad": 653642,
		"settlement": "ראש העין",
		"manager": "סבטלנה קוזל",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "טומשין הבית של תמר שפיים",
		"semelMosad": 420976,
		"settlement": "נתניה",
		"manager": "יעל בן ארי בידנר",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "טומשין כעביה טבאש חג'אג'רה",
		"semelMosad": 247346,
		"settlement": "כעביה-טבאש-חג'אג'רה",
		"manager": "מתקאל סבתאן",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "טוקאייר",
		"semelMosad": 421156,
		"settlement": "בחן",
		"manager": "סימה אטיאס",
		"level": "יסודי בלבד",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "טור מכין לבנות",
		"semelMosad": 118133,
		"settlement": "ירושלים",
		"manager": "מנאל פתיאני",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "טור מכין לבנים",
		"semelMosad": 118125,
		"settlement": "ירושלים",
		"manager": "כאלד ראשיד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "טיור אלגנה אלתרבויה",
		"semelMosad": 511741,
		"settlement": "ירושלים",
		"manager": "איה עבדאללה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "טכלולוגי נעמת אלכרום",
		"semelMosad": 278234,
		"settlement": "מג'ד אל-כרום",
		"manager": "ראניה אסמאעיל",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "טכנולוגי אבו סנאן",
		"semelMosad": 247361,
		"settlement": "אבו סנאן",
		"manager": "יאסמין אמון",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "דרוזי"
	},
	{
		"schoolName": "טכנולוגי הארמון חנ\"מ",
		"semelMosad": 268029,
		"settlement": "אבו גוש",
		"manager": "וולאא גנאים",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "טכנולוגי נעמ\"ת",
		"semelMosad": 278267,
		"settlement": "ג'דיידה-מכר",
		"manager": "זינאת ארשיד",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "טכנולוגי נעמ\"ת נחף",
		"semelMosad": 278176,
		"settlement": "נחף",
		"manager": "סאוסאן קדורה",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "טכנולוגי נעמ\"ת שפרעם",
		"semelMosad": 278044,
		"settlement": "שפרעם",
		"manager": "כיתי בשארה",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "טכנולוגי נעמת ע.מאהל",
		"semelMosad": 278051,
		"settlement": "עין מאהל",
		"manager": "פדילה אבו ליל",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "טכנולוגי עירוני ויצו",
		"semelMosad": 470021,
		"settlement": "רחובות",
		"manager": "הרצל שקרצי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "טכנולוגי תורני נחלים",
		"semelMosad": 900076,
		"settlement": "נחלים",
		"manager": "אליהו טבול",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "טכני חיל האויר",
		"semelMosad": 670810,
		"settlement": "באר שבע",
		"manager": "יעקב דוידזון",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "טל חיים",
		"semelMosad": 110445,
		"settlement": "ירושלים",
		"manager": "יעקב מחפודה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "טל ישראל",
		"semelMosad": 758243,
		"settlement": "ירושלים",
		"manager": "חנן גבריאל אידלשטיין",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "טללי החיים",
		"semelMosad": 416255,
		"settlement": "רמלה",
		"manager": "אשר דגגה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "טללים",
		"semelMosad": 612358,
		"settlement": "ערד",
		"manager": "זאב אלבו",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "טלמון",
		"semelMosad": 165324,
		"settlement": "טלמון",
		"manager": "איתי אהרוני",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "טמרה של העמק",
		"semelMosad": 218305,
		"settlement": "טמרה (יזרעאל)",
		"manager": "ורוד סאלם",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "טף לטף",
		"semelMosad": 338202,
		"settlement": "מודיעין עילית",
		"manager": "אוסנת זילברמן",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "טרה סנטה",
		"semelMosad": 247064,
		"settlement": "נצרת",
		"manager": "סימון חרו",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "טרה סנטה",
		"semelMosad": 247015,
		"settlement": "עכו",
		"manager": "גאדה מח'ול",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "טרה סנטה",
		"semelMosad": 417014,
		"settlement": "רמלה",
		"manager": "עבדל משיח פהים",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "טרה סנטה",
		"semelMosad": 511485,
		"settlement": "תל אביב - יפו",
		"manager": "זריפה חדיד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "טרה סנטה",
		"semelMosad": 441113,
		"settlement": "רמלה",
		"manager": "ויולאט לורנזו",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "טרה סנטה בית חנינא",
		"semelMosad": 743369,
		"settlement": "ירושלים",
		"manager": "מישיל פראג",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "טרה סנטה גבשה בנים",
		"semelMosad": 148189,
		"settlement": "ירושלים",
		"manager": "איברהים פלתס",
		"level": "יסודי ועליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "טרה סנטה לבנות",
		"semelMosad": 148171,
		"settlement": "ירושלים",
		"manager": "חנאן פתחו",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "טרה סנטה נצרת",
		"semelMosad": 217117,
		"settlement": "נצרת",
		"manager": "לובנא אנדריא",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "טרה סנטה עכו",
		"semelMosad": 217190,
		"settlement": "עכו",
		"manager": "סוזאן שולי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "טריהאוס",
		"semelMosad": 475863,
		"settlement": "הרצליה",
		"manager": "",
		"level": "יסודי ועליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "טשרניחובסקי",
		"semelMosad": 310557,
		"settlement": "חיפה",
		"manager": "דורון וינברג",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "י.ח. ברנר",
		"semelMosad": 510321,
		"settlement": "גבעתיים",
		"manager": "מזל שלו",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "י.ישעיהו",
		"semelMosad": 513416,
		"settlement": "חולון",
		"manager": "שרה פרץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יאנוש קורצאק",
		"semelMosad": 111351,
		"settlement": "ירושלים",
		"manager": "אורית נבון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יאנוש קורצאק-ק.שמונה",
		"semelMosad": 212654,
		"settlement": "קרית שמונה",
		"manager": "שרה שורץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יאפא אלמוסתקבל ל\"ג",
		"semelMosad": 573105,
		"settlement": "תל אביב - יפו",
		"manager": "מוחמד כבוב",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "יאפת נצרת א",
		"semelMosad": 219279,
		"settlement": "יפיע",
		"manager": "דאהוד עלי סאלח",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "יאפת נצרת ד",
		"semelMosad": 219592,
		"settlement": "יפיע",
		"manager": "נאיל ח'טיב",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "יב\"ע בית יהודה",
		"semelMosad": 640151,
		"settlement": "כפר מימון",
		"manager": "עמיחי שי יומטוביאן",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יבוא ברינה",
		"semelMosad": 612721,
		"settlement": "נתיבות",
		"manager": "גאולה אברגל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יבנה",
		"semelMosad": 311027,
		"settlement": "קרית אתא",
		"manager": "ליאת בוזגלו",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יבנה",
		"semelMosad": 310318,
		"settlement": "חדרה",
		"manager": "משה עמרן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יבניאלי",
		"semelMosad": 310565,
		"settlement": "חיפה",
		"manager": "איילת אוסטר-ויסקוס",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יבניאלי",
		"semelMosad": 411975,
		"settlement": "רחובות",
		"manager": "אורית בנבניסטי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יגאל אלון",
		"semelMosad": 414573,
		"settlement": "הוד השרון",
		"manager": "ורד בנשוהם",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יגאל אלון",
		"semelMosad": 512590,
		"settlement": "גבעתיים",
		"manager": "רגינה אבירם",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יגאל אלון",
		"semelMosad": 310896,
		"settlement": "טירת כרמל",
		"manager": "אורנה עגיב",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יגאל אלון",
		"semelMosad": 242073,
		"settlement": "נוף הגליל",
		"manager": "אילן שמחי",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יגאל אלון",
		"semelMosad": 411819,
		"settlement": "קדימה-צורן",
		"manager": "זיו זיוה רבה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יגאל אלון-מגדל העמק",
		"semelMosad": 213504,
		"settlement": "מגדל העמק",
		"manager": "עינת וקנין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יגדיל תורה",
		"semelMosad": 725580,
		"settlement": "ירושלים",
		"manager": "יוסף שלום נתניאן",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יד אשר בנות",
		"semelMosad": 616417,
		"settlement": "ירושלים",
		"manager": "גילה אסולין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יד אשר בנים",
		"semelMosad": 616409,
		"settlement": "ירושלים",
		"manager": "אשר בן אבו",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יד ביד גשר על",
		"semelMosad": 328153,
		"settlement": "כפר קרע",
		"manager": "חסן אגבאריה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "יד המלך",
		"semelMosad": 640300,
		"settlement": "נתיבות",
		"manager": "ישי פישמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יד חזון",
		"semelMosad": 612713,
		"settlement": "באר טוביה",
		"manager": "ימית כהן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יד חרוצים",
		"semelMosad": 270249,
		"settlement": "טבריה",
		"manager": "שלום גרבי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יד יצחק",
		"semelMosad": 611434,
		"settlement": "קרית גת",
		"manager": "עדי חסנו שלום",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יד לבנים",
		"semelMosad": 412767,
		"settlement": "פתח תקווה",
		"manager": "אמיר פוני",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יד לחמישה -דבורה",
		"semelMosad": 210484,
		"settlement": "דבורה",
		"manager": "הילה כהן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יד מרדכי",
		"semelMosad": 512558,
		"settlement": "בת ים",
		"manager": "אביבית מיסטריאל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יד שבתאי",
		"semelMosad": 611905,
		"settlement": "אשדוד",
		"manager": "יוסף שלום גרוס",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יד-גיורא",
		"semelMosad": 544122,
		"settlement": "הרצליה",
		"manager": "רונית כהן",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ידיד ה'",
		"semelMosad": 186361,
		"settlement": "ירושלים",
		"manager": "שאול אריה מורגנשטרן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ידידיה",
		"semelMosad": 733881,
		"settlement": "פתח תקווה",
		"manager": "מנחם ידעי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ידלין",
		"semelMosad": 412932,
		"settlement": "ראשון לציון",
		"manager": "אורנה שלי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יהודה הלוי",
		"semelMosad": 110452,
		"settlement": "ירושלים",
		"manager": "חגית באבד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יהודה הלוי",
		"semelMosad": 420109,
		"settlement": "נתניה",
		"manager": "ענת יגדל",
		"level": "חט\"ב + עליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יהודה הלוי",
		"semelMosad": 430470,
		"settlement": "יהוד-מונוסון",
		"manager": "נעמי אילה קלרשטין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יהודה המכבי",
		"semelMosad": 518365,
		"settlement": "תל אביב - יפו",
		"manager": "איתי סוקולובר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יהושע גן",
		"semelMosad": 112094,
		"settlement": "בית אריה",
		"manager": "שי חלימה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יהלום",
		"semelMosad": 611897,
		"settlement": "עפולה",
		"manager": "פרח בר כהן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יהלום",
		"semelMosad": 612382,
		"settlement": "אשדוד",
		"manager": "רויטל חדוק",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יהלם",
		"semelMosad": 510768,
		"settlement": "רמת גן",
		"manager": "שירלי חביב",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יהלם-מעלות תרשיחא",
		"semelMosad": 211383,
		"settlement": "מעלות-תרשיחא",
		"manager": "איילת שבאבו",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יובלי הנגב",
		"semelMosad": 615526,
		"settlement": "גבעות בר",
		"manager": "מרים אלגרבלי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יובלים",
		"semelMosad": 482133,
		"settlement": "גני תקווה",
		"manager": "יערית חן עגיב",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יובלים",
		"semelMosad": 570259,
		"settlement": "אור יהודה",
		"manager": "נתנאל מאיר ברק",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יובלים",
		"semelMosad": 413773,
		"settlement": "קדימה-צורן",
		"manager": "רינה צבע",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יובלים",
		"semelMosad": 620260,
		"settlement": "ירושלים",
		"manager": "פנינה פלג-כהן",
		"level": "יסודי חט\"ב ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יובלים",
		"semelMosad": 614065,
		"settlement": "באר שבע",
		"manager": "רויטל מורחיים",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יודפת",
		"semelMosad": 212969,
		"settlement": "נוף הגליל",
		"manager": "הדס קרן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יוחנן הקדוש",
		"semelMosad": 317073,
		"settlement": "חיפה",
		"manager": "ג'רייס סלאמה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "יוחנני",
		"semelMosad": 513184,
		"settlement": "הרצליה",
		"manager": "עינת בן נאים",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יונתן",
		"semelMosad": 413484,
		"settlement": "נתניה",
		"manager": "מיטל מכלוף",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יוסף לקח בנות",
		"semelMosad": 267351,
		"settlement": "ירושלים",
		"manager": "רוני עובד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יוסף קארו",
		"semelMosad": 611657,
		"settlement": "באר שבע",
		"manager": "יורם רוש",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יזרעאליה",
		"semelMosad": 312090,
		"settlement": "חיפה",
		"manager": "לוטם קירמאיר קרולינסקי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יח\"ד",
		"semelMosad": 612960,
		"settlement": "אשדוד",
		"manager": "רחמה כהן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יחד",
		"semelMosad": 512673,
		"settlement": "חולון",
		"manager": "קרן אפשטיין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יחד",
		"semelMosad": 213827,
		"settlement": "גבעת אלה",
		"manager": "יפעת פבריקנט",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יחד",
		"semelMosad": 172171,
		"settlement": "מודיעין-מכבים-רעות",
		"manager": "שרי תבור",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יחדיו",
		"semelMosad": 413088,
		"settlement": "רעננה",
		"manager": "קרן סטלמך זוהר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יחדיו",
		"semelMosad": 520643,
		"settlement": "תל אביב - יפו",
		"manager": "רינת רחים בנימיני",
		"level": "יסודי בלבד",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יחדיו",
		"semelMosad": 614578,
		"settlement": "באר שבע",
		"manager": "ליאת סויסה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יחדיו",
		"semelMosad": 412189,
		"settlement": "רמת הכובש",
		"manager": "נועם שר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יחי ראובן",
		"semelMosad": 227108,
		"settlement": "ביתר עילית",
		"manager": "בן ציון בקר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יחל ישראל",
		"semelMosad": 311761,
		"settlement": "חיפה",
		"manager": "שיינה ליבה יפה ליבוביץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יחל ישראל",
		"semelMosad": 340570,
		"settlement": "חיפה",
		"manager": "חנה מלכה פרידמן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יחל ישראל",
		"semelMosad": 312975,
		"settlement": "חיפה",
		"manager": "ברוך פדר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ילקוט הרועים",
		"semelMosad": 632794,
		"settlement": "אל-רום",
		"manager": "גור גילעת",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ים התורה",
		"semelMosad": 633602,
		"settlement": "ירושלים",
		"manager": "שבתי ויספיש",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ים התורה",
		"semelMosad": 715300,
		"settlement": "ירושלים",
		"manager": "שבתי ויספיש",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ים סוף",
		"semelMosad": 615252,
		"settlement": "אילת",
		"manager": "רונית זגדון רביטל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ימין אורד",
		"semelMosad": 370155,
		"settlement": "ניר עציון",
		"manager": "מרים ברכה פרבר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ימין הרצוג",
		"semelMosad": 610048,
		"settlement": "אילת",
		"manager": "דוד נענה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ימית",
		"semelMosad": 312579,
		"settlement": "קרית ים",
		"manager": "שרה זהבה טל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יסדת עוז",
		"semelMosad": 482745,
		"settlement": "קדומים",
		"manager": "גלעד מנחם ברלין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יסוד החינוך",
		"semelMosad": 757682,
		"settlement": "ירושלים",
		"manager": "אלישבע בן דוד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יסוד המעלה",
		"semelMosad": 413948,
		"settlement": "ראשון לציון",
		"manager": "מיה שירי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יסודות",
		"semelMosad": 411694,
		"settlement": "פתח תקווה",
		"manager": "הדר יהוד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יסודות",
		"semelMosad": 620054,
		"settlement": "באר שבע",
		"manager": "נסים חדד",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יסודי",
		"semelMosad": 648741,
		"settlement": "דימונה",
		"manager": "תמי לדאני",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יסודי 1 רמת יצחק",
		"semelMosad": 313676,
		"settlement": "נשר",
		"manager": "חיה קלרית גולדנר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יסודי 12",
		"semelMosad": 384362,
		"settlement": "חיפה",
		"manager": "אסתר אזולאי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יסודי 1200",
		"semelMosad": 482752,
		"settlement": "הוד השרון",
		"manager": "עינת לנדיס",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יסודי 2",
		"semelMosad": 384347,
		"settlement": "טירת כרמל",
		"manager": "יפעת יוגב",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יסודי 3",
		"semelMosad": 384578,
		"settlement": "קרית מוצקין",
		"manager": "יפעת חזן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יסודי 5",
		"semelMosad": 616433,
		"settlement": "מרכז שפירא",
		"manager": "יוסף חיים דודי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יסודי 6",
		"semelMosad": 384420,
		"settlement": "נשר",
		"manager": "שרה אולנר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יסודי א כאבול",
		"semelMosad": 219188,
		"settlement": "כאבול",
		"manager": "מחמוד ריאן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "יסודי א' אכסאל",
		"semelMosad": 219162,
		"settlement": "אכסאל",
		"manager": "אחמד יחיא",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "יסודי א' בענה",
		"semelMosad": 219501,
		"settlement": "בענה",
		"manager": "מונה ח'ליל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "יסודי א' דיר חנא",
		"semelMosad": 219238,
		"settlement": "דייר חנא",
		"manager": "מוחמד שואהדה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "יסודי א' חורה",
		"semelMosad": 618652,
		"settlement": "חורה",
		"manager": "מנצור אבו סבית",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "יסודי א' טורעאן",
		"semelMosad": 219154,
		"settlement": "טורעאן",
		"manager": "נאדיה דחלה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "יסודי א' טמרה",
		"semelMosad": 218289,
		"settlement": "טמרה",
		"manager": "רוקיה ג'נאן דיאב מילחם",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "יסודי א' כפר מנדא",
		"semelMosad": 218438,
		"settlement": "כפר מנדא",
		"manager": "עזמי קדח",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "יסודי א' משהד",
		"semelMosad": 218537,
		"settlement": "משהד",
		"manager": "מריאן אבו אחמד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "יסודי א' עילוט",
		"semelMosad": 219600,
		"settlement": "עילוט",
		"manager": "יאסר אבו ראס",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "יסודי א' שעב",
		"semelMosad": 219485,
		"settlement": "שעב",
		"manager": "יאסר פאעור",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "יסודי א' שפרעם",
		"semelMosad": 219014,
		"settlement": "שפרעם",
		"manager": "זינה עליאן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "יסודי א'-אלעין",
		"semelMosad": 219337,
		"settlement": "נחף",
		"manager": "מנאל אעמר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "יסודי אבו סולב",
		"semelMosad": 633438,
		"settlement": "אבו תלול",
		"manager": "עליאן אבו קטיפאן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "יסודי אבן ח'לדון",
		"semelMosad": 217356,
		"settlement": "דבוריה",
		"manager": "מחמוד יוסף",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "יסודי אבן סינא",
		"semelMosad": 217513,
		"settlement": "טמרה",
		"manager": "יאסרה חג'אזי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "יסודי אבן סינא כאבול",
		"semelMosad": 217364,
		"settlement": "כאבול",
		"manager": "מירי קדורה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "יסודי אבן רושד",
		"semelMosad": 288175,
		"settlement": "עראבה",
		"manager": "אנאס רבאח",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "יסודי אום אלגנם",
		"semelMosad": 218081,
		"settlement": "שבלי - אום אל-גנם",
		"manager": "איאד כחילי סעאידה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "יסודי אומנויות",
		"semelMosad": 338350,
		"settlement": "יקנעם עילית",
		"manager": "אלישבע אסתר בר-אור",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יסודי אורטודוקסי",
		"semelMosad": 417030,
		"settlement": "רמלה",
		"manager": "רובלית פזע",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "יסודי אחבאב אלרחמאן",
		"semelMosad": 354837,
		"settlement": "ירושלים",
		"manager": "נאדר אבו עפיפה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "יסודי אל באטן",
		"semelMosad": 318881,
		"settlement": "אום אל-פחם",
		"manager": "ח'אלדיה מחאג'נה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "יסודי אלאימאן בנות",
		"semelMosad": 238501,
		"settlement": "ירושלים",
		"manager": "בהיה עאבדין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "יסודי אלאימאן בנים",
		"semelMosad": 758227,
		"settlement": "ירושלים",
		"manager": "וסאם אבו הדואן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "יסודי אלאימאן לבנים",
		"semelMosad": 238493,
		"settlement": "ירושלים",
		"manager": "דימא אבו אלסעוד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "יסודי אלאשראק עין קניה",
		"semelMosad": 217869,
		"settlement": "עין קנייא",
		"manager": "חסאן בשארה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "דרוזי"
	},
	{
		"schoolName": "יסודי אלבוכארי",
		"semelMosad": 219782,
		"settlement": "טמרה",
		"manager": "פיסל עואד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "יסודי אלבורג'",
		"semelMosad": 219774,
		"settlement": "שפרעם",
		"manager": "עומר ח'אלדי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "יסודי אלבסליה",
		"semelMosad": 219378,
		"settlement": "שפרעם",
		"manager": "יאסמין חדר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "יסודי אלחוארזמי",
		"semelMosad": 482281,
		"settlement": "טייבה",
		"manager": "היתם בראנסי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "יסודי אלחוארנה",
		"semelMosad": 338210,
		"settlement": "כפר קרע",
		"manager": "עודה עלימי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "יסודי אלחית כפר עקב",
		"semelMosad": 178194,
		"settlement": "ירושלים",
		"manager": "עיסא נאסר",
		"level": "יסודי ועליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "יסודי אלמסתקבל שועפט",
		"semelMosad": 512343,
		"settlement": "ירושלים",
		"manager": "נג'את עמורה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "יסודי אלנג'אח",
		"semelMosad": 227496,
		"settlement": "ג'דיידה-מכר",
		"manager": "ג'יהאן הואש",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "יסודי אלע'ג'ר",
		"semelMosad": 217851,
		"settlement": "ע'ג'ר",
		"manager": "חסן פתאלי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "דרוזי"
	},
	{
		"schoolName": "יסודי אלקאוס",
		"semelMosad": 338335,
		"settlement": "אום אל-פחם",
		"manager": "מחמוד ג'בארין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "יסודי אלשאפעי",
		"semelMosad": 482299,
		"settlement": "טייבה",
		"manager": "זאהר עבד אל קאדר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "יסודי אלשמאלי",
		"semelMosad": 430447,
		"settlement": "יפיע",
		"manager": "ערין טואפרה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "יסודי ארזים",
		"semelMosad": 214197,
		"settlement": "יקנעם עילית",
		"manager": "אורית נעוס",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יסודי ב'",
		"semelMosad": 410282,
		"settlement": "בוקעאתא",
		"manager": "מרואן שמס",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "דרוזי"
	},
	{
		"schoolName": "יסודי ב' אכסאל",
		"semelMosad": 217505,
		"settlement": "אכסאל",
		"manager": "אמג'ד דראושה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "יסודי ב' בענה",
		"semelMosad": 218107,
		"settlement": "בענה",
		"manager": "כראמה תיתי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "יסודי ב' חינוך מיוחד",
		"semelMosad": 627042,
		"settlement": "תל שבע",
		"manager": "גמילי אלעסיבי",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "יסודי ב' טובא",
		"semelMosad": 217430,
		"settlement": "טובא-זנגריה",
		"manager": "ויסאם הייב",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "יסודי ב' טורעאן",
		"semelMosad": 218263,
		"settlement": "טורעאן",
		"manager": "דועא עדוי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "יסודי ב' משהד",
		"semelMosad": 217273,
		"settlement": "משהד",
		"manager": "עיסאם סולימאן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "יסודי ב' עבדו סלים",
		"semelMosad": 218743,
		"settlement": "אעבלין",
		"manager": "אנג'אד אדלבי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "יסודי ב'-יפיע",
		"semelMosad": 217398,
		"settlement": "יפיע",
		"manager": "עיסאם חסן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "יסודי בגבעת רם",
		"semelMosad": 312637,
		"settlement": "קרית אתא",
		"manager": "איריס הראל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יסודי בוסמת טבעון",
		"semelMosad": 219261,
		"settlement": "בסמת טבעון",
		"manager": "סלוא נעראני",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "יסודי בוקעאתא",
		"semelMosad": 217810,
		"settlement": "בוקעאתא",
		"manager": "סוהאד טרביה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "דרוזי"
	},
	{
		"schoolName": "יסודי בית זרזיר",
		"semelMosad": 219410,
		"settlement": "זרזיר",
		"manager": "סאלם עיאדאת",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "יסודי בסביוני ים",
		"semelMosad": 376004,
		"settlement": "קרית ים",
		"manager": "רונית חסין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יסודי ג'",
		"semelMosad": 219717,
		"settlement": "ריינה",
		"manager": "מחמוד מסארוה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "יסודי ג'",
		"semelMosad": 217109,
		"settlement": "דייר חנא",
		"manager": "אימאן עתאמנה ח'לאילה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "יסודי ג'",
		"semelMosad": 384636,
		"settlement": "עילוט",
		"manager": "למיס גנאמה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "יסודי ג'",
		"semelMosad": 616441,
		"settlement": "מג'דל שמס",
		"manager": "בלקיס אבו סאלח",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "דרוזי"
	},
	{
		"schoolName": "יסודי ג' - נחף",
		"semelMosad": 219790,
		"settlement": "נחף",
		"manager": "ח'אלד עוסמאן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "יסודי ג' אכסאל",
		"semelMosad": 219550,
		"settlement": "אכסאל",
		"manager": "עזמי יחיא",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "יסודי ג' אעבלין",
		"semelMosad": 219576,
		"settlement": "אעבלין",
		"manager": "תג'ריד קאסם שיח' אחמד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "יסודי ג' בענה",
		"semelMosad": 219758,
		"settlement": "בענה",
		"manager": "שאדיה בדראן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "יסודי ג' טורעאן",
		"semelMosad": 217380,
		"settlement": "טורעאן",
		"manager": "נאסר נסאר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "יסודי ג' כפר כנא",
		"semelMosad": 219493,
		"settlement": "כפר כנא",
		"manager": "עביר עואודה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "יסודי ג' כפר מנדא",
		"semelMosad": 219626,
		"settlement": "כפר מנדא",
		"manager": "ח'דיג'ה זידאן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "יסודי ג' שפרעם",
		"semelMosad": 219105,
		"settlement": "שפרעם",
		"manager": "נג'אח ח'אזם",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "יסודי גוש חלב-ג'יש",
		"semelMosad": 218149,
		"settlement": "ג'ש (גוש חלב)",
		"manager": "רים ח'טיב",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "יסודי ד'",
		"semelMosad": 217463,
		"settlement": "כפר כנא",
		"manager": "אחמד אמארה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "יסודי ד'",
		"semelMosad": 348110,
		"settlement": "טורעאן",
		"manager": "רוז שעבאן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "יסודי ד' 'אלשרק'",
		"semelMosad": 219055,
		"settlement": "נצרת",
		"manager": "סוהייר פאהום עבד אלראזק",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "יסודי ד' אעבלין",
		"semelMosad": 219824,
		"settlement": "אעבלין",
		"manager": "מוחמד חג'אג'רה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "יסודי ד' כאבול",
		"semelMosad": 239814,
		"settlement": "כאבול",
		"manager": "סייף אל דין בושכאר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "יסודי ד' ע\"ש מוחמד סובח",
		"semelMosad": 219436,
		"settlement": "שפרעם",
		"manager": "מוופק יאסין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "יסודי ד' עבהרה",
		"semelMosad": 338368,
		"settlement": "עין מאהל",
		"manager": "ראיד חביבאללה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "יסודי דאר אלאולאד",
		"semelMosad": 338343,
		"settlement": "ירושלים",
		"manager": "אוסאמה מושעשע",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "יסודי דתי - באר גנים",
		"semelMosad": 610345,
		"settlement": "ניצן",
		"manager": "יקיר דוד זוכר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יסודי דתי חדש בנות",
		"semelMosad": 610600,
		"settlement": "בני דקלים",
		"manager": "הדס קליין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יסודי דתי חדש בנים",
		"semelMosad": 610618,
		"settlement": "בני דקלים",
		"manager": "ישראל יוקל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יסודי ה'",
		"semelMosad": 222364,
		"settlement": "כפר כנא",
		"manager": "ג'והינה עומר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "יסודי ה' כפר מנדא",
		"semelMosad": 219709,
		"settlement": "כפר מנדא",
		"manager": "מוסטפא עאלם",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "יסודי ה' ע\"ש שיח אחמד אלח'טיב",
		"semelMosad": 217349,
		"settlement": "שפרעם",
		"manager": "מחמוד ח'טיב",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "יסודי הר גילון",
		"semelMosad": 214205,
		"settlement": "גילון",
		"manager": "קרן ארד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יסודי הר הזיתים",
		"semelMosad": 717801,
		"settlement": "ירושלים",
		"manager": "רגדה אבו ג'מעה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "יסודי הרצוג",
		"semelMosad": 631044,
		"settlement": "אשקלון",
		"manager": "שרה סבטלנה קליסקר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יסודי השלום",
		"semelMosad": 228668,
		"settlement": "אבו גוש",
		"manager": "עמראן גמהור",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "יסודי ו'",
		"semelMosad": 418434,
		"settlement": "טירה",
		"manager": "סלים מנסור",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "יסודי ו'",
		"semelMosad": 247429,
		"settlement": "מגאר",
		"manager": "אנור גאנם",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "דרוזי"
	},
	{
		"schoolName": "יסודי ואדי אלעין",
		"semelMosad": 217521,
		"settlement": "סח'נין",
		"manager": "סואר שלאעטה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "יסודי ואדי סלאמה",
		"semelMosad": 219345,
		"settlement": "סלמה",
		"manager": "פאדי סואעד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "יסודי חדש",
		"semelMosad": 618660,
		"settlement": "שגב-שלום",
		"manager": "עארף אלעמור",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "יסודי חדש",
		"semelMosad": 482604,
		"settlement": "הוד השרון",
		"manager": "אורית רון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יסודי חדש באלהוואשלה",
		"semelMosad": 618728,
		"settlement": "קצר א-סר",
		"manager": "מחמד אלהואשלה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "יסודי חדש בבית פלט",
		"semelMosad": 618710,
		"settlement": "אבו קרינאת (יישוב)",
		"manager": "עודה אבו צבייח",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "יסודי חדש בגבעת טל",
		"semelMosad": 312751,
		"settlement": "קרית אתא",
		"manager": "איריס אנדרשי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יסודי חדש משותף",
		"semelMosad": 219725,
		"settlement": "בסמת טבעון",
		"manager": "חמדה חילף חמידה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "יסודי חדש קיסריה",
		"semelMosad": 312835,
		"settlement": "קיסריה",
		"manager": "רונית גבע",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יסודי חדש שכו 1",
		"semelMosad": 610568,
		"settlement": "תל שבע",
		"manager": "מחמד אבו עסא",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "יסודי טובא",
		"semelMosad": 218255,
		"settlement": "טובא-זנגריה",
		"manager": "עבד אל כרים הייב",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "יסודי טייבה העמק",
		"semelMosad": 218271,
		"settlement": "טייבה (בעמק)",
		"manager": "חאזם זועבי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "יסודי ינוח ב'",
		"semelMosad": 217471,
		"settlement": "יאנוח-ג'ת",
		"manager": "חוסיין חמדאן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "דרוזי"
	},
	{
		"schoolName": "יסודי כוחלה",
		"semelMosad": 618454,
		"settlement": "כחלה",
		"manager": "עלי אבו רביעה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "יסודי כפר מסר",
		"semelMosad": 218420,
		"settlement": "כפר מצר",
		"manager": "חסן זועבי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "יסודי מ\"מ רמת כורזים",
		"semelMosad": 214569,
		"settlement": "אליפלט",
		"manager": "שני דיין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יסודי מ\"מ רקפות",
		"semelMosad": 384032,
		"settlement": "קרית ביאליק",
		"manager": "לימור לוי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יסודי מ\"מ שערי תקוה",
		"semelMosad": 416941,
		"settlement": "שערי תקווה",
		"manager": "איתי קרמר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יסודי מוקיבלה",
		"semelMosad": 218495,
		"settlement": "מוקייבלה",
		"manager": "מוראד זיאדאת",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "יסודי מזרעה",
		"semelMosad": 218503,
		"settlement": "מזרעה",
		"manager": "מחמוד אגא",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "יסודי מכחול",
		"semelMosad": 618801,
		"settlement": "כחלה",
		"manager": "מוסה אבו עיאדה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "יסודי ממ לאומנויות",
		"semelMosad": 411751,
		"settlement": "יבנה",
		"manager": "רונית הראל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יסודי ממ\"ד",
		"semelMosad": 457291,
		"settlement": "כפר תפוח",
		"manager": "שלו אנגל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יסודי ממ\"ד לוד",
		"semelMosad": 482927,
		"settlement": "לוד",
		"manager": "תרצה שטרן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יסודי ממ\"ד קצרין",
		"semelMosad": 214171,
		"settlement": "קצרין",
		"manager": "מנחם ונינו",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יסודי ממלכתי אקולוגי",
		"semelMosad": 411595,
		"settlement": "יבנה",
		"manager": "שושנה גופשטיין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יסודי ממלכתי ברקן",
		"semelMosad": 442590,
		"settlement": "ברקן",
		"manager": "אתי יזרעאל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יסודי מנהיגות אומנו",
		"semelMosad": 719625,
		"settlement": "כפר קרע",
		"manager": "ופיק אגבאריה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "יסודי מעיליא",
		"semelMosad": 218529,
		"settlement": "מעיליא",
		"manager": "אליאס עבד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "יסודי נופים",
		"semelMosad": 214239,
		"settlement": "מגדל העמק",
		"manager": "ליליה נודל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יסודי נין",
		"semelMosad": 218560,
		"settlement": "ניין",
		"manager": "ע'דיר אבו רומחין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "יסודי סאגור",
		"semelMosad": 218685,
		"settlement": "סאג'ור",
		"manager": "סאוסן אבו חמיד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "דרוזי"
	},
	{
		"schoolName": "יסודי סביון-גני יהודה",
		"semelMosad": 411504,
		"settlement": "סביון",
		"manager": "ישראלה כדורי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יסודי סולם",
		"semelMosad": 218693,
		"settlement": "סולם",
		"manager": "אבתהאל זועבי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "יסודי סלמאן פרג'",
		"semelMosad": 218313,
		"settlement": "יאנוח-ג'ת",
		"manager": "סלימאן חטיב",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "דרוזי"
	},
	{
		"schoolName": "יסודי סנדלה",
		"semelMosad": 218735,
		"settlement": "צנדלה",
		"manager": "איה עומרי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "יסודי ע\"ש אברט",
		"semelMosad": 210674,
		"settlement": "חצור הגלילית",
		"manager": "רינת ביטון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יסודי ע\"ש אילן רמון",
		"semelMosad": 312744,
		"settlement": "חדרה",
		"manager": "שרית אוזן פניאל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יסודי ע\"ש יואב גבעתי",
		"semelMosad": 611202,
		"settlement": "נווה זוהר",
		"manager": "גרשון עמית",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יסודי ע\"ש יצחק נבון",
		"semelMosad": 384339,
		"settlement": "קרית מוצקין",
		"manager": "אסתר אפריאט",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יסודי ע\"ש כאמל סעדה",
		"semelMosad": 219006,
		"settlement": "שעב",
		"manager": "מוחמד סעדה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "יסודי ע\"ש לובה אליאב",
		"semelMosad": 615286,
		"settlement": "לכיש",
		"manager": "רינת רוזנפלד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יסודי ע\"ש תמרי",
		"semelMosad": 312736,
		"settlement": "אור עקיבא",
		"manager": "תמר לב",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יסודי עוזיר",
		"semelMosad": 218826,
		"settlement": "עוזייר",
		"manager": "עפיף יחיא",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "יסודי עוספייה",
		"semelMosad": 384057,
		"settlement": "עספיא",
		"manager": "עביר כיוף",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "דרוזי"
	},
	{
		"schoolName": "יסודי עילבון",
		"semelMosad": 218750,
		"settlement": "עיילבון",
		"manager": "סמיר אסוייד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "יסודי עין נקובא",
		"semelMosad": 118034,
		"settlement": "עין נקובא",
		"manager": "גאדה קזל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "יסודי ערבי נווה שלום",
		"semelMosad": 418400,
		"settlement": "לוד",
		"manager": "ראיד אזברגה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "יסודי עש פארוק שחידם",
		"semelMosad": 318295,
		"settlement": "עספיא",
		"manager": "גאוהרה מנסור",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "דרוזי"
	},
	{
		"schoolName": "יסודי פסגות\"-יראון",
		"semelMosad": 210914,
		"settlement": "יראון",
		"manager": "חוה פטריסיה טרכטנברג",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יסודי צומח ממ\"ד",
		"semelMosad": 482372,
		"settlement": "ראש העין",
		"manager": "מוריה חלמיש",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יסודי צור יצחק",
		"semelMosad": 412163,
		"settlement": "צור יצחק",
		"manager": "איל נחום",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יסודי רומאנה",
		"semelMosad": 354977,
		"settlement": "עוזייר",
		"manager": "סברי רחאל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "יסודי ריהאם",
		"semelMosad": 371930,
		"settlement": "מג'ד אל-כרום",
		"manager": "לביבה אדריס",
		"level": "יסודי בלבד",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "יסודי רכמה",
		"semelMosad": 632786,
		"settlement": "מסעודין אל-עזאזמה",
		"manager": "סלימאן אלפריגת",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "יסודי רמת כרמים",
		"semelMosad": 610501,
		"settlement": "אשקלון",
		"manager": "גלי פאר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יסודי שבלי",
		"semelMosad": 218933,
		"settlement": "שבלי - אום אל-גנם",
		"manager": "ג'אודאת שיבלי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "יסודי שיכון מערבי",
		"semelMosad": 317123,
		"settlement": "אום אל-פחם",
		"manager": "מוחמד שפיק",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "יסודי שכ' בית צפאפה",
		"semelMosad": 359828,
		"settlement": "ירושלים",
		"manager": "אמאן עליאן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "יסודי שכ' ג'משה",
		"semelMosad": 219816,
		"settlement": "מגאר",
		"manager": "לביב סרחאן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "דרוזי"
	},
	{
		"schoolName": "יסודי שכ' קחאוש",
		"semelMosad": 318873,
		"settlement": "אום אל-פחם",
		"manager": "סמאהר כבהא מחאג'נה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "יסודי שלישי",
		"semelMosad": 482919,
		"settlement": "כפר סבא",
		"manager": "חן דויטש",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יסודי תדהר",
		"semelMosad": 411330,
		"settlement": "עפולה",
		"manager": "סיון וידנפלד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יסודי תורני סביבתי",
		"semelMosad": 611111,
		"settlement": "מצפה רמון",
		"manager": "מרים קדוש",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יסודי תרשיחא",
		"semelMosad": 219030,
		"settlement": "מעלות-תרשיחא",
		"manager": "אקבאל בדוי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "יסמין",
		"semelMosad": 418293,
		"settlement": "לוד",
		"manager": "הנא אבו עיד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "יעבץ",
		"semelMosad": 310193,
		"settlement": "זכרון יעקב",
		"manager": "אפרת נישונית גבאי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יעד עמלני",
		"semelMosad": 520619,
		"settlement": "בת ים",
		"manager": "לימור דהן",
		"level": "חט\"ב + עליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יעל רום",
		"semelMosad": 573030,
		"settlement": "רמת השרון",
		"manager": "מיכל גטניו",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יעלים",
		"semelMosad": 612044,
		"settlement": "אילת",
		"manager": "לינור קטן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יעלים-עופרים",
		"semelMosad": 611459,
		"settlement": "ערד",
		"manager": "מזלית פורטונה שמעוני",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יעלת חן -חסידי לבנות",
		"semelMosad": 560417,
		"settlement": "ירושלים",
		"manager": "אורה רבקה וינגורט",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יעקב ניב",
		"semelMosad": 513200,
		"settlement": "חולון",
		"manager": "יעל אסף",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יערת העמק-מגדל העמק",
		"semelMosad": 244269,
		"settlement": "מגדל העמק",
		"manager": "אילן יצחקי",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יפה נוף",
		"semelMosad": 510305,
		"settlement": "תל אביב - יפו",
		"manager": "אורטל גמליאל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יפה נוף",
		"semelMosad": 413641,
		"settlement": "ראשון לציון",
		"manager": "אורנה פייגרסון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יפה נוף",
		"semelMosad": 611913,
		"settlement": "באר שבע",
		"manager": "נורית צעד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יפה נוף",
		"semelMosad": 111062,
		"settlement": "ירושלים",
		"manager": "רונית אהובה שמאא",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יפה נוף ממ\"ד",
		"semelMosad": 338723,
		"settlement": "מעלה אדומים",
		"manager": "חגית חדד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יפתח",
		"semelMosad": 191031,
		"settlement": "אבנת",
		"manager": "יריב פלר-יסף",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יצחק בן צבי",
		"semelMosad": 212357,
		"settlement": "שלומי",
		"manager": "סיגל אדרי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יצחק בן צבי",
		"semelMosad": 510420,
		"settlement": "הרצליה",
		"manager": "רדית ברוך אשר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יצחק נבון",
		"semelMosad": 612069,
		"settlement": "באר שבע",
		"manager": "פז ארבלי ברוך",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יצחק נבון",
		"semelMosad": 581421,
		"settlement": "אור יהודה",
		"manager": "רחל ענב",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יצחק נבון",
		"semelMosad": 412593,
		"settlement": "רחובות",
		"manager": "אביגיל מזור",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יצחק נבון",
		"semelMosad": 410233,
		"settlement": "מרכז אזורי שוהם",
		"manager": "מירב פלג",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יצחק נבון",
		"semelMosad": 630988,
		"settlement": "מיתר",
		"manager": "עירית כהן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יצחק נבון",
		"semelMosad": 566919,
		"settlement": "פתח תקווה",
		"manager": "רחל אדרי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יצחק נבון",
		"semelMosad": 579219,
		"settlement": "הרצליה",
		"manager": "שירלי רוזמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יצחק נבון",
		"semelMosad": 411215,
		"settlement": "מזכרת בתיה",
		"manager": "אריאלה הרפז",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יצחק רבין",
		"semelMosad": 613851,
		"settlement": "נתיבות",
		"manager": "מיכל כהן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יצחק רבין",
		"semelMosad": 611723,
		"settlement": "דימונה",
		"manager": "רונית היררי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יצחק רבין",
		"semelMosad": 614875,
		"settlement": "אשדוד",
		"manager": "אפרת טבול",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יצחק רבין",
		"semelMosad": 415232,
		"settlement": "הוד השרון",
		"manager": "מזל וינשטיין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יצחק רבין",
		"semelMosad": 313783,
		"settlement": "נשר",
		"manager": "לירז גורצקי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יצחק רבין",
		"semelMosad": 644690,
		"settlement": "אילת",
		"manager": "רינת לובלינר",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יצחק רבין",
		"semelMosad": 214155,
		"settlement": "כפר תבור",
		"manager": "קרן קורן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יצחק רגר",
		"semelMosad": 644534,
		"settlement": "באר שבע",
		"manager": "ענת ביביש רוס",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יצחק שדה",
		"semelMosad": 414235,
		"settlement": "כפר סבא",
		"manager": "מירב נורובטי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יצחק שדה",
		"semelMosad": 613257,
		"settlement": "דימונה",
		"manager": "נעמי מרסדס פניאן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יצחק שדה א",
		"semelMosad": 510230,
		"settlement": "בת ים",
		"manager": "גלית אטיאס",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יצחק שמיר",
		"semelMosad": 581314,
		"settlement": "תל אביב - יפו",
		"manager": "רן ענבר",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יצחק שמיר",
		"semelMosad": 514919,
		"settlement": "חולון",
		"manager": "אמיר פרץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "יראת ה''",
		"semelMosad": 722801,
		"settlement": "ירושלים",
		"manager": "אלישבע בארי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ירושלים",
		"semelMosad": 512830,
		"settlement": "בת ים",
		"manager": "אילת אילונה אנגל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ירושלים",
		"semelMosad": 312702,
		"settlement": "קרית אתא",
		"manager": "עליזה איפרח",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ירים משה",
		"semelMosad": 141762,
		"settlement": "ירושלים",
		"manager": "אהליאב נדף",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ירכא ממלכתי ב",
		"semelMosad": 218339,
		"settlement": "ירכא",
		"manager": "נואל אבו עבלה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "דרוזי"
	},
	{
		"schoolName": "יש' תיכונית בלבב שלם",
		"semelMosad": 641233,
		"settlement": "ירוחם",
		"manager": "יאיר פנחס יעקבי שווילי",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישבת תפארת ישראל",
		"semelMosad": 738575,
		"settlement": "מודיעין עילית",
		"manager": "נחום מרדכי טירהויז",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישגב",
		"semelMosad": 512467,
		"settlement": "תל אביב - יפו",
		"manager": "אלה שגיא",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישוע הנער דון בוסקו",
		"semelMosad": 217133,
		"settlement": "נצרת",
		"manager": "ג'נאן עבדו",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "ישועות יעקב ויוסף",
		"semelMosad": 514901,
		"settlement": "בת ים",
		"manager": "רחל עטיה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישועות משה",
		"semelMosad": 477638,
		"settlement": "ביתר עילית",
		"manager": "יקותיאל דוד זיידה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישורון",
		"semelMosad": 311399,
		"settlement": "פרדס חנה-כרכור",
		"manager": "שלמה ווזנה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישורון מ\"ד",
		"semelMosad": 510628,
		"settlement": "חולון",
		"manager": "יצחק שלום רוטנברג",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבה גדולה וכולל פנ",
		"semelMosad": 633370,
		"settlement": "ירושלים",
		"manager": "ישראל כהנא שפירא",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבה דחסידי בעלזא ב",
		"semelMosad": 728717,
		"settlement": "בית שמש",
		"manager": "אברהם גד פיליפ",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבה חיי תורה",
		"semelMosad": 460709,
		"settlement": "ירושלים",
		"manager": "חיים מויאל",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבה למצוינים רחובו",
		"semelMosad": 729087,
		"settlement": "רחובות",
		"manager": "שמואל חסין",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבה לצעירים",
		"semelMosad": 541672,
		"settlement": "בני ברק",
		"manager": "משה פרומר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבה לצעירים",
		"semelMosad": 444471,
		"settlement": "רחובות",
		"manager": "שלום זלמן כהנוביץ",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבה לצעירים",
		"semelMosad": 340547,
		"settlement": "זכרון יעקב",
		"manager": "יעקב יצחק זילברברג",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבה לצעירים",
		"semelMosad": 141200,
		"settlement": "ירושלים",
		"manager": "אהרן מיכאל נויבירט",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבה לצעירים",
		"semelMosad": 344531,
		"settlement": "קרית אתא",
		"manager": "צבי פוסטבסקי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבה לצעירים - בית",
		"semelMosad": 749572,
		"settlement": "צפת",
		"manager": "מנחם מענדל פרידמן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבה לצעירים אלעד",
		"semelMosad": 715318,
		"settlement": "אלעד",
		"manager": "אהרון קטנוב",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבה לצעירים אשדוד",
		"semelMosad": 728725,
		"settlement": "אשדוד",
		"manager": "חנוך דוב שרף",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבה לצעירים אשדוד",
		"semelMosad": 641357,
		"settlement": "אשדוד",
		"manager": "יצחק יעקב",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבה לצעירים באיאן",
		"semelMosad": 360826,
		"settlement": "ביתר עילית",
		"manager": "אהרן רבינוביץ",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבה לצעירים בעלז",
		"semelMosad": 460519,
		"settlement": "קרית יערים",
		"manager": "נפתלי פרידמן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבה לצעירים גור",
		"semelMosad": 644237,
		"settlement": "ערד",
		"manager": "חנוך אידלמן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבה לצעירים חיצים",
		"semelMosad": 441857,
		"settlement": "איתמר",
		"manager": "שי סויסה",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבה לצעירים מאור י",
		"semelMosad": 519579,
		"settlement": "רכסים",
		"manager": "נתנאל אפרים חיאק",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבה לצעירים סלונים",
		"semelMosad": 360834,
		"settlement": "ביתר עילית",
		"manager": "יעקב רוטנברג",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבה לצעירים עפולה",
		"semelMosad": 758151,
		"settlement": "עפולה",
		"manager": "פרוספר אשר דדון",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבה לצעירים עפולה",
		"semelMosad": 754432,
		"settlement": "עפולה",
		"manager": "שלמה ישראל גרינשטיין",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבה לצעירים פתח תק",
		"semelMosad": 729848,
		"settlement": "פתח תקווה",
		"manager": "אשר זנובסקי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבה לצעירים ק. שמו",
		"semelMosad": 519660,
		"settlement": "חיפה",
		"manager": "רחל חניה",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבה לצעירים רכסים",
		"semelMosad": 728808,
		"settlement": "רכסים",
		"manager": "נעה איצקוביץ",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבה לצעירים שומריה",
		"semelMosad": 615369,
		"settlement": "שומריה",
		"manager": "גדיאל שפנגנטל",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבה לצעירים תלציון",
		"semelMosad": 736256,
		"settlement": "כוכב יעקב",
		"manager": "יצחק הלי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבה קטנה ארחות מרד",
		"semelMosad": 722017,
		"settlement": "כוכב השחר",
		"manager": "מיכאל אלוש",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבה קטנה גור החדשה",
		"semelMosad": 631739,
		"settlement": "קרית גת",
		"manager": "יחזקאל וסרציג",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבה קטנה טשכנוב רמ",
		"semelMosad": 755439,
		"settlement": "ירושלים",
		"manager": "חיים שמואל בקרמן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבה קטנה למצויינים",
		"semelMosad": 541441,
		"settlement": "בני ברק",
		"manager": "איתמר דרבקין",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבה קטנה תורת חיים",
		"semelMosad": 442533,
		"settlement": "יד בנימין",
		"manager": "יצחק אמסלם",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבה שעלי תורה",
		"semelMosad": 144600,
		"settlement": "בית שמש",
		"manager": "רפאל משה אסולין",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבה תורה אור",
		"semelMosad": 141234,
		"settlement": "ירושלים",
		"manager": "סימור שיינברג",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבה תיכ' תורה ומדע",
		"semelMosad": 141754,
		"settlement": "ירושלים",
		"manager": "שמעון ביטון",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבה תיכונית",
		"semelMosad": 460691,
		"settlement": "מעלות-תרשיחא",
		"manager": "אלעד ברוך",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבה תיכונית",
		"semelMosad": 540849,
		"settlement": "בני ברק",
		"manager": "משה לייב ליקסנברג",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבה תיכונית",
		"semelMosad": 656132,
		"settlement": "מיתר",
		"manager": "",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבה תיכונית",
		"semelMosad": 641399,
		"settlement": "קרית גת",
		"manager": "נתנאל דהן",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבה תיכונית",
		"semelMosad": 644435,
		"settlement": "מצפה רמון",
		"manager": "ארי-חיים הנדלר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבה תיכונית",
		"semelMosad": 144212,
		"settlement": "קרית ארבע",
		"manager": "אבינועם טוביה הורוביץ",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבה תיכונית אגדלך",
		"semelMosad": 718056,
		"settlement": "ירושלים",
		"manager": "חיים אלבז",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבה תיכונית אילת",
		"semelMosad": 610170,
		"settlement": "אילת",
		"manager": "אמנון ויסבוים",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבה תיכונית אמי\"ת אלירז",
		"semelMosad": 442426,
		"settlement": "פתח תקווה",
		"manager": "עפר ארן",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבה תיכונית אמי\"ת צפת",
		"semelMosad": 240234,
		"settlement": "צפת",
		"manager": "יצחק נדביה נאה",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבה תיכונית בנ\"ע עלי",
		"semelMosad": 641498,
		"settlement": "עלי",
		"manager": "עובדיה בן משה",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבה תיכונית בנים",
		"semelMosad": 544494,
		"settlement": "רמת גן",
		"manager": "פתחיה ישראל זיו-אור",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבה תיכונית דובר טוב",
		"semelMosad": 729111,
		"settlement": "ירושלים",
		"manager": "הדסה משה סלושץ",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבה תיכונית דרכא נתיבות",
		"semelMosad": 640425,
		"settlement": "נתיבות",
		"manager": "צבי שילה",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבה תיכונית הר ברכה",
		"semelMosad": 482844,
		"settlement": "ברכה",
		"manager": "יהודית כהן",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבה תיכונית חדשה",
		"semelMosad": 640003,
		"settlement": "אשדוד",
		"manager": "שלום מלול",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבה תיכונית חיספין",
		"semelMosad": 240457,
		"settlement": "חספין",
		"manager": "חיים איזנטל",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבה תיכונית ממ\"ד",
		"semelMosad": 384545,
		"settlement": "חריש",
		"manager": "חגי שיל\"ת",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבה תיכונית נווה",
		"semelMosad": 641365,
		"settlement": "נווה",
		"manager": "אמיתי הורוביץ",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבה תכונית בר אילן",
		"semelMosad": 540484,
		"settlement": "תל אביב - יפו",
		"manager": "בנימין זנויל פרל",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבה תנכ\"ית זכרון יעקב",
		"semelMosad": 384370,
		"settlement": "זכרון יעקב",
		"manager": "יעקב גיטלמן",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת אביר יעקב",
		"semelMosad": 214098,
		"settlement": "נהריה",
		"manager": "יחזקאל רפאלי",
		"level": "יסודי ועליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת אבשלום",
		"semelMosad": 544361,
		"settlement": "בני ברק",
		"manager": "אברהם ישעיהו מלק",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת אהבת חיים",
		"semelMosad": 141291,
		"settlement": "כוכב השחר",
		"manager": "אריאל בן ברוך",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת אהבת תורה",
		"semelMosad": 342329,
		"settlement": "מודיעין עילית",
		"manager": "יעקב מאיר פלדמן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת אהל אברהם",
		"semelMosad": 729863,
		"settlement": "אלעד",
		"manager": "ישי חזן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת אהל יעקב",
		"semelMosad": 338673,
		"settlement": "אלעד",
		"manager": "אלישיב-דוד קוביוף",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת אוהל יוסף",
		"semelMosad": 719765,
		"settlement": "ירושלים",
		"manager": "אברהם מצליח",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת אוהל מועד",
		"semelMosad": 140582,
		"settlement": "ירושלים",
		"manager": "דוד כהן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת אוהל שמחה",
		"semelMosad": 141192,
		"settlement": "ירושלים",
		"manager": "משה צויבל",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת אוהל שמעון",
		"semelMosad": 140798,
		"settlement": "ירושלים",
		"manager": "בנימין סופר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת אוצר התורה",
		"semelMosad": 711556,
		"settlement": "ירושלים",
		"manager": "שמואל רבר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת אור אלחנן",
		"semelMosad": 140814,
		"settlement": "ירושלים",
		"manager": "מנחם מנדל רקובסקי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת אור אפריים",
		"semelMosad": 728733,
		"settlement": "בית שמש",
		"manager": "ארי מרטין רפאלוביץ",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת אור ברוך",
		"semelMosad": 140442,
		"settlement": "ירושלים",
		"manager": "גבריאל מונק",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת אור גאון",
		"semelMosad": 541102,
		"settlement": "בני ברק",
		"manager": "אהרן גבאי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת אור הרמ\"ז",
		"semelMosad": 361469,
		"settlement": "אשדוד",
		"manager": "ברוך טסלר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת אור ישראל",
		"semelMosad": 440800,
		"settlement": "פתח תקווה",
		"manager": "מרדכי רבינוביץ",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת אור מאיר שמחה",
		"semelMosad": 240747,
		"settlement": "נהריה",
		"manager": "ישראל ואנונו",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת אור משה",
		"semelMosad": 570580,
		"settlement": "תל אביב - יפו",
		"manager": "בנימין פינזל",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת אור עציון",
		"semelMosad": 640185,
		"settlement": "מרכז שפירא",
		"manager": "ערן פרינס",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת אורחות תורה",
		"semelMosad": 541748,
		"settlement": "בני ברק",
		"manager": "יוסף חזקיהו מישקובסקי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת אחינו לצעירים",
		"semelMosad": 362244,
		"settlement": "מודיעין עילית",
		"manager": "מיכאל אליעזר ברלין",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת אלוני הבשן",
		"semelMosad": 240622,
		"settlement": "אלוני הבשן",
		"manager": "רן יוסף שם-טוב",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת אלכסנדר",
		"semelMosad": 544239,
		"settlement": "בני ברק",
		"manager": "ירחמיאל מושקוביץ",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת אמי\"ת",
		"semelMosad": 644849,
		"settlement": "באר שבע",
		"manager": "סיון סרור",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת אמי\"ת נחשון",
		"semelMosad": 180034,
		"settlement": "נחם",
		"manager": "נועם קריגמן",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת אמי\"ת עמיחי",
		"semelMosad": 442368,
		"settlement": "רחובות",
		"manager": "שמעון שושן",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת אמרי אמת",
		"semelMosad": 540963,
		"settlement": "בני ברק",
		"manager": "אברהם מרדכי טרויבע",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת אמרי חיים ויז'ניץ",
		"semelMosad": 234047,
		"settlement": "מודיעין עילית",
		"manager": "אשר ויזניצר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת אמרי נועם",
		"semelMosad": 141614,
		"settlement": "ירושלים",
		"manager": "צבי קאהן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת ארחות משה",
		"semelMosad": 519603,
		"settlement": "בית שמש",
		"manager": "דניאל פרוש",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת ב\"ע אדרת",
		"semelMosad": 540674,
		"settlement": "בת ים",
		"manager": "איתן גמליאל",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת באור שמואל",
		"semelMosad": 141523,
		"settlement": "ירושלים",
		"manager": "נחשון אליעזר שילר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת באר אברהם",
		"semelMosad": 141457,
		"settlement": "ירושלים",
		"manager": "שלמה חיים וינברג",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת באר התורה",
		"semelMosad": 141481,
		"settlement": "ירושלים",
		"manager": "יוסף קריינדלר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת באר יהודה",
		"semelMosad": 160366,
		"settlement": "ירושלים",
		"manager": "יצחק טופיק",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת באר יצחק- מודי",
		"semelMosad": 754804,
		"settlement": "מודיעין עילית",
		"manager": "חיים משה גולדברג",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת באר שבע",
		"semelMosad": 660308,
		"settlement": "באר שבע",
		"manager": "אורן חיים קפלן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת בית אברהם",
		"semelMosad": 140921,
		"settlement": "ירושלים",
		"manager": "שלמה חיים וינברג",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת בית אשר",
		"semelMosad": 477091,
		"settlement": "ירושלים",
		"manager": "נח בלוי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת בית דוד",
		"semelMosad": 141093,
		"settlement": "ירושלים",
		"manager": "שלמה קרלנשטיין",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת בית דוד",
		"semelMosad": 640623,
		"settlement": "קרית גת",
		"manager": "יוסף יצחק זילברשטרום",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת בית חלקיה",
		"semelMosad": 444513,
		"settlement": "בית חלקיה",
		"manager": "יוסף עמרם מושקוביץ",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת בית יוסף",
		"semelMosad": 544247,
		"settlement": "בני ברק",
		"manager": "ברוך ויסבקר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת בית ישראל",
		"semelMosad": 540971,
		"settlement": "בני ברק",
		"manager": "זליג ליב ברברמן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת בית מאיר",
		"semelMosad": 541086,
		"settlement": "בני ברק",
		"manager": "מיכאל סבן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת בית מדרש עליון",
		"semelMosad": 540526,
		"settlement": "בני ברק",
		"manager": "ירחמיאל אונגרישער",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת בית מנחם",
		"semelMosad": 541565,
		"settlement": "בני ברק",
		"manager": "לוי יצחק רוזנברג",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת בית מרדכי",
		"semelMosad": 140574,
		"settlement": "ירושלים",
		"manager": "חיים אורי רבינוביץ",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת בית מרדכי",
		"semelMosad": 644369,
		"settlement": "אשדוד",
		"manager": "אשר זאב ויס",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת בית רפאל",
		"semelMosad": 735845,
		"settlement": "בני ברק",
		"manager": "אבנר בנימינוב",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת בית שפירא",
		"semelMosad": 544270,
		"settlement": "בני ברק",
		"manager": "צבי שינקר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת בנ\"ע אורות יהודה",
		"semelMosad": 142174,
		"settlement": "אפרת",
		"manager": "יהודה גייסון",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת בנ\"ע בר יוחאי מירון",
		"semelMosad": 755066,
		"settlement": "מירון",
		"manager": "יהונתן יוסף זומר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת בנ\"ע הדרום רחובות",
		"semelMosad": 440362,
		"settlement": "רחובות",
		"manager": "דוד טברסקי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת בנ\"ע חדרה",
		"semelMosad": 340356,
		"settlement": "חדרה",
		"manager": "אלמוג וגנר",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת בנ\"ע כינור דוד",
		"semelMosad": 142281,
		"settlement": "עטרת",
		"manager": "מרדכי יעקב הרשקוף",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת בנ\"ע כפר הרואה",
		"semelMosad": 440321,
		"settlement": "כפר הרא\"ה",
		"manager": "יעקב חביב",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת בנ\"ע לפיד מודיעין",
		"semelMosad": 165852,
		"settlement": "מודיעין-מכבים-רעות",
		"manager": "שמואל חיים רוזנבלום",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת בנ\"ע מטה בנימין",
		"semelMosad": 140939,
		"settlement": "בית אל",
		"manager": "נסים ניצן ימין",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת בנ\"ע נווה הרצוג",
		"semelMosad": 440487,
		"settlement": "אשדוד",
		"manager": "מאיר נסים אדרי",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת בנ\"ע נר תמיד חשמונאים",
		"semelMosad": 144766,
		"settlement": "חשמונאים",
		"manager": "איתמר ליברמן",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת בנ\"ע ע\"ש אביתר",
		"semelMosad": 244624,
		"settlement": "בית שאן",
		"manager": "אמיתי בן פזי",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת בנ\"ע פסגת זאב",
		"semelMosad": 144758,
		"settlement": "ירושלים",
		"manager": "בן ציון אליהו אלול",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת בנ\"ע רעננה",
		"semelMosad": 440370,
		"settlement": "רעננה",
		"manager": "ניר שקורי",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת בני אהרן",
		"semelMosad": 719799,
		"settlement": "אלעד",
		"manager": "מרדכי פולק",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת בני יוסף",
		"semelMosad": 144170,
		"settlement": "ירושלים",
		"manager": "בינימין איבנבאום",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת בני עקיבא",
		"semelMosad": 340232,
		"settlement": "חיפה",
		"manager": "יורם שמיר",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת בני עקיבא",
		"semelMosad": 214338,
		"settlement": "מירון",
		"manager": "אורית גרוס",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת בני עקיבא",
		"semelMosad": 440347,
		"settlement": "נתניה",
		"manager": "יאיר חיים שטדלר",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת בני עקיבא",
		"semelMosad": 640177,
		"settlement": "באר שבע",
		"manager": "רחמים פנש",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת בני עקיבא גבעת שמואל",
		"semelMosad": 441634,
		"settlement": "גבעת שמואל",
		"manager": "אריה יעקב פוגלמן",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת בני עקיבא השומרון",
		"semelMosad": 440958,
		"settlement": "קרני שומרון",
		"manager": "יעקב מאיר מייטליס",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת בני עקיבא מירון",
		"semelMosad": 270280,
		"settlement": "מירון",
		"manager": "מיכאל קאפח",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת בני עקיבא מכלו",
		"semelMosad": 746404,
		"settlement": "מעלה לבונה",
		"manager": "יאיר יצחק שחור",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת בני עקיבא נחלים",
		"semelMosad": 440339,
		"settlement": "נחלים",
		"manager": "אריאל אליעזר ליבוביץ",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת בני עקיבא ראשון לציון",
		"semelMosad": 444646,
		"settlement": "ראשון לציון",
		"manager": "אריאל רוקח",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת בני עקיבא תקות יעקב",
		"semelMosad": 240176,
		"settlement": "שדה יעקב",
		"manager": "מלאכי קאופמן",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת בני צבי בית אל",
		"semelMosad": 141739,
		"settlement": "בית אל",
		"manager": "יורם סבג",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת בני ראובן",
		"semelMosad": 758060,
		"settlement": "אלעד",
		"manager": "שמשון מיימן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת בניין אב",
		"semelMosad": 288159,
		"settlement": "ירושלים",
		"manager": "דוד אברהם",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת ברית יעקב",
		"semelMosad": 727271,
		"settlement": "ירושלים",
		"manager": "יואל טולדנו",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת ברכת אפרים",
		"semelMosad": 541243,
		"settlement": "בני ברק",
		"manager": "בצלאל פנחסי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת ברכת אריה",
		"semelMosad": 361477,
		"settlement": "ירושלים",
		"manager": "כתריאל כהן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת ברכת השם",
		"semelMosad": 729566,
		"settlement": "בית הגדי",
		"manager": "רחמים חניה",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת ברכת תורה",
		"semelMosad": 440636,
		"settlement": "ירושלים",
		"manager": "ישי גנתי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת ברסלב",
		"semelMosad": 344119,
		"settlement": "בני ברק",
		"manager": "נחמן אליעזר ברגשטין",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת ברסלב אור מוהר",
		"semelMosad": 749770,
		"settlement": "יבנאל",
		"manager": "מרדכי יעקבזון",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת ברסלב ביתר",
		"semelMosad": 747535,
		"settlement": "ביתר עילית",
		"manager": "מוריה אורנשטיין",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת ברסלב לצעירים-",
		"semelMosad": 499988,
		"settlement": "מודיעין עילית",
		"manager": "צבי יצחק יהודה שפיצר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת גאון צבי",
		"semelMosad": 541284,
		"settlement": "בני ברק",
		"manager": "נחמיה הורביץ",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת דברי חיים",
		"semelMosad": 440768,
		"settlement": "נתניה",
		"manager": "מאיר שכטר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת דברי מלך",
		"semelMosad": 729038,
		"settlement": "בית שמש",
		"manager": "פרומה עדינה גפן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת דברי מרדכי",
		"semelMosad": 542084,
		"settlement": "בני ברק",
		"manager": "יעקב יוסף מן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת דברי שלמה",
		"semelMosad": 362640,
		"settlement": "מודיעין עילית",
		"manager": "נתן שמריהו כהן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת דובר שלום",
		"semelMosad": 641225,
		"settlement": "אשדוד",
		"manager": "יוסף צבי קוט",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת דעת תורה",
		"semelMosad": 165472,
		"settlement": "ירושלים",
		"manager": "מנחם חיים היילברון",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת דרך אמונה",
		"semelMosad": 560524,
		"settlement": "תירוש",
		"manager": "יצחק הרמן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת דרך התורה",
		"semelMosad": 141580,
		"settlement": "מודיעין עילית",
		"manager": "צבי מיכאל גרוס",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת דרכא בני יששכר",
		"semelMosad": 660266,
		"settlement": "באר גנים",
		"manager": "נטף מויאל",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת דרכי דוד",
		"semelMosad": 519405,
		"settlement": "בני ברק",
		"manager": "אליהו יגודיוב",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת דרכי יוסף",
		"semelMosad": 654020,
		"settlement": "ירושלים",
		"manager": "חנינה עטר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת דרכי משה-אברהם",
		"semelMosad": 544346,
		"settlement": "בני ברק",
		"manager": "חיים שינקר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת דרכי נעם",
		"semelMosad": 441204,
		"settlement": "פתח תקווה",
		"manager": "משה רפאל מן",
		"level": "יסודי ועליונה",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת הבוכרים",
		"semelMosad": 441063,
		"settlement": "כפר חב\"ד",
		"manager": "מנחם הלל גורביץ",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת הדרת מנחם",
		"semelMosad": 520155,
		"settlement": "ביתר עילית",
		"manager": "מרדכי שלמה מיזליק",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת הזורעים",
		"semelMosad": 240812,
		"settlement": "הזורעים",
		"manager": "ניר וינברג",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת היכל יצחק",
		"semelMosad": 745968,
		"settlement": "ירושלים",
		"manager": "יצחק פראגר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת היכל שמואל",
		"semelMosad": 633388,
		"settlement": "בני ברק",
		"manager": "אברהם ישעיהו פרובר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת הישוב החדש",
		"semelMosad": 544031,
		"settlement": "תל אביב - יפו",
		"manager": "יונתן אלכסנדר גולדסמיט",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת המתמיד קרית שמונה",
		"semelMosad": 240218,
		"settlement": "קרית שמונה",
		"manager": "אריאל פריש",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת הרב עמיאל",
		"semelMosad": 540476,
		"settlement": "תל אביב - יפו",
		"manager": "ארקדי בקרמן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת ויזניץ",
		"semelMosad": 541151,
		"settlement": "בני ברק",
		"manager": "מנחם ארנסטר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת זהר התורה",
		"semelMosad": 141630,
		"settlement": "ירושלים",
		"manager": "דוד אוזן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת זכרון אליעזר",
		"semelMosad": 142331,
		"settlement": "ירושלים",
		"manager": "אברהם ברוך אבא רקובסקי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת זכרון דוד",
		"semelMosad": 541391,
		"settlement": "בני ברק",
		"manager": "מנשה נגר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת זכרון מיכאל",
		"semelMosad": 649103,
		"settlement": "זכרון יעקב",
		"manager": "דוד-בער סברדלוב",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת זכרון סיני",
		"semelMosad": 544205,
		"settlement": "בני ברק",
		"manager": "משה אלעזר וולף",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת זרע יצחק",
		"semelMosad": 144147,
		"settlement": "ירושלים",
		"manager": "חיים צבי גרין",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת חב\"ד פתח תקוה",
		"semelMosad": 732529,
		"settlement": "פתח תקווה",
		"manager": "איתן גלבוע",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת חורב",
		"semelMosad": 140145,
		"settlement": "ירושלים",
		"manager": "יצחק דור",
		"level": "יסודי ועליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת חזון נחום",
		"semelMosad": 541847,
		"settlement": "בני ברק",
		"manager": "שלמה זכריה קוסטליץ",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת חזון נחום",
		"semelMosad": 541854,
		"settlement": "בני ברק",
		"manager": "שלמה זכריה קוסטליץ",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת חזון נחום",
		"semelMosad": 541201,
		"settlement": "בני ברק",
		"manager": "שלמה זכריה קוסטליץ",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת חידושי הרי\"מ",
		"semelMosad": 590349,
		"settlement": "תל אביב - יפו",
		"manager": "ישראל גינזבורג",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת חיי משה",
		"semelMosad": 144261,
		"settlement": "ירושלים",
		"manager": "בנימין בייניש קאהן-מינץ",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת חכמה ודעת",
		"semelMosad": 722033,
		"settlement": "ירושלים",
		"manager": "נחמן אדרי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת חכמי לובלין",
		"semelMosad": 541987,
		"settlement": "בני ברק",
		"manager": "יעקב לנדאו",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת חכמת שלמה",
		"semelMosad": 441345,
		"settlement": "רחובות",
		"manager": "יעקב אליעזר לזרוביץ",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת חמד",
		"semelMosad": 560607,
		"settlement": "בני ברק",
		"manager": "מרדכי דרורי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת חמדת משה",
		"semelMosad": 361543,
		"settlement": "מודיעין עילית",
		"manager": "אברהם שמואל פפנהיים",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת חנוך לנער",
		"semelMosad": 770719,
		"settlement": "נתיבות",
		"manager": "חיים מגורי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת חסד לאברהם",
		"semelMosad": 747519,
		"settlement": "בני ברק",
		"manager": "חיים אלעזר ניסן פרסר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת חסידי בעלז",
		"semelMosad": 140673,
		"settlement": "ירושלים",
		"manager": "חיים שיף",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת חסידי גור",
		"semelMosad": 344309,
		"settlement": "חיפה",
		"manager": "אריה זאב גינצברג",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת חסידי גור",
		"semelMosad": 183269,
		"settlement": "בית שמש",
		"manager": "יצחק מנחם קלינבסקי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת חסידי דאראג",
		"semelMosad": 541631,
		"settlement": "בני ברק",
		"manager": "אהרן פרידמן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת טבריה",
		"semelMosad": 244509,
		"settlement": "טבריה",
		"manager": "אחיקם גדעון שבח",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת יוסף לקח",
		"semelMosad": 640714,
		"settlement": "אופקים",
		"manager": "תנחום פרנק",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת יחל ישראל",
		"semelMosad": 346031,
		"settlement": "חיפה",
		"manager": "אברהם מנדלוביץ",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת ים לצעירים",
		"semelMosad": 140160,
		"settlement": "ירושלים",
		"manager": "דוד שמחון",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת יקירי ירושלים",
		"semelMosad": 140681,
		"settlement": "ירושלים",
		"manager": "יוסף שר שלום כהן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת ישמח משה",
		"semelMosad": 144410,
		"settlement": "ירושלים",
		"manager": "יקותיאל מיכאל אלבז",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת כוכב מיעקב",
		"semelMosad": 140780,
		"settlement": "ירושלים",
		"manager": "צבי שטרן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת כח התורה",
		"semelMosad": 747477,
		"settlement": "אלעד",
		"manager": "רחל אורלי יונה",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת כנסת חזקיהו",
		"semelMosad": 340588,
		"settlement": "רכסים",
		"manager": "מרדכי איצקוביץ",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת כנסת יצחק",
		"semelMosad": 344168,
		"settlement": "חדרה",
		"manager": "יהושע גרשון ארנברג",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת כנסת יצחק",
		"semelMosad": 363879,
		"settlement": "מודיעין עילית",
		"manager": "שמעון נוביק",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת כנסת יששכר",
		"semelMosad": 541458,
		"settlement": "בני ברק",
		"manager": "אברהם ישעיהו גולדשטוף",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת כנסת מרדכי",
		"semelMosad": 560557,
		"settlement": "מודיעין עילית",
		"manager": "יצחק כהנא",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת כסא רחמים",
		"semelMosad": 444505,
		"settlement": "בני ברק",
		"manager": "חיים ישראל",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת כפר גנים",
		"semelMosad": 440297,
		"settlement": "פתח תקווה",
		"manager": "ניצן ברגר",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת כתר התורה",
		"semelMosad": 645119,
		"settlement": "ביתר עילית",
		"manager": "יחזקאל טאוב",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת מאור אליהו",
		"semelMosad": 144360,
		"settlement": "קרית יערים",
		"manager": "משה דב מנדלסון",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת מאור ישראל",
		"semelMosad": 541219,
		"settlement": "בני ברק",
		"manager": "יהודה כהן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת מאור מנחם",
		"semelMosad": 441386,
		"settlement": "רחובות",
		"manager": "חיים אהרון",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת מאורות",
		"semelMosad": 238527,
		"settlement": "בית שמש",
		"manager": "ארן גרנית",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת מאורות התורה",
		"semelMosad": 519595,
		"settlement": "אשדוד",
		"manager": "ששון קטן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת מגדל תורה",
		"semelMosad": 141242,
		"settlement": "ירושלים",
		"manager": "יעקב יצחק רוזנטל",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת מגן הילד",
		"semelMosad": 541177,
		"settlement": "בני ברק",
		"manager": "שלום קאיקוב",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת מיר לצעירים",
		"semelMosad": 160523,
		"settlement": "ירושלים",
		"manager": "מנחם גרשון זרצקי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת מלכות תורה",
		"semelMosad": 442681,
		"settlement": "מודיעין עילית",
		"manager": "יוסף נפתלי רוזנטלר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת מעין התלמוד",
		"semelMosad": 141796,
		"settlement": "ירושלים",
		"manager": "מרדכי דב קרשינסקי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת מעלה חבר",
		"semelMosad": 141358,
		"settlement": "פני חבר",
		"manager": "עמיחי חזן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת מעלות התורה",
		"semelMosad": 140947,
		"settlement": "ירושלים",
		"manager": "שלום משה אשר וסליובסקי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת מעלות חיים",
		"semelMosad": 144246,
		"settlement": "ירושלים",
		"manager": "אהרן פרנק איזנר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת מערב השומרון",
		"semelMosad": 482950,
		"settlement": "אלקנה",
		"manager": "מרב ידעי",
		"level": "חט\"ב בלבד",
		"special": "מיוחד",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת מערב שומרון",
		"semelMosad": 420026,
		"settlement": "אלקנה",
		"manager": "אהוד סט",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת מערבא",
		"semelMosad": 140954,
		"settlement": "מתתיהו",
		"manager": "חיים ברוך חייט",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת מצפה רחובות",
		"semelMosad": 761395,
		"settlement": "רחובות",
		"manager": "אסף יהודה כהן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת מקור חיים",
		"semelMosad": 141028,
		"settlement": "כפר עציון",
		"manager": "גילעד רוזנברג",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת מקור חיים",
		"semelMosad": 615948,
		"settlement": "ירושלים",
		"manager": "יהונתן גוטמן",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת מרכז תורה אור",
		"semelMosad": 641019,
		"settlement": "באר שבע",
		"manager": "אברהם מיכאל ממן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת משך חוכמה",
		"semelMosad": 544197,
		"settlement": "בני ברק",
		"manager": "אפרים מילר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת משכן התורה",
		"semelMosad": 541722,
		"settlement": "בני ברק",
		"manager": "יעקב פוליקמן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת משכן יעקב",
		"semelMosad": 344226,
		"settlement": "חיפה",
		"manager": "דוד כץ",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת משכן ציון",
		"semelMosad": 141572,
		"settlement": "ירושלים",
		"manager": "אליהו אבא שאול",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת משכן שלמה",
		"semelMosad": 141606,
		"settlement": "ירושלים",
		"manager": "אברהם דה פרוידיגר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת משכן שמעון",
		"semelMosad": 544254,
		"settlement": "בני ברק",
		"manager": "אהרן ברזילי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת משכנות הרועים",
		"semelMosad": 541573,
		"settlement": "בני ברק",
		"manager": "ישועה אלאלוף",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת משכנות נעורים",
		"semelMosad": 740381,
		"settlement": "יסודות",
		"manager": "יהודה כהן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת משכנותיך ישראל",
		"semelMosad": 761320,
		"settlement": "בית שמש",
		"manager": "שלמה דה-יונג",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת מתיבתא",
		"semelMosad": 476903,
		"settlement": "בית שמש",
		"manager": "אבישי דוד בראון",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת נהורא",
		"semelMosad": 441592,
		"settlement": "מבוא חורון",
		"manager": "אברהם יצחק גרינבוים",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת נוה שמואל",
		"semelMosad": 140764,
		"settlement": "אפרת",
		"manager": "אבישי נתן מילנר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת נחלת אהרן קויד",
		"semelMosad": 716779,
		"settlement": "בני ברק",
		"manager": "ישראל שלום ארליך",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת נחלת דוד",
		"semelMosad": 441055,
		"settlement": "פתח תקווה",
		"manager": "שלמה מרקוס",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת נחלת יוסף",
		"semelMosad": 460451,
		"settlement": "ירושלים",
		"manager": "יוסף בן עמרם",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת נעימות התורה",
		"semelMosad": 729020,
		"settlement": "ביתר עילית",
		"manager": "נתן ארוש",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת נעם",
		"semelMosad": 144691,
		"settlement": "ירושלים",
		"manager": "אבישי סגל",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת נצח התורה",
		"semelMosad": 362194,
		"settlement": "מודיעין עילית",
		"manager": "בן ציון פרידמן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת נר ישראל",
		"semelMosad": 242610,
		"settlement": "אלעד",
		"manager": "יחיא אדמונד תורג'מן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת נר שמואל",
		"semelMosad": 148130,
		"settlement": "ירושלים",
		"manager": "אהרן אריה בנון",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת נתיב מאיר",
		"semelMosad": 140137,
		"settlement": "ירושלים",
		"manager": "דעואל בסוק",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת נתיבות חיים",
		"semelMosad": 544189,
		"settlement": "בני ברק",
		"manager": "רפאל אלקרייף (חריף)",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת נתיבות יוסף",
		"semelMosad": 141101,
		"settlement": "מצפה יריחו",
		"manager": "יהונתן דמרי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת נתיבות יעקב",
		"semelMosad": 142018,
		"settlement": "ירושלים",
		"manager": "יוסף קריינדלר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת נתיבות מרדכי",
		"semelMosad": 758250,
		"settlement": "ירושלים",
		"manager": "מנדל וינטר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת סלבודקה כנסת י",
		"semelMosad": 540609,
		"settlement": "בני ברק",
		"manager": "יצחק שורץ",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת סקווירא",
		"semelMosad": 519900,
		"settlement": "ירושלים",
		"manager": "אהרון פריזל",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת עטרת חיים צבי",
		"semelMosad": 646794,
		"settlement": "ביתר עילית",
		"manager": "שלום ניסטנפובר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת עטרת ישראל",
		"semelMosad": 140541,
		"settlement": "ירושלים",
		"manager": "מאיר טוביה פולק",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת עטרת מתתיהו",
		"semelMosad": 519959,
		"settlement": "נתניה",
		"manager": "פנחס שקד",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת עתניאל לצעירים",
		"semelMosad": 640375,
		"settlement": "עתניאל",
		"manager": "חברון שילה",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת פאר אהרון",
		"semelMosad": 361667,
		"settlement": "קוממיות",
		"manager": "משה יהודה לייב זוסמן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת פאר השלום",
		"semelMosad": 167262,
		"settlement": "ירושלים",
		"manager": "מרדכי אליהו רפאלי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת פאר התלמוד",
		"semelMosad": 168393,
		"settlement": "ירושלים",
		"manager": "יוסף חיים דרשן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת פאר מרדכי",
		"semelMosad": 442863,
		"settlement": "ירושלים",
		"manager": "משה מרדכי מושקוביץ",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת פאר משה",
		"semelMosad": 544387,
		"settlement": "פתח תקווה",
		"manager": "משה פינטו",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת פונוביץ",
		"semelMosad": 442145,
		"settlement": "אשדוד",
		"manager": "נפתלי יצחק אורלנצ'יק",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת פוניבז'",
		"semelMosad": 631523,
		"settlement": "בני ברק",
		"manager": "מרדכי לנאייל",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת פוניבז'",
		"semelMosad": 541896,
		"settlement": "בני ברק",
		"manager": "ראובן קורלנדסקי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת פורת יוסף",
		"semelMosad": 184093,
		"settlement": "ירושלים",
		"manager": "אפרים מרשה",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת פורת יוסף גאולה",
		"semelMosad": 197715,
		"settlement": "ירושלים",
		"manager": "אפרים מרשה",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת פלח הרימון",
		"semelMosad": 653600,
		"settlement": "שילה",
		"manager": "יעקב-משה פליקס",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת פני מנחם",
		"semelMosad": 633263,
		"settlement": "ירושלים",
		"manager": "יהושע העשיל רייס",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת פסגת יצחק",
		"semelMosad": 361881,
		"settlement": "בית מאיר",
		"manager": "שלום חיים קרלינסקי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת צביה אשקלון",
		"semelMosad": 641183,
		"settlement": "אשקלון",
		"manager": "משה חיים ויצמן",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת צביה דימונה",
		"semelMosad": 641332,
		"settlement": "דימונה",
		"manager": "יעקב דודי",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת צביה כרמי קטיף",
		"semelMosad": 746669,
		"settlement": "כרמי קטיף",
		"manager": "רענן זהבי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת צביה לוד",
		"semelMosad": 441824,
		"settlement": "לוד",
		"manager": "פנחס בן יאיר",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת צביה מ.אדומים",
		"semelMosad": 144428,
		"settlement": "מעלה אדומים",
		"manager": "יוסף מנחם פרנקל",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת צביה פתח תקווה",
		"semelMosad": 441832,
		"settlement": "פתח תקווה",
		"manager": "יגאל גולן דיעי",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת צביה קטיף",
		"semelMosad": 442319,
		"settlement": "יד בנימין",
		"manager": "יועד אהרוני",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת צדקת יוסף",
		"semelMosad": 644252,
		"settlement": "אשדוד",
		"manager": "שמואל אהרן איינפלד",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת צמח צדיק",
		"semelMosad": 640722,
		"settlement": "אשדוד",
		"manager": "ישראל מאיער",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת צמח צדיק ויז'נ",
		"semelMosad": 442095,
		"settlement": "אלעד",
		"manager": "ישראל ברכר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת קול יעקב לצעיר",
		"semelMosad": 519637,
		"settlement": "ביתר עילית",
		"manager": "איתן ידגר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת קול רפאל",
		"semelMosad": 560102,
		"settlement": "בת ים",
		"manager": "אשר חיון",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת קניני התורה",
		"semelMosad": 141267,
		"settlement": "ירושלים",
		"manager": "יעקב כהן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת קרלין סטולין",
		"semelMosad": 460386,
		"settlement": "מודיעין עילית",
		"manager": "חיים דוב באלד",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת קרלין סטולין",
		"semelMosad": 541052,
		"settlement": "בני ברק",
		"manager": "יחיאל מיכל רוזנקרנץ",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת קרן אורה",
		"semelMosad": 244236,
		"settlement": "כרמיאל",
		"manager": "אברהם צבי מרגלית",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת ראדין",
		"semelMosad": 756049,
		"settlement": "נתניה",
		"manager": "מיכאל גוגיג",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת רכסים הקטנה",
		"semelMosad": 560227,
		"settlement": "ירושלים",
		"manager": "יהודה שמ סופר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת רכסים לצעירים",
		"semelMosad": 739078,
		"settlement": "רכסים",
		"manager": "",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת שאול ומרי טויל",
		"semelMosad": 141143,
		"settlement": "ירושלים",
		"manager": "אהרן שם-טוב",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת שבי חברון לצעירים",
		"semelMosad": 455162,
		"settlement": "פני חבר",
		"manager": "שחר יהוד",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת שיח תורה",
		"semelMosad": 370882,
		"settlement": "מודיעין עילית",
		"manager": "אלי אוחיון",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת שלום בניך",
		"semelMosad": 360578,
		"settlement": "ירושלים",
		"manager": "מרדכי טאו",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת שעלבים",
		"semelMosad": 770305,
		"settlement": "שעלבים",
		"manager": "גרשון אליהו שחור",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת שער אליעזר",
		"semelMosad": 442335,
		"settlement": "רחובות",
		"manager": "שמואל חיים הרשקוביץ",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת שער השמיים",
		"semelMosad": 267724,
		"settlement": "טלמון",
		"manager": "אמיתי-מאיר מיטלס",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת שערי דעה",
		"semelMosad": 758417,
		"settlement": "מודיעין עילית",
		"manager": "פדאל טימסטיט",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת שערי דעת",
		"semelMosad": 715409,
		"settlement": "ביתר עילית",
		"manager": "אפרים זאב מזוז",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת שערי ציון",
		"semelMosad": 444786,
		"settlement": "בני ברק",
		"manager": "יהודה אלישיוב",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת שפע חיים צאנז",
		"semelMosad": 460147,
		"settlement": "ירושלים",
		"manager": "יעקב הלברשטם",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת שפת אמת",
		"semelMosad": 141135,
		"settlement": "ירושלים",
		"manager": "יצחק מאיר טאומן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת שפתי צדיק",
		"semelMosad": 240424,
		"settlement": "חצור הגלילית",
		"manager": "חנוך שמעון איזנברג",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת ת\"ת מורשה",
		"semelMosad": 361261,
		"settlement": "ירושלים",
		"manager": "הלל פלסר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת תהילת שלמה",
		"semelMosad": 344341,
		"settlement": "חיפה",
		"manager": "ישראל מאיר וייס",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת תהילת שלמה",
		"semelMosad": 362475,
		"settlement": "בני ברק",
		"manager": "יצחק אריה טופורוביץ",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת תומכי תמימים",
		"semelMosad": 142232,
		"settlement": "ביתר עילית",
		"manager": "מרדכי מישולובין",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת תומכי תמימים",
		"semelMosad": 440727,
		"settlement": "לוד",
		"manager": "מנשה חדד",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת תומכי תמימים",
		"semelMosad": 340802,
		"settlement": "חיפה",
		"manager": "איתן יעקב פיזם",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת תומכי תמימים",
		"semelMosad": 244517,
		"settlement": "מגדל העמק",
		"manager": "נחום רפאל ברגר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת תורה אור אשדוד",
		"semelMosad": 640995,
		"settlement": "אשדוד",
		"manager": "משה כהן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת תורה והוראה",
		"semelMosad": 513283,
		"settlement": "תל אביב - יפו",
		"manager": "יוסי פרץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת תורה והוראה",
		"semelMosad": 771121,
		"settlement": "תל אביב - יפו",
		"manager": "רפאל ועקנין",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת תורה ושלום",
		"semelMosad": 344127,
		"settlement": "בני ברק",
		"manager": "זיו מכלוף",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת תורת אמת",
		"semelMosad": 140434,
		"settlement": "ירושלים",
		"manager": "ראובן בורושנסקי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת תורת חסד",
		"semelMosad": 444604,
		"settlement": "רחובות",
		"manager": "בנימין פנסקי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת תורת ישראל",
		"semelMosad": 727099,
		"settlement": "ירושלים",
		"manager": "יוסף שלום גרמה",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת תורת ישראל",
		"semelMosad": 660217,
		"settlement": "נתיבות",
		"manager": "יעקב איצקוביץ",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת תורת מאיר",
		"semelMosad": 244418,
		"settlement": "טבריה",
		"manager": "דוד אוחנה",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת תורת מנחם",
		"semelMosad": 441493,
		"settlement": "נתניה",
		"manager": "משה אורנשטיין",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת תורת מרדכי",
		"semelMosad": 728691,
		"settlement": "מודיעין עילית",
		"manager": "חיים יצחק זלושינסקי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת תורת עולם",
		"semelMosad": 733824,
		"settlement": "בית שמש",
		"manager": "שבתי מנחם שטיינר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת תורת רפאל",
		"semelMosad": 141937,
		"settlement": "ירושלים",
		"manager": "אברהם קוק",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת תורת שלמה י-ם",
		"semelMosad": 560706,
		"settlement": "ירושלים",
		"manager": "יצחק זאב גולדמן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת תושיה לצעירים",
		"semelMosad": 644674,
		"settlement": "תפרח",
		"manager": "יהודה לויסון",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת תיכון קול מבשר",
		"semelMosad": 140251,
		"settlement": "מבשרת ציון",
		"manager": "יעקב יוסף אזולאי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת תנא דבי אליהו",
		"semelMosad": 362202,
		"settlement": "ירושלים",
		"manager": "שלמה בצלאל אדרי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת תפארת התורה",
		"semelMosad": 747493,
		"settlement": "בית שמש",
		"manager": "יעקב סגל",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת תפארת התלמוד",
		"semelMosad": 144162,
		"settlement": "ירושלים",
		"manager": "ברכיהו שינקר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת תפארת ישראל",
		"semelMosad": 140749,
		"settlement": "ירושלים",
		"manager": "נחום מרדכי טירהויז",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת תפארת משה",
		"semelMosad": 541185,
		"settlement": "בני ברק",
		"manager": "אמנון סבאג",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת תפארת ציון",
		"semelMosad": 544379,
		"settlement": "בני ברק",
		"manager": "שלמה קניבסקי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישיבת תפארת-מרדכי",
		"semelMosad": 541334,
		"settlement": "בני ברק",
		"manager": "יאיר שלמה זקש",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישכיל פיהו",
		"semelMosad": 177287,
		"settlement": "ירושלים",
		"manager": "אסתר בירנבאום",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישמח משה",
		"semelMosad": 182931,
		"settlement": "ירושלים",
		"manager": "אליהו נתניאן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ישרי לב",
		"semelMosad": 747634,
		"settlement": "בני ברק",
		"manager": "יצחק חיים הכהן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "כ\"ג יורדי הסירה",
		"semelMosad": 270033,
		"settlement": "עכו",
		"manager": "זאב רביץ",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "כאייל תערוג",
		"semelMosad": 721662,
		"settlement": "ירושלים",
		"manager": "אייל עמרמי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "כברי -מעיינות",
		"semelMosad": 210930,
		"settlement": "כברי",
		"manager": "אוסנת נוטמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "כברי חט\"ע",
		"semelMosad": 260240,
		"settlement": "כברי",
		"manager": "אורן דרמון",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "כוכב אבו אלהיגא",
		"semelMosad": 218362,
		"settlement": "כאוכב אבו אל-היג'א",
		"manager": "עביר אבו אל היג'א",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "כוכב הצפון",
		"semelMosad": 530535,
		"settlement": "תל אביב - יפו",
		"manager": "נעמה סקלי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "כוכב הצפון",
		"semelMosad": 614404,
		"settlement": "אשקלון",
		"manager": "שרה פלום",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "כוכב השחר בנות",
		"semelMosad": 113480,
		"settlement": "כוכב השחר",
		"manager": "רונית כהן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "כוכב יעקב בנות",
		"semelMosad": 113787,
		"settlement": "כוכב יעקב",
		"manager": "דן אפרים בירנבאום",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "כוכבי המדבר ברנקו וייס",
		"semelMosad": 724161,
		"settlement": "להב",
		"manager": "מחמד אלטורי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "כולנא יחד",
		"semelMosad": 511477,
		"settlement": "תל אביב - יפו",
		"manager": "שרון מיכאלי רמון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "כינר",
		"semelMosad": 210708,
		"settlement": "טבריה",
		"manager": "שמעון ויקרט",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "כישורית",
		"semelMosad": 220525,
		"settlement": "כישור",
		"manager": "ניצן אסתר עמית",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "כיתות בי\"ח סורוקה",
		"semelMosad": 620534,
		"settlement": "באר שבע",
		"manager": "ניקול סגל",
		"level": "יסודי חט\"ב ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "כל ישראל חברים",
		"semelMosad": 340190,
		"settlement": "חיפה",
		"manager": "מיכל גל",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "כלל חסידי לאנשי י-ם",
		"semelMosad": 174664,
		"settlement": "ירושלים",
		"manager": "אלעזר קליין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "כלל חסידי קרית הבעשט",
		"semelMosad": 416198,
		"settlement": "פתח תקווה",
		"manager": "יהושע גליק",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "כלניות",
		"semelMosad": 630061,
		"settlement": "באר שבע",
		"manager": "מוריה שהם",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "כלנית -כרמיאל",
		"semelMosad": 214395,
		"settlement": "כרמיאל",
		"manager": "רמה עובדיה מושקוביץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "כנסת הגדולה מפעלים ח",
		"semelMosad": 719575,
		"settlement": "מודיעין עילית",
		"manager": "תומר שטינמיץ",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "כנסת יהודה",
		"semelMosad": 112706,
		"settlement": "ירושלים",
		"manager": "יוסף מלין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "כנסת יחזקאל",
		"semelMosad": 442087,
		"settlement": "אלעד",
		"manager": "ברוך אטינגר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "כנסת מאיר",
		"semelMosad": 441485,
		"settlement": "ראשון לציון",
		"manager": "מאיר מילוא",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "כנסת תורת חיים",
		"semelMosad": 755603,
		"settlement": "בית שמש",
		"manager": "אהרן ברנשטין",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "כנפי יונה",
		"semelMosad": 373357,
		"settlement": "צפת",
		"manager": "עינב רבקה ביטון",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "כנפי רוח",
		"semelMosad": 617043,
		"settlement": "בית אריה",
		"manager": "אבי זימן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "כנפי רוח בנות",
		"semelMosad": 384552,
		"settlement": "חריש",
		"manager": "רעות עטרה ליפשיץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "כנפי רוח בנים",
		"semelMosad": 384271,
		"settlement": "חריש",
		"manager": "חגי כהן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "כסולות",
		"semelMosad": 214130,
		"settlement": "נוף הגליל",
		"manager": "אורית יאיר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "כף החיים",
		"semelMosad": 755546,
		"settlement": "נהריה",
		"manager": "איתן מסעוד פורטל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "כפיר",
		"semelMosad": 513408,
		"settlement": "תל אביב - יפו",
		"manager": "אור מקובר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "כפר אדומים",
		"semelMosad": 112052,
		"settlement": "כפר אדומים",
		"manager": "עדי יגל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "כפר גלים",
		"semelMosad": 380055,
		"settlement": "כפר גלים",
		"manager": "אלפא וינברגר",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "כפר הנוער איילת השחר",
		"semelMosad": 719591,
		"settlement": "יונתן",
		"manager": "ברוריה נויברגר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "כפר הנוער אמי\"ת",
		"semelMosad": 579490,
		"settlement": "פתח תקווה",
		"manager": "ברוריה-קליה פרון",
		"level": "חט\"ב + עליונה",
		"special": "מיוחד",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "כפר הנוער בן שמן",
		"semelMosad": 480145,
		"settlement": "בן שמן (כפר נוער)",
		"manager": "סיגל בייליס",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "כפר הנוער הדתי",
		"semelMosad": 380048,
		"settlement": "כפר הנוער הדתי",
		"manager": "צבי יהודה רוזנברג",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "כפר הנוער הערבי למצויינות",
		"semelMosad": 734863,
		"settlement": "גבעת חביבה",
		"manager": "אנדירה חדיג'ה ביאדסה",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "כפר הנוער קדמה",
		"semelMosad": 670828,
		"settlement": "קדמה",
		"manager": "דנה ישראלי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "כפר יאסיף א",
		"semelMosad": 219147,
		"settlement": "כפר יאסיף",
		"manager": "הלאל ספייה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "כפר יסיף ב",
		"semelMosad": 218388,
		"settlement": "כפר יאסיף",
		"manager": "סאוסן בסל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "כפר כנא א",
		"semelMosad": 218412,
		"settlement": "כפר כנא",
		"manager": "סמיר עואודה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "כפר כנא ב",
		"semelMosad": 218404,
		"settlement": "כפר כנא",
		"manager": "נג'ם אמארה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "כפר נוער ניר העמק",
		"semelMosad": 280032,
		"settlement": "עפולה",
		"manager": "סיגל אופיר",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "כפר עקב",
		"semelMosad": 148288,
		"settlement": "ירושלים",
		"manager": "אוסאמה טוטח",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "כפר עקב",
		"semelMosad": 638858,
		"settlement": "ירושלים",
		"manager": "טארק גית",
		"level": "יסודי בלבד",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "כפר עקב בנות",
		"semelMosad": 642231,
		"settlement": "ירושלים",
		"manager": "אפנאן דיאב סואלמה",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "כפר עקב חדש",
		"semelMosad": 179754,
		"settlement": "ירושלים",
		"manager": "אמאני פלאח",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "כפר עקב לבנים",
		"semelMosad": 518373,
		"settlement": "ירושלים",
		"manager": "תורה חלאילה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "כפר קאסם ז'",
		"semelMosad": 417063,
		"settlement": "כפר קאסם",
		"manager": "אמאל סרסור",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "כפר קרע א",
		"semelMosad": 318352,
		"settlement": "כפר קרע",
		"manager": "זהור עותמאן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "כצנלסון",
		"semelMosad": 510347,
		"settlement": "גבעתיים",
		"manager": "רוית סירי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "כצנלסון",
		"semelMosad": 410944,
		"settlement": "כפר סירקין",
		"manager": "אורנית גל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "כצנלסון",
		"semelMosad": 510560,
		"settlement": "חולון",
		"manager": "בהירה אורלי ששון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "כרכור",
		"semelMosad": 311076,
		"settlement": "פרדס חנה-כרכור",
		"manager": "איריס חזיזה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "כרם אלסאחב הרשמי",
		"semelMosad": 228015,
		"settlement": "נצרת",
		"manager": "שורוק בטאח סואן",
		"level": "יסודי חט\"ב ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "כרם גפן ישראל",
		"semelMosad": 362145,
		"settlement": "בני ברק",
		"manager": "יעקב אלכסנדר שוורצברט",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "כרם גפנים",
		"semelMosad": 623850,
		"settlement": "כרמיאל",
		"manager": "אורי שלם",
		"level": "חט\"ב + עליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "כרם חיה מושקא",
		"semelMosad": 643569,
		"settlement": "קרית שמונה",
		"manager": "ורד סגרה",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "כרמי גת",
		"semelMosad": 630038,
		"settlement": "קרית גת",
		"manager": "מעיין פרגון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "כרמי חן",
		"semelMosad": 166470,
		"settlement": "עלי",
		"manager": "אביטל נאומבורג",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "כרמי יהודה",
		"semelMosad": 111328,
		"settlement": "אלון שבות",
		"manager": "רענן יוסף גלמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "כרמים",
		"semelMosad": 413617,
		"settlement": "ראשון לציון",
		"manager": "איריס בנימיני",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "כרמים",
		"semelMosad": 413690,
		"settlement": "אלקנה",
		"manager": "מנואל צרפתי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "כרמים",
		"semelMosad": 616359,
		"settlement": "קרית גת",
		"manager": "מירי לוי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "כרמים בעמק",
		"semelMosad": 220129,
		"settlement": "עפולה",
		"manager": "רות חייט",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "כרמל",
		"semelMosad": 213629,
		"settlement": "נוף הגליל",
		"manager": "נורית יעקב",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "כרמל וים",
		"semelMosad": 311290,
		"settlement": "עין כרמל",
		"manager": "שרון שקד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "כרמל-נתיב אליעזר",
		"semelMosad": 310847,
		"settlement": "חיפה",
		"manager": "גלית ראם רחמים",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "כתב סופר",
		"semelMosad": 513846,
		"settlement": "בני ברק",
		"manager": "דוד דב פרידמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "כתב סופר אלעד",
		"semelMosad": 430041,
		"settlement": "אלעד",
		"manager": "שואל אריה מאירוביץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "כתר תורה",
		"semelMosad": 440230,
		"settlement": "יבנה",
		"manager": "מתתיהו הגרי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "לאה גולדברג",
		"semelMosad": 530717,
		"settlement": "גבעתיים",
		"manager": "יפעת שטיינר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "לאה גולדברג",
		"semelMosad": 482067,
		"settlement": "כפר סבא",
		"manager": "מירי ספקטור",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "לאה גולדברג",
		"semelMosad": 411926,
		"settlement": "נתניה",
		"manager": "יהודית פרור",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "לאמנויות",
		"semelMosad": 513655,
		"settlement": "תל אביב - יפו",
		"manager": "אהוד קליין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "לב",
		"semelMosad": 384404,
		"settlement": "חדרה",
		"manager": "נורית אקשטיין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "לב אברהם",
		"semelMosad": 361030,
		"settlement": "ירושלים",
		"manager": "בונים כהן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "לב אליהו",
		"semelMosad": 360560,
		"settlement": "ירושלים",
		"manager": "יעקב זכריה",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "לב הבוסתן",
		"semelMosad": 620476,
		"settlement": "ירושלים",
		"manager": "ריקי יהלום ארבל",
		"level": "חט\"ב + עליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "לב החינוך חסידי",
		"semelMosad": 420620,
		"settlement": "ביתר עילית",
		"manager": "יוסף אליהו זלפריינד",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "לב המושבה",
		"semelMosad": 412718,
		"settlement": "נס ציונה",
		"manager": "לימור ליהי צפניק",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "לב העמק-נאות מרדכי",
		"semelMosad": 211441,
		"settlement": "נאות מרדכי",
		"manager": "שמעת קסטוריאנו",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "לב הפרדס",
		"semelMosad": 416339,
		"settlement": "גאולים",
		"manager": "מאיה גניש",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "לב שמחה בית ספר חינו",
		"semelMosad": 716803,
		"settlement": "אשדוד",
		"manager": "מרים בלומה לוי",
		"level": "יסודי בלבד",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "לב-טוב",
		"semelMosad": 512665,
		"settlement": "הרצליה",
		"manager": "מירי לוי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "לבאות",
		"semelMosad": 613174,
		"settlement": "ערד",
		"manager": "סימה ברון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "לבב חכמה",
		"semelMosad": 644989,
		"settlement": "ירושלים",
		"manager": "בנימין גמליאלי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "לביא",
		"semelMosad": 436717,
		"settlement": "נעלה",
		"manager": "גלית שרויטמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "לביא אזורי ממד",
		"semelMosad": 211128,
		"settlement": "לביא",
		"manager": "ראומה ביים",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "לביא ממ\"ד",
		"semelMosad": 362228,
		"settlement": "צור הדסה",
		"manager": "דקלה אבו אהרונוביץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "לדעת חכמה",
		"semelMosad": 560532,
		"settlement": "אלעד",
		"manager": "מרים ברורמן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "להבים",
		"semelMosad": 613463,
		"settlement": "להבים",
		"manager": "איריס אור",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "להורות נתן",
		"semelMosad": 455170,
		"settlement": "חדרה",
		"manager": "שי שאול מזרחי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "לווית חן",
		"semelMosad": 714824,
		"settlement": "חולון",
		"manager": "ריקי הדסה יצחקי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "לוי אשכול",
		"semelMosad": 111179,
		"settlement": "בית שמש",
		"manager": "רחל ויצמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "לוי אשכול",
		"semelMosad": 513531,
		"settlement": "חולון",
		"manager": "ענת שטינברג גרוטס",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "לומדי אברהם",
		"semelMosad": 499939,
		"settlement": "ביתר עילית",
		"manager": "מנחם מנדל טסלר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "לוריא",
		"semelMosad": 110270,
		"settlement": "ירושלים",
		"manager": "אילנה אוריצקי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "לחינוך מיוחד - חדש",
		"semelMosad": 618686,
		"settlement": "חורה",
		"manager": "גיברין אלסייד",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "לחם ביכורים",
		"semelMosad": 160382,
		"settlement": "ירושלים",
		"manager": "רונן כהן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "לטבע סביבה וחברה",
		"semelMosad": 513689,
		"settlement": "תל אביב - יפו",
		"manager": "ורד גצלר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "לי-אור",
		"semelMosad": 482703,
		"settlement": "רעננה",
		"manager": "מיטל ברמלי-יצחק",
		"level": "יסודי בלבד",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ליאו באק",
		"semelMosad": 313460,
		"settlement": "חיפה",
		"manager": "לולי שטרן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ליאו בק",
		"semelMosad": 310607,
		"settlement": "חיפה",
		"manager": "שרית ממן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "למען אחי",
		"semelMosad": 442574,
		"settlement": "שילת",
		"manager": "רחל אורלי יונה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "למען אחי-ת\"ת שערי תורה",
		"semelMosad": 112896,
		"settlement": "ירושלים",
		"manager": "אפרים רזניק",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "למרחב",
		"semelMosad": 417006,
		"settlement": "פתח תקווה",
		"manager": "יעקב-משה ליפשיץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "לפיד",
		"semelMosad": 413476,
		"settlement": "הוד השרון",
		"manager": "חנה ענבר מילוא",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "לשון לימודים",
		"semelMosad": 346528,
		"settlement": "עופרה",
		"manager": "דפנה פרום",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מ\"ד מורשה",
		"semelMosad": 411769,
		"settlement": "פתח תקווה",
		"manager": "קרן אביאל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מ\"ד מעלות חיים",
		"semelMosad": 415224,
		"settlement": "פתח תקווה",
		"manager": "אשרית מסעודה יצחק",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מ\"מ הפעמונים",
		"semelMosad": 416610,
		"settlement": "רעננה",
		"manager": "שירי דנטס",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מ\"מ מנחם בגין",
		"semelMosad": 416164,
		"settlement": "נתניה",
		"manager": "עליזה מורן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מ\"מ נופרים",
		"semelMosad": 482554,
		"settlement": "ראש העין",
		"manager": "שושנה בן צור",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מ\"מ ע\"ש יצחק נבון",
		"semelMosad": 113639,
		"settlement": "בית שמש",
		"manager": "נוגה יוכבד מור",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מ\"מ צומח אי",
		"semelMosad": 482547,
		"settlement": "ראש העין",
		"manager": "צרויה פנט נהרי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מ\"מ צומח סי",
		"semelMosad": 482562,
		"settlement": "ראש העין",
		"manager": "אינה גולדמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מ\"מ צמח השדה",
		"semelMosad": 113902,
		"settlement": "מעלה אדומים",
		"manager": "דנה לוי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מ\"מ רחביה ע\"ש דוד גו",
		"semelMosad": 110205,
		"settlement": "ירושלים",
		"manager": "ענת לביא",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מ\"מ רעות",
		"semelMosad": 313759,
		"settlement": "עין שמר",
		"manager": "גילה זיסקינד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מ\"מ תשרי",
		"semelMosad": 416628,
		"settlement": "נתניה",
		"manager": "לימור אבני בן אריה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מ\"מ-א ע\"ש נאיף קבלאן",
		"semelMosad": 218115,
		"settlement": "בית ג'ן",
		"manager": "ג'אלב דבור",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "דרוזי"
	},
	{
		"schoolName": "מ\"מד הר חומה",
		"semelMosad": 160531,
		"settlement": "ירושלים",
		"manager": "שרונה חנה שם טוב",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מ. חינוכי בנות מוריה",
		"semelMosad": 160630,
		"settlement": "ירושלים",
		"manager": "רות צרויה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מ. חינוכי למצטיינות",
		"semelMosad": 141333,
		"settlement": "ירושלים",
		"manager": "דניאל ויצמן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מ.חינוכי תורני חורב",
		"semelMosad": 411108,
		"settlement": "רעננה",
		"manager": "ישראל דנציגר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מ.תורני דחסידי באבוב",
		"semelMosad": 442764,
		"settlement": "ירושלים",
		"manager": "יהושע לנדאו",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מאור",
		"semelMosad": 322040,
		"settlement": "טירת כרמל",
		"manager": "יעל לוין",
		"level": "חט\"ב + עליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מאור ושמש",
		"semelMosad": 656587,
		"settlement": "בני ברק",
		"manager": "דב ישכר יצחק טרום",
		"level": "יסודי חט\"ב ועליונה",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מאור ישראל",
		"semelMosad": 415661,
		"settlement": "אלעד",
		"manager": "שלום פרץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מאור לציון",
		"semelMosad": 361014,
		"settlement": "בית שמש",
		"manager": "אשר מרדכי מלכה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מאי זיאדה -אלשיכון",
		"semelMosad": 218677,
		"settlement": "נצרת",
		"manager": "דימה אגבאריה עפיפי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "מאיר מד-בית שאן",
		"semelMosad": 210294,
		"settlement": "בית שאן",
		"manager": "יהודה בן שושן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מאמוניה",
		"semelMosad": 148148,
		"settlement": "ירושלים",
		"manager": "גדיר טהבוב",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "מבוא גליל-איילת השחר",
		"semelMosad": 210062,
		"settlement": "איילת השחר",
		"manager": "נגה שמחי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מבוא התלמוד",
		"semelMosad": 166488,
		"settlement": "ירושלים",
		"manager": "רפאל חזן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מבוא חורון",
		"semelMosad": 112516,
		"settlement": "מבוא חורון",
		"manager": "ערן מרגלית",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מבוא חורון בנות",
		"semelMosad": 611871,
		"settlement": "מבוא חורון",
		"manager": "אפרת אסתר שלזינגר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מבואות",
		"semelMosad": 610212,
		"settlement": "באר טוביה",
		"manager": "שמחה עטייה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מבואות הנגב",
		"semelMosad": 660274,
		"settlement": "שובל",
		"manager": "אליהו פרץ",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מבועים",
		"semelMosad": 610733,
		"settlement": "מבועים",
		"manager": "מאיה חניה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מבקשי תורה",
		"semelMosad": 519918,
		"settlement": "בית שמש",
		"manager": "יהודה גרילק",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מבקשי תורה",
		"semelMosad": 761858,
		"settlement": "בית שמש",
		"manager": "אלעזר שמעון ויינברג",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מגאר ד ראס אלחבייה",
		"semelMosad": 219618,
		"settlement": "מגאר",
		"manager": "עומר ח'ראנבה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "דרוזי"
	},
	{
		"schoolName": "מגאר ממלכתי ג",
		"semelMosad": 219352,
		"settlement": "מגאר",
		"manager": "מיעדאד סרחאן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "דרוזי"
	},
	{
		"schoolName": "מגד",
		"semelMosad": 412205,
		"settlement": "רעננה",
		"manager": "רות סנדלר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מגדאלכרום אלסלאם",
		"semelMosad": 218461,
		"settlement": "מג'ד אל-כרום",
		"manager": "נג'יבה סרחאן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "מגדאלכרום עמר אלחטאב",
		"semelMosad": 218453,
		"settlement": "מג'ד אל-כרום",
		"manager": "נזאר זרקאוי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "מגדים",
		"semelMosad": 114181,
		"settlement": "מעלה אדומים",
		"manager": "תמר חדאד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מגדל אור",
		"semelMosad": 240341,
		"settlement": "מגדל העמק",
		"manager": "אליעזר יחזקאל גרוסמן",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מגדל אור",
		"semelMosad": 240697,
		"settlement": "מגדל העמק",
		"manager": "פרננד אופיר טוביאנה",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מגדל אור בנות שלהבת",
		"semelMosad": 246983,
		"settlement": "מגדל העמק",
		"manager": "משה שלמה פרידמן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מגדל אור בנים",
		"semelMosad": 214478,
		"settlement": "מגדל העמק",
		"manager": "שמעון סטניסלב ריס",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מגילות",
		"semelMosad": 112367,
		"settlement": "קליה",
		"manager": "רחלה אליאב",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מגינים",
		"semelMosad": 310623,
		"settlement": "חיפה",
		"manager": "שרון שחק קופלוביץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מגן",
		"semelMosad": 511576,
		"settlement": "תל אביב - יפו",
		"manager": "כלנית בר און",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מגן",
		"semelMosad": 620047,
		"settlement": "באר שבע",
		"manager": "לימור כהן",
		"level": "יסודי חט\"ב ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מגן אבות",
		"semelMosad": 160424,
		"settlement": "בית שמש",
		"manager": "דניאל זאב סיימון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מגן אבות",
		"semelMosad": 240770,
		"settlement": "צפת",
		"manager": "מנחם נתן קניג",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מגן אברהם",
		"semelMosad": 120477,
		"settlement": "ירושלים",
		"manager": "שושנה שפירא",
		"level": "יסודי בלבד",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מגן הלב",
		"semelMosad": 749796,
		"settlement": "אשדוד",
		"manager": "רחל אלגרבלי",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מגן הלב",
		"semelMosad": 631341,
		"settlement": "בית שמש",
		"manager": "תמר גולדמן",
		"level": "יסודי בלבד",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מגן הלב",
		"semelMosad": 633917,
		"settlement": "אשדוד",
		"manager": "שלמה לוי",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מגן הלב",
		"semelMosad": 633909,
		"settlement": "אשדוד",
		"manager": "רות אברמוביץ",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מגן הלב",
		"semelMosad": 633891,
		"settlement": "אשדוד",
		"manager": "חוה מינקה",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מגן הלב",
		"semelMosad": 633784,
		"settlement": "אשדוד",
		"manager": "יעקב פיליפ",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מגן הלב",
		"semelMosad": 647230,
		"settlement": "לוד",
		"manager": "אסנת מאשה אדלר",
		"level": "יסודי בלבד",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מגן הלב אשדוד",
		"semelMosad": 620328,
		"settlement": "אשדוד",
		"manager": "מרי ביטון",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מגן הלב בית שמש",
		"semelMosad": 239863,
		"settlement": "בית שמש",
		"manager": "ברכה גלזר",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מגשימים",
		"semelMosad": 482398,
		"settlement": "רמלה",
		"manager": "ילנה וייסקובוי",
		"level": "יסודי חט\"ב ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מדעי תורני",
		"semelMosad": 614727,
		"settlement": "שדרות",
		"manager": "דינה חורי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מדעים ואמנויות",
		"semelMosad": 270298,
		"settlement": "נהריה",
		"manager": "דורית חילאי",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מדעים וטכנולוגיה נצרת",
		"semelMosad": 278010,
		"settlement": "נצרת",
		"manager": "חנא אבו אל עסל",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "מדעים ויהדות",
		"semelMosad": 312033,
		"settlement": "חדרה",
		"manager": "גילה נהרי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מדעים ויהדות",
		"semelMosad": 416644,
		"settlement": "אור יהודה",
		"manager": "יצחק גוטליב",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מדעים ותיכון גלגוליה",
		"semelMosad": 448050,
		"settlement": "ג'לג'וליה",
		"manager": "רומיו אחמד",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "מדרשה אומנויות מדעים דרכא",
		"semelMosad": 810002,
		"settlement": "ניצן",
		"manager": "מיטל הולנד",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מדרשיית עמליה",
		"semelMosad": 140277,
		"settlement": "ירושלים",
		"manager": "חסיה פריאל",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מדרשת קמה",
		"semelMosad": 641324,
		"settlement": "ירוחם",
		"manager": "שולמית לוי",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מודיעים מד",
		"semelMosad": 511865,
		"settlement": "תל אביב - יפו",
		"manager": "יואב שמולביץ'",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מוהליבר מד",
		"semelMosad": 510198,
		"settlement": "בני ברק",
		"manager": "רחל קנפו",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מוהרן",
		"semelMosad": 499772,
		"settlement": "בני ברק",
		"manager": "אריאל עו אלכורת",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מוזות-בי\"ס תיכון לאומניות",
		"semelMosad": 542035,
		"settlement": "תל אביב - יפו",
		"manager": "ורד ניצני קוזוקרו",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מול גלעד אשדות יעקוב",
		"semelMosad": 213231,
		"settlement": "אשדות יעקב (איחוד)",
		"manager": "דניאל גלעד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מולדת",
		"semelMosad": 613166,
		"settlement": "באר שבע",
		"manager": "איריס נבון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מולדת",
		"semelMosad": 111369,
		"settlement": "מבשרת ציון",
		"manager": "תמר פוליטי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מולויה לבנות",
		"semelMosad": 118331,
		"settlement": "ירושלים",
		"manager": "מוראד חמודה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "מוסד יובלים",
		"semelMosad": 420190,
		"settlement": "רמלה",
		"manager": "מירב מיסה סימוביץ",
		"level": "חט\"ב + עליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מוסדות ביאלא חלקת יה",
		"semelMosad": 728816,
		"settlement": "בני ברק",
		"manager": "יצחק מרדכי מרמרוש",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מוסדות בית נחמיה",
		"semelMosad": 648410,
		"settlement": "בני ברק",
		"manager": "משה יהודה קרול",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מוסדות חנוך אור חיים",
		"semelMosad": 540393,
		"settlement": "בני ברק",
		"manager": "משה קרויזר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מוסדות חסידי בעלזא",
		"semelMosad": 510370,
		"settlement": "בני ברק",
		"manager": "אהרון פרלשטיין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מוסדות חסידי בעלזא",
		"semelMosad": 513770,
		"settlement": "בני ברק",
		"manager": "ראובן ברייש",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מוסדות חסידי בעלזא",
		"semelMosad": 540989,
		"settlement": "בני ברק",
		"manager": "ראובן ברייש",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מוסדות חסידי בעלזא ח",
		"semelMosad": 464222,
		"settlement": "חיפה",
		"manager": "מרדכי שובקס",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מוסדות טשכנוב",
		"semelMosad": 143164,
		"settlement": "ירושלים",
		"manager": "מנחם שלמה בקרמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מוסדות יצחק ירנן",
		"semelMosad": 647966,
		"settlement": "אשקלון",
		"manager": "שמואל דמרי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מוסדות ישיבת פ\"ת",
		"semelMosad": 442400,
		"settlement": "פתח תקווה",
		"manager": "הדס שמיר",
		"level": "חט\"ב + עליונה",
		"special": "מיוחד",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מוסדות מגדל אור",
		"semelMosad": 771030,
		"settlement": "מגדל העמק",
		"manager": "דגנית מלכה",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מוסדות נחלת חיים בית",
		"semelMosad": 795831,
		"settlement": "ביתר עילית",
		"manager": "יואל יחזקאל ישראל",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מוסדות שער התורה אשדוד",
		"semelMosad": 641373,
		"settlement": "אשדוד",
		"manager": "משה אריה ברנשטיין",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מוסדות שפע חיים צאנז",
		"semelMosad": 560664,
		"settlement": "בני ברק",
		"manager": "מאיר צבי בלומנטל",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מוסדות תורה ושלום",
		"semelMosad": 541912,
		"settlement": "בני ברק",
		"manager": "אהרן חדד",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מוסדי עולם משך חכמה",
		"semelMosad": 643734,
		"settlement": "ירושלים",
		"manager": "יעקב משה מרדכי זלצמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מוסמוס",
		"semelMosad": 318204,
		"settlement": "מעלה עירון",
		"manager": "רים אגבאריה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "מועאויה",
		"semelMosad": 318196,
		"settlement": "בסמ\"ה",
		"manager": "עימאד אגבאריה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "מופת חופים",
		"semelMosad": 357483,
		"settlement": "חדרה",
		"manager": "חן חנה נסימי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מורדי הגיטאות",
		"semelMosad": 510834,
		"settlement": "רמת גן",
		"manager": "רויטל זמוד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מוריה",
		"semelMosad": 310854,
		"settlement": "חיפה",
		"manager": "רחל לוי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מוריה",
		"semelMosad": 310300,
		"settlement": "חדרה",
		"manager": "אירית חיים",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מוריה",
		"semelMosad": 611376,
		"settlement": "אשדוד",
		"manager": "בילו ידידיה בן יצחק",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מוריה",
		"semelMosad": 614768,
		"settlement": "באר שבע",
		"manager": "מזיה דזאנהשוילי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מוריה",
		"semelMosad": 620062,
		"settlement": "אשקלון",
		"manager": "עדי נחום",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מוריה מד",
		"semelMosad": 511881,
		"settlement": "תל אביב - יפו",
		"manager": "דביר אפלבוים",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מוריה מד-עפולה",
		"semelMosad": 211961,
		"settlement": "עפולה",
		"manager": "עדי גבר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מוריה ממ\"ד כרמיאל",
		"semelMosad": 213108,
		"settlement": "כרמיאל",
		"manager": "אורי פנחס",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מורשה",
		"semelMosad": 566851,
		"settlement": "פתח תקווה",
		"manager": "אסתר בר לב",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מורשה",
		"semelMosad": 311084,
		"settlement": "פרדס חנה-כרכור",
		"manager": "עליזה בטש",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מורשה",
		"semelMosad": 420091,
		"settlement": "נתניה",
		"manager": "איריס בוארון",
		"level": "יסודי בלבד",
		"special": "מיוחד",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מורשה",
		"semelMosad": 745208,
		"settlement": "אשדוד",
		"manager": "יחזקאל כרמי",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מורשה",
		"semelMosad": 610477,
		"settlement": "ברכיה",
		"manager": "פרידה אוסנת מדאר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מורשת בית יעקב",
		"semelMosad": 239616,
		"settlement": "ירושלים",
		"manager": "יפה זלזניק",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מורשת דליה חיי שרה",
		"semelMosad": 184002,
		"settlement": "בית שמש",
		"manager": "אמיר קריספל",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מורשת זבולון ק.רבין",
		"semelMosad": 416230,
		"settlement": "נתניה",
		"manager": "ברכה דבול",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מורשת ישורון",
		"semelMosad": 612119,
		"settlement": "אופקים",
		"manager": "אמנון דינר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מורשת משה מד",
		"semelMosad": 512475,
		"settlement": "רמת גן",
		"manager": "עינבל מלכה פרקש",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מורשת נריה",
		"semelMosad": 416636,
		"settlement": "גבעת שמואל",
		"manager": "ליאת ברשישת",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מורשת רחל",
		"semelMosad": 499889,
		"settlement": "ביתר עילית",
		"manager": "חסיה בריזל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מורשת שמחה",
		"semelMosad": 631440,
		"settlement": "ביתר עילית",
		"manager": "מיכל הוד",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מושירפה",
		"semelMosad": 318212,
		"settlement": "מעלה עירון",
		"manager": "ראמי ארפאעיה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "מח\"ט אורט מרום עכו",
		"semelMosad": 585851,
		"settlement": "עכו",
		"manager": "ענת רובו",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מח\"ט בית חנינה בנים",
		"semelMosad": 642298,
		"settlement": "ירושלים",
		"manager": "עומר עליאן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "מח\"ט יא",
		"semelMosad": 570069,
		"settlement": "תל אביב - יפו",
		"manager": "דרור איסרוב",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מח\"ט כפר-קרע",
		"semelMosad": 635391,
		"settlement": "כפר קרע",
		"manager": "ענת עזב",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "מח\"ט ע\"ש אבן רושד",
		"semelMosad": 460253,
		"settlement": "טמרה",
		"manager": "באסם אבו אל היג'א",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "מח\"ט עמל מקס פיין",
		"semelMosad": 611483,
		"settlement": "תל אביב - יפו",
		"manager": "אופיר לוי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מח\"ט עמל פסגות נהריה",
		"semelMosad": 370569,
		"settlement": "נהריה",
		"manager": "אסנת בירנבוים",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מח\"ט עמל רהט",
		"semelMosad": 519314,
		"settlement": "רהט",
		"manager": "חסין אלקרינאוי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "מח\"ט עמל רמלה",
		"semelMosad": 519108,
		"settlement": "רמלה",
		"manager": "יניב פכטר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מח\"ט עמל רעות",
		"semelMosad": 648170,
		"settlement": "באר שבע",
		"manager": "תמיר חסון",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "מח\"ט תיכון טכנולוגי",
		"semelMosad": 448290,
		"settlement": "כפר קאסם",
		"manager": "עבדאללה בדיר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "מחבה",
		"semelMosad": 482026,
		"settlement": "כפר ברא",
		"manager": "איה פדילה",
		"level": "יסודי וחט\"ב",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "מחול",
		"semelMosad": 718965,
		"settlement": "מחולה",
		"manager": "רמי כהן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מחזיקי הדת",
		"semelMosad": 640284,
		"settlement": "אשדוד",
		"manager": "ישראל יהודה מיטלמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מחט אופקים",
		"semelMosad": 641001,
		"settlement": "אופקים",
		"manager": "משה לוי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מחט ערערה בנגב",
		"semelMosad": 519090,
		"settlement": "ערערה-בנגב",
		"manager": "עמר אבו עראר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "מחלקת ילדים",
		"semelMosad": 120329,
		"settlement": "ירושלים",
		"manager": "שרה שושני",
		"level": "יסודי וחט\"ב",
		"special": "מיוחד",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מיוחד אל מנאר",
		"semelMosad": 428029,
		"settlement": "טירה",
		"manager": "פותנה קאסם",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "מיוחד ג'סר אלזרקא",
		"semelMosad": 328104,
		"settlement": "ג'סר א-זרקא",
		"manager": "מוחמד גרבאן",
		"level": "יסודי בלבד",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "מיוחד גולני בית שאן",
		"semelMosad": 220103,
		"settlement": "בית שאן",
		"manager": "מירב דהן אלוק",
		"level": "יסודי חט\"ב ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מיוחד כיח לחרשים",
		"semelMosad": 120121,
		"settlement": "ירושלים",
		"manager": "ג'יהאן סרחאן",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מיוחד לוטם",
		"semelMosad": 421032,
		"settlement": "רחובות",
		"manager": "אליאן תמר פרידמן",
		"level": "חט\"ב + עליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מיוחד מוריה",
		"semelMosad": 421099,
		"settlement": "גדרה",
		"manager": "עקיבא ישראל פורת",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מיוחד ניצנים מד",
		"semelMosad": 120154,
		"settlement": "ירושלים",
		"manager": "תמירה שטרן",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מיוחד צורים",
		"semelMosad": 420414,
		"settlement": "הוד השרון",
		"manager": "חנה כפיר",
		"level": "חט\"ב + עליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מיוחד רעות",
		"semelMosad": 420356,
		"settlement": "פתח תקווה",
		"manager": "אורלי קלצוק",
		"level": "יסודי בלבד",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מיטב מדעים ויהדות",
		"semelMosad": 719724,
		"settlement": "חדרה",
		"manager": "חגית חיה אביר",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מייסר",
		"semelMosad": 318220,
		"settlement": "מייסר",
		"manager": "סוהאד סייף",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "מים חיים מירושלים",
		"semelMosad": 747725,
		"settlement": "ירושלים",
		"manager": "רעי ישי מידד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מימון",
		"semelMosad": 110585,
		"settlement": "ירושלים",
		"manager": "אביתר שושן חורי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מימון מד",
		"semelMosad": 510081,
		"settlement": "אזור",
		"manager": "יעל כהן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מינזר הנזירות",
		"semelMosad": 318626,
		"settlement": "חיפה",
		"manager": "היפא נג'אר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "מירון מד",
		"semelMosad": 511907,
		"settlement": "תל אביב - יפו",
		"manager": "מוריה מאיה טרבלסי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מישור הנוף",
		"semelMosad": 415398,
		"settlement": "ראשון לציון",
		"manager": "ורד שיליאן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מישרים",
		"semelMosad": 442012,
		"settlement": "מרכז אזורי שוהם",
		"manager": "מיכל כליל-החורש",
		"level": "חט\"ב + עליונה",
		"special": "מיוחד",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מיתר",
		"semelMosad": 220533,
		"settlement": "בוסתן הגליל",
		"manager": "אריאלה גולני",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מיתר",
		"semelMosad": 312942,
		"settlement": "כפר גלים",
		"manager": "מיה שכטר-אורחן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מיתרים",
		"semelMosad": 571141,
		"settlement": "חולון",
		"manager": "רוית מגר",
		"level": "חט\"ב + עליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מיתרים",
		"semelMosad": 455931,
		"settlement": "פתח תקווה",
		"manager": "נחמה אליהו",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מיתרים",
		"semelMosad": 415943,
		"settlement": "ראשון לציון",
		"manager": "לילך איזן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מיתרים",
		"semelMosad": 384503,
		"settlement": "קרית ביאליק",
		"manager": "טל אינדיבו",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מיתרים רעננה",
		"semelMosad": 442251,
		"settlement": "רעננה",
		"manager": "דב דניאל ארבל",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מכ' עמל להנדסה באר שבע",
		"semelMosad": 670836,
		"settlement": "באר שבע",
		"manager": "מור בן עזרא",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מכבים-חדשני",
		"semelMosad": 413518,
		"settlement": "גן יבנה",
		"manager": "מירב הוד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מכון אהרון וישראל",
		"semelMosad": 140632,
		"settlement": "ירושלים",
		"manager": "אהרן פרידמן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מכון בית יעקב למורות",
		"semelMosad": 731174,
		"settlement": "מודיעין עילית",
		"manager": "ברכה הריס",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מכון בית יעקב למורות",
		"semelMosad": 742544,
		"settlement": "אלעד",
		"manager": "מלכה הורביץ",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מכון בית יעקב למורות",
		"semelMosad": 757641,
		"settlement": "ירושלים",
		"manager": "חיה שטראוס",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מכון בית יעקב למורות",
		"semelMosad": 190066,
		"settlement": "ירושלים",
		"manager": "ישראל לוין",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מכון הברכה",
		"semelMosad": 140608,
		"settlement": "ירושלים",
		"manager": "נחמה קוריץ",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מכון ש.פ.י.",
		"semelMosad": 440305,
		"settlement": "יהוד-מונוסון",
		"manager": "אלי סלומון",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מכין לבנות אלתורי",
		"semelMosad": 148239,
		"settlement": "ירושלים",
		"manager": "מיסאא אלגואנמה",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "מכלל",
		"semelMosad": 510776,
		"settlement": "רמת גן",
		"manager": "עינת זבולון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מכללה אזורית אשקלון",
		"semelMosad": 643601,
		"settlement": "גן יבנה",
		"manager": "יונית (חוה) כהן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מכללה אזורית ע.הירדן",
		"semelMosad": 270538,
		"settlement": "דגניה א'",
		"manager": "עידו חגאי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מכללה איזורית כסרא ס",
		"semelMosad": 733725,
		"settlement": "כסרא-סמיע",
		"manager": "מופיד רבאח",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "מכללה טכ' ע\"ש סיטרין",
		"semelMosad": 370080,
		"settlement": "בית צבי",
		"manager": "מנחם מענדל חייבי",
		"level": "יסודי ועליונה",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מכללה טכנו' חיל-אויר",
		"semelMosad": 770396,
		"settlement": "חיפה",
		"manager": "שלום בובליל",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מכללה טכנולוגית שפרע",
		"semelMosad": 647594,
		"settlement": "שפר",
		"manager": "ח'תאם יאסין",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "מכללה טכנולוגית שפרעם",
		"semelMosad": 575001,
		"settlement": "שפרעם",
		"manager": "האיל עזי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "מכללת אבראהימיה",
		"semelMosad": 144238,
		"settlement": "ירושלים",
		"manager": "אמין באשה",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "מכללת אור העתיד גן י",
		"semelMosad": 654210,
		"settlement": "גן יבנה",
		"manager": "מאיר אנטבי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מכללת אורט",
		"semelMosad": 770875,
		"settlement": "ירושלים",
		"manager": "דוד הנרי גרקו",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מכללת אורט בראודה",
		"semelMosad": 271015,
		"settlement": "כרמיאל",
		"manager": "סיגל כורם",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מכללת אורט סיגלובסקי",
		"semelMosad": 571281,
		"settlement": "תל אביב - יפו",
		"manager": "ישראל הרשקוביץ",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מכללת אורמת ע\"ש י. ג",
		"semelMosad": 718999,
		"settlement": "יבנה",
		"manager": "מרים קובלסקי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מכללת אלביאן",
		"semelMosad": 248260,
		"settlement": "בענה",
		"manager": "עלי אבו זיד",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "מכללת אלרסאלה",
		"semelMosad": 338772,
		"settlement": "שפרעם",
		"manager": "ראשא שלון",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "מכללת גרנאטה לחינוך",
		"semelMosad": 247312,
		"settlement": "כפר כנא",
		"manager": "פהימה מוסטפא אמארה",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "מכללת הרמלין להנדסה",
		"semelMosad": 471029,
		"settlement": "נתניה",
		"manager": "אריאל חורין",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ ג'-גוונים עפולה",
		"semelMosad": 213991,
		"settlement": "עפולה",
		"manager": "אילנה ימנה עוז",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ כי\"ח -צפת ב'",
		"semelMosad": 212043,
		"settlement": "צפת",
		"manager": "נחמה ססי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ צומח איי",
		"semelMosad": 484048,
		"settlement": "ראש העין",
		"manager": "גלי שלמוביץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ צומח בי",
		"semelMosad": 484022,
		"settlement": "ראש העין",
		"manager": "גל לוי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד אבן שוהם",
		"semelMosad": 213595,
		"settlement": "נהריה",
		"manager": "בטי בוקריס",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד אבני החושן",
		"semelMosad": 413211,
		"settlement": "שוהם",
		"manager": "פנינה אלמוג",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד אבני חפץ",
		"semelMosad": 415463,
		"settlement": "אבני חפץ",
		"manager": "שירה ישראל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד אוהל מאיר",
		"semelMosad": 410969,
		"settlement": "קרית עקרון",
		"manager": "טליה בר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד אוהל שלום",
		"semelMosad": 411843,
		"settlement": "ראש העין",
		"manager": "טל חריר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד אוהל שלום",
		"semelMosad": 410357,
		"settlement": "גדרה",
		"manager": "שרה לבז",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד אוהל שרה",
		"semelMosad": 411850,
		"settlement": "ראש העין",
		"manager": "אביבה צוברי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד אור השרון",
		"semelMosad": 412379,
		"settlement": "תנובות",
		"manager": "יעל יקיר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד אור זבולון",
		"semelMosad": 414557,
		"settlement": "אריאל",
		"manager": "דניאלה ינאי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד אור תורה",
		"semelMosad": 411827,
		"settlement": "קדימה-צורן",
		"manager": "אורית אשואל שליו",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד אור תורה",
		"semelMosad": 412361,
		"settlement": "תל מונד",
		"manager": "דפנה דעי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד אפרתי",
		"semelMosad": 413153,
		"settlement": "בני עי\"ש",
		"manager": "שי דהן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד אריאל",
		"semelMosad": 410415,
		"settlement": "גני תקווה",
		"manager": "יאיר מילוא",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד אריאל",
		"semelMosad": 413534,
		"settlement": "רמלה",
		"manager": "גילה ערוסי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד אריאל",
		"semelMosad": 414268,
		"settlement": "רעננה",
		"manager": "נועה ויצנר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד ביל\"ו",
		"semelMosad": 413492,
		"settlement": "רעננה",
		"manager": "מלכה נצחיה אדלר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד בית מרגלית",
		"semelMosad": 414466,
		"settlement": "נתניה",
		"manager": "יפה שנק",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד במעלה",
		"semelMosad": 410639,
		"settlement": "יהוד-מונוסון",
		"manager": "אילנית הרוש",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד בנות הר ברכה",
		"semelMosad": 482083,
		"settlement": "ברכה",
		"manager": "חגית קארו",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד בנות חב\"ד ב",
		"semelMosad": 411058,
		"settlement": "לוד",
		"manager": "צפורה מוזגורשוילי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד בנות נועם הצבי",
		"semelMosad": 413559,
		"settlement": "רחובות",
		"manager": "יובל לנגזם",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד בנות נחלת צבי",
		"semelMosad": 413989,
		"settlement": "אלון מורה",
		"manager": "מירב אהרונוב",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד בנות נחשון",
		"semelMosad": 413625,
		"settlement": "קדומים",
		"manager": "מנחם בן-יוסף",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד בנים הר ברכה",
		"semelMosad": 430306,
		"settlement": "ברכה",
		"manager": "אבינוח ברנר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד בנים חב\"ד ב",
		"semelMosad": 414219,
		"settlement": "לוד",
		"manager": "ברק כוכבי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד בנים נחלת צבי",
		"semelMosad": 414961,
		"settlement": "אלון מורה",
		"manager": "שמואל קנר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד בנים נחשון",
		"semelMosad": 414862,
		"settlement": "קדומים",
		"manager": "עמוס הלל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד בר אילן",
		"semelMosad": 412171,
		"settlement": "רמלה",
		"manager": "יהודה שדמי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד בר אילן",
		"semelMosad": 410829,
		"settlement": "כפר יונה",
		"manager": "אריאל טיבי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד בר אילן",
		"semelMosad": 410910,
		"settlement": "כפר סבא",
		"manager": "דרור סעדיה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד ברויאר צעירים",
		"semelMosad": 410589,
		"settlement": "יד בנימין",
		"manager": "נורית ראובן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד ברוכין",
		"semelMosad": 484071,
		"settlement": "ברוכין",
		"manager": "נעמי אופן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד ברקאי לבנים",
		"semelMosad": 414839,
		"settlement": "קרני שומרון",
		"manager": "אריאל בשן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד ג.מרדכי",
		"semelMosad": 430025,
		"settlement": "ירושלים",
		"manager": "אבישי יהודה פרינץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד גוננים",
		"semelMosad": 436725,
		"settlement": "ירושלים",
		"manager": "אפרת דבורה ארביב",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד דק\"ל",
		"semelMosad": 414474,
		"settlement": "כוכב יאיר",
		"manager": "צבי פייל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד המר-בית אפרים",
		"semelMosad": 412965,
		"settlement": "ראשון לציון",
		"manager": "יצחק עמר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד הצבי - קשת",
		"semelMosad": 213579,
		"settlement": "קשת",
		"manager": "גדעון טמיר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד הראל",
		"semelMosad": 610923,
		"settlement": "מצפה רמון",
		"manager": "בצלאל סגמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד הראל",
		"semelMosad": 413393,
		"settlement": "ראשון לציון",
		"manager": "אושירה ארצי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד הרי\"ן",
		"semelMosad": 414060,
		"settlement": "רמלה",
		"manager": "ציון משה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד חב\"ד",
		"semelMosad": 410928,
		"settlement": "כפר סבא",
		"manager": "מוסיה מרים גולדשמיד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד חב\"ד -יבנאל",
		"semelMosad": 210815,
		"settlement": "יבנאל",
		"manager": "יוסף מאיר אפריגן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד חב\"ד אוהל יצחק",
		"semelMosad": 411298,
		"settlement": "נס ציונה",
		"manager": "חיה פייגה זלמנוב",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד חב\"ד בנים",
		"semelMosad": 414599,
		"settlement": "רחובות",
		"manager": "אמיר הרץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד חבד בנות ח.מושק",
		"semelMosad": 415521,
		"settlement": "נתניה",
		"manager": "גיטה אילה וולפא",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד חבד בנים ד.מנחם",
		"semelMosad": 414730,
		"settlement": "נתניה",
		"manager": "מאיר מור יוסף",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד טללי אורות",
		"semelMosad": 415455,
		"settlement": "חיננית",
		"manager": "יפעת שבלב",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד יבנה",
		"semelMosad": 412254,
		"settlement": "רעננה",
		"manager": "רון נחשון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד יובלים",
		"semelMosad": 410332,
		"settlement": "אורנית",
		"manager": "שרון הלר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד יחידני",
		"semelMosad": 413997,
		"settlement": "יבנה",
		"manager": "שני ברזילי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד יסודי הרואה",
		"semelMosad": 410720,
		"settlement": "כפר הרא\"ה",
		"manager": "קרן זהבה הולנדר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד יקיר",
		"semelMosad": 414623,
		"settlement": "יקיר",
		"manager": "דקלה רגנסבורגר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד ישורון",
		"semelMosad": 412874,
		"settlement": "נתניה",
		"manager": "שרונה קרסנר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד ישורון",
		"semelMosad": 411934,
		"settlement": "ראשון לציון",
		"manager": "רויטל נהמי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד לבנות שלהבת",
		"semelMosad": 415976,
		"settlement": "שעלבים",
		"manager": "שולמית דונר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד לבנים",
		"semelMosad": 520098,
		"settlement": "הרצליה",
		"manager": "יואל רז",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד לבנים שלהבת",
		"semelMosad": 412940,
		"settlement": "שעלבים",
		"manager": "אברהם קרקובר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד לבנים- יסודי תלמוד תורה",
		"semelMosad": 410746,
		"settlement": "כפר חב\"ד",
		"manager": "מנחם מנדל כהן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד לטכנולוגיה ולאומנויות",
		"semelMosad": 544056,
		"settlement": "תל אביב - יפו",
		"manager": "יוספה גלית לויים",
		"level": "חט\"ב + עליונה",
		"special": "מיוחד",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד לפידים",
		"semelMosad": 412288,
		"settlement": "קרני שומרון",
		"manager": "מלכה שירזי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד מודיעים",
		"semelMosad": 410092,
		"settlement": "נחלים",
		"manager": "יפעת אסתי גלרנטר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד מורשת",
		"semelMosad": 214577,
		"settlement": "מורשת",
		"manager": "אליהו נמתיוף",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד מורשת זבולון",
		"semelMosad": 415117,
		"settlement": "גבעת שמואל",
		"manager": "רחל שמוקלר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד מורשת מנחם",
		"semelMosad": 413740,
		"settlement": "גבעת שמואל",
		"manager": "עידן טחן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד מעורב",
		"semelMosad": 645804,
		"settlement": "נתיבות",
		"manager": "יעל ביטן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד מעלה שמעוני",
		"semelMosad": 414680,
		"settlement": "ראשון לציון",
		"manager": "נתנאל בן-דוד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד מעלות משולם",
		"semelMosad": 414250,
		"settlement": "רחובות",
		"manager": "מאיר יצחק אמויאל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד משואה",
		"semelMosad": 413849,
		"settlement": "קרני שומרון",
		"manager": "ורד אלג'ם",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד נועם",
		"semelMosad": 415372,
		"settlement": "נתניה",
		"manager": "מרים פרל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד נועם",
		"semelMosad": 214668,
		"settlement": "צפת",
		"manager": "יגאל בנימין לוגשי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד נועם -עציון",
		"semelMosad": 413054,
		"settlement": "יבנה",
		"manager": "עובדיה מאנע",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד נועם הצבי-בנים",
		"semelMosad": 414185,
		"settlement": "רחובות",
		"manager": "משה מנחם לבקוביץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד נועם טבריה",
		"semelMosad": 214411,
		"settlement": "טבריה",
		"manager": "חי דמרי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד נועם-כפר בתיה",
		"semelMosad": 415166,
		"settlement": "רעננה",
		"manager": "איילת אופיר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד נצח ישראל",
		"semelMosad": 411736,
		"settlement": "פתח תקווה",
		"manager": "רווית זר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד סיני",
		"semelMosad": 412064,
		"settlement": "רחובות",
		"manager": "עמנואל סברי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד סיני",
		"semelMosad": 411942,
		"settlement": "ראשון לציון",
		"manager": "ענת דהן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד סיני",
		"semelMosad": 410399,
		"settlement": "גן יבנה",
		"manager": "לימור בשארי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד סיני",
		"semelMosad": 420505,
		"settlement": "רמלה",
		"manager": "חיה ברקוביץ",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד ע\"ש ברויאר בוגרים",
		"semelMosad": 413583,
		"settlement": "יד בנימין",
		"manager": "תומר תמרי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד עוזיאל",
		"semelMosad": 414177,
		"settlement": "נתניה",
		"manager": "צמרת בן חיים",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד עמיח\"י",
		"semelMosad": 410977,
		"settlement": "קבוצת יבנה",
		"manager": "יניב ערקי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד עמנואל",
		"semelMosad": 482489,
		"settlement": "לוד",
		"manager": "איתן כהן",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד פדואל",
		"semelMosad": 416214,
		"settlement": "פדואל",
		"manager": "יעל נדב",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד פסגת דוד",
		"semelMosad": 112573,
		"settlement": "ירושלים",
		"manager": "אלדד כהן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד צורים",
		"semelMosad": 413724,
		"settlement": "ראש העין",
		"manager": "רננה כהן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד צמח השדה",
		"semelMosad": 113753,
		"settlement": "מעלה אדומים",
		"manager": "בועז אלחנן סמואל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד קרליבך",
		"semelMosad": 530337,
		"settlement": "נתניה",
		"manager": "בלה פירסט",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד רבבה בנות",
		"semelMosad": 482190,
		"settlement": "רבבה",
		"manager": "צבי שיוביץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד רבבה בנים",
		"semelMosad": 430330,
		"settlement": "רבבה",
		"manager": "ישי גרטלר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד רמב\"ם",
		"semelMosad": 411868,
		"settlement": "ראש העין",
		"manager": "אביחי רצון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד רמב\"ם",
		"semelMosad": 410068,
		"settlement": "אליכין",
		"manager": "שלמה ישראל אטיאס",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד רמב\"ם",
		"semelMosad": 410175,
		"settlement": "בית דגן",
		"manager": "רוני אהרוני",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד רמב\"ם",
		"semelMosad": 414276,
		"settlement": "לוד",
		"manager": "רביטל ז'נט קנינו",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד רעות",
		"semelMosad": 414037,
		"settlement": "נס ציונה",
		"manager": "אופיר אביקסיס",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד רש\"י",
		"semelMosad": 411439,
		"settlement": "נתניה",
		"manager": "שמעון מסעוד לוי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד שבטי ישראל",
		"semelMosad": 232900,
		"settlement": "מודיעין-מכבים-רעות",
		"manager": "נורית צפורה מררי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד שבי שומרון",
		"semelMosad": 413708,
		"settlement": "שבי שומרון",
		"manager": "נאוה מרים האיתן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד שילה",
		"semelMosad": 414490,
		"settlement": "כפר סבא",
		"manager": "ליאת אחרק",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד שילה",
		"semelMosad": 411082,
		"settlement": "הוד השרון",
		"manager": "סילבי ויסברט",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד שכטרמן",
		"semelMosad": 410837,
		"settlement": "כפר יעבץ",
		"manager": "ישראל מאיר אפרתי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד שערי תקווה",
		"semelMosad": 430322,
		"settlement": "שערי תקווה",
		"manager": "ליאור אמיר אליאסי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד תורני למדעים",
		"semelMosad": 613489,
		"settlement": "אשקלון",
		"manager": "שרית שמואל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד תורני נווה אברהם",
		"semelMosad": 417220,
		"settlement": "פתח תקווה",
		"manager": "יעל שטרן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד תחכמוני",
		"semelMosad": 411496,
		"settlement": "נתניה",
		"manager": "חגית יודקוביץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ\"ד תחכמוני",
		"semelMosad": 412080,
		"settlement": "רחובות",
		"manager": "עודד כלפה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ' אהרונוביץ",
		"semelMosad": 410845,
		"settlement": "נווה ירק",
		"manager": "נחמה שיינברג",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ' בכר רוסו",
		"semelMosad": 411793,
		"settlement": "צור משה",
		"manager": "יפית יפה הראל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ' גן רווה ע\"ש יצחק נבון",
		"semelMosad": 410183,
		"settlement": "עיינות",
		"manager": "משה מור",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ' גנים",
		"semelMosad": 414565,
		"settlement": "גני תקווה",
		"manager": "מיכאל הררי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ' המר(גני אביב ב)",
		"semelMosad": 415984,
		"settlement": "לוד",
		"manager": "איריס נעים",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ' הרצוג",
		"semelMosad": 411116,
		"settlement": "מזכרת בתיה",
		"manager": "מיכל משולם",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ' מעבר אפק",
		"semelMosad": 411546,
		"settlement": "עינת",
		"manager": "גלי אנגלשטיין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ' פינס",
		"semelMosad": 410340,
		"settlement": "גדרה",
		"manager": "גלית אקריש",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ' רביבים",
		"semelMosad": 410407,
		"settlement": "גני תקווה",
		"manager": "איריס בוצר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממ' רימון - ג.שומרון",
		"semelMosad": 414540,
		"settlement": "קרני שומרון",
		"manager": "רות רוזנטל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממגד גבעות",
		"semelMosad": 384180,
		"settlement": "רכסים",
		"manager": "רבקה דחבש",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממד בירב -צפת",
		"semelMosad": 212068,
		"settlement": "צפת",
		"manager": "מיכל דמרי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממד בנות יסודי בית רבקה בוגרות",
		"semelMosad": 412882,
		"settlement": "כפר חב\"ד",
		"manager": "ברוריה הולצמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממד נעם",
		"semelMosad": 312629,
		"settlement": "קרית אתא",
		"manager": "רחל איטח",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממדבר מתנה - ניצנה",
		"semelMosad": 749630,
		"settlement": "ניצנה (קהילת חינוך)",
		"manager": "גבריאל טרבלסי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממל' אורנים",
		"semelMosad": 411181,
		"settlement": "יהוד-מונוסון",
		"manager": "אתי אינדיג",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממל' מילקין",
		"semelMosad": 414581,
		"settlement": "אריאל",
		"manager": "רועי אביב",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממל' נחשונים",
		"semelMosad": 413377,
		"settlement": "אריאל",
		"manager": "איריס ורד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממל' עומר",
		"semelMosad": 414193,
		"settlement": "שקד",
		"manager": "אפרת צארום",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממל' עליזה בגין",
		"semelMosad": 414417,
		"settlement": "אריאל",
		"manager": "שלומית בן ישי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממלכתי",
		"semelMosad": 413369,
		"settlement": "ניר צבי",
		"manager": "דרור גרשטיין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממלכתי א",
		"semelMosad": 410456,
		"settlement": "הוד השרון",
		"manager": "שלי קורן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממלכתי א מגדל שמס",
		"semelMosad": 217828,
		"settlement": "מג'דל שמס",
		"manager": "רפיק עוידאת",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "דרוזי"
	},
	{
		"schoolName": "ממלכתי א'",
		"semelMosad": 418244,
		"settlement": "ג'לג'וליה",
		"manager": "מנאל ראבי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "ממלכתי א' גוליס",
		"semelMosad": 219303,
		"settlement": "ג'ולס",
		"manager": "ג'דעאן כבישי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "דרוזי"
	},
	{
		"schoolName": "ממלכתי אזורי",
		"semelMosad": 410019,
		"settlement": "אביחיל",
		"manager": "אלי ארביב",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממלכתי אל סאלם -חדש",
		"semelMosad": 418301,
		"settlement": "קלנסווה",
		"manager": "אימאן סלאמה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "ממלכתי אפק",
		"semelMosad": 415133,
		"settlement": "ראש העין",
		"manager": "מיטל ארליך",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממלכתי אשכול",
		"semelMosad": 415240,
		"settlement": "ראש העין",
		"manager": "לימור נגר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממלכתי ב' גוליס",
		"semelMosad": 218131,
		"settlement": "ג'ולס",
		"manager": "סנא נבואני",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "דרוזי"
	},
	{
		"schoolName": "ממלכתי ב' דיר חנא",
		"semelMosad": 218180,
		"settlement": "דייר חנא",
		"manager": "נאסר נסרה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "ממלכתי בי\"ס אזורי",
		"semelMosad": 410209,
		"settlement": "בית יצחק-שער חפר",
		"manager": "גילה ציטרון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממלכתי בן צבי",
		"semelMosad": 410951,
		"settlement": "קרית עקרון",
		"manager": "אוסנת דולב כהן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממלכתי ג'",
		"semelMosad": 113258,
		"settlement": "מעלה אדומים",
		"manager": "שרון אלמליח",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממלכתי הדר",
		"semelMosad": 416958,
		"settlement": "כפר יונה",
		"manager": "רות מילשטיין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממלכתי הדר השרון",
		"semelMosad": 415315,
		"settlement": "חירות",
		"manager": "שונית לאופר זילברמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממלכתי ויתקין",
		"semelMosad": 410738,
		"settlement": "כפר ויתקין",
		"manager": "אורנה כהן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממלכתי חדשני אופק",
		"semelMosad": 414953,
		"settlement": "בני עי\"ש",
		"manager": "ליאת דוד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממלכתי טל",
		"semelMosad": 415125,
		"settlement": "ראש העין",
		"manager": "הדס ברכה קימל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממלכתי לב רן",
		"semelMosad": 414805,
		"settlement": "קדימה-צורן",
		"manager": "לואיז בר עם",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממלכתי לפיד",
		"semelMosad": 415075,
		"settlement": "לפיד",
		"manager": "שירית רחל אהרוני",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממלכתי מאוחד יובלים",
		"semelMosad": 410308,
		"settlement": "גבעת חיים (מאוחד)",
		"manager": "הילה לרר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממלכתי משגב",
		"semelMosad": 410290,
		"settlement": "גבעת חיים (איחוד)",
		"manager": "עדי גזית",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממלכתי משואה",
		"semelMosad": 113589,
		"settlement": "ירושלים",
		"manager": "שרה אלבז",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממלכתי מתן",
		"semelMosad": 411199,
		"settlement": "מתן",
		"manager": "הדר טמלמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממלכתי נאעורה",
		"semelMosad": 218545,
		"settlement": "נאעורה",
		"manager": "אחמד זועבי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "ממלכתי נוה דליה",
		"semelMosad": 414904,
		"settlement": "ראש העין",
		"manager": "סיגל ראש",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממלכתי נוף צורים",
		"semelMosad": 414144,
		"settlement": "כוכב יאיר",
		"manager": "קמי רינת פייראיזן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממלכתי נופים",
		"semelMosad": 414896,
		"settlement": "ראש העין",
		"manager": "מיכל אקסלרוד כהן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממלכתי ניצני השרון",
		"semelMosad": 415083,
		"settlement": "קדימה-צורן",
		"manager": "שרון טרנר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממלכתי ניצנים",
		"semelMosad": 413732,
		"settlement": "שוהם",
		"manager": "רינת מרציאנו",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממלכתי עמית",
		"semelMosad": 414052,
		"settlement": "מודיעין-מכבים-רעות",
		"manager": "ענבל לוי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממלכתי עמל",
		"semelMosad": 410811,
		"settlement": "כפר יונה",
		"manager": "אדוה גת",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממלכתי צופית",
		"semelMosad": 411785,
		"settlement": "בית ברל",
		"manager": "אורית לנדאו",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממלכתי רעים",
		"semelMosad": 412049,
		"settlement": "מודיעין-מכבים-רעות",
		"manager": "גילה בר לב",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממלכתי שדות",
		"semelMosad": 414284,
		"settlement": "בת חפר",
		"manager": "ליבנת הפלר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממלכתי שדות -רמז",
		"semelMosad": 415042,
		"settlement": "בית דגן",
		"manager": "גלית תירוש",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממלכתי שלנו",
		"semelMosad": 412346,
		"settlement": "תל מונד",
		"manager": "ענבל שביט",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ממלכתי תפו\"ז",
		"semelMosad": 413146,
		"settlement": "פרדסיה",
		"manager": "לילך חגי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מנו\"ף",
		"semelMosad": 771287,
		"settlement": "עכו",
		"manager": "קרן כספין בן ציון",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מנזר רוזרי",
		"semelMosad": 160739,
		"settlement": "ירושלים",
		"manager": "הודה קיקיה",
		"level": "יסודי ועליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "מנחם בגין",
		"semelMosad": 113530,
		"settlement": "בית שמש",
		"manager": "בת שבע עבוש",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מנחם בגין",
		"semelMosad": 213926,
		"settlement": "נהריה",
		"manager": "חגית בן גוזי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מנחם בגין",
		"semelMosad": 614099,
		"settlement": "דימונה",
		"manager": "רעות פלדברג",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מנחם בגין",
		"semelMosad": 614263,
		"settlement": "קרית גת",
		"manager": "מרים קסוס",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מנחם בגין",
		"semelMosad": 213280,
		"settlement": "נוף הגליל",
		"manager": "חוה אבוקרט",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מנחם בגין",
		"semelMosad": 475053,
		"settlement": "הוד השרון",
		"manager": "יוספה ספי זלצמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מנחת",
		"semelMosad": 114033,
		"settlement": "ירושלים",
		"manager": "אביבית ליזה שור זקן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מנחת חינוך אהל אשר",
		"semelMosad": 731190,
		"settlement": "מודיעין עילית",
		"manager": "אהרן דויטש",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מנחת ירושלים",
		"semelMosad": 749978,
		"settlement": "בית שמש",
		"manager": "אסתר שיינא לינדר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מנחת שלמה",
		"semelMosad": 160671,
		"settlement": "ירושלים",
		"manager": "גדליהו דבינסקי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מנשה אלישר",
		"semelMosad": 111872,
		"settlement": "ירושלים",
		"manager": "הדס קצב-שטויאר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מסאר",
		"semelMosad": 338715,
		"settlement": "נצרת",
		"manager": "ח'ולוד עבד אלהאדי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "מסאר",
		"semelMosad": 238089,
		"settlement": "נצרת",
		"manager": "רולא שומר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "מסילהבערבה שושנתיעקב",
		"semelMosad": 614891,
		"settlement": "ירוחם",
		"manager": "רפאל עמרם לונטל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מסילות",
		"semelMosad": 520023,
		"settlement": "בת ים",
		"manager": "רחל לוי",
		"level": "יסודי בלבד",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מסילת יעקב",
		"semelMosad": 343996,
		"settlement": "ירושלים",
		"manager": "מידי קרויזר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מעבר לקשת",
		"semelMosad": 649152,
		"settlement": "קרית גת",
		"manager": "ויולט צוקרמן",
		"level": "יסודי בלבד",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מעגלים",
		"semelMosad": 610774,
		"settlement": "מעגלים",
		"manager": "אמנון אברגל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מעגלים",
		"semelMosad": 731935,
		"settlement": "נתניה",
		"manager": "ענבל לב",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מעגנים",
		"semelMosad": 311142,
		"settlement": "מעגן מיכאל",
		"manager": "מרגלית מור",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מעוז התורה",
		"semelMosad": 514786,
		"settlement": "בני ברק",
		"manager": "אסף אביטן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מעוז חיים",
		"semelMosad": 475723,
		"settlement": "לוד",
		"manager": "יחזקאל חזי כהן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מעוז חיים ירושלים",
		"semelMosad": 744250,
		"settlement": "ירושלים",
		"manager": "עמיאל אילן כהן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מעוז ירושלים שיח סוד",
		"semelMosad": 162016,
		"settlement": "ירושלים",
		"manager": "חנה דבורה פעלד",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מעוז מכבים",
		"semelMosad": 414631,
		"settlement": "מודיעין-מכבים-רעות",
		"manager": "ענת גרימברג",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מעון יום אלאמל אעבלי",
		"semelMosad": 228163,
		"settlement": "אעבלין",
		"manager": "אמאל טאהא",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "מעון יום בועינה נג'א",
		"semelMosad": 228353,
		"settlement": "בועיינה-נוג'ידאת",
		"manager": "לילא סלימאן",
		"level": "יסודי חט\"ב ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "מעון עדנאן-ירכא",
		"semelMosad": 228338,
		"settlement": "ירכא",
		"manager": "רשא עודה סחניני",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "דרוזי"
	},
	{
		"schoolName": "מעוף",
		"semelMosad": 521203,
		"settlement": "פתח תקווה",
		"manager": "שרון משיח",
		"level": "חט\"ב + עליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מעיין",
		"semelMosad": 414227,
		"settlement": "יבנה",
		"manager": "רננה אשתר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מעיין",
		"semelMosad": 441923,
		"settlement": "פתח תקווה",
		"manager": "איתמר ישראל אליהו עמר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מעיין",
		"semelMosad": 723486,
		"settlement": "כפר חסידים א'",
		"manager": "עירד שושני",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מעיין האמונה בנות",
		"semelMosad": 383729,
		"settlement": "חריש",
		"manager": "סמדר צורישדי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מעיין חיים",
		"semelMosad": 616185,
		"settlement": "עלי",
		"manager": "אורן בצלאל סיטבון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מעיין חיים ושלום",
		"semelMosad": 660043,
		"settlement": "אשקלון",
		"manager": "חיים כהן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מעיין חיים ושלום",
		"semelMosad": 640938,
		"settlement": "אשקלון",
		"manager": "אמונה דיאמנט",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מעיין חיים ושלום בנות",
		"semelMosad": 614420,
		"settlement": "אשקלון",
		"manager": "גיטל שיינא שמידע",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מעיין חיים ושלום בנים",
		"semelMosad": 614230,
		"settlement": "אשקלון",
		"manager": "יהושע שי עמרם",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מעיין שרה",
		"semelMosad": 620013,
		"settlement": "אשקלון",
		"manager": "חגית רוקח",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מעיינות",
		"semelMosad": 357475,
		"settlement": "פרדס חנה-כרכור",
		"manager": "נורית בנולול",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מעיינות",
		"semelMosad": 611327,
		"settlement": "נטור",
		"manager": "צורית אבן אש",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מעיינות בית רבקה",
		"semelMosad": 372953,
		"settlement": "ביתר עילית",
		"manager": "שולמית ברכה לנדאו",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מעייני הישועה",
		"semelMosad": 540138,
		"settlement": "בני ברק",
		"manager": "טובה אסתר אופנהימר",
		"level": "יסודי חט\"ב ועליונה",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מעיל שמואל",
		"semelMosad": 647487,
		"settlement": "צפת",
		"manager": "נתנאל יעקובוביץ",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מעינות",
		"semelMosad": 630079,
		"settlement": "בר גיורא",
		"manager": "עידן שניר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מעלה",
		"semelMosad": 370551,
		"settlement": "ירושלים",
		"manager": "אריאל צלקו",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מעלה ההר",
		"semelMosad": 616193,
		"settlement": "מצובה",
		"manager": "שירי ולטר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מעלה התורה בנות",
		"semelMosad": 131029,
		"settlement": "מעלה אדומים",
		"manager": "אילת כהן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מעלה התורה בנים",
		"semelMosad": 113308,
		"settlement": "מעלה אדומים",
		"manager": "אברהם מיארה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מעלה מד",
		"semelMosad": 520197,
		"settlement": "תל אביב - יפו",
		"manager": "תמר זילברשטיין",
		"level": "יסודי בלבד",
		"special": "מיוחד",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מעלה מכמש",
		"semelMosad": 166074,
		"settlement": "מעלה מכמש",
		"manager": "יעל שפט",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מעלה שחרות",
		"semelMosad": 770602,
		"settlement": "יטבתה",
		"manager": "אפרת אדר",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מעלות",
		"semelMosad": 620153,
		"settlement": "אשדוד",
		"manager": "דורית שלום",
		"level": "יסודי חט\"ב ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מעלות בית יעקב",
		"semelMosad": 141853,
		"settlement": "ירושלים",
		"manager": "אריה רוב",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מעלות דפנה יד המורה",
		"semelMosad": 111278,
		"settlement": "ירושלים",
		"manager": "אסתר עזרא",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מעלות הגולן",
		"semelMosad": 384594,
		"settlement": "רמות",
		"manager": "ורד גורפינקל שמיר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מעלות התורה",
		"semelMosad": 724682,
		"settlement": "חוסן",
		"manager": "",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מעלות ירושלים",
		"semelMosad": 756577,
		"settlement": "בית שמש",
		"manager": "פרומה מיסקי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מעלות נסים ממד עפולה",
		"semelMosad": 211953,
		"settlement": "עפולה",
		"manager": "אליאור בטיטו",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מעלות שמחה",
		"semelMosad": 734061,
		"settlement": "חצור הגלילית",
		"manager": "נטע מלכה",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מעלות שרה",
		"semelMosad": 460469,
		"settlement": "מודיעין עילית",
		"manager": "אושרת טולידאנו",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מענית",
		"semelMosad": 412122,
		"settlement": "רמלה",
		"manager": "שרה דוד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מענית",
		"semelMosad": 610303,
		"settlement": "באר שבע",
		"manager": "שושנה דהן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מעשי הגדולים",
		"semelMosad": 430413,
		"settlement": "ביתר עילית",
		"manager": "יוסף אקשטיין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מפי עוללים",
		"semelMosad": 384131,
		"settlement": "רכסים",
		"manager": "אברהם בן שלום",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מפתן",
		"semelMosad": 430488,
		"settlement": "כפר סבא",
		"manager": "מרים עמראני",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מפתן אלון",
		"semelMosad": 530287,
		"settlement": "תל אביב - יפו",
		"manager": "נדב וולצוק",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מפתן אם אלפחם",
		"semelMosad": 328062,
		"settlement": "אום אל-פחם",
		"manager": "הנא מחאג'נה",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "מפתן ארזים",
		"semelMosad": 430678,
		"settlement": "יבנה",
		"manager": "יצחק דודי",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מפתן בית חגי",
		"semelMosad": 634113,
		"settlement": "חגי",
		"manager": "רון שמעון אבנאים",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מפתן הרצליה",
		"semelMosad": 530683,
		"settlement": "הרצליה",
		"manager": "אמתי ליפסמה",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מפתן טירה",
		"semelMosad": 420554,
		"settlement": "טירה",
		"manager": "נרין עיראקי",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "מפתן מג'אר",
		"semelMosad": 238055,
		"settlement": "מגאר",
		"manager": "רושדי עראידה",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "דרוזי"
	},
	{
		"schoolName": "מפתן נצרת",
		"semelMosad": 238014,
		"settlement": "נצרת",
		"manager": "נידא אחמד",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "מפתן קרית ים",
		"semelMosad": 330266,
		"settlement": "קרית ים",
		"manager": "חיה חמיש",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מפתן שפרעם",
		"semelMosad": 238022,
		"settlement": "שפרעם",
		"manager": "אקבאל חסון",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "מפתן תמרה",
		"semelMosad": 238048,
		"settlement": "טמרה",
		"manager": "עבדאללה אבו אל היג'א",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "מצדה",
		"semelMosad": 520486,
		"settlement": "תל אביב - יפו",
		"manager": "גלית אזרזר",
		"level": "יסודי בלבד",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מצודות-קרית שמונה",
		"semelMosad": 212092,
		"settlement": "קרית שמונה",
		"manager": "שולמית בן שטרית",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מצפה",
		"semelMosad": 311621,
		"settlement": "קרית מוצקין",
		"manager": "רות דדון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מצפה",
		"semelMosad": 611939,
		"settlement": "באר שבע",
		"manager": "רינת מדינה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מצפה ברנע א",
		"semelMosad": 611954,
		"settlement": "אשקלון",
		"manager": "אלכסנדרה יאמין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מצפה גולן",
		"semelMosad": 213272,
		"settlement": "בני יהודה",
		"manager": "מירה הופמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מצפה ים",
		"semelMosad": 614073,
		"settlement": "אילת",
		"manager": "סיגל רחמים",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מצפה ימים",
		"semelMosad": 611921,
		"settlement": "אשקלון",
		"manager": "יפה אילוז",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מצפה יריחו",
		"semelMosad": 112151,
		"settlement": "מצפה יריחו",
		"manager": "יוסף יהושע יחזק פלהימר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מצפור סביונים",
		"semelMosad": 214452,
		"settlement": "מעלות-תרשיחא",
		"manager": "סימה עזריה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקדש מנחם",
		"semelMosad": 442202,
		"settlement": "אלעד",
		"manager": "יעקב זכריה קטן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקוה ישראל דתי",
		"semelMosad": 580027,
		"settlement": "מקווה ישראל",
		"manager": "ברוריה נחמיאס",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקוה ישראל כללי",
		"semelMosad": 580035,
		"settlement": "מקווה ישראל",
		"manager": "דפנה שניידר",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקום לגדול",
		"semelMosad": 510172,
		"settlement": "רמת השרון",
		"manager": "גלעד ליבמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקום לחלום",
		"semelMosad": 759555,
		"settlement": "רמת השרון",
		"manager": "נטליה טרבילו",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקור ברוך",
		"semelMosad": 540278,
		"settlement": "בני ברק",
		"manager": "הרצקע שובקס",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקור ברוך סרט ויזניץ",
		"semelMosad": 513903,
		"settlement": "בני ברק",
		"manager": "יצחק מנדלקורן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקור התקווה",
		"semelMosad": 430074,
		"settlement": "ירושלים",
		"manager": "יואל רוסו",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקור חיים",
		"semelMosad": 113399,
		"settlement": "ירושלים",
		"manager": "אביתר כהן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקור חיים מד",
		"semelMosad": 510156,
		"settlement": "בני ברק",
		"manager": "אורית עוזרי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקור חכמה",
		"semelMosad": 530030,
		"settlement": "בית שמש",
		"manager": "אריאל עזריאל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף \"אבן רושד",
		"semelMosad": 640797,
		"settlement": "רהט",
		"manager": "סאבר אבו מדיגם",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "מקיף \"בית אלחכמה ",
		"semelMosad": 338657,
		"settlement": "נצרת",
		"manager": "סימא דראושה",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "מקיף א כללי",
		"semelMosad": 640128,
		"settlement": "אשדוד",
		"manager": "הדס בן שושן",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף א'לנור",
		"semelMosad": 648113,
		"settlement": "רהט",
		"manager": "חלד זיאדנה",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "מקיף א'עש שיךס.כניפס",
		"semelMosad": 247130,
		"settlement": "שפרעם",
		"manager": "סלמאן אבו עביד",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "מקיף אבדאע",
		"semelMosad": 645838,
		"settlement": "ערערה-בנגב",
		"manager": "",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "מקיף אבו גוש",
		"semelMosad": 148080,
		"settlement": "אבו גוש",
		"manager": "מחמד עבדל רחמן",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "מקיף אבן אלהייתם",
		"semelMosad": 248195,
		"settlement": "באקה אל-גרביה",
		"manager": "מוחמד ענאבוסי",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "מקיף אבן ח'לדון",
		"semelMosad": 248575,
		"settlement": "עראבה",
		"manager": "אוסאמה כנאענה",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "מקיף אורט",
		"semelMosad": 340034,
		"settlement": "קרית מוצקין",
		"manager": "שרה פינטו",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף אורט אחווה",
		"semelMosad": 247866,
		"settlement": "נאעורה",
		"manager": "עבד אל סלאם אבו אחמד",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "מקיף אורט כרמים",
		"semelMosad": 244368,
		"settlement": "כרמיאל",
		"manager": "אילן פרסטר",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף אורט למדע וטכנו",
		"semelMosad": 570168,
		"settlement": "חולון",
		"manager": "שלמה גנון",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף אורט מעלות",
		"semelMosad": 240465,
		"settlement": "מעלות-תרשיחא",
		"manager": "שולמית אליקן",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף אורט ע\"ש אברט",
		"semelMosad": 770776,
		"settlement": "חצור הגלילית",
		"manager": "דורית בטאן",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף אורט ע\"ש גוטמן",
		"semelMosad": 471011,
		"settlement": "נתניה",
		"manager": "טל בר-מעוז",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף אורט עש גרינברג",
		"semelMosad": 340059,
		"settlement": "קרית טבעון",
		"manager": "אתי עמנואל לוי",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף אזורי ברנר",
		"semelMosad": 770065,
		"settlement": "גבעת ברנר",
		"manager": "יצחק עוזר חולדאי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף אזורי מרחבים",
		"semelMosad": 770990,
		"settlement": "בתי ספר של מרחבים",
		"manager": "רחל סיני",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף אזורי עמי אסף",
		"semelMosad": 460030,
		"settlement": "בית ברל",
		"manager": "מרב פרידלנדר",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף אחווה ירכא",
		"semelMosad": 800060,
		"settlement": "ירכא",
		"manager": "סלמאן מולא",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "דרוזי"
	},
	{
		"schoolName": "מקיף אל-בטוף",
		"semelMosad": 248435,
		"settlement": "עוזייר",
		"manager": "חאתם אבו חוסין",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "מקיף אל-פרוק",
		"semelMosad": 648139,
		"settlement": "כסיפה",
		"manager": "עודה אלעמור",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "מקיף אלבוכארי",
		"semelMosad": 247247,
		"settlement": "עראבה",
		"manager": "מרעב עאסלה",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "מקיף אלבטוף עראבה",
		"semelMosad": 249284,
		"settlement": "עראבה",
		"manager": "סמיר נסאר",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "מקיף אלוני יצחק",
		"semelMosad": 360149,
		"settlement": "אלוני יצחק",
		"manager": "רון חי",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף אלחיאת",
		"semelMosad": 648154,
		"settlement": "חורה",
		"manager": "סברי עוקבי",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "מקיף אלמג'ד ע\"ש השיח",
		"semelMosad": 477166,
		"settlement": "שגב-שלום",
		"manager": "זוהיר אבו בלאל",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "מקיף אלנהדה",
		"semelMosad": 448340,
		"settlement": "כפר ברא",
		"manager": "דרהקאן ריאן",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "מקיף אלנור",
		"semelMosad": 644203,
		"settlement": "חורה",
		"manager": "חאפז אלעטאונה",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "מקיף אלנור ערערה",
		"semelMosad": 648147,
		"settlement": "ערערה-בנגב",
		"manager": "אברהים אבו גויעד",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "מקיף אלסאלם",
		"semelMosad": 648303,
		"settlement": "שגב-שלום",
		"manager": "עלי אבן נסיר",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "מקיף אלסלעה",
		"semelMosad": 715623,
		"settlement": "ירושלים",
		"manager": "ולאא גול",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "מקיף אלרסאלה",
		"semelMosad": 248443,
		"settlement": "נחף",
		"manager": "חוסאם עבאס",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "מקיף אלתקוא",
		"semelMosad": 648329,
		"settlement": "רהט",
		"manager": "פואד עמראני",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "מקיף אם אל פחם",
		"semelMosad": 348037,
		"settlement": "אום אל-פחם",
		"manager": "אחמד שיך חוסין",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "מקיף אנה פרנק",
		"semelMosad": 280115,
		"settlement": "סאסא",
		"manager": "אופיר נבון",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף אעבלין",
		"semelMosad": 247239,
		"settlement": "אעבלין",
		"manager": "סאלחה אדריס",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "מקיף אפרים קציר",
		"semelMosad": 544353,
		"settlement": "חולון",
		"manager": "מלכה חרותי",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף אריאל",
		"semelMosad": 440925,
		"settlement": "אריאל",
		"manager": "אביגיל סאמין",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף ב'",
		"semelMosad": 248104,
		"settlement": "מגאר",
		"manager": "עפיף עראידה",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "דרוזי"
	},
	{
		"schoolName": "מקיף באר טוביה",
		"semelMosad": 660134,
		"settlement": "באר טוביה",
		"manager": "אביגדור ויצמן",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף בוסתן אל מרג'",
		"semelMosad": 248229,
		"settlement": "ניין",
		"manager": "סלמאן זועבי",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "מקיף בועינה נג'ידאת",
		"semelMosad": 249235,
		"settlement": "בועיינה-נוג'ידאת",
		"manager": "סאמי חמודה",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "מקיף ביר אל-מכסור",
		"semelMosad": 247122,
		"settlement": "ביר אל-מכסור",
		"manager": "נואף גדיר",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "מקיף בית ג'ן חט\"ב המנוח גאלב מ",
		"semelMosad": 248179,
		"settlement": "בית ג'ן",
		"manager": "ג'לאל סעד",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "דרוזי"
	},
	{
		"schoolName": "מקיף בית חנינה",
		"semelMosad": 148197,
		"settlement": "ירושלים",
		"manager": "מגדי עוויסאת",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "מקיף בית חנינה לבנות",
		"semelMosad": 477901,
		"settlement": "ירושלים",
		"manager": "ליאנא האשם",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "מקיף בית ירח",
		"semelMosad": 261065,
		"settlement": "כנרת (קבוצה)",
		"manager": "שרון שדה",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף בענה",
		"semelMosad": 249110,
		"settlement": "בענה",
		"manager": "מוחמד ח'ליל",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "מקיף ג' א. נמר חוסין",
		"semelMosad": 248344,
		"settlement": "שפרעם",
		"manager": "ג'לאל חמאדה",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "מקיף ג' ע\"ש רוגוזין",
		"semelMosad": 770461,
		"settlement": "אשדוד",
		"manager": "גילה שמעוני",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף ג' עמית",
		"semelMosad": 771071,
		"settlement": "ראשון לציון",
		"manager": "יפה ליבה",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף גבעת פרדיס",
		"semelMosad": 360800,
		"settlement": "פוריידיס",
		"manager": "מוחמד מוחסן",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "מקיף גדידה",
		"semelMosad": 248625,
		"settlement": "ג'דיידה-מכר",
		"manager": "קייסר אנטון",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "מקיף גוונים",
		"semelMosad": 360123,
		"settlement": "עין שמר",
		"manager": "זהר בן שימול",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף גילה",
		"semelMosad": 140731,
		"settlement": "ירושלים",
		"manager": "אייל רייכמן",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף גליל מערבי",
		"semelMosad": 260489,
		"settlement": "גורנות הגליל",
		"manager": "חיה ויקטוריה פלדמן",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף ד'",
		"semelMosad": 640482,
		"settlement": "אשדוד",
		"manager": "יהודית ביטון",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף דאלית אל-כרמל",
		"semelMosad": 347054,
		"settlement": "דאלית אל-כרמל",
		"manager": "חוזמה חלבי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "דרוזי"
	},
	{
		"schoolName": "מקיף דיר חנא",
		"semelMosad": 247163,
		"settlement": "דייר חנא",
		"manager": "מנאר דגש",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "מקיף דנמרק",
		"semelMosad": 140269,
		"settlement": "ירושלים",
		"manager": "דורית יוסף",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף דרכא",
		"semelMosad": 644807,
		"settlement": "נתיבות",
		"manager": "יוסף אברג'ל",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף דרכא ג'וליס",
		"semelMosad": 247833,
		"settlement": "ג'ולס",
		"manager": "עביר עבאס",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "דרוזי"
	},
	{
		"schoolName": "מקיף דתי",
		"semelMosad": 670232,
		"settlement": "שדרות",
		"manager": "אסף בן דוד",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף דתי אזורי",
		"semelMosad": 444844,
		"settlement": "שוהם",
		"manager": "קרן פנאהלה",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף דתי אמי\"ת",
		"semelMosad": 770552,
		"settlement": "באר שבע",
		"manager": "צוריאל רובינס",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף דתי אמי\"ת קנדי",
		"semelMosad": 240036,
		"settlement": "עכו",
		"manager": "אסתר שרה צברי",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף דתי אמית ק.מלאכי",
		"semelMosad": 670414,
		"settlement": "קרית מלאכי",
		"manager": "אביעד גבאי",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף דתי אריאל",
		"semelMosad": 370254,
		"settlement": "טירת כרמל",
		"manager": "אברהם אליעזר רוק",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף דתי גרוס",
		"semelMosad": 640235,
		"settlement": "קרית גת",
		"manager": "בתיה ורד סבו",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף דתי חבד בית חנה",
		"semelMosad": 640631,
		"settlement": "קרית מלאכי",
		"manager": "יעל עזריאל",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף דתי חדש לבנות",
		"semelMosad": 644542,
		"settlement": "נתיבות",
		"manager": "אסף מרדכי ירחי",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף דתי יהודה",
		"semelMosad": 240051,
		"settlement": "עפולה",
		"manager": "יהונתן חזי",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף דתי ע\"ש לוינסון",
		"semelMosad": 340042,
		"settlement": "קרית ים",
		"manager": "שלמה קאפח",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף דתי ע\"ש רוגוזין",
		"semelMosad": 640136,
		"settlement": "אשדוד",
		"manager": "שמחה פרץ",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף דתי עזתה בנות",
		"semelMosad": 680108,
		"settlement": "זמרת",
		"manager": "דרורית וייס",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף דתי עירוני ב'",
		"semelMosad": 640078,
		"settlement": "אשקלון",
		"manager": "גדעון חננאל שינדלר",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף ה דרכא",
		"semelMosad": 644450,
		"settlement": "אשקלון",
		"manager": "דליה רחמים",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף ה' כללי",
		"semelMosad": 640672,
		"settlement": "אשדוד",
		"manager": "שלמה פייער",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף הדסים",
		"semelMosad": 440180,
		"settlement": "אבן יהודה",
		"manager": "אליהו בצלאל",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף היובל",
		"semelMosad": 541169,
		"settlement": "הרצליה",
		"manager": "עפר משה אילות",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף העמק המערבי",
		"semelMosad": 260257,
		"settlement": "יפעת",
		"manager": "אילנית ערן",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף הר וגיא",
		"semelMosad": 260307,
		"settlement": "דפנה",
		"manager": "רוית רוזנטל פלד",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף הר טוב",
		"semelMosad": 841262,
		"settlement": "צרעה",
		"manager": "דנה אלון",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף השרון עש רוטברג",
		"semelMosad": 770842,
		"settlement": "רמת השרון",
		"manager": "הדי גור",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף ו דרכא",
		"semelMosad": 641514,
		"settlement": "אשקלון",
		"manager": "תהילה גואטה",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף ו ע\"ש יגאל אלון",
		"semelMosad": 440982,
		"settlement": "ראשון לציון",
		"manager": "אורנה אולגה פרבר",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף ו'",
		"semelMosad": 644310,
		"settlement": "אשדוד",
		"manager": "רונית טאוב איסקוב",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף ולדורף הרדוף",
		"semelMosad": 240572,
		"settlement": "הרדוף",
		"manager": "דגנית גונן עמיר",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף ז'",
		"semelMosad": 644377,
		"settlement": "אשדוד",
		"manager": "עליזה סבג",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף ז' רביבים",
		"semelMosad": 441147,
		"settlement": "ראשון לציון",
		"manager": "בלה בסן",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף ח'",
		"semelMosad": 644484,
		"settlement": "אשדוד",
		"manager": "אריאלה הררי",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף חדש",
		"semelMosad": 344325,
		"settlement": "חריש",
		"manager": "ליאור לויתן",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף חדש",
		"semelMosad": 540468,
		"settlement": "ריינה",
		"manager": "סובחי עדוי",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "מקיף חדש ט'",
		"semelMosad": 644583,
		"settlement": "אשדוד",
		"manager": "הדס דמתי",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף חדש ע\"ש רבין",
		"semelMosad": 344416,
		"settlement": "קרית ים",
		"manager": "אביבה כהן",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף חדש רובע יז",
		"semelMosad": 620005,
		"settlement": "אשדוד",
		"manager": "ליבנת סיסו זיק",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף חוף הכרמל",
		"semelMosad": 770073,
		"settlement": "מעגן מיכאל",
		"manager": "אלדד שלום פורת",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף חוף השרון",
		"semelMosad": 470625,
		"settlement": "שפיים",
		"manager": "עירית על-דור שפירא",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף חקלאי כדורי",
		"semelMosad": 280065,
		"settlement": "כדורי",
		"manager": "דלית אביגד",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף חקלאי נהלל",
		"semelMosad": 280057,
		"settlement": "נהלל",
		"manager": "ולריה זילברברג",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף ט'",
		"semelMosad": 441311,
		"settlement": "ראשון לציון",
		"manager": "סופי בן ארצי",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף טומשין טובא",
		"semelMosad": 249250,
		"settlement": "טובא-זנגריה",
		"manager": "נאשד הייב",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "מקיף טומשין ערערה",
		"semelMosad": 648071,
		"settlement": "ערערה-בנגב",
		"manager": "שאדי אבו עראר",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "מקיף טשרניחובסקי",
		"semelMosad": 440115,
		"settlement": "נתניה",
		"manager": "סמדר דביר",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף י\"א",
		"semelMosad": 420489,
		"settlement": "ראשון לציון",
		"manager": "ליזה סורדו",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף י\"ג",
		"semelMosad": 480194,
		"settlement": "ראשון לציון",
		"manager": "שרון שמואל",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף י'",
		"semelMosad": 644823,
		"settlement": "אשדוד",
		"manager": "מרדכי ארבל",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף י' אבני החושן",
		"semelMosad": 441758,
		"settlement": "ראשון לציון",
		"manager": "עטרה בשן",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף יהוד",
		"semelMosad": 440289,
		"settlement": "יהוד-מונוסון",
		"manager": "חיים בדש",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף כאוכב",
		"semelMosad": 372243,
		"settlement": "כאוכב אבו אל-היג'א",
		"manager": "יוסף מנסור",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "מקיף כפר סילבר",
		"semelMosad": 680033,
		"settlement": "כפר סילבר",
		"manager": "יהודה אודי ג'רמון",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף כפר קאסם",
		"semelMosad": 800086,
		"settlement": "כפר קאסם",
		"manager": "איאד עאמר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "מקיף כרמל זבולון",
		"semelMosad": 360222,
		"settlement": "יגור",
		"manager": "דורית טלמור-שריג",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף לאורו נלך",
		"semelMosad": 541367,
		"settlement": "בני ברק",
		"manager": "גולן כהן",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף ליאו בק",
		"semelMosad": 340018,
		"settlement": "חיפה",
		"manager": "אופק מאיר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף מ\"מ קדימה",
		"semelMosad": 441576,
		"settlement": "קדימה-צורן",
		"manager": "דינה אריאס",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף מבואות עירון",
		"semelMosad": 360040,
		"settlement": "עין שמר",
		"manager": "אלן אילנה אבישר",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף מדעים",
		"semelMosad": 641621,
		"settlement": "אשקלון",
		"manager": "אסתר דעי",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף מופת בסמת",
		"semelMosad": 344101,
		"settlement": "חיפה",
		"manager": "אברהם זיגלר",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף מושריפה",
		"semelMosad": 348359,
		"settlement": "מעלה עירון",
		"manager": "עבד אלכרים אגבאריה",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "מקיף מזרחי כאבול",
		"semelMosad": 247254,
		"settlement": "כאבול",
		"manager": "עומר ריאן",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "מקיף ממ\"ד",
		"semelMosad": 440073,
		"settlement": "ראשון לציון",
		"manager": "דבורה חיה שוירמן",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף מנור",
		"semelMosad": 260471,
		"settlement": "אילון",
		"manager": "בן גור אריה",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף מעין-שחר",
		"semelMosad": 460063,
		"settlement": "עין החורש",
		"manager": "ניר נין נון",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף נופי גולן",
		"semelMosad": 240499,
		"settlement": "קצרין",
		"manager": "הילי מרום",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף נחל עירון",
		"semelMosad": 348276,
		"settlement": "בסמ\"ה",
		"manager": "עלי אגבאריה",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "מקיף סולם צור",
		"semelMosad": 260331,
		"settlement": "גשר הזיו",
		"manager": "ענת וייס",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף ע\"ש אלדד",
		"semelMosad": 441097,
		"settlement": "נתניה",
		"manager": "יהודה פרידריך",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף ע\"ש אריה מאיר",
		"semelMosad": 644732,
		"settlement": "קרית גת",
		"manager": "רחל שולמרק",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף ע\"ש גוטוירט",
		"semelMosad": 640318,
		"settlement": "שדרות",
		"manager": "יוסף יצחק אדגר דגן",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף ע\"ש ד\"ר חאלד ס",
		"semelMosad": 733121,
		"settlement": "נצרת",
		"manager": "אבראהים יונס",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "מקיף ע\"ש ז'בוטינסקי",
		"semelMosad": 480178,
		"settlement": "באר יעקב",
		"manager": "ורוניקה קופר",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף ע\"ש זיו",
		"semelMosad": 140723,
		"settlement": "ירושלים",
		"manager": "יורם חיים",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף ע\"ש יגאל אלון",
		"semelMosad": 541045,
		"settlement": "רמת השרון",
		"manager": "אנדראה קליסקי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף ע\"ש יני",
		"semelMosad": 248013,
		"settlement": "כפר יאסיף",
		"manager": "מיכאיל ח'ורי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "מקיף ע\"ש יצחק נבון",
		"semelMosad": 544528,
		"settlement": "חולון",
		"manager": "אורי בנימין לס",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף ע\"ש יצחק רבין",
		"semelMosad": 644526,
		"settlement": "באר שבע",
		"manager": "שמשון רפאל ונגרובר",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף ע\"ש יצחק רבין",
		"semelMosad": 648121,
		"settlement": "חורה",
		"manager": "סקר אבו סבית",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "מקיף ע\"ש מוסינזון",
		"semelMosad": 470138,
		"settlement": "הוד השרון",
		"manager": "רמי אגוזי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף ע\"ש מנחם בגין",
		"semelMosad": 644492,
		"settlement": "אילת",
		"manager": "אלדד חיימוביץ",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף ע\"ש ספיר",
		"semelMosad": 770768,
		"settlement": "ירוחם",
		"manager": "בת אל סבריסה אוסדון",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף ע\"ש עפו פאעור",
		"semelMosad": 248534,
		"settlement": "שעב",
		"manager": "עדנאן פאעור",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "מקיף ע\"ש קאסם גאנם",
		"semelMosad": 800136,
		"settlement": "מגאר",
		"manager": "ריזק עותמאן",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "דרוזי"
	},
	{
		"schoolName": "מקיף ע\"ש רוגוזין",
		"semelMosad": 340117,
		"settlement": "קרית אתא",
		"manager": "אורלי בן הרוש שבת",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף ע\"ש שיפמן",
		"semelMosad": 370122,
		"settlement": "טירת כרמל",
		"manager": "שושנה חדש",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף ע\"ש שלאון",
		"semelMosad": 640656,
		"settlement": "קרית גת",
		"manager": "אליעזר עוזי פרץ",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף ע. דוד טוביהו",
		"semelMosad": 644708,
		"settlement": "באר שבע",
		"manager": "עופרה פרוינד",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף עומר",
		"semelMosad": 644591,
		"settlement": "עומר",
		"manager": "ענת טריווקס",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף עין גדי",
		"semelMosad": 771105,
		"settlement": "עין גדי",
		"manager": "בן אראלי",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף עין מאהל",
		"semelMosad": 800078,
		"settlement": "עין מאהל",
		"manager": "סאמר חביבאללה",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "מקיף עינות הירדן",
		"semelMosad": 260067,
		"settlement": "עמיר",
		"manager": "רוית עליזה חסון",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף עירוני א'",
		"semelMosad": 644294,
		"settlement": "אשקלון",
		"manager": "אורית בלאו פנחס",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף עירוני גולדווטר",
		"semelMosad": 640052,
		"settlement": "אילת",
		"manager": "אפרת זקס",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף עירוני ד'",
		"semelMosad": 644559,
		"settlement": "אשקלון",
		"manager": "ליאת פרטוק",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף עירוני ח'",
		"semelMosad": 441287,
		"settlement": "ראשון לציון",
		"manager": "רויטל הירשפלד",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף עירוני ק. חיים",
		"semelMosad": 340133,
		"settlement": "חיפה",
		"manager": "חנה גרינברג",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף עמק החולה",
		"semelMosad": 260232,
		"settlement": "כפר בלום",
		"manager": "עדנה בז",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף עמקים-תבור",
		"semelMosad": 260182,
		"settlement": "מזרע",
		"manager": "דוד גורן",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף ערבי",
		"semelMosad": 278028,
		"settlement": "עכו",
		"manager": "מוחמד חג'וג'",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "מקיף ערבי",
		"semelMosad": 448100,
		"settlement": "רמלה",
		"manager": "ראבב נאסר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "מקיף עתיד אלנג'אח למדעים",
		"semelMosad": 448134,
		"settlement": "טייבה",
		"manager": "ראמי מצארוה",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "מקיף פרדיס",
		"semelMosad": 348227,
		"settlement": "פוריידיס",
		"manager": "חוסני שושה אבו דאהש",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "מקיף צפית",
		"semelMosad": 660076,
		"settlement": "כפר מנחם",
		"manager": "נטע חיה פרץ",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף קשת",
		"semelMosad": 160242,
		"settlement": "ירושלים",
		"manager": "אורית צילה סומר",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף ראמה",
		"semelMosad": 248047,
		"settlement": "ראמה",
		"manager": "נאדיה פראג'",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "מקיף רהט",
		"semelMosad": 648048,
		"settlement": "רהט",
		"manager": "חמייס אבו סיאם",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "מקיף רוגוזין",
		"semelMosad": 240192,
		"settlement": "מגדל העמק",
		"manager": "דוד אייל גריסרו",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף רוגוזין ב",
		"semelMosad": 342113,
		"settlement": "קרית אתא",
		"manager": "ענת חן",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף ריינה",
		"semelMosad": 248427,
		"settlement": "ריינה",
		"manager": "אחמד בסול",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "מקיף שאעור",
		"semelMosad": 248583,
		"settlement": "מג'ד אל-כרום",
		"manager": "נאיף פרחאת",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "מקיף שגב",
		"semelMosad": 648063,
		"settlement": "שגב-שלום",
		"manager": "מסלם אבן חמאד",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "מקיף שיזף אלמותנבי",
		"semelMosad": 378075,
		"settlement": "חיפה",
		"manager": "ראיף עומרי",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "מקיף שיטים",
		"semelMosad": 660498,
		"settlement": "ספיר",
		"manager": "דיתה יהודית בראונשטיין",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף שכונת אבו שהאב",
		"semelMosad": 442889,
		"settlement": "שפרעם",
		"manager": "טלאל יאסין",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "מקיף שער הנגב",
		"semelMosad": 660126,
		"settlement": "ניר עם",
		"manager": "יעקב חדד",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף שפיה",
		"semelMosad": 380022,
		"settlement": "מאיר שפיה",
		"manager": "שרה קשת",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף שקמה",
		"semelMosad": 660084,
		"settlement": "יד מרדכי",
		"manager": "ורד פרקש איבניר",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף שרת",
		"semelMosad": 240077,
		"settlement": "נוף הגליל",
		"manager": "שמואל ליידרמן",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף שש - שנתי",
		"semelMosad": 249193,
		"settlement": "חורפיש",
		"manager": "סדיקה פארס",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "דרוזי"
	},
	{
		"schoolName": "מקיף שש שנתי",
		"semelMosad": 344473,
		"settlement": "קרית אתא",
		"manager": "מאיר יושעי",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף שש שנתי אלטור לבנים",
		"semelMosad": 148247,
		"settlement": "ירושלים",
		"manager": "מוחמד דעיס",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "מקיף שש שנתי הרצוג",
		"semelMosad": 540864,
		"settlement": "חולון",
		"manager": "אלדד חיים קפיטלניק",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף שש שנתי סלאמה",
		"semelMosad": 244343,
		"settlement": "סלמה",
		"manager": "מחמוד סואעד",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "מקיף שש שנתי עתיד פקיעין",
		"semelMosad": 248419,
		"settlement": "פקיעין (בוקייעה)",
		"manager": "לילא עבאס",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "דרוזי"
	},
	{
		"schoolName": "מקיף שש שנתי ק. שרת",
		"semelMosad": 541110,
		"settlement": "חולון",
		"manager": "אורן קמינצקי",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף שש שנתי קוגל",
		"semelMosad": 540310,
		"settlement": "חולון",
		"manager": "ורד כהן דוד",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מקיף תרשיחא",
		"semelMosad": 248062,
		"settlement": "מעלות-תרשיחא",
		"manager": "ראנדה אג'א",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "מקצועי בית יעקב",
		"semelMosad": 170035,
		"settlement": "ירושלים",
		"manager": "חיים רוזנצווייג",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מר.חינוכי בנות מוריה",
		"semelMosad": 456293,
		"settlement": "אלעד",
		"manager": "שמעון אברהמי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מרגליות",
		"semelMosad": 734301,
		"settlement": "אשדוד",
		"manager": "שרה ברכה לף",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מרום",
		"semelMosad": 320580,
		"settlement": "כפר חסידים א'",
		"manager": "שירה מרלי",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מרום",
		"semelMosad": 541805,
		"settlement": "רמת השרון",
		"manager": "אסתר עובדיה",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מרומי שדה",
		"semelMosad": 511535,
		"settlement": "אחוזת ברק",
		"manager": "איילת וילדר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מרחב",
		"semelMosad": 442608,
		"settlement": "רעננה",
		"manager": "ערן מור",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מרחב",
		"semelMosad": 610394,
		"settlement": "שדה צבי",
		"manager": "סימה עזרא",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מרחבי אשכול",
		"semelMosad": 630046,
		"settlement": "מגן",
		"manager": "דנה אליזה פרץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מרחבים",
		"semelMosad": 482646,
		"settlement": "יבנה",
		"manager": "טליה מי רז בולליס",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מרחבים",
		"semelMosad": 311365,
		"settlement": "פרדס חנה-כרכור",
		"manager": "רווית רשף",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מרחבים",
		"semelMosad": 612325,
		"settlement": "בתי ספר של מרחבים",
		"manager": "עמליה מלי דבש יהושפט",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מרחבים -נאות שקמה",
		"semelMosad": 415620,
		"settlement": "ראשון לציון",
		"manager": "גלית בוהארון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מרחבים כפר יהושע",
		"semelMosad": 211029,
		"settlement": "כפר יהושע",
		"manager": "מרים איציק זינטי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מרים בוארדי",
		"semelMosad": 218578,
		"settlement": "אעבלין",
		"manager": "ג'והיינה מטר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "מרים ילן שטקליס",
		"semelMosad": 566893,
		"settlement": "נתניה",
		"manager": "זיו טוקנדר אלמוג",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מרכז חינוך אורט",
		"semelMosad": 270470,
		"settlement": "בית שאן",
		"manager": "יעקב רוימי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מרכז חינוך אורט",
		"semelMosad": 770388,
		"settlement": "כפר סבא",
		"manager": "ענת ישעיהו",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מרכז חינוך טכנ' נעמת",
		"semelMosad": 278184,
		"settlement": "נצרת",
		"manager": "ליליאן חכים",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "מרכז חינוך יפו",
		"semelMosad": 548032,
		"settlement": "תל אביב - יפו",
		"manager": "אהרן אלון שלום",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "מרכז חינוך לבנות",
		"semelMosad": 340554,
		"settlement": "זכרון יעקב",
		"manager": "חנה רבקה מיטלמן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מרכז חינוכי גב.יערים",
		"semelMosad": 422097,
		"settlement": "גבעת יערים",
		"manager": "אליהו פרנקל",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מרכז חינוכי וולפסון",
		"semelMosad": 581207,
		"settlement": "חולון",
		"manager": "סגלית שהרבני",
		"level": "יסודי חט\"ב ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מרכז חינוכי ע\"ש ויצמן",
		"semelMosad": 520601,
		"settlement": "רמת גן",
		"manager": "מיכל רז",
		"level": "יסודי חט\"ב ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מרכז חינוכי שניידר",
		"semelMosad": 420240,
		"settlement": "פתח תקווה",
		"manager": "משכית גילן שוחט",
		"level": "יסודי חט\"ב ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מרכז חנוך משיבי חיים",
		"semelMosad": 670323,
		"settlement": "אשדוד",
		"manager": "יצחק מאיר קמינר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מרכז חסידי ויז'ניץ",
		"semelMosad": 605600,
		"settlement": "אשדוד",
		"manager": "מאיר יעקב גרינפלד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מרכז חסידי ויז'ניץ",
		"semelMosad": 362210,
		"settlement": "בני ברק",
		"manager": "אברהם אנגלשטיין",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מרכז ישיבות וכוללים",
		"semelMosad": 160556,
		"settlement": "ירושלים",
		"manager": "חיים אלבז",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מרכז למידה תמרים",
		"semelMosad": 714972,
		"settlement": "סנסנה",
		"manager": "יפעת לוי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מרכז לתורה והוראה",
		"semelMosad": 739052,
		"settlement": "פתח תקווה",
		"manager": "זבולון מרדכי כהנא",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מרכז מוסדות נדבורנה",
		"semelMosad": 541128,
		"settlement": "בני ברק",
		"manager": "יעקב לנדאו",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מרכז נוער",
		"semelMosad": 630095,
		"settlement": "אשקלון",
		"manager": "יצחק אברגל",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מרכז נוער דתי",
		"semelMosad": 630160,
		"settlement": "באר שבע",
		"manager": "אלכס גליקסברג",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מרכז נוער טבריה",
		"semelMosad": 230821,
		"settlement": "טבריה",
		"manager": "אלה פלק",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מרכז נוער טכ' אמי\"ת",
		"semelMosad": 170407,
		"settlement": "ירושלים",
		"manager": "בת שבע סיגאווי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מרכז נוער ממ\"ד",
		"semelMosad": 431205,
		"settlement": "נתניה",
		"manager": "רותי אוור עמרני",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מרכז נוער ממלכתי",
		"semelMosad": 431189,
		"settlement": "נתניה",
		"manager": "בנימין שי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מרכז נוער ע\"ש משה קו",
		"semelMosad": 630764,
		"settlement": "אשדוד",
		"manager": "בנימין זיזו",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מרכז נער ע\"ש טננבאום",
		"semelMosad": 571125,
		"settlement": "הרצליה",
		"manager": "רון פולק",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מרכז עתיד לוגי טק",
		"semelMosad": 740894,
		"settlement": "באר יעקב",
		"manager": "אולג סידלניק",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מרכז רפואי רעות",
		"semelMosad": 503631,
		"settlement": "תל אביב - יפו",
		"manager": "אילונה פינטו",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מרכז שיקום נוער ת\"א",
		"semelMosad": 520858,
		"settlement": "רחובות",
		"manager": "הילי יהלום",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מרכז תורני בנות ציון",
		"semelMosad": 116129,
		"settlement": "ירושלים",
		"manager": "יוסף נתניאן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מרשאן שפרעם",
		"semelMosad": 218057,
		"settlement": "שפרעם",
		"manager": "יוסף חניפס",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "מש\"י מרכז שיקום ילדי",
		"semelMosad": 759225,
		"settlement": "בני ברק",
		"manager": "רות ברכה מסלטי",
		"level": "יסודי בלבד",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מש\"י מרכז שקום ילדים",
		"semelMosad": 195958,
		"settlement": "ירושלים",
		"manager": "שולמית פרבשטיין",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "משאבים",
		"semelMosad": 610808,
		"settlement": "משאבי שדה",
		"manager": "חן גולדמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "משאת מלך",
		"semelMosad": 631556,
		"settlement": "אלעד",
		"manager": "נפתלי-יעקב בן זאב",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "משה ארנס",
		"semelMosad": 444232,
		"settlement": "פתח תקווה",
		"manager": "לאה אוברקוביץ",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "משה הס",
		"semelMosad": 510529,
		"settlement": "חולון",
		"manager": "אביטל כהן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "משואה ע\"ש יוסף שלום",
		"semelMosad": 611038,
		"settlement": "קרית גת",
		"manager": "דבורה גימני בדש",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "משואת נריה",
		"semelMosad": 160697,
		"settlement": "מודיעין-מכבים-רעות",
		"manager": "רות סירי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "משותף עמק חרוד",
		"semelMosad": 211755,
		"settlement": "עין חרוד (איחוד)",
		"manager": "הילה שוב גולן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "משותף רימון מסילות",
		"semelMosad": 211326,
		"settlement": "מסילות",
		"manager": "גלית דגן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "משיבים פתח תקווה",
		"semelMosad": 455188,
		"settlement": "פתח תקווה",
		"manager": "יצחק ירמיהו אוירבך",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "משיבת נפש",
		"semelMosad": 630897,
		"settlement": "הוד השרון",
		"manager": "משה שמואל שטרן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "משך חכמה",
		"semelMosad": 757690,
		"settlement": "ירושלים",
		"manager": "אהרון נתנאל אלמלך",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "משכן התורה",
		"semelMosad": 160127,
		"settlement": "ירושלים",
		"manager": "דניאל ביטון",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "משכן התלמוד",
		"semelMosad": 560490,
		"settlement": "מודיעין עילית",
		"manager": "יצחק אברג'ל",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "משכן התלמוד",
		"semelMosad": 660050,
		"settlement": "אופקים",
		"manager": "דוד שק",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "משכן חיים",
		"semelMosad": 284307,
		"settlement": "ירושלים",
		"manager": "יחזקאל סיטון",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "משכנות אברהם",
		"semelMosad": 540047,
		"settlement": "רמת גן",
		"manager": "נחוניה חיים ניסן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "משכנות התלמוד",
		"semelMosad": 747576,
		"settlement": "פתח תקווה",
		"manager": "זבולון קדוש",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "משכנות יונה",
		"semelMosad": 761759,
		"settlement": "ירושלים",
		"manager": "משה פרקוף",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "משכנות יעקב",
		"semelMosad": 360750,
		"settlement": "בית שמש",
		"manager": "יצחק שורץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "משכנות יעקב",
		"semelMosad": 714147,
		"settlement": "מודיעין עילית",
		"manager": "פדאל טימסטיט",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "משכנותיך ישראך",
		"semelMosad": 741280,
		"settlement": "ירושלים",
		"manager": "חוה שטרן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "משכנותיך ישראל",
		"semelMosad": 112458,
		"settlement": "בית שמש",
		"manager": "אליצור עזרן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "משלב דרור",
		"semelMosad": 641456,
		"settlement": "צור הדסה",
		"manager": "ענבל פיקהולץ שילה",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "משמרת מאיר",
		"semelMosad": 131219,
		"settlement": "ביתר עילית",
		"manager": "טובה גיטל אייזנבך",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "משמרת מאיר",
		"semelMosad": 442699,
		"settlement": "ביתר עילית",
		"manager": "אחינועם קפלן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "משנת חיים",
		"semelMosad": 442665,
		"settlement": "ירושלים",
		"manager": "שמואל יהודה פריליך",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "משנתו שלמה",
		"semelMosad": 632216,
		"settlement": "ביתר עילית",
		"manager": "שמעון יוחנן מינצברג",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "משעול",
		"semelMosad": 723502,
		"settlement": "כפר חסידים א'",
		"manager": "זכית ריף",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "משעולים",
		"semelMosad": 384024,
		"settlement": "עתלית",
		"manager": "סיגל אגוזי איתן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "משפחה קדושה-נצרת",
		"semelMosad": 228122,
		"settlement": "נצרת",
		"manager": "קארלו פונדריני",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "מתיבתא",
		"semelMosad": 741165,
		"settlement": "זכרון יעקב",
		"manager": "נתן שאול שמול",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מתיבתא דרבי יוחנן מע",
		"semelMosad": 736975,
		"settlement": "בני ברק",
		"manager": "יוסף ירמיהו דנציגר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "מתתיהו",
		"semelMosad": 110726,
		"settlement": "צלפון",
		"manager": "אילנית כהן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נאות",
		"semelMosad": 613638,
		"settlement": "אשקלון",
		"manager": "דניאלה בלהה תורגמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נאות אברהם",
		"semelMosad": 615880,
		"settlement": "באר שבע",
		"manager": "אליהו חוטה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נאות אסתר בנות",
		"semelMosad": 613604,
		"settlement": "אשדוד",
		"manager": "גילה שמחה אוחנה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נאות אשלים אנתרופול'",
		"semelMosad": 415927,
		"settlement": "ראשון לציון",
		"manager": "הדר דורות ארנון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נאות לון",
		"semelMosad": 614636,
		"settlement": "באר שבע",
		"manager": "הדס פרץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נאות מרגלית",
		"semelMosad": 415208,
		"settlement": "פתח תקווה",
		"manager": "רועי אביצור",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נאות שמחה",
		"semelMosad": 640821,
		"settlement": "אשדוד",
		"manager": "גילה שמחה אוחנה",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נארת",
		"semelMosad": 218982,
		"settlement": "ריחאניה",
		"manager": "מאג'ד בדר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "צרקסי"
	},
	{
		"schoolName": "נגב סיני",
		"semelMosad": 611855,
		"settlement": "משאבי שדה",
		"manager": "רינת רבקה גובי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נגבה",
		"semelMosad": 413336,
		"settlement": "ראשון לציון",
		"manager": "ניצה קלדס ווינסטון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נהג כצאן יוסף",
		"semelMosad": 172395,
		"settlement": "טלמון",
		"manager": "יוסף אוריאל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נהורא דהורייתא",
		"semelMosad": 361550,
		"settlement": "אלעד",
		"manager": "דוד אגאי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נהלל אזורי",
		"semelMosad": 211458,
		"settlement": "נהלל",
		"manager": "אשרת טל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נהרדעא",
		"semelMosad": 288134,
		"settlement": "גני תקווה",
		"manager": "יעקב קושלנד",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נודע בשערים",
		"semelMosad": 174623,
		"settlement": "בית שמש",
		"manager": "שלמה גולדמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נוה",
		"semelMosad": 582502,
		"settlement": "נוקדים",
		"manager": "יפית ארזי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נוה דקלים",
		"semelMosad": 412056,
		"settlement": "ראשון לציון",
		"manager": "ברי דרפל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נוה הדסה",
		"semelMosad": 444034,
		"settlement": "תל יצחק",
		"manager": "אביחי יפת",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נוה זמר",
		"semelMosad": 482539,
		"settlement": "רעננה",
		"manager": "סמדר קיסילבסקי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נוה חוה",
		"semelMosad": 240598,
		"settlement": "כרמיאל",
		"manager": "חיה שרה דיאמנט",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נוה חוף",
		"semelMosad": 413716,
		"settlement": "ראשון לציון",
		"manager": "קלרה משומר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נוה חנה",
		"semelMosad": 140913,
		"settlement": "אלון שבות",
		"manager": "רונן בן דוד",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נוה מדבר",
		"semelMosad": 610642,
		"settlement": "חצרים",
		"manager": "יהודה אבי עד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נוה משה רשב\"י",
		"semelMosad": 417139,
		"settlement": "הוד השרון",
		"manager": "הדס גדעוני",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נוה נאמן",
		"semelMosad": 410464,
		"settlement": "הוד השרון",
		"manager": "זמירה בר-עם",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נוה עוז",
		"semelMosad": 411637,
		"settlement": "פתח תקווה",
		"manager": "קרן צור הרוש",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נוה עוז -שקמה-חדש",
		"semelMosad": 416024,
		"settlement": "ראשון לציון",
		"manager": "ורד לזובסקי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נוה עמיאל",
		"semelMosad": 280016,
		"settlement": "שדה יעקב",
		"manager": "שלומית ברק",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נוה עציון בנות",
		"semelMosad": 111898,
		"settlement": "ירושלים",
		"manager": "שירה בן ישראל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נוה צאלים",
		"semelMosad": 420174,
		"settlement": "רמות השבים",
		"manager": "יפית שם-טוב אבס",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נוה צוף",
		"semelMosad": 111963,
		"settlement": "נוה צוף",
		"manager": "תמר סלע",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נוה רם",
		"semelMosad": 313569,
		"settlement": "רכסים",
		"manager": "לימור אהובה ערמון",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נוה שלום-ואחד אל-סלם",
		"semelMosad": 124032,
		"settlement": "נווה שלום",
		"manager": "נעמה אבו דאלו",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נווה אילן",
		"semelMosad": 614396,
		"settlement": "אשקלון",
		"manager": "מוטי עטייה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נווה אריאל",
		"semelMosad": 756320,
		"settlement": "אריאל",
		"manager": "עמירם סבן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נווה אריאל",
		"semelMosad": 414292,
		"settlement": "אריאל",
		"manager": "אפרת שרשבסקי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נווה במדבר",
		"semelMosad": 613786,
		"settlement": "באר שבע",
		"manager": "גלית בן נעים",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נווה דקלים",
		"semelMosad": 614214,
		"settlement": "אשקלון",
		"manager": "סברינה סבריסה בן שבת",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נווה דקלים",
		"semelMosad": 618587,
		"settlement": "דריג'את",
		"manager": "סאמי אלאטרש",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "נווה חיה",
		"semelMosad": 615153,
		"settlement": "נתיבות",
		"manager": "נחמה מחפוד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נווה מדבר ניצנה",
		"semelMosad": 718189,
		"settlement": "ניצנה (קהילת חינוך)",
		"manager": "אימן אגבאריה",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "נווה מנחם ע\"ש מ.בגין",
		"semelMosad": 614545,
		"settlement": "באר שבע",
		"manager": "רחל פסו",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נווה עמרם",
		"semelMosad": 612770,
		"settlement": "דימונה",
		"manager": "רחל אוזן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נווה ציון - ורדימון",
		"semelMosad": 612523,
		"settlement": "אשקלון",
		"manager": "שריה דחוח הלוי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נווה שלום",
		"semelMosad": 614719,
		"settlement": "באר שבע",
		"manager": "אורית זוזוט",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נווה-מדבר",
		"semelMosad": 618603,
		"settlement": "אבו ג'ווייעד (שבט)",
		"manager": "חאתם אבו קוידר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "נוות יעקב",
		"semelMosad": 132290,
		"settlement": "גני מודיעין",
		"manager": "רבקה המר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נוות יעקב",
		"semelMosad": 756544,
		"settlement": "רכסים",
		"manager": "מרים סבג",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נוות ישראל",
		"semelMosad": 631242,
		"settlement": "מעלה אדומים",
		"manager": "אתיה אסתר זאב",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נוות ישראל",
		"semelMosad": 141119,
		"settlement": "ירושלים",
		"manager": "דרורית בן נתן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נוטרדאם",
		"semelMosad": 248823,
		"settlement": "מעיליא",
		"manager": "איאד קסיס",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "נועם",
		"semelMosad": 312124,
		"settlement": "פרדס חנה-כרכור",
		"manager": "רחמים ביטון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נועם",
		"semelMosad": 613125,
		"settlement": "אשדוד",
		"manager": "אמנון בריח",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נועם - נריה",
		"semelMosad": 214429,
		"settlement": "בית שאן",
		"manager": "בצלאל סימחון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נועם אורות הנגב",
		"semelMosad": 614586,
		"settlement": "נתיבות",
		"manager": "שמעון שי גבאי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נועם אליהו",
		"semelMosad": 613398,
		"settlement": "נתיבות",
		"manager": "ליאת דורי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נועם בנות פסגת זאב",
		"semelMosad": 115592,
		"settlement": "ירושלים",
		"manager": "צביה הרנץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נועם בנות ק.משה",
		"semelMosad": 111393,
		"settlement": "ירושלים",
		"manager": "חוה רבקה קורמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נועם בנות רמות",
		"semelMosad": 113001,
		"settlement": "ירושלים",
		"manager": "יונת דבורה טוקר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נועם בנים פסגת זאב",
		"semelMosad": 113597,
		"settlement": "ירושלים",
		"manager": "ארז הימלפרב",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נועם בנים ק.משה",
		"semelMosad": 112003,
		"settlement": "ירושלים",
		"manager": "אורית דהן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נועם בנים רמות",
		"semelMosad": 113563,
		"settlement": "ירושלים",
		"manager": "שירה יזדי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נועם המאירי בנות",
		"semelMosad": 411041,
		"settlement": "לוד",
		"manager": "שולמית בלפר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נועם המאירי בנים",
		"semelMosad": 482893,
		"settlement": "לוד",
		"manager": "מגל יוסף עם שלם",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נועם חיים",
		"semelMosad": 613083,
		"settlement": "דימונה",
		"manager": "יניב חסאן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נועם ישראל",
		"semelMosad": 210799,
		"settlement": "טבריה",
		"manager": "לימור גלולה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נועם נצרים",
		"semelMosad": 615062,
		"settlement": "בני נצרים",
		"manager": "טליה בלומה וינגאט",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נועם נריה בנות",
		"semelMosad": 482901,
		"settlement": "לוד",
		"manager": "אפרת (חוה) עמנואל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נועם נריה בנים",
		"semelMosad": 417097,
		"settlement": "לוד",
		"manager": "עופרה יהודה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נועם תיכון טכנולגי",
		"semelMosad": 761205,
		"settlement": "ביתר עילית",
		"manager": "פנינה שרה לשם",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נוף אדום",
		"semelMosad": 612135,
		"settlement": "יטבתה",
		"manager": "שרי הריס",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נוף גליל",
		"semelMosad": 212001,
		"settlement": "פרוד",
		"manager": "דניאלה חזן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נוף הגליל",
		"semelMosad": 744110,
		"settlement": "נוף הגליל",
		"manager": "מנחם מענדל גינזבורג",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נוף הרים",
		"semelMosad": 616383,
		"settlement": "מודיעין-מכבים-רעות",
		"manager": "ליאת הופמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נוף הרים",
		"semelMosad": 120014,
		"settlement": "איתנים",
		"manager": "מעין מוראד",
		"level": "יסודי חט\"ב ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נוף השדות",
		"semelMosad": 630103,
		"settlement": "נגבה",
		"manager": "אינגה אדרי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נוף ילדות",
		"semelMosad": 442459,
		"settlement": "תל מונד",
		"manager": "שירה סמדר בכר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נוף ילדות",
		"semelMosad": 510974,
		"settlement": "מקווה ישראל",
		"manager": "ירדן כהנא",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נוף ים",
		"semelMosad": 510438,
		"settlement": "הרצליה",
		"manager": "מיכל גוטליב פרידור",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נוף ים",
		"semelMosad": 613596,
		"settlement": "אשדוד",
		"manager": "עליזה יפרח",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נוף ים א'",
		"semelMosad": 612473,
		"settlement": "אשקלון",
		"manager": "ליאור (מאיר) שורץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נוף ים ב'",
		"semelMosad": 613406,
		"settlement": "אשקלון",
		"manager": "שרית מתתיהו",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נופי ארבל-גנוסר",
		"semelMosad": 210427,
		"settlement": "גינוסר",
		"manager": "רונית צ'ובינסקי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נופי הבשור",
		"semelMosad": 660324,
		"settlement": "מגן",
		"manager": "דוד דואני-קוטלר",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נופי החורש",
		"semelMosad": 416503,
		"settlement": "אלפי מנשה",
		"manager": "דבורה ענבי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נופי ים",
		"semelMosad": 514950,
		"settlement": "תל אביב - יפו",
		"manager": "כרמית ברגמן סמוראי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נופי מולדת",
		"semelMosad": 482232,
		"settlement": "גן יבנה",
		"manager": "דלית כהן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נופים",
		"semelMosad": 615013,
		"settlement": "כנות",
		"manager": "שגית ציביה איפרגן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נופים",
		"semelMosad": 313668,
		"settlement": "חיפה",
		"manager": "אסתר גרינבוך",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נופים ע\"ש דוד רמז",
		"semelMosad": 511188,
		"settlement": "תל אביב - יפו",
		"manager": "שמחה סמדר מורס",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נופית",
		"semelMosad": 312652,
		"settlement": "נופית",
		"manager": "מושית טוני זיו",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נור אלהודא אלמקדסיה",
		"semelMosad": 419259,
		"settlement": "ירושלים",
		"manager": "מהא אשתיה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "נות ישראל",
		"semelMosad": 112169,
		"settlement": "ירושלים",
		"manager": "יוכבד חזוני",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נזירות הכרמל חיפה",
		"semelMosad": 318618,
		"settlement": "חיפה",
		"manager": "ויויאן נח'לה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "נזירות הכרמלית",
		"semelMosad": 317024,
		"settlement": "חיפה",
		"manager": "ראגידה דאוד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "נזירות המושיע",
		"semelMosad": 247080,
		"settlement": "נצרת",
		"manager": "עאוני בטחיש",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "נזירות הסיליזיאן",
		"semelMosad": 214262,
		"settlement": "נצרת",
		"manager": "ריתא חדאד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "נזירות נצרת",
		"semelMosad": 347021,
		"settlement": "חיפה",
		"manager": "באסם ג'מאל",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "נזירות נצרת",
		"semelMosad": 217257,
		"settlement": "שפרעם",
		"manager": "ראמי סרור",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "נזירות סנט גוזף",
		"semelMosad": 247056,
		"settlement": "נצרת",
		"manager": "מיסון חנא",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "נזירות פרנציסקניות",
		"semelMosad": 247031,
		"settlement": "נצרת",
		"manager": "רוזית עראף",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "נזירות פרנציסקניות",
		"semelMosad": 217034,
		"settlement": "כפר כנא",
		"manager": "ריניה מוסא",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "נזירות פרנציסקניות",
		"semelMosad": 217083,
		"settlement": "נצרת",
		"manager": "מאג'דה עילוטי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "נזר התלמוד",
		"semelMosad": 440644,
		"settlement": "ביתר עילית",
		"manager": "יעקב כהן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נזר שראל",
		"semelMosad": 541094,
		"settlement": "ראשון לציון",
		"manager": "מנחם מאיר אופנהיים",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נחל האמונה",
		"semelMosad": 343954,
		"settlement": "מודיעין עילית",
		"manager": "חיים ברוך טרנובסקי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נחלי התורה",
		"semelMosad": 732081,
		"settlement": "ירושלים",
		"manager": "נפתלי שיף",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נחליאל",
		"semelMosad": 214031,
		"settlement": "מגדל העמק",
		"manager": "אריאלה מאדר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נחלים",
		"semelMosad": 612655,
		"settlement": "בתי ספר של מרחבים",
		"manager": "נירית אליהו",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נחלת אפרים",
		"semelMosad": 756353,
		"settlement": "נתיבות",
		"manager": "נתנאל חיים מדר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נחלת אפרים",
		"semelMosad": 640854,
		"settlement": "דימונה",
		"manager": "יעקב חמד",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נחלת בנימין בנים",
		"semelMosad": 111708,
		"settlement": "עופרה",
		"manager": "שניר הראל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נחלת יעקב",
		"semelMosad": 430215,
		"settlement": "ביתר עילית",
		"manager": "שבח שוורץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נחלת ירושלים",
		"semelMosad": 522177,
		"settlement": "ירושלים",
		"manager": "חיה צינא קוגלמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נחלת ירושלים",
		"semelMosad": 522235,
		"settlement": "ירושלים",
		"manager": "אסתר רוזנפלד רייכמאן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נחלת ישראל",
		"semelMosad": 499806,
		"settlement": "מגדל העמק",
		"manager": "מלכיאל נוריאל טולידאנו",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נחלת שלמה",
		"semelMosad": 730473,
		"settlement": "מודיעין עילית",
		"manager": "טוביה גרומן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נחלת שמואל",
		"semelMosad": 441956,
		"settlement": "נתניה",
		"manager": "חנניה גבאי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נחלת שמעון",
		"semelMosad": 410761,
		"settlement": "מודיעין עילית",
		"manager": "שלמה אהרמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נחשון למדעים טכנולוגיה ואומנות",
		"semelMosad": 444737,
		"settlement": "מרכז אזורי שוהם",
		"manager": "אסתר יצחקי",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נחשוני החולה יסוד המ",
		"semelMosad": 212753,
		"settlement": "יסוד המעלה",
		"manager": "יונית אסתר שנקר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נחשונים",
		"semelMosad": 512566,
		"settlement": "בת ים",
		"manager": "אנה מדניק רטנר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נטופה",
		"semelMosad": 214122,
		"settlement": "נוף הגליל",
		"manager": "מרים הלנה כהן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נטע ארז",
		"semelMosad": 615898,
		"settlement": "באר שבע",
		"manager": "איתי אבירם",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נטעי אורה",
		"semelMosad": 641282,
		"settlement": "פתח תקווה",
		"manager": "מאיר אלפא",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נטעי תורה",
		"semelMosad": 499970,
		"settlement": "ירושלים",
		"manager": "שלמה מלול",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נטעים",
		"semelMosad": 510792,
		"settlement": "רמת גן",
		"manager": "מרב שרייבר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נטעים",
		"semelMosad": 384065,
		"settlement": "עין שמר",
		"manager": "אהרון פוגל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נטעים",
		"semelMosad": 616748,
		"settlement": "בית העמק",
		"manager": "מיה רשתי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נטעים מיתרים",
		"semelMosad": 610311,
		"settlement": "באר שבע",
		"manager": "ליאת מימון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נטעים-הבניית התנהגות",
		"semelMosad": 420307,
		"settlement": "לוד",
		"manager": "אודליה שוחט",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ניב לחרשים",
		"semelMosad": 520221,
		"settlement": "תל אביב - יפו",
		"manager": "גלנה גוליק",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ניו בית עדנאן",
		"semelMosad": 347153,
		"settlement": "ירכא",
		"manager": "רים הרדל נאסר",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "דרוזי"
	},
	{
		"schoolName": "ניל\"י",
		"semelMosad": 112193,
		"settlement": "ניל\"י",
		"manager": "ליאת לוי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נילי",
		"semelMosad": 310177,
		"settlement": "זכרון יעקב",
		"manager": "רחלי מאירי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ניסוי ארגנטינה",
		"semelMosad": 645424,
		"settlement": "ירושלים",
		"manager": "איתי בנוביץ",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ניסויי על איזורי תפן",
		"semelMosad": 349928,
		"settlement": "כפר ורדים",
		"manager": "איתי דינר",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ניצן",
		"semelMosad": 610899,
		"settlement": "ניצנים",
		"manager": "איזבל שרון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ניצני המדע",
		"semelMosad": 430496,
		"settlement": "רחובות",
		"manager": "דורית נדלר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ניצני הנגב",
		"semelMosad": 610436,
		"settlement": "בית קמה",
		"manager": "אסנת קריצ'מן מוריץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ניצני זבולון",
		"semelMosad": 311035,
		"settlement": "כפר המכבי",
		"manager": "אורלי פרש-אזולאי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ניצני עוז",
		"semelMosad": 140848,
		"settlement": "בית שמש",
		"manager": "אליצור עזרן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ניצני קטיף",
		"semelMosad": 615120,
		"settlement": "ניצן",
		"manager": "אושרה שירה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ניצני רעות",
		"semelMosad": 357467,
		"settlement": "עין שמר",
		"manager": "לילך הוכמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ניצנים",
		"semelMosad": 416420,
		"settlement": "נס ציונה",
		"manager": "לימור שמעונוב",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ניצנים",
		"semelMosad": 142398,
		"settlement": "מודיעין-מכבים-רעות",
		"manager": "אורלי כהן אבידן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ניצנים",
		"semelMosad": 512442,
		"settlement": "תל אביב - יפו",
		"manager": "מירב אליקים צלניקר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ניצנים",
		"semelMosad": 510800,
		"settlement": "רמת גן",
		"manager": "אהוד גלעד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ניצנים",
		"semelMosad": 482935,
		"settlement": "אליכין",
		"manager": "נעמה הר נוי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ניצנים",
		"semelMosad": 414359,
		"settlement": "ראשון לציון",
		"manager": "גילת הר פז",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ניצנים",
		"semelMosad": 310250,
		"settlement": "חדרה",
		"manager": "סיגלית שלימק",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ניצנים",
		"semelMosad": 514117,
		"settlement": "חולון",
		"manager": "מיכאל בן-מיכאל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ניצנים",
		"semelMosad": 220442,
		"settlement": "עכו",
		"manager": "יגאל מרדכי פייגנבאום",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ניצנים",
		"semelMosad": 415950,
		"settlement": "תל מונד",
		"manager": "שמחה פרץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ניצנים",
		"semelMosad": 421081,
		"settlement": "גדרה",
		"manager": "עליזה בז",
		"level": "חט\"ב + עליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ניצנים כרמיאל",
		"semelMosad": 213413,
		"settlement": "כרמיאל",
		"manager": "הדס פלדמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ניר",
		"semelMosad": 510636,
		"settlement": "קרית אונו",
		"manager": "שרונה באקבה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ניר",
		"semelMosad": 620526,
		"settlement": "אשדוד",
		"manager": "מרים דונדיש",
		"level": "יסודי חט\"ב ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ניר עציון",
		"semelMosad": 311209,
		"settlement": "ניר עציון",
		"manager": "חדוה זילברברג בן ישר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נירים",
		"semelMosad": 310672,
		"settlement": "חיפה",
		"manager": "הילה מימון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נירים",
		"semelMosad": 240820,
		"settlement": "בוסתן הגליל",
		"manager": "יוסף חנניה",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נירים חנ\"מ",
		"semelMosad": 420463,
		"settlement": "מעלה אדומים",
		"manager": "ענת הודיה אלבז אברישומי",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נעורים",
		"semelMosad": 312520,
		"settlement": "קרית ביאליק",
		"manager": "מירי בן שושן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נעורים",
		"semelMosad": 144568,
		"settlement": "ירושלים",
		"manager": "מוריה טובה כובשי",
		"level": "חט\"ב + עליונה",
		"special": "מיוחד",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נעים הליכות",
		"semelMosad": 611442,
		"settlement": "עפולה",
		"manager": "אביאל ביטון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נעם התורה",
		"semelMosad": 513069,
		"settlement": "בני ברק",
		"manager": "יצחק ויזל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נעם מנחם",
		"semelMosad": 189894,
		"settlement": "ביתר עילית",
		"manager": "אליעזר סופר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נער ישראל",
		"semelMosad": 616169,
		"settlement": "ירושלים",
		"manager": "בועז אהרון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נפלאות",
		"semelMosad": 482513,
		"settlement": "כפר יונה",
		"manager": "יפית גויטע",
		"level": "חט\"ב + עליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נפתלי -יבנאל",
		"semelMosad": 210807,
		"settlement": "יבנאל",
		"manager": "קרן אריש",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נצח ישראל",
		"semelMosad": 460717,
		"settlement": "בית שמש",
		"manager": "מיכאל דוב גרליץ",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נצח ישראל",
		"semelMosad": 611061,
		"settlement": "קרית מלאכי",
		"manager": "רוית גדנקן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נצח ישראל",
		"semelMosad": 749937,
		"settlement": "בית שמש",
		"manager": "שאול רפאל מילר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נצח ישראל בנות",
		"semelMosad": 759050,
		"settlement": "בית שמש",
		"manager": "אילה אסתר כי טוב",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נצרת אלחורש א'",
		"semelMosad": 219535,
		"settlement": "נצרת",
		"manager": "ראידה שהאב",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "נצרת אלכרום",
		"semelMosad": 218669,
		"settlement": "נצרת",
		"manager": "חסאן ח'טיב",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "נצרת ביר אלאמיר",
		"semelMosad": 219329,
		"settlement": "נצרת",
		"manager": "וסים נסרה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "נר הגלבוע",
		"semelMosad": 213447,
		"settlement": "גן נר",
		"manager": "אביבית רויטל כהן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נר לרחל ובניה ע\"ה",
		"semelMosad": 115824,
		"settlement": "ירושלים",
		"manager": "גבריאל יוסף זילברמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נריה",
		"semelMosad": 470377,
		"settlement": "יבנה",
		"manager": "מרדכי אהרן",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נרקיסים",
		"semelMosad": 311514,
		"settlement": "קרית טבעון",
		"manager": "שרונה הראל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נשמת התורה",
		"semelMosad": 749887,
		"settlement": "ירושלים",
		"manager": "בנימין מאיר קורי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נתיב",
		"semelMosad": 520569,
		"settlement": "הרצליה",
		"manager": "רונית יפת",
		"level": "יסודי בלבד",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נתיב",
		"semelMosad": 214080,
		"settlement": "הושעיה",
		"manager": "רחל שמיר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נתיב",
		"semelMosad": 520635,
		"settlement": "תל אביב - יפו",
		"manager": "עופרי לוי חונוביץ",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נתיב התורה",
		"semelMosad": 745471,
		"settlement": "בית שמש",
		"manager": "ישראל מדר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נתיב זבולון",
		"semelMosad": 115998,
		"settlement": "מודיעין-מכבים-רעות",
		"manager": "דוד אפטר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נתיב מאיר-מעלות",
		"semelMosad": 212811,
		"settlement": "מעלות-תרשיחא",
		"manager": "סיגלית אטיאס",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נתיבות דעת",
		"semelMosad": 721175,
		"settlement": "בית שמש",
		"manager": "שרה טיישר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נתיבות דרור",
		"semelMosad": 344598,
		"settlement": "אור עקיבא",
		"manager": "רמי כדר",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נתיבות הלימוד",
		"semelMosad": 729418,
		"settlement": "ירושלים",
		"manager": "ישראל מנחם קוריץ",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נתיבות התורה",
		"semelMosad": 198457,
		"settlement": "מודיעין עילית",
		"manager": "שמואל בנימין טולנפלד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נתיבות התורה",
		"semelMosad": 723973,
		"settlement": "מודיעין עילית",
		"manager": "שמואל בנימין טולנפלד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נתיבות חכמה",
		"semelMosad": 712943,
		"settlement": "מודיעין עילית",
		"manager": "זהבה הגר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נתיבות חכמה",
		"semelMosad": 184085,
		"settlement": "ביתר עילית",
		"manager": "שרה הלפרין",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נתיבות רבקה",
		"semelMosad": 514505,
		"settlement": "בת ים",
		"manager": "רחל רביבו",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נתיבות רבקה",
		"semelMosad": 544478,
		"settlement": "בת ים",
		"manager": "אלישבע נטף",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נתיבות-יורם",
		"semelMosad": 610329,
		"settlement": "באר שבע",
		"manager": "נורית צרפתי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נתיבי אור (מעורב)",
		"semelMosad": 614651,
		"settlement": "נתיבות",
		"manager": "יעקב ארוש",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נתיבי נועם מגן אברהם",
		"semelMosad": 338624,
		"settlement": "ירושלים",
		"manager": "נחמן דובדבני",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נתיבי עם",
		"semelMosad": 613430,
		"settlement": "באר שבע",
		"manager": "אורלי קוקה אשרי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נתן אלתרמן",
		"semelMosad": 566901,
		"settlement": "נתניה",
		"manager": "לימור חנני",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "נתן יונתן",
		"semelMosad": 417212,
		"settlement": "פתח תקווה",
		"manager": "אביבה גולן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ס ד ס יוניקתולי נצרת",
		"semelMosad": 217059,
		"settlement": "נצרת",
		"manager": "נאדיה אבו שקארה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "סאדברי ירושלים",
		"semelMosad": 347757,
		"settlement": "ירושלים",
		"manager": "שחר יצחק גרוסמן",
		"level": "יסודי ועליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "סאדברי-עמק האלה",
		"semelMosad": 733527,
		"settlement": "נתיב הל\"ה",
		"manager": "אסתר הילה שצופק",
		"level": "יסודי ועליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "סאחורה מכין בנות",
		"semelMosad": 178178,
		"settlement": "ירושלים",
		"manager": "אמאל אשקיראת",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "סאלד",
		"semelMosad": 110130,
		"settlement": "ירושלים",
		"manager": "עמיעד יעקב מלצר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "סאלד",
		"semelMosad": 420067,
		"settlement": "כפר סבא",
		"manager": "ורד בן דרור",
		"level": "יסודי חט\"ב ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "סאלח סמור",
		"semelMosad": 219733,
		"settlement": "שפרעם",
		"manager": "רוזה דעקה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "סאלם",
		"semelMosad": 318246,
		"settlement": "מעלה עירון",
		"manager": "עטאללה אגבאריה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "סאסא-נוף הרים",
		"semelMosad": 211631,
		"settlement": "סאסא",
		"manager": "סיגלית פרץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "סביון",
		"semelMosad": 520536,
		"settlement": "רמת גן",
		"manager": "סיגל-שחף גרדה",
		"level": "יסודי בלבד",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "סביון",
		"semelMosad": 482448,
		"settlement": "באר יעקב",
		"manager": "רויטל קורן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "סביונים",
		"semelMosad": 416388,
		"settlement": "נס ציונה",
		"manager": "סמדר בייז",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "סביונים",
		"semelMosad": 513572,
		"settlement": "אור יהודה",
		"manager": "לילי חושן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "סגולה מד",
		"semelMosad": 512632,
		"settlement": "בני ברק",
		"manager": "עמוס מימון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "סדיגורא",
		"semelMosad": 112524,
		"settlement": "ירושלים",
		"manager": "רפאל אלבוים",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "סדנת שילוב יובלים",
		"semelMosad": 759134,
		"settlement": "אלון שבות",
		"manager": "נועה הניה מנדלבוים",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "סדנת שילוב-גבעות",
		"semelMosad": 168054,
		"settlement": "בת עין",
		"manager": "אסף יעקב טבול",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "סואחרה י",
		"semelMosad": 118356,
		"settlement": "ירושלים",
		"manager": "אוסאמה סרחי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "סואחרה לבנות",
		"semelMosad": 118166,
		"settlement": "ירושלים",
		"manager": "רנא שאער מנסור",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "סוגה בשושנים",
		"semelMosad": 748897,
		"settlement": "טבריה",
		"manager": "הדר כהן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "סווא",
		"semelMosad": 228064,
		"settlement": "נצרת",
		"manager": "ג'ומאן טנוס נג'אר",
		"level": "יסודי חט\"ב ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "סווא בית ספר לחינוך מיוחד",
		"semelMosad": 228379,
		"settlement": "אבו סנאן",
		"manager": "אדיבה מוסא",
		"level": "יסודי חט\"ב ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "דרוזי"
	},
	{
		"schoolName": "סוואחרה בנים",
		"semelMosad": 518704,
		"settlement": "ירושלים",
		"manager": "מהא סלמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "סוכת דוד",
		"semelMosad": 226340,
		"settlement": "בית שמש",
		"manager": "יאשיהו רצאבי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "סוכת דוד",
		"semelMosad": 112714,
		"settlement": "ירושלים",
		"manager": "ניסן מנחם שם טוב",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "סולם",
		"semelMosad": 411777,
		"settlement": "בית שמש",
		"manager": "בת שבע כץ",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "סוסיא",
		"semelMosad": 613349,
		"settlement": "סוסיה",
		"manager": "רבקה ברקוביץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "סוסיא-ישיבת בני\"ע",
		"semelMosad": 142265,
		"settlement": "סוסיה",
		"manager": "נעם יצחק פרל",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "סוקולוב",
		"semelMosad": 512871,
		"settlement": "בת ים",
		"manager": "עינת שני",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "סורקיס",
		"semelMosad": 415596,
		"settlement": "כפר סבא",
		"manager": "ניצה אורלי אבידר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "סטודיו אנקורי",
		"semelMosad": 479055,
		"settlement": "תל אביב - יפו",
		"manager": "דיויד צ'רלס קלאר",
		"level": "יסודי ועליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "סי פי עפולה",
		"semelMosad": 220384,
		"settlement": "עפולה",
		"manager": "דגנית שטיינהאור",
		"level": "יסודי חט\"ב ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "סיגליות",
		"semelMosad": 638304,
		"settlement": "באר שבע",
		"manager": "זיוה נבון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "סימני דרך",
		"semelMosad": 482323,
		"settlement": "מעברות",
		"manager": "יוגב אמיתי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "סיני",
		"semelMosad": 610188,
		"settlement": "אשקלון",
		"manager": "יעקב קנדלקאר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "סלאח א'דין",
		"semelMosad": 618371,
		"settlement": "רהט",
		"manager": "אמיר קרינאוי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "סלואן יסודי חדש",
		"semelMosad": 178145,
		"settlement": "ירושלים",
		"manager": "יחיא נאבלסי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "סלואן יסודי לבנות",
		"semelMosad": 118182,
		"settlement": "ירושלים",
		"manager": "חסיבה דולה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "סלואן יסודי מעורב",
		"semelMosad": 118174,
		"settlement": "ירושלים",
		"manager": "גיהאד אבו ח'לף",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "סלואן מכין לבנות",
		"semelMosad": 118208,
		"settlement": "ירושלים",
		"manager": "פאטמה פתיחה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "סלואן מכין לבנים",
		"semelMosad": 118190,
		"settlement": "ירושלים",
		"manager": "סאמר גול",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "סלעית ע\"ש עוז",
		"semelMosad": 482182,
		"settlement": "סלעית",
		"manager": "נמרוד בן-נוח",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "סמדר",
		"semelMosad": 544288,
		"settlement": "הרצליה",
		"manager": "רבקה שמש",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "סמהר לבנות",
		"semelMosad": 239871,
		"settlement": "ירושלים",
		"manager": "אמאל טחאן",
		"level": "יסודי ועליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "סמט מ. חינוך ב\"י",
		"semelMosad": 360503,
		"settlement": "ירושלים",
		"manager": "רבקה שחר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "סמילנסקי",
		"semelMosad": 412031,
		"settlement": "רחובות",
		"manager": "אפרת אלון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "סמינר אקשטיין",
		"semelMosad": 648444,
		"settlement": "בני ברק",
		"manager": "רבקה מוריה שפיצר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "סמינר ב\"י אפיקי דעה",
		"semelMosad": 199109,
		"settlement": "ירושלים",
		"manager": "גיטל גרוזובסקי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "סמינר ב.יעקב אור שרה",
		"semelMosad": 560359,
		"settlement": "בית שמש",
		"manager": "צביה בוקצ'ין",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "סמינר ב.יעקב תל ציון",
		"semelMosad": 560268,
		"settlement": "כוכב יעקב",
		"manager": "גבריאל קוסבר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "סמינר בינת החינוך",
		"semelMosad": 749481,
		"settlement": "ירושלים",
		"manager": "בת שבע אסולין",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "סמינר בית חינוך",
		"semelMosad": 284026,
		"settlement": "מודיעין עילית",
		"manager": "הדסה זילברמן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "סמינר בית יעקב",
		"semelMosad": 226803,
		"settlement": "בית שמש",
		"manager": "לאה פרלשטין",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "סמינר בית יעקב",
		"semelMosad": 189076,
		"settlement": "ירושלים",
		"manager": "דוד מיכאל עמנואל",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "סמינר בית יעקב",
		"semelMosad": 189084,
		"settlement": "ירושלים",
		"manager": "אסתר הלל",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "סמינר בית יעקב ב.שמש",
		"semelMosad": 197798,
		"settlement": "בית שמש",
		"manager": "ברכה דרוק",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "סמינר בית יעקב החדש",
		"semelMosad": 640987,
		"settlement": "אשדוד",
		"manager": "פרל ליבוביץ",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "סמינר בית יעקב החדש",
		"semelMosad": 660282,
		"settlement": "נתיבות",
		"manager": "צפורה פישבין",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "סמינר בית יעקב החדש",
		"semelMosad": 640771,
		"settlement": "באר שבע",
		"manager": "סימה אביטל",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "סמינר בית יעקב החסיד",
		"semelMosad": 645036,
		"settlement": "מודיעין עילית",
		"manager": "ראובן כץ",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "סמינר בית יעקב צפת",
		"semelMosad": 240721,
		"settlement": "צפת",
		"manager": "ברכה שרה קפלן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "סמינר בית יעקב תפארת",
		"semelMosad": 749200,
		"settlement": "אשדוד",
		"manager": "רבקה נחמני",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "סמינר בית מלכה בית ש",
		"semelMosad": 722512,
		"settlement": "בית שמש",
		"manager": "שרה מילר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "סמינר בית מרגלית",
		"semelMosad": 442517,
		"settlement": "אלעד",
		"manager": "אורלי אלבז",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "סמינר בנות חנה- חב\"ד",
		"semelMosad": 642108,
		"settlement": "בני ברק",
		"manager": "חנה רבקה זלמנוב",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "סמינר בנות לאה אסתר",
		"semelMosad": 759043,
		"settlement": "בני ברק",
		"manager": "חנה אשר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "סמינר בנות מלכה",
		"semelMosad": 761353,
		"settlement": "מודיעין עילית",
		"manager": "שרה סוקולובר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "סמינר גברא אלעד",
		"semelMosad": 715235,
		"settlement": "אלעד",
		"manager": "רוחלי יצחקי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "סמינר גור בית שמש",
		"semelMosad": 560219,
		"settlement": "בית שמש",
		"manager": "אסתר רוטנברג",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "סמינר גור חיפה",
		"semelMosad": 729558,
		"settlement": "חיפה",
		"manager": "שרה הנדלס",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "סמינר הרב כהנא",
		"semelMosad": 541508,
		"settlement": "בני ברק",
		"manager": "אברהם כהנא",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "סמינר הרב מאיר",
		"semelMosad": 541474,
		"settlement": "בני ברק",
		"manager": "רחל דינר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "סמינר חניכי הישיבות",
		"semelMosad": 641274,
		"settlement": "אשדוד",
		"manager": "שושנה ווייס",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "סמינר חסידי ברסלב",
		"semelMosad": 359059,
		"settlement": "ירושלים",
		"manager": "חיה אסתר קסטלניץ",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "סמינר חסידי ירושלים",
		"semelMosad": 560581,
		"settlement": "ירושלים",
		"manager": "מרים רבקה ברנדר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "סמינר יצהר",
		"semelMosad": 758094,
		"settlement": "כפר סבא",
		"manager": "אביה רחלה סילברשטיין",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "סמינר כלל חסידי",
		"semelMosad": 373183,
		"settlement": "ביתר עילית",
		"manager": "חנה מינדל מנהיים",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "סמינר כלל חסידי צפת",
		"semelMosad": 656017,
		"settlement": "צפת",
		"manager": "מלכה שיינדל קיוואק",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "סמינר לבנות אלקיים",
		"semelMosad": 541482,
		"settlement": "בני ברק",
		"manager": "מזל טוב אלקיים",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "סמינר לבנות ויזניץ",
		"semelMosad": 641290,
		"settlement": "אשדוד",
		"manager": "יפה שיינדל שטיינמץ",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "סמינר לבנות ירים משה",
		"semelMosad": 176909,
		"settlement": "ירושלים",
		"manager": "אהרן נינו",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "סמינר לוית חן",
		"semelMosad": 722074,
		"settlement": "בית שמש",
		"manager": "ברכה נרי שרה אדלר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "סמינר לפידות",
		"semelMosad": 478875,
		"settlement": "ירושלים",
		"manager": "מוריה גרינוואלד",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "סמינר מורשת ירושלים",
		"semelMosad": 722066,
		"settlement": "ביתר עילית",
		"manager": "חיה הנלה לייפר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "סמינר עטרת רחל",
		"semelMosad": 541680,
		"settlement": "בני ברק",
		"manager": "צביה בוכריץ",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "סמינר שושנים",
		"semelMosad": 749077,
		"settlement": "ירושלים",
		"manager": "ורד בכר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "סמינר תהילה",
		"semelMosad": 541490,
		"settlement": "בני ברק",
		"manager": "משה אואקנין",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "סמינר תורני בית יעקב",
		"semelMosad": 560342,
		"settlement": "בני ברק",
		"manager": "יוכבד חדוה גברא",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "סנא",
		"semelMosad": 722819,
		"settlement": "יפיע",
		"manager": "תגריד קאדריה",
		"level": "יסודי בלבד",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "סנט ג'וזף",
		"semelMosad": 417022,
		"settlement": "רמלה",
		"manager": "יאסמין אלקלק",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "סנט ג'ורג'",
		"semelMosad": 448332,
		"settlement": "לוד",
		"manager": "זוהיר זידאן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "סנט ג'ורג'",
		"semelMosad": 412817,
		"settlement": "לוד",
		"manager": "קפאח מוחארב",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "סנט גוזף אקליריקי",
		"semelMosad": 247023,
		"settlement": "נצרת",
		"manager": "סמעאן אבו סינה",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "סנט גוזף נצרת",
		"semelMosad": 217158,
		"settlement": "נצרת",
		"manager": "לורה אבו ח'דרה ח'ורי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "סעדיה גאון מד",
		"semelMosad": 510057,
		"settlement": "אור יהודה",
		"manager": "ענת ברקוביץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ספיר",
		"semelMosad": 413658,
		"settlement": "לוד",
		"manager": "איריס עזריה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ע\"ס טכנולוגי",
		"semelMosad": 374199,
		"settlement": "ג'סר א-זרקא",
		"manager": "אחמד מחאמיד",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "ע\"ש \"חיים חפר",
		"semelMosad": 511519,
		"settlement": "פתח תקווה",
		"manager": "נעמה גילאור",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ע\"ש אהוד מנור",
		"semelMosad": 416446,
		"settlement": "פתח תקווה",
		"manager": "אסתר רחמים",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ע\"ש אהרון דוידי",
		"semelMosad": 411413,
		"settlement": "נתניה",
		"manager": "אלונה שלום",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ע\"ש אילן רמון",
		"semelMosad": 131318,
		"settlement": "מודיעין-מכבים-רעות",
		"manager": "נעמה דהן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ע\"ש הנדיב-מטולה",
		"semelMosad": 211268,
		"settlement": "מטולה",
		"manager": "מסעודה מרסל לב",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ע\"ש זבולון המר",
		"semelMosad": 312819,
		"settlement": "טירת כרמל",
		"manager": "דלית מנשה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ע\"ש חג' סלמאן אלעתאיקה",
		"semelMosad": 615336,
		"settlement": "רהט",
		"manager": "סקר אבו עאבד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "ע\"ש חוסיין יאסין ב",
		"semelMosad": 218842,
		"settlement": "עראבה",
		"manager": "יוסף כנאענה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "ע\"ש יגאל אלון",
		"semelMosad": 513309,
		"settlement": "בת ים",
		"manager": "אילנה שובל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ע\"ש יעקב כהן",
		"semelMosad": 514935,
		"settlement": "קרית אונו",
		"manager": "ליאת חיה פלג",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ע\"ש יצחק נבון מודיעין",
		"semelMosad": 540500,
		"settlement": "מודיעין-מכבים-רעות",
		"manager": "ליאת אפלבוים",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ע\"ש יצחק רבין",
		"semelMosad": 312728,
		"settlement": "פרדס חנה-כרכור",
		"manager": "מירב שרף",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ע\"ש יצחק רבין עי\"ס",
		"semelMosad": 144675,
		"settlement": "מודיעין-מכבים-רעות",
		"manager": "רבקה ריקי נגרי",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ע\"ש נעמי שמר",
		"semelMosad": 414201,
		"settlement": "נתניה",
		"manager": "מזל דיין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ע\"ש נעמי שמר",
		"semelMosad": 413955,
		"settlement": "פתח תקווה",
		"manager": "רויטל שהם",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ע\"ש נעמי שמר",
		"semelMosad": 513630,
		"settlement": "תל אביב - יפו",
		"manager": "יעל טויסטר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ע\"ש עבד אלעזיז אמון",
		"semelMosad": 218172,
		"settlement": "דייר אל-אסד",
		"manager": "ג'האן דבאח עואד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "ע\"ש רא\"ל מרדכי גור",
		"semelMosad": 144741,
		"settlement": "מודיעין-מכבים-רעות",
		"manager": "דוד שרבי",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ע'ש חמיס אלקרנאוי ז'",
		"semelMosad": 618579,
		"settlement": "רהט",
		"manager": "חמאד אלקרינאווי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "עארה ב",
		"semelMosad": 318261,
		"settlement": "ערערה",
		"manager": "ראבאל כבהא",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "עבד אלראוף סמארה",
		"semelMosad": 442566,
		"settlement": "טירה",
		"manager": "וחיד חאסקיה",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "עבד אלרחמן אלחא'ג",
		"semelMosad": 318394,
		"settlement": "חיפה",
		"manager": "מוחמד עואד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "עבד רבה אלאסד",
		"semelMosad": 618082,
		"settlement": "לקיה",
		"manager": "יוסף אלאסד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "עבדאללה בנות מח\"ט",
		"semelMosad": 633305,
		"settlement": "ירושלים",
		"manager": "סנא יאסין",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "עגורים מבואות חרמון",
		"semelMosad": 420059,
		"settlement": "מטולה",
		"manager": "אילנה ויינמן",
		"level": "חט\"ב + עליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עדיני",
		"semelMosad": 412015,
		"settlement": "ראשון לציון",
		"manager": "רחל קידר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עדני הנגב",
		"semelMosad": 820548,
		"settlement": "שובל",
		"manager": "אורלי פסל",
		"level": "חט\"ב + עליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עובדת",
		"semelMosad": 618793,
		"settlement": "ביר הדאג'",
		"manager": "סלימאן זנון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "עודד",
		"semelMosad": 646299,
		"settlement": "לבון",
		"manager": "גיורא משה אבידור",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עוז",
		"semelMosad": 520502,
		"settlement": "חולון",
		"manager": "שירן הלן ברקול גייצן",
		"level": "יסודי חט\"ב ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עוז והדר",
		"semelMosad": 414821,
		"settlement": "נתניה",
		"manager": "אורן גבאי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עוזיאל",
		"semelMosad": 613141,
		"settlement": "באר שבע",
		"manager": "אילת רחל ויינטרוב",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עוזיאל",
		"semelMosad": 610196,
		"settlement": "אשקלון",
		"manager": "מאיר גמליאל מלכה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עוזיאל",
		"semelMosad": 110486,
		"settlement": "ירושלים",
		"manager": "רונן מזרחי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עוזיאל מד-קרית שמונה",
		"semelMosad": 212118,
		"settlement": "קרית שמונה",
		"manager": "אילנה מויאל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עוללים",
		"semelMosad": 414706,
		"settlement": "נתניה",
		"manager": "אלימלך פינטוך",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עולמאא אלג'ד",
		"semelMosad": 410902,
		"settlement": "ירושלים",
		"manager": "חולה בשיתי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "עולמות תכון חרד.בנות",
		"semelMosad": 460436,
		"settlement": "ירושלים",
		"manager": "מורן נוימרק",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עומר",
		"semelMosad": 573055,
		"settlement": "רמת השרון",
		"manager": "גליה לידובסקי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עומר אבן אלח'טאב",
		"semelMosad": 318519,
		"settlement": "ג'סר א-זרקא",
		"manager": "אידה זאיט",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "עומר אבן אלח'טאב מכר",
		"semelMosad": 219477,
		"settlement": "ג'דיידה-מכר",
		"manager": "מימנה רנה עתאמנה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "עומר אבן אלחטאב",
		"semelMosad": 418202,
		"settlement": "קלנסווה",
		"manager": "עותמאן חטיב",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "עומר בן אלח'טאב",
		"semelMosad": 418103,
		"settlement": "כפר ברא",
		"manager": "עמראן אבו חיט",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "עומר בן אלח'טאב",
		"semelMosad": 618322,
		"settlement": "רהט",
		"manager": "עאליה אבו בלאל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "עומר בן אלח'טאב ד'",
		"semelMosad": 247148,
		"settlement": "נצרת",
		"manager": "עזמי ארשייד",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "עומר בן אלחטאב",
		"semelMosad": 318071,
		"settlement": "באקה אל-גרביה",
		"manager": "מיסון גנאים",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "עומר משגב",
		"semelMosad": 640441,
		"settlement": "עצמון שגב",
		"manager": "אורלי גלעד",
		"level": "חט\"ב + עליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עומרים",
		"semelMosad": 611863,
		"settlement": "עומר",
		"manager": "סגלית סעדה ישי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עוספיא א",
		"semelMosad": 318303,
		"settlement": "עספיא",
		"manager": "נאיף מנסור",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "דרוזי"
	},
	{
		"schoolName": "עופר",
		"semelMosad": 325969,
		"settlement": "חיפה",
		"manager": "סיגל מרי יוהנה לב",
		"level": "חט\"ב + עליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עופרים",
		"semelMosad": 241059,
		"settlement": "ירושלים",
		"manager": "אפרת ולנסי-מטרסו",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עופרים",
		"semelMosad": 384222,
		"settlement": "חיפה",
		"manager": "נחום בלוך",
		"level": "יסודי בלבד",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עזר לחינוך",
		"semelMosad": 732420,
		"settlement": "ירושלים",
		"manager": "חיים עוזר אורלווק",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עטרת אסתר",
		"semelMosad": 614362,
		"settlement": "שדרות",
		"manager": "שושנה עזרן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עטרת אסתר",
		"semelMosad": 361238,
		"settlement": "ירושלים",
		"manager": "שרה יוסף",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עטרת הלויים",
		"semelMosad": 441865,
		"settlement": "פתח תקווה",
		"manager": "יהושע לוי קיפור",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עטרת התורה",
		"semelMosad": 144071,
		"settlement": "ירושלים",
		"manager": "אהרן יוסף לוי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עטרת התורה",
		"semelMosad": 359067,
		"settlement": "ירושלים",
		"manager": "אלתר יהודה יברוב",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עטרת התורה",
		"semelMosad": 734954,
		"settlement": "ירושלים",
		"manager": "יעקב חי ששון",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עטרת התלמוד",
		"semelMosad": 745877,
		"settlement": "תל אביב - יפו",
		"manager": "יחזקאל חזן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עטרת חיה",
		"semelMosad": 514489,
		"settlement": "בני ברק",
		"manager": "חיה בילה לנדא",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עטרת חיים",
		"semelMosad": 727362,
		"settlement": "אלעד",
		"manager": "אליאל אליהו אברהם נהרי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עטרת חיים",
		"semelMosad": 413013,
		"settlement": "כפר סבא",
		"manager": "יהודה אמר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עטרת חיים בנות",
		"semelMosad": 758995,
		"settlement": "כפר סבא",
		"manager": "איילת תעשה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עטרת יוסף שלום",
		"semelMosad": 519736,
		"settlement": "מודיעין עילית",
		"manager": "חנוך הבלין",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עטרת מאיר",
		"semelMosad": 641217,
		"settlement": "אשדוד",
		"manager": "יכין הלוי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עטרת מלכה",
		"semelMosad": 743682,
		"settlement": "מודיעין עילית",
		"manager": "טויבה גפנר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עטרת מלכה קרית גת",
		"semelMosad": 643650,
		"settlement": "קרית גת",
		"manager": "יהודית ברינשטיין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עטרת מרדכי",
		"semelMosad": 240614,
		"settlement": "טבריה",
		"manager": "יצחק בן ארוש",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עטרת עמרם אוהל אשר",
		"semelMosad": 338830,
		"settlement": "אלעד",
		"manager": "שמעון רווח",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עטרת רחל",
		"semelMosad": 631481,
		"settlement": "ירושלים",
		"manager": "יהודית חנה זלוף",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עטרת רחל",
		"semelMosad": 373142,
		"settlement": "טבריה",
		"manager": "רחל קולודצקי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עטרת שלמה אלעד",
		"semelMosad": 747501,
		"settlement": "אלעד",
		"manager": "אברהם מרמרוש",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עטרת שלמה אריה",
		"semelMosad": 360529,
		"settlement": "ירושלים",
		"manager": "שמעון ברסלויער",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עטרת שלמה בארה\"ק",
		"semelMosad": 729079,
		"settlement": "בני ברק",
		"manager": "אברהם צבי פוברסקי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עטרת שלמה ביתר",
		"semelMosad": 722140,
		"settlement": "ביתר עילית",
		"manager": "אליאב יונגרייז",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עטרת תמר",
		"semelMosad": 759019,
		"settlement": "חיפה",
		"manager": "צפורה פייגא ברלינר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עי\"ס אלאימאן בנות",
		"semelMosad": 238485,
		"settlement": "ירושלים",
		"manager": "נאילה חליל",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "עי\"ס אלאימאן בנים",
		"semelMosad": 238519,
		"settlement": "ירושלים",
		"manager": "גמאל באשה",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "עי\"ס אלחיה צור באהר",
		"semelMosad": 185157,
		"settlement": "ירושלים",
		"manager": "סלאח דבש",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "עי\"ס אלקאסמי",
		"semelMosad": 348342,
		"settlement": "באקה אל-גרביה",
		"manager": "וסים בריה",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "עי\"ס כרמים בנימינה גבעת עדה",
		"semelMosad": 370395,
		"settlement": "בנימינה-גבעת עדה",
		"manager": "יורם יפה",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עי\"ס למדעים מועאוויה",
		"semelMosad": 370494,
		"settlement": "בסמ\"ה",
		"manager": "אבראהים מחאמיד",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "עידוד",
		"semelMosad": 420471,
		"settlement": "רחובות",
		"manager": "רויטל לב אלקריף",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עידנים",
		"semelMosad": 115527,
		"settlement": "מודיעין-מכבים-רעות",
		"manager": "נטע מכתאר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עיוני בית יעקב",
		"semelMosad": 440263,
		"settlement": "פתח תקווה",
		"manager": "עזריה הילדסהיימר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עיוני מג'דל שמס",
		"semelMosad": 247858,
		"settlement": "מג'דל שמס",
		"manager": "תורכי אבו סאלח",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "דרוזי"
	},
	{
		"schoolName": "עיילוט ב '",
		"semelMosad": 218768,
		"settlement": "עילוט",
		"manager": "עבדאללה חג'אג'רה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "עיינת הגליל",
		"semelMosad": 410217,
		"settlement": "עמיר",
		"manager": "ענת כפרי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עילא\"י",
		"semelMosad": 331025,
		"settlement": "מגדל העמק",
		"manager": "אביעד-ניסים כלב",
		"level": "יסודי בלבד",
		"special": "מיוחד",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עין אברהים",
		"semelMosad": 318279,
		"settlement": "אום אל-פחם",
		"manager": "יוסף מחאג'נה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "עין אלסהלה",
		"semelMosad": 318287,
		"settlement": "בסמ\"ה",
		"manager": "סחאר בדראן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "עין גנים",
		"semelMosad": 412908,
		"settlement": "פתח תקווה",
		"manager": "רונית שעשה קרסיק",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עין הים",
		"semelMosad": 310698,
		"settlement": "חיפה",
		"manager": "ברוך יעקובי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עין הקורא",
		"semelMosad": 413294,
		"settlement": "ראשון לציון",
		"manager": "עדנה אלי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עין הרים",
		"semelMosad": 111096,
		"settlement": "עין כרם-בי\"ס חקלאי",
		"manager": "לילך זריהן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עיסאויה לבנות",
		"semelMosad": 118224,
		"settlement": "ירושלים",
		"manager": "עפאף קדומי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "עיסאויה לבנים",
		"semelMosad": 118216,
		"settlement": "ירושלים",
		"manager": "עבד אל פתאח זידאן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "עירוני א'",
		"semelMosad": 340174,
		"settlement": "חיפה",
		"manager": "יפית כהן",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עירוני א'",
		"semelMosad": 144683,
		"settlement": "מודיעין-מכבים-רעות",
		"manager": "דביר שלומי",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עירוני א' חשמונאים",
		"semelMosad": 540351,
		"settlement": "בת ים",
		"manager": "מלכה אביוב",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עירוני א' לאמנויות",
		"semelMosad": 540112,
		"settlement": "תל אביב - יפו",
		"manager": "יואב פרידן",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עירוני א' מקיף",
		"semelMosad": 640037,
		"settlement": "באר שבע",
		"manager": "אביבה סויסה",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עירוני ב' ישיבה",
		"semelMosad": 340166,
		"settlement": "חיפה",
		"manager": "משה מרסל אלמלם",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עירוני ג'",
		"semelMosad": 340158,
		"settlement": "חיפה",
		"manager": "לילך שני רומאנו",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עירוני ג'",
		"semelMosad": 140046,
		"settlement": "ירושלים",
		"manager": "אילת גולומב פלנר",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עירוני ג' מקיף",
		"semelMosad": 640193,
		"settlement": "באר שבע",
		"manager": "אוה חוה סטרול",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עירוני ד'",
		"semelMosad": 540146,
		"settlement": "תל אביב - יפו",
		"manager": "נתן שטרן",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עירוני ד' גן נחום",
		"semelMosad": 440875,
		"settlement": "ראשון לציון",
		"manager": "חגי בן אברהם",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עירוני דתי רוטשילד",
		"semelMosad": 140038,
		"settlement": "ירושלים",
		"manager": "שולמית אייזקס",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עירוני ה'",
		"semelMosad": 540153,
		"settlement": "תל אביב - יפו",
		"manager": "לימור מילר",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עירוני ה'",
		"semelMosad": 340315,
		"settlement": "חיפה",
		"manager": "חגית לוי",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עירוני ה' מקיף",
		"semelMosad": 440917,
		"settlement": "ראשון לציון",
		"manager": "אסתר וינקלר",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עירוני ו' מקיף",
		"semelMosad": 770826,
		"settlement": "באר שבע",
		"manager": "מתי מינדלין",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עירוני ו' מקיף",
		"semelMosad": 540161,
		"settlement": "תל אביב - יפו",
		"manager": "גלאל טוחי",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עירוני חוגים",
		"semelMosad": 340208,
		"settlement": "חיפה",
		"manager": "מילנה מירון",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עירוני ט'",
		"semelMosad": 540195,
		"settlement": "תל אביב - יפו",
		"manager": "רונן רפאל",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עירוני י' אליאנס",
		"semelMosad": 540088,
		"settlement": "תל אביב - יפו",
		"manager": "יניב בכר",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עירוני יא'",
		"semelMosad": 540518,
		"settlement": "תל אביב - יפו",
		"manager": "דרור איסרוב",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עירוני יב'",
		"semelMosad": 548016,
		"settlement": "תל אביב - יפו",
		"manager": "אוסאמה עראר",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "עירוני יד'",
		"semelMosad": 770297,
		"settlement": "תל אביב - יפו",
		"manager": "יעל איילון",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עירוני כ\"ד- עמוס עוז",
		"semelMosad": 581439,
		"settlement": "תל אביב - יפו",
		"manager": "שרית פקרמן שחר",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עירוני כ\"ח- רוז מצקין",
		"semelMosad": 533208,
		"settlement": "תל אביב - יפו",
		"manager": "אלירן עזרא",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עירוני כללי",
		"semelMosad": 540377,
		"settlement": "בני ברק",
		"manager": "רבקה חסקלוביץ",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עירוני כללי הראשונים",
		"semelMosad": 540328,
		"settlement": "הרצליה",
		"manager": "סיגלית דוד",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עירוני מסורתי",
		"semelMosad": 140962,
		"settlement": "ירושלים",
		"manager": "יעל הימן",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עירוני מקיף רמות",
		"semelMosad": 540492,
		"settlement": "בת ים",
		"manager": "חגית איזאק",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עירוני מקיף שי עגנון",
		"semelMosad": 444950,
		"settlement": "נתניה",
		"manager": "צפורה ידידיה",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עירוני מקיף שפרעם",
		"semelMosad": 248070,
		"settlement": "שפרעם",
		"manager": "עלי מוקבל",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "עירוני משה שרת",
		"semelMosad": 490037,
		"settlement": "נתניה",
		"manager": "רון חיים גבאי",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עירוני נווה צדק",
		"semelMosad": 520288,
		"settlement": "תל אביב - יפו",
		"manager": "תרצה יודלביץ שולמן",
		"level": "חט\"ב + עליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עירוני ע\"ש בליך",
		"semelMosad": 540211,
		"settlement": "רמת גן",
		"manager": "הילה זגזג-רומש",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עירוני ע\"ש בן גוריון",
		"semelMosad": 490052,
		"settlement": "פתח תקווה",
		"manager": "חגי קרקו",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עירוני ע\"ש שזר",
		"semelMosad": 770586,
		"settlement": "בת ים",
		"manager": "שימרית שי",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עירוני עש פנחס אילון",
		"semelMosad": 540294,
		"settlement": "חולון",
		"manager": "אוסנת נעים",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עירוני פסגת זאב",
		"semelMosad": 141309,
		"settlement": "ירושלים",
		"manager": "סוניה רפאלי",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עירוני ראשית בנות",
		"semelMosad": 540039,
		"settlement": "תל אביב - יפו",
		"manager": "רבקה ברק טמיר",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עירוני תורני ב' ג'",
		"semelMosad": 540120,
		"settlement": "תל אביב - יפו",
		"manager": "רבקה אילוז",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עכו מח\"ט(מרכז חינוך)",
		"semelMosad": 499624,
		"settlement": "עכו",
		"manager": "איאד סאלח",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "על איזורי קשת",
		"semelMosad": 114389,
		"settlement": "ירושלים",
		"manager": "לילך אטיה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "על יסודי אדם",
		"semelMosad": 641522,
		"settlement": "ירושלים",
		"manager": "הילה מור",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "על יסודי אלחיה",
		"semelMosad": 347096,
		"settlement": "בסמ\"ה",
		"manager": "סופיאן כבהא",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "על יסודי בנים יד משה",
		"semelMosad": 560391,
		"settlement": "בית שמש",
		"manager": "פנחס בידרמן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "על יסודי טכנולוגי",
		"semelMosad": 448266,
		"settlement": "טירה",
		"manager": "שירין פדילה",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "על יסודי טכנולוגי",
		"semelMosad": 248831,
		"settlement": "מג'ד אל-כרום",
		"manager": "ח'אלד בדארנה",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "על יסודי מאר מטרי",
		"semelMosad": 343970,
		"settlement": "ירושלים",
		"manager": "סמיר זנאנירי",
		"level": "יסודי ועליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "על יסודי משהד",
		"semelMosad": 277020,
		"settlement": "משהד",
		"manager": "זיאד חסן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "על יסודי עולמאא אלגד",
		"semelMosad": 719047,
		"settlement": "ירושלים",
		"manager": "חולה בשיתי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "על יסודי צור הדסה",
		"semelMosad": 420018,
		"settlement": "צור הדסה",
		"manager": "עידו אברהם אהרונוביץ",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "על יסודי רב נתיבי",
		"semelMosad": 178079,
		"settlement": "ירושלים",
		"manager": "נהאד שוויקי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "על יסודי רב נתיבי",
		"semelMosad": 560722,
		"settlement": "ירושלים",
		"manager": "ג'האן מנצור אבו חוף",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "עלה",
		"semelMosad": 520874,
		"settlement": "בני ברק",
		"manager": "חגית מעודה",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עלה",
		"semelMosad": 475848,
		"settlement": "בני ברק",
		"manager": "חגית מעודה",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עלה ירושלים",
		"semelMosad": 132019,
		"settlement": "ירושלים",
		"manager": "רחל פישהיימר",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עלה ירושלים",
		"semelMosad": 475780,
		"settlement": "ירושלים",
		"manager": "יוכבד פנינה לוי",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עלה נגב",
		"semelMosad": 620427,
		"settlement": "בתי ספר של מרחבים",
		"manager": "צופיה גרין",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עלה נגב מרחבים",
		"semelMosad": 640219,
		"settlement": "בתי ספר של מרחבים",
		"manager": "מוריה מרים לסרי",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עלומים",
		"semelMosad": 312207,
		"settlement": "קרית ים",
		"manager": "לורט פייטלוביץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עלומים",
		"semelMosad": 644146,
		"settlement": "אשדוד",
		"manager": "ספיר קקון",
		"level": "יסודי חט\"ב ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עלומים",
		"semelMosad": 510578,
		"settlement": "חולון",
		"manager": "אירית סילביה רוזנבלט קולומבוס",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עלומים",
		"semelMosad": 513192,
		"settlement": "קרית אונו",
		"manager": "עינב חביב אסא",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עלי גבעה -כפר גלעדי",
		"semelMosad": 210971,
		"settlement": "כפר גלעדי",
		"manager": "טל חיה רובינסקי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עלי זהב",
		"semelMosad": 482430,
		"settlement": "עלי זהב",
		"manager": "לאה שטינמץ",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עלי זהב מזרח",
		"semelMosad": 482828,
		"settlement": "עלי זהב",
		"manager": "יצחק רפופורט",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עליה",
		"semelMosad": 310706,
		"settlement": "חיפה",
		"manager": "שי דשכנזי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עליות",
		"semelMosad": 510750,
		"settlement": "רמת גן",
		"manager": "אסתר קדוש",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עליזה ארבילי-דגניה",
		"semelMosad": 310904,
		"settlement": "טירת כרמל",
		"manager": "שולמית בן-עמי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עליזה בגין מגדל העמק",
		"semelMosad": 213702,
		"settlement": "מגדל העמק",
		"manager": "דורית כהן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עליזה ומנחם בגין",
		"semelMosad": 313825,
		"settlement": "קרית מוצקין",
		"manager": "רעות ברדה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עלמא",
		"semelMosad": 746115,
		"settlement": "נשר",
		"manager": "אוריה ורדית מצרפי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עמאר",
		"semelMosad": 328161,
		"settlement": "חיפה",
		"manager": "אומיימה קדור",
		"level": "יסודי חט\"ב ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "עמי אסף",
		"semelMosad": 611491,
		"settlement": "דימונה",
		"manager": "ימית אסתר קוריאה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עמיאל רמב\"ם מד",
		"semelMosad": 511931,
		"settlement": "תל אביב - יפו",
		"manager": "רות דרעי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עמיטל תורני",
		"semelMosad": 411306,
		"settlement": "פתח תקווה",
		"manager": "חגית קליפר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עמית",
		"semelMosad": 612812,
		"settlement": "מיתר",
		"manager": "דליה אדל פרץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עמל",
		"semelMosad": 520072,
		"settlement": "חולון",
		"manager": "פנינה זלטא",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עמל אולפנת הראל נהריה",
		"semelMosad": 240085,
		"settlement": "נהריה",
		"manager": "איילת כהן",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עמל אמירים אופקים",
		"semelMosad": 770057,
		"settlement": "אופקים",
		"manager": "יהל שחר",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עמל בנות ציון ירושלים",
		"semelMosad": 176487,
		"settlement": "ירושלים",
		"manager": "הדסה אסתר עזר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עמל התורה הקדושה",
		"semelMosad": 757831,
		"settlement": "בני ברק",
		"manager": "מאיר שטיבל",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עמל חדרה למדעים ולאמנויות",
		"semelMosad": 370072,
		"settlement": "חדרה",
		"manager": "אילנה שטרהל",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עמל ירמיהו ירושלים",
		"semelMosad": 442657,
		"settlement": "ירושלים",
		"manager": "דוב רוזמן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עמל כסרא סמיע",
		"semelMosad": 248336,
		"settlement": "כסרא-סמיע",
		"manager": "נזיר רבאח",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "דרוזי"
	},
	{
		"schoolName": "עמל ליידי דיוייס ירושלים",
		"semelMosad": 170175,
		"settlement": "ירושלים",
		"manager": "רפאל אלפסי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עמל ליידי דייויס ת\"א",
		"semelMosad": 570036,
		"settlement": "תל אביב - יפו",
		"manager": "שרה הלפרין",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עמל מח\"ט זרזיר",
		"semelMosad": 633685,
		"settlement": "זרזיר",
		"manager": "סוכינה סואעד",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "עמל נופרים בגליל טבריה",
		"semelMosad": 240267,
		"settlement": "טבריה",
		"manager": "דקל ערוסי",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עמל ע\"ש אברהים קאסם טירה",
		"semelMosad": 448027,
		"settlement": "טירה",
		"manager": "מוחמד סעיד קאסם",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "עמל ע\"ש ע. עתילי טירה",
		"semelMosad": 448241,
		"settlement": "טירה",
		"manager": "בושרא דעאס",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "עמל עהד חורה למצוינות",
		"semelMosad": 640276,
		"settlement": "חורה",
		"manager": "מחמד אבו נג'א",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "עמל עטרות מ.ירושלים",
		"semelMosad": 199919,
		"settlement": "ירושלים",
		"manager": "אנואר דאוד",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "עמל רמות באר שבע",
		"semelMosad": 670042,
		"settlement": "באר שבע",
		"manager": "מטי בילו",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עמל שבח מופת ת\"א",
		"semelMosad": 570051,
		"settlement": "תל אביב - יפו",
		"manager": "מקס סיון",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עמל שחקים נהריה",
		"semelMosad": 244152,
		"settlement": "נהריה",
		"manager": "אברהם מנשס",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עמלה של תורה",
		"semelMosad": 722025,
		"settlement": "ירושלים",
		"manager": "יוסף זאב פיינשטיין",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עמק חפר משותף",
		"semelMosad": 641647,
		"settlement": "מדרשת רופין",
		"manager": "עמית ויינשטיין",
		"level": "חט\"ב + עליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עמק יזרעאל-גניגר",
		"semelMosad": 210435,
		"settlement": "גיניגר",
		"manager": "רואי כהן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עמק יפה",
		"semelMosad": 844043,
		"settlement": "ניר יפה",
		"manager": "יעקב רוימי",
		"level": "חט\"ב + עליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עמר אבן אלחטאב",
		"semelMosad": 318048,
		"settlement": "אום אל-פחם",
		"manager": "מארי אגבאריה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "עמריה",
		"semelMosad": 118307,
		"settlement": "ירושלים",
		"manager": "עבד אל ווהאב עיסא",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "ענבלים",
		"semelMosad": 573089,
		"settlement": "מודיעין-מכבים-רעות",
		"manager": "רויטל ישראל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עפרה חזה",
		"semelMosad": 581181,
		"settlement": "תל אביב - יפו",
		"manager": "רחלי פרידמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עץ חיים",
		"semelMosad": 512319,
		"settlement": "ביתר עילית",
		"manager": "ברוך קוגלמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עץ חיים בנות",
		"semelMosad": 482349,
		"settlement": "רחובות",
		"manager": "שרה רינה שכטר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עץ חיים דבאבוב בארה",
		"semelMosad": 648519,
		"settlement": "בני ברק",
		"manager": "שמשון הירש",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עציון",
		"semelMosad": 312223,
		"settlement": "אור עקיבא",
		"manager": "ריקי גינון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עציון",
		"semelMosad": 614560,
		"settlement": "קרית מלאכי",
		"manager": "חיים דוד מימרן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עציון גבר",
		"semelMosad": 612564,
		"settlement": "אילת",
		"manager": "איריס נבו",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עציון מד",
		"semelMosad": 511949,
		"settlement": "תל אביב - יפו",
		"manager": "אוראל קפרי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עציון מד",
		"semelMosad": 520593,
		"settlement": "בני ברק",
		"manager": "רחלי דבורה שטיין",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עצמון",
		"semelMosad": 211623,
		"settlement": "נוף הגליל",
		"manager": "שירה ירדני",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עצמונה",
		"semelMosad": 613943,
		"settlement": "שומריה",
		"manager": "עליזה אלבז",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עצת אמת",
		"semelMosad": 131193,
		"settlement": "מודיעין עילית",
		"manager": "יצחק קאפ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עקיבא מד",
		"semelMosad": 510271,
		"settlement": "בת ים",
		"manager": "חנה נגר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עראק אלשבאב",
		"semelMosad": 318337,
		"settlement": "אום אל-פחם",
		"manager": "סוהא מחאג'נה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "ערב אלגריפאת",
		"semelMosad": 218859,
		"settlement": "זרזיר",
		"manager": "גסאן גריפאת",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "ערב אלהוזייל",
		"semelMosad": 618058,
		"settlement": "רהט",
		"manager": "ראד אלהזיל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "ערב אלהיב פרוש רמאנה",
		"semelMosad": 218065,
		"settlement": "רומת הייב",
		"manager": "יוסף דלקי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "ערב אלחגאגרה",
		"semelMosad": 218867,
		"settlement": "כעביה-טבאש-חג'אג'רה",
		"manager": "מוחמד חג'אג'רה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "ערב אלחלף",
		"semelMosad": 218875,
		"settlement": "בסמת טבעון",
		"manager": "כאמל כעביה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "ערב אלחרובייה",
		"semelMosad": 218883,
		"settlement": "שפרעם",
		"manager": "דיאב סואעד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "ערב אלכעבייה",
		"semelMosad": 218891,
		"settlement": "כעביה-טבאש-חג'אג'רה",
		"manager": "ראג'ח אבו סויד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "ערב אלמזאריב",
		"semelMosad": 218909,
		"settlement": "זרזיר",
		"manager": "בושרא מזאריב",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "ערב אלעראמשה",
		"semelMosad": 218925,
		"settlement": "עראמשה",
		"manager": "מוחמד מזעל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "ערב חגיראת מכסור א",
		"semelMosad": 218222,
		"settlement": "ביר אל-מכסור",
		"manager": "זכי חוג'יראת",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "ערמונים",
		"semelMosad": 514398,
		"settlement": "רמת גן",
		"manager": "נעמה בן יהודה (דויטש) שלה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ערערה אלדהראת",
		"semelMosad": 318527,
		"settlement": "ערערה",
		"manager": "רוזקי אבו הלאל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "ערערה אלסלאם",
		"semelMosad": 318311,
		"settlement": "ערערה",
		"manager": "מוחמד אבו עקל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "עש גולדה מאיר נהריה",
		"semelMosad": 213710,
		"settlement": "נהריה",
		"manager": "איריס מלכה אללוף",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עש דינור",
		"semelMosad": 513143,
		"settlement": "חולון",
		"manager": "אורנה רמון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עש-הרצפלד",
		"semelMosad": 520627,
		"settlement": "חולון",
		"manager": "רויטל וייס",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עשה חייל",
		"semelMosad": 112250,
		"settlement": "אפרת",
		"manager": "יוסף שרבי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עתיד",
		"semelMosad": 510826,
		"settlement": "רמת גן",
		"manager": "אילה יצחקי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עתיד אום אל פחם",
		"semelMosad": 247320,
		"settlement": "אום אל-פחם",
		"manager": "מרואן מחאג'נה",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "עתיד אור מרגלית",
		"semelMosad": 641084,
		"settlement": "באר שבע",
		"manager": "חוה טוניק",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עתיד אלאהליה",
		"semelMosad": 347070,
		"settlement": "אום אל-פחם",
		"manager": "אחמד קבהא",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "עתיד אלנהדה כפר קרע",
		"semelMosad": 384016,
		"settlement": "כפר קרע",
		"manager": "אשרף סביחאת",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "עתיד דאר אל חכמא",
		"semelMosad": 448159,
		"settlement": "לוד",
		"manager": "אנואר קשקוש",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "עתיד חדש רחובות",
		"semelMosad": 519116,
		"settlement": "רחובות",
		"manager": "אילת ורד יוסף",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עתיד ירכא",
		"semelMosad": 519363,
		"settlement": "ירכא",
		"manager": "אחמד חמזה",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "דרוזי"
	},
	{
		"schoolName": "עתיד כרמיאל",
		"semelMosad": 756999,
		"settlement": "כרמיאל",
		"manager": "עדנה מרום",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עתיד למדעים לוד",
		"semelMosad": 441196,
		"settlement": "לוד",
		"manager": "גאולה סתי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עתיד מ.חנוך טכנולוגי",
		"semelMosad": 460576,
		"settlement": "לוד",
		"manager": "סומה עבד אל האדי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "עתיד מקיף אלמג'ד",
		"semelMosad": 448019,
		"settlement": "טייבה",
		"manager": "חוסני חאג יחיא",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "עתיד מקיף חדש אום בטין",
		"semelMosad": 648311,
		"settlement": "אום בטין",
		"manager": "יוסף אבו כף",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "עתיד מקיף ממ\"ד יגאל אלון",
		"semelMosad": 444943,
		"settlement": "יהוד-מונוסון",
		"manager": "אמיר כהן",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עתיד נווה נחום",
		"semelMosad": 442806,
		"settlement": "באר יעקב",
		"manager": "יגאל לוי",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עתיד עטרת חן",
		"semelMosad": 716423,
		"settlement": "ירושלים",
		"manager": "חנה רחל מאיר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עתיד עירוני ד",
		"semelMosad": 370403,
		"settlement": "חיפה",
		"manager": "יפית אוחנה",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עתיד קרית מוצקין",
		"semelMosad": 384008,
		"settlement": "קרית מוצקין",
		"manager": "אהוד בן גרא",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עתיד רזיאל",
		"semelMosad": 544395,
		"settlement": "הרצליה",
		"manager": "מלוה רוזנברג לוי",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עתידים",
		"semelMosad": 475061,
		"settlement": "כרמי יוסף",
		"manager": "אורלי אדרי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עתידים",
		"semelMosad": 484055,
		"settlement": "יבנה",
		"manager": "סמדר דורות",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עתידים ע\"ש מ. אלישיב",
		"semelMosad": 544593,
		"settlement": "חולון",
		"manager": "מרק בבוט",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "עתידך ירושלים",
		"semelMosad": 442723,
		"settlement": "ירושלים",
		"manager": "ישראל זכריה פרידלנדר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "פאר - צור",
		"semelMosad": 384321,
		"settlement": "חדרה",
		"manager": "יעל פלמח",
		"level": "יסודי בלבד",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "פאר מנחם",
		"semelMosad": 530022,
		"settlement": "אלעד",
		"manager": "דב סגל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "פארק",
		"semelMosad": 384586,
		"settlement": "חדרה",
		"manager": "אלון ירושלמי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "פארק המדע",
		"semelMosad": 729244,
		"settlement": "נס ציונה",
		"manager": "שמרית שמחה רביבו סולומון",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "פוירשטיין",
		"semelMosad": 120543,
		"settlement": "ירושלים",
		"manager": "ברוריה עמיחי",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "פטריאכט לטינה ריינה",
		"semelMosad": 217042,
		"settlement": "ריינה",
		"manager": "כריסתין זהר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "פיכמן",
		"semelMosad": 312140,
		"settlement": "חיפה",
		"manager": "אושרת אליה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "פינלנד סקול",
		"semelMosad": 610949,
		"settlement": "אשדוד",
		"manager": "מאיר בן זקן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "פינסקר",
		"semelMosad": 311001,
		"settlement": "קרית אתא",
		"manager": "שרית אברהם רוזנהיימר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "פיק\"א",
		"semelMosad": 411652,
		"settlement": "פתח תקווה",
		"manager": "גילת אנגל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "פיקודיך הבינני",
		"semelMosad": 359216,
		"settlement": "מודיעין עילית",
		"manager": "שמואל נחום סגל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "פלך",
		"semelMosad": 582007,
		"settlement": "תל אביב - יפו",
		"manager": "חנה פרידמן",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "פלך זכרון יעקב",
		"semelMosad": 374249,
		"settlement": "זכרון יעקב",
		"manager": "ברכה דביר",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "פלך ע\"ש רות קורמן",
		"semelMosad": 442079,
		"settlement": "קרית עקרון",
		"manager": "סנדרינה אלבאום בוחניק",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "פני יהושע",
		"semelMosad": 346635,
		"settlement": "ירושלים",
		"manager": "יוסף פרץ",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "פני מאיר (מעורב)",
		"semelMosad": 416156,
		"settlement": "כפר יונה",
		"manager": "יוסף הללויה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "פניני חן",
		"semelMosad": 460246,
		"settlement": "בית שמש",
		"manager": "מיכל פופקא",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "פנינת אור",
		"semelMosad": 213249,
		"settlement": "מגדל העמק",
		"manager": "חנה זייבאלד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "פנינת בית יעקב",
		"semelMosad": 511253,
		"settlement": "בני ברק",
		"manager": "מיכלא איטה בריינדל ויס",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "פסגות",
		"semelMosad": 616235,
		"settlement": "אשקלון",
		"manager": "ורד נטו",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "פסגות",
		"semelMosad": 311886,
		"settlement": "קרית ביאליק",
		"manager": "אורלי גדרון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "פסגות",
		"semelMosad": 613844,
		"settlement": "מיתר",
		"manager": "שגית טורג'מן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "פסגות",
		"semelMosad": 112110,
		"settlement": "פסגות",
		"manager": "יונה שפי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "פסגות ע\"ש חיים גורי",
		"semelMosad": 482224,
		"settlement": "ראש העין",
		"manager": "אביבית גרשון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "פסגת אמיר",
		"semelMosad": 338582,
		"settlement": "קציר",
		"manager": "גלית שפילר",
		"level": "יסודי בלבד",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "פסגת זאב מז ממד",
		"semelMosad": 113449,
		"settlement": "ירושלים",
		"manager": "אביטל ששון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "פסגת זאב מזרח א",
		"semelMosad": 114074,
		"settlement": "ירושלים",
		"manager": "אורנה הרשקוביץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "פסגת זאב מזרח ב' ממ'",
		"semelMosad": 113472,
		"settlement": "ירושלים",
		"manager": "רבקה חטב",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "פסגת זאב ממלכתי א",
		"semelMosad": 112482,
		"settlement": "ירושלים",
		"manager": "אילנית גבאי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "פסגת זאב מערב",
		"semelMosad": 114066,
		"settlement": "ירושלים",
		"manager": "נאוה אסולין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "פסגת זאב צפון",
		"semelMosad": 111120,
		"settlement": "ירושלים",
		"manager": "רונית ברפי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "פסיפס",
		"semelMosad": 731182,
		"settlement": "מודיעין-מכבים-רעות",
		"manager": "אלה מור",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "פקיעין ממלכתי ב",
		"semelMosad": 218958,
		"settlement": "פקיעין (בוקייעה)",
		"manager": "אכרם ח'ורי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "דרוזי"
	},
	{
		"schoolName": "פרדס מרחבי למידה",
		"semelMosad": 649020,
		"settlement": "פרדס חנה-כרכור",
		"manager": "נדב גרינברג",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "פרדס צפוני",
		"semelMosad": 414813,
		"settlement": "יבנה",
		"manager": "מירה צדוק",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "פרחי המדע",
		"semelMosad": 417246,
		"settlement": "רחובות",
		"manager": "אורית כוכבי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "פריר בית חנינא",
		"semelMosad": 184010,
		"settlement": "ירושלים",
		"manager": "גורג נבר",
		"level": "יסודי ועליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "פרנקל גבעת שפירא",
		"semelMosad": 111831,
		"settlement": "ירושלים",
		"manager": "זהר אליהו",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "פתחיה",
		"semelMosad": 747931,
		"settlement": "בית שמש",
		"manager": "שרה נישקעה למברגר",
		"level": "יסודי בלבד",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "פתחיה - פסגות",
		"semelMosad": 642835,
		"settlement": "ביתר עילית",
		"manager": "טובה גיטה קסטלניץ",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "צאלון",
		"semelMosad": 410100,
		"settlement": "באר יעקב",
		"manager": "מיכל אדרי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "צאלים",
		"semelMosad": 614271,
		"settlement": "אילת",
		"manager": "גילה גולן פרץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "צאן קדשים",
		"semelMosad": 455923,
		"settlement": "בית שמש",
		"manager": "שמעון בקר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "צבר",
		"semelMosad": 620211,
		"settlement": "דימונה",
		"manager": "רויטל נחמה בר-שי",
		"level": "יסודי חט\"ב ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "צהלה",
		"semelMosad": 511634,
		"settlement": "תל אביב - יפו",
		"manager": "איילת אסתר גבאי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "צהר לטהר",
		"semelMosad": 632349,
		"settlement": "רכסים",
		"manager": "חדוה אדלר",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "צהר לטהר",
		"semelMosad": 642470,
		"settlement": "צפת",
		"manager": "שרה מוסקוביץ",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "צוהר לטהר",
		"semelMosad": 520148,
		"settlement": "ביתר עילית",
		"manager": "רות שטרית",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "צוהר לטוהר",
		"semelMosad": 320515,
		"settlement": "כפר חסידים א'",
		"manager": "מרים רייזה וייסבלום",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "צופה שרון",
		"semelMosad": 414318,
		"settlement": "אלפי מנשה",
		"manager": "אורן אוריאלי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "צופיה",
		"semelMosad": 574293,
		"settlement": "נתניה",
		"manager": "הילה וינשטין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "צופיה קרית יובל",
		"semelMosad": 633008,
		"settlement": "ירושלים",
		"manager": "חוה בנטולילה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "צופים",
		"semelMosad": 482943,
		"settlement": "צופים",
		"manager": "מרים שטיינמץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "צוקי אביב",
		"semelMosad": 539916,
		"settlement": "תל אביב - יפו",
		"manager": "אורית מזל ארנון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "צור באהר בנות",
		"semelMosad": 168021,
		"settlement": "ירושלים",
		"manager": "הנאדי יעיש",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "צור באהר בנים",
		"semelMosad": 338491,
		"settlement": "ירושלים",
		"manager": "דועא קאסם",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "צור באהר מכין",
		"semelMosad": 118240,
		"settlement": "ירושלים",
		"manager": "עארף אבו חאמד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "צור הדסה",
		"semelMosad": 136259,
		"settlement": "צור הדסה",
		"manager": "רויטל טויטו-אליקים",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "צור יצחק חדש",
		"semelMosad": 482273,
		"settlement": "צור יצחק",
		"manager": "שרון בראונשטיין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "צור ישראל",
		"semelMosad": 346932,
		"settlement": "פסגות",
		"manager": "חנניא צור",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "צורים",
		"semelMosad": 412783,
		"settlement": "צור הדסה",
		"manager": "רינת סיידוף",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "צין",
		"semelMosad": 611715,
		"settlement": "מדרשת בן גוריון",
		"manager": "עינת עמר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "צליל",
		"semelMosad": 313692,
		"settlement": "חדרה",
		"manager": "סגלית יעקובי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "צליל ים",
		"semelMosad": 348003,
		"settlement": "קרית ים",
		"manager": "אלונה ונט-דוידוב",
		"level": "יסודי בלבד",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "צליל מיתר",
		"semelMosad": 482406,
		"settlement": "ראשון לציון",
		"manager": "אנדראה פלביה לוי",
		"level": "יסודי בלבד",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "צלילי נעם",
		"semelMosad": 113159,
		"settlement": "מבשרת ציון",
		"manager": "לאה בוטבול",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "צלעה",
		"semelMosad": 118349,
		"settlement": "ירושלים",
		"manager": "אדיב נצרה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "צלעה בנות",
		"semelMosad": 419267,
		"settlement": "ירושלים",
		"manager": "מהה שחאדה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "צמח א'",
		"semelMosad": 613000,
		"settlement": "אשדוד",
		"manager": "אירינה מגרילישוילי טיומקין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "צמח דוד",
		"semelMosad": 616557,
		"settlement": "צפת",
		"manager": "דב אלן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "צמח צדיק (רמב\"ם)",
		"semelMosad": 312538,
		"settlement": "טירת כרמל",
		"manager": "מרים אלמליח",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "צמח צדיק ויזניץ",
		"semelMosad": 416297,
		"settlement": "אלעד",
		"manager": "יאיר זכריה ורטהימר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "צמרות",
		"semelMosad": 416594,
		"settlement": "באר יעקב",
		"manager": "גלית-גלדיס דוזלי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "צמרות איילון",
		"semelMosad": 581462,
		"settlement": "תל אביב - יפו",
		"manager": "ליתם גוטמן אדרי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "צמרות איילון",
		"semelMosad": 581322,
		"settlement": "תל אביב - יפו",
		"manager": "נועה שומרוני",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "צעד קדימה",
		"semelMosad": 420968,
		"settlement": "ראשון לציון",
		"manager": "חנה ציק",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "צפורי מד",
		"semelMosad": 512574,
		"settlement": "בת ים",
		"manager": "לילי חגאג",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "צפרירים",
		"semelMosad": 612911,
		"settlement": "אשדוד",
		"manager": "מיכל גל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "צפרירים",
		"semelMosad": 310276,
		"settlement": "חדרה",
		"manager": "עדלה עדי שאול",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ק חינוך אמית עתידים אור עקיבא",
		"semelMosad": 344440,
		"settlement": "אור עקיבא",
		"manager": "אסתר מלכה לארדו",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ק. ח. ע\"ש זאב בוים",
		"semelMosad": 640029,
		"settlement": "קרית גת",
		"manager": "צפורה בן טולילה גוט",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ק. חינוך אורט עפולה",
		"semelMosad": 270041,
		"settlement": "עפולה",
		"manager": "איריס אהרון דוד",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ק.ח אורט יד ליבוביץ",
		"semelMosad": 470096,
		"settlement": "נתניה",
		"manager": "ורד דברת",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ק.חינוך בנ\"ע חולון",
		"semelMosad": 544692,
		"settlement": "חולון",
		"manager": "איתמר רוזנפלד",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ק.יעקב שערי ציון",
		"semelMosad": 512327,
		"settlement": "תל אביב - יפו",
		"manager": "אהרון עשרי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ק.תורנית טכ בית שלמה",
		"semelMosad": 470302,
		"settlement": "רחובות",
		"manager": "דוד בוזגלו",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "קבוצת יבנה",
		"semelMosad": 460113,
		"settlement": "קבוצת יבנה",
		"manager": "יאיר לסלוי",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "קדושת תורה",
		"semelMosad": 366971,
		"settlement": "ירושלים",
		"manager": "שלום יהודה קליין",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "קדימה",
		"semelMosad": 312165,
		"settlement": "קרית ביאליק",
		"manager": "יוסף חלואני",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "קדם",
		"semelMosad": 512509,
		"settlement": "נוקדים",
		"manager": "דוד שמואל יסלזון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "קדם",
		"semelMosad": 615138,
		"settlement": "ערד",
		"manager": "איילת קלכמן שרגאי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "קדם",
		"semelMosad": 411231,
		"settlement": "בארותיים",
		"manager": "אסף לוי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "קדמה ירושלים",
		"semelMosad": 144592,
		"settlement": "ירושלים",
		"manager": "מיכל חדאד",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "קהילה",
		"semelMosad": 560110,
		"settlement": "תל אביב - יפו",
		"manager": "יעל ס\"ט-רשף",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "קהילות יעקב עליון",
		"semelMosad": 410423,
		"settlement": "אלעד",
		"manager": "יעקב סגל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "קהילות יעקב-בני יוסף",
		"semelMosad": 743468,
		"settlement": "בית שמש",
		"manager": "אמיר הוקס",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "קהילת יעקב",
		"semelMosad": 413765,
		"settlement": "אלעד",
		"manager": "אברהם מקלב",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "קהילתי השלום",
		"semelMosad": 112342,
		"settlement": "מבשרת ציון",
		"manager": "סיגל בר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "קהילתי מ\"מ ג' יפיע",
		"semelMosad": 219428,
		"settlement": "יפיע",
		"manager": "פח'ריה כילאני שנאתי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "קול אריה",
		"semelMosad": 373209,
		"settlement": "ירושלים",
		"manager": "צבי דב גרינפלד",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "קול יהודה",
		"semelMosad": 560250,
		"settlement": "בית שמש",
		"manager": "חנן מרדכי פרוכטר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "קול יהודה (מעורב)",
		"semelMosad": 214023,
		"settlement": "חצור הגלילית",
		"manager": "נתנאלה לחיאני",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "קול יעקב",
		"semelMosad": 611426,
		"settlement": "ירוחם",
		"manager": "יונה קנינו",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "קול יעקב",
		"semelMosad": 499863,
		"settlement": "נתיבות",
		"manager": "יצחק רבי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "קוממיות",
		"semelMosad": 510115,
		"settlement": "בני ברק",
		"manager": "רות טייב צוריאל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "קורצאק",
		"semelMosad": 510867,
		"settlement": "רמת גן",
		"manager": "יפית בלנקיט",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "קורצאק",
		"semelMosad": 311894,
		"settlement": "קרית מוצקין",
		"manager": "סיגל המבורג",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "קטמון",
		"semelMosad": 616920,
		"settlement": "ירושלים",
		"manager": "יהודה ברוכי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "קיבוץ גלויות",
		"semelMosad": 411629,
		"settlement": "פתח תקווה",
		"manager": "מירב פלג בס",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "קיהילתי למצויינות בית ברל",
		"semelMosad": 521237,
		"settlement": "בית ברל",
		"manager": "שאדי פדילה",
		"level": "יסודי ועליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "קישון רמת דוד",
		"semelMosad": 220053,
		"settlement": "רמת דוד",
		"manager": "עירית גולן רביב",
		"level": "יסודי בלבד",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "קמפוס יצחק נבון לאמנויות",
		"semelMosad": 512400,
		"settlement": "נתניה",
		"manager": "אפרת דניאל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "קמפוס פרס חדשנות ומנהיגות",
		"semelMosad": 270140,
		"settlement": "יקנעם עילית",
		"manager": "עדה אברכהן שמש",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "קנייני התורה",
		"semelMosad": 541144,
		"settlement": "בני ברק",
		"manager": "מאיר פרץ",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "קנין התורה",
		"semelMosad": 346445,
		"settlement": "בית שמש",
		"manager": "גבריאל ניסנוב",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "קס\"ם-קהילה סביבה מקו",
		"semelMosad": 267468,
		"settlement": "יד השמונה",
		"manager": "תמר שטרן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "קפלן",
		"semelMosad": 411686,
		"settlement": "פתח תקווה",
		"manager": "חוה מתתיהו",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "קפלן",
		"semelMosad": 310284,
		"settlement": "חדרה",
		"manager": "עינב מלר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "קציר",
		"semelMosad": 313635,
		"settlement": "קציר",
		"manager": "ורד חזן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "קציר מ\"מ",
		"semelMosad": 355552,
		"settlement": "מודיעין-מכבים-רעות",
		"manager": "יהודית גרימברג שוורץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "קציר משגב",
		"semelMosad": 640565,
		"settlement": "עצמון שגב",
		"manager": "אסף מצקין",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "קרוב",
		"semelMosad": 450056,
		"settlement": "מפעלי נחם - הר טוב",
		"manager": "ליאת ברשישת",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "קרול",
		"semelMosad": 411660,
		"settlement": "פתח תקווה",
		"manager": "רונן יגר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "קריית החינוך למדעים",
		"semelMosad": 442293,
		"settlement": "רחובות",
		"manager": "הילה שחף",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "קריית חינוך נתיבי נועם דרכא",
		"semelMosad": 444026,
		"settlement": "גדרה",
		"manager": "שמואל חי כורש",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "קרית ארבע בנים",
		"semelMosad": 113969,
		"settlement": "קרית ארבע",
		"manager": "שלמה שמעון לוינגר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "קרית האומנים",
		"semelMosad": 417196,
		"settlement": "רמלה",
		"manager": "גלית אשכנזי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "קרית החינוך אורט עכו",
		"semelMosad": 770859,
		"settlement": "עכו",
		"manager": "איריס גולדנברג",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "קרית החינוך אמי\"ת",
		"semelMosad": 770784,
		"settlement": "חצור הגלילית",
		"manager": "בועז יוספי",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "קרית המתיבתא",
		"semelMosad": 340521,
		"settlement": "חדרה",
		"manager": "שמואל מיכאל ביטון",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "קרית חינוך אשדוד",
		"semelMosad": 644260,
		"settlement": "אשדוד",
		"manager": "אברהם דולינגר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "קרית חינוך בן גוריון",
		"semelMosad": 480046,
		"settlement": "מדרשת רופין",
		"manager": "יהודה אטיאס",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "קרית חינוך דרור",
		"semelMosad": 844076,
		"settlement": "בני דרור",
		"manager": "אריאלה בן ארי",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "קרית חינוך יפו",
		"semelMosad": 540179,
		"settlement": "תל אביב - יפו",
		"manager": "עינת גבע",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "קרית חינוך לבנים",
		"semelMosad": 613281,
		"settlement": "אשדוד",
		"manager": "אברהם ישראל ליבוביץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "קרית חינוך ע\"ש גינסבורג",
		"semelMosad": 470963,
		"settlement": "יבנה",
		"manager": "טל דרור",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "קרית חינוך ע\"ש רבין",
		"semelMosad": 444836,
		"settlement": "גן יבנה",
		"manager": "מרים שפונט",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "קרית חינוך-ק. מלאכי",
		"semelMosad": 644757,
		"settlement": "קרית מלאכי",
		"manager": "יהודה שרגא עמית",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "קרית חנוך אורט",
		"semelMosad": 370312,
		"settlement": "קרית ביאליק",
		"manager": "רמי פרץ פורת",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "קרית יעקב הרצוג",
		"semelMosad": 444067,
		"settlement": "כפר סבא",
		"manager": "אליקים שיוביץ",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "קרית יערים",
		"semelMosad": 120238,
		"settlement": "קרית יערים(מוסד)",
		"manager": "ענבל נחמד",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "קרית יערים",
		"semelMosad": 513317,
		"settlement": "רמת השרון",
		"manager": "עידית לוינץ גילאי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "קרית נוער ירושלים",
		"semelMosad": 140129,
		"settlement": "ירושלים",
		"manager": "דורון טייב",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "קרית עמל",
		"semelMosad": 311530,
		"settlement": "קרית טבעון",
		"manager": "טלי דלויה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "קרית רבין ממ' חדש",
		"semelMosad": 414888,
		"settlement": "נתניה",
		"manager": "אורנית ארדיטי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "קרלין סטולין",
		"semelMosad": 186387,
		"settlement": "מודיעין עילית",
		"manager": "עמרם בריזל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "קרלין סטולין",
		"semelMosad": 513804,
		"settlement": "בני ברק",
		"manager": "אברהם ישראל גומבו",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "קרן אור",
		"semelMosad": 124024,
		"settlement": "ירושלים",
		"manager": "אורנית שטיין",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "קשת",
		"semelMosad": 573097,
		"settlement": "תל אביב - יפו",
		"manager": "יעל פישר אביטן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "קשת",
		"semelMosad": 244632,
		"settlement": "נוף הגליל",
		"manager": "יעל גולדמן",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "קשת",
		"semelMosad": 614958,
		"settlement": "אשדוד",
		"manager": "לילך לוי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "קשת",
		"semelMosad": 623892,
		"settlement": "באר שבע",
		"manager": "קלרטה לב",
		"level": "יסודי בלבד",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "קשת",
		"semelMosad": 172189,
		"settlement": "מודיעין-מכבים-רעות",
		"manager": "קרן מרקוביץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "קשת",
		"semelMosad": 442475,
		"settlement": "גדרה",
		"manager": "ימימה מיארה דנן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "קשת",
		"semelMosad": 414508,
		"settlement": "כוכב יאיר",
		"manager": "מאיה גרנט",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "קשת",
		"semelMosad": 456921,
		"settlement": "רמלה",
		"manager": "אסתר ישראלי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "קשת",
		"semelMosad": 320549,
		"settlement": "ברקאי",
		"manager": "דליה דור",
		"level": "יסודי בלבד",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "קשת",
		"semelMosad": 353037,
		"settlement": "זכרון יעקב",
		"manager": "בלהה בן דוד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "קשת בגליל",
		"semelMosad": 384602,
		"settlement": "כדורי",
		"manager": "ינון קמון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "קשת ברנקו וייס",
		"semelMosad": 441568,
		"settlement": "מזכרת בתיה",
		"manager": "ידידיה יונתן עציון",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "קשת התקווה",
		"semelMosad": 210104,
		"settlement": "אליקים",
		"manager": "שפרה טולדנו צפריר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "קשת יונתן",
		"semelMosad": 210302,
		"settlement": "בית שאן",
		"manager": "אלירז אגא מזרחי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "קשת ראם ע\"ש אליס סליגסברג",
		"semelMosad": 170092,
		"settlement": "ירושלים",
		"manager": "גלית קמיניץ",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "קשת שהם",
		"semelMosad": 414938,
		"settlement": "שוהם",
		"manager": "אילה קופלוביץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "קשת שער הנגב",
		"semelMosad": 616805,
		"settlement": "גבים",
		"manager": "לימור דרמון אביטאן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ראס אל עמוד לבנים",
		"semelMosad": 540567,
		"settlement": "ירושלים",
		"manager": "שדא מחמוד",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "ראס אלעין עראבה",
		"semelMosad": 219832,
		"settlement": "עראבה",
		"manager": "ופיקה נסאר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "ראס אלעמוד ע\"ס לבנות",
		"semelMosad": 440784,
		"settlement": "ירושלים",
		"manager": "אריג' ארנאוט",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "ראס עלי ואלחואלד",
		"semelMosad": 218974,
		"settlement": "ראס עלי",
		"manager": "מיסון כעביה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "ראשדיה",
		"semelMosad": 148163,
		"settlement": "ירושלים",
		"manager": "אסעד עוויס",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "ראשונים",
		"semelMosad": 411256,
		"settlement": "נס ציונה",
		"manager": "סגלית שגב-שמעון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ראשונים",
		"semelMosad": 510214,
		"settlement": "בת ים",
		"manager": "עינת חוה ולדמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ראשית",
		"semelMosad": 183194,
		"settlement": "ירושלים",
		"manager": "תמר פרידמן-בן-שלום",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ראשית",
		"semelMosad": 186395,
		"settlement": "אלון שבות",
		"manager": "נטע נאמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ראשית חכמה",
		"semelMosad": 442434,
		"settlement": "אלעד",
		"manager": "חיים-יוסף אורלן",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ראשית חכמה",
		"semelMosad": 729426,
		"settlement": "ביתר עילית",
		"manager": "יהודית הרשלר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "רב תחומי כסייפה",
		"semelMosad": 800037,
		"settlement": "כסיפה",
		"manager": "נאיף אבו רביעה",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "רב תחומי לקיה",
		"semelMosad": 648097,
		"settlement": "לקיה",
		"manager": "יוסף אבו קרן",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "רב תחומי עמל א' פ\"ת",
		"semelMosad": 470070,
		"settlement": "פתח תקווה",
		"manager": "מרים גלינה ענני",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "רב תחומי עמל ב' פ\"ת",
		"semelMosad": 470591,
		"settlement": "פתח תקווה",
		"manager": "סגלית רוזן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "רב תחומי עמל בגין צפת",
		"semelMosad": 270025,
		"settlement": "צפת",
		"manager": "עודד זהבי",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "רב תחומי עמל דבוריה",
		"semelMosad": 800128,
		"settlement": "דבוריה",
		"manager": "מוחמד יוסף",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "רב תחומי עמל הדקלים תל שבע",
		"semelMosad": 678011,
		"settlement": "תל שבע",
		"manager": "סאמח אבו עסא",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "רב תחומי עמל הולץ חיל האויר",
		"semelMosad": 570044,
		"settlement": "תל אביב - יפו",
		"manager": "שרית מלמד",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "רב תחומי עמל זרזיר",
		"semelMosad": 248666,
		"settlement": "זרזיר",
		"manager": "פואד גדיר",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "רב תחומי עמל טייבה",
		"semelMosad": 478016,
		"settlement": "טייבה",
		"manager": "הלאל מסארוה",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "רב תחומי עמל יגאל אלון רמלה",
		"semelMosad": 470047,
		"settlement": "רמלה",
		"manager": "ברק מנשה פרידמן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "רב תחומי עמל עילוט",
		"semelMosad": 249300,
		"settlement": "עילוט",
		"manager": "ראניה מנסור",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "רב תחומי עמל תל שבע א'",
		"semelMosad": 800144,
		"settlement": "תל שבע",
		"manager": "גאסר אבו סולב",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "רב תחומי עמל תלמים ירושלים",
		"semelMosad": 440446,
		"settlement": "ירושלים",
		"manager": "אסתר הדסה רוזמן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "רבי עקיבא",
		"semelMosad": 310029,
		"settlement": "אור עקיבא",
		"manager": "ארז יהונתן קהלני",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "רביבים",
		"semelMosad": 510545,
		"settlement": "חולון",
		"manager": "ג'וסלין-נילי קורן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "רבקה גובר",
		"semelMosad": 610857,
		"settlement": "נהורה",
		"manager": "תמר לזרוביץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "רגבה אזורי",
		"semelMosad": 212159,
		"settlement": "רגבה",
		"manager": "עדי הראל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "רגבים",
		"semelMosad": 610055,
		"settlement": "אמונים",
		"manager": "בלה רוזנברג",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "רגבים",
		"semelMosad": 645416,
		"settlement": "אופקים",
		"manager": "מור אבירם",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "רגבים בגולן",
		"semelMosad": 240630,
		"settlement": "רמת מגשימים",
		"manager": "לאה קלינשפיז",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "רגבים בנגב",
		"semelMosad": 550004,
		"settlement": "בני נצרים",
		"manager": "יוסף בוסקילה",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "רגבים בנימין",
		"semelMosad": 748574,
		"settlement": "מעלה מכמש",
		"manager": "אלנתן בן יעקב",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "רגבים בעמק",
		"semelMosad": 713594,
		"settlement": "טירת צבי",
		"manager": "עוזי אברהם רמון",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "רהאם",
		"semelMosad": 228650,
		"settlement": "מג'ד אל-כרום",
		"manager": "סאלח אלחאג'",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "רוואד אלקודס",
		"semelMosad": 641415,
		"settlement": "ירושלים",
		"manager": "נארימן אלתאג'י",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "רוזן",
		"semelMosad": 412981,
		"settlement": "ראשון לציון",
		"manager": "עליזה בלנק",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "רוממה",
		"semelMosad": 312082,
		"settlement": "חיפה",
		"manager": "דפנה בוננה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "רון ארד",
		"semelMosad": 474460,
		"settlement": "רחובות",
		"manager": "קרן קריינדל שחל",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "רונה רמון",
		"semelMosad": 384453,
		"settlement": "חריש",
		"manager": "יגאל אריכא",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "רונה רמון",
		"semelMosad": 482760,
		"settlement": "רמלה",
		"manager": "מיכלי פרוח אבן חיים",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "רונה רמון",
		"semelMosad": 384537,
		"settlement": "נהריה",
		"manager": "תמי דניאל",
		"level": "יסודי וחט\"ב",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "רועי קליין",
		"semelMosad": 417121,
		"settlement": "נתניה",
		"manager": "אלישיב סגל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "רוקח",
		"semelMosad": 511642,
		"settlement": "תל אביב - יפו",
		"manager": "יונתן בוימפלד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "רותם",
		"semelMosad": 612515,
		"settlement": "אשקלון",
		"manager": "רינת-שמחה נחמני",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "רותם",
		"semelMosad": 713685,
		"settlement": "מצפה רמון",
		"manager": "עמיחי הס",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "רזיאל",
		"semelMosad": 413922,
		"settlement": "נתניה",
		"manager": "מירב לוי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "רחל המשוררת",
		"semelMosad": 482075,
		"settlement": "כפר סבא",
		"manager": "מיכל עזרא",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ריאד אלסלאחין",
		"semelMosad": 420646,
		"settlement": "ירושלים",
		"manager": "ספאא אבו שריף",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "ריימונד לאוואן",
		"semelMosad": 542092,
		"settlement": "מקווה ישראל",
		"manager": "רות בן סמיאן",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ריינה א",
		"semelMosad": 219311,
		"settlement": "ריינה",
		"manager": "קאסם מוסא",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "ריינה ב",
		"semelMosad": 218990,
		"settlement": "ריינה",
		"manager": "סופיאן מוסא",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "רימון",
		"semelMosad": 421107,
		"settlement": "לוד",
		"manager": "לונה בוקובזה",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "רימון",
		"semelMosad": 739045,
		"settlement": "פרדס חנה-כרכור",
		"manager": "יותם אריאל קלו ליבנה",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "רימון",
		"semelMosad": 482521,
		"settlement": "פרדסיה",
		"manager": "טל מרמור",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "רימון",
		"semelMosad": 346734,
		"settlement": "פרדס חנה-כרכור",
		"manager": "נועה יזדי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "רימון",
		"semelMosad": 416487,
		"settlement": "כפר יונה",
		"manager": "גלית פישר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "רימונים",
		"semelMosad": 512970,
		"settlement": "קרית אונו",
		"manager": "נועה ולבר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "רימונים",
		"semelMosad": 311522,
		"settlement": "קרית טבעון",
		"manager": "אוראל גוזף נאמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "רימונים",
		"semelMosad": 416370,
		"settlement": "גדרה",
		"manager": "רתם גורן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "רימונים",
		"semelMosad": 420141,
		"settlement": "עפולה",
		"manager": "דורית חן",
		"level": "חט\"ב + עליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "רימונים",
		"semelMosad": 610485,
		"settlement": "אשדוד",
		"manager": "חלי עסיס",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "רימונים",
		"semelMosad": 620195,
		"settlement": "באר שבע",
		"manager": "רונית פז",
		"level": "יסודי בלבד",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "רימונים כפר כנא",
		"semelMosad": 228312,
		"settlement": "כפר כנא",
		"manager": "סחר עומרי טאהא",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "רימלט",
		"semelMosad": 414870,
		"settlement": "נתניה",
		"manager": "ירון שמש",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "רינה אתגרים חינוכיים",
		"semelMosad": 372896,
		"settlement": "נצרת",
		"manager": "נאילה חאמד",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "רמב\"ם",
		"semelMosad": 613497,
		"settlement": "קרית גת",
		"manager": "יצחק ויינברגר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "רמב\"ם",
		"semelMosad": 610204,
		"settlement": "אשקלון",
		"manager": "יצחק בריגה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "רמב\"ם",
		"semelMosad": 610378,
		"settlement": "באר שבע",
		"manager": "אלירן נחום",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "רמבם",
		"semelMosad": 310870,
		"settlement": "חיפה",
		"manager": "גאולה גילה חדד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "רמבם מד",
		"semelMosad": 510461,
		"settlement": "הרצליה",
		"manager": "אורית צדוק",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "רמבם מד-בית שאן",
		"semelMosad": 212795,
		"settlement": "בית שאן",
		"manager": "דוד ישראל אדרי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "רמבם מד-נהריה",
		"semelMosad": 211532,
		"settlement": "נהריה",
		"manager": "מוריה שוקרון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "רמבם מד-עכו",
		"semelMosad": 211847,
		"settlement": "עכו",
		"manager": "יהונתן רז",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "רמבם מד-קרית שמונה",
		"semelMosad": 212126,
		"settlement": "קרית שמונה",
		"manager": "רחל הוד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "רמון",
		"semelMosad": 415992,
		"settlement": "באר יעקב",
		"manager": "ענת נעמה לוי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "רמון",
		"semelMosad": 610790,
		"settlement": "מצפה רמון",
		"manager": "פני סעדון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "רמון",
		"semelMosad": 416578,
		"settlement": "רחובות",
		"manager": "קרן רוזנטל כהן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "רמון ע\"ש אילן רונה ואסף רמון",
		"semelMosad": 211185,
		"settlement": "מגדל העמק",
		"manager": "רינת שפטניצקי זמיר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "רמות",
		"semelMosad": 614081,
		"settlement": "באר שבע",
		"manager": "שרית הינדי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "רמות",
		"semelMosad": 310755,
		"settlement": "חיפה",
		"manager": "עמית מיטנר דורון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "רמות ויצמן",
		"semelMosad": 410787,
		"settlement": "יבנה",
		"manager": "עמליה סויסא",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "רמות זבולון",
		"semelMosad": 729012,
		"settlement": "אבטין",
		"manager": "יוסרא זובידאת",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "רמות ים",
		"semelMosad": 470443,
		"settlement": "מכמורת",
		"manager": "אשר קסו",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "רמות ממ\"ד ב'",
		"semelMosad": 112219,
		"settlement": "ירושלים",
		"manager": "אורלי לרר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "רמות ממלכתי ג",
		"semelMosad": 112201,
		"settlement": "ירושלים",
		"manager": "לבנה ליבי מילון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "רמות רכס",
		"semelMosad": 615104,
		"settlement": "באר שבע",
		"manager": "קרן ווקנין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "רמז",
		"semelMosad": 410621,
		"settlement": "יהוד-מונוסון",
		"manager": "ורד אחידוב עזריאל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "רמז",
		"semelMosad": 410894,
		"settlement": "כפר סבא",
		"manager": "פנינה קוזניץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "רמז",
		"semelMosad": 411918,
		"settlement": "ראשון לציון",
		"manager": "לימור חכמון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "רמז -נהריה",
		"semelMosad": 211516,
		"settlement": "נהריה",
		"manager": "מזל קורקוס",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "רמת אביב",
		"semelMosad": 513341,
		"settlement": "תל אביב - יפו",
		"manager": "יגאל יערן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "רמת אהרון-אור מרדכי",
		"semelMosad": 514190,
		"settlement": "בני ברק",
		"manager": "רפאל בלוך",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "רמת אפעל",
		"semelMosad": 512954,
		"settlement": "רמת גן",
		"manager": "אופיר סליפוי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "רמת הדסה",
		"semelMosad": 344085,
		"settlement": "קרית טבעון",
		"manager": "טל שמעון",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "רמת החיל",
		"semelMosad": 511667,
		"settlement": "תל אביב - יפו",
		"manager": "אילן קרן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "רמת הנשיא",
		"semelMosad": 383935,
		"settlement": "חיפה",
		"manager": "מאור שניר",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "רמת חן ע\"ש א. רמון",
		"semelMosad": 510842,
		"settlement": "רמת גן",
		"manager": "רונית איבשיץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "רמת מודיעין",
		"semelMosad": 112813,
		"settlement": "חשמונאים",
		"manager": "ברוריה-ברינדל לדרר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "רמת מוריה",
		"semelMosad": 112565,
		"settlement": "ירושלים",
		"manager": "ניצה אלעזריי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "רנד",
		"semelMosad": 228494,
		"settlement": "אכסאל",
		"manager": "זיאד עומרי",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "רנד",
		"semelMosad": 359174,
		"settlement": "אכסאל",
		"manager": "אריג' ספדי דראושה",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "רנד אבו גוש לחנ\"מ",
		"semelMosad": 420745,
		"settlement": "אבו גוש",
		"manager": "ספא נאסר עווד",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "רנד טירה",
		"semelMosad": 428086,
		"settlement": "טירה",
		"manager": "היבה בשארה",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "רנה קסין דרכא",
		"semelMosad": 140087,
		"settlement": "ירושלים",
		"manager": "שגיא כהן",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "רנה של תורה",
		"semelMosad": 460162,
		"settlement": "כרמיאל",
		"manager": "אברהם ויסבלום",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "רננות",
		"semelMosad": 613927,
		"settlement": "באר שבע",
		"manager": "פנינה כהן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "רננים",
		"semelMosad": 320317,
		"settlement": "זכרון יעקב",
		"manager": "שני עמל",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "רננים",
		"semelMosad": 414441,
		"settlement": "ראשון לציון",
		"manager": "גלית זיו",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "רננים",
		"semelMosad": 612747,
		"settlement": "אשדוד",
		"manager": "שרון סטר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "רננים קרית שמונה",
		"semelMosad": 220046,
		"settlement": "קרית שמונה",
		"manager": "רחל מזרחי",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "רנסנס",
		"semelMosad": 362046,
		"settlement": "ירושלים",
		"manager": "שירין ג'בר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "רעות",
		"semelMosad": 416016,
		"settlement": "גדרה",
		"manager": "דנית אדלמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "רעות",
		"semelMosad": 544148,
		"settlement": "הרצליה",
		"manager": "סמדר נחמיאס",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "רעות",
		"semelMosad": 411066,
		"settlement": "הוד השרון",
		"manager": "טל אהרוני",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "רעות",
		"semelMosad": 413302,
		"settlement": "ראשון לציון",
		"manager": "אילנה שורץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "רעות -גני דן",
		"semelMosad": 415935,
		"settlement": "רמלה",
		"manager": "מרים קופרמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "רעות קהילה דתית",
		"semelMosad": 160416,
		"settlement": "ירושלים",
		"manager": "מתן ראבד",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "רעות-טבריה",
		"semelMosad": 210732,
		"settlement": "טבריה",
		"manager": "נפתלי טורזמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "רעים",
		"semelMosad": 612333,
		"settlement": "אשדוד",
		"manager": "ירון שניידר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "רעים",
		"semelMosad": 120592,
		"settlement": "ירושלים",
		"manager": "שרה לאה בורנשטיין ממן",
		"level": "יסודי בלבד",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "רעים",
		"semelMosad": 620559,
		"settlement": "באר שבע",
		"manager": "סיגלית סימה מזרחי",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "רעים",
		"semelMosad": 214684,
		"settlement": "שמשית",
		"manager": "שלומית גייסט",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "רקפות",
		"semelMosad": 482596,
		"settlement": "ראשון לציון",
		"manager": "זיוה זוהרה טויטו",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "רקפת-כרמיאל",
		"semelMosad": 214163,
		"settlement": "כרמיאל",
		"manager": "לידה נחום",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "רש\"י בנות",
		"semelMosad": 514976,
		"settlement": "תל אביב - יפו",
		"manager": "נחמה לאה טרבולסי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "רש\"ש",
		"semelMosad": 417964,
		"settlement": "אחיעזר",
		"manager": "מרדכי מעודה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "רשב\"י",
		"semelMosad": 414946,
		"settlement": "לוד",
		"manager": "עדית אהובה קוביוף",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "רשב\"י",
		"semelMosad": 613182,
		"settlement": "קרית גת",
		"manager": "יסכה בת-אל סמימי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "רתמים",
		"semelMosad": 614156,
		"settlement": "אשדוד",
		"manager": "שושנה בכור",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ש\"י עגנון",
		"semelMosad": 410134,
		"settlement": "כפר סבא",
		"manager": "מיכל בר משה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שבות ישראל",
		"semelMosad": 759688,
		"settlement": "אפרת",
		"manager": "יעקב אלמלך",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שבט סופר",
		"semelMosad": 212761,
		"settlement": "חצור הגלילית",
		"manager": "חנן דוד פלג",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שביט",
		"semelMosad": 416495,
		"settlement": "רחובות",
		"manager": "מירב בכר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שבילי אית\"ם",
		"semelMosad": 640102,
		"settlement": "אופקים",
		"manager": "ישראל סבן",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שבילי בינה",
		"semelMosad": 633198,
		"settlement": "בית שמש",
		"manager": "אסתר פיגא ביננשטוק",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שבילי דעת",
		"semelMosad": 560565,
		"settlement": "ביתר עילית",
		"manager": "חיה רוזה גילקרוב",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שבילי התורה",
		"semelMosad": 523431,
		"settlement": "ירושלים",
		"manager": "משה יעקב ברמן",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שבילי רעות",
		"semelMosad": 384313,
		"settlement": "עין שמר",
		"manager": "רועי טפליץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שבילים",
		"semelMosad": 620278,
		"settlement": "גבעת זאב",
		"manager": "ורד שושנה אטשקה",
		"level": "יסודי חט\"ב ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שבילים",
		"semelMosad": 520213,
		"settlement": "תל אביב - יפו",
		"manager": "אסנת פדידה",
		"level": "יסודי בלבד",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שבילים ע\"ש בגין",
		"semelMosad": 384305,
		"settlement": "פרדס חנה-כרכור",
		"manager": "רות שמרון",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שבילים ע\"ש בגין",
		"semelMosad": 367730,
		"settlement": "פרדס חנה-כרכור",
		"manager": "גל אורון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שדא אלוורד",
		"semelMosad": 338533,
		"settlement": "ירושלים",
		"manager": "סרין סאפי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "שדה אליהו משותף דתי",
		"semelMosad": 212274,
		"settlement": "שדה אליהו",
		"manager": "אוסנת אבני",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שדה יעקב מד",
		"semelMosad": 212290,
		"settlement": "שדה יעקב",
		"manager": "שרה אסתר רפפורט",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שדה צופים",
		"semelMosad": 512095,
		"settlement": "מעלות-תרשיחא",
		"manager": "לירון כהן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שדות",
		"semelMosad": 475608,
		"settlement": "נס ציונה",
		"manager": "אביטל סובל רויף",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שדות",
		"semelMosad": 613133,
		"settlement": "באר טוביה",
		"manager": "דורית דוריאן אלוני",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שדות",
		"semelMosad": 616730,
		"settlement": "שדה אליהו",
		"manager": "לוית ראובני",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שדות",
		"semelMosad": 384149,
		"settlement": "פרדס חנה-כרכור",
		"manager": "טלמור קלוס",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שדות איילון",
		"semelMosad": 414797,
		"settlement": "בית חשמונאי",
		"manager": "אילן שתם",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שדות אשכול",
		"semelMosad": 610741,
		"settlement": "מגן",
		"manager": "קרולינה ארם",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שדות יואב",
		"semelMosad": 614529,
		"settlement": "גת (קיבוץ)",
		"manager": "שלומית אבודרם",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שדות נגב",
		"semelMosad": 633461,
		"settlement": "בית הגדי",
		"manager": "שמעון שוקרון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שדות סילבר",
		"semelMosad": 616276,
		"settlement": "כפר סילבר",
		"manager": "עליזה בנלולו",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שדי חמד",
		"semelMosad": 111716,
		"settlement": "מעלה אדומים",
		"manager": "יסכה בן סימון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שדמה",
		"semelMosad": 482794,
		"settlement": "רחובות",
		"manager": "טל שדה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שהם",
		"semelMosad": 430298,
		"settlement": "עפולה",
		"manager": "מיטל הלן גונן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שובו",
		"semelMosad": 238113,
		"settlement": "עכו",
		"manager": "תרצה אינהורן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שובו",
		"semelMosad": 512202,
		"settlement": "בת ים",
		"manager": "אסתר מושייב",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שובו - מעורב",
		"semelMosad": 214700,
		"settlement": "נוף הגליל",
		"manager": "שרה-חנה נדר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שובו אשדוד",
		"semelMosad": 614859,
		"settlement": "אשדוד",
		"manager": "מלכה שינין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שובו לוד-מעורב",
		"semelMosad": 412114,
		"settlement": "לוד",
		"manager": "מיכל הומינר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שובו מיטב נתניה",
		"semelMosad": 756619,
		"settlement": "נתניה",
		"manager": "ישי שולמן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שובו מיטב פ\"ת",
		"semelMosad": 729533,
		"settlement": "פתח תקווה",
		"manager": "אליהו בר טל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שובו מיטב ראשלצ",
		"semelMosad": 756205,
		"settlement": "ראשון לציון",
		"manager": "דניאל בר טל",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שובו ניצנים-מעורב",
		"semelMosad": 614693,
		"settlement": "אשקלון",
		"manager": "יצחק לוי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שובו נתניה-מעורב",
		"semelMosad": 416099,
		"settlement": "נתניה",
		"manager": "אנה שילקרוט",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שובו ראשון לציון",
		"semelMosad": 416206,
		"settlement": "ראשון לציון",
		"manager": "חיה פלדמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שובו רחובות מעורב",
		"semelMosad": 416222,
		"settlement": "רחובות",
		"manager": "דבורה אהרון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שובו תל אביב",
		"semelMosad": 514760,
		"settlement": "תל אביב - יפו",
		"manager": "לאה חברוני",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שובו-מעורב",
		"semelMosad": 312884,
		"settlement": "חדרה",
		"manager": "בת-שבע ידידיה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שובו-מעורב",
		"semelMosad": 413021,
		"settlement": "פתח תקווה",
		"manager": "בת שבע קפלר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שובו-מעורב",
		"semelMosad": 160135,
		"settlement": "בית שמש",
		"manager": "אסתר מלכה אקרמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שובו-מעורב",
		"semelMosad": 613034,
		"settlement": "באר שבע",
		"manager": "יהודא לייב ווייטמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שועפט בנות ב'",
		"semelMosad": 148254,
		"settlement": "ירושלים",
		"manager": "מיסון חלאק",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "שועפט בנים",
		"semelMosad": 540625,
		"settlement": "ירושלים",
		"manager": "אדיב ח'לאילה",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "שורשים",
		"semelMosad": 411421,
		"settlement": "נתניה",
		"manager": "אורלי איבי בן-אדרת",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שורשים",
		"semelMosad": 482711,
		"settlement": "מדרשת רופין",
		"manager": "גלית יוסף",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שורשים",
		"semelMosad": 412809,
		"settlement": "נטור",
		"manager": "יונתן ברוך הירש",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שושנה דמארי",
		"semelMosad": 581447,
		"settlement": "תל אביב - יפו",
		"manager": "נורית גבע",
		"level": "יסודי בלבד",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שושנים לבנות",
		"semelMosad": 614297,
		"settlement": "אופקים",
		"manager": "אושרית כרמי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שז\"ר",
		"semelMosad": 612556,
		"settlement": "אשדוד",
		"manager": "חגית דהן פנחס",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שזר",
		"semelMosad": 413252,
		"settlement": "נתניה",
		"manager": "ורד עזריה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שזר",
		"semelMosad": 513135,
		"settlement": "חולון",
		"manager": "נועה שועלי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שזר",
		"semelMosad": 513333,
		"settlement": "הרצליה",
		"manager": "הדר טימור-עוזר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שזר",
		"semelMosad": 413435,
		"settlement": "רחובות",
		"manager": "רויטל ברקאי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שחף",
		"semelMosad": 520585,
		"settlement": "בת ים",
		"manager": "עינת נהרי קרקו",
		"level": "חט\"ב + עליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שחף",
		"semelMosad": 410647,
		"settlement": "שוהם",
		"manager": "עומר נאור",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שחפים",
		"semelMosad": 581389,
		"settlement": "נתניה",
		"manager": "נירית כהן",
		"level": "חט\"ב + עליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שחקים",
		"semelMosad": 412304,
		"settlement": "אורנית",
		"manager": "נורית פיטוסי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שחקים",
		"semelMosad": 641449,
		"settlement": "אשדוד",
		"manager": "כרמית חואקין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שחקים",
		"semelMosad": 480202,
		"settlement": "סתריה",
		"manager": "איילה אפרתי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שחקים",
		"semelMosad": 581215,
		"settlement": "חולון",
		"manager": "יסמין לסאג' דרגן",
		"level": "חט\"ב + עליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שחר",
		"semelMosad": 612440,
		"settlement": "אשדוד",
		"manager": "רמי רחמים כהן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שחר",
		"semelMosad": 526921,
		"settlement": "בת ים",
		"manager": "יוסי אברהם",
		"level": "יסודי וחט\"ב",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שחר",
		"semelMosad": 520114,
		"settlement": "פתח תקווה",
		"manager": "חדוה כהן",
		"level": "יסודי בלבד",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שחר אשכול",
		"semelMosad": 613646,
		"settlement": "מגן",
		"manager": "אלכסנדרה קופץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שחר בוגרים",
		"semelMosad": 725689,
		"settlement": "פתח תקווה",
		"manager": "מיכל קרון",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שחר-ע'ש קלור ז\"ל",
		"semelMosad": 620104,
		"settlement": "באר שבע",
		"manager": "רונית ססונקר",
		"level": "יסודי חט\"ב ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שיבולים",
		"semelMosad": 482109,
		"settlement": "נס ציונה",
		"manager": "חלי סמרה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שיבולים",
		"semelMosad": 616243,
		"settlement": "מודיעין-מכבים-רעות",
		"manager": "מירב משולם",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שיזף",
		"semelMosad": 514463,
		"settlement": "אור יהודה",
		"manager": "סיון יהודית אלעד גוטליב",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שיח",
		"semelMosad": 511972,
		"settlement": "בית העמק",
		"manager": "שלמה דן לסרי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שיח יצחק",
		"semelMosad": 541599,
		"settlement": "בני ברק",
		"manager": "יעקב משה שבקס",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שיח סוד - רמת שלמה",
		"semelMosad": 631911,
		"settlement": "ירושלים",
		"manager": "משה בלר",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שיח סוד -רכס",
		"semelMosad": 631929,
		"settlement": "ירושלים",
		"manager": "אברהם ישעיהו שכטר",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שיטים",
		"semelMosad": 612242,
		"settlement": "ספיר",
		"manager": "רז לחוביצר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שילה",
		"semelMosad": 612408,
		"settlement": "אשדוד",
		"manager": "נעמה גרציה סט",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שילה",
		"semelMosad": 310342,
		"settlement": "חדרה",
		"manager": "אליסף נוימן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שילה מד",
		"semelMosad": 512962,
		"settlement": "קרית אונו",
		"manager": "רמה אלקיים",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שיפר",
		"semelMosad": 413039,
		"settlement": "פתח תקווה",
		"manager": "יפעת הירש-ויסמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שיקומי שחפים",
		"semelMosad": 225128,
		"settlement": "עמיר",
		"manager": "ישי אדלר",
		"level": "יסודי חט\"ב ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שיקמים",
		"semelMosad": 421115,
		"settlement": "ראשון לציון",
		"manager": "אורלי לייפר",
		"level": "חט\"ב + עליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שיר חדש",
		"semelMosad": 338145,
		"settlement": "אלעד",
		"manager": "לויס יהודה פייסט",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שירה חדשה",
		"semelMosad": 476366,
		"settlement": "ירושלים",
		"manager": "אברהם קלמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שירה חדשה",
		"semelMosad": 572768,
		"settlement": "בת ים",
		"manager": "ורדה גמליאל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שירת ברכה",
		"semelMosad": 615344,
		"settlement": "שדרות",
		"manager": "אורה סבז",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שירת דבורה",
		"semelMosad": 131169,
		"settlement": "ירושלים",
		"manager": "דבורה קנר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שירת דבורה",
		"semelMosad": 370528,
		"settlement": "צפת",
		"manager": "שינא חוה ציקינובסקי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שירת הגליל",
		"semelMosad": 384610,
		"settlement": "הודיות",
		"manager": "גדי יעקובוביץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שירת הים",
		"semelMosad": 311597,
		"settlement": "קרית ים",
		"manager": "אבנר אוחיון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שירת הים",
		"semelMosad": 615237,
		"settlement": "אשדוד",
		"manager": "אילנה דאי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שירת הכהן",
		"semelMosad": 641563,
		"settlement": "ירושלים",
		"manager": "רחל ברטה ליאון פיינרמן",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שירת הלב",
		"semelMosad": 482034,
		"settlement": "בני דרור",
		"manager": "עינת שרף",
		"level": "יסודי חט\"ב ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שירת התורה",
		"semelMosad": 747733,
		"settlement": "מודיעין עילית",
		"manager": "יחזקאל שרגא רייכמאן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שירת חנה",
		"semelMosad": 541466,
		"settlement": "תל אביב - יפו",
		"manager": "דבורה כהן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שירת חנה",
		"semelMosad": 719005,
		"settlement": "עלי",
		"manager": "סיגל אסתר לשם",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שירת חנן",
		"semelMosad": 131292,
		"settlement": "אלון שבות",
		"manager": "מרים בורוכוב",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שירת מרים",
		"semelMosad": 430199,
		"settlement": "ירושלים",
		"manager": "מליה גולדשטיין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שירת משה",
		"semelMosad": 441774,
		"settlement": "חמד",
		"manager": "חיים מנחם יעקבסון",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שירת שרה",
		"semelMosad": 430256,
		"settlement": "אלעד",
		"manager": "שרה סגל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שכ הפרחים",
		"semelMosad": 384560,
		"settlement": "חריש",
		"manager": "אורית ולדמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שכונה 14",
		"semelMosad": 616516,
		"settlement": "תל שבע",
		"manager": "ג'אבר אבו רקייק",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "שכונת המשתלה",
		"semelMosad": 514521,
		"settlement": "תל אביב - יפו",
		"manager": "רויטל אקב",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שלב חנמ למדעי הסביבה",
		"semelMosad": 175448,
		"settlement": "בית שמש",
		"manager": "נעמה אלוז",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שלהבות",
		"semelMosad": 482851,
		"settlement": "ראשון לציון",
		"manager": "יהודית חיה מושקה גלוכובסקי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שלהבות",
		"semelMosad": 581397,
		"settlement": "נתניה",
		"manager": "גאולה לייאנס",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שלהבות חב\"ד",
		"semelMosad": 760728,
		"settlement": "קרית גת",
		"manager": "נעה נתן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שלהבות חב\"ד",
		"semelMosad": 616474,
		"settlement": "גדרה",
		"manager": "חנה קרניאל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שלהבת",
		"semelMosad": 470583,
		"settlement": "פתח תקווה",
		"manager": "נאוה וסרמן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שלהבת הדרום",
		"semelMosad": 640110,
		"settlement": "דימונה",
		"manager": "לאה ברויער",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שלהבת הדרום(בנות)",
		"semelMosad": 614982,
		"settlement": "דימונה",
		"manager": "שמעונה פרץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שלוה",
		"semelMosad": 310771,
		"settlement": "חיפה",
		"manager": "יובל צרפתי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שלום מד",
		"semelMosad": 510917,
		"settlement": "רמת גן",
		"manager": "ענר ויסברד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שלום עליכם- בצת",
		"semelMosad": 210328,
		"settlement": "בצת",
		"manager": "יפעת שייט משולם",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שלמון",
		"semelMosad": 413195,
		"settlement": "ראשון לציון",
		"manager": "מרית אנה מויאל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שמואל הנגיד",
		"semelMosad": 544130,
		"settlement": "הרצליה",
		"manager": "יהודית חיה לוין",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שמעוני",
		"semelMosad": 413823,
		"settlement": "פתח תקווה",
		"manager": "מלכי מלכה רבינוביץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שמעוני",
		"semelMosad": 510354,
		"settlement": "גבעתיים",
		"manager": "שרון בכור לוינהר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שמרית",
		"semelMosad": 417188,
		"settlement": "נתניה",
		"manager": "אליאן לסמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שנקר",
		"semelMosad": 510594,
		"settlement": "חולון",
		"manager": "ענת גל קוהבלום",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שעפאט לבנים א",
		"semelMosad": 118273,
		"settlement": "ירושלים",
		"manager": "מוחמד אמארה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "שעפט לבנות א",
		"semelMosad": 118281,
		"settlement": "ירושלים",
		"manager": "סוזאן כטיב",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "שער אליעזר בנות",
		"semelMosad": 441022,
		"settlement": "רחובות",
		"manager": "גילה מלכה ראזנבוים",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שער האריות",
		"semelMosad": 513119,
		"settlement": "חולון",
		"manager": "רוית בקרמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שער התלמוד",
		"semelMosad": 724377,
		"settlement": "בני ברק",
		"manager": "יצחק שמעון ברברמן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שער שמעון",
		"semelMosad": 541839,
		"settlement": "תל אביב - יפו",
		"manager": "הודיה מזיאנה בוכריס",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שער שמעון אור תורה",
		"semelMosad": 514851,
		"settlement": "תל אביב - יפו",
		"manager": "מיכל טובה חירארי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שערי אור עקיבא",
		"semelMosad": 758078,
		"settlement": "אור עקיבא",
		"manager": "שלמה יוגדייף",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שערי אשר",
		"semelMosad": 756833,
		"settlement": "תל אביב - יפו",
		"manager": "בנימין אריאל אשר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שערי רחמים",
		"semelMosad": 660332,
		"settlement": "ירושלים",
		"manager": "חנניה אזולאי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שערי רקיע",
		"semelMosad": 762096,
		"settlement": "ירושלים",
		"manager": "יוסף אפרים גלנץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שערי תבונה",
		"semelMosad": 715797,
		"settlement": "אלעד",
		"manager": "אהרן חדד",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שערי תורה",
		"semelMosad": 160358,
		"settlement": "ירושלים",
		"manager": "חיים יעקב ווייל",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שערי תלמוד",
		"semelMosad": 560433,
		"settlement": "ביתר עילית",
		"manager": "שמעון סירוטה",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שפיר",
		"semelMosad": 610550,
		"settlement": "מרכז שפירא",
		"manager": "ארז בעל צדקה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שפירא",
		"semelMosad": 511675,
		"settlement": "תל אביב - יפו",
		"manager": "יעקב יצחק",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שפע חיים צאנז",
		"semelMosad": 748699,
		"settlement": "אלעד",
		"manager": "אברהם פרנקל",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שפרינצק",
		"semelMosad": 411991,
		"settlement": "רחובות",
		"manager": "אוסנת אור שי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שפרינצק",
		"semelMosad": 311480,
		"settlement": "קרית אתא",
		"manager": "גלית תורגמן בנלולו",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שפרינצק",
		"semelMosad": 611020,
		"settlement": "קרית גת",
		"manager": "ורדה לוי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שפרירים ב' חטיבת ביניים",
		"semelMosad": 770214,
		"settlement": "גבעת חיים (איחוד)",
		"manager": "חבצלת זהר הרגיל",
		"level": "חט\"ב בלבד",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שפתי כהן",
		"semelMosad": 722009,
		"settlement": "אשדוד",
		"manager": "יעקב חיים כהן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שפתי רננות",
		"semelMosad": 615351,
		"settlement": "נתיבות",
		"manager": "אושרת בלו",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שקד",
		"semelMosad": 414334,
		"settlement": "נס ציונה",
		"manager": "שירלי סלמון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שקד",
		"semelMosad": 614115,
		"settlement": "אשדוד",
		"manager": "קרן רבקה סבג בארי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שקד",
		"semelMosad": 620351,
		"settlement": "אשקלון",
		"manager": "מינה מרים יוספי",
		"level": "יסודי חט\"ב ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שקד",
		"semelMosad": 312769,
		"settlement": "קרית טבעון",
		"manager": "מיכל אפרים",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שקד -לב הפארק",
		"semelMosad": 415885,
		"settlement": "רעננה",
		"manager": "אסנת שניידר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שקמה",
		"semelMosad": 415612,
		"settlement": "ראשון לציון",
		"manager": "רינת שטינמץ - ולטר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שקמים",
		"semelMosad": 611160,
		"settlement": "שדרות",
		"manager": "אילה חדד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שרון",
		"semelMosad": 515445,
		"settlement": "חולון",
		"manager": "רות קרן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שרונה",
		"semelMosad": 482422,
		"settlement": "כפר יונה",
		"manager": "עירית קציר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שריד",
		"semelMosad": 414094,
		"settlement": "רחובות",
		"manager": "ורד שגב",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שריד -שגיא",
		"semelMosad": 212415,
		"settlement": "שריד",
		"manager": "אורלי להב",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שרשים מד",
		"semelMosad": 512038,
		"settlement": "תל אביב - יפו",
		"manager": "אליאור תורג'מן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שרת",
		"semelMosad": 512988,
		"settlement": "בת ים",
		"manager": "ענת שמחה עבאדי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שרת",
		"semelMosad": 511451,
		"settlement": "תל אביב - יפו",
		"manager": "שרון גונן זיידל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שרת",
		"semelMosad": 312199,
		"settlement": "פרדס חנה-כרכור",
		"manager": "רון זיו",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שרת",
		"semelMosad": 413062,
		"settlement": "רמלה",
		"manager": "איריס עידו-מסטאי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שרת",
		"semelMosad": 513721,
		"settlement": "חולון",
		"manager": "מירב אטיאס",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שרת משה",
		"semelMosad": 512756,
		"settlement": "קרית אונו",
		"manager": "אלינור בן-חמו",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שש שנתי אורט טבריה",
		"semelMosad": 244210,
		"settlement": "טבריה",
		"manager": "דנה מור",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שש שנתי אחד העם",
		"semelMosad": 440107,
		"settlement": "פתח תקווה",
		"manager": "יעל בצלאל זלזני",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שש שנתי איש שלום",
		"semelMosad": 444828,
		"settlement": "כפר יונה",
		"manager": "מלכה חלוץ",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שש שנתי בוקעאתא",
		"semelMosad": 249219,
		"settlement": "בוקעאתא",
		"manager": "עדנאן בחסאס",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "דרוזי"
	},
	{
		"schoolName": "שש שנתי ידלין",
		"semelMosad": 441303,
		"settlement": "ראשון לציון",
		"manager": "דורית עיני",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שש שנתי יצחק נבון",
		"semelMosad": 482620,
		"settlement": "יבנה",
		"manager": "אורנית גדג' שני",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שש שנתי ע\"ש יצחק שמיר",
		"semelMosad": 711051,
		"settlement": "פתח תקווה",
		"manager": "רחל בראל",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שש שנתי ע\"ש קופטאן",
		"semelMosad": 800110,
		"settlement": "דאלית אל-כרמל",
		"manager": "אימאן חליפה",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "דרוזי"
	},
	{
		"schoolName": "שש שנתי רמון",
		"semelMosad": 580001,
		"settlement": "פתח תקווה",
		"manager": "ליזה רכס",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שש שנתי שיבולים",
		"semelMosad": 482125,
		"settlement": "נס ציונה",
		"manager": "רחלה אלפסי",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שש-שנתי רות",
		"semelMosad": 482778,
		"settlement": "גדרה",
		"manager": "יהושפט הלוי",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שש-שנתי תיכון גבעת שמואל",
		"semelMosad": 482257,
		"settlement": "גבעת שמואל",
		"manager": "חנה אלון",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שתילי זיתים",
		"semelMosad": 513853,
		"settlement": "בני ברק",
		"manager": "שמעון זיאת",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שתילים",
		"semelMosad": 540427,
		"settlement": "בני ברק",
		"manager": "נתן צבי פינקל",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שתילים בני ברק",
		"semelMosad": 733873,
		"settlement": "בני ברק",
		"manager": "חנה הרוש",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שתילים ירושלים",
		"semelMosad": 470815,
		"settlement": "ירושלים",
		"manager": "ליסה לבוביק",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שתילים כרמיאל",
		"semelMosad": 759118,
		"settlement": "כרמיאל",
		"manager": "אסתר שרמן",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שתילים צפריה",
		"semelMosad": 748319,
		"settlement": "צפריה",
		"manager": "מרים כרמלי",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "שתילים רכים",
		"semelMosad": 515486,
		"settlement": "בני ברק",
		"manager": "אליעזר בלוך",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת מסילה בערבה",
		"semelMosad": 613562,
		"settlement": "ירוחם",
		"manager": "רפאל עמרם לונטל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת \"לב שמחה\" רובע ח'",
		"semelMosad": 610980,
		"settlement": "אשדוד",
		"manager": "הרש יוסף גולדברג",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת אבי העזרי",
		"semelMosad": 227439,
		"settlement": "בית שמש",
		"manager": "יעקב סגל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת אבי העזרי",
		"semelMosad": 238105,
		"settlement": "ירושלים",
		"manager": "אוריאל קוק",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת אבני צדק אבני של",
		"semelMosad": 114116,
		"settlement": "ירושלים",
		"manager": "שלמה חיים לוין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת אדרת אליהו",
		"semelMosad": 734715,
		"settlement": "בני ברק",
		"manager": "נחום מזרחי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת אהבת משה",
		"semelMosad": 124131,
		"settlement": "ירושלים",
		"manager": "נסים כלפא",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת אהל משה",
		"semelMosad": 613331,
		"settlement": "בית הגדי",
		"manager": "רחמים חניה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת אהלי אברהם",
		"semelMosad": 514570,
		"settlement": "בני ברק",
		"manager": "משה חזקיה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת אהלי יעקב",
		"semelMosad": 414698,
		"settlement": "עמנואל",
		"manager": "ציון לוי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת אהלי ספר",
		"semelMosad": 114322,
		"settlement": "מודיעין עילית",
		"manager": "יאיר גינזבורג",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת אוהל התורה",
		"semelMosad": 194480,
		"settlement": "מודיעין עילית",
		"manager": "גולן חסידי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת אור גאון",
		"semelMosad": 514588,
		"settlement": "בני ברק",
		"manager": "עודד מלכה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת אור דוד",
		"semelMosad": 514109,
		"settlement": "בני ברק",
		"manager": "יעל מזוז",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת אור חדש",
		"semelMosad": 370379,
		"settlement": "רכסים",
		"manager": "חנה נחמה וינר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת אור חדש הר-נוף",
		"semelMosad": 134700,
		"settlement": "ירושלים",
		"manager": "ארנון יוגדיוף",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת אור יאיר",
		"semelMosad": 655332,
		"settlement": "שמעה",
		"manager": "אברהם חיים רוט",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת אור לציון",
		"semelMosad": 414763,
		"settlement": "נתניה",
		"manager": "אהרון גבאי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת אור מאיר",
		"semelMosad": 614917,
		"settlement": "אשדוד",
		"manager": "מאיר שאול עמר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת אור מנחם",
		"semelMosad": 612416,
		"settlement": "אשקלון",
		"manager": "דוד קסטנבוים",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת אור מנחם",
		"semelMosad": 611137,
		"settlement": "ערד",
		"manager": "דוד טירנואר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת אור עולם",
		"semelMosad": 338137,
		"settlement": "ביתר עילית",
		"manager": "ראובן לינדר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת אור תורה",
		"semelMosad": 160291,
		"settlement": "מודיעין עילית",
		"manager": "גד קרעי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת אורות התורה",
		"semelMosad": 615039,
		"settlement": "אשדוד",
		"manager": "אשר מאיר קרסינטה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת אחיעזר",
		"semelMosad": 113803,
		"settlement": "ירושלים",
		"manager": "חיים אוירבך-אור",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת איש מצליח",
		"semelMosad": 514562,
		"settlement": "בני ברק",
		"manager": "אליהו קבלן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת אמונת ציון -בנים",
		"semelMosad": 440529,
		"settlement": "ירושלים",
		"manager": "מרדכי ישראל שטרן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת אמרי בינה",
		"semelMosad": 410043,
		"settlement": "אלעד",
		"manager": "ישראל מאיר מלכה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת ארץ המוריה",
		"semelMosad": 462267,
		"settlement": "ירושלים",
		"manager": "אברהם עמרם שבת",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת ארץ המוריה",
		"semelMosad": 370536,
		"settlement": "ירושלים",
		"manager": "שירה רבקה כהן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת באר מנחם",
		"semelMosad": 741132,
		"settlement": "גבעת זאב",
		"manager": "נחום פיקסלר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת בוסטון",
		"semelMosad": 415877,
		"settlement": "בני ברק",
		"manager": "נתן פוקסברומר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת בית אשר",
		"semelMosad": 113142,
		"settlement": "ירושלים",
		"manager": "יצחק יעקב גוטליב",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת בית דוד",
		"semelMosad": 513374,
		"settlement": "חולון",
		"manager": "אליהו פולק",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת בית חנוך ק.הרצוג",
		"semelMosad": 514430,
		"settlement": "בני ברק",
		"manager": "אליעזר צולשן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת בית יהודה",
		"semelMosad": 421131,
		"settlement": "פתח תקווה",
		"manager": "אורי עמר",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת בית יוסף",
		"semelMosad": 614933,
		"settlement": "אשדוד",
		"manager": "מאיר בן גיגי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת בית יוסף",
		"semelMosad": 214551,
		"settlement": "טבריה",
		"manager": "שאול בוכריס",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת בית יוסף",
		"semelMosad": 615179,
		"settlement": "קרית מלאכי",
		"manager": "ברוך כלאף",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת בית ישראל",
		"semelMosad": 612226,
		"settlement": "אשדוד",
		"manager": "גרשון עובדיה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת בית רבן",
		"semelMosad": 511626,
		"settlement": "ירושלים",
		"manager": "נתנאל גריינימן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת בית שלום",
		"semelMosad": 338244,
		"settlement": "בית שמש",
		"manager": "יצחק שורץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת במסילה נעלה",
		"semelMosad": 142729,
		"settlement": "ירושלים",
		"manager": "יצחק שלמה אדרי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת בן איש חייל",
		"semelMosad": 514372,
		"settlement": "רמת גן",
		"manager": "יוסף שלמה ניסן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת בן איש חיל",
		"semelMosad": 416453,
		"settlement": "רחובות",
		"manager": "אליהו חלימי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת בני אברכים מצויינים",
		"semelMosad": 728923,
		"settlement": "ירושלים",
		"manager": "טובה מרים תמים",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת בני הישיבות",
		"semelMosad": 756981,
		"settlement": "נוף הגליל",
		"manager": "שמעון יום טוב וויזר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת בני נפתלי",
		"semelMosad": 755017,
		"settlement": "בית שמש",
		"manager": "יהודה גליק",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת בני ציון",
		"semelMosad": 514174,
		"settlement": "בת ים",
		"manager": "יוסף ורון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת בניהו",
		"semelMosad": 613364,
		"settlement": "נתיבות",
		"manager": "יובל אלמלך",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת בנין עולם",
		"semelMosad": 267989,
		"settlement": "ירושלים",
		"manager": "שמעון דורלכר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת בעל\"ז",
		"semelMosad": 514000,
		"settlement": "תל אביב - יפו",
		"manager": "יצחק אברהם ברנשטיין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת בעקבי הצאן",
		"semelMosad": 416966,
		"settlement": "ראש העין",
		"manager": "יאיר בשארי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת ברסלב אלעד",
		"semelMosad": 611772,
		"settlement": "אלעד",
		"manager": "ברוך ידידיה אלול",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת ברקאי",
		"semelMosad": 511543,
		"settlement": "נתניה",
		"manager": "אליעזר ביטר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת בתי אבות",
		"semelMosad": 513366,
		"settlement": "בני ברק",
		"manager": "בועז בדיחי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת גור ערד",
		"semelMosad": 613422,
		"settlement": "ערד",
		"manager": "יצחק פלר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת גירסת ישראל",
		"semelMosad": 143883,
		"settlement": "ירושלים",
		"manager": "טוביה יצחק קמינר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת דברות משה",
		"semelMosad": 113860,
		"settlement": "ירושלים",
		"manager": "אריה פוקסברומר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת דברי יחזקאל",
		"semelMosad": 441048,
		"settlement": "מבשרת ציון",
		"manager": "אילן בורלא",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת דחסידי בעלזא",
		"semelMosad": 411074,
		"settlement": "ירושלים",
		"manager": "עזריאל ישעיה זיכרמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת דעת זקנים",
		"semelMosad": 113712,
		"settlement": "מודיעין עילית",
		"manager": "משה יוסף גפני",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת דעת זקנים אלעד",
		"semelMosad": 416354,
		"settlement": "אלעד",
		"manager": "ראובן טורץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת דעת מבינים",
		"semelMosad": 434019,
		"settlement": "פתח תקווה",
		"manager": "עידן הרשקו",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת דרך התורה",
		"semelMosad": 338178,
		"settlement": "אלעד",
		"manager": "אלישיב-דוד קוביוף",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת דרך חסידים",
		"semelMosad": 514794,
		"settlement": "בני ברק",
		"manager": "מרדכי לייב גלבשטיין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת דרך ישרים",
		"semelMosad": 414102,
		"settlement": "פתח תקווה",
		"manager": "אליהו קונצקי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת דרכי אבות",
		"semelMosad": 759100,
		"settlement": "נתניה",
		"manager": "יעקב שמעון שר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת דרכי אבות צאנז",
		"semelMosad": 632448,
		"settlement": "צפת",
		"manager": "אהרון ארליך",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת דרכי איש",
		"semelMosad": 359208,
		"settlement": "ביתר עילית",
		"manager": "נחום פומרנץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת דרכי חיים",
		"semelMosad": 613976,
		"settlement": "אופקים",
		"manager": "דניאל שם טוב",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת דרכי יהודה",
		"semelMosad": 620567,
		"settlement": "אשדוד",
		"manager": "ישראל המבורגר",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת דרכי מוריה",
		"semelMosad": 756973,
		"settlement": "פתח תקווה",
		"manager": "יששכר רקובר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת דרכי נועם",
		"semelMosad": 359232,
		"settlement": "בית שמש",
		"manager": "שמחה ירון פוקסברומר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת דרכי תורה",
		"semelMosad": 312686,
		"settlement": "רכסים",
		"manager": "נסים דנינו",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת האיש משה",
		"semelMosad": 514927,
		"settlement": "בת ים",
		"manager": "שי אלימלך",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת הדסים תפרח",
		"semelMosad": 612598,
		"settlement": "תפרח",
		"manager": "לבנה כהן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת הדר יוסף",
		"semelMosad": 142646,
		"settlement": "עלי",
		"manager": "שי בן שלום",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת הדרת ירושלים",
		"semelMosad": 115816,
		"settlement": "ירושלים",
		"manager": "שלום צבי וולנסקי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת החדש בעקבי הצאן",
		"semelMosad": 514752,
		"settlement": "בני ברק",
		"manager": "שמואל צברי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת החדש יסודות",
		"semelMosad": 730002,
		"settlement": "יסודות",
		"manager": "יהושע כהן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת החינוך",
		"semelMosad": 338764,
		"settlement": "מודיעין עילית",
		"manager": "בן ציון גדליה נורדמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת המאוחד",
		"semelMosad": 214254,
		"settlement": "מגדל העמק",
		"manager": "שמעון אלמלח",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת המסורה",
		"semelMosad": 616102,
		"settlement": "ירושלים",
		"manager": "אליקים יעקב שלמה כהנוביץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת המסורה",
		"semelMosad": 111310,
		"settlement": "ירושלים",
		"manager": "ישראל יצחק משה טרנובסקי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת המסילה",
		"semelMosad": 338186,
		"settlement": "מודיעין עילית",
		"manager": "יעקב הלי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת המסילה",
		"semelMosad": 227090,
		"settlement": "כוכב יעקב",
		"manager": "ישעיהו פישר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת הרא\"ם",
		"semelMosad": 174821,
		"settlement": "ירושלים",
		"manager": "נפתלי סנדי שרייבר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת הראם",
		"semelMosad": 414300,
		"settlement": "רחובות",
		"manager": "מאיר דב יהודה פנט",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת וב\"י גור",
		"semelMosad": 612267,
		"settlement": "קרית גת",
		"manager": "משה זילברשטיין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת וב\"י דרכי נועם",
		"semelMosad": 613869,
		"settlement": "אופקים",
		"manager": "גילה סגרון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת וב\"י הרמב\"ם",
		"semelMosad": 514612,
		"settlement": "רמת השרון",
		"manager": "מרים פישל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת וב\"י חורב",
		"semelMosad": 312041,
		"settlement": "פרדס חנה-כרכור",
		"manager": "רחל דויטש",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת וב\"י חשמונאים",
		"semelMosad": 113365,
		"settlement": "גני מודיעין",
		"manager": "ברוך שלום כהן הלפרין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת וב\"י יסודי התורה",
		"semelMosad": 613372,
		"settlement": "שדרות",
		"manager": "עליזה אלגרבלי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת וב\"י נוה צבי",
		"semelMosad": 111013,
		"settlement": "בית שמש",
		"manager": "משה צבי קליין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת וב\"י נתיב מאיר",
		"semelMosad": 611814,
		"settlement": "באר שבע",
		"manager": "יצחק דרעי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת וב\"י עמיחי כרמיא",
		"semelMosad": 244277,
		"settlement": "כרמיאל",
		"manager": "מלכה גיטל וינגרטן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת וב\"י קול מנחם",
		"semelMosad": 213330,
		"settlement": "חצור הגלילית",
		"manager": "מנחם צבי וינגוט",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת וב\"י שערי ציון",
		"semelMosad": 163931,
		"settlement": "ירושלים",
		"manager": "שרה טל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת ובי' וולפסון",
		"semelMosad": 512210,
		"settlement": "הרצליה",
		"manager": "יוסף לוי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת ובי' חורב רמת גן",
		"semelMosad": 512244,
		"settlement": "רמת גן",
		"manager": "יהודה שיש",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת ובי' חזון יחזקאל",
		"semelMosad": 611301,
		"settlement": "אלומה",
		"manager": "גילה פנש",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת ובי' יחד",
		"semelMosad": 514877,
		"settlement": "תל אביב - יפו",
		"manager": "אשר ברדה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת ובי' ישורון",
		"semelMosad": 611335,
		"settlement": "קרית מלאכי",
		"manager": "יהושע מלכה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת ובי' מסורת אבות",
		"semelMosad": 312512,
		"settlement": "טירת כרמל",
		"manager": "גרשון שורק",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת ובי' מרכז באר שבע",
		"semelMosad": 611285,
		"settlement": "באר שבע",
		"manager": "יהודה ראובן אוחנה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת ובי' נר ישראל",
		"semelMosad": 412486,
		"settlement": "יהוד-מונוסון",
		"manager": "ישי מעודה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת ובי' נתיבות משה",
		"semelMosad": 413187,
		"settlement": "פתח תקווה",
		"manager": "מאיר טננבוים",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת ובי' נתיבות משה",
		"semelMosad": 415786,
		"settlement": "רחובות",
		"manager": "חיה מלכה מלכה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת ובי' נתיבות משה",
		"semelMosad": 312470,
		"settlement": "חיפה",
		"manager": "שמואל אבא אדלשטיין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת ובי' שילה דימונה",
		"semelMosad": 612457,
		"settlement": "דימונה",
		"manager": "נתנאל מאיר כלפון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת ובי' שערי ציון",
		"semelMosad": 414011,
		"settlement": "רמלה",
		"manager": "יוכבד מורגנוסר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת ויזניץ מודיעין ע",
		"semelMosad": 410886,
		"settlement": "מודיעין עילית",
		"manager": "זכריה שרייבר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת זיו התורה",
		"semelMosad": 513499,
		"settlement": "בני ברק",
		"manager": "מאיר ויסמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת זכרון גבריאל",
		"semelMosad": 226506,
		"settlement": "זכרון יעקב",
		"manager": "אריה ריזל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת זכרון יעקב אלעד",
		"semelMosad": 343962,
		"settlement": "אלעד",
		"manager": "שלמה ידידיה חזן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת זכרון מאיר",
		"semelMosad": 312488,
		"settlement": "חיפה",
		"manager": "יצחק שלמה זייבאלד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת זכרון מאיר שמעון",
		"semelMosad": 338087,
		"settlement": "בית שמש",
		"manager": "אריה יהודה שידלובסקי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת חב\"ד בנים",
		"semelMosad": 384206,
		"settlement": "בית צבי",
		"manager": "ערן יהודה דישון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת חברון",
		"semelMosad": 113175,
		"settlement": "קרית ארבע",
		"manager": "ברוך מימון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת חורב",
		"semelMosad": 412551,
		"settlement": "נתניה",
		"manager": "יוסף הלל קרישבסקי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת חזו\"א",
		"semelMosad": 637975,
		"settlement": "בני ברק",
		"manager": "אליעזר אהרון רוטנברג",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת חיי עולם",
		"semelMosad": 523183,
		"settlement": "בני ברק",
		"manager": "ישראל-ברוך חג'בי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת חיי עולם",
		"semelMosad": 112995,
		"settlement": "ירושלים",
		"manager": "מנחם שמעון שלמה אריה ברנשטיין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת חכמת שלמה",
		"semelMosad": 131177,
		"settlement": "ירושלים",
		"manager": "אברהם שלום פינזל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת חכמת שלמה חסידי",
		"semelMosad": 412536,
		"settlement": "ירושלים",
		"manager": "משה שמואל וייס",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת חמדת התורה טבריה",
		"semelMosad": 511378,
		"settlement": "טבריה",
		"manager": "גיל-ניסים נחמני",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת חניכי הישיבות",
		"semelMosad": 610675,
		"settlement": "נתיבות",
		"manager": "ערן אסיאג",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת חניכי הישיבות",
		"semelMosad": 615187,
		"settlement": "אופקים",
		"manager": "יהונתן יוני קמחי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת חניכי הישיבות",
		"semelMosad": 136010,
		"settlement": "בית שמש",
		"manager": "פנחס שלמה אביטן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת חסידי אור",
		"semelMosad": 174748,
		"settlement": "בית שמש",
		"manager": "מיכאל יחיאל ארשטר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת חפץ חיים",
		"semelMosad": 414045,
		"settlement": "ראשון לציון",
		"manager": "ישראל בנימין גלנץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת יגדיל תורה",
		"semelMosad": 131110,
		"settlement": "קרית יערים",
		"manager": "יוסף שלום גרמה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת יחד",
		"semelMosad": 514992,
		"settlement": "תל אביב - יפו",
		"manager": "חנה גננדל שמואלביץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת ילדי חן",
		"semelMosad": 214353,
		"settlement": "יבנאל",
		"manager": "רם אבירם",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת יסדת עוז",
		"semelMosad": 614669,
		"settlement": "אופקים",
		"manager": "דניאל קדם",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת יסוד העולם",
		"semelMosad": 512384,
		"settlement": "בני ברק",
		"manager": "דוד בן יאיר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת יסודי התורה רה\"ע",
		"semelMosad": 412627,
		"settlement": "ראש העין",
		"manager": "צבי אריה ויס",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת יסודי התורה ת\"א",
		"semelMosad": 512368,
		"settlement": "תל אביב - יפו",
		"manager": "נועם אלימלך שטרן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת יקירי ירושלים",
		"semelMosad": 111948,
		"settlement": "ירושלים",
		"manager": "אליהו סודרי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת יקירי ירושלים",
		"semelMosad": 288027,
		"settlement": "בית שמש",
		"manager": "שאול נחום",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת ירום משה",
		"semelMosad": 111021,
		"settlement": "ירושלים",
		"manager": "יהודה בלולו",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת ישועת יעקב יוסף",
		"semelMosad": 514224,
		"settlement": "בת ים",
		"manager": "שלמה לוי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת כינור לדוד",
		"semelMosad": 514455,
		"settlement": "בני ברק",
		"manager": "יצחק אביטן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת כלל חסידי",
		"semelMosad": 612341,
		"settlement": "אשדוד",
		"manager": "שמואל שלמה אדלר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת כלל חסידי",
		"semelMosad": 344788,
		"settlement": "מודיעין עילית",
		"manager": "אברהם שלמה פרידמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת כלל חסידי",
		"semelMosad": 632737,
		"settlement": "בית שמש",
		"manager": "יהודא לייב קופף",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת כלל חסידי - צפת",
		"semelMosad": 754788,
		"settlement": "צפת",
		"manager": "אברהם שלמה פרידמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת כלל חסידי בית שמש",
		"semelMosad": 476465,
		"settlement": "בית שמש",
		"manager": "יוסף דב לבקוביץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת כלל חסידי ביתר ע",
		"semelMosad": 410522,
		"settlement": "ביתר עילית",
		"manager": "חיים עמרם הברפלד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת כלל חסידי זכרון צבי",
		"semelMosad": 630848,
		"settlement": "בני ברק",
		"manager": "בנימין שר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת לב שמחה רובע ז'",
		"semelMosad": 613547,
		"settlement": "אשדוד",
		"manager": "שמואל גולדברג",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת לב שמחה\" רובע ג'",
		"semelMosad": 610964,
		"settlement": "אשדוד",
		"manager": "צבי יצחק גליקסון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת לויכטר",
		"semelMosad": 110932,
		"settlement": "ירושלים",
		"manager": "דב בר ארליך",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת לקח טוב",
		"semelMosad": 514380,
		"settlement": "בני ברק",
		"manager": "אהרן דונר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת מאור ישראל",
		"semelMosad": 113290,
		"settlement": "ירושלים",
		"manager": "אשר חזיזא",
		"level": "יסודי בלבד",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת מאורי צ'רנוביל",
		"semelMosad": 113191,
		"settlement": "ירושלים",
		"manager": "ישראל יהודה קרישבסקי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת מאיר עיני ישראל",
		"semelMosad": 614248,
		"settlement": "אשדוד",
		"manager": "יהודה רוזנברג",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת מאיר עיניים",
		"semelMosad": 124123,
		"settlement": "מודיעין עילית",
		"manager": "אפרים מוסאי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת מגן אברהם",
		"semelMosad": 417113,
		"settlement": "ראשון לציון",
		"manager": "מנשה מוסאי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת מהר\"ל ביתר עילית",
		"semelMosad": 237537,
		"settlement": "ביתר עילית",
		"manager": "משה-אורי כהן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת מוריה",
		"semelMosad": 113548,
		"settlement": "ירושלים",
		"manager": "אהרון יעקב רוני פרידמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת מוריה אלעד",
		"semelMosad": 442558,
		"settlement": "אלעד",
		"manager": "יששכר רקובר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת מוריה רמת ב.שמש",
		"semelMosad": 430264,
		"settlement": "בית שמש",
		"manager": "מרים זאבי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת מורשה",
		"semelMosad": 613554,
		"settlement": "אשדוד",
		"manager": "יעקב קריספין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת מורשה",
		"semelMosad": 111864,
		"settlement": "ירושלים",
		"manager": "משה בן הרוש",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת מורשה",
		"semelMosad": 640748,
		"settlement": "אשדוד",
		"manager": "פנחס קריספין",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת מורשת התורה",
		"semelMosad": 312694,
		"settlement": "חיפה",
		"manager": "עודד שבזי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת מחזיקי הדת אשדוד",
		"semelMosad": 640268,
		"settlement": "אשדוד",
		"manager": "דוד אליאס",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת מכתב מאליהו",
		"semelMosad": 514828,
		"settlement": "אור יהודה",
		"manager": "שרון אמסלם",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת מסורת ישראל חיפה",
		"semelMosad": 313593,
		"settlement": "חיפה",
		"manager": "יהושע דוד ביננפלד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת מעלה מכמש",
		"semelMosad": 188078,
		"settlement": "מעלה מכמש",
		"manager": "אברהם-בצלאל מגדל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת מפי עוללים",
		"semelMosad": 613752,
		"settlement": "קרית גת",
		"manager": "שמעון מויאל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת מפי עוללים",
		"semelMosad": 312918,
		"settlement": "זכרון יעקב",
		"manager": "מיכאל ישעיה כהן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת מצפה יריחו",
		"semelMosad": 630236,
		"settlement": "מצפה יריחו",
		"manager": "אליאס אלחרט",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת מרחביה",
		"semelMosad": 413286,
		"settlement": "פתח תקווה",
		"manager": "אלחנן מערבי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת מרכז ב\"ב",
		"semelMosad": 512129,
		"settlement": "בני ברק",
		"manager": "משה עזריאל רוזנטל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת משיבת נפש",
		"semelMosad": 414748,
		"settlement": "גני תקווה",
		"manager": "ישורון זאבי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת משכן מאיר",
		"semelMosad": 615161,
		"settlement": "באר שבע",
		"manager": "מאור ישראל זנה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת משכנות יעקב",
		"semelMosad": 514182,
		"settlement": "בני ברק",
		"manager": "יצחק פריד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת משנת יהודה",
		"semelMosad": 124099,
		"settlement": "ביתר עילית",
		"manager": "דן אריה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת נווה שלום",
		"semelMosad": 415794,
		"settlement": "רמלה",
		"manager": "חיים רצון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת נחלת מאיר",
		"semelMosad": 614024,
		"settlement": "אשדוד",
		"manager": "מאיר בן זקן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת נחמת ציון",
		"semelMosad": 134676,
		"settlement": "ירושלים",
		"manager": "נתן חנניה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת נטיעים",
		"semelMosad": 415828,
		"settlement": "פתח תקווה",
		"manager": "שלמה יהודה ביטון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת נטעי מאיר",
		"semelMosad": 731620,
		"settlement": "ביתר עילית",
		"manager": "יוחנן בוקשפן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת נטעי מאיר",
		"semelMosad": 113928,
		"settlement": "ביתר עילית",
		"manager": "ערן עוזיאל בן פורת",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת נטעי ראובן",
		"semelMosad": 612879,
		"settlement": "נתיבות",
		"manager": "רפאל משה מושיעאל מיימון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת ניצני הנגב",
		"semelMosad": 613679,
		"settlement": "דימונה",
		"manager": "עמוס סופיר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת ניצני התורה",
		"semelMosad": 410662,
		"settlement": "נצר חזני",
		"manager": "יגאל דהרי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת נעימות התורה",
		"semelMosad": 131078,
		"settlement": "ירושלים",
		"manager": "אליהו זיידל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת נפש החיים",
		"semelMosad": 288076,
		"settlement": "ביתר עילית",
		"manager": "אברהם אלכסנדר בלעדי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת נר הצפון",
		"semelMosad": 214213,
		"settlement": "מגדל העמק",
		"manager": "אושרי-אשר אזולאי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת נר שמואל",
		"semelMosad": 331066,
		"settlement": "חדרה",
		"manager": "אליהו יוסף ברונר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת נתיבות יהודה",
		"semelMosad": 614834,
		"settlement": "נתיבות",
		"manager": "מאיר זיו שלים",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת נתיבות משה נהריה",
		"semelMosad": 747915,
		"settlement": "נהריה",
		"manager": "יצחק מאיר שטימן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת נתיבות שלמה",
		"semelMosad": 144287,
		"settlement": "ירושלים",
		"manager": "יהודה אריה לייב דלבקוביץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת נתיבי התורה",
		"semelMosad": 174656,
		"settlement": "ירושלים",
		"manager": "אפרים אטלי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת סיני יבנה",
		"semelMosad": 413120,
		"settlement": "יבנה",
		"manager": "עוזיאל בשארי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת סנהדרין",
		"semelMosad": 115766,
		"settlement": "ירושלים",
		"manager": "ראובן זאב פודולסקי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת סקווירא",
		"semelMosad": 724906,
		"settlement": "בית שמש",
		"manager": "חיים דוד זילברמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת עטרת שלמה",
		"semelMosad": 248906,
		"settlement": "בית שמש",
		"manager": "יוסף יחיאל ניסל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת עטרת שלמה ב.ברק",
		"semelMosad": 510248,
		"settlement": "בני ברק",
		"manager": "יונה עמנואל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת עטרת שלמה מערב",
		"semelMosad": 511758,
		"settlement": "בני ברק",
		"manager": "אליעזר טויב",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת עכו צפון",
		"semelMosad": 212522,
		"settlement": "עכו",
		"manager": "ישורון שלום פורשנר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת עמנואל",
		"semelMosad": 414383,
		"settlement": "עמנואל",
		"manager": "אשר זליג טורק",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת עץ חיים",
		"semelMosad": 482091,
		"settlement": "רחובות",
		"manager": "שלמה גרינבוים",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת עצמונה",
		"semelMosad": 613687,
		"settlement": "שומריה",
		"manager": "יובל גבריאלי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת פאר יוסף",
		"semelMosad": 114140,
		"settlement": "ירושלים",
		"manager": "רחמים עצמון רם",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת פאר משה",
		"semelMosad": 514604,
		"settlement": "פתח תקווה",
		"manager": "מאיר זקבך",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת פיתוחי חותם",
		"semelMosad": 514885,
		"settlement": "תל אביב - יפו",
		"manager": "יוסף רחמים בן ציון חירארי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת פלא יועץ",
		"semelMosad": 612895,
		"settlement": "אשקלון",
		"manager": "נסים כלפון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת פני מנחם",
		"semelMosad": 512855,
		"settlement": "בני ברק",
		"manager": "דוד אדלר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת פקודת אלעזר",
		"semelMosad": 145466,
		"settlement": "ירושלים",
		"manager": "שלום דונט",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת פרחי כהונה",
		"semelMosad": 213439,
		"settlement": "צפת",
		"manager": "יגאל כהן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת פרי התורה",
		"semelMosad": 194134,
		"settlement": "מודיעין עילית",
		"manager": "צבי יעקובוביץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת פרי חדש",
		"semelMosad": 514281,
		"settlement": "בני ברק",
		"manager": "יהונתן אליהו אלישע",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת פרי תואר",
		"semelMosad": 416347,
		"settlement": "אלעד",
		"manager": "אפרים ישראל אורשלימי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת צמח צדק",
		"semelMosad": 415349,
		"settlement": "פתח תקווה",
		"manager": "אברהם פרקש",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת צמח צדק ויזניץ",
		"semelMosad": 614776,
		"settlement": "אשדוד",
		"manager": "שלמה צבי ברלוביץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת קדושת הלוי",
		"semelMosad": 175703,
		"settlement": "בית שמש",
		"manager": "יחיאל אדלר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת קהילות יעקב",
		"semelMosad": 361022,
		"settlement": "ירושלים",
		"manager": "בנימין איזנטל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת קהילת קמיניץ",
		"semelMosad": 114124,
		"settlement": "ירושלים",
		"manager": "מאיר מנחם נוסבוים",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת קהל חסידים אלעד",
		"semelMosad": 282350,
		"settlement": "אלעד",
		"manager": "בן ציון דויטש",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת קנין התורה",
		"semelMosad": 511360,
		"settlement": "מודיעין עילית",
		"manager": "נפתלי סנדי שרייבר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת קנין תורה",
		"semelMosad": 168427,
		"settlement": "קרית ארבע",
		"manager": "עמיצדק שלמה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת קרית ספר",
		"semelMosad": 160309,
		"settlement": "מודיעין עילית",
		"manager": "יהודה שם טוב",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת קרן נתיבות משה",
		"semelMosad": 411090,
		"settlement": "קדימה-צורן",
		"manager": "יוסף קנפלמכר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת רבי עקיבא יפיק ת",
		"semelMosad": 512160,
		"settlement": "בני ברק",
		"manager": "שלמה זלמן קרלינשטין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת רזי-לי",
		"semelMosad": 512178,
		"settlement": "בני ברק",
		"manager": "יוסף לבציון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת רש\"י",
		"semelMosad": 514166,
		"settlement": "תל אביב - יפו",
		"manager": "יהודה מהדיזאדה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת שארית ישראל",
		"semelMosad": 412569,
		"settlement": "פתח תקווה",
		"manager": "יוסף פישר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת שילה",
		"semelMosad": 514802,
		"settlement": "תל אביב - יפו",
		"manager": "הדסה וינמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת שירת יוסף",
		"semelMosad": 416271,
		"settlement": "פתח תקווה",
		"manager": "שאול בובליל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת שלום בניך",
		"semelMosad": 338152,
		"settlement": "ירושלים",
		"manager": "מליה גולדשטיין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת שמחת תורה",
		"semelMosad": 514547,
		"settlement": "תל אביב - יפו",
		"manager": "אהרן נחום מרגלית",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת שער השמים",
		"semelMosad": 728998,
		"settlement": "בית אל",
		"manager": "יעקב משה בנימין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת שער התורה",
		"semelMosad": 513291,
		"settlement": "בני ברק",
		"manager": "יעקב קובר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת שערי חסד",
		"semelMosad": 643452,
		"settlement": "ירושלים",
		"manager": "חיים עוזר אורלווק",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת שפתי רננות",
		"semelMosad": 416263,
		"settlement": "פתח תקווה",
		"manager": "אהרן פישר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת תורה ברמה",
		"semelMosad": 113738,
		"settlement": "ירושלים",
		"manager": "שלמה ברזקה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת תורה ודעת",
		"semelMosad": 267716,
		"settlement": "כרמיאל",
		"manager": "יהודה אריה טרגר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת תורה לשמה",
		"semelMosad": 614479,
		"settlement": "אופקים",
		"manager": "שמעון מוריוסף",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת תורה לשמה",
		"semelMosad": 124040,
		"settlement": "בית שמש",
		"manager": "אברהם טולידאנו",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת תורה תמימה",
		"semelMosad": 311837,
		"settlement": "קרית אתא",
		"manager": "יוסף מרדכי רוטנברג",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת תורני עץ החיים",
		"semelMosad": 160564,
		"settlement": "מעלה אדומים",
		"manager": "אילה טרוזמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת תורת אמת",
		"semelMosad": 111252,
		"settlement": "ירושלים",
		"manager": "יעקב צבי שטיינברג",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת תורת אמת",
		"semelMosad": 311811,
		"settlement": "חיפה",
		"manager": "שמעון זאב קלפהולץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת תורת הבית",
		"semelMosad": 134601,
		"settlement": "בית שמש",
		"manager": "יצחק שורץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת תורת החיים",
		"semelMosad": 312983,
		"settlement": "רכסים",
		"manager": "רפאל פנחס ווינט",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת תורת חיים",
		"semelMosad": 614164,
		"settlement": "יד בנימין",
		"manager": "הראל ערוסי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת תורת יעקב",
		"semelMosad": 512053,
		"settlement": "בני ברק",
		"manager": "אברהם שלום תורגמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת תורת יעקב י-ם",
		"semelMosad": 742684,
		"settlement": "ירושלים",
		"manager": "אברהם כהן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת תורת ישעיהו",
		"semelMosad": 510644,
		"settlement": "בני ברק",
		"manager": "מיכאל קקון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת תורת שמשון",
		"semelMosad": 612887,
		"settlement": "אופקים",
		"manager": "הרשל לנפסקי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת תל ציון",
		"semelMosad": 226811,
		"settlement": "כוכב יעקב",
		"manager": "תנחום קלטי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת תלפיות",
		"semelMosad": 514083,
		"settlement": "בני ברק",
		"manager": "יוסף אברהם חיים אהרונוב",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת תפארת טבריה",
		"semelMosad": 214148,
		"settlement": "טבריה",
		"manager": "בנימין זאב בוימל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת תפארת מרדכי",
		"semelMosad": 513937,
		"settlement": "בני ברק",
		"manager": "שלמה יעקב שוהם",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת תפארת נחמי'ה",
		"semelMosad": 756338,
		"settlement": "אשדוד",
		"manager": "ישראל רוטנברג",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת תפארת נצח ילדי ב",
		"semelMosad": 514695,
		"settlement": "בני ברק",
		"manager": "פנחס מילר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת תשב\"ר אלעד",
		"semelMosad": 721886,
		"settlement": "אלעד",
		"manager": "יהושע העשיל קוגל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת\"ת תשב\"ר בית שמש",
		"semelMosad": 343947,
		"settlement": "בית שמש",
		"manager": "מרדכי יהודה פיין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת''ת אמרי דוד",
		"semelMosad": 723494,
		"settlement": "בני ברק",
		"manager": "רפאל דדון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת''ת מדרש מאיר",
		"semelMosad": 761429,
		"settlement": "מודיעין עילית",
		"manager": "מרדכי פלס",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת'ת ברסלב",
		"semelMosad": 513952,
		"settlement": "בני ברק",
		"manager": "יצחק מאיר גרודז'ינסקי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת'ת יחי ראובן",
		"semelMosad": 136267,
		"settlement": "ירושלים",
		"manager": "יעקב דונר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "ת'ת קרית הרצוג",
		"semelMosad": 513960,
		"settlement": "בני ברק",
		"manager": "אליעזר יצחק זייציק",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תבונה ודעת",
		"semelMosad": 508275,
		"settlement": "קרית גת",
		"manager": "מתתיהו עמראני",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תבל תיכון רמות",
		"semelMosad": 641662,
		"settlement": "ירושלים",
		"manager": "יובל נבו",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תבנית החינוך המוזהב",
		"semelMosad": 212985,
		"settlement": "צביה",
		"manager": "אורי פרי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תדהר",
		"semelMosad": 214635,
		"settlement": "יקנעם עילית",
		"manager": "סופי רחום",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תדהר",
		"semelMosad": 120444,
		"settlement": "ירושלים",
		"manager": "בת ציון אור",
		"level": "יסודי בלבד",
		"special": "מיוחד",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תהילה",
		"semelMosad": 312876,
		"settlement": "חדרה",
		"manager": "שפרה בת-שבע קדוש",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תהלת שלמה",
		"semelMosad": 375881,
		"settlement": "חיפה",
		"manager": "מאיר ולדר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תו\"ם חרב לאת",
		"semelMosad": 470211,
		"settlement": "חרב לאת",
		"manager": "אליעזר פנחס ברויאר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תולדות ניסים",
		"semelMosad": 361527,
		"settlement": "ירושלים",
		"manager": "שרה חיה רחמה מלכה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תולדות ניסים בנים",
		"semelMosad": 362624,
		"settlement": "ירושלים",
		"manager": "אריאל ביג'אווי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תום ודעת",
		"semelMosad": 729210,
		"settlement": "ירושלים",
		"manager": "יוסף חיים פרל",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תומכי תמימים - ישיבה",
		"semelMosad": 725382,
		"settlement": "קרית מלאכי",
		"manager": "נתן וולף",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תומר",
		"semelMosad": 210286,
		"settlement": "בית שאן",
		"manager": "מרים עיני",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תומר",
		"semelMosad": 612481,
		"settlement": "באר שבע",
		"manager": "איריס נהון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תומר רחל",
		"semelMosad": 112177,
		"settlement": "מעלה אדומים",
		"manager": "שרי בן חמו",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תוספות יום-טוב",
		"semelMosad": 141713,
		"settlement": "ירושלים",
		"manager": "איסר מלין",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תורה בתפארתה",
		"semelMosad": 722132,
		"settlement": "אלעד",
		"manager": "יעקב סטפנסקי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תורה ודעת",
		"semelMosad": 741496,
		"settlement": "בני ברק",
		"manager": "רות אלחדד",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תורה ודעת",
		"semelMosad": 540245,
		"settlement": "בני ברק",
		"manager": "אריה זאב קאופמן",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תורה ומדע ע\"ש הרמב\"ם",
		"semelMosad": 482216,
		"settlement": "יבנה",
		"manager": "סיון שער",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תורני אורות בנות",
		"semelMosad": 131052,
		"settlement": "בית שמש",
		"manager": "יוסף פרקש",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תורני אורות בנים",
		"semelMosad": 114165,
		"settlement": "בית שמש",
		"manager": "שלום צבי קרל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תורני אקדמי באר יעקב",
		"semelMosad": 733741,
		"settlement": "באר יעקב",
		"manager": "דניאל אזואלוס",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תורני אריאל",
		"semelMosad": 348466,
		"settlement": "מודיעין-מכבים-רעות",
		"manager": "אברהם שוחטמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תורני בעלזא",
		"semelMosad": 647677,
		"settlement": "נוף הגליל",
		"manager": "משה שמואל אהרן מליק",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תורני ואולפ' בר אילן",
		"semelMosad": 440149,
		"settlement": "נתניה",
		"manager": "יוסף-שאול שנה",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תורני חדש - בנות",
		"semelMosad": 645218,
		"settlement": "שדרות",
		"manager": "יעל ממו",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תורני חדש ממ\"ד",
		"semelMosad": 614883,
		"settlement": "שדרות",
		"manager": "גדי בקיה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תורני לבנות ב.שולמית",
		"semelMosad": 141168,
		"settlement": "ירושלים",
		"manager": "אמיתי יצחק שחור",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תורני לבנות נווה חנה",
		"semelMosad": 613992,
		"settlement": "אשדוד",
		"manager": "אתי חנונה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תורני מדעי נועם",
		"semelMosad": 614750,
		"settlement": "כפר מימון",
		"manager": "אבניאל כהן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תורני מדעי-עץ החיים",
		"semelMosad": 614305,
		"settlement": "באר שבע",
		"manager": "יעקב שיטרית",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תורני נווה זאב",
		"semelMosad": 611004,
		"settlement": "באר שבע",
		"manager": "ניצן יצחק חלק",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תורני נסויי בנות פלך",
		"semelMosad": 140236,
		"settlement": "ירושלים",
		"manager": "יעל-סופי פפר",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תורני נסויי הרטמן",
		"semelMosad": 144220,
		"settlement": "ירושלים",
		"manager": "שאול דוד",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תורני ע\"ש פ. הימלפרב",
		"semelMosad": 140186,
		"settlement": "ירושלים",
		"manager": "שלמה דנינו",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תורני רמות",
		"semelMosad": 113316,
		"settlement": "ירושלים",
		"manager": "יהושוע יתאח",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תורת אהרון",
		"semelMosad": 361451,
		"settlement": "בני ברק",
		"manager": "חיים מאיר פדר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תורת אליהו",
		"semelMosad": 643387,
		"settlement": "רחובות",
		"manager": "דוד גרינברג",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תורת אליהו",
		"semelMosad": 163923,
		"settlement": "בית שמש",
		"manager": "דוד טקשה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תורת אליהו",
		"semelMosad": 346510,
		"settlement": "מחסיה",
		"manager": "דוד טקשה",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תורת אמך",
		"semelMosad": 366666,
		"settlement": "בית שמש",
		"manager": "בלומה ויזל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תורת אמך",
		"semelMosad": 478768,
		"settlement": "בית שמש",
		"manager": "איתן ידגר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תורת אמך",
		"semelMosad": 360511,
		"settlement": "ירושלים",
		"manager": "בן ציון דוד דורון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תורת אמת",
		"semelMosad": 513820,
		"settlement": "בני ברק",
		"manager": "אליהו פרידלנדר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תורת אמת",
		"semelMosad": 430165,
		"settlement": "אלעד",
		"manager": "אברהם יעקב סלמון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תורת הנחל",
		"semelMosad": 560318,
		"settlement": "בית שמש",
		"manager": "משה ראובן ביננשטוק",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תורת זאב",
		"semelMosad": 338277,
		"settlement": "ירושלים",
		"manager": "אפרים אנסבכר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תורת חיים",
		"semelMosad": 745919,
		"settlement": "בני ברק",
		"manager": "רפאל משה אללוף",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תורת חיים",
		"semelMosad": 761379,
		"settlement": "אלעד",
		"manager": "אליעזר שלומוביץ",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תורת חיים",
		"semelMosad": 373167,
		"settlement": "ירושלים",
		"manager": "יוסף רוטשטיין",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תורת חיים",
		"semelMosad": 362251,
		"settlement": "מודיעין עילית",
		"manager": "יוסף ישראל נויגרשל",
		"level": "יסודי בלבד",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תורת חיים",
		"semelMosad": 733857,
		"settlement": "ירושלים",
		"manager": "אברהם נחמיה ולדמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תורת חיים -ת\"ת חסידי",
		"semelMosad": 560425,
		"settlement": "ירושלים",
		"manager": "יהודה ראב",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תורת חיים ואהבה",
		"semelMosad": 477018,
		"settlement": "בני ברק",
		"manager": "צבי ישראל פרויליך",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תורת חיים-נתיבות",
		"semelMosad": 613570,
		"settlement": "נתיבות",
		"manager": "ישראל מסעוד אלוש",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תורת חסד",
		"semelMosad": 360552,
		"settlement": "בית שמש",
		"manager": "אליעזר צור",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תורת חסד",
		"semelMosad": 368092,
		"settlement": "בית שמש",
		"manager": "גבריאל בן דוד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תורת חסד",
		"semelMosad": 630129,
		"settlement": "גבעת זאב",
		"manager": "מרגלית ברהום",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תורת חסד",
		"semelMosad": 460741,
		"settlement": "אלעד",
		"manager": "ברוך דיסקין",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תורת חסד בגליל",
		"semelMosad": 240788,
		"settlement": "טבריה",
		"manager": "שלום כהן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תורת יעקב",
		"semelMosad": 758466,
		"settlement": "בת ים",
		"manager": "שמעון כהן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תורת ישראל",
		"semelMosad": 728873,
		"settlement": "ירושלים",
		"manager": "",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תורת מנחם",
		"semelMosad": 616623,
		"settlement": "כוכב יעקב",
		"manager": "ליבא חיה מושקא קרומבי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תורת משה",
		"semelMosad": 346098,
		"settlement": "ירושלים",
		"manager": "יורם אבנר מימון",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תורת משה",
		"semelMosad": 400002,
		"settlement": "חמד",
		"manager": "תרזה שפרה פרנקל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תורת עולם-ציון אברהם",
		"semelMosad": 114330,
		"settlement": "בית שמש",
		"manager": "שבתי מנחם שטיינר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תורת שלום",
		"semelMosad": 611848,
		"settlement": "בית שמש",
		"manager": "אליעזר סופר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תורת-משה שעי קול יעק",
		"semelMosad": 238097,
		"settlement": "ביתר עילית",
		"manager": "נסים שמואל חמואי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תחכמוני",
		"semelMosad": 310359,
		"settlement": "חדרה",
		"manager": "נורית זררד נעים",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תחכמוני מד",
		"semelMosad": 510289,
		"settlement": "בת ים",
		"manager": "אוולין רוטשילד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תחכמוני מד-בית שאן",
		"semelMosad": 210310,
		"settlement": "בית שאן",
		"manager": "מירב לוי אבורמד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תחכמוני מד-טבריה",
		"semelMosad": 210781,
		"settlement": "טבריה",
		"manager": "חגית אביבית כנפי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון אביב",
		"semelMosad": 441808,
		"settlement": "רעננה",
		"manager": "אורנה אהרון",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון אהל משה",
		"semelMosad": 749028,
		"settlement": "זמרת",
		"manager": "טובה גמליאל",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון אהל שם",
		"semelMosad": 540203,
		"settlement": "רמת גן",
		"manager": "ישראל וילוז'ני",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון אורט אבו תלול",
		"semelMosad": 660233,
		"settlement": "אבו תלול",
		"manager": "רגי אלכרם",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "תיכון אורט אבוקרינט",
		"semelMosad": 648220,
		"settlement": "אבו קרינאת (יישוב)",
		"manager": "מאליק אבו קרינאת",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "תיכון אורט אלהואשלה",
		"semelMosad": 648279,
		"settlement": "קצר א-סר",
		"manager": "יוסף אל הואשלה",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "תיכון אורית",
		"semelMosad": 710756,
		"settlement": "סח'נין",
		"manager": "ואאל חלאילה",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "תיכון אורית",
		"semelMosad": 248781,
		"settlement": "נצרת",
		"manager": "שאדיה ספורי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "תיכון אורתודוקסי",
		"semelMosad": 448142,
		"settlement": "רמלה",
		"manager": "אלהם מחול",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "תיכון אזורי מגידו",
		"semelMosad": 260109,
		"settlement": "עין השופט",
		"manager": "סימה מנט",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון אזורי רמת הנגב",
		"semelMosad": 755181,
		"settlement": "שיזף",
		"manager": "דפנה ארבל",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון איתן",
		"semelMosad": 480095,
		"settlement": "סתריה",
		"manager": "אברהם ערן חדידה",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון איתן ע\"ש רפאל איתן",
		"semelMosad": 540534,
		"settlement": "רמת גן",
		"manager": "נועה אבניאל בר-און",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון אכסאל",
		"semelMosad": 248112,
		"settlement": "אכסאל",
		"manager": "עומר שלבי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "תיכון אל סייד",
		"semelMosad": 640250,
		"settlement": "אל סייד",
		"manager": "אברהים אלסאייד",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "תיכון אלביאן סח'נין",
		"semelMosad": 278283,
		"settlement": "סח'נין",
		"manager": "פריד חלאילי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "תיכון אלון",
		"semelMosad": 541268,
		"settlement": "רמת גן",
		"manager": "עירן ירושלמי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון אלכוארזמי",
		"semelMosad": 248740,
		"settlement": "טמרה",
		"manager": "נאדר חג'אזי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "תיכון אלמל",
		"semelMosad": 740365,
		"settlement": "סח'נין",
		"manager": "עבד אל חכים טרביה",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "תיכון אלמעאלי",
		"semelMosad": 560482,
		"settlement": "כפר כנא",
		"manager": "חאתם דהאמשה",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "תיכון אלסייד",
		"semelMosad": 685586,
		"settlement": "אל סייד",
		"manager": "קיפאח אל סייד",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "תיכון אלעולא",
		"semelMosad": 410001,
		"settlement": "לוד",
		"manager": "סלוא אבו מרסה",
		"level": "יסודי ועליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "תיכון אנתרופוסופי",
		"semelMosad": 630640,
		"settlement": "הוד השרון",
		"manager": "שירלי שקד",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון אסקנדר",
		"semelMosad": 342337,
		"settlement": "אום אל-פחם",
		"manager": "ח'אלד מחאג'נה",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "תיכון אפיקים",
		"semelMosad": 441436,
		"settlement": "פתח תקווה",
		"manager": "מרים כהן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון אקסלנס לבנות",
		"semelMosad": 460022,
		"settlement": "ירושלים",
		"manager": "מאלק פלאח",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "תיכון באר עמ\"י",
		"semelMosad": 520676,
		"settlement": "באר טוביה",
		"manager": "תומר אמנו",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון בגין",
		"semelMosad": 444745,
		"settlement": "ראש העין",
		"manager": "פני פזית היבש",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון בית חיה ורבקה",
		"semelMosad": 640367,
		"settlement": "באר שבע",
		"manager": "פועה איטו חרר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון בית חנה",
		"semelMosad": 440255,
		"settlement": "נתניה",
		"manager": "שמואל שר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון בית יעקב",
		"semelMosad": 142034,
		"settlement": "בית שמש",
		"manager": "אסתר רחל וינגרטן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון בית יעקב",
		"semelMosad": 140715,
		"settlement": "ירושלים",
		"manager": "יוכבד צפורה פרצוביץ",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון בית יעקב",
		"semelMosad": 440933,
		"settlement": "עמנואל",
		"manager": "בלה וינגוט",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון בית יעקב",
		"semelMosad": 440131,
		"settlement": "נתניה",
		"manager": "צפורה ברכה אקשטיין",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון בית יעקב",
		"semelMosad": 540237,
		"settlement": "רמת גן",
		"manager": "משה לוסטיג",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון בית יעקב",
		"semelMosad": 542027,
		"settlement": "אור יהודה",
		"manager": "אסתר ליכטיג",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון בית יעקב",
		"semelMosad": 346023,
		"settlement": "מודיעין עילית",
		"manager": "יעקב צבי רוזנשטין",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון בית יעקב",
		"semelMosad": 440313,
		"settlement": "רחובות",
		"manager": "שרה מקובצקי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון בית יעקב",
		"semelMosad": 340331,
		"settlement": "חיפה",
		"manager": "חיים ישראל אוסטרי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון בית יעקב",
		"semelMosad": 640896,
		"settlement": "אשקלון",
		"manager": "לאה וילר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון בית יעקב",
		"semelMosad": 640730,
		"settlement": "ערד",
		"manager": "אריה אליעזר רוטנברג",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון בית יעקב וזניץ",
		"semelMosad": 540856,
		"settlement": "בני ברק",
		"manager": "יקותיאל צבי שטרן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון בית יעקב תל אב",
		"semelMosad": 714857,
		"settlement": "תל אביב - יפו",
		"manager": "מרים רוטנברג",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון בית מלכה",
		"semelMosad": 541060,
		"settlement": "בני ברק",
		"manager": "מרים רינגל",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון בית צפאפא",
		"semelMosad": 148056,
		"settlement": "ירושלים",
		"manager": "האשם בדר",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "תיכון במעלה אדר",
		"semelMosad": 442301,
		"settlement": "פתח תקווה",
		"manager": "צבי אהרון בירנבאום",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון בנות אור חיה",
		"semelMosad": 641258,
		"settlement": "אשקלון",
		"manager": "שרה אוריאן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון בפטיסטי",
		"semelMosad": 247049,
		"settlement": "נצרת",
		"manager": "עזיז דעים",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "תיכון ברנקו וייס מיתרים",
		"semelMosad": 644617,
		"settlement": "מיתר",
		"manager": "אפרת בשירי קורן",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון ברנר",
		"semelMosad": 440099,
		"settlement": "פתח תקווה",
		"manager": "מיכל מרדכי ריכרט",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון גבעול",
		"semelMosad": 338541,
		"settlement": "חדרה",
		"manager": "רשף בורדה",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון גבעת גונן",
		"semelMosad": 140871,
		"settlement": "ירושלים",
		"manager": "יפית גורגיה גרין סווירי",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון גבעת ושינגטון",
		"semelMosad": 440560,
		"settlement": "בית רבן",
		"manager": "חיים שמואל מנחם שרקי",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון גור קרית גת",
		"semelMosad": 748004,
		"settlement": "קרית גת",
		"manager": "שושנה יפה פפר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון גני תקווה",
		"semelMosad": 719542,
		"settlement": "גני תקווה",
		"manager": "נעה רכט",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון דה שליט",
		"semelMosad": 440016,
		"settlement": "רחובות",
		"manager": "אבי קיש",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון דיר אל-אסד",
		"semelMosad": 248302,
		"settlement": "דייר אל-אסד",
		"manager": "מחמוד עומר",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "תיכון דמוקרטי",
		"semelMosad": 759399,
		"settlement": "הוד השרון",
		"manager": "רמי אברהם נייפריס",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון דמוקרטי ק.אונו",
		"semelMosad": 499699,
		"settlement": "קרית אונו",
		"manager": "רביב נוחומוביץ",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון דמוקרטי קשת",
		"semelMosad": 338426,
		"settlement": "זכרון יעקב",
		"manager": "שלומית קובלינר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון דניצגר",
		"semelMosad": 240010,
		"settlement": "קרית שמונה",
		"manager": "עופר זפרני",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון דרור",
		"semelMosad": 170043,
		"settlement": "ירושלים",
		"manager": "רני חזון וייס",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון דרכא אחווה",
		"semelMosad": 614321,
		"settlement": "דימונה",
		"manager": "בתיה שם טוב",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון דרכא אפלמן",
		"semelMosad": 640243,
		"settlement": "דימונה",
		"manager": "יעקב מאדי",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון דרכא זינמן",
		"semelMosad": 644062,
		"settlement": "דימונה",
		"manager": "ורד פלמנבאום",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון דרכא ליהמן",
		"semelMosad": 640086,
		"settlement": "דימונה",
		"manager": "גריגורי פוזניאנסקי",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון דרכא מחט",
		"semelMosad": 519124,
		"settlement": "דימונה",
		"manager": "תמר אלקיים",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון דתי איתן חדש",
		"semelMosad": 441378,
		"settlement": "רמלה",
		"manager": "שי אפשטיין",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון דתי באר יעקב",
		"semelMosad": 440206,
		"settlement": "באר יעקב",
		"manager": "תמר כהנא",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון דתי עתיד",
		"semelMosad": 440495,
		"settlement": "ראש העין",
		"manager": "איתמר נגר",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון הגליל",
		"semelMosad": 248021,
		"settlement": "נצרת",
		"manager": "פיסל טאהא",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "תיכון הדרים",
		"semelMosad": 441006,
		"settlement": "הוד השרון",
		"manager": "עירית קרס",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון המעיין מקיף יב",
		"semelMosad": 444257,
		"settlement": "ראשון לציון",
		"manager": "סיגל חנה פנט",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון המר דרכא",
		"semelMosad": 571158,
		"settlement": "בת ים",
		"manager": "יוסף יהושע שטריק",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון הראל",
		"semelMosad": 440941,
		"settlement": "כפר סבא",
		"manager": "ציפי יעקב",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון הראל",
		"semelMosad": 144501,
		"settlement": "מבשרת ציון",
		"manager": "ליאת שגב",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון השאם אבו רומי",
		"semelMosad": 248088,
		"settlement": "טמרה",
		"manager": "הישאם חג'אזי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "תיכון ויזניץ אלעד",
		"semelMosad": 460733,
		"settlement": "אלעד",
		"manager": "בלומה פרידמן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון ויזניץ מודיעין",
		"semelMosad": 746784,
		"settlement": "מודיעין עילית",
		"manager": "פרידה פלדמן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון ז'קלין כהנוב",
		"semelMosad": 542076,
		"settlement": "בת ים",
		"manager": "יעל שמבורסקי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון זכרון יעקב",
		"semelMosad": 356212,
		"settlement": "זכרון יעקב",
		"manager": "מירב קסלר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון ח'דיג'ה",
		"semelMosad": 348250,
		"settlement": "אום אל-פחם",
		"manager": "מוחמד ג'בארין",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "תיכון חב\"ד בנות",
		"semelMosad": 482307,
		"settlement": "רחובות",
		"manager": "שרה חנה ריפקיינד",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון חדרה",
		"semelMosad": 340281,
		"settlement": "חדרה",
		"manager": "יעל פז",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון חדש",
		"semelMosad": 713131,
		"settlement": "כפר מנדא",
		"manager": "טארק קדח",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "תיכון חדש בנים",
		"semelMosad": 644781,
		"settlement": "נתיבות",
		"manager": "משה בטיטו",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון חדש דרכא",
		"semelMosad": 571224,
		"settlement": "בת ים",
		"manager": "רחל ביטון",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון חדש ע\"ש רבין",
		"semelMosad": 540070,
		"settlement": "תל אביב - יפו",
		"manager": "לימור מרקנזון",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון חדש ע\"ש רבין",
		"semelMosad": 644344,
		"settlement": "קרית גת",
		"manager": "אילנה גז-ביק",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון חדש רמות",
		"semelMosad": 644393,
		"settlement": "באר שבע",
		"manager": "מירי זמר",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון חורב רעננה",
		"semelMosad": 484006,
		"settlement": "רעננה",
		"manager": "רחל יוזוק",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון חיפה",
		"semelMosad": 340612,
		"settlement": "חיפה",
		"manager": "יששכר אינדיג",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון חסידי בית יעקב",
		"semelMosad": 540930,
		"settlement": "בני ברק",
		"manager": "פסיה אידלס",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון חק' טכ' ראמה",
		"semelMosad": 288019,
		"settlement": "ראמה",
		"manager": "אנעאם דאוד",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "תיכון חקלאי ימה",
		"semelMosad": 800003,
		"settlement": "זמר",
		"manager": "וליד זיידאן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "תיכון חרדי באר יעקב",
		"semelMosad": 740753,
		"settlement": "באר יעקב",
		"manager": "בילה פרוכטר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון חרדי לבנות ב\"י",
		"semelMosad": 240580,
		"settlement": "עכו",
		"manager": "סימה בוחבוט",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון טומשין אלנג'אח",
		"semelMosad": 348011,
		"settlement": "באקה אל-גרביה",
		"manager": "פלסטין חג' אחמד",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "תיכון טומשין חולון",
		"semelMosad": 541532,
		"settlement": "חולון",
		"manager": "דניאל גרסטל",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון טומשין ראשל\"צ",
		"semelMosad": 441477,
		"settlement": "ראשון לציון",
		"manager": "אתי שיטרית",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון טורעאן",
		"semelMosad": 248138,
		"settlement": "טורעאן",
		"manager": "דיאב עומר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "תיכון טכנולוגי האופק",
		"semelMosad": 370262,
		"settlement": "אור עקיבא",
		"manager": "עינת רובין",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון טכנולוגי נעמת",
		"semelMosad": 370486,
		"settlement": "חדרה",
		"manager": "מירב רובין בן מנחם",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון טכנולוגי נעמת",
		"semelMosad": 470641,
		"settlement": "לוד",
		"manager": "קלוד יגאל בוגנים",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון טכנולוגי נעמת",
		"semelMosad": 470526,
		"settlement": "הוד השרון",
		"manager": "יפעת הולץ",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון טכנולוגי נעמת",
		"semelMosad": 570705,
		"settlement": "תל אביב - יפו",
		"manager": "מנחם צרויה",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון טכנולוגי נעמת",
		"semelMosad": 570945,
		"settlement": "חולון",
		"manager": "אורית לבון",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון טכנולוגי נעמת",
		"semelMosad": 470542,
		"settlement": "ראשון לציון",
		"manager": "אראלה גליקמן שאול",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון טרה סנטה יפו",
		"semelMosad": 547042,
		"settlement": "תל אביב - יפו",
		"manager": "גוליית חליל",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "תיכון יד ביד",
		"semelMosad": 346494,
		"settlement": "ירושלים",
		"manager": "אפרת עמית מאיר",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון יחד",
		"semelMosad": 237354,
		"settlement": "מודיעין-מכבים-רעות",
		"manager": "שגיב אלבז",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון ינוח ג'ת",
		"semelMosad": 247296,
		"settlement": "יאנוח-ג'ת",
		"manager": "מאג'ד ביסאן",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "דרוזי"
	},
	{
		"schoolName": "תיכון יעדים",
		"semelMosad": 541979,
		"settlement": "אור יהודה",
		"manager": "גיל יאיר",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון יעדים לחינוך",
		"semelMosad": 370023,
		"settlement": "כפר ביאליק",
		"manager": "ארז מזרחי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון יפיע",
		"semelMosad": 248328,
		"settlement": "יפיע",
		"manager": "נסרין פאיד חטיב",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "תיכון ישיבתי נרייה",
		"semelMosad": 641316,
		"settlement": "בית הגדי",
		"manager": "יצחק בן נעים",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון כאבול",
		"semelMosad": 249094,
		"settlement": "כאבול",
		"manager": "ח'ליל עכריה",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "תיכון כפר אדומים",
		"semelMosad": 611475,
		"settlement": "כפר אדומים",
		"manager": "יעל ספיר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון כפר כנא",
		"semelMosad": 800094,
		"settlement": "כפר כנא",
		"manager": "יוסף אמארה",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "תיכון כפר מנדא",
		"semelMosad": 249037,
		"settlement": "כפר מנדא",
		"manager": "סלימאן עבדאלחלים",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "תיכון כצנלסון",
		"semelMosad": 440172,
		"settlement": "כפר סבא",
		"manager": "טוביה רוזנברג-מלליס",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון כרמל",
		"semelMosad": 348193,
		"settlement": "חיפה",
		"manager": "אנא מריה כראם",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "תיכון לאומנויות",
		"semelMosad": 140806,
		"settlement": "ירושלים",
		"manager": "תומר בליטי דגן",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון לבנות בית יעקב",
		"semelMosad": 442749,
		"settlement": "קרית אתא",
		"manager": "שרה רוטנברג",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון לבנות בית יעקב",
		"semelMosad": 340695,
		"settlement": "רכסים",
		"manager": "מרדכי מנחם בן שלום",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון להנדסה ולמדעים אורט ערבי",
		"semelMosad": 417204,
		"settlement": "לוד",
		"manager": "שירין חאפי",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "תיכון לחינוך סביבתי",
		"semelMosad": 770941,
		"settlement": "מדרשת בן גוריון",
		"manager": "אופיר בן-פורת",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון לקייה",
		"semelMosad": 648055,
		"settlement": "לקיה",
		"manager": "עלי אל אסד",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "תיכון מדעים קלנסואה",
		"semelMosad": 448233,
		"settlement": "קלנסווה",
		"manager": "ראודה נאטור אבו עיטה",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "תיכון מולדה",
		"semelMosad": 648345,
		"settlement": "סעווה",
		"manager": "עמר אטרש",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "תיכון מח\"ט קלנסואה",
		"semelMosad": 440354,
		"settlement": "קלנסווה",
		"manager": "עבד אל והאב קשקוש",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "תיכון מכבים רעות",
		"semelMosad": 144329,
		"settlement": "מודיעין-מכבים-רעות",
		"manager": "רוית ורון",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון מסעדה",
		"semelMosad": 247817,
		"settlement": "מסעדה",
		"manager": "נג'אח אבראהים",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "דרוזי"
	},
	{
		"schoolName": "תיכון מעלה עירון",
		"semelMosad": 348318,
		"settlement": "מעלה עירון",
		"manager": "ח'אלד אגבאריה",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "תיכון מקיף אורט ערד",
		"semelMosad": 640227,
		"settlement": "ערד",
		"manager": "רונית זנו",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון מקיף אלראזי",
		"semelMosad": 440412,
		"settlement": "ירושלים",
		"manager": "רובא מומני",
		"level": "יסודי ועליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "תיכון מקיף ע\"ש רודמן",
		"semelMosad": 340349,
		"settlement": "קרית ים",
		"manager": "שרה שייקר",
		"level": "חט\"ב בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון מקיף קלנסווה",
		"semelMosad": 448076,
		"settlement": "קלנסווה",
		"manager": "מובארק תאיה",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "תיכון מר אליאס",
		"semelMosad": 247106,
		"settlement": "אעבלין",
		"manager": "אליאס אבו גנימה",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "תיכון מראח אלגוזלאן",
		"semelMosad": 371112,
		"settlement": "יפיע",
		"manager": "רג'א אחמד",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "תיכון משהד",
		"semelMosad": 247288,
		"settlement": "משהד",
		"manager": "מנאל חסן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "תיכון נווה חיה",
		"semelMosad": 462929,
		"settlement": "נתיבות",
		"manager": "אושרית אביטבול",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון נחף",
		"semelMosad": 248641,
		"settlement": "נחף",
		"manager": "היתם קאדרי",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "תיכון נר הצפון",
		"semelMosad": 240648,
		"settlement": "מגדל העמק",
		"manager": "דוד מנחם",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון נתיבות שלום",
		"semelMosad": 740688,
		"settlement": "ביתר עילית",
		"manager": "רחל פרץ",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון נתיבי רחל",
		"semelMosad": 640409,
		"settlement": "קרית גת",
		"manager": "שגית גמליאל",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון סלמאן אלהוזייל",
		"semelMosad": 648030,
		"settlement": "רהט",
		"manager": "עזאלדין אלהוזייל",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "תיכון סלמאן חטיב",
		"semelMosad": 249318,
		"settlement": "ע'ג'ר",
		"manager": "עמראן ח'טיב",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "דרוזי"
	},
	{
		"schoolName": "תיכון סנט טרקמנצ'ץ",
		"semelMosad": 247411,
		"settlement": "ירושלים",
		"manager": "נארק אברהמיאן",
		"level": "יסודי ועליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "תיכון סנט מיכאל",
		"semelMosad": 548040,
		"settlement": "תל אביב - יפו",
		"manager": "נג'לה חנחן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "תיכון ע\"ש אוסטרובסקי",
		"semelMosad": 440024,
		"settlement": "רעננה",
		"manager": "שי שטרן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון ע\"ש אילן רמון",
		"semelMosad": 441410,
		"settlement": "הוד השרון",
		"manager": "חנה דלמדיגו",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון ע\"ש אל\"מ עודד ראור",
		"semelMosad": 410225,
		"settlement": "קדימה-צורן",
		"manager": "נעמי ורד",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון ע\"ש בויאר",
		"semelMosad": 140103,
		"settlement": "ירושלים",
		"manager": "דפנה מנשה-ברוך",
		"level": "יסודי ועליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון ע\"ש ג'מאל טרביה",
		"semelMosad": 800052,
		"settlement": "סח'נין",
		"manager": "כמאל טרביה",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "תיכון ע\"ש גולדה",
		"semelMosad": 499681,
		"settlement": "נס ציונה",
		"manager": "ירון גרבר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון ע\"ש גולדה מאיר",
		"semelMosad": 441139,
		"settlement": "פתח תקווה",
		"manager": "יצחק אשכנזי",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון ע\"ש גלילי",
		"semelMosad": 441030,
		"settlement": "כפר סבא",
		"manager": "יעל מטלון",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון ע\"ש חיים הרצוג",
		"semelMosad": 444117,
		"settlement": "כפר סבא",
		"manager": "סמדר גולדשטין",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון ע\"ש יעקובזון",
		"semelMosad": 141044,
		"settlement": "ירושלים",
		"manager": "ישראל מאיר ברלין",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון ע\"ש יצחק רבין",
		"semelMosad": 441238,
		"settlement": "כפר סבא",
		"manager": "סימונה שאול",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון ע\"ש נעמי שמר",
		"semelMosad": 442277,
		"settlement": "גן יבנה",
		"manager": "ונדה רבין",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון ע\"ש קלעי",
		"semelMosad": 540344,
		"settlement": "גבעתיים",
		"manager": "ענת ג'קסון",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון ע\"ש קציר",
		"semelMosad": 770453,
		"settlement": "רחובות",
		"manager": "מירב כפיר",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון עיסויה בנים",
		"semelMosad": 729871,
		"settlement": "ירושלים",
		"manager": "טארק ג'לאג'ל",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "תיכון ערערה למדע וטכ",
		"semelMosad": 800102,
		"settlement": "ערערה",
		"manager": "אחמד יונס",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "תיכון עש אחמד ע.יחיא",
		"semelMosad": 378018,
		"settlement": "כפר קרע",
		"manager": "נאזם זחאלקה",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "תיכון עש יצחק בן צבי",
		"semelMosad": 540286,
		"settlement": "קרית אונו",
		"manager": "מרגלית גלית לוי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון פה הזהב",
		"semelMosad": 247098,
		"settlement": "ג'ש (גוש חלב)",
		"manager": "האני אנדראוס",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "תיכון פסגת אמיר",
		"semelMosad": 367748,
		"settlement": "חריש",
		"manager": "פזית בן דור",
		"level": "חט\"ב + עליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון פרי תואר בית י",
		"semelMosad": 716605,
		"settlement": "אלעד",
		"manager": "רבקה בן דוד",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון פרר",
		"semelMosad": 548024,
		"settlement": "תל אביב - יפו",
		"manager": "מהא עאבד",
		"level": "יסודי ועליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "תיכון צור באהר",
		"semelMosad": 198192,
		"settlement": "ירושלים",
		"manager": "היתאם סלאמה",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "תיכון צור באהר לבנות",
		"semelMosad": 460584,
		"settlement": "ירושלים",
		"manager": "גדיר בשארה",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "תיכון קדם",
		"semelMosad": 732735,
		"settlement": "ערד",
		"manager": "מתן שחר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון קורצ'אק '",
		"semelMosad": 755223,
		"settlement": "באר שבע",
		"manager": "מוהן רם",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון קטאין אלשומר",
		"semelMosad": 342352,
		"settlement": "אום אל-פחם",
		"manager": "כמאל אגבאריה",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "תיכון ראשית",
		"semelMosad": 648386,
		"settlement": "ירושלים",
		"manager": "מיכל מלכה",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון ראשית עירוני ח",
		"semelMosad": 540187,
		"settlement": "תל אביב - יפו",
		"manager": "ארז יוסף זדה",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון רנד לחנ\"מ",
		"semelMosad": 359182,
		"settlement": "אכסאל",
		"manager": "דועא עומרי",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "תיכון רננות",
		"semelMosad": 442244,
		"settlement": "פתח תקווה",
		"manager": "חנה פרץ",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון רנסנס",
		"semelMosad": 460006,
		"settlement": "ירושלים",
		"manager": "נאדיה דעיס",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "תיכון שובו פתח תקווה",
		"semelMosad": 442343,
		"settlement": "פתח תקווה",
		"manager": "פייגי צפורה קרומין",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון שוהם",
		"semelMosad": 441337,
		"settlement": "שוהם",
		"manager": "איריס בויקיס",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון שועפט",
		"semelMosad": 148155,
		"settlement": "ירושלים",
		"manager": "נאדר נירוך",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "ערבי"
	},
	{
		"schoolName": "תיכון שיבלי",
		"semelMosad": 247882,
		"settlement": "שבלי - אום אל-גנם",
		"manager": "דיב סבייחי",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "תיכון שמעון בן צבי",
		"semelMosad": 540591,
		"settlement": "גבעתיים",
		"manager": "אלה אלקה ספיר גונן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון שק\"ד דרכא",
		"semelMosad": 260059,
		"settlement": "שדה אליהו",
		"manager": "עמרי עמירם",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון שקד",
		"semelMosad": 462200,
		"settlement": "אורנים",
		"manager": "דוד מליניאק",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון שקמים",
		"semelMosad": 645382,
		"settlement": "הוד השרון",
		"manager": "שירן רות דור",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון שש שנתי",
		"semelMosad": 456913,
		"settlement": "רמלה",
		"manager": "שלמה גולנזר",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון תהילות",
		"semelMosad": 641191,
		"settlement": "אופקים",
		"manager": "שלום מלכה",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון תורני דרך אבות",
		"semelMosad": 460097,
		"settlement": "אפרת",
		"manager": "יהונתן הולנדר",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון תורני עש אמתי",
		"semelMosad": 160457,
		"settlement": "ירושלים",
		"manager": "מיכאל יחיאל קרש",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון תל אביב",
		"semelMosad": 560094,
		"settlement": "תל אביב - יפו",
		"manager": "שושנה גרבוז",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון תלמה ילין",
		"semelMosad": 540021,
		"settlement": "גבעתיים",
		"manager": "דן שגיב",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון תמר",
		"semelMosad": 860353,
		"settlement": "נטור",
		"manager": "אלחנן לוי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון תמר אריאל",
		"semelMosad": 470187,
		"settlement": "נתניה",
		"manager": "חיה רובינשטין",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכון תראבין",
		"semelMosad": 660225,
		"settlement": "תראבין א-צאנע(ישוב)",
		"manager": "עבד אל רזיק סאנע",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "תיכון'מור'מטרו-ווסט",
		"semelMosad": 444562,
		"settlement": "רעננה",
		"manager": "אמנון בר נתן",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכונ\"ט ע\"ש אלתרמן",
		"semelMosad": 515502,
		"settlement": "תל אביב - יפו",
		"manager": "רם כהן",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תיכונית רעים",
		"semelMosad": 525923,
		"settlement": "חולון",
		"manager": "חיה לנדאו",
		"level": "חט\"ב + עליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תינאל בית ספר יסודי כפר קמא",
		"semelMosad": 219071,
		"settlement": "כפר כמא",
		"manager": "רנדה חתוקאי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "צרקסי"
	},
	{
		"schoolName": "תכון ברנקו וייס-אילת",
		"semelMosad": 660175,
		"settlement": "אילת",
		"manager": "מתן הפנר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תכון חדרה בית-אליעזר",
		"semelMosad": 344382,
		"settlement": "חדרה",
		"manager": "גלי לוברמן",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תכון ליד האוניברסיטה",
		"semelMosad": 140061,
		"settlement": "ירושלים",
		"manager": "ארז הקר",
		"level": "חט\"ב + עליונה",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תכון ת\"א שלוחת נתניה",
		"semelMosad": 442020,
		"settlement": "נתניה",
		"manager": "אלי לוין",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תל אלמלח",
		"semelMosad": 618132,
		"settlement": "כסיפה",
		"manager": "מחמוד עמור",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "תל חי",
		"semelMosad": 310789,
		"settlement": "חיפה",
		"manager": "טל רויטל אפלבאום",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תל חי",
		"semelMosad": 513044,
		"settlement": "תל אביב - יפו",
		"manager": "שלי טל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תל חי-קרית שמונה",
		"semelMosad": 212100,
		"settlement": "קרית שמונה",
		"manager": "לאה ארליך",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תל כסיף",
		"semelMosad": 618173,
		"settlement": "כסיפה",
		"manager": "גמאל אבו צבייח",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "תל\"י",
		"semelMosad": 414375,
		"settlement": "הוד השרון",
		"manager": "מיכל רובין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תל\"י גאולים ע\"ש יצחק נבון",
		"semelMosad": 110171,
		"settlement": "ירושלים",
		"manager": "יאנה גרסון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תל\"י נופי הסלע",
		"semelMosad": 160499,
		"settlement": "מעלה אדומים",
		"manager": "שרית אנג'ל אור",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תל\"י ע\"ש פרנקל",
		"semelMosad": 412445,
		"settlement": "רעננה",
		"manager": "אפרת רונן ליפניק",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תל-נורדוי",
		"semelMosad": 511725,
		"settlement": "תל אביב - יפו",
		"manager": "יעל אדלשטיין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תל-ערד",
		"semelMosad": 618116,
		"settlement": "קודייראת א-צאנע(שבט)",
		"manager": "אדיב אבו רביעה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "תלי בית וגן",
		"semelMosad": 111682,
		"settlement": "ירושלים",
		"manager": "איה סלחה מאיר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תלם",
		"semelMosad": 482976,
		"settlement": "גבעת שמואל",
		"manager": "שגית צפריר",
		"level": "יסודי בלבד",
		"special": "מיוחד",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תלמוד תורה \"עץ חיים",
		"semelMosad": 647867,
		"settlement": "פרדס חנה-כרכור",
		"manager": "חיים אברהם דיין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תלמוד תורה - דרך אמת",
		"semelMosad": 417725,
		"settlement": "עפולה",
		"manager": "שלמה ישראל גרינשטיין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תלמוד תורה אומץ",
		"semelMosad": 616144,
		"settlement": "נוף הגליל",
		"manager": "גולן עקיבא בן-דוד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תלמוד תורה באר יעקב",
		"semelMosad": 424010,
		"settlement": "באר יעקב",
		"manager": "ציון בטיטו",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תלמוד תורה בית אליהו",
		"semelMosad": 511055,
		"settlement": "בית שמש",
		"manager": "אברהם יצחק ברדפיס",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תלמוד תורה בן פורת יוסף",
		"semelMosad": 616284,
		"settlement": "יצהר",
		"manager": "אלעד מאיר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תלמוד תורה בני אברהם",
		"semelMosad": 613885,
		"settlement": "אשדוד",
		"manager": "שמעון שטובר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תלמוד תורה בני משה",
		"semelMosad": 754929,
		"settlement": "בית שמש",
		"manager": "דוד צבי שלמה שורץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תלמוד תורה בני-מנחם",
		"semelMosad": 513861,
		"settlement": "בני ברק",
		"manager": "מנחם מנדל גרינפלד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תלמוד תורה ברסלב",
		"semelMosad": 729194,
		"settlement": "תל אביב - יפו",
		"manager": "איתי בכר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תלמוד תורה ברסלב בנים",
		"semelMosad": 383737,
		"settlement": "חריש",
		"manager": "אייל עזרתי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תלמוד תורה גולני",
		"semelMosad": 448357,
		"settlement": "חספין",
		"manager": "שי גמרסני",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תלמוד תורה דביר",
		"semelMosad": 174680,
		"settlement": "נוף איילון",
		"manager": "מיכאל בן שלמה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תלמוד תורה דרך חכמה",
		"semelMosad": 213173,
		"settlement": "צפת",
		"manager": "רפאל דאנה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תלמוד תורה דרכי שלום",
		"semelMosad": 338459,
		"settlement": "אלעד",
		"manager": "ישראל אורדמנס",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תלמוד תורה הראי\"ה",
		"semelMosad": 482315,
		"settlement": "רחובות",
		"manager": "אברהם אלי יוסף פרץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תלמוד תורה ויזניץ",
		"semelMosad": 725622,
		"settlement": "עפולה",
		"manager": "משה חיים טסלר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תלמוד תורה ויזניץ קר",
		"semelMosad": 634881,
		"settlement": "בני ברק",
		"manager": "שבח גרינפלד",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תלמוד תורה חב\"ד",
		"semelMosad": 615989,
		"settlement": "טבריה",
		"manager": "עמרי בן עקיבא",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תלמוד תורה חב\"ד",
		"semelMosad": 512293,
		"settlement": "תל אביב - יפו",
		"manager": "שניאור זלמן אהרון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תלמוד תורה חכמת שלמה",
		"semelMosad": 733667,
		"settlement": "ירושלים",
		"manager": "מנחם מנדל ברסלואר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תלמוד תורה חניכי היש",
		"semelMosad": 718759,
		"settlement": "אשקלון",
		"manager": "שמואל דמרי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תלמוד תורה חפץ חיים",
		"semelMosad": 733766,
		"settlement": "כרמיאל",
		"manager": "נחמן דוד רוטנר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תלמוד תורה יסדת עז",
		"semelMosad": 512061,
		"settlement": "ירושלים",
		"manager": "יהושע נחום אריאל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תלמוד תורה ירושלים",
		"semelMosad": 631986,
		"settlement": "ירושלים",
		"manager": "צבי פרוש",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תלמוד תורה כתב סופר",
		"semelMosad": 164673,
		"settlement": "ביתר עילית",
		"manager": "אהרן יהודה אבלס",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תלמוד תורה מגן אבות",
		"semelMosad": 213850,
		"settlement": "צפת",
		"manager": "נתן אפרים קניג",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תלמוד תורה מעלות רם",
		"semelMosad": 482737,
		"settlement": "גדרה",
		"manager": "יובל בצלאל מיטלמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תלמוד תורה מרכז העיר",
		"semelMosad": 521971,
		"settlement": "ירושלים",
		"manager": "דוד אנסבכר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תלמוד תורה מתיבתא",
		"semelMosad": 725697,
		"settlement": "בני ברק",
		"manager": "יהושע העשל גפנר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תלמוד תורה נדבורנה",
		"semelMosad": 733840,
		"settlement": "ביתר עילית",
		"manager": "שלמה בנעט",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תלמוד תורה נווה",
		"semelMosad": 615146,
		"settlement": "נווה",
		"manager": "נריה צור",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תלמוד תורה פני מנחם גור",
		"semelMosad": 633941,
		"settlement": "ירושלים",
		"manager": "ישראל ענדען",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תלמוד תורה פרי מגדים",
		"semelMosad": 411249,
		"settlement": "פתח תקווה",
		"manager": "יעקב משה ורנר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תלמוד תורה שערי דעת",
		"semelMosad": 131128,
		"settlement": "ירושלים",
		"manager": "מרדכי שמואל קולר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תלמוד תורה שפתי חיים",
		"semelMosad": 475673,
		"settlement": "ירושלים",
		"manager": "נפתלי סנדי שרייבר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תלמוד תורה תורת העמק",
		"semelMosad": 124156,
		"settlement": "ירושלים",
		"manager": "מיכאל אורי סופר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תלמוד תורה תשב\"ר",
		"semelMosad": 761742,
		"settlement": "לוד",
		"manager": "עמנואל נחום אדלר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תלמי הדר",
		"semelMosad": 384354,
		"settlement": "חריש",
		"manager": "רחל רוט",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תלמי רון",
		"semelMosad": 384289,
		"settlement": "חריש",
		"manager": "ענת אורגד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תלמים",
		"semelMosad": 442491,
		"settlement": "באר יעקב",
		"manager": "מורן שחר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תלפיות מזרח ממלכתי ב",
		"semelMosad": 112136,
		"settlement": "ירושלים",
		"manager": "רוחמה תמיר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תמיר",
		"semelMosad": 320556,
		"settlement": "קרית אתא",
		"manager": "מיכל בן דוד",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תמיר",
		"semelMosad": 430033,
		"settlement": "ראשון לציון",
		"manager": "שיר קדר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תמר",
		"semelMosad": 338442,
		"settlement": "משואה",
		"manager": "ניר דוד",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תנופה בשערי תורה",
		"semelMosad": 311803,
		"settlement": "חיפה",
		"manager": "רות אריאלי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תפארת בית יעקב",
		"semelMosad": 213181,
		"settlement": "טבריה",
		"manager": "לאה שניטרפלר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תפארת בנים",
		"semelMosad": 165118,
		"settlement": "ירושלים",
		"manager": "אברהם גולד",
		"level": "יסודי בלבד",
		"special": "מיוחד",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תפארת ברוך",
		"semelMosad": 499814,
		"settlement": "ירושלים",
		"manager": "חיים יוסף קירזון",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תפארת החיים מעורב",
		"semelMosad": 112904,
		"settlement": "בית שמש",
		"manager": "דניאל עוקשי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תפארת החכמה",
		"semelMosad": 632232,
		"settlement": "בית שמש",
		"manager": "משה דוד אנשין",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תפארת חיה",
		"semelMosad": 499848,
		"settlement": "קרית גת",
		"manager": "עדי קדוש",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תפארת חיה",
		"semelMosad": 481978,
		"settlement": "אלעד",
		"manager": "שיינא לישנר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תפארת יוחנן",
		"semelMosad": 288126,
		"settlement": "ביתר עילית",
		"manager": "יוסף שמעי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תפארת יוסף",
		"semelMosad": 514836,
		"settlement": "תל אביב - יפו",
		"manager": "שמחה בר ששת",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תפארת יורם מיכאל",
		"semelMosad": 641159,
		"settlement": "נתיבות",
		"manager": "יוסף אביטל",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תפארת לוי",
		"semelMosad": 142315,
		"settlement": "ירושלים",
		"manager": "משה הלוי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תפארת מרדכי שלמה",
		"semelMosad": 643668,
		"settlement": "קרית גת",
		"manager": "דוד זליג אייכלער",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תפארת משה",
		"semelMosad": 747337,
		"settlement": "ביתר עילית",
		"manager": "יוסף שמעי",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תפארת משה בצלאל",
		"semelMosad": 141069,
		"settlement": "ירושלים",
		"manager": "גדליה סבינר",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תפארת שרה",
		"semelMosad": 729236,
		"settlement": "בני ברק",
		"manager": "מרים דיין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תפארת שרה",
		"semelMosad": 640904,
		"settlement": "נתיבות",
		"manager": "יפה ביטון",
		"level": "עליונה בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תפנית",
		"semelMosad": 620229,
		"settlement": "בית שמש",
		"manager": "אורלה מועלם",
		"level": "חט\"ב + עליונה",
		"special": "מיוחד",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תקוה לילד",
		"semelMosad": 165845,
		"settlement": "ירושלים",
		"manager": "רות שטרן",
		"level": "יסודי ועליונה",
		"special": "מיוחד",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תקוע",
		"semelMosad": 112607,
		"settlement": "תקוע",
		"manager": "עפרה נתן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תרבות",
		"semelMosad": 413450,
		"settlement": "ראשון לציון",
		"manager": "דליה ברזילאי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תרבין-יסודי",
		"semelMosad": 618637,
		"settlement": "תראבין א-צאנע(ישוב)",
		"manager": "נאסר אלדין שיבלי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי",
		"sector": "בדואי"
	},
	{
		"schoolName": "תריג הרי יהודה",
		"semelMosad": 112805,
		"settlement": "קרית יערים",
		"manager": "יצחק לובל",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תשב\"ר הגבעה הדרומית",
		"semelMosad": 642355,
		"settlement": "מודיעין עילית",
		"manager": "משה דוד גייגר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תשב\"ר חנוך לנער",
		"semelMosad": 442624,
		"settlement": "אלעד",
		"manager": "מנחם מנדל דונט",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תשב\"ר יקירי רכסים",
		"semelMosad": 313809,
		"settlement": "רכסים",
		"manager": "יורם עדיקה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תשב\"ר ממ\"ח",
		"semelMosad": 634865,
		"settlement": "קרית גת",
		"manager": "איל ירמיהו",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תשב'ר הרב",
		"semelMosad": 312678,
		"settlement": "רכסים",
		"manager": "גבריאל בירנבאום",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תת אמת ואמונה סלונים",
		"semelMosad": 614826,
		"settlement": "קרית גת",
		"manager": "פנחס גליק",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תת בני אחים פרמישלאן",
		"semelMosad": 511840,
		"settlement": "בני ברק",
		"manager": "ירוחם יצחק לנדסמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תת ברכת אברהם סלונים",
		"semelMosad": 194753,
		"settlement": "ביתר עילית",
		"manager": "מנחם מנדל פוקסמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תת ובי יסודי התורה",
		"semelMosad": 412700,
		"settlement": "רעננה",
		"manager": "ישראל וסרמן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תת ובי כפר גדעון",
		"semelMosad": 212506,
		"settlement": "כפר גדעון",
		"manager": "חגי ירוחם רבינסקי",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תת ובי שכון ג טבריה",
		"semelMosad": 212480,
		"settlement": "טבריה",
		"manager": "שרון רם",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תת וישיבה דחסידי גור",
		"semelMosad": 160317,
		"settlement": "ירושלים",
		"manager": "חיים גבריאל פומרנץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תת חב\"ד",
		"semelMosad": 213900,
		"settlement": "מגדל העמק",
		"manager": "משה דוד איזנברגר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תת חדר חדש דרך תבונה",
		"semelMosad": 113274,
		"settlement": "ירושלים",
		"manager": "גבריאל קוסבר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תת חסיד דארוג",
		"semelMosad": 514778,
		"settlement": "בני ברק",
		"manager": "עקיבא פשקוס",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תת חפץ חיים",
		"semelMosad": 113886,
		"settlement": "ירושלים",
		"manager": "ניסן בורשטיין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תת למרחב עמותה תורני",
		"semelMosad": 415091,
		"settlement": "פתח תקווה",
		"manager": "ליבנת שגיב",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תת מוריה רמת בית שמש",
		"semelMosad": 199117,
		"settlement": "בית שמש",
		"manager": "משה דוד שטינר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תת מעיין החינוך אלעד",
		"semelMosad": 241018,
		"settlement": "אלעד",
		"manager": "ריצ'רד מיכאל סדיה",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תת מפי עוללים",
		"semelMosad": 514042,
		"settlement": "חולון",
		"manager": "שמעון צדיק",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תת מרכז חסידי ויזניץ",
		"semelMosad": 412437,
		"settlement": "אלעד",
		"manager": "אברהם אנגלשטיין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תת מרכז חסידי ויזניץ",
		"semelMosad": 512020,
		"settlement": "בני ברק",
		"manager": "אברהם אנגלשטיין",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תת עטרת שלמה מ.עילית",
		"semelMosad": 440404,
		"settlement": "מודיעין עילית",
		"manager": "יוסף יצחק גלוסקינוס",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תת רוזין בני ברק",
		"semelMosad": 513515,
		"settlement": "בני ברק",
		"manager": "ברוך וועג",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תת רוממה ירושלים",
		"semelMosad": 113852,
		"settlement": "ירושלים",
		"manager": "ציון גז",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תת תורת אהרון רמת אשכול",
		"semelMosad": 733949,
		"settlement": "ירושלים",
		"manager": "ישראל דוד שטינוביץ",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תת תורת חסד",
		"semelMosad": 199752,
		"settlement": "ביתר עילית",
		"manager": "משה ארוש",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "חרדי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תתמ\"ד אזורי גוש עציון",
		"semelMosad": 511717,
		"settlement": "בת עין",
		"manager": "מנחם שמואל איתן",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תתמ\"ד בנות נריה",
		"semelMosad": 650002,
		"settlement": "טלמון",
		"manager": "חגית מדמון",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	},
	{
		"schoolName": "תתמ\"ד נעם חומת שמואל",
		"semelMosad": 616425,
		"settlement": "ירושלים",
		"manager": "חגית קרמר",
		"level": "יסודי בלבד",
		"special": "רגיל",
		"type": "ממלכתי דתי",
		"sector": "יהודי"
	}
]