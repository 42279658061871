import Vue from 'vue';
import Vuex from 'vuex';
import sessionConfig from './config/session-config';
import moment from 'moment';
import VuexPersistence from 'vuex-persist';
import teacher from './store-modules/teacher';
import student from './store-modules/student';
import teacherService from './services/teacher-service';
import mockStudent from './config/mock-student.json5';
import devConfig from './config/dev-config';
import i18n from './i18n';

Vue.use(Vuex);

const vuexCookie = new VuexPersistence({
  restoreState: key => {
    let localStorageData = JSON.parse(window.localStorage.getItem(key));
    if (localStorageData) {
      let stateExpirationTime = moment(localStorageData.stateSaveTime).add(
        sessionConfig.STATE_STORAGE_SAVE_DURATION_IN_MINUTES,
        'minutes'
      );
      if (moment(new Date()).isBefore(stateExpirationTime)) {
        return localStorageData;
      } else {
        localStorage.removeItem(key);
        return null;
      }
    } else {
      return null;
    }
  },
  saveState: (key, state) => {
    state.stateSaveTime = moment(new Date());
    window.localStorage.setItem(key, JSON.stringify(state));
  }
});

export default new Vuex.Store({
  modules: {
    teacher,
    student
  },
  state: {
    token: null,
    devMode: devConfig.currentMode === devConfig.options.devMode,
    language: 'he',
    userType: null,
    logoutUriPrefix: null,
    loggedOutDueToTokenExpiry: false,
    appRootServices: {
      dialog: () => null,
      spinner: () => null
    }
  },

  mutations: {
    setDevMode(state) {
      state.devMode = !state.devMode;
    },
    setLoggedOutDueToTokenExpiry(state, value) {
      state.loggedOutDueToTokenExpiry = value;
    },
    setRefToConfirmDialog(state, payload) {
      state.appRootServices.dialog = payload;
    },
    setRefToFullScreenSpinner(state, payload) {
      state.appRootServices.spinner = payload;
    },
    setToken(state, token) {
      state.token = token;
    },
    setName(state, data) {
      if (!data) {
        this.commit('student/setStudentName', {
          firstName: null,
          lastName: null
        });
        this.commit('teacher/setTeacherName', {
          firstName: null,
          lastName: null
        });
      } else {
        let userType = data.userType;
        let fullName = {
          firstName: data.firstName,
          lastName: data.lastName
        };
        if (userType === 'T') {
          this.commit('teacher/setTeacherName', fullName);
          this.commit('student/setStudentName', {
            firstName: null,
            lastName: null
          });
        } else {
          this.commit('student/setStudentName', fullName);
          this.commit('teacher/setTeacherName', {
            firstName: null,
            lastName: null
          });
        }
      }
    },
    setUserType(state, type) {
      state.userType = type;
    },
    setUserSchools(state, data) {
      if (data) {
        if (data.schools) {
          if (data.userType === 'T') {
            this.commit('teacher/setSchoolsList', data.schools);
          } else {
            this.commit('student/setSchoolName', data.schools[0]);
          }
        }
      } else {
        this.commit('teacher/setSchoolsList', null);
        this.commit('student/setSchoolName', null);
      }
    },
    setLanguage(state, language) {
      state.language = language;
    },
    setLogoutUriPrefix(state, logoutUriPrefix) {
      state.logoutUriPrefix = logoutUriPrefix;
    }
  },
  actions: {
    async setLoginData(state, data, clientRedirectUri = null) {
      // First clean state
      this.dispatch('cleanState');

      // Commit new state
      this.commit('setToken', data.token);
      this.commit('setLogoutUriPrefix', data.logoutUriPrefix);
      this.commit('setName', data);
      this.commit('setUserType', data.userType);
      this.commit('setUserSchools', data);
      // Fetch dataItems
      if (data.userType === 'T') {
        this.commit('teacher/setUserCanUploadExcel', data.userCanUploadExcel);
        this.commit('teacher/setDidAcceptTos', !data.showTos);
        this.dispatch('teacher/setDataItems', data.token);
        let isSysAdmin = false;
        if (data.isSysAdmin && !clientRedirectUri) {
          // isSysAdmin = confirm('האם להכנס למצב צפיה של אדמין?');
          isSysAdmin = await state.state.appRootServices.dialog(
            i18n.t('labels.adminConfirmTitle'),
            '',
            i18n.t('labels.adminConfirmAdmin'),
            i18n.t('labels.adminConfirmAfvisor')
          );
          if (isSysAdmin) {
            this.commit('teacher/setIsSysAdmin', isSysAdmin);
            let studentTz = prompt('אנא הזיני תז של תלמיד');
            let res = await teacherService.getStudentByTz(
              this.state.token,
              studentTz + ''
            );
            if (res) {
              if (res.data) {
                if (res.data.success) {
                  this.commit('teacher/setSearchedTz', studentTz);
                  this.commit('teacher/setSelectedStudent', res.data.student);
                }
              }
            }
          }
        }
      } else {
        this.dispatch('student/setDataItems', data.token);
      }
    },
    cleanState() {
      this.commit('setToken', null);
      this.commit('setName', null);
      this.commit('setUserType', null);
      this.commit('setUserSchools', null);
      this.dispatch('teacher/cleanModuleState');
      this.dispatch('student/cleanModuleState');
    }
  },
  getters: {
    getDevMode(state) {
      return state.devMode; //&& devConfig.currentMode === devConfig.options.devMode
    },
    getLoggedOutDueToTokenExpiry(state) {
      return state.loggedOutDueToTokenExpiry;
    },
    isTeacher(state) {
      return state.userType === 'T';
    },
    isStudent(state) {
      return state.userType === 'S';
    },
    isUser(state) {
      return state.userType;
    },
    getToken(state) {
      return state.token;
    },
    getState(state) {
      return state;
    },
    getFullName(state) {
      let fullName = null;
      if (state.userType === 'T') {
        fullName = `${state.teacher.firstName || ''} ${state.teacher.lastName ||
          ''}`.trim();
      } else {
        fullName = `${state.student.firstName || ''} ${state.student.lastName ||
          ''}`.trim();
      }
      return fullName;
    }
  },
  plugins: [vuexCookie.plugin]
});

function minutesToMilliseconds(minutes) {
  let convertMinutesToMilliseconds = minutes * 60 * 1000;
  return convertMinutesToMilliseconds;
}
