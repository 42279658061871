import axios from 'axios';
import store from '../store';
import notificationsManager from './notifications';
import i18n from '../i18n';
import constants from '../constants/app-constants';

const POST_STR = 'POST';
const GET_STR = 'GET';

export default {
  get: async function(url, body, headers) {
    return await genericAxiosRequest(url, body, headers, GET_STR);
  },

  post: async function(url, body, headers) {
    return await genericAxiosRequest(url, body, headers, POST_STR);
  }
};

async function genericAxiosRequest(url, body, headers, reqType) {
  try {
    let response = null;
    if (reqType === POST_STR) {
      // console.log('POST');
      response = await axios.post(url, body, headers);
    } else {
      // console.log('GET');
      response = await axios.get(url, body, headers);
    }
    // console.log(
    //   'response:',
    //   response,
    //   response.data.message === constants.errCodes.TOKEN_EXPIRED_ERROR
    // ); // debug
    if (response.data.success) {
      return response;
    } else if (
      response.data.message === constants.errCodes.TOKEN_EXPIRED_ERROR
    ) {
      store.dispatch('cleanState');
      store.commit('setLoggedOutDueToTokenExpiry', true);
      window.location.pathname = '/';
      return null;
    } else if (
      response.data.errorCode === constants.errCodes.ID_ALREADY_EXISTS ||
      response.data.errorCode === constants.errCodes.STUDENT_NOT_FOUND ||
      response.data.errorCode === constants.errCodes.ID_LAST_DIGIT_NOT_VALID
    ) {
      return response;
    } else {
      let errorMessage = i18n.t('errorCodes.' + response.data.errorCode);
      notificationsManager.noTitle('error', errorMessage);

      // We are assuming that the caller does not have a flow which depends on a specific error type.
      // Thus we are just returning null in case of success false.
      // If it's needed to handle specific error types in the flow, replace this return statement and give back the code.
      // Verify that any services which invoke this code are suited to check for success=false instead of null.
      return null;
    }
  } catch (error) {
    // in case of exception, show generic 'server error' msg
    console.warn(error);
    notificationsManager.noTitle('error', i18n.t('errorCodes.NETWORK_ERROR'));
  }
}
