<template>
  <div class="flex justify-center align-center">
    <!-- <div class="loader" /> -->
  </div>
</template>

<script>
import generalService from '../services/general-service';
import teacherService from '../services/teacher-service';
import appConfig from '../config/dev-config';
import logger from '@/services/logger.js';

export default {
  name: 'IdmCallback',
  props: {
    declaredPathSuffixForIdm: {
      type: String,
      default: null
    }
  },
  created() {
    this.processIdmCallback();
  },
  methods: {
    async processIdmCallback() {
      try {
        // If we are developing locally and the popup took place in a popup window, we have to explicitly specify
        // the path suffix to which the post-login redirect was made, otherwise the server will use its regular configuration
        // and there will be a mismatch. And we don't want to manually modify the redirect_uri in our server settings every time.
        // NOTE: No slashes are supported. the value of declaredPathSuffixForIdm can be 'login-popup-callback.html', but not
        // /some/subpath/login-popup-callback.html, because then the router will not match the resulted route with this component.
        let explicitRedirectUri = null;
        if (this.declaredPathSuffixForIdm) {
          explicitRedirectUri = `${location.protocol}//${location.host}/${
            this.declaredPathSuffixForIdm
          }`;
        }

        // the received base64-encoded state is in the query params. Decode with atob
        const state = JSON.parse(atob(this.$route.query.state));
        // console.log('state from query: ', state);
        const { clientRedirectUri, studentObjId, studentTz } = state;

        let loginResult = await generalService.openIdLogin(
          window.location.href,
          explicitRedirectUri
        );
        if (loginResult.success) {
          this.handleLoginSuccess(
            loginResult,
            studentObjId,
            studentTz,
            clientRedirectUri
          );
        } else {
          // No token back from server
          this.$store.dispatch('cleanState');
          logger.error('Error logging in: ' + loginResult.errorCode);
          this.$router.push('/');
        }
      } catch (err) {
        // TODO: Replace this with some notify message
        console.error(new Date() + ': Error processing login: ' + err);
        this.$router.push('/');
      }
    },
    async handleLoginSuccess(
      data,
      studentObjId = null,
      studentTz = null,
      clientRedirectUri = null
    ) {
      await this.$store.dispatch('setLoginData', data, clientRedirectUri);
      if (data.userType === 'T') {
        if (studentObjId) {
          this.handleStudentObjId(atob(studentObjId));
        }
        if (studentTz) {
          this.handleStudentTz(atob(studentTz));
        }
        if (data.showTos && !appConfig.skipTos && !data.isSysAdmin) {
          this.$router.push('/tos');
        } else {
          if (clientRedirectUri) {
            this.$router.push(clientRedirectUri);
          } else {
            this.$router.push('/teacher');
          }
        }
      } else if (data.userType === 'S') {
        this.$router.push('/student');
      }
      logger.debug(data);
    },
    handleStudentTz(studentTz) {
      this.$store.commit('teacher/setSearchedTz', studentTz);
    },
    async handleStudentObjId(studentObjId) {
      const token = this.$store.getters.getToken;
      const res = await teacherService.getStudentByObjId(token, studentObjId);
      if (res) {
        if (res.data) {
          if (res.data.success) {
            this.$store.commit('teacher/setSelectedStudent', res.data.student);
          }
        }
      }
    }
  }
};
</script>

<style>
.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
