import httpService from './http-service';
import apiConfig from './app-api';
import logger from '@/services/logger.js';

export default {
  getTeacherDummyData: async function(token) {
    const response = await httpService.get(apiConfig.getTeacherDummyDataUrl(), {
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': token
      }
    });
    if (response) {
      return response.data;
    } else {
      return response;
    }
  },
  switchActiveSchool: async function(data) {
    const response = await httpService.post(
      apiConfig.getTeacherDummySwitchSchoolUrl(),
      { semelMosad: data.semelMosad },
      {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': data.token
        }
      }
    );
    if (response) {
      return response.data;
    } else {
      return response;
    }
  },
  uploadExcelFile: async function(data, token) {
    const response = await httpService.post(
      apiConfig.getFileUploadUrl(),
      { file: data },
      {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token
        }
      }
    );

    return response;
  },
  registerStudent: async function(data, token) {
    const response = await httpService.post(
      apiConfig.getRegisterStudentUrl(),
      { student: data },
      {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token
        }
      }
    );

    return response;
  },
  updateStudent: async function(data, token) {
    const response = await httpService.post(
      apiConfig.getUpdateStudentUrl(),
      { student: data },
      {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token
        }
      }
    );

    return response;
  },
  uploadJsonFile: async function() {
    const response = await httpService.get(apiConfig.getJsonUploadUrl(), {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    return response;
  },
  getStudentDataForManagmentTool: async function(token) {
    const response = await httpService.get(
      apiConfig.getManagmentToolDataUrl(),
      {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token
        }
      }
    );

    return response;
  },
  deleteStudentByDocId: async function(token, studentDocId) {
    const response = await httpService.post(
      apiConfig.getStudentDeleteUrl(),
      { studentDocId },
      {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token
        }
      }
    );

    logger.debug(response);

    if (response) {
      return response.data.success;
    } else {
      return response;
    }
  },
  getStudentByTz: async (token, studentTz) => {
    const response = await httpService.post(
      apiConfig.getSearchStudentUrl(),
      { studentTz },
      {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token
        }
      }
    );

    return response;
  },
  getStudentByObjId: async (token, studentObjId) => {
    const response = await httpService.post(
      apiConfig.getStudentByObjIdUrl(),
      { studentObjId },
      {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token
        }
      }
    );

    return response;
  },
  getStudentsList: async (token) => {
    const response = await httpService.get(
        apiConfig.getStudentsListUrl(),
        {
          headers: {
            'Content-Type': 'application/json',
            'x-access-token': token
          }
        }
    );

    return response;
  },
  updateAnswersAboutStudent: async (data, token) => {
    const response = await httpService.post(
      apiConfig.getUpdateAnswersAboutStudentUrl(),
      /* data : {
          studentObjId: context.selectedStudent._id,
          answersAboutStudent: context.answersAboutStudent
        }*/
      data,
      {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token
        }
      }
    );
    return response;
  },
  updateSelectedRecommendations: async (data, token) => {
    const response = await httpService.post(
        apiConfig.getUpdateSelectedRecommendationsUrl(),
        data,
        {
          headers: {
            'Content-Type': 'application/json',
            'x-access-token': token
          }
        }
    );
    return response;
  },
  submitProfileForm: async (data, token, studentTz) => {
    const response = await httpService.post(
      apiConfig.getSubmitProfileFormUrl(),
      /* data : {
          studentObjId: store selectedStudent._id,
          teacherComments
        }*/
      { ...data, studentTz: btoa(studentTz) },
      {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token
        }
      }
    );
    return response;
  },
  updateComments: async (data, token) => {
    const response = await httpService.post(
      apiConfig.getUpdateCommentsFormUrl(),
      { ...data },
      {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token
        }
      }
    );
    return response;
  }
};
