<template>
  <div>

    <div
      v-show="spinner == 2 && toShdowSpinner"
      class="full-screen-spinner"
    >
      <div
        class="lds-dual-ring"
        :class="cssClass"
      />
    </div>

    <div
      v-show="spinner == 3 && toShdowSpinner"
      class="full-screen-spinner"
    >
      <div
        class="lds-roller"
        :class="cssClass"
      ><div /><div /><div /><div /><div /><div /><div /><div /></div>
    </div>

    <div
      v-show="spinner == 1 && toShdowSpinner"
      class="full-screen-spinner"
    >
      <div
        class="dots"
        :class="cssClass"
      >
        <div class="bounce1" />
        <div class="bounce2" />
        <div class="bounce3" />
      </div>
    </div>

    
    <div
      v-show="spinner == 4 && toShdowSpinner"
      class="full-screen-spinner"
    >
      <div
      
        class="dots"
        :class="cssClass"
      >
        <div class="bounce1" />
        <div class="bounce2" />
        <div class="bounce3" />
      </div>
    </div>
    
    
  </div>
</template>


<script>
export default {
  props: ['color', 'spinner', 'cssClass'],
  data() {
    return {
      toShdowSpinner: false
    };
  },
  methods: {
    toggleSpinner(flag) {
      this.toShdowSpinner = flag;
    }
  }
};
</script>

<!-- to change the color, go to css -->

<style lang="scss" scoped>
$defailtColor: #f3d5bd;
$teacher: black;
$student: yellow;

.full-screen-spinner {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100vw;
  line-height: 100vh;
  // height: 100vh;
  background-color: rgba(0, 0, 0, 0.349);
  z-index: 999;
  // display: flex;
  // justify-content: center;
  // align-items: center;
}

.lds-dual-ring {
  display: inline-block;
  width: 64px;
  height: 64px;
}
.lds-dual-ring:after {
  content: ' ';
  display: block;
  width: 46px;
  height: 46px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid $defailtColor;
  border-color: $defailtColor transparent $defailtColor transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

.lds-dual-ring.teacher:after {
  border: 5px solid $teacher;
  border-color: $teacher transparent $teacher transparent;
}

.lds-dual-ring.student:after {
  border: 5px solid $student;
  border-color: $student transparent $student transparent;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 32px 32px;
}
.lds-roller div:after {
  content: ' ';
  display: block;
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: $defailtColor;
  margin: -3px 0 0 -3px;
}

.lds-roller.teacher div:after {
  background: $teacher;
}

.lds-roller.student div:after {
  background: $student;
}

.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 50px;
  left: 50px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 54px;
  left: 45px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 57px;
  left: 39px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 58px;
  left: 32px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 57px;
  left: 25px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 54px;
  left: 19px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 50px;
  left: 14px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 45px;
  left: 10px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.dots {
  //   margin: 100px auto 0;
  //   width: 70px;
  text-align: center;
}

.dots > div {
  width: 18px;
  height: 18px;
  background-color: $defailtColor;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.dots.teacher > div {
  background-color: $teacher;
}

.dots.student > div {
  background-color: $student;
}

.dots .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.dots .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
</style>
