import httpService from './http-service';
import apiConfig from './app-api';
import logger from '@/services/logger.js';

export default {
  registerTeacher: async function(data, token) {
    const response = await httpService.post(
      apiConfig.getRegisterTeacherUrl(),
      { teacher: data },
      {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token
        }
      }
    );

    return response;
  },
  registerTeachers: async function(data, token) {
    const response = await httpService.post(
        apiConfig.getRegisterTeachersUrl(),
        { teachers: data },
        {
          headers: {
            'Content-Type': 'application/json',
            'x-access-token': token
          }
        }
    );

    return response;
  },
  updateAdvices: async (
    token,
    studentObjId,
    studentTz,
    updatedAdvicesArr,
    adminComments
  ) => {
    const response = await httpService.post(
      apiConfig.getUpdateAdvicesUrl(),
      {
        studentObjId,
        studentTz: btoa(studentTz),
        updatedAdvicesArr,
        adminComments
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token
        }
      }
    );
    if (response) {
      return response;
    } else {
      return response;
    }
  },
  approveAdvices: async (token, studentObjId, studentTz) => {
    const response = await httpService.post(
      apiConfig.getApproveAdvicesUrl(),
      { studentObjId, studentTz: btoa(studentTz) },
      {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token
        }
      }
    );
    if (response) {
      return response;
    } else {
      return response;
    }
  },
  schoolsManagement: async (token) => {
    const response = await httpService.get(
      apiConfig.getSchoolsManagementUrl(),
      {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token
        }
      }
    );
    if (response) {
      return response;
    } else {
      return response;
    }
  },
  switchSchoolStatus: async (token, semelMosad, status) => {
    const response = await httpService.post(
      apiConfig.getSwitchSchoolUrl(),
      { semelMosad: semelMosad, status },
      {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token
        }
      }
    );
    if (response) {
      return response;
    } else {
      return response;
    }
  },
  switchSchoolGenerator: async (token, semelMosad, status) => {
    const response = await httpService.post(
        apiConfig.getSwitchSchoolGeneratorUrl(),
        { semelMosad: semelMosad, status },
        {
          headers: {
            'Content-Type': 'application/json',
            'x-access-token': token
          }
        }
    );
    if (response) {
      return response;
    } else {
      return response;
    }
  },
};
