<template>
  <div class="collapsable-text-items-list">
    <div
      class="container"
    >
      <div>
        <div
          v-if="!submitSuccess || endUpdateProcess"
          class="edvise-page-title"
          dir="rtl"
        > {{ dynamicHdr }}</div>
        <div
          v-else
          dir="rtl"
        >
          <div class="edvise-page-title">{{ $t('sentSucces') }}</div>
          <p class="submission-p"> 
            {{ $t('submissionP1[0]') }}.
            <br>
            {{ $t('submissionP1[1]') }}.
            <br>
            {{ $t('submissionP1[2]') }}
            <router-link
              class="map-link"
              :to="`/map`"
            >
              {{ $t('atStudentChart') }}</router-link>.
            <br>
            {{ $t('submissionP2') }}.
          </p>
        </div>
        <br>
        <div
          v-if="currentUrl === 'edit-advice' && !endUpdateProcess"
          class="textarea-container placeholder"
        >
          <label for="textarea">{{ $t('enterNewRecommandation') }} </label>
          <textarea
            id="textarea"
            v-model="adminComments"
            class="edvise-input"
            dir="rtl"
            :placeholder="$t('writeAdvices')"
          />
          <br>
        </div>
        <p
          v-if="currentUrl === 'edit-advice'"
          style="text-align:right; direction: rtl;"
        > {{ !endUpdateProcess ? $t('chooseAdvices') : $t('adviceSent') }} </p>
        <div
          v-if="staticData.length > 0 && !endUpdateProcess"
          class="accordion view"
          :class="{ 'smaller': submitSuccess || currentUrl === 'edit-advice' }" 
        >
          <div
            v-for="(dataItem , index) of staticData"
            :key="index"
            class="accordion-item"
          >
            <div class="flex">
              <a
                dir="rtl"
                @click="dataItem.isOpen = !dataItem.isOpen"
              >{{ dataItem.question || dataItem.t }}</a>
              <div
                class="ul-btn pointer"
                :class="{'pls-btn':!dataItem.isOpen, 'mns-btn':dataItem.isOpen}"
                @click="dataItem.isOpen = !dataItem.isOpen"
              />
            </div>
            <div
              :class="{'opened':dataItem.isOpen, 'closed':!dataItem.isOpen}"
              class="content"
            >
              <p
                v-if="dataItem.answer"
                v-html="dataItem.answer"
              />
              <!-- if were not rendering qna or knowledge base, assuming were rendering edit-advices, which dont have answer property on each object. -->
              <div v-else>
                <div>
                  <div
                    v-for="(advice, adviceIndex) in dataItem"
                    :key="adviceIndex"
                  >
                    <div
                      v-if="toShowItemInVFor(adviceIndex)"
                      class="flex align-baseline justify-end advice-checkbox-wrapper"
                    >
                      <span class="advice-span">{{ advice }}</span> 
                      <div
                        :class="{ checked: adviceIsChecked(adviceIndex) }"
                        class="custom-checkbox"
                        @click="toggleAdviceInArr(adviceIndex, $event)"
                      />
                      <input
                        class="original-checkbox"
                        :checked="adviceIsChecked(adviceIndex)"
                        type="checkbox"
                        @click="toggleAdviceInArr(adviceIndex)"
                      > 
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="currentUrl === 'edit-advice'"
            class="flex justify-end btn-container"
          >
            <button
              class="round-btn-blue"
              :class="{ 'disabled-btn': disableSubmitBtn }"
              :disabled="disableSubmitBtn"
              @click="updateAdvices"
            > {{ $t('saveAndSend') }} </button>  
          </div>
        </div>
        <div
          v-if="submitSuccess && !endUpdateProcess"
          class="flex justify-end btn-container"
        >
          <button
            class="round-btn-blue"
            @click="$router.push('/teacher/search-student')"
          > {{ $t('backToHome') }} </button>  
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import collapsibleStaticData from '@/locales/collapsable-static-data';
import adminService from '@/services/admin-service';

export default {
  name: 'CollapsableTextItemsList',
  data() {
    return {
      staticData: [],
      currentUrl: '',
      dynamicHdr: '',
      selectedStudentAdvice: [],
      adviceToUpdate: new Set(),
      adminComments: '',
      disableSubmitBtn: false,
      endUpdateProcess: false
    };
  },
  beforeRouteLeave(to, from, next) {
    // just use `this`
    this.$store.commit('teacher/setSubmitSuccess', false);
    next();
  },
  computed: {
    submitSuccess() {
      return this.$store.getters['teacher/getSubmitSuccess'];
    }
  },
  watch: {
    $route() {
      // console.log('route change');
      this.reloadContent();
    }
  },
  created() {
    // console.log('enetering route' + this.submitSuccess);
    this.setComponenetData();
    this.reloadContent();
  },
  methods: {
    setComponenetData() {
      this.currentUrl = window.location.pathname.split('/').slice(-1)[0];
      const student = this.$store.getters['teacher/getSelectedStudent'];
      if (student) {
        if (student && student.recommendationsData) {
          this.selectedStudentAdvice =
            student.recommendationsData.recommendationCodes;
          this.adminComments = student.recommendationsData.adminComments;
          this.adviceToUpdate = new Set(this.selectedStudentAdvice);
        }
      }
    },
    toggleAdviceInArr(adviceCode, event) {
      if (event.target) {
        event.target.classList.toggle('checked');
      }
      // advices codes in json are strings and in selected student are numbers. therefore, cahnging them so numbers like DB expects.
      let adviceCodeNum = Number(adviceCode);
      if (this.adviceToUpdate.has(adviceCodeNum)) {
        this.adviceToUpdate.delete(adviceCodeNum);
        // console.log('remove advice:', { adviceCode });
      } else {
        this.adviceToUpdate = this.adviceToUpdate.add(adviceCodeNum);
        // console.log('add advice:', { adviceCode });
      }
    },
    adviceIsChecked(adviceCode) {
      // checking if advice exxists on student advice arr from DB. if it does, check its checkbox.
      let advice = this.selectedStudentAdvice.find(
        studentAdvice => studentAdvice === Number(adviceCode)
      );
      if (advice) {
        return true;
      }
      return false;
    },
    reloadContent() {
      this.currentUrl = window.location.pathname.split('/').slice(-1)[0];
      this.dynamicHdr = collapsibleStaticData[this.currentUrl].title;
      let staticData = collapsibleStaticData[this.currentUrl].entries;
      if (Array.isArray(staticData)) {
        this.staticData = staticData.map(item => ({
          ...item,
          isOpen: false
        }));
      } else {
        // if static data is not an array, assuming its a questions object.
        this.staticData = Object.keys(staticData).map(adviceTreeCode => ({
          ...staticData[adviceTreeCode],
          isOpen: false,
          adviceTreeCode
        }));
      }
    },
    openAccordItem(adviceTreeCode) {
      this.staticData[adviceTreeCode].isOpen = !this.staticData[adviceTreeCode]
        .isOpen;
    },
    toShowItemInVFor(adviceIndex) {
      // some item in the object should not be shown
      return (
        adviceIndex !== 't' &&
        adviceIndex !== 'isOpen' &&
        adviceIndex !== 'adviceTreeCode'
      );
    },
    async updateAdvices() {
      let token = this.$store.getters.getToken;
      this.disableSubmitBtn = true;
      let res = await adminService.updateAdvices(
        token,
        this.$store.getters['teacher/getSelectedStudent']._id,
        this.$store.getters['teacher/getSearchedTz'],
        [...this.adviceToUpdate], // adviceToUpdate is a set! it should be an array when sent to the server.
        this.adminComments
      );
      if (res) {
        this.disableSubmitBtn = false;
        if (res.data) {
          if (res.data.success) {
            this.$store.commit('teacher/setSelectedStudent', res.data.student);
            this.endUpdateProcess = true;
            this.dynamicHdr = this.$t('good!');
          }
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/style.scss';

*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

.original-checkbox {
  visibility: hidden;
  // position: relative;
}

.advice-checkbox-wrapper {
  position: relative;
}

.custom-checkbox {
  background-color: #fff;
  height: 17px;
  width: 17px;
  border: solid 1px #f3d5bd;
  border-radius: 3px;
  position: absolute;
  top: 11.5px;

  &.checked {
    background-image: url('../assets/img/glyphicons/check.png');
    background-position: center;
    background-size: 50%;
  }
}

.container {
  margin: 0 auto;
  padding: 4rem 4rem 0 4rem;
  width: 50rem;
}

.btn-container {
  padding: 20px 0;
}

h1 {
  font-size: 3rem;
  color: $color-blue;
  padding: 1.5rem 0rem 2rem 1rem;
  margin: 0;
  text-align: right;
}
.ul-btn {
  margin-top: 13px;
  width: 30px;
  height: 30px;
}

.round-btn-blue {
  padding: 11px 20px;
  height: fit-content;
  width: fit-content;
}

.pls-btn {
  background-image: url('../assets/img/glyphicons/plus@2x.png');
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: 5px 5px;
  cursor: pointer;
}
.mns-btn {
  background-image: url('../assets/img/glyphicons/minus@2x.png');
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: 5px 13px;
  cursor: pointer;
}
.accordion a {
  text-align: right;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  padding: 1rem 0.5rem 1rem 1rem;
  color: $color-blue;
  font-size: 16px;
  font-weight: 400;
}

.accordion a:hover,
.accordion a:hover::after {
  cursor: pointer;
  color: $color-darkblue;
}

.accordion a:hover::after {
  border: 1px solid $color-blue;
}
.accordion-item {
  border-bottom: 0.5px solid $color-blue;
}
// only first accordion item will have border top.
.accordion-item:first-of-type {
  border-top: 0.5px solid $color-blue;
}
.accordion a.active {
  color: $color-darkblue;
  border-bottom: 1px solid $color-darkblue;
}

.accordion a::after {
  font-family: $font-main;
  position: absolute;
  float: right;
  right: 1rem;
  font-size: 1rem;
  color: #7288a2;
  width: 50px;
  height: 50px;
  text-align: right;
}
.submission-p {
  font-family: $font-main;
  width: 200;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: right;
  color: $color-blue;
}
.map-link {
  color: $color-blue;
  text-decoration-line: underline;
}

.accordion a.active::after {
  font-family: 'Ionicons';
  color: $color-blue;
  // border: 1px solid $color-darkblue;
  text-align: right;
}
.accordion {
  //  overflow: hidden;
  height: 60vh;
  @media (max-height: 700px) {
    height: 40vh;
  }
  overflow: auto;

  &.cancel-scroll {
    overflow: visible;
    height: auto;
  }
}

.smaller {
  height: 40vh;
  overflow: auto;
  padding-right: 40px;
}

.closed {
  text-align: right;
  opacity: 0;
  padding: 0 1rem;
  max-height: 0;
  border-bottom: 1px solid $color-blue;
  overflow: hidden;
  clear: both;
  // -webkit-transition: all 0.2s ease 0.15s;
  // -o-transition: all 0.2s ease 0.15s;
  // transition: all 0.2s ease 0.15s;
  :hover {
    color: $color-darkblue;
  }
}

.accordion .content p {
  font-size: 16px;
  font-weight: 400;
  color: $color-blue;
  direction: rtl;
}

.opened {
  text-align: right;
  opacity: 1;
  padding: 1rem;
  max-height: 100%;
  // -webkit-transition: all 0.5s ease 0.15s;
  // -o-transition: all 0.15s ease 0.15s;
  // transition: all 0.15s ease 0.15s;
  :hover {
    color: $color-darkblue;
  }
}

.advice-span {
  padding: 8px 20px;
  direction: rtl;
}

.edvise-input {
  padding-top: 5px;
  width: 100%;
  height: 10vh;
}

.textarea-container {
  text-align: right;
  padding-bottom: 3vh;

  textarea {
    margin-top: 1vh;
  }
}
</style>

<style lang="scss">
@import '@/assets/scss/style.scss';

.accordion,
.view {
  padding-right: 30px;
  width: calc(100% + 40px);

  ::-webkit-scrollbar-track {
    background-color: transperent;
  }
  ::-webkit-scrollbar {
    display: block;
    width: 4px;
    background-color: transperent;
  }
  ::-webkit-scrollbar-thumb {
    display: block;
    border-radius: 10px;
    background-color: $color-blue;
  }
}
</style>
