export default {
  TEACHER: 'TEACHER',
  STUDENT: 'STUDENT',
  errCodes: {
    TOKEN_EXPIRED_ERROR: 'TOKEN_EXPIRED_ERROR',
    FORM_NOT_COMPLETE: 'FORM_NOT_COMPLETE',
    STUDENT_NOT_FOUND: 'STUDENT_NOT_FOUND',
    ID_ALREADY_EXISTS: 'ID_ALREADY_EXISTS',
    ID_LAST_DIGIT_NOT_VALID: 'ID_LAST_DIGIT_NOT_VALID'
  },
  answers: {
    YES: 'YES',
    NO: 'NO',
    IDK: 'IDK',
    YES_UNCARED: 'YES_UNCARED',
    YES_CARED: 'YES_CARED',
    TESTED: 'TESTED',
    NEED_TREATMENT: 'NEED_TREATMENT',
    TREATED: 'TREATED'
  }
};
