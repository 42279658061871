<template>
  <!-- existing-student-mode is a css class that adds more values to the calc of the height, since the links card is shown on this mode -->
  <div
    class="vertical-bullets"
    :class="{'existing-student-mode': config.NAV.links, 'admin':config.admin}"
  >
    <ul
      v-if="listToRender.length > 0"
      class="bullet-list"
    >
      <router-link
        v-for="item of listToRender"
        :id="`qTopic${item}`"
        :key="item"
        :class="{ 'checked': checked(item) }"
        tag="li"
        :to="`${relativePath}?section=${item}`"
      >
        <span class="bullet-text">{{ $t(config.NAV.bullets.type + '.' + item) }}</span>
      </router-link>
      <router-link
        v-if="!relativePath.includes('advice')"
        tag="li"
        :to="`${relativePath}?section=finish`"
      >
        <span class="bullet-text">{{ $t('finish') }}</span>
      </router-link>
    </ul>
  </div>
</template>

<script>
import advicesJson from '@/config/advices.json5';
import profileFormJson from '@/config/profile-form.json5';
import hiddenWhenStudNotDiagnosed from '@/config/hidden-when-stud-not-diagnosed.json5';
import advicesTopicListMixins from '@/mixins/advices-topic-list-mixins';

export default {
  mixins: [advicesTopicListMixins],
  props: {
    config: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      advicesStrArr: Object.keys(advicesJson),
      profileQStrArr: Object.keys(profileFormJson),
      listToRender: [],
      relativePath: this.$route.path
    };
  },
  computed: {
    isAllQuestionsDisplayed() {
      return this.$store.getters['teacher/isAllQuestionsDisplayed'];
    },
    treesFullyFilledByUser() {
      return this.$store.getters['teacher/getTreesFullyFilledByUser'];
    }
  },
  watch: {
    isAllQuestionsDisplayed() {
      this.resetComponent();
    },
    config() {
      this.resetComponent();
    },
    $route() {
      this.relativePath = this.$route.path;
      this.resetComponent();
      // scrolls into the active bullet
      const el = document.querySelector('.router-link-exact-active');
      if (el) {
        el.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });
      }
    }
  },
  mounted() {
    this.resetComponent();
  },
  methods: {
    checked(item) {
      let flag =
        this.config.NAV.bullets.checks &&
        this.$store.getters['teacher/getTreesFullyFilledByUser'][item];
      return flag;
    },
    resetComponent() {
      if (this.config.NAV.bullets.type === 'profile') {
        if (this.isAllQuestionsDisplayed) {
          this.listToRender = this.profileQStrArr;
        } else {
          this.listToRender =
            hiddenWhenStudNotDiagnosed.sectionsToShowForIncompleteForm;
        }
      } else if (this.config.NAV.bullets.type === 'advice') {
        this.renderStudentAdvicesBullets();
      } else {
        this.listToRender = [];
      }
    },
    renderStudentAdvicesBullets() {
      // mixins function
      this.listToRender = this.advicesTopicList();
    }
  }
};
</script>

<style lang="scss">
@import '@/assets/scss/style.scss';
// these vars aren't being used because they don't work inside calc.

.vertical-bullets {
  // hardcoded hight!
  // 100% of screen size minus elements that take space from the bullets list
  // each #{$hr} is for footer, header or hr element. each 30px is for text line, and 3em for top padding.
  // if any extra elements are added to modular sidebar, they should be taken into account.
  max-height: calc(
    100vh -
      (
        #{$header} + #{$header} + #{$hr} + #{$modularSideBarLineHeight} + #{$modularSideBarLineHeight} +
          #{$profileFormPaddingTop}
      )
  );

  @media (max-height: 700px) {
    max-height: none;
  }
  &.existing-student-mode {
    max-height: calc(
      100vh -
        (
          #{$header} + #{$header} + #{$header} + #{$hr} + #{$hr} + #{$modularSideBarLineHeight} +
            #{$modularSideBarLineHeight} + #{$modularSideBarLineHeight} + #{$modularSideBarLineHeight} +
            #{$modularSideBarLineHeight} + #{$profileFormPaddingTop} + 30px
        )
    );

    @media (max-height: 700px) {
      max-height: none;
    }
  }
  // since admin has all 3 cards, its required to decrease the height even more.
  &.admin {
    max-height: calc(
      100vh -
        (
          #{$header} + #{$header} + #{$hr} + #{$hr} + #{$modularSideBarLineHeight} + #{$modularSideBarLineHeight} + #{$modularSideBarLineHeight} + #{$modularSideBarLineHeight} +
            #{$modularSideBarLineHeight} + #{$modularSideBarLineHeight} + #{$modularSideBarLineHeight} + #{$modularSideBarLineHeight} + #{$profileFormPaddingTop} + #{$profileFormPaddingTop} + #{$profileFormPaddingTop} + #{$profileFormPaddingTop} 
        ));

    @media (max-height: 700px) {
      max-height: none;
    }
  }
  overflow: auto;
  .bullet-list {
    direction: rtl;
    margin-right: 4.5em;
    padding-left: 0;
  }
  li {
    font-size: 16px;
    list-style-type: none;
    display: flex;
    align-items: center;
    position: relative;
    color: $color-lightblue;
    cursor: pointer;
    overflow: visible;
  }
  // li span {
  //   font-size: 12px;
  // }
  ul.bullet-list li:after {
    content: '';
    border: 0.5px solid;
    position: absolute;
    right: 7px;
    top: 15px;
    height: 100%;
  }
  ul.bullet-list li:nth-last-child(1):after {
    display: none;
  }
  // actual bullet inactive
  ul.bullet-list li:before {
    margin: 12px 0;
    content: '';
    height: 15px;
    width: 15px;
    background-color: $color-lightblue;
    border-radius: 50%;
  }
  ul.bullet-list li.checked:before {
    background-image: url('../../assets/img/glyphicons/check.png');
    z-index: 2;
    background-size: 60% 60%;
    background-position: center center;
    background-repeat: no-repeat;
  }
  // actuall bullet active link
  ul.bullet-list li.router-link-exact-active::before {
    position: relative;
    // // width: 10%; // // // height: 10%; // top: 10px; // z-index: 1; // font-size: 420%; // content: 'º';
    right: -4px;
    height: 23px;
    width: 23px;
    z-index: 1;
    background-color: $color-blue;
    border: 4px solid $color-peach;
  }
  .bullet-text {
    position: absolute;
    right: 55px;
    //top: 6px;
    text-align: right;
    line-height: 1.1;
  }
  ul.bullet-list li.router-link-exact-active .bullet-text {
    color: $color-peach;
    font-weight: 600;
    top: 10px;
  }
}
.vertical-bullets-wrapper {
  ::-webkit-scrollbar-track {
    background-color: transperent;
  }
  ::-webkit-scrollbar {
    display: block;
    width: 4px;
    background-color: transperent;
  }
  ::-webkit-scrollbar-thumb {
    display: block;
    border-radius: 10px;
    background-color: $color-peach;
  }
}
</style>
