<template>
  <div class="login">
    <div v-if="token">
      <h4 style="color:blue">Already logged in as <span style="color:purple">{{ userType }}</span></h4>
    </div>
    <h1>Login</h1>
    <div>
      <br>
      <br>
      <span>select mock IDM user</span>
      <br>
      <div 
        v-if="mockIdmUsers" 
        class="mock-idm-list"
      >
        <div class="box-inside-mock">
          <h4>מורים</h4>
          <p 
            v-for="teacher in mockIdmUsers.teachers"
            :key="teacher.zehut" 
            class="idmMockBtn"
            @click="getLoginPromise(teacher)"
          >{{ teacher.name }}</p> 
        </div>
        <div class="box-inside-mock">
          <h4>תלמידים</h4>
          <p 
            v-for="student in mockIdmUsers.students"
            :key="student.zehut" 
            class="idmMockBtn"
            @click="getLoginPromise(student)"
          >{{ student.name }}</p> 
        </div>
      </div>
    </div>
    <br>
    <br>
    <button
      class="round-btn-blue"
      @click="beginIdmLogin()"
    >idm login</button>
    <br>
    <br>
    <div>
      <v-dialog />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import generalService from '../services/general-service';
import devConfig from '../config/dev-config.js';
// import Notifications from '../services/notifications';
import logger from '@/services/logger.js';

export default {
  name: 'MockLogin',
  data() {
    return {
      userData: this.$store.getters.getState,
      mockUserId: null,
      popUpErrorMsg: false,
      token: this.$store.getters.getToken,
      userType: null,
      mockIdmUsers: null,
      devConfig
    };
  },
  computed: {
    getToken() {
      return this.$store.getters.getToken;
    },
    getUserType: function() {
      let isTeacher = this.$store.getters.isTeacher;
      if (isTeacher) {
        return 'Teacher';
      } else {
        return 'Student';
      }
    },
    devMode() {
      return this.$store.getters.getDevMode;
    }
  },
  created() {
    this.getMockIdmUsers();
    this.startEmailLogin();
  },
  mounted() {
    if (this.devMode) {
      // Dev Mode
      logger.debug('DEV MODE');

      if (this.isAutoLoginMode()) {
        // Automatic login will be executed now
      }
    } else {
      // Prod Mode
      logger.debug('PROD MODE');
    }

    this.userType = this.getUserType;
  },
  methods: {
    async beginIdmLogin(popupBlocked = false) {
      // If in PWA mode, then "window.matchMedia('(display-mode: standalone)').matches" will be true
      if (
        (this.devConfig.forceIdmLoginInPopup ||
          window.matchMedia('(display-mode: standalone)').matches) &&
        !popupBlocked
      ) {
        logger.debug('popup mode');
        const explicitRedirectUri = `${location.protocol}//${
          location.host
        }/login-popup-callback.html`;
        let result = await generalService.startOpenIdSession(
          explicitRedirectUri
        );
        if (!result.success) {
          logger.error('Could not connect to EDU identity management');
          // TODO: Show some visible error message
          return;
        }

        // TODO: Put this window in the center
        let loginPopupHandler = window.open(
          result.url,
          'loginPopup',
          'width=400,height=500'
        );

        // In case where popup was blocked - performing login in the same window
        // TODO: Wait a few seconds to allow popup unblock?
        if (!loginPopupHandler) {
          this.beginIdmLogin(true);
        }

        // Receving the login code from the child window and redirecting to idmcallback.
        // The 'login-popup-callback.html' is actually a route parameter, which will be passed to the server,
        // as an explicit redirect uri.
        window.addEventListener('message', evt => {
          if (evt.data && evt.data.type === 'finalizeLogin') {
            let redirectionQueryParams = evt.data.redirectionUrl.split('?')[1];
            window.location.href = `${location.protocol}//${
              location.host
            }/idmcallback/login-popup-callback.html?${redirectionQueryParams}`;
          }
        });
      } else {
        logger.debug('same window mode');

        const state = {
          clientRedirectUri: this.$route.query.redirectUri,
          studentTz: this.$route.query.studentTz,
          studentObjId: this.$route.query.studentObjId
        };

        // In this case the regular redirect URL from the IDM client settings on the server will be used
        let result = await generalService.startOpenIdSession(null, state);
        if (!result.success) {
          logger.error('Could not connect to EDU identity management');
          // TODO: Show some visible error message
          return;
        }
        window.location.href = result.url;
      }
    },
    async getLoginPromise(userData) {
      // console.log('userData:', userData);
      let data = await generalService.performMockLogin(userData);
      logger.debug(data);
      if (data.success) {
        if (data.token) {
          // Token returned
          await this.$store.dispatch('setLoginData', data);
          if (data.userType === 'T') {
            if (data.showTos && !this.devConfig.skipTos && !data.isSysAdmin) {
              this.$router.push('/tos');
            } else {
              this.$router.push('/teacher');
            }
          } else if (data.userType === 'S') {
            this.$router.push('/student');
          }
        } else {
          // No token back from server
          this.$store.dispatch('cleanState');
        }
      } else {
        alert(data.errorCode);
      }
    },
    startEmailLogin() {
      if (this.$route.query.fromEmail) {
        this.beginIdmLogin();
      }
    },
    getState() {
      return this.$store.getters.getState;
    },
    isAutoLoginMode() {
      if (this.devConfig.automaticLogin) {
        return true;
      } else {
        return false;
      }
    },
    getMockIdmUsers: async function() {
      let response = await generalService.getMockIdmJSON();
      if (response) {
        this.mockIdmUsers = response.idmMockUsers;
        logger.debug(response);
      } else {
        logger.error('Error on getMockIdmUsers request.');
      }
    }
  }
};
</script>

<style>
.mock-idm-list {
  display: inline-flex;
}

.box-inside-mock {
  margin: 10px;
  padding: 10px;
  text-align: right;
}

.idmMockBtn {
  margin: 10px;
  background-color: rgb(206, 206, 206);
  border-radius: 15px;
  padding: 10px;
  color: black;
  cursor: pointer;
  text-align: center;
  transition: 0.3s;
}

.idmMockBtn:hover {
  transition: 0.3s;
  background-color: rgb(41, 46, 75);
  color: white;
  box-shadow: 0px 0px 10px -1px black;
}
</style>
