import advicesJSON from '../config/advices.json5';

export default {
  qna: {
    title: 'שאלות ותשובות',
    entries: [
      {
        question: `דף הדרכה למשתמש`,
        answer: `<div dir="rtl">
        <b> כניסה למערכת </b> 
        <br>
        <br>
        <p>כדי להיכנס למערכת יש להקליד את סיסמת ההזדהות האישית שלך ממשרד החינוך (IDM). </p>
        <br>
        <b> הכנסת תלמיד חדש/ קיים </b> 
        <br>
        <br>
        <p>הכנסת תלמיד נעשית ע"י מס' ת.ז של התלמיד.  תחת סיסמת ההזדהות האישית שלך ניתן להכניס מספר בלתי מוגבל של תלמידים. המערכת שומרת את הנתונים שהזנת על התלמידים וניתן להגיע אל הנתונים ע"פ מספר ת.ז של התלמיד. </p>
        <br>
        <b> הזנת נתונים </b> 
        <p>
            <ol style="list-style-type: hebrew;">
                <li>המערכת מבחינה בין שני סוגי תלמידים. בין תלמיד מאובחן לתלמיד שאינו מאובחן. על תלמיד מאובחן המערכת אוספת יותר נתונים שמופיעים בגוף האבחון, בעיקר בתפקודים הקוגנטיביים. לתלמיד שאינו מאובחן הנתונים המתבקשים הם מההיכרות עם תפקודי התלמיד.</li><br>
                <li>עליך לענות על כל השאלות אבל אם ישנה שאלה שבה לא ברורה לך התשובה ואת לא מצליחה להעריך את היכולת מהתרשמות כללית יש לסמן "לא ידוע" <b>ולא להשאיר ריק</b>. </li><br>
                <li>מצד ימין של המסך מופיעה רשימת התחומים הנשאלים . כל תחום שהושלם תהליך המילוי שלו יסומן ב - <img style="width: 20px; display: inline; vertical-align: middle;" src="../img/v.png" alt=""></li><br>
                <li>בסיום מילוי השאלון עליך לשלוח את השאלון באמצעות לחיצה על כפתור "<b>שלח</b>" . (בשלב הפיילוט  התוכנית התערבות האישית תתקבל לאחר שלושה ימי עבודה)</li><br>
                <li>לאחר מילוי השאלון ואף לאחר שליחתו ניתן להיכנס שוב לשאלון התלמיד ולשנות נתונים ע"פ שיקול דעתך ו"<b>המפה התפקודית</b>" ו"<b>תוכנית ההתערבות האישית</b>" ישתנו בהתאם.</li>
            </ol>
        </p>
        <br>
        <b>מפה תפקודית</b>
        <br>
        <br>
        <ol style="list-style-type: hebrew;">
            <li>המפה התפקודית של התלמיד ניתנת מיד בסיום מילוי השאלון.</li>
            <li>המפה כוללת את כל התחומים עליהם ענית בשאלון התלמיד ומציגה בפניך פריסה רחבה של יכולות וקשיים של התלמיד בתפקודים השונים: <br>
                    יכולות מסומנים בחץ בצבע ירוק     <img style="width: 20px; display: inline; vertical-align: middle;" src="../img/arrow-green.png" alt=""><br>
                   קשיים מסומנים בחץ בצבע אדום <img style="width: 20px; display: inline; vertical-align: middle;" src="../img/arrow-red.png" alt="">
            </li>
            <li>
                    המפה התפקודית האישית היא הבסיס לתוכנית ההתערבות ומאפשרת לצוות החינוכי להבין את מכלול היכולות והקשיים של התלמיד במטרה לייצר:
                    <ul style="padding-right: 30px; list-style-type: disc;">
                        <li>שפה אחידה ומקצועית של הצוות החינוכי עם התלמיד וההורים.</li>
                        <li>הפעלת שיקול דעת ויצירתיות של הצוות החינוכי עם התלמיד.</li>
                    </ul>
            </li>
        </ol>
        <br>
        <b>תוכנית התערבות אישית</b>
        <br>
        <br>
        <ol style="list-style-type: hebrew;">
            <li>תוכנית ההתערבות האישית נבנית על בסיס המפה התפקודית של התלמיד ומדויקות לצרכי התלמיד. <br>
                    התוכנית מתייחסת למספר תחומים: <br>
                    <b> התנהגותי </b> - כולל תפקודים אישיותיים והעצמה אישית.
                    <b> התארגנות </b> - כולל תחומי ההתארגנות בכיתה ומחוץ לכיתה ושיתוף הורים בהתארגנות אחה"צ.
                    <b> תפקודים אקדמיים </b> - כולל תהליכי למידה, התייחסות למבחנים והכנת שיעורי בית.
            </li><br>
            <li>
                    כל ההמלצות הן מדויקות וטובות ויסייעו לתלמיד בהתאם לפרופיל התלמיד שהזנת. אך מכיוון שמדובר בחינוך ובדיני נפשות של תלמידים ומורים, ומערכות שונות של בתי ספר בעלי יכולות הכלה מגוונות. את נדרשת להפעיל שיקול דעת בבחירת ההמלצות הנשלחות לצוות, והמלצה שנראית לך לא מתאימה מהסיבות הנ"ל באפשרותך לוותר עליה. <br>
                    <b>גם ביצוע של חלק מההמלצות יסיע ויעזור לתלמיד.</b>
            </li><br>
            <li>לנוחותך מצורפת רשימת המלצות מקבילות חלופיות. ההמלצות החלופיות נועדו למקרים בהם את חשה שיש צורך בתוכנית התערבות חזקה יותר. כדאי לפנות אליהם רק לאחר שנעזרת במערכת ההמלצות הרגילה.</li>
        </ol>
    </div>`
      },
      {
        question: 'כיצד עלי להתחבר למערכת?',
        answer:
          'כדי להיכנס למערכת יש להקליד את סיסמת ההזדהות האישית שלך ממשרד החינוך (IDM)'
      },
      {
        question: 'כיצד מצרפים תלמיד למערכת?',
        answer:
          'הכנסת תלמיד נעשית ע"י מס\' ת.ז של התלמיד תחת סיסמת ההזדהות האישית שלך'
      },
      {
        question: 'האם קיימת הגבלה על מספר התלמידים שניתן להכניס למערכת?',
        answer:
          'ניתן להכניס מספר בלתי מוגבל של תלמידים. המערכת שומרת את הנתונים שהזנת על התלמידים וניתן להגיע אל הנתונים ע"פ מספר ת.ז של התלמיד'
      },
      {
        question: 'האם המערכת נותנת מענה רק לתלמיד מאובחן?',
        answer:
          'המערכת מבחינה בין שני סוגי תלמידים. בין תלמיד מאובחן לתלמיד שאינו מאובחן. על תלמיד מאובחן המערכת אוספת יותר נתונים שמופיעים בגוף האבחון בעיקר בתפקודים הקונטיביים. לתלמיד שאינו מאובחן הנתונים המתבקשים הם מתוך ההיכרות עם תפקודי התלמיד.'
      },
      {
        question: 'מה אעשה כשאיני יודעת תשובה לשאלה?',
        answer:
          'עליך לענות על כל השאלות אבל אם ישנה שאלה שבה לא ברורה לך התשובה ואת לא מצליחה להעריך את היכולת מהתרשמות כללית יש לסמן "לא ידוע" ולא להשאיר ריק.'
      },
      {
        question: 'יש לי מידע חדש על התלמיד, מה לעשות?',
        answer: `לאחר מילוי השאלון ואפילו לאחר שליחתו ניתן להיכנס שוב לשאלון התלמיד ולשנות נתונים ע"פ שיקול דעתך ו"<b>המפה התפקודית</b>" ו"<b>התוכנית התערבות האישית</b>" ישתנו בהתאם.`
      },
      {
        question: 'מהי המפה התפקודית?',
        answer: `המפה כוללת את כל התחומים עליהם ענית בשאלון התלמיד ומציגה בפניך פריסה רחבה של יכולות וקשיים של התלמיד בתפקודים השונים: <br>
        יכולות מסומנים בחץ בצבע ירוק   <img style="display: inline;" src="../img/arrow-green.png"> <br>
        קשיים מסומנים בחץ בצבע אדום  <img style="display: inline;" src="../img/arrow-red.png"> `
      },
      {
        question: 'מה השימוש שאני יכולה לעשות במפה התפקודית?',
        answer: `מה השימוש שאני יכולה לעשות במפה התפקודית?
        המפה התפקודית האישית היא הבסיס לתוכנית ההתערבות ומאפשרת לצוות החינוכי להבין את מכלול היכולות והקשיים של התלמיד במטרה לייצר:
        <br>א-	שפה אחידה ומקצועית של הצוות החינוכי עם התלמיד וההורים<br>
        ב-	הפעלת שיקול דעת ויצירתיות של הצוות החינוכי עם התלמיד
        `
      },
      {
        question: 'מהי תוכנית ההתערבות האישית?',
        answer: `תוכנית ההתערבות האישית נבנית על בסיס המפה התפקודית של התלמיד ומתייחסת למספר תחומים:
        <b>הפניות</b> - המערכת מזהה צרכים נוספים שהתלמיד נדרש בהם לבדיקה מעמיקה יותר, המערכת אינה מאבחנת תחומים אלו אלא ממליצה לפנות לגורמי המקצוע השונים על מנת לדייק את הצורך והטיפול הנדרש בתחום הנ"ל. <br>
        <b>התנהגותי</b> - כולל תפקודים אישיותיים והעצמה אישית<br>
        <b>התארגנות</b> - כולל תחומי ההתארגנות בכיתה ומחוץ לכיתה ושיתוף הורים בהתארגנות אחה"צ<br>
        <b>תפקודים אקדמיים</b> - כולל תהליכי למידה, התייחסות למבחנים והכנת שיעורי בית
        `
      }
    ]
  },
  'knowledge-base': {
    title: 'מאגר מידע ',
    entries: [
      {
        question: 'שגיאות כתיב',
        answer: `<div>
          שגיאת כתיב היא שימוש ברצף סימנים של כתב שנועד לייצג מילה או צירוף לשוני, השונה באיותו מהכתיב התקני בשפה, ולכן מהווה שגיאה (ויקיפדיה). 
          <br>
          <br>
          <ol style="list-style-type: hebrew;">
              <li>
                  <span style="text-decoration: underline;"> מהן שגיאות כתיב חמורות (פונולוגיות)?</span><br>
                  שגיאות כתיב חמורות הן שגיאות המקשות מאוד על קריאת הכתוב, לדוגמא: 
                  <ul style="padding-right: 30px; list-style-type: disc;">
                      <li>שגיאות השמטת אותיות (שוחן- שולחן)</li>
                      <li>שגיאות הוספת אותיות (סרגרל- סרגל)</li>
                      <li>שגיאות שיכול אותיות (מקח- מחק) </li>
                      <li>שגיאות דמיון בצליל (גלי- דלי)</li>
                  </ul>
              </li>
              <br>
              <li>
                  <span style="text-decoration: underline;"> 	מהן שגיאות כתיב מצויות?</span><br>
                  שגיאות כתיב מצויות הן שגיאות נפוצות אצל תלמידים רבים, לדוגמא: 
                  <ul style="padding-right: 30px; list-style-type: disc;">
                      <li>שגיאות הומופוניות- החלפת אותיות בעלות אותו צליל  ח/כ, א/ע/ה, כ/ק, ו/ב, ט/ת, ש/ס</li>
                      <li>שגיאות מורפולוגיות-שגיאות באותיות השורש (שחחתי- שכחתי) </li>
                      <li>שגיאות אורתוגרפיות- אותיות בעלות דמיון חזותי (ג/ז), חוסר שליטה באותיות סופיות (פ/ף, מ/ם)</li>
                  </ul>
              </li>
          </ol>      
        </div>`
      },
      {
        question: 'אינטליגנציה/ חשיבה',
        answer: `<div>
        אינטליגנציה היא מכלול הכישורים שבאמצעותם ניתן לפתור בעיות הדורשות חשיבה בצורה יעילה (ויקיפדיה).
        <br>
        <span style="text-decoration: underline;">באבחון דידקטי </span>יופיע תחום חשיבה המתחלק לחשיבה בלתי מילולית וחשיבה מילולית
        <span style="text-decoration: underline;">באבחון פסיכולוגי  </span>יופיע תחום אינטליגנציה המתחלק ליכולת מילולית/ קריסטלית, יכולת ביצועית/ פלואידית  ולאינטליגנציה כללית<br>
        במידה <span style="text-decoration: underline;">והאבחון פסיכו- דידקטי  </span> יופיעו שניהם
</div>`
      },
      {
        question: 'אימפולסיביות',
        answer:
          'אימפולסיביות היא צורת התנהגות המאופיינת בפעולה ללא מחשבת תחילה. משום שפעולה אימפולסיבית מתבצעת ללא מודעות להשלכות האפשריות יש בה מרכיב של אקראיות, כך שלעיתים היא מובילה לתוצאות חיוביות ולעיתים לשליליות. (ויקיפדיה)'
      },
      {
        question: 'אסוציאטיביות',
        answer:
          'חשיבה אסוציאטיבית- במצב זה מתקיים קושי רב להגיע ליעד החשיבה, משום שחלקי החשיבה מעוררים אסוציאציות לא מקובלות ולא ענייניות. בעקבות זאת נוצרת סטייה מתמדת מיעד החשיבה ואי יכולת להגיע אליו. (ויקיפדיה)'
      },
      {
        question: 'אינטגרציה ויזו- מוטורית',
        answer:
          'אינטגרציה ויזו- מוטורית היא יכולת העיניים והידיים לעבוד בתיאום ליצירת תנועות מדויקות ויעילות המשפיעות על תוצר גרפי הולם בעיצוב צורות ובכתיבה.'
      },
      {
        question: 'שליפת מילים',
        answer: `שיום" הכוונה היא ליכולת לתת שם (תווית מילולית) לפריט כלשהו. קרויה גם "שליפת מילים" או "דליית מילים". הכוונה של המושגים הללו דומה – יכולת "להוציא" מילים מוכרות מן הזיכרון שלנו על מנת להשתמש בהן. <br>
        <a target="_blank" rel="noopener noreferrer" href="http://www.michal-icht.co.il/%D7%A7%D7%A9%D7%99%D7%99-%D7%A9%D7%99%D7%95%D7%9D-%D7%95%D7%A9%D7%9C%D7%99%D7%A4%D7%94/"> קיצור מתוך מאמרה של ד"ר מיכל איכט  </a>`
      },
      {
        question: 'עיבוד שמיעתי',
        answer: `
        קושי בעיבוד שמיעה (מרכזי) נובע מהפרעה בסינון המסר המשמעותי מתוך כלל הגירוי השמיעתי. <br>
        ההפרעה אינה נובעת מבעיה באוזן החיצונית / התיכונה / הפנימית אלא בעצבים המובילים למרכזי העיבוד הגבוהים במוח. (    <a  target="_blank" rel="noopener noreferrer" href="http://www.hadassah.org.il/%D7%90%D7%95%D7%93%D7%95%D7%AA-%D7%94%D7%93%D7%A1%D7%94/tips/%D7%A7%D7%95%D7%A9%D7%99-%D7%91%D7%A2%D7%99%D7%91%D7%95%D7%93-%D7%A9%D7%9E%D7%99%D7%A2%D7%94-%D7%9E%D7%A8%D7%9B%D7%96%D7%99.aspx">מתוך אתר של "מרכז רפואי הדסה"</a>) <br>
        <br>במענה על שאלון תלמיד באתר Edvise על שאלת קושי בעיבוד שמיעתי ניתן לענות: <br>
        <ol style="list-style-type: hebrew;">
          <li>
          התרשמותית- שומע מילים שונות משנאמרו, זקוק לחזרות על דברים שנאמרו 
          </li>
          <li>
          מתוך אבחון- ניתן למצוא מידע באבחון בתחום השפה או בתחום הזיכרון או בסיכום הממצאים בסוף האבחון
          </li>
        </il>
        `
      },
      {
        question: 'זיכרון',
        answer: `
        זיכרון הוא היכולת לאגור מידע ולהשתמש בו. בעברית, המילה "זיכרון" מתייחסת הן לתהליך והן לפרטי המידע הנאגר. בעוד שהתפישה וההכרה משמשות לקליטת גירויים, הזיכרון דרוש לשם המשכיות הלמידה. שני התהליכים העיקריים הם האחסון והשליפה (<a target="_blank" rel="noopener noreferrer" href="https://he.wikibooks.org/wiki/%D7%A4%D7%A1%D7%99%D7%9B%D7%95%D7%9C%D7%95%D7%92%D7%99%D7%94_%D7%A9%D7%9C_%D7%94%D7%97%D7%99%D7%A0%D7%95%D7%9A/%D7%A4%D7%A8%D7%A7%D7%99%D7%9D_%D7%91%D7%A4%D7%A1%D7%99%D7%9B%D7%95%D7%9C%D7%95%D7%92%D7%99%D7%94_%D7%97%D7%99%D7%A0%D7%95%D7%9B%D7%99%D7%AA/%D7%96%D7%99%D7%9B%D7%A8%D7%95%D7%9F_%D7%95%D7%A9%D7%9B%D7%97%D7%94">ויקיפדיה</a>)
        <br>
        "זיכרון מושהה"- הכוונה לזיכרון לטווח ארוך
        <br>
        "זיכרון מיידי"- הכוונה לזיכרון לטווח קצר`
      },
      {
        question: 'הפרעות זיכרון (רטרואקטיבי פרואקטיבי)',
        answer: `
        בודקים האם יש הפרעה בזיכרון בין ידע מאוחסן לידע חדש. כשיש עומס על הזיכרון יכול להופיע קושי ברכישת גירויים חדשים ובשמירה על גירויים קיימים. <br><br>
        אם אחד מסוגי ההפרעות (רטרואקטיבי\ פרואקטיבי) לא תקין יש לסמן "כן" (ישנן הפרעות זיכרון).
        `
      },
      {
        question: 'תפיסה חזותית וויזו - מוטורית',
        answer: `
        <span style="text-decoration: underline;"> תפיסת גישטאלט  </span> <br>
        תפיסת גישטאלט- עוסקת בתפיסת המרחב החזותי. טענתה העיקרית היא שאנו תופסים אובייקטים מלכתחילה כמשהו שלם ומאורגן, כתבנית ולא כאוסף של חלקים.(טקסולוגיה)
        <br>
        <span style="text-decoration: underline;"> תפיסת פרטים  </span> 
        <br>
        קליטת מידע חזותי כאוסף של חלקים ורק בסוף כמשהו שלם ומאורגן`
      }
    ]
  },
  'edit-advice': {
    title: 'כתיבת המלצות חדשות',
    entries: advicesJSON
  }
};
