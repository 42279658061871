module.exports = {
	"_id": "5c7febe861aa444f18616b3e",
	"formData": {
		"answersAboutStudent": [
			{
				"treeAnswers": [
					{
						"_id": "5c7fecf061aa444f18616bae",
						"questionId": "IsStudDiagnosed",
						"answerCode": "YES"
					},
					{
						"_id": "5c7fecf061aa444f18616bad",
						"questionId": "IsADD",
						"answerCode": "HARD"
					}
				],
				"_id": "5c7fecf061aa444f18616bac",
				"treeId": "0"
			},
			{
				"treeAnswers": [
					{
						"_id": "5c7fecf061aa444f18616bab",
						"questionId": "IsRestless",
						"answerCode": "HARD"
					},
					{
						"_id": "5c7fecf061aa444f18616baa",
						"questionId": "IsDayDream",
						"answerCode": "ATA_IDK"
					},
					{
						"_id": "5c7fecf061aa444f18616ba9",
						"questionId": "IsImpulsive",
						"answerCode": "ATA_IDK"
					},
					{
						"_id": "5c7fecf061aa444f18616ba8",
						"questionId": "IsSlowToAct",
						"answerCode": "ATA_IDK"
					},
					{
						"_id": "5c7fecf061aa444f18616ba7",
						"questionId": "IsAssociative",
						"answerCode": "ATA_IDK"
					},
					{
						"_id": "5c7fecf061aa444f18616ba6",
						"questionId": "IsHardToFinish",
						"answerCode": "ATA_IDK"
					},
					{
						"_id": "5c7fecf061aa444f18616ba5",
						"questionId": "IsVentilation",
						"answerCode": "HARD"
					},
					{
						"_id": "5c7fecf061aa444f18616ba4",
						"questionId": "IsInfoFlood",
						"answerCode": "ATA_IDK"
					},
					{
						"_id": "5c7fecf061aa444f18616ba3",
						"questionId": "IsVisualAttention",
						"answerCode": "NO"
					},
					{
						"_id": "5c7fecf061aa444f18616ba2",
						"questionId": "IsActiveWorkingMemory",
						"answerCode": "YES_IDK"
					},
					{
						"_id": "5c7fecf061aa444f18616ba1",
						"questionId": "IsNeuroDiagnosed",
						"answerCode": "YES_UNCARED"
					}
				],
				"_id": "5c7fecf061aa444f18616ba0",
				"treeId": "1"
			},
			{
				"treeAnswers": [
					{
						"_id": "5c7fecf061aa444f18616b9f",
						"questionId": "WordRecall",
						"answerCode": "HARD"
					},
					{
						"_id": "5c7fecf061aa444f18616b9e",
						"questionId": "AutoQuickNaming",
						"answerCode": "YES_IDK"
					},
					{
						"_id": "5c7fecf061aa444f18616b9d",
						"questionId": "PhonologicStream",
						"answerCode": "NO"
					},
					{
						"_id": "5c7fecf061aa444f18616b9c",
						"questionId": "SemanticStream",
						"answerCode": "NO_IDK"
					},
					{
						"_id": "5c7fecf061aa444f18616b9b",
						"questionId": "AudibleTextPace",
						"answerCode": "YES"
					},
					{
						"_id": "5c7fecf061aa444f18616b9a",
						"questionId": "AudibleProcess",
						"answerCode": "NO"
					},
					{
						"_id": "5c7fecf061aa444f18616b99",
						"questionId": "Vocabulary",
						"answerCode": "HARD"
					},
					{
						"_id": "5c7fecf061aa444f18616b98",
						"questionId": "ByHeartParticipation",
						"answerCode": "HARD"
					}
				],
				"_id": "5c7fecf061aa444f18616b97",
				"treeId": "2"
			},
			{
				"treeAnswers": [
					{
						"_id": "5c7fecf061aa444f18616b96",
						"questionId": "ContextualMemoryImmediate",
						"answerCode": "NO"
					},
					{
						"_id": "5c7fecf061aa444f18616b95",
						"questionId": "ContextualMemoryPostponed",
						"answerCode": "YES"
					},
					{
						"_id": "5c7fecf061aa444f18616b94",
						"questionId": "SingletonMemoryImmediate",
						"answerCode": "YES"
					},
					{
						"_id": "5c7fecf061aa444f18616b93",
						"questionId": "SingletonMemoryPostponed",
						"answerCode": "NO"
					},
					{
						"_id": "5c7fecf061aa444f18616b92",
						"questionId": "MemoryIdentification",
						"answerCode": "YES"
					},
					{
						"_id": "5c7fecf061aa444f18616b91",
						"questionId": "MemoryLearnRepetitionCurve",
						"answerCode": "NO"
					},
					{
						"_id": "5c7fecf061aa444f18616b90",
						"questionId": "MemoryLearnCurveSuddenDrop",
						"answerCode": "YES"
					},
					{
						"_id": "5c7fecf061aa444f18616b8f",
						"questionId": "RetroProActiveMemory",
						"answerCode": "NO"
					}
				],
				"_id": "5c7fecf061aa444f18616b8e",
				"treeId": "3"
			},
			{
				"treeAnswers": [
					{
						"_id": "5c7fecf061aa444f18616b8d",
						"questionId": "IsStudPsychDiagnosed",
						"answerCode": "YES"
					},
					{
						"_id": "5c7fecf061aa444f18616b8c",
						"questionId": "IsGeneralIntl",
						"answerCode": "ABN_LOW"
					},
					{
						"_id": "5c7fecf061aa444f18616b8b",
						"questionId": "IsVerbalCrystal",
						"answerCode": "ABN_LOW"
					}
				],
				"_id": "5c7fecf061aa444f18616b8a",
				"treeId": "5b"
			}
		],
		"isSubmitted": true,
		"teacherComment": "כגדכגד"
	},
	"recommendationsData": {
		"recommendationCodes": [
			1,
			7,
			103,
			323,
			329,
			322,
			21,
			223,
			326,
			331
		],
		"status": "APPROVED"
	},
	"schoolClass": {
		"schoolName": "בגין הרצליה",
		"semelMosad": "789123",
		"classGrade": "א",
		"classNumber": 1
	}
}