import httpService from './http-service';
import apiConfig from './app-api';
// import stubs from '../util/stubs';

export default {
  getStudentDummyData: async function(token) {
    const response = await httpService.get(apiConfig.getStudentDummyDataUrl(), {
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': token
      }
    });
    if (response) {
      return response.data;
    } else {
      return response;
    }
  }
};
