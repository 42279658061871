export default {
  appServerProtocolAndHost: '{{AppServerProtocolAndHost}}',
  appServerPort: '{{AppServerPort}}',
  appServerUri: '{{AppServerUri}}',
  appClientProtocolAndHost: '{{AppClientProtocolAndHost}}',
  appClientPort: '{{AppClientPort}}',

  // This is for the API endpoints
  getAppServerUrl() {
    if (this.appServerProtocolAndHost.startsWith('{{')) {
      return 'http://localhost:3000';
    } else {
      return `${this.appServerProtocolAndHost}${
        this.appServerPort != '80' ? ':' + this.appServerPort : ''
      }${this.appServerUri}`;
    }
  },

  // This is for links to be shared with users, which go through client routes
  getAppClientUrl() {
    if (this.appClientProtocolAndHost.startsWith('{{')) {
      return 'http://localhost:8080';
    } else {
      return `${this.appClientProtocolAndHost}${
        this.appClientPort != '80' ? ':' + this.appClientPort : ''
      }`;
    }
  },

  getMockIdmJSON() {
    return `${this.getAppServerUrl()}/users/mock-idm-json`;
  },

  getIdmLoginUrl() {
    return `${this.getAppServerUrl()}/users/login`;
  },

  getNormalLoginUrl() {
    return `${this.getAppServerUrl()}/users/normal-login`;
  },

  getMockLoginUrl() {
    return `${this.getAppServerUrl()}/users/mock-login`;
  },

  getMockMailUrl() {
    return `${this.getAppServerUrl()}/mail/contact-us`;
  },

  getMockAcceptTosUrl() {
    return `${this.getAppServerUrl()}/tos/protected/accept-tos`;
  },

  getStartOpenIdSessionUrl() {
    return `${this.getAppServerUrl()}/users/start-openid-session`;
  },

  getFileUploadUrl() {
    return `${this.getAppServerUrl()}/teacher/excelupload`;
  },

  getJsonUploadUrl() {
    return `${this.getAppServerUrl()}/teacher/json-upload`;
  },

  // Teacher URLs

  getManagmentToolDataUrl() {
    return `${this.getAppServerUrl()}/teacher/protected/studentmanagement`;
  },

  getStudentDeleteUrl() {
    return `${this.getAppServerUrl()}/teacher/protected/deletestudent`;
  },

  getRegisterStudentUrl() {
    return `${this.getAppServerUrl()}/teacher/protected/register-student`;
  },

  getUpdateStudentUrl() {
    return `${this.getAppServerUrl()}/teacher/protected/update-student`;
  },

  getTeacherDummyDataUrl() {
    return `${this.getAppServerUrl()}/teacher/protected/test`;
  },

  getTeacherDummySwitchSchoolUrl() {
    return `${this.getAppServerUrl()}/teacher/protected/switch-school`;
  },

  getSearchStudentUrl() {
    return `${this.getAppServerUrl()}/teacher/protected/search-student`;
  },

  getStudentByObjIdUrl() {
    return `${this.getAppServerUrl()}/teacher/protected/get-student-by-objid`;
  },

  getStudentsListUrl() {
    return `${this.getAppServerUrl()}/teacher/protected/get-student-list`;
  },

  getUpdateAnswersAboutStudentUrl() {
    return `${this.getAppServerUrl()}/teacher/protected/update-answers-about-student`;
  },

  getUpdateSelectedRecommendationsUrl() {
    return `${this.getAppServerUrl()}/teacher/protected/update-student-selected-recommendations`;
  },

  getSubmitProfileFormUrl() {
    return `${this.getAppServerUrl()}/teacher/protected/submit-form`;
  },

  getUpdateCommentsFormUrl() {
    return `${this.getAppServerUrl()}/teacher/protected/update-comments`;
  },

  // Student urls
  getStudentDummySwitchSchoolUrl() {
    return `${this.getAppServerUrl()}/student/protected/test`;
  },

  getStudentDummyDataUrl() {
    return `${this.getAppServerUrl()}/student/protected/test`;
  },

  //-------------------admin urls
  getUpdateAdvicesUrl() {
    return `${this.getAppServerUrl()}/admin/protected/update-advices`;
  },

  getRegisterTeacherUrl() {
    return `${this.getAppServerUrl()}/admin/protected/register-teacher`;
  },

  getRegisterTeachersUrl() {
    return `${this.getAppServerUrl()}/admin/protected/register-teachers`;
  },

  getApproveAdvicesUrl() {
    return `${this.getAppServerUrl()}/admin/protected/approve-advices`;
  },

  getSchoolsManagementUrl() {
    return `${this.getAppServerUrl()}/admin/protected/schools-statistics`;
  },

  getSwitchSchoolUrl() {
    return `${this.getAppServerUrl()}/admin/protected/switch-school`;
  },
  getSwitchSchoolGeneratorUrl() {
    return `${this.getAppServerUrl()}/admin/protected/switch-school-generator`;
  },
};


