import winston from 'winston';
import setImmediate from 'setimmediate';

const logger = winston.createLogger({
  transports: [
    new winston.transports.Console({
      level: 'debug',
      json: false,
      colorize: true
    })
  ],
  format: winston.format.json()
});

export default logger;
