<template lang="pug">
  .layout-with-sidebar
    .view(:class="{flex: $route.meta.hasOwnProperty('fullpageapi')}",
      style="min-height: calc(100vh - 56px);",
      :style="{'padding-right': (!$route.meta || $route.meta.withoutSidebar) ? '0' : '30px', \
          height: $route.name === 'SchoolsManagement' ? 'calc(100vh - 56px)' : 'unset'}")
      router-view(:mode="profileFormMode()")
    .side-image(v-if="desktop && (!$route.meta || !$route.meta.withoutSidebar)", :class="{'flex align-center': sideView('IMG')}")
      div(v-if="sideView('IMG')")
        img(v-show="false", :src="img()")
      div(v-if="sideView('NAV')")
        ModularSideBar(:config="modularSideBarConfig()")
</template>

<script>
import util from '@/util/util';
import ModularSideBar from '@/components/side-by-side/ModularSideBar';
import sideViewConfig from '@/config/side-view-config.json5';

export default {
  name: 'LayoutWithSidebar',
  components: {
    ModularSideBar
  },
  data() {
    return {
      desktop: util.isDesktop(),
      locationName: null
    };
  },
  watch: {
    $route() {
      this.defineLocationName();
    }
  },
  created() {
    util.listenToResize.call(this);
    this.defineLocationName();
  },
  methods: {
    modularSideBarConfig() {
      if (this.locationName) {
        let config = sideViewConfig[this.locationName];
        // if on route admin, add admin true to config.
        if (this.$route.path.indexOf('admin') > -1) {
          config.NAV.advisor = true;
          config = { ...config, admin: true };
        }
        return config;
      }
      return null;
    },
    sideView(itemCodeToShow) {
      // if there is not locationName, just go to default and show an img.
      if (!this.locationName) {
        return itemCodeToShow === 'IMG';
      }
      // otherwise, show the item listed in the json.
      try{
        return sideViewConfig[this.locationName].side === itemCodeToShow;
      }
      catch(e){
        return false;
      }
    },
    img() {
      try {
        // if the require fails (and there is not picture), the app wont break.
        if (!this.locationName) {
          // login path is '/' and tos img is same as login
          this.locationName = 'tos';
        }
        if (this.locationName === 'edit-student') {
          this.locationName = 'new-student';
        }
        let img = require(`@/assets/img/side-images/${this.locationName}.png`);
        return img;
      } catch (e) {
        console.warn(e);
        return require(`@/assets/img/side-images/tos.png`);
      }
    },
    defineLocationName() {
      let path = this.$route.path.split('/');
      // the last item on this arr will be the location name of the route.
      // for example, /admin/view-profile-form. locationName will be view-profile-form.
      // this can be used to 'find' a config object in the json config.
      this.locationName = path[path.length - 1];
    },
    profileFormMode() {
      return this.locationName.split('-')[0];
    }
  }
};
</script>


<style lang="scss" scoped>
@import '@/assets/scss/style.scss';

.layout-with-sidebar {
  display: flex;
}

.side-image {
  width: 33%;
  background-color: $color-blue;
  // page minus footer and header
  //height: calc(100vh - 112px);
  // overflow: hidden;
  // max-height:
  img {
    @media (max-width: 1366px) {
      max-height: 466px;
    }
  }
}

.view {
  width: -webkit-fill-available;
}
</style>
