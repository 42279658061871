var options = {
  devMode: 'DEV',
  prodMode: 'PROD',
  'development': 'DEV',
  'production': 'PROD'
};

export default {
  currentMode: options[process.env.NODE_ENV],
  automaticLogin: false,
  skipTos: false,
  forceIdmLoginInPopup: false, // this is for testing with login in popup
  options
};
