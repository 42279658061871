export default {
    reviews: [
        {
            "writer": "חני כ.\nמנחת סל איש",
            "content": "חל שינוי גדול בתלמידה מה שלא קרה שנים רבות , האמא מספרת שרואה שינוי גם בבית ",
            "readMore": "פשוט מדהים, <span class='edvise'>Edvise</span> קלה לשימוש, מקבלת אינפורמציה בכל התחומים וממוקדת. המחנכת והסייעת נהננו מאוד, ההמלצות נתנו להן כלים כיצד לקדם את התלמידה. חל שינוי גדול בתלמידה מה שלא קרה שנים רבות, האמא מספרת שרואה שינוי גם בבית."
        },
        {
            "writer": "אלינועם ס.\nרכזת שילוב",
            "content": "<span class='edvise'>Edvise</span> מסייע רבות בשיח מול ההורים, כשמציגים להם את המפה התפקודית וההתאמות הם מבינים ומשתפים פעולה בצורה מיטבית ",
            "readMore": "בתהליך פשוט ע\"י לחיצת כפתורים פשוטה שמובילה לדבר גדול!! <span class='edvise'>Edvise</span> הוא הכלי היחיד שכאשר ממלאים שאלון מקבלים באופן מיידי המלצות לעבודה בשטח.מסייע הרבה למחנכת ולמורת שילוב בתהליך העבודה עם התלמידים.למורת השילוב הוא מדייק את הקשיים ונותן תמונת מצב על התלמידה בקשיים ובחוזקות ע\"י המפה התפקודית ובכך מאפשר שיח איכותי בהכוונה של המחנכים על התלמידים. למחנכת בכך שהיא מקבלת סיוע מיידי בהתאמות עבור התלמידים ללמידה בכיתה, לעבודה, למבחנים וכדו'.. המחנכת משתפת בהתאמות את כלל הצוות המקצועי וכך יש אחידות בעבודה מול התלמידים. כולן שותפות לקידום שלה!! ובנוסף לכל זה. אדוויס מסייע רבות בשיח מול ההורים, כשמציגים להם את המפה התפקודית וההתאמות הם מבינים ומשתפים פעולה בצורה מיטבית."
        },
        {
            "writer": "שרי א.\nמורת שילוב",
            "content": "<span class='edvise'>Edvise</span> עוזר לי מאוד בבנית התחי ",
            "readMore": "<span class='edvise'>Edvise</span> עוזר לי מאוד בבנית התח''י. השאלות של הממשק יוצרות שיתוף פעולה שלי המורת שילוב עם המחנך, הפרופיל תלמיד יוצא מדוייק ו<span class='edvise'>Edvise</span> יוצרת מטרות ודרכי הוראה מותאמות לתלמיד.תודה על הכלי הנפלא הזה!"
        },
        {
            "writer": "אפרת ח.\nמנחה מתכללת, בי\"ס יסודי",
            "content": "היה נהדר להראות במפה איך אפשר לפצות קשיים בעזרת החוזקות ",
            "readMore": "היה נהדר להראות במפה איך אפשר לפצות קשיים בעזרת החוזקות כאשר המפה נמצאת מול עיננו היה קל וישים למצוא פתרונות."
        },
        {
            "writer": "גאולה ב.\nמנחה מתכללת בתיכונים",
            "content": "<span class='edvise'>Edvise</span> קלה וידידותית לשימוש,ההמלצות מפורטות מאוד וברורות להבנה ויישום של צוות בחינוך הרגיל ",
            "readMore": "אני אישית נהנת לעבוד עם <span class='edvise'>Edvise</span> היא קלה וידידותית לשימוש. ההמלצות מפורטות מאוד וברורות להבנה ויישום של צוות בחינוך הרגיל. אני רואה הקבלה של יסודות התפקוד, הלימודי חברתי וריגשי בכל הגילאיים לכן זה מתאים. רוב מנהלות התיכון קיבלו בברכה את התוכנה המשך עשיה ברוכה!"
        },
        {
            "writer": "גבריאל ט.\nמנחה מתכלל בתיכונים",
            "content": "המורה שמרגיש שותף פעיל שהרי הוא מילא את השאלון ויש לו חלק בפיתוח התוכנית שנוצרה",
            "readMore": "<span class='edvise'>Edvise</span> אכן הביאה משב רוח חדש מנקודת מבט של הנחייה מתכללת.ראשית חלק מן ההמלצות שהיא מביאה, על פי רוב תואמות את השיח המקדים שלנו עם המורים ואז הם מרגישים שאכן יש \"הבנה\" למערכת הזו.שנית היא מגייסת את המורים כי זו לא אמירה של מנחה מול חוות דעת של מחנך אלא זה משהו ניטרלי שנותן רעיונות והמלצות אז אין התנגדות רגשית למורה לשתף עם זה פעולה.התוכנית העלתה את יכולות ההנחיה על ידי העשרת תוכניות ההתערבות שנבנות יחד עבור התלמידים והן בשיתוף הפעולה עם המורה שמרגיש שותף פעיל שהרי הוא מילא את השאלון ויש לו חלק בפיתוח התוכנית שנוצרה."
        },
        {
            "writer": "מזל נ.\nרכזת מנחות סל אישי",
            "content": "מדהים...נפלא ביותר!",
            "readMore": "מדהים...נפלא ביותר!\nחבל שאין לי דרך להסריט את המורות, יועצות וסייעות בזמן קבלת תוכנית ההתערבות והמפה התפקודית"
        },
        {
            "writer": "איריס י.\nמנחה מתכללת",
            "content": "המחנכת הופכת להיות מקצועית יותר, ולעבוד בסיעור מוחין בשיתוף עם כל הצוות המקצועי. ההורים שמחים ומשתפים פעולה ",
            "readMore": "תוכנית נפלאה אשר מגלה באופן מיידי את היכרות המורה עם תפקודי התלמיד, פריסה רחבה של יכולות וקשיי התלמיד בתפקודים השונים, ומאפשרת לצוות החינוכי להבין את מכלול היכולות והקשיים של התלמיד ולייצר שפה אחידה ומקצועית. המנהלות והמנהלים שמחים עד מאד על כך שניתנת לתלמיד מפה תפקודית ומדויקת לצרכיו ונעשה כל מיפוי האפשרויות. המחנכת הופכת להיות מקצועית יותר, ולעבוד בסיעור מוחין בשיתוף עם כל הצוות המקצועי. ההורים שמחים ומשתפים פעולה, והתלמידים מקבלים התייחסות למבחנים והכנת ש\"ב בכיתה ומחוץ לכיתה, העצמה אישית, ובמידת הצורך פנייה לגורמי מקצוע שונים.\n יישר כח!"
        },
        {
            "writer": "שרה א.\nרכזת הכלה והשתלבות",
            "content": "שיתוף הפעולה עם ההורים הוא אחר לגמרי כשמשתמשים ב־<span class='edvise'>Edvise</span> ",
            "readMore": "השימוש ב־<span class='edvise'>Edvise</span> מדייק אותנו מאוד בבית ספר. בהכנות לפגישות מורכבות עם הורים אנחנו מכינים את הפגישה דרך מערכת <span class='edvise'>Edvise</span> מזינים את הפרטים על התלמיד, המחנך בונה תוכנית מדוייקת לעבודה בכיתה. והדבר המדהים הוא כשמציגים להורים את המפה התפקודית איך מעצימים אצל הילד את החוזקות שלו. שיתוף הפעולה עם ההורים הוא אחרת לגמרי כשמשתמשים ב־<span class='edvise'>Edvise</span>."
        },
        {
            "writer": "אוהד ו.\nמנהל בי\"ס",
            "content": "<span class='edvise'>Edvise</span> נותנת את המענים לעבודה עם התלמיד בצורה בהירה.",
            "readMore": "<span class='edvise'>Edvise</span> עוזרת לדייק את העבודה המקצועית עם תלמידי השילוב.היא נותנת את המענים לעבודה עם התלמיד בצורה בהירה."
        },
        {
            "writer": "דורית מ.\nאמא לילד בכיתה ז'",
            "content": "קיבלתי ביד חוברת של סיכום האבחון שעבר בני. עכשיו מאיפה מתחילים? איך אני מבינה מה בדיוק זה אומר? איך אדע מה מגיע לבני? מה לבקש ולצפות מבית הספר? פניתי לעזרה ל־<span class='edvise'>Edvise</span> ופתאום הכל התבהר. הכל מונח מול העיניים בצורה מופשטת ומסודרת גם להורים וגם למורים. נשאר לנו רק לגשת לעבודה...",
            "readMore": null
        }
    ]
}