<template>
  <div
    class="teacher"
  >
    <router-view :mode="constants.TEACHER" />
  </div>
</template>

<script>
// @ is an alias to /src
import constants from '@/constants/app-constants.js';

export default {
  name: 'Teacher',
  data() {
    return {
      constants
    };
  }
};
</script>
