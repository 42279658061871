<template lang="pug">
.flex.justify-center.align-center.full-height-page
  .contact-container
    .edvise-page-title {{ $t('login') }}
    div(style="display: flex; justify-content: left; margin-bottom: 10px;")
      div(id="g_id_onload", data-client_id="15866967082-g8m8gfjuibvuo4u4o7h98ftgdpul3j4t.apps.googleusercontent.com",
        data-context="signin", data-ux_mode="popup", :data-login_uri="origin+'/api/users/google-login'",
        data-auto_prompt="false")
      div(lang="he" class="g_id_signin", data-type="standard", data-shape="rectangular",
        data-theme="filled_blue", data-text="signin_with", data-size="large",
        data-locale="he_IL", data-logo_alignment="left", style="width: 270px;")

    .idm-sso-btn(style="text-align: left; margin-bottom: 20px")
      button(type="button", @click="idmLogin")
        img(src="/img/logo-edu.png", style="height: 32px;")
        | כניסה דרך משרד החינוך
    form.form-content(action="!#", @submit.prevent="processLogin")
      .inputs-container
        .input-container.placeholder
        label.edvise-input-label.contact-label(for="tz") {{ $t('labels.tz') }}
        input#tz.edvise-input.contact-input(v-model="formData.tz", type="text", @blur="touched.tz = true")
        .validation-text(:class="{'show': touched.tz && !legalTz(parseInt(formData.tz)) }") תעודת זהות לא תקינה
        .input-container.placeholder
          label.edvise-input-label.contact-label(for="firstname") {{ $t('labels.password') }}
          input#password.edvise-input.contact-input(v-model="formData.password", type="password", @blur="touched.password = true")
          .validation-text(:class="{'show': touched.password && formData.password.length < 8 }") הסיסמה צריכה להיות באורך 8 תווים לפחות
    div.notAllowed(v-if="notAllowed") משתמש זה אינו מורשה כניסה למערכת
    .send-btn
      button.search-btn.round-btn-blue(type="submit", :disabled="!isFormValid", :class="{ 'disabled-btn': !isFormValid }") התחברות

</template>

<script>
import generalService from '../services/general-service';
import teacherService from '../services/teacher-service';
import appConfig from '../config/dev-config';
import logger from '@/services/logger.js';

export default {
  name: 'NormalLogin',
  data: ()=>({
    formData: {
      tz: null,
      password: null
    },
    touched: {
      tz: null,
      password: null
    },
    rules: {
      required: value => !!value || '',
      email: () => this.email || ''
    },
    notAllowed: false
  }),
  computed:{
    isFormValid() {
      return this.validateFormFields();
    },
    origin(){
      return location.origin
    }
  },
  mounted() {
    console.log(this.$route.query.state)
    if(this.$route.query?.login){
      this.handleLoginSuccess(JSON.parse(this.$route.query.login))
    }
    if(location.search === '?notallowed') this.notAllowed = true
    // this.processIdmCallback();
  },
  methods: {
    legalTz(num) {
      var tot = 0;
      var tz = String(num).padStart(9,'0');
      for (let i = 0; i < 8; i++) {
        let x = (((i % 2) + 1) * tz.charAt(i));
        if (x > 9) {
          x = x.toString();
          x = parseInt(x.charAt(0)) + parseInt(x.charAt(1));
        }
        tot += x;
      }
      return (tot + parseInt(tz.charAt(8))) % 10 === 0;
    },
    validateFormFields() {
      for (let key in this.formData) {
        if (!this.formData[key]) {
          return false;
        }
        if (key === 'password' && this.formData.password.length < 8) {
          return false;
        }
        if (key === 'tz' && !this.legalTz(parseInt(this.formData.tz))){
          return false
        }
      }
      return true;
    },
    async idmLogin(){
      const state = {
        clientRedirectUri: this.$route.query.redirectUri,
        studentTz: this.$route.query.studentTz,
        studentObjId: this.$route.query.studentObjId
      };

      // In this case the regular redirect URL from the IDM client settings on the server will be used
      let result = await generalService.startOpenIdSession(null, state);
      if (!result.success) {
        logger.error('Could not connect to EDU identity management');
        // TODO: Show some visible error message
        return;
      }
      window.location.href = result.url;
    },
    async processLogin() {
      console.log('login action')
      try {
        // If we are developing locally and the popup took place in a popup window, we have to explicitly specify
        // the path suffix to which the post-login redirect was made, otherwise the server will use its regular configuration
        // and there will be a mismatch. And we don't want to manually modify the redirect_uri in our server settings every time.
        // NOTE: No slashes are supported. the value of declaredPathSuffixForIdm can be 'login-popup-callback.html', but not
        // /some/subpath/login-popup-callback.html, because then the router will not match the resulted route with this component.
        let explicitRedirectUri = null;
        if (this.declaredPathSuffixForIdm) {
          explicitRedirectUri = `${location.protocol}//${location.host}/${
            this.declaredPathSuffixForIdm
          }`;
        }

        // the received base64-encoded state is in the query params. Decode with atob
        // const state = JSON.parse(atob(this.$route.query.state));
        // console.log('state from query: ', state);
        // const { clientRedirectUri, studentObjId, studentTz } = state;

        let loginResult = await generalService.normalLogin(
          this.formData.tz, this.formData.password
        );
        if (loginResult.success) {
          this.handleLoginSuccess(
            loginResult.data
          );
        } else {
          // No token back from server
          this.$store.dispatch('cleanState');
          // logger.error('Error logging in: ' + loginResult.errorCode);
          // this.$router.push('/');
        }
      } catch (err) {
        // TODO: Replace this with some notify message
        // console.error(new Date() + ': Error processing login: ' + err);
        // this.$router.push('/');
      }
    },
    async handleLoginSuccess(
      data,
      studentObjId = null,
      studentTz = null,
      clientRedirectUri = null
    ) {
      await this.$store.dispatch('setLoginData', data, clientRedirectUri);
      if (data.userType === 'T') {
        if (studentObjId) {
          this.handleStudentObjId(atob(studentObjId));
        }
        if (studentTz) {
          this.handleStudentTz(atob(studentTz));
        }
        if (data.showTos && !appConfig.skipTos && !data.isSysAdmin) {
          this.$router.push('/tos');
        } else {
          if (clientRedirectUri) {
            this.$router.push(clientRedirectUri);
          } else {
            this.$router.push('/teacher');
          }
        }
      } else if (data.userType === 'S') {
        this.$router.push('/student');
      }
      logger.debug(data);
    },
    handleStudentTz(studentTz) {
      this.$store.commit('teacher/setSearchedTz', studentTz);
    },
    async handleStudentObjId(studentObjId) {
      const token = this.$store.getters.getToken;
      const res = await teacherService.getStudentByObjId(token, studentObjId);
      if (res) {
        if (res.data) {
          if (res.data.success) {
            this.$store.commit('teacher/setSelectedStudent', res.data.student);
          }
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../assets/scss/style.scss';

.edvise-page-title {
  margin-top: 4rem;
  @media (max-width: 1366px) {
    padding-right: 20px;
  }
}


.form-content {
  @media (max-width: 1366px) {
    //max-height: 43vh;
    overflow: auto;
    padding-right: 20px;
  }
}
.full-height-page{
  height: calc(100vh - 56px);
}

.map-title {
  font-family: $font-main;
  font-size: 40px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.96;
  letter-spacing: normal;
  color: $color-blue;
  padding: 0rem 1rem 2rem 0rem;
  margin: 0;
  text-align: right;
}
.clean-link {
  color: $color-blue;
  text-decoration: underline;
}
.container {
  margin: 0 auto;
  padding: 4rem 4rem 4rem 0rem;
  width: 48rem;
  font-family: $font-main;
}
.map-text {
  font-size: 16px;
  letter-spacing: normal;
  text-align: right;
  padding: 1rem 1rem 0 0;
  color: $color-blue;
  direction: rtl;
}

label{
  width: 200px;
  display: inline-block;
  text-align: left;
  padding-left:10px;
}

.contact-container{
  direction: rtl;
  text-align: right;
}

.contact {
  text-align: right;
  direction: rtl;

  label{
    width: 200px;
    text-align: left;
    padding-left:10px;
  }

  .contact-container {
    width: 65%;
    height: 100%;
  }

  .inputs-container {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    margin-top: 1em;
  }

  .textarea-container {
    width: 100%;
  }

  .input-container {
    // width: 50%;
    // display: inline-block;
  }
  // .contact-us-instructions {
  //   margin-bottom: 1.5em;
  // }

  .contact-input {
    direction: rtl;
    padding: 22px 10px;
    font-size: 16px;
    // width: 90%;
    width: 315px;
  }

  #message.contact-input {
    width: 100%;
    padding: 10px;
    height: 56px;
    line-height: 32px;
    font-size: 16px;
    resize: none;
  }
}

.send-btn {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  @media (max-width: 1366px) {
    padding-right: 20px;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.validation-text {
  text-align: left;
  color: red;
  font-weight: 600;
  font-size: 16px;
  visibility: hidden;

  &.show {
    visibility: initial;
  }
}

.disabled-btn {
  cursor: auto;
}

.contact-label {
  font-size: 16px;
  line-height: 3vh;
}
.idm-sso-btn > button{
  background: #4c6aaf;
  padding: 12px 30px;
  border-radius: 3px;
  border: none;
  will-change: box-shadow, transform;
  transition: box-shadow .2s cubic-bezier(.4,0,1,1), background-color .2s cubic-bezier(.4,0,.2,1);
  font-weight: 400;
  text-align: center;
  width: 270px;
  display: inline-flex;
  align-items: center;
  gap: 3px;
  font-size: 17px;
  color: white;
}
.notAllowed{
  text-align: center;
  padding-bottom: 10px;
  font-size: 2em;
  font-weight: bold;
  color: darkred;
}
</style>
