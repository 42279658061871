<template lang="pug">
.links-card
  router-link.link(tag="div", :to="`${relativePath}/view-profile-form?section=0`")
    | {{ $t('navbar.studentDetails') }}
  router-link.link(tag="a", target="_blank", :to="`/map`")
    | {{ $t('navbar.studentChart') }}
  router-link.link(v-if="showGenerator", :class="{'router-link-active': $route.path.endsWith('generator') }", tag="div", :to="`${relativePath}/generator`")
    | {{ $t('navbar.generators') }}
  router-link.link(tag="div", :class="{'router-link-active': $route.path === `${relativePath}/edit-advice` }", :to="`${relativePath}/view-advice`")
    | {{ $t('navbar.advice') }}
  router-link.link(v-if="isAdvices", tag="div", :to="`${relativePath}/alt-advice`")
    | {{ $t('navbar.altAdvice') }}

</template>

<script>
import relativePathMixins from '@/mixins/relative-path-mixins';
import store from "@/store";
//TODO: change the query at advice html link!!
export default {
  name: 'LinksCard',
  mixins: [relativePathMixins],
  computed: {
    showGenerator(){
      if (store.getters['teacher/isSysAdmin']) {
        return true;
      }
      const student = this.$store.getters['teacher/getSelectedStudent'];
      if (!student) return false
      const schoolList = this.$store.getters['teacher/getSchoolList'];
      const semelMosad = student.schoolClass.semelMosad
      const school = schoolList.find(x=>x.semelMosad === semelMosad)
      if (!school) return false
      return school.generator || this.$store.getters['getDevMode']
    },
    isAdvices() {
      const student = this.$store.getters['teacher/getSelectedStudent'];
      if (student) {
        if (student.recommendationsData) {
          if (student.recommendationsData.recommendationCodes) {
            if (student.recommendationsData.recommendationCodes.length > 0) {
              return true;
            }
          }
        }
      }
      return false;
    }
  },
  created() {
    this.manageRelativePath();
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/style.scss';

.links-card {
  text-align: right;
}

.link {
  font-size: $font-size-txt;
  color: $color-white;
  line-height: 40px;
  cursor: pointer;
  text-decoration: none;

  &.router-link-active {
    font-weight: 600;
    color: $color-peach;
  }
}
</style>
