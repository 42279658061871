<template lang="pug">
  .map-card.flex.flex-wrap.space-between(:class=`{
      empty:
        (!answersAboutStudentObj[currentIndex] ||
        shouldBeHidden(currentIndex)) &&
        (Number(currentIndex) === 4 ||
        Number(currentIndex) === 5 ||
        Number(currentIndex) === 3),
    }`)
    .card-setup(v-if="Number(currentIndex) !== 9")
      .card-hdr {{ fixI18nSpaces('functionalMapSubHdrs.' + [currentIndex]) }}
      ul(v-if=`
          answersAboutStudentObj[currentIndex] && !shouldBeHidden(currentIndex)
        `)
        .card-secondary-title(v-if="sectionsWithSubHdr(currentIndex)") {{ fixI18nSpaces('functionalMapSubHdrs.sub' + currentIndex) }}
        div(v-for="(question, qCode) of profileFormJson[currentIndex]", v-show=`
            !(
              hiddenWhenStudNotDiagnosed.hiddenQuestions.includes(qCode) &&
              !isAllQuestionsDisplayed
            )
          `, :key="qCode")
          .card-secondary-title(v-if="questionsWithSubHdrs(qCode)")
            br(v-if="!(Number(currentIndex) === 6 && !isAllQuestionsDisplayed)")

            | {{ fixI18nSpaces('functionalMapSubHdrs.' + qCode) }}
          li.li-setup(v-if="arrowJson[qCode]", :class=`{
              up:
                'UP' === arrowJson[qCode][answersAboutStudentObj[currentIndex][qCode]],
              down:
                'DOWN' === arrowJson[qCode][answersAboutStudentObj[currentIndex][qCode]],
              null:
                'NULL' === arrowJson[qCode][answersAboutStudentObj[currentIndex][qCode]],
            }`)
            span.inner-text(dir="rtl") {{ fixI18nSpaces('functionalMap.' + qCode) }}
      .no-data(v-if=`
          !answersAboutStudentObj[currentIndex] || shouldBeHidden(currentIndex)
        `)
        div {{ fixI18nSpaces('noDataToShow') }}
    .map-card.flex.flex-wrap.space-between(v-if="Number(currentIndex) === 9")
      div
        .card.card-setup.social-card
          ul(v-if="answersAboutStudentObj[currentIndex]")
            .card-hdr {{ fixI18nSpaces('generalFuncMapSubHdrs.1') }}
            div(v-for="(question, qCode) of mapData[1]", v-show=`
                !(
                  hiddenWhenStudNotDiagnosed.hiddenQuestions.includes(qCode) &&
                  !isAllQuestionsDisplayed
                )
              `, :key="qCode")
              li.li-setup(v-if="arrowJson[qCode]", :class=`{
                  up:
                    'UP' === arrowJson[qCode][
                      answersAboutStudentObj[currentIndex][qCode]
                    ],
                  down:
                    'DOWN' === arrowJson[qCode][
                      answersAboutStudentObj[currentIndex][qCode]
                    ],
                  null:
                    'NULL' === arrowJson[qCode][
                      answersAboutStudentObj[currentIndex][qCode]
                    ],
                }`)
                span.inner-text(dir="rtl")
                  | {{
                  | fixI18nSpaces('functionalMap.' + qCode)
                  | }}
        .card-setup.learning-process-card
          ul(v-if="answersAboutStudentObj[currentIndex]")
            .card-hdr {{ fixI18nSpaces('generalFuncMapSubHdrs.' + '2') }}
            div(v-for="(question, qCode) of mapData[3]", v-show=`
                !(
                  hiddenWhenStudNotDiagnosed.hiddenQuestions.includes(qCode) &&
                  !isAllQuestionsDisplayed
                )
              `, :key="qCode")
              li.li-setup(v-if="arrowJson[qCode]", :class=`{
                  up:
                    'UP' === arrowJson[qCode][
                      answersAboutStudentObj[currentIndex][qCode]
                    ],
                  down:
                    'DOWN' === arrowJson[qCode][
                      answersAboutStudentObj[currentIndex][qCode]
                    ],
                  null:
                    'NULL' === arrowJson[qCode][
                      answersAboutStudentObj[currentIndex][qCode]
                    ],
                }`)
                span.inner-text(dir="rtl")
                  | {{
                  | fixI18nSpaces('functionalMap.' + qCode)
                  | }}
      div
        .card.card-setup.personal-card
          ul(v-if="answersAboutStudentObj[currentIndex]")
            .card-hdr {{ fixI18nSpaces('generalFuncMapSubHdrs.' + '3') }}
            div(v-for="(question, qCode) of mapData[2]", v-show=`
                !(
                  hiddenWhenStudNotDiagnosed.hiddenQuestions.includes(qCode) &&
                  !isAllQuestionsDisplayed
                )
              `, :key="qCode")
              li.li-setup(v-if="arrowJson[qCode]", :class=`{
                  up:
                    'UP' === arrowJson[qCode][
                      answersAboutStudentObj[currentIndex][qCode]
                    ],
                  down:
                    'DOWN' === arrowJson[qCode][
                      answersAboutStudentObj[currentIndex][qCode]
                    ],
                  null:
                    'NULL' === arrowJson[qCode][
                      answersAboutStudentObj[currentIndex][qCode]
                    ],
                }`)
                span.inner-text(dir="rtl")
                  | {{
                  | fixI18nSpaces('functionalMap.' + qCode)
                  | }}
        .card-setup.behavior-card
          ul(v-if="answersAboutStudentObj[currentIndex]")
            .card-hdr {{ fixI18nSpaces('generalFuncMapSubHdrs.' + '4') }}
            div(v-for="(question, qCode) of mapData[4]", v-show=`
                !(
                  hiddenWhenStudNotDiagnosed.hiddenQuestions.includes(qCode) &&
                  !isAllQuestionsDisplayed
                )
              `, :key="qCode")
              li.li-setup(v-if="arrowJson[qCode]", :class=`{
                  up:
                    'UP' === arrowJson[qCode][
                      answersAboutStudentObj[currentIndex][qCode]
                    ],
                  down:
                    'DOWN' === arrowJson[qCode][
                      answersAboutStudentObj[currentIndex][qCode]
                    ],
                  null:
                    'NULL' === arrowJson[qCode][
                      answersAboutStudentObj[currentIndex][qCode]
                    ],
                }`)
                span.inner-text(dir="rtl") {{ fixI18nSpaces('functionalMap.' + qCode) }}

</template>

<script>
// import advices from '@/config/advices.json5';
import profileFormJson from '@/config/profile-form.json5';
import hiddenWhenStudNotDiagnosed from '@/config/hidden-when-stud-not-diagnosed.json5';
import arrowJson from '@/config/questions-map-arrow-diractions.json5';
import mapData from '@/config/general-func-map-data.json5';

export default {
  name: 'MapCard',
  props: { currentIndex: { type: String, required: true } },
  data() {
    return {
      arrowJson,
      profileFormJson,
      hiddenWhenStudNotDiagnosed,
      // advices,
      mapData,
    };
  },
  computed: {
    isAllQuestionsDisplayed() {
      return this.$store.getters['teacher/isAllQuestionsDisplayed'];
    },
    answersAboutStudentObj() {
      return this.$store.getters['teacher/getAnswersAboutStudentObj'];
      // return this.$store.state.teacher.answersAboutStudentObj;
    },
  },
  // mounted() {
  //   this.generalFuncArr = this.profileFormJson[9];
  //   this.generalFuncPt1
  // },
  methods: {
    fixStringSpaces(str) {
      return str.split(' ').join(String.fromCharCode(160));
    },
    fixI18nSpaces(i18path) {
      // return this.$t(i18path).replace(' ', String.fromCharCode(160));
      return this.fixStringSpaces(this.$t(i18path));
    },
    sectionsWithSubHdr(currentIndex) {
      return (
        Number(currentIndex) === 7 ||
        Number(currentIndex) === 8 ||
        Number(currentIndex) === 2 ||
        Number(currentIndex) === 5 ||
        Number(currentIndex) === 6
      );
    },
    shouldBeHidden(currentIndex) {
      return (
        !this.isAllQuestionsDisplayed &&
        (Number(currentIndex) === 4 ||
          Number(currentIndex) === 3 ||
          Number(currentIndex) === 5)
      );
    },
    questionsWithSubHdrs(qCode) {
      return this.mapData.questionsWithSubHdr.includes(qCode);
    },
    arrowDir(qcode) {
      return this.arrowJson[qcode][this.answersAboutStudentObj[4][qCode]];
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/style.scss';
.generalfunc-card .card-setup {
  margin: 4px 0;
}
.card-setup {
  border: 1px solid black;
  width: 409px;
  overflow: hidden;

  .card-secondary-title {
    text-align: right;
    margin-right: 42px;
    font-size: 15px;
  }

  &.personal-card {
    // height: 238px;
  }

  &.social-card {
    height: 132px;
  }

  &.learning-process-card {
    // height: 252px;
  }

  &.behavior-card {
    // height: 171px;
  }
}

.empty {
  height: 150px;
}

.inner-text {
  margin-right: 30px;
}
.li-setup {
  margin-right: 32px;
  // text-align: start;
}
.up,
.down {
  background-size: 4%;
  background-position: right;
}
.up {
  background-image: url('../../assets/img/glyphicons/icon-03.png');
}
.down {
  background-image: url('../../assets/img/glyphicons/icon-02.png');
}

.card-hdr {
  margin-right: 32px;
  font-size: 16px;
  text-align: right;
  text-decoration: underline;
  font-weight: 600;
}
li {
  font-size: 16px;
  text-align: right;
  list-style-position: inside;
  direction: rtl;
}

ul {
  // padding-right: 10px;
  margin: 5px 0;
  list-style-type: none;
}

.no-data {
  text-align: right;
  margin-right: 72px;
  font-size: 15px;
}
</style>
