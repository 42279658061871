export default {
  data() {
    return {
      relativePath: this.$route.path
    };
  },
  watch: {
    $route() {
      this.manageRelativePath();
    }
  },
  methods: {
    manageRelativePath() {
      this.relativePath = this.$route.path;
      // relativePath: teacher, student or any other user can have different paths. relativePath is adapive. for example: teacher/teacherItems/1 or student/33
      //   if (this.$route.params.itemId) {
      let pathArr = this.$route.path.split('/');
      pathArr.pop(); //pops the last item of this arr, normally the param or the current location, out of the arr.
      this.relativePath = pathArr.join('/'); //result is the same uri without the param or the current location.
      // console.log({ relativePath: this.relativePath });

      //   }
      // console.log('relativePath: ', this.relativePath); //debug
    }
  }
};
