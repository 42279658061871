import _ from 'lodash';
import teacherService from '../services/teacher-service';
import logger from '@/services/logger.js';
import profileFormJson from '@/config/profile-form.json5';
import constants from '@/constants/app-constants';
import hiddenWhenStudNotDiagnosed from '@/config/hidden-when-stud-not-diagnosed.json5';

const isAllQuestionsDisplayed = answersAboutStudent => {
  if (answersAboutStudent[0]) {
    if (answersAboutStudent[0].treeAnswers[0]) {
      // if IsStudDiagnosed (the first question in the form) answer is YES, all questions should be displayed.
      return (
        answersAboutStudent[0].treeAnswers[0].answerCode ===
        constants.answers.YES
      );
    }
  }
  return false;
};

const answersTreeToObj = answersTree => {
  let answersObj = {};
  // map the obj
  for (const answerObj of answersTree.treeAnswers) {
    answersObj[answerObj.questionId] = answerObj.answerCode;
  }
  return answersObj;
};

const isFormFullyFilledByUser = (
  answersAboutStudentObj,
  isAllQuestionsDisplayed,
  state
) => {
  let treeIsNotComplete = false;
  let formIsNotComplete = false;
  for (const answersTreeId in profileFormJson) {
    if (
      !isAllQuestionsDisplayed &&
      !hiddenWhenStudNotDiagnosed.sectionsToShowForIncompleteForm.includes(
        answersTreeId
      )
    ) {
      continue; // if an uncomplete form is being run, dont test irelevant trees.
    }
    if (answersAboutStudentObj[answersTreeId]) {
      for (const qCode in profileFormJson[answersTreeId]) {
        if (
          !isAllQuestionsDisplayed &&
          hiddenWhenStudNotDiagnosed.hiddenQuestions.includes(qCode)
        ) {
          continue; // if an uncomplete form is being run, dont test irelevant questions.
        }
        if (
          !answersAboutStudentObj[answersTreeId][qCode] ||
          answersAboutStudentObj[answersTreeId][qCode] === ''
        ) {
          treeIsNotComplete = true;
          formIsNotComplete = true;
        }
      }
      if (!treeIsNotComplete) {
        // if this form is not not complete, which means complete, add it to full trees list
        state.treesFullyFilledByUser[answersTreeId] = true;
      }
      // reset this test
      treeIsNotComplete = false;
    } else {
      // if the q tree in not found in the answer about student, then the form is not complete.
      formIsNotComplete = true;
    }
  }
  if (formIsNotComplete) {
    return false;
  }
  // if false wasn't returned until this point, it means the form is complete.
  return true;
};

const setAnswersAboutStudent = state => {
  // this object represnts the questions in the form that are complete.
  // they are marked with a v in vertical bullets component.
  state.treesFullyFilledByUser = {};
  if (state.selectedStudent.formData) {
    if (state.selectedStudent.formData.answersAboutStudent) {
      /* eslint-disable */
      let answersAboutStudent = state.selectedStudent.formData.answersAboutStudent;
      let answersAboutStudentObj = {};
      // creating an object per tree, more suitable to work with
      for (const answersTree of answersAboutStudent) {
        answersAboutStudentObj[answersTree.treeId] = answersTreeToObj( answersTree );
      }
      state.answersAboutStudentObj = answersAboutStudentObj;
      
      // is form fully filled depends on is all questions diaplyed.
      state.isAllQuestionsDisplayed = isAllQuestionsDisplayed( answersAboutStudent );
      state.isFormFullyFilledByUser = isFormFullyFilledByUser( answersAboutStudentObj, state.isAllQuestionsDisplayed, state );
    }
  }
};

export default {
  namespaced: true,
  state: {
    firstName: null,
    lastName: null,
    schoolsList: [],
    activeSchool: null,
    dataItems: [],
    selectedStudent: null, // ref to currently active item in dataItems
    isSysAdmin: false,
    userCanUploadExcel: false,
    answersAboutStudentObj: {},
    isFormFullyFilledByUser: null,
    submitSuccess: false,
    searchedTz: null,
    isAllQuestionsDisplayed: false,
    treesFullyFilledByUser: {},
    editStudent: {
      firstName: null,
      lastName: null,
      semelMosad: null,
      classNumber: null,
      classGrade: null,
      studentObjId: null
    },
    didAcceptTos: null
  },
  getters: {
    getTeacherName: state => `${state.firstName} ${state.lastName}`,
    getDidAcceptTos: state => state.didAcceptTos,
    getEditStudent: state => state.editStudent,
    isAllQuestionsDisplayed: state => state.isAllQuestionsDisplayed,
    queryToData: state => state.dataItems,
    getSchoolList: state => state.schoolsList,
    getActiveSchool: state => state.activeSchool,
    getUserCanUploadExcel: state => state.userCanUploadExcel,
    isSysAdmin: state => state.isSysAdmin,
    getSelectedStudent: state => state.selectedStudent,
    getAnswersAboutStudentObj: state => state.answersAboutStudentObj,
    getIsFormFullyFilledByUser: state => state.isFormFullyFilledByUser,
    getSubmitSuccess: state => state.submitSuccess,
    getSearchedTz: state => state.searchedTz,
    getTreesFullyFilledByUser: state => state.treesFullyFilledByUser
  },
  mutations: {
    setDidAcceptTos(state, payload){
      state.didAcceptTos = payload;
    },
    setEditStudent(state, payload) {
      state.editStudent = payload;
    },
    setTreesFullyFilledByUser(state, section) {
      state.treesFullyFilledByUser[section] = true;
    },
    resetTreesFullyFilledByUser(state) {
      state.treesFullyFilledByUser = {};
    },
    setIsAllQuestionsDisplayed(state, payload) {
      state.isAllQuestionsDisplayed = payload;
    },
    setTreeAnswersAboutStudent(state, payload) {
      // payload = {treeid: string, answersObj: { qCode:answerCode }}
      state.answersAboutStudentObj[payload.treeId] = payload.answersObj;
    },
    setTeacherName(state, payload) {
      state.firstName = payload.firstName;
      state.lastName = payload.lastName;
    },
    setSchoolsList(state, schoolsList) {
      state.schoolsList = schoolsList;
    },
    setActiveSchoolById(state, activesemelMosad) {
      let foundSchoolObject = state.schoolsList.find(
        schoolObj => schoolObj.semelMosad === activesemelMosad
      );
      state.activeSchool = foundSchoolObject || null;
    },
    setDataItems(state, dataItems) {
      state.dataItems = dataItems;
    },
    setSelectedStudent(state, itemRef) {
      if (state.selectedStudent) {
        if (state.selectedStudent._id !== itemRef._id) {
          // in case of switching student, reset answersAboutStudent.
          state.answersAboutStudentObj = {};
        }
      }
      state.selectedStudent = itemRef;
      setAnswersAboutStudent(state);
    },
    setSelectedRecommendations(state, recommendationsData){
      state.selectedStudent.recommendationsData = recommendationsData
    },
    setFormData(state, formData) {
      if (state.selectedStudent) {
        state.selectedStudent.formData = formData;
      }
      setAnswersAboutStudent(state);
    },
    // setAnswersAboutStudent,
    setUserCanUploadExcel(state, payload) {
      state.userCanUploadExcel = payload;
    },
    setIsSysAdmin(state, payload) {
      state.isSysAdmin = payload;
    },
    setSubmitSuccess(state, payload) {
      state.submitSuccess = payload;
    },
    setSearchedTz(state, payload) {
      state.searchedTz = payload;
    }
  },
  actions: {
    updateAnswersAboutStudent: async (context, token) => {
      let tempAnswersAboutStudent = context.state.answersAboutStudentObj;
      if (Object.keys(tempAnswersAboutStudent).length < 1) {
        // if the obj is empty, dont update
        return;
      }
      // map the obj to fit the DB model
      let answersAboutStudent = Object.keys(tempAnswersAboutStudent).map(
        treeId => ({
          treeId,
          treeAnswers: Object.keys(tempAnswersAboutStudent[treeId]).map(
            qCode => ({
              questionId: qCode,
              answerCode: tempAnswersAboutStudent[treeId][qCode]
            })
          )
        })
      );
      let response = await teacherService.updateAnswersAboutStudent(
        {
          studentObjId: context.state.selectedStudent._id,
          answersAboutStudent
        },
        token
      );
      if (response) {
        // console.log(response);
        if (response.data.success) {
          // since the student's form was updated in db, update the student's form in store
          context.commit('setFormData', response.data.formData);
        } else {
          console.warn('something went wrong');
        }
      }
    },
    updateSelectedRecommendations: async (context, data) => {
      let response = await teacherService.updateSelectedRecommendations(
          {
            studentObjId: context.state.selectedStudent._id,
            selectedRecommendations: data.selected
          },
          data.token
      );
      if (response) {
        // console.log(response);
        if (response.data.success) {
          context.commit('setSelectedRecommendations', response.data.recommendationsData);
          return response.data.recommendationsData
        } else {
          console.warn('something went wrong');
        }
      }
    },
    setSelectedStudentById(context, itemId) {
      logger.debug('item id = ' + itemId);
      // let numId= +itemId
      let newSelectedStudent = _.find(context.state.dataItems, { id: itemId });
      context.commit('setSelectedStudent', newSelectedStudent);
    },
    // TODO: Rename to find, Install lodash, remove async (see)
    populateSessionData(context, payload) {
      context.commit('setTeacherName', payload);
      context.commit('setSchoolsList', payload.schoolsList);
      context.commit('setActiveSchoolById', payload.schoolsList[0]);
    },
    setDataItems: async function(context, token) {
      let response = await teacherService.getTeacherDummyData(token);
      if (response.data) {
        context.commit('setDataItems', response.data);
      }
    },
    switchSchool: async function(context, data) {
      let response = await teacherService.switchActiveSchool(data);
      if (response.token) {
        context.commit('setActiveSchoolById', data.semelMosad);
        return response.token;
      } else {
        logger.debug('Request failed.');
      }
    },
    cleanModuleState: function(context) {
      context.state.firstName = null;
      context.state.lastName = null;
      context.state.schoolsList = null;
      context.state.activeSchool = null;
      context.state.dataItems = null;
      context.state.selectedStudent = null;
      context.state.isSysAdmin = false;
      context.state.userCanUploadExcel = false;
      context.state.searchedTz = null;
      context.state.treesFullyFilledByUser = {};
    }
  }
};
