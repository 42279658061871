<template lang="pug">
#pages
  .no-map(v-if="!formComplete")
    p {{ fixI18nSpaces('noMap') }}
  .pages-container(v-else)
    .map-text.print-btn.flex.space-around
      div
        span {{ fixI18nSpaces('printMap') }}
        a.link-txt(@click="print") {{ fixI18nSpaces('pressHere') }}
    #nodeToRenderAsPDF.map-container
      .flex.space-between.nav
        .mikra {{ fixI18nSpaces('labels.mikra') }}
          br
          .pushed-text
            .flex.justify-center.align-center
              .mikra-hdr {{ fixI18nSpaces('labels.ability') }}
              img.glyph(src="../assets/img/glyphicons/icon-03.png", alt)
            .flex.justify-center.align-center
              .mikra-hdr {{ fixI18nSpaces('labels.hardship') }}
              img.glyph(src="../assets/img/glyphicons/icon-02.png", alt)
        .data-box-three
          div {{ fixI18nSpaces('theStudent') }}
          .pushed-text
            div
              b {{ studentTz }} | {{selectedStudent.lastName}} {{selectedStudent.firstName}}
            div(dir="rtl") {{ selectedStudent.schoolClass.semelMosad }}
              | &nbsp;|
              | {{ fixStringSpaces(' ' + selectedStudent.schoolClass.classGrade +"' " + selectedStudent.schoolClass.classNumber + ' ') }}
        .data-box-two {{ fixI18nSpaces('theAdvisor') }}

          .pushed-text
            div
              b {{ fixStringSpaces(`${advisor.firstName || ' '} ${advisor.lastName || ' '} `) }}
            div {{ `${advisor.email || ''} | ${advisor.phone || ''}` }}
        .data-box-one
          .hdr(dir="rtl") {{ fixI18nSpaces('navbar.studentChart') }}
          div(dir="rtl") {{ today() }}
        .og-box
      .flex.flex-wrap.space-between.cards-container
        .main-right
          .general-card
            .trees-hdr {{ fixI18nSpaces('functionalMapHdrs.cognetivefunc') }}
            .cognetivefunc-card.flex.space-between
              .right-side
                .visio-motor-card-wrapper
                  MapCard.visio-motor-card(current-index="4")
                .intelligence-card-wrapper
                  MapCard.intelligence-card(current-index="5")
                .hearing-memory-card-wrapper
                  MapCard.hearing-memory-card(current-index="3")
              .left-side
                .language-card-wrapper
                  MapCard.language-card(current-index="2")
                .listening-card-wrapper
                  MapCard.listening-card(current-index="1")
          .general-card
            .trees-hdr {{ fixI18nSpaces('functionalMapHdrs.generalTests') }}
            .flex-container.generalTests-card.flex.space-between
              .right-side
                div
                  .radio-container
                    .radio-hdr {{ fixI18nSpaces('generalTestMapSubHdrs.opticFocusMultiple') }}
                    .pushed-text-radios {{ generateAnswerForRadio('IsOpticFocusMultiple') }}
                  .radio-container
                    .radio-hdr {{ fixI18nSpaces('generalTestMapSubHdrs.auditoryMultiple') }}
                    .pushed-text-radios {{ generateAnswerForRadio('IsAuditoryMultiple') }}
                  .radio-container
                    .radio-hdr {{ fixI18nSpaces('generalTestMapSubHdrs.opticMultiple') }}
                    .pushed-text-radios {{ generateAnswerForRadio('IsOpticMultiple') }}
              .left-side
                .general-test-container(style="text-align: right")
                  .radio-hdr {{ fixI18nSpaces('moreComments') }}
                  | {{selectedStudent.formData.teacherComment}}
        div
          .general-card
            .trees-hdr {{ fixI18nSpaces('functionalMapHdrs.generalfunc') }}
            .flex-container.generalfunc-card.flex.space-between
              MapCard(current-index="9")
          .general-card
            .trees-hdr {{ fixI18nSpaces('functionalMapHdrs.academicfunc') }}
            .flex-container.academicfunc-card.flex.space-between
              .right-side
                .reading-card-wrapper
                  MapCard.reading-card(current-index="6")
                .meaning-card-wrapper
                  MapCard.meaning-card(current-index="7")
              .left-side
                .writing-card-wrapper
                  MapCard.writing-card(current-index="8")
</template>

<script>
import advices from '@/config/advices.json5';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import moment from 'moment';
import profileFormJson from '@/config/profile-form.json5';
import hiddenWhenStudNotDiagnosed from '@/config/hidden-when-stud-not-diagnosed.json5';
import arrowJson from '@/config/questions-map-arrow-diractions.json5';
import MapCard from '@/components/cards/MapCard.vue';
import constants from '@/constants/app-constants';
import spinnerMixins from '@/mixins/spinner-mixins';

export default {
  name: 'Map',
  components: {
    MapCard
  },
  mixins: [spinnerMixins],
  data() {
    return {
      arrowJson,
      profileFormJson,
      html2canvas,
      jsPDF,
      advices,
      hiddenWhenStudNotDiagnosed,
      yesNoArr: [
        { labelCode: constants.answers.YES },
        { labelCode: constants.answers.NO }
      ]
    };
  },
  computed: {
    selectedStudent() {
      return this.$store.getters['teacher/getSelectedStudent'];
    },
    studentTz() {
      return this.$store.getters['teacher/getSearchedTz'];
    },
    advisor() {
      const advisor = this.$store.getters['teacher/getSelectedStudent'].formData
        .submittedBy;
      return advisor || {};
    },
    isAllQuestionsDisplayed() {
      return this.$store.getters['teacher/isAllQuestionsDisplayed'];
    },
    answersAboutStudentObj() {
      return this.$store.state.teacher.answersAboutStudentObj;
    },
    formComplete() {
      return this.$store.getters['teacher/getIsFormFullyFilledByUser'];
    }
  },
  methods: {
    today() {
      moment.locale('he');
      return moment().format('DD MMM YYYY');
    },
    scale(value) {
      let style = document.querySelector('#nodeToRenderAsPDF').style.transform;
      let scale = 1;
      if (style) {
        scale = style.split('(')[1].split(')')[0];
      }
      scale = Number(scale) + Number(value);
      if (scale < 0) {
        return;
      }
      document.querySelector('#nodeToRenderAsPDF').style.transform =
        'scale(' + scale + ')';
    },
    generateAnswerForRadio(qCode) {
      const answerCode = this.$store.getters[
        'teacher/getAnswersAboutStudentObj'
      ][10][qCode];
      return this.fixI18nSpaces('profileFormAnswers.' + answerCode);
    },
    fixStringSpaces(str) {
      return str.split(' ').join(String.fromCharCode(160));
    },
    fixI18nSpaces(i18path) {
      // return this.$t(i18path).replace(' ', String.fromCharCode(160));
      return this.fixStringSpaces(this.$t(i18path));
    },
    print() {
      const filename = `מפה תפקודית - ${this.selectedStudent.lastName} ${this.selectedStudent.firstName} - `+
          `${this.selectedStudent.schoolClass.classGrade} ${this.selectedStudent.schoolClass.classNumber}`.replace(/['":?!]/g, '');
      this.showSpinner();
      html2canvas(document.querySelector('#nodeToRenderAsPDF'), {
        scale: 3
      }).then(canvas => {
        // document.getElementById('test').appendChild(canvas);
        let pdf = new jsPDF('l', 'mm', 'a4');
        pdf.addImage(canvas.toDataURL('image/jpeg'), 'JPEG', 4, 5, 288, 193);
        pdf.save(filename);
        this.hideSpinner();
      });
    },
    arrowDir(qcode) {
      return this.arrowJson[qcode][this.answersAboutStudentObj[4][qCode]];
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/style.scss';
#nodeToRenderAsPDF {
  // transform: scale(0.75);
  transform-origin: top;
}

.scale-btns {
  // display: flex;
  // justify-content: center;
  // flex-direction: column;
}

.scale-up,
.scale-down {
  font-size: 16px;
  text-align: right;
  cursor: pointer;
}

.pages-container {
  margin: 0 auto;
  width: fit-content;
}
.print-btn {
  height: 60px;
  outline: none;
  font-family: $font-main;
  background-color: $color-peach;
  display: flex;
  // justify-content: center;
  align-items: center;
  letter-spacing: normal;
  text-align: center;
  color: $color-blue;
  font-size: $font-size-runtxt;
  font-weight: 900;
  margin: 10px auto;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
.cards-container {
  margin-top: 11px;
  direction: rtl;
  width: 1654px;
}
.map-container {
  background-color: #fff;
}
.general-card {
  width: 821px;
}
.no-map {
  position: relative;
  padding: 2%;
  top: 100px;
  font-size: $font-size-runtxt;
  font-weight: bold;
  font-family: $font-main;
  background-color: $color-peach;
  width: 400px;
  height: 100px;
  margin-left: auto;
  margin-right: auto;
}
.trees-hdr {
  background: $color-peach;
  color: $color-blue;
  border: 1px solid $color-blue;
  font-weight: 900;
  font-size: 20px;
  text-align: right;
  padding-right: 13px;
  height: 36px;
}
.li-setup {
  margin-right: 20px;
}
.map-title {
  font-family: $font-main;
  font-size: 40px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.96;
  letter-spacing: normal;
  color: $color-blue;
  padding: 0rem 1rem 2rem 0rem;
  margin: 0;
  text-align: right;
}
.clean-link {
  color: $color-blue;
}
.container {
  font-family: $font-main;
}
.map-text {
  font-size: 16px;
  letter-spacing: normal;
  text-align: right;
  color: $color-blue;
  direction: rtl;
}
.mikra-hdr {
  // margin-left: 30px;
}
.nav {
  background: white;
  width: 1654px;
  border: 1px $color-blue solid;
  font-size: 12px;
  text-align: right;
}
.data-box-one,
.data-box-two,
.data-box-three {
  border-right: 1px $color-blue solid;
  padding: 16px;
}
.data-box-one {
  font-size: 15px;
  width: 357px;
  padding-top: 12px;
}
.data-box-two {
  width: 440px;
}
.data-box-three {
  width: 442px;
}
.pushed-text {
  font-size: 15px;
  padding-right: 10px;
}
.og-box {
  width: 209px;
  height: 69px;
  margin: 12px;
  // background-color: $color-peach;
  background-image: url('../assets/img/logo-desktop.png');
  background-position: top;
}
.mikra {
  width: 178px;
  border-right: 1px $color-blue solid;
  text-align: right;
  padding: 16px;
}
.glyph {
  width: 14px;
  height: 10px;
  margin: 0.5px;
  margin-left: 5px;
}
.up {
  background-image: url('../assets/img/glyphicons/icon-03.png');
  background-size: 1%;
  background-position: right;
}
.down {
  background-image: url('../assets/img/glyphicons/icon-02.png');
  background-size: 1%;
  background-position: right;
}
.hdr {
  font-size: 29px;
  font-weight: 900;
}
li {
  text-align: right;
  list-style-position: inside;
  direction: rtl;
}

.radio-wrapper {
  margin: 8px 26px;
}

.radio-comp-wrapper,
.general-test-container,
.radio-container {
  border: 1px solid $color-blue;
}
.general-test-container {
  height: 254px;
}

.radio-container {
  height: 82px;
}

.general-test-container,
.radio-container {
  width: 409px;

  .hr {
    margin-top: 35px;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    border-bottom: 1px solid $color-blue;
  }

  .pushed-text-radios {
    text-align: right;
    margin-right: 62px;
    font-size: 15px;
  }
}

.pushed-text-radios {
  margin-right: 36px;
}

.radio-hdr {
  margin-right: 32px;
  font-size: 16px;
  text-align: right;
  text-decoration: underline;
  font-weight: 600;
}

.general-test-container,
.radio-container,
.language-card,
.listening-card,
.visio-motor-card,
.intelligence-card,
.hearing-memory-card,
.writing-card,
.reading-card,
.meaning-card {
  margin: 4px 0;
  // overflow: hidden;
}

.writing-card {
  height: 600px;
}

.reading-card {
  height: 294px;
}

.meaning-card {
  height: 302px;
}

.hearing-memory-card {
  height: 204px;

  &.empty {
    // height: 150px;
  }
}

.language-card {
  height: 357px;
}

.listening-card {
  height: 300px;
}

.visio-motor-card {
  height: 204px;
}

.intelligence-card {
  height: 245px;
}
</style>

<style lang="scss">
.general-card .answers-wrapper .answer-div {
  margin: 3px;
}

#app.application {
  background-color: transparent;
}
</style>
