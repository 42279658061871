<template lang="pug">
full-page(v-if="scrWidth > 768", ref="fullpage",
  id="fullpage", style="direction: rtl;",
  :options="fullpageOptions", @after-load="updateRoute")
  .section
    main-section
  .sidebar-filler
    .side &nbsp;
    .fill &nbsp;
  .section(:class="{active: $route.hash === '#about'}")
    about-section
  .sidebar-filler
    .side &nbsp;
    .fill &nbsp;
  .section(:class="{active: $route.hash === '#reviews'}")
    reviews-section
  .sidebar-filler
    .side &nbsp;
    .fill &nbsp;
  .section(:class="{active: $route.hash === '#products'}")
    products-section
  .sidebar-filler
    .side &nbsp;
    .fill &nbsp;
  .section(:class="{active: $route.hash === '#contactus'}")
    contactus-section
.mobile(v-else)
  main-section
  .separator
  about-section
  .separator
  reviews-section
  .separator
  products-section
  .separator
  contactus-section#contactus
</template>

<script>
// @ is an alias to /src
import generalService from '../services/general-service';
import devConfig from '../config/dev-config.js';
// import Notifications from '../services/notifications';
import logger from '@/services/logger.js';
import Main from "@/views/home/Main";
import Contactus from "@/views/home/Contactus";
import ProductsSection from "@/views/home/Products";
import ReviewsSection from "@/views/home/Reviews";
import About from "@/views/home/About";

export default {
  name: 'Login',
  components: {
    ReviewsSection,
    ProductsSection,
    'main-section': Main,
    'contactus-section': Contactus,
    'about-section': About,
  },
  data() {
    return {
      userData: this.$store.getters.getState,
      mockUserId: null,
      popUpErrorMsg: false,
      token: this.$store.getters.getToken,
      userType: null,
      mockIdmUsers: null,
      devConfig,
      scrWidth: window.innerWidth,
      fullpageOptions:{
        licenseKey: 'KO8N6-9NJ97-72KDJ-6WJ78-LKUDO',
        afterLoad: this.updateRoute,
        normalScrollElements: '.accordion',
        navigation: true,
        navigationPosition: 'right',
      },
      pages: ['', 'about', 'reviews', 'products', 'contactus'],
    };
  },
  computed: {
    getToken() {
      return this.$store.getters.getToken;
    },
    devMode() {
      return this.$store.getters.getDevMode;
    },
    getUserType: function () {
      let isTeacher = this.$store.getters.isTeacher;
      if (isTeacher) {
        return 'Teacher';
      } else {
        return 'Student';
      }
    }
  },
  created() {
    this.getMockIdmUsers();
    this.startEmailLogin();
  },
  mounted() {
    if (this.devMode) {
      // Dev Mode
      logger.debug('DEV MODE');

      if (this.isAutoLoginMode()) {
        // Automatic login will be executed now
      }
    } else {
      // Prod Mode
      logger.debug('PROD MODE');
    }

    this.userType = this.getUserType;

    EventsBus.$on('moveFullpage', (page) => {
      console.log('moveFullpage', page);
      this.$refs.fullpage.api.moveTo(this.pages.indexOf(page)+1);
    });
  },
  methods: {
    updateRoute(origin, destination, direction, trigger){
      if (destination.index === 0) history.replaceState({}, '', `/`);
      else history.replaceState({}, '', `/#${this.pages[destination.index]}`);
      EventsBus.$emit('updateRoute', this.pages[destination.index]);
    },
    async beginIdmLogin(popupBlocked = false) {
      // If in PWA mode, then "window.matchMedia('(display-mode: standalone)').matches" will be true
      if (
          (this.devConfig.forceIdmLoginInPopup ||
              window.matchMedia('(display-mode: standalone)').matches) &&
          !popupBlocked
      ) {
        logger.debug('popup mode');
        const explicitRedirectUri = `${location.protocol}//${
            location.host
        }/login-popup-callback.html`;
        let result = await generalService.startOpenIdSession(
            explicitRedirectUri
        );
        if (!result.success) {
          logger.error('Could not connect to EDU identity management');
          // TODO: Show some visible error message
          return;
        }

        // TODO: Put this window in the center
        let loginPopupHandler = window.open(
            result.url,
            'loginPopup',
            'width=400,height=500'
        );

        // In case where popup was blocked - performing login in the same window
        // TODO: Wait a few seconds to allow popup unblock?
        if (!loginPopupHandler) {
          this.beginIdmLogin(true);
        }

        // Receving the login code from the child window and redirecting to idmcallback.
        // The 'login-popup-callback.html' is actually a route parameter, which will be passed to the server,
        // as an explicit redirect uri.
        window.addEventListener('message', evt => {
          if (evt.data && evt.data.type === 'finalizeLogin') {
            let redirectionQueryParams = evt.data.redirectionUrl.split('?')[1];
            window.location.href = `${location.protocol}//${
                location.host
            }/idmcallback/login-popup-callback.html?${redirectionQueryParams}`;
          }
        });
      } else {
        logger.debug('same window mode');

        const state = {
          clientRedirectUri: this.$route.query.redirectUri,
          studentTz: this.$route.query.studentTz,
          studentObjId: this.$route.query.studentObjId
        };

        // In this case the regular redirect URL from the IDM client settings on the server will be used
        let result = await generalService.startOpenIdSession(null, state);
        if (!result.success) {
          logger.error('Could not connect to EDU identity management');
          // TODO: Show some visible error message
          return;
        }
        window.location.href = result.url;
      }
    },
    async getLoginPromise(userData) {
      // console.log('userData:', userData);
      let data = await generalService.performMockLogin(userData);
      logger.debug(data);
      if (data.success) {
        if (data.token) {
          // Token returned
          await this.$store.dispatch('setLoginData', data);
          if (data.userType === 'T') {
            if (data.showTos && !this.devConfig.skipTos && !data.isSysAdmin) {
              this.$router.push('/tos');
            } else {
              this.$router.push('/teacher');
            }
          } else if (data.userType === 'S') {
            this.$router.push('/student');
          }
        } else {
          // No token back from server
          this.$store.dispatch('cleanState');
        }
      } else {
        alert(data.errorCode);
      }
    },
    startEmailLogin() {
      if (this.$route.query.fromEmail) {
        this.beginIdmLogin();
      }
    },
    getState() {
      return this.$store.getters.getState;
    },
    // isDevMode() {
    //   if (this.devConfig.currentMode === this.devConfig.options.devMode) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // },
    isAutoLoginMode() {
      if (this.devConfig.automaticLogin) {
        return true;
      } else {
        return false;
      }
    },
    getMockIdmUsers: async function () {
      let response = await generalService.getMockIdmJSON();
      if (response) {
        this.mockIdmUsers = response.idmMockUsers;
        // logger.debug(response);
      } else {
        logger.error('Error on getMockIdmUsers request.');
      }
    }
  }
};
</script>
<style>
@media only screen and (max-width: 768px){
  .sidebar, .sidebar-filler{
    display: none !important;
  }
  .section-container, .content{
    height: auto !important;
  }
  #fullpage{
    width: 100vw;
  }
  .separator{
    height: 3px;
    background-color: #2c3e50;
    margin: 25px 0 25px 0;
  }

}
</style>
<style lang="scss" scoped>
.section-container {
  direction: rtl;
  height: calc(100% - 56px);
  justify-content: stretch;
  display: flex;
}
.sidebar-filler{
  margin-top: -56px;
  height: 56px;
  display: flex;
  .side{
    width: calc(30% - 2px);
    min-width: 300px;
    background-color: #545871;
    height: 100%;
  }
  .fill{
    width: -webkit-fill-available;
    height: 100%;
  }
}


.about-button {
  font-size: 30px;
  border: 1px solid;
  border-radius: 35px;
  width: 250px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.generator-button {
  margin-top: 50px;
  position: relative;
  font-size: 30px;
  width: 250px;
  height: 100px;
  border: 1px solid;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 35px;
}

.generator-soon {
  font-size: 20px;
  direction: rtl;
  transform: rotate(-31deg);
  position: absolute;
  margin-top: -57px;
  margin-left: -149px;
  border-bottom: 30px solid #f6e4cc;
  border-left: 17px solid transparent;
  border-right: 50px solid transparent;
  height: 0;
  color: red;
}

.youtube-video {
  margin-top: 2rem;
  padding-bottom: 100px;
}

.edvise-page-title {
  margin-top: 2rem;
  direction: rtl;

  //@media (max-width: 1366px) {
  //  img {
  //    width: 40%;
  //  }
  //  margin-top: 20px;
  //  padding: 0;
  //}
}

.welcome {
  font-weight: bolder;
  margin-top: 0rem;
}

.login-text {
  text-align: right;
  direction: rtl;
  font-size: 16px;

  &.smaller {
    font-size: 13px;
    font-weight: bold;
  }
}

.login-btn-container {
  direction: rtl;
  padding-top: 40px;
  display: flex;
  justify-content: center;

  //@media (max-width: 1366px) {
  //  &.dev-mode {
  //    padding-top: 20px;
  //  }
  //}

  .round-btn-blue {
    width: 130px;
    font-size: 18px;
    height: 40px;
  }
}

.dev-mode-container {
  text-align: right;
  margin-top: 30px;
}

.logo-fixed-size {
  max-width: 40%;
}

.footer {
  position: sticky;
}

.sidebar a:link, .sidebar a:visited {
  color: #f6e4cc;
}

@media (min-width: 1366px) {
  //.login::-webkit-scrollbar {
  //  width: 0px;
  //  background: transparent; /* Chrome/Safari/Webkit */
  //}

  //.login {
  //  height: calc(100vh - 112px);
  //  overflow: auto;
  //}
}

// .login {
//   margin-bottom: 100px;
// }
</style>
