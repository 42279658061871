<template>
  <v-app
    id="app"
    :class="{'stop-scrolling': stopScroll }"
  >
    <div v-if="$route.path === '/map'">
      <Map />
    </div>
    <div
      v-else
      class="app-wrapper"
    >
      <div class="app-main">
        <!-- <div id="nav"> -->
        <!-- <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link> -->
        <!-- </div> -->
        <Navbar
          :to-display="navbarItems.toDisplay"
          :fields="navbarItems.fields"
        />
        <router-view />
        <Footer v-if="displayFooter" />
        <div class="notifications"> 
          <notifications
            classes="vue-notification warn"
            position="bottom right"
            group="warn"
          />
          <notifications
            classes="vue-notification error"
            position="bottom right"
            group="error"
          />
          <notifications
            classes="vue-notification success"
            position="bottom right"
            group="success"
          />
          <notifications
            position="bottom right"
            group="default"
          />
        </div>
      </div>
    </div>
    <div>
      <Dialog ref="dialog" />
    </div>
    <div>
      <Spinner
        ref="spinner"
        spinner="3"
      />
    </div>
  </v-app>
</template>

<script>
import Navbar from '@/components/NavBar';
import Map from '@/components/Map';
import Footer from '@/components/Footer';
import Dialog from '@/components/Dialog';
import Spinner from '@/components/Spinner';
import notifications from '@/services/notifications';
import util from '@/util/util';

export default {
  components: { Map, Dialog, Spinner, Navbar, Footer },
  data() {
    return {
      desktop: window.innerWidth > 768,
      stopScroll: false
    };
  },
  computed: {
    displayFooter() {
      return false
      // return this.desktop || this.$route.path === '/';
    },
    navbarItems() {
      return {
        fields: this.$store.getters.isTeacher
          ? { nameField: 'schoolName', id: 'semelMosad' }
          : { nameField: 'schoolClassName', id: 'classId' },
        toDisplay: {
          selectionList: this.$store.getters.isUser ? true : false,
          studentLink: true,
          teacherLink: this.$store.getters.isTeacher,
          contactLink: this.$store.getters.isUser,
          homeLink: true,
          tosLink: this.$store.getters.isTeacher ? true : false,
          aboutLink: false,
          logoutLink: true
        }
      };
    }
  },
  mounted() {
    util.listenToResize.call(this);
    this.writeDialogRefToStore();
    if (this.$store.getters.getLoggedOutDueToTokenExpiry) {
      notifications.noTitle('warn', this.$t('tokenExpired'));
    }
    this.$store.commit('setLoggedOutDueToTokenExpiry', false);
    navigator.serviceWorker.getRegistrations().then( function(registrations) { for(let registration of registrations) { registration.unregister(); } });

  },
  methods: {
    writeDialogRefToStore() {
      this.$store.commit('setRefToConfirmDialog', this.confirmDialog);
      this.$store.commit('setRefToFullScreenSpinner', this.spinnerRef);
    },
    confirmDialog(title, content, confirm, cancel, maxWidth) {
      return this.$refs.dialog.open(title, content, confirm, cancel, maxWidth);
    },
    spinnerRef(flag) {
      this.stopScroll = flag;
      return this.$refs.spinner.toggleSpinner(flag);
    }
  }
};
</script>


<style lang="scss">
@import '@/assets/scss/style.scss';

#app {
  font-family: $font-main;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100vh;
  background-color: #2c3e50;

  &.stop-scrolling {
    overflow: hidden;
  }
}

.app-wrapper {
  display: flex;
  justify-content: center;

}

.app-main {
  width: 1366px;
  background-color: $color-lightpeach;
  height: -webkit-fill-available;
}
.application--wrap{
  background-image: url('./assets/img/layout/background/background.png');
  background-size: cover;
}
#nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: #2c3e50;
    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

::-webkit-scrollbar-thumb,
::-webkit-scrollbar,
::-webkit-scrollbar-track {
  display: none;
}

.v-dialog h2 {
  color: $color-blue;
  text-align: right;
  font-size: 16px;
}
@media (max-height: 650px) {
  html{
    font-size: 12px !important;
  }
}
@media (max-width: 768px) {
  html{
    font-size: 11px !important;
  }
}
</style>
