<template lang="pug">
  .section-container
    contactus-sidebar
    .content
      div.header Edvise
        .text
          b חליפה אישית לצרכי התלמיד.

      .text
        p ממשק הממפה את יכולות התלמיד ומייצר תוכנית התערבות אישית.
        p עשרה תחומי תפקוד של התלמיד מוזנים למערכת ועל בסיסם המערכת מנפיקה מיידית&#32;
          | #[b מפה תפקודית רחבה] המשקפת את היכולות של התלמיד
          | ו#[b תוכנית התערבות] מדויקת לתלמיד להפעלה בכיתה ע"י כל הצוות החינוכי.
        p המורים מבינים טוב יותר את קשיי ההבנה של התלמידים, שמים לב ליכולת המוטיבציה ומה מניע אותם, מתחשבים במידע משפחתי הנוגע לשיתוף הפעולה ותמיכת ההורים ומתייחסים למידע אישיותי וחברתי של התלמיד.
        p המפה התפקודית מסייעת לצוותים בארגון המידע המקצועי ומאפשרת לראות את הקשרים בין התפקודים הקוגנטיביים לתפקודים האקדמיים ולהבין את הקשר והתלות ביניהם.
        p תוכנית ההתערבות מנגישה כלים ודרכי הוראה שמסייעים לתלמיד בלמידה בתוך הכיתה. ובכך Edvise מאפשרת לבתי הספר להכיר ולהכיל את הצרכים של כל התלמידים.
        p Edvise מאושרת במאגר התוכניות של משרד החינוך (מס' 7689) ומאושרת במסלול הכלה והשתלבות,
          |  זכתה בפרס הצטיינות IT Awards 2020 - אנשים ומחשבים ושותפה במחקר של תוכנית אדוות - מעוז
        p במאגר הגפ"ן של משרד החינוך, תוכנית מס' 7689.
        p.text-center(style="margin-top: 2.14rem;")
          b Edvise הופכת את המורים מקצועיים יותר,
        p.text-center
          b את התלמידים שקופים פחות
        p.text-center
          b ואת ההורים שמחים ומשתפי פעולה!

</template>

<script>
import ContactusSidebar from "@/components/ContactusSidebar";

export default {
  name: "about",
  components: {'contactus-sidebar': ContactusSidebar},
}
</script>
<style scoped lang="scss">
.section-container {
  direction: rtl;
  width: 100%;
  //height: calc(100% - 56px);
  justify-content: stretch;
  display: flex;
}
.content{
  display: flex;
  justify-content: center;
  flex-direction: column;
  //height: calc(100vh - 56px);
  width: 100%;
  .header{
    font-size: 2rem;
    font-weight: bold;
    margin: 1.75rem;
  }
  .text{
    font-size: 1.25rem;
    padding: 0 20px 0 20px;
    text-align: center;
    p {
      margin-bottom: .7rem;
    }
  }

}
</style>