module.exports = {
	"0קריאה0דיוק0": [
		"IsPhonologicAwarness",
		"IsTextReadAccurate",
		"VowelsAndConstants",
		"Nonword",
		"MeaningfulWords",
		"WritingTemplate"
	],
	"0קריאה0קצב0": [
		"AudibleTextPace",
		"SilentTextPace"
	],
	"0הבנת0נשמע0": [
		"ListeningCompInformativeTextHigh",
		"ListeningCompInformativeTextLow",
		"ListeningCompStoryTextHigh",
		"ListeningCompStoryTextLow"
	],
	"0הבנת0נקרא0": [
		"HearingCompInformativeTextHigh",
		"HearingCompInformativeTextLow",
		"HearingCompStoryTextHigh",
		"HearingCompStoryTextLow"
	],
	"0כתיבה0עיצוב0": [
		"IsPageOrganised",
		"IsHandWriteStyle",
		"IsPencilHold",
		"IsTireFactor"
	],
	"0כתיבה0איות0": [
		"IsCommonSpelling",
		"IsSevereSpelling"
	],
	"0כתיבה0הבעה0": [
		"IsMessageOrganised",
		"IsLinguisticHigh",
		"IsWritePace",
		"IsOpenQuestion",
		"IsClosedQuestion"
	],
	"0שפה0הבעה0": [
		"ByHeartParticipation",
		"Vocabulary",
		"WordRecall"
	],
	"0שפה0שש0": [
		"AutoQuickNaming",
		"PhonologicStream",
		"SemanticStream",
		"AudibleTextPace",
		"AudibleProcess"
	],
	"0תפיסה0חו0מ0": [
		"VisualPerformanceNoMotor",
		"VisioMotoricIntegration",
		"VisualIdentifyGen",
		"VisualIdentifyImmediate",
		"GestaltPerception",
		"DetailsPerception"
	],
	"0חשיבה0דידקטי0": [
		"AbstractNonVerbalThinking",
		"NonAbstractVerbalThinking",
		"IsStudentInSpeciaEdKindergarden",
		"IsStudentRecommendedForSpecialEdInRegularSchool"
	],
	"0חשיבה0פסיכולוגי0": [
		"IsStudPsychDiagnosed",
		"IsGeneralIntl",
		"IsVerbalCrystal",
		"IsDoerFluid",
		"IsStudentInSpeciaEdKindergarden",
		"IsStudentRecommendedForSpecialEdInRegularSchool"
	],
	"0זיכרון0שמעתי0": [
		"contextualMemoryImmediate",
		"contextualMemoryPostponed",
		"SingletonMemoryImmediate",
		"SingletonMemoryPostponed",
		"MemoryIdentification",
		"MemoryLearnRepetitionCurve",
		"MemoryLearnCurveSuddenDrop",
		"RetroProActiveMemory"
	],
	"0קשב0": [
		"IsRestless",
		"IsDayDream",
		"IsImpulsive",
		"IsSlowToAct",
		"IsAssociative",
		"IsHardToFinish",
		"IsVentilation",
		"IsInfoFlood",
		"IsVisualAttention",
		"IsActiveWorkingMemory",
		"IsNeuroDiagnosed"
	],
	"0חוש0תנועתי0": [
		"ClassEquipment",
		"EnvironmentOrganize"
	],
	"0שיח0חברתי0": [
		"Cooperation",
		"PeerContact"
	],
	"0בנאישי0": [
		"SocialEtiquette",
		"AdultsRelations",
		"ClassAttendance",
		"ClassParticipate"
	],
	"0ס0ת0למידה0": [
		"TaskOrganize",
		"AfterHoursLearn",
		"ClassWorkIndependant",
		"Achievements"
	],
	"0התנהגות0רגשי0": [
		"ChangeCope",
		"Anxiety",
		"FailureCope",
		"FrustrationThreshold",
		"SelfConfident",
		"LearningMotivated"
	]
}