module.exports = {
	"sectionsToShowForIncompleteForm": [
		"0",
		"1",
		"2",
		"6",
		"7",
		"8",
		"9",
		"10"
	],
	"hiddenQuestions": [
		"IsActiveWorkingMemory",
		"IsVisualAttention",
		"AutoQuickNaming",
		"PhonologicStream",
		"SemanticStream",
		"IsPhonologicAwarness",
		"VowelsAndConstants",
		"Nonword",
		"MeaningfulWords",
		"WritingTemplate",
		"IsGeneralIntl",
		"IsVerbalCrystal",
		"IsDoerFluid"
	]
}