export default {
qna: [
  {
    "question": "מהי Edvise?",
    "answer": "ממשק הממפה את יכולות התלמיד ומייצר עבורו תוכנית התערבות אישית."
  },
  {
    "question": "האם בלחיצת כפתור אקבל מיד תוצרים?",
    "answer": "כן!!! מיידית!!\nממלאים שאלון ומקבלים מייד את המפה התפקודית ותוכנית ההתערבות.\nמתחילים מייד.\n"
  },
  {
    "question": "מי יכול להשתמש ב Edvise?",
    "answer": "צוותי חינוך והוראה, הורים *(במסלול הורים), מכונים פרטיים."
  },
  {
    "question": "האם Edvise עוזרת לתלמיד? ",
    "answer": "וודאי!! Edvise עוזרת לתלמיד ומסייעת למקד את הקושי, \nבמקום ללכת סחור סחור במשך שנים וליצור פערים שקשה לגשר עליהם."
  },
  {
    "question": "כיצד Edvise עוזרת למורים? ",
    "answer": " Edvise עוזרת למורה משלושה היבטים:\n- המורה מבין את התלמיד שלו, את היכולות והצרכים שלו. ומאפשרת לו לבחור תוכנית התערבות עבורו.\n- כל הכיתה מרוויחה כי המורה נהיה מנוסה יותר ולומד לגוון את הלמידה כך שיותר תלמידים שותפים לה.\n- משתמשי Edvise מדווחים כי זכו לשיתוף פעולה פורה מאוד עם ההורים בזכות העבודה עם Edvise. \n"
  },
  {
    "question": "האם Edvise עוזרת להורים? ",
    "answer": "כן! הקושי הגדול של הורים הוא העיכוב באבחנת הקושי של הילד ותהליך ארוך מידי של ניסוי ותהיה. Edvise מקצרת את התהליך והזמן היקר ומאפשרת להורים לפעול לטובת ילדם באופן ברור יותר. \n"
  },
  {
    "question": "האם Edvise מסייעת למורות שילוב וחנ\"מ?",
    "answer": "אדוויס ממוקדת גם לצרכים של מורות שילוב וחנ\"מ. \nבימים אלו, אנו עמלים על בניית מחולל תח\"י ותל\"א. "
  },
  {
    "question": "האם Edvise מחליפה את הצורך באבחון?",
    "answer": "Edvise הינה מערכת תומכת החלטה האוספת מידע מהמעטפת הסובבת את הילד וככזו היא אינה יכולה לאבחן תפקודים שונים."
  },
  {
    "question": "האם Edvise מחליפה את המורים?",
    "answer": "ממש לא!!! להיפך Edvise הינה מערכת תומכת החלטה המסייעת לצוות החינוכי להבין את את החוזקות של הילד ואת הקשר בין התפקודים השונים ואת דרכי העבודה בכיתה שייסיעו לו. היא מחזקת את העבודה של המורה ומדייקת אותו."
  },
  {
    "question": "מדוע אנו ממליצים על מסלול ל3 שנים?",
    "answer": "בכדי ליצור שינוי בתלמיד, בצוות ביה\"ס ובשיתוף הפעולה עם ההורים יש צורך בעבודה תהליכית עמוקה לאורך זמן. בתהליך של 3 שנים הצוות לומד להכיר את המערכת ולנצל אותה באופן מיטבי. "
  }
]
}