<template lang="pug">
  .section-container
    contactus-sidebar
      .menu
        router-link.item(tag="div", to="/purchase/schools", replace,
          :class="{active: $route.params.page === 'schools'}") בתי ספר
        router-link.item(tag="div", to="/purchase/parents", replace,
          :class="{active: $route.params.page === 'parents'}") הורים
        router-link.item(tag="div", to="/purchase/others", replace,
          :class="{active: $route.params.page === 'others'}")  מתיאות, רשויות, מכונים פרטיים
    .menu-container(v-if="mobile")
      .menu
        router-link.item(tag="div", to="/purchase/schools", replace,
          :class="{active: $route.params.page === 'schools'}") בתי ספר
        router-link.item(tag="div", to="/purchase/parents", replace,
          :class="{active: $route.params.page === 'parents'}") הורים
        router-link.item(tag="div", to="/purchase/others", replace,
          :class="{active: $route.params.page === 'others'}")  מתיאות, רשויות, מכונים פרטיים
    .content(v-if="$route.params.page === 'schools'")
      .header בתי ספר
      .text
        | תוכנית Edvise מכילה:
        ul
          li אפשרות להזין למערכת מספר תלמידים בלתי מוגבל.
          li אפשרות למספר משתמשים  (רכזות, יועצת, מורות)
          li גישה לסרטוני הסבר וליווי לתוכנית* (*בתהליך בנייה)
          li  הצטרפות לקהילת Edvise בווטסאפ ובמייל למענה על שאלות ותמיכה.
      .header.smaller מסלולי רכישה
      .row
        .product-container
          .border
            .product-name
            b.text המסלול השנתי
            .text.
            .text.
              מסלול מנוי לשנת לימודים אחת
              עלות - 2500 ש"ח

              ניתן לרכישה דרך מערכת גפ"ן
        .product-container.better
          .border.better
            .product-name
            b.text  המסלול המשתלם#[sup.small *]
            .text.
              מסלול מנוי ל־3 שנות לימוד
              עלות שנתית - 2083 ש"ח

              ניתן לרכישה דרך מערכת גפ"ן
          div *מדוע אנו ממליצים על מסלול ל3 שנים?
          .small-and-right בכדי ליצור שינוי בתלמיד, בצוות ביה"ס ובשיתוף הפעולה עם ההורים יש צורך בעבודה תהליכית עמוקה לאורך זמן. בתהליך של 3 שנים הצוות לומד להכיר את המערכת ולנצל אותה באופן מיטבי.
    .content(v-else-if="$route.params.page === 'parents'")
      .header הורים/מסלול חדש
      .text הורים המעוניינים להשתמש ב Edvise לטובת הבנת התפקוד וההמלצות ובעיקר כמסלול הכנה לוועדות השונות.
      br
      .text Edvise מסייעת להורים להבין:
        ul
          li את התפקוד של ילדם.
          li את הקשר בין התפקודים השונים.
          li את החוזקות של ילדם ואילו תחומים דורשים עבודה.
          li  את ההמלצות לדרכי ההתערבות עבור ילדם.
          li ובעיקר כמסלול הכנה לקראת הועדות השונות
      br
      .tos.text
        b
          p תנאי השירות#[sup.small *]:
        ul
          li כיום השירות ניתן רק לילדים המאובחנים באבחון פסיכו-דידקטי.
          li שליחת האבחונים למערכת.
          li מפגש זום באורך שעה למעבר על התוצרים והתייעצות.
          li קבלת תוצרי Edvise למייל (מפה תפקודית ותוכנית התערבות).
          li עלות השירות 600 ש"ח
          .smaller *המערכת משאירה לעצמה את הזכות לא לקבל כל פניה.
    .content(v-else-if="$route.params.page === 'others'", style="justify-content: flex-start;")
      .header מתיאות, רשויות, מכונים פרטיים
      .text(style="text-align: center") להצעת מחיר ניתן להתקשר למס'&nbsp;
        a(href="tel:0528454140") 0528454140

</template>

<script>
import ContactusSidebar from "@/components/ContactusSidebar";

export default {
  name: "Pricing",
  components: {'contactus-sidebar': ContactusSidebar},
  data: ()=>({
    school: false,
    mobile: window.innerWidth < 768,
  }),
  // mounted(){
  //   this.page = this.pages.find((x) => x.id === this.$route.params.page);
  // },
  // watch:{
  //   '$route.params.page': {
  //     handler: function(page) {
  //       this.page = this.pages.find((x) => x.id === page);
  //     },
  //     deep: true,
  //     immediate: true
  //   }
  // },
}
</script>
<style scoped lang="scss">
.section-container {
  direction: rtl;
  //height: calc(100% - 56px);
  flex: 1 1 0;
  justify-content: stretch;
  display: flex;
  .menu{
    position: absolute;
    //width: 20%;
    div{
      display: block;
      text-align: right;
      padding: 10px;
      border: 1px solid;
      color: #f6e4cc;
      border-radius: 10px;
      margin-bottom: 5px;
      cursor: pointer;
      &.active{
        background-color: #f6e4cc;
        color: #545871;
      }
    }
  }
}
@media only screen and (max-width: 768px){
  .section-container {
    flex-direction: column;
    .menu-container{
      display: flex;
      justify-content: center;
    }
    .menu{
      position: static;
      width: 90%;
      div{
        display: block;
        text-align: right;
        padding: 10px;
        border: 1px solid;
        color: #545871;
        background-color: #f6e4cc;
        //background-color: #545871;
        border-radius: 10px;
        margin-bottom: 5px;
        cursor: pointer;
        &.active{
          color: #f6e4cc;
          background-color: #545871;
        }
      }
    }
    .content .product-container.better{
      max-width: 50% !important;
    }
  }
}
.content {
  display: flex;
  //justify-content: center;
  flex-direction: column;
  //height: calc(100vh - 56px);
  width: 100%;
  padding: 0 20px;
  justify-content: flex-start;
  line-height: 1.65;
  .header {
    font-size: 2rem;
    font-weight: bold;
    margin: 1.78rem;
    &.smaller{
      font-size: 1.57rem;
    }
  }
  .text {

    //text-align: center;
    text-align: right;
    font-size: 1.28rem;
    white-space: pre-line;
    ul{
      padding-right: 1.43rem;
    }
  }
  .row{
    display: flex;
    justify-content: space-evenly;
  }
  .product-container {
    //width: 50%;
    display: flex;
    align-items: center;
    flex-direction: column;
    &.better{
      align-items: flex-start;
      max-width: 40%;
    }
    .border{
      border: 3px solid;
      border-radius: 9px;
      padding: .7rem 1.07rem;
      &.better{
        background-color: #7fddc6;
      }
    }
    .small-and-right{
      text-align: right;
    }
    .text{
      font-size: 1.28rem;
      text-align: center;
      sup.small{
        font-size: 1.28rem;
        top: -3px;
      }
    }
 }
  .tos{
    padding-right: 3.57rem;
    .smaller{
      font-size: 1rem;
    }
  }
  .text{
    font-size: 1.28rem;
    sup.small{
      font-size: 1.28rem;
      top: -3px;
    }
  }
}
</style>