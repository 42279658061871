<template>
  <div class="advisor-card">
    <div class="advisor-card-title">
      {{ $t('theAdvisor') }}
    </div>
    <div class="advisor-name flex space-between">
      <!-- TODO: fetch the values here from the store. -->
      <span class="date-span"> {{ today() }} </span><span> {{ advisor.name }} </span> 
    </div>
    <div class="advisor-phone">
      {{ advisor.phone }}
    </div>
    <div class="advisor-phone">
      {{ advisor.email }}
    </div>
    <div class="advisor-email">
      <!-- advisor@gmail.com -->
      <!-- {{ advisorData.email }} -->
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'AdvisorCard',
  data() {
    return {
      advisor: this.setAdvisor()
    };
  },
  computed: {
    advisorData() {
      return this.$store.getters['teacher/getSelectedStudent'].formData
        .submittedBy;
    }
  },
  methods: {
    today() {
      moment.locale('he');
      return moment().format('DD MMM YYYY');
    },
    setAdvisor() {
      const student = this.$store.getters['teacher/getSelectedStudent'];
      if (student) {
        let advisor;
        if (student.formData) {
          if (student.formData.submittedBy) {
            advisor = student.formData.submittedBy;
          } else {
            advisor = student.createdBy;
          }
        } else {
          advisor = student.createdBy;
        }
        return {
          name: `${advisor.firstName} ${advisor.lastName}`,
          phone: advisor.phone,
          email: advisor.email
        };
      }
      return {};
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/style.scss';

.advisor-card {
  text-align: right;
}

.advisor-card-title {
  font-size: $font-size-footer;
  color: $color-lightblue;
  // padding-bottom: 2em;
  padding-bottom: 2vh;
}

.advisor-phone,
.advisor-name,
.advisor-email {
  font-size: $font-size-txt;
  color: $color-white;
  line-height: $modularSideBarLineHeight;
}

.date-span {
  direction: rtl;
}
</style>
