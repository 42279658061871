const grades = {
  '1': 'א',
  '2': 'ב',
  '3': 'ג',
  '4': 'ד',
  '5': 'ה',
  '6': 'ו'
};

export default {
  deepClone: objectOrArray => JSON.parse(JSON.stringify(objectOrArray)),
  listenToResize() {
    window.addEventListener('resize', () => {
      this.desktop = window.innerWidth > 768;
    });
  },
  isDesktop() {
    return window.innerWidth > 768;
  },
  mapSchoolClassesToHeb: schoolClasses => {
    // debugger;
    return schoolClasses.map(schoolClass => ({
      ...schoolClass,
      schoolClassName:
        grades[schoolClass.classGrade] + "' " + schoolClass.classNumber
    }));
  }
};
