<template lang="pug">
  .section-container
    contactus-sidebar
    .edvise-container.content
      .header חווית משתמשים
      .slick-container
        VueSlickCarousel(v-bind="settings")
          template(v-for="review in reviews")
            review-box(:review="review", @showModal="showModal")
      Transition(name="fade")
        .modal-container(v-if="modalReview", @click="closeIfNeeded", ref="modal")
          review-box.modal(:review="modalReview", readMore="true", @closeModal="modalReview = false")
</template>

<script>
import ContactusSidebar from "@/components/ContactusSidebar";
import ReviewBox from "@/views/home/ReviewBox";
import ReviewsText from "@/locales/home-reviews.js";
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
export default {
  name: "reviews-section",
  components: {
    ReviewBox,
    'contactus-sidebar': ContactusSidebar,
    VueSlickCarousel
  },
  data: () => ({
    reviews: ReviewsText.reviews.map(x=>x),
    pageReviews: [],
    modalReview: false,
    settings: {
      "dots": true,
      // "focusOnSelect": true,
      "infinite": true,
      "speed": 500,
      "slidesToShow": window.innerWidth > 768 ? 3 : 1,
      "slidesToScroll": window.innerWidth > 768 ? 3 : 1,
      // "touchThreshold": 5,
      "rtl": true,
      draggable: false,
      touchMove: false
    }
  }),
  mounted() {
    this.pageReviews = this.reviews.slice(0, 3);

  },
  methods:{
    showModal(e){
      this.modalReview = e
    },
    closeIfNeeded(e){
      if(e.target === this.$refs.modal){
        this.modalReview = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
@media only screen and (max-width: 768px) {
  .modal-container{
    height: 100vh !important;
    width: 100vw !important;
    .modal{
      width: 100vw !important;
      .box{
        width: auto !important;
      }
    }

  }
}
.content{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 56px);
  width: 100%;
  .edvise{
    color: #860d0d;
  }
  .header{
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1.78rem;
  }
  .reviews-container{
    display: flex;
    width: 100%;
    //width: 33%;
    //display: inline-block;
    justify-content: space-around;
  }
  .slick-container{
    width: 70rem;
    max-width: 990px;
  }
  .modal-container{
    position: absolute;
    height: calc(100% - 56px);
    width: 77%;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(100,100,100,.5);
    backdrop-filter: blur(2px);
    @media only screen and (max-width: 768px) {
      width: 100vw !important;
    }
    .box{
      width: 500px;

      border: 3px solid #2c3e50;
      height: 500px;
      filter: blur(0px);
      border-radius: 10px;
      padding: 20px 40px;
      background-color: #f6e4cc;
    }
  }
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to{
    opacity: 0;
  }
}
@media (max-width: 768px) {
  .slick-container{
    width: 85vw !important;
    //max-width: 990px;
  }
  .content{
    height: auto !important;
    margin-bottom: 25px;
  }
}
</style>
<style lang="scss">
.slick-slide {
  padding: 0 10px;
}
.slick-prev:before, .slick-next:before{
  color: #50546e;
}
.modal-container{

  .box{
    width: 500px;
    border: 3px solid #2c3e50;
    height: 500px;
    filter: blur(0px);
    border-radius: 10px;
    padding: 20px 40px;
    background-color: #f6e4cc;
  }
}

</style>