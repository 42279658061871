export default {
  methods: {
    showSpinner() {
      //   debugger;
      this.$store.state.appRootServices.spinner(true);
    },
    hideSpinner() {
      this.$store.state.appRootServices.spinner(false);
    }
  }
};
