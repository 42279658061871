<template lang="pug">
  .section-container
    contactus-sidebar
      .menu(v-if="$route.name === 'products'")
        router-link.item(v-for="page in pages", tag="div", :to="'/products/'+page.id",
          :class="{active: $route.params.page === page.id}") {{page.header}}
    .menu-container(v-if="mobile")
      .menu(v-if="$route.name === 'products'")
        router-link.item(v-for="page in pages", tag="div", :to="'/products/'+page.id",
          :class="{active: $route.params.page === page.id}") {{page.header}}
    .content(v-if="!page")
      .header מה זה Edvise?
      .row
        .product-container
          .product-name #[a(href="javascript:void(0)", @click="$router.push('/products/map')") מפה תפקודית]
          a.product-image(href="javascript:void(0)", @click="$router.push('/products/map')")
            img(src="@/assets/img/products/map.png")
        .product-container
          .product-name #[a(href="javascript:void(0)", @click="$router.push('/products/personal-intervention')") תוכנית התערבות אישית]
          a.product-image(href="javascript:void(0)", @click="$router.push('/products/personal-intervention')")
            img(src="@/assets/img/products/personal-intervention.png")
      .row
        .product-container
          .product-name #[a(href="javascript:void(0)", @click="$router.push('/products/tachi-generator')") מחולל תח"י]
          a.product-image(href="javascript:void(0)", @click="$router.push('/products/tachi-generator')")
            img(src="@/assets/img/products/tachi-generator.png")
        .product-container
          .product-name #[a(href="javascript:void(0)", @click="$router.push('/products/tala-generator')") מחולל תל"א]
          a.product-image(href="javascript:void(0)", @click="$router.push('/products/tala-generator')")
            img(src="@/assets/img/products/tala-generator.png")

    .content(v-else)
      .header {{page.header}}
      .text {{page.content}}
      .image
        img(:src="page.image")
</template>

<script>
import ContactusSidebar from "@/components/ContactusSidebar";

export default {
  name: "products-section",
  components: {'contactus-sidebar': ContactusSidebar},
  data: ()=>({
    page: 'map',
    mobile: window.innerWidth < 768,
    pages: [
      {
        id: 'map',
        header: 'מפה תפקודית',
        content: 'מיפוי יכולות התלמיד על פי עשרה תחומי תפקוד שונים והצגתם במפה המסייעת לצוותים\n' +
            'בארגון המידע והבנה טובה יותר של יכולות התלמיד והקשיים עימם הוא מתמודד.',
        image: require('../../assets/img/products/map.png')
      },{
        id: 'personal-intervention',
        header: 'תוכנית התערבות אישית',
        content: 'תוכנית המנגישה כלים ודרכי הוראה המסייעים לתלמיד בלמידה בתוך הכיתה. ובכך Edvise\n' +
            ' מאפשרת לבתי הספר להכיר ולהכיל את הצרכים של כל התלמידים.',
        image: require('../../assets/img/products/personal-intervention.png')
      },{
        id: 'tachi-generator',
        header: 'מחולל תח"י',
        content: 'מחולל המסייע בכתיבת התח"י (תוכנית חינוכית יחידנית) הנבנית עבור תלמידים הנמצאים\n' +
            'בתוכנית השילוב במסגרת מערכת החינוך הרגיל. טופס המשמש את המחנכת והצוות\n' +
            'המקצועי בבניית תוכנית עבור התלמיד. התוכנית נבנית בתחילת השנה ומשותפת עם\n' +
            ' התלמיד והוריו ולאורך השנה נעזרים בה בישיבות השונות.',
        image: require('../../assets/img/products/tachi-generator.png')
      },{
        id: 'tala-generator',
        header: 'מחולל תל"א',
        content: 'מחולל המסייע בכתיבת התל"א (תוכנית לימודית אישית) הנבנית עבור תלמידים עם לקות\n' +
            'בתחומי תפקוד שונים הקשורים ללמידה ולשילוב במערכת החינוך הרגילה או המיוחדת.\n' +
            'טופס המשמש את המחנכת והצוות המקצועי בבניית תוכנית עבור התלמיד.\n' +
            'התוכנית נבנית בתחילת השנה ומשותפת עם התלמיד והוריו ולאורך השנה נעזרים בה בישיבות השונות.',
        image: require('../../assets/img/products/tala-generator.png')
      },
    ]
  }),
  mounted(){
    this.page = this.pages.find((x) => x.id === this.$route.params.page);
  },
  watch:{
    '$route.params.page': {
      handler: function(page) {
        this.page = this.pages.find((x) => x.id === page);
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    toPage(){

    }
  }
}
</script>
<style scoped lang="scss">
.section-container {
  direction: rtl;
  //height: calc(100% - 56px);
  flex: 1 1 0;
  justify-content: stretch;
  display: flex;
  .menu{
    position: absolute;
    //width: 20%;
    div{
      display: block;
      text-align: right;
      padding: .7rem 1.43rem;
      border: 1px solid;
      color: #f6e4cc;
      border-radius: 10px;
      margin-bottom: 5px;
      cursor: pointer;
      &.active{
        background-color: #f6e4cc;
        color: #545871;
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .content{
    img{
      width: 90%;
    }
  }
}
@media only screen and (max-width: 768px){
  .section-container {
    flex-direction: column;
    .menu-container{
      display: flex;
      justify-content: center;
    }
    .menu{
      position: static;
      width: 90%;
      div{
        display: block;
        text-align: right;
        padding: 10px;
        border: 1px solid;
        color: #545871;
        background-color: #f6e4cc;
        //background-color: #545871;
        border-radius: 10px;
        margin-bottom: 5px;
        cursor: pointer;
        &.active{
          color: #f6e4cc;
          background-color: #545871;
        }
      }
    }
    .content .product-container.better{
      max-width: 50% !important;
    }
  }
}
.content{
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: calc(100vh - 56px);
  width: 100%;
  .header{
    font-size: 2rem;
    font-weight: bold;
    margin-top: 1.43rem;
  }
  .row{
    display: flex;
    margin-top: 1.43rem;
    text-align: center;
    .product-container{
      width: 50%;
      display: flex;
      align-items: center;
      flex-direction: column;
      a:link, a:visited{
        text-decoration: none;
        color: #2c3e50;
      }
      .product-name{
        font-size: 1.28rem;
        padding: 0 20px 0 20px;
        text-align: center;
      }
      .product-image {
        width: 75%;
        img{
          aspect-ratio: 16/9;
          object-fit: contain;
          width: 100%;
        }
      }
    }
  }

  .text{
    text-align: center;
    font-size: 1.28rem;
    white-space: pre-line;
  }
  .image{
    width: 100%;
    margin-top: 3.57rem;
    display: flex;
    justify-content: center;
    align-items: center;
    img{
      //width: 700px;
      //height: 300px;
      //aspect-ratio: 16/9;
      //object-fit: contain;
      border: 4px solid;
      max-width: 700px;
      max-height: 400px;
    }
  }
}
</style>