import { render, staticRenderFns } from "./ModularSideBar.vue?vue&type=template&id=17de8916&scoped=true&lang=pug"
import script from "./ModularSideBar.vue?vue&type=script&lang=js"
export * from "./ModularSideBar.vue?vue&type=script&lang=js"
import style0 from "./ModularSideBar.vue?vue&type=style&index=0&id=17de8916&prod&lang=scss&scoped=true"
import style1 from "./ModularSideBar.vue?vue&type=style&index=1&id=17de8916&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17de8916",
  null
  
)

export default component.exports