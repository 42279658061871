module.exports = {
	"0": {
		"IsStudDiagnosed": {
			"answers": [
				{
					"value": 0,
					"labelCode": "YES"
				},
				{
					"value": 1,
					"labelCode": "NO"
				}
			],
			"qNum": 1
		}
	},
	"1": {
		"IsRestless": {
			"answers": [
				{
					"value": 1,
					"labelCode": "HARD"
				},
				{
					"value": 0,
					"labelCode": "ATA"
				},
				{
					"value": 0,
					"labelCode": "IDK"
				}
			],
			"qNum": 3
		},
		"IsDayDream": {
			"answers": [
				{
					"value": 1,
					"labelCode": "HARD"
				},
				{
					"value": 0,
					"labelCode": "ATA"
				},
				{
					"value": 0,
					"labelCode": "IDK"
				}
			],
			"qNum": 4
		},
		"IsImpulsive": {
			"answers": [
				{
					"value": 1,
					"labelCode": "HARD"
				},
				{
					"value": 0,
					"labelCode": "ATA"
				},
				{
					"value": 0,
					"labelCode": "IDK"
				}
			],
			"qNum": 5,
			"tooltip": true
		},
		"IsSlowToAct": {
			"answers": [
				{
					"value": 1,
					"labelCode": "HARD"
				},
				{
					"value": 0,
					"labelCode": "ATA"
				},
				{
					"value": 0,
					"labelCode": "IDK"
				}
			],
			"qNum": 6
		},
		"IsAssociative": {
			"answers": [
				{
					"value": 1,
					"labelCode": "HARD"
				},
				{
					"value": 0,
					"labelCode": "ATA"
				},
				{
					"value": 0,
					"labelCode": "IDK"
				}
			],
			"qNum": 7,
			"tooltip": true
		},
		"IsHardToFinish": {
			"answers": [
				{
					"value": 1,
					"labelCode": "HARD"
				},
				{
					"value": 0,
					"labelCode": "ATA"
				},
				{
					"value": 0,
					"labelCode": "IDK"
				}
			],
			"qNum": 8
		},
		"IsVentilation": {
			"answers": [
				{
					"value": 1,
					"labelCode": "HARD"
				},
				{
					"value": 0,
					"labelCode": "ATA"
				},
				{
					"value": 0,
					"labelCode": "IDK"
				}
			],
			"qNum": 9
		},
		"IsInfoFlood": {
			"answers": [
				{
					"value": 1,
					"labelCode": "HARD"
				},
				{
					"value": 0,
					"labelCode": "ATA"
				},
				{
					"value": 0,
					"labelCode": "IDK"
				}
			],
			"qNum": 10
		},
		"IsVisualAttention": {
			"answers": [
				{
					"value": 0,
					"labelCode": "YES"
				},
				{
					"value": 1,
					"labelCode": "NO"
				},
				{
					"value": 0,
					"labelCode": "IDK"
				}
			],
			"qNum": 11,
			"tooltip": true
		},
		"IsActiveWorkingMemory": {
			"answers": [
				{
					"value": 0,
					"labelCode": "YES"
				},
				{
					"value": 1,
					"labelCode": "NO"
				},
				{
					"value": 0,
					"labelCode": "IDK"
				}
			],
			"qNum": 12,
			"tooltip": true
		},
		"IsNeuroDiagnosed": {
			"answers": [
				{
					"value": 2,
					"labelCode": "YES_CARED"
				},
				{
					"value": 1,
					"labelCode": "YES_UNCARED"
				},
				{
					"value": 0,
					"labelCode": "NO"
				},
				{
					"value": 0,
					"labelCode": "IDK"
				}
			],
			"qNum": 13
		}
	},
	"2": {
		"ByHeartParticipation": {
			"answers": [
				{
					"value": 1,
					"labelCode": "HARD"
				},
				{
					"value": 0,
					"labelCode": "ATA"
				},
				{
					"value": -1,
					"labelCode": "STG"
				}
			],
			"qNum": 14
		},
		"Vocabulary": {
			"answers": [
				{
					"value": 1,
					"labelCode": "HARD"
				},
				{
					"value": 0,
					"labelCode": "ATA"
				},
				{
					"value": -1,
					"labelCode": "STG"
				}
			],
			"qNum": 15
		},
		"WordRecall": {
			"answers": [
				{
					"value": 1,
					"labelCode": "HARD"
				},
				{
					"value": 0,
					"labelCode": "ATA"
				},
				{
					"value": -1,
					"labelCode": "STG"
				}
			],
			"qNum": 16,
			"tooltip": true
		},
		"AutoQuickNaming": {
			"answers": [
				{
					"value": 0,
					"labelCode": "YES"
				},
				{
					"value": 1,
					"labelCode": "NO"
				},
				{
					"value": 0,
					"labelCode": "IDK"
				}
			],
			"qNum": 18,
			"tooltip": true
		},
		"PhonologicStream": {
			"answers": [
				{
					"value": 0,
					"labelCode": "YES"
				},
				{
					"value": 1,
					"labelCode": "NO"
				},
				{
					"value": 0,
					"labelCode": "IDK"
				}
			],
			"qNum": 19
		},
		"SemanticStream": {
			"answers": [
				{
					"value": 0,
					"labelCode": "YES"
				},
				{
					"value": 1,
					"labelCode": "NO"
				},
				{
					"value": 1,
					"labelCode": "IDK"
				}
			],
			"qNum": 20
		}
	},
	"3": {
		"ContextualMemoryImmediate": {
			"answers": [
				{
					"value": 0,
					"labelCode": "YES"
				},
				{
					"value": 1,
					"labelCode": "NO"
				},
				{
					"value": "UNKNOWN",
					"labelCode": "IDK"
				}
			],
			"qNum": 23,
			"tooltip": true
		},
		"ContextualMemoryPostponed": {
			"answers": [
				{
					"value": 0,
					"labelCode": "YES"
				},
				{
					"value": 1,
					"labelCode": "NO"
				},
				{
					"value": "UNKNOWN",
					"labelCode": "IDK"
				}
			],
			"qNum": 24,
			"tooltip": true
		},
		"SingletonMemoryImmediate": {
			"answers": [
				{
					"value": 0,
					"labelCode": "YES"
				},
				{
					"value": 1,
					"labelCode": "NO"
				},
				{
					"value": "UNKNOWN",
					"labelCode": "IDK"
				}
			],
			"qNum": 25
		},
		"SingletonMemoryPostponed": {
			"answers": [
				{
					"value": 0,
					"labelCode": "YES"
				},
				{
					"value": 1,
					"labelCode": "NO"
				},
				{
					"value": "UNKNOWN",
					"labelCode": "IDK"
				}
			],
			"qNum": 26
		},
		"MemoryIdentification": {
			"answers": [
				{
					"value": 0,
					"labelCode": "YES"
				},
				{
					"value": 1,
					"labelCode": "NO"
				},
				{
					"value": 1,
					"labelCode": "IDK"
				}
			],
			"qNum": 27,
			"tooltip": true
		},
		"MemoryLearnRepetitionCurve": {
			"answers": [
				{
					"value": 0,
					"labelCode": "YES"
				},
				{
					"value": 1,
					"labelCode": "NO"
				},
				{
					"value": 1,
					"labelCode": "IDK"
				}
			],
			"qNum": 29
		},
		"MemoryLearnCurveSuddenDrop": {
			"answers": [
				{
					"value": 1,
					"labelCode": "YES"
				},
				{
					"value": 0,
					"labelCode": "NO"
				},
				{
					"value": 0,
					"labelCode": "IDK"
				}
			],
			"qNum": 31,
			"tooltip": true
		},
		"RetroProActiveMemory": {
			"answers": [
				{
					"value": 1,
					"labelCode": "YES"
				},
				{
					"value": 0,
					"labelCode": "NO"
				},
				{
					"value": 0,
					"labelCode": "IDK"
				}
			],
			"qNum": 32,
			"tooltip": true
		}
	},
	"4": {
		"VisualPerformanceNoMotor": {
			"answers": [
				{
					"value": 0,
					"labelCode": "YES"
				},
				{
					"value": 1,
					"labelCode": "NO"
				},
				{
					"value": 0,
					"labelCode": "IDK"
				}
			],
			"qNum": 33
		},
		"VisioMotoricIntegration": {
			"answers": [
				{
					"value": 1,
					"labelCode": "YES"
				},
				{
					"value": 0,
					"labelCode": "NO"
				},
				{
					"value": "UNKNOWN",
					"labelCode": "IDK"
				}
			],
			"qNum": 34,
			"tooltip": true
		},
		"VisualIdentifyGen": {
			"answers": [
				{
					"value": 0,
					"labelCode": "YES"
				},
				{
					"value": 1,
					"labelCode": "NO"
				},
				{
					"value": "UNKNOWN",
					"labelCode": "IDK"
				}
			],
			"qNum": 35
		},
		"VisualIdentifyImmediate": {
			"answers": [
				{
					"value": 0,
					"labelCode": "YES"
				},
				{
					"value": 1,
					"labelCode": "NO"
				},
				{
					"value": "UNKNOWN",
					"labelCode": "IDK"
				}
			],
			"qNum": 36,
			"tooltip": true
		},
		"VisualIdentifyPostponed": {
			"answers": [
				{
					"value": 0,
					"labelCode": "YES"
				},
				{
					"value": 1,
					"labelCode": "NO"
				},
				{
					"value": "UNKNOWN",
					"labelCode": "IDK"
				}
			],
			"qNum": 37,
			"tooltip": true
		},
		"GestaltPerception": {
			"answers": [
				{
					"value": 0,
					"labelCode": "YES"
				},
				{
					"value": 1,
					"labelCode": "NO"
				},
				{
					"value": "UNKNOWN",
					"labelCode": "IDK"
				}
			],
			"qNum": 38,
			"tooltip": true
		},
		"DetailsPerception": {
			"answers": [
				{
					"value": 0,
					"labelCode": "YES"
				},
				{
					"value": 1,
					"labelCode": "NO"
				},
				{
					"value": "UNKNOWN",
					"labelCode": "IDK"
				}
			],
			"qNum": 39,
			"tooltip": true
		}
	},
	"5": {
		"AbstractNonVerbalThinking": {
			"answers": [
				{
					"value": 0,
					"labelCode": "YES"
				},
				{
					"value": 1,
					"labelCode": "NO"
				},
				{
					"value": 0,
					"labelCode": "IDK"
				}
			],
			"qNum": 41,
			"tooltip": true
		},
		"NonAbstractVerbalThinking": {
			"answers": [
				{
					"value": 0,
					"labelCode": "YES"
				},
				{
					"value": 1,
					"labelCode": "NO"
				},
				{
					"value": 0,
					"labelCode": "IDK"
				}
			],
			"qNum": 42,
			"tooltip": true
		},
		"IsStudentInSpeciaEdKindergarden": {
			"answers": [
				{
					"value": 1,
					"labelCode": "YES"
				},
				{
					"value": 0,
					"labelCode": "NO"
				}
			],
			"qNum": 49
		},
		"IsStudentRecommendedForSpecialEdInRegularSchool": {
			"answers": [
				{
					"value": 1,
					"labelCode": "YES"
				},
				{
					"value": 0,
					"labelCode": "NO"
				}
			],
			"qNum": 50
		},
		"IsGeneralIntl": {
			"answers": [
				{
					"value": 1,
					"labelCode": "ABN_LOW"
				},
				{
					"value": 0,
					"labelCode": "AVG_HIGH_ABV"
				},
				{
					"value": 0,
					"labelCode": "IDK"
				}
			],
			"qNum": 46,
			"tooltip": true
		},
		"IsVerbalCrystal": {
			"answers": [
				{
					"value": 1,
					"labelCode": "ABN_LOW"
				},
				{
					"value": 0,
					"labelCode": "AVG_HIGH_ABV"
				},
				{
					"value": 0,
					"labelCode": "IDK"
				}
			],
			"qNum": 47,
			"tooltip": true
		},
		"IsDoerFluid": {
			"answers": [
				{
					"value": 1,
					"labelCode": "ABN_LOW"
				},
				{
					"value": 0,
					"labelCode": "AVG_HIGH_ABV"
				},
				{
					"value": 1,
					"labelCode": "IDK"
				}
			],
			"qNum": 48,
			"tooltip": true
		}
	},
	"6": {
		"IsTextReadAccurate": {
			"answers": [
				{
					"value": 0,
					"labelCode": "YES"
				},
				{
					"value": 1,
					"labelCode": "NO"
				},
				{
					"value": 0,
					"labelCode": "IDK"
				}
			],
			"qNum": 56
		},
		"IsPhonologicAwarness": {
			"answers": [
				{
					"value": 0,
					"labelCode": "YES"
				},
				{
					"value": 1,
					"labelCode": "NO"
				},
				{
					"value": 0,
					"labelCode": "IDK"
				}
			],
			"qNum": 51,
			"tooltip": true
		},
		"VowelsAndConstants": {
			"answers": [
				{
					"value": 0,
					"labelCode": "YES"
				},
				{
					"value": 1,
					"labelCode": "NO"
				},
				{
					"value": 1,
					"labelCode": "IDK"
				}
			],
			"qNum": 52
		},
		"MeaningfulWords": {
			"answers": [
				{
					"value": 0,
					"labelCode": "YES"
				},
				{
					"value": 1,
					"labelCode": "NO"
				},
				{
					"value": 0,
					"labelCode": "IDK"
				}
			],
			"qNum": 54
		},
		"Nonword": {
			"answers": [
				{
					"value": 0,
					"labelCode": "YES"
				},
				{
					"value": 1,
					"labelCode": "NO"
				},
				{
					"value": 0,
					"labelCode": "IDK"
				}
			],
			"qNum": 53
		},
		"WritingTemplate": {
			"answers": [
				{
					"value": 0,
					"labelCode": "YES"
				},
				{
					"value": 1,
					"labelCode": "NO"
				},
				{
					"value": 1,
					"labelCode": "IDK"
				}
			],
			"qNum": 55
		},
		"AudibleTextPace": {
			"answers": [
				{
					"value": 0,
					"labelCode": "YES"
				},
				{
					"value": 1,
					"labelCode": "NO"
				},
				{
					"value": 0,
					"labelCode": "IDK"
				}
			],
			"qNum": 57
		},
		"SilentTextPace": {
			"answers": [
				{
					"value": 0,
					"labelCode": "YES"
				},
				{
					"value": 1,
					"labelCode": "NO"
				},
				{
					"value": 0,
					"labelCode": "IDK"
				}
			],
			"qNum": 58
		}
	},
	"7": {
		"ListeningCompInformativeTextLow": {
			"answers": [
				{
					"value": 0,
					"labelCode": "YES"
				},
				{
					"value": 1,
					"labelCode": "NO"
				},
				{
					"value": "UNKNOWN",
					"labelCode": "IDK"
				}
			],
			"qNum": 59
		},
		"ListeningCompInformativeTextHigh": {
			"answers": [
				{
					"value": 0,
					"labelCode": "YES"
				},
				{
					"value": 1,
					"labelCode": "NO"
				},
				{
					"value": "UNKNOWN",
					"labelCode": "IDK"
				}
			],
			"qNum": 60
		},
		"ListeningCompStoryTextLow": {
			"answers": [
				{
					"value": 0,
					"labelCode": "YES"
				},
				{
					"value": 1,
					"labelCode": "NO"
				},
				{
					"value": "UNKNOWN",
					"labelCode": "IDK"
				}
			],
			"qNum": 61
		},
		"ListeningCompStoryTextHigh": {
			"answers": [
				{
					"value": 0,
					"labelCode": "YES"
				},
				{
					"value": 1,
					"labelCode": "NO"
				},
				{
					"value": "UNKNOWN",
					"labelCode": "IDK"
				}
			],
			"qNum": 62
		},
		"HearingCompInformativeTextLow": {
			"answers": [
				{
					"value": 0,
					"labelCode": "YES"
				},
				{
					"value": 1,
					"labelCode": "NO"
				},
				{
					"value": "UNKNOWN",
					"labelCode": "IDK"
				}
			],
			"qNum": 63
		},
		"HearingCompInformativeTextHigh": {
			"answers": [
				{
					"value": 0,
					"labelCode": "YES"
				},
				{
					"value": 1,
					"labelCode": "NO"
				},
				{
					"value": "UNKNOWN",
					"labelCode": "IDK"
				}
			],
			"qNum": 64
		},
		"HearingCompStoryTextLow": {
			"answers": [
				{
					"value": 0,
					"labelCode": "YES"
				},
				{
					"value": 1,
					"labelCode": "NO"
				},
				{
					"value": "UNKNOWN",
					"labelCode": "IDK"
				}
			],
			"qNum": 65
		},
		"HearingCompStoryTextHigh": {
			"answers": [
				{
					"value": 0,
					"labelCode": "YES"
				},
				{
					"value": 1,
					"labelCode": "NO"
				},
				{
					"value": "UNKNOWN",
					"labelCode": "IDK"
				}
			],
			"qNum": 66
		}
	},
	"8": {
		"IsPageOrganised": {
			"answers": [
				{
					"value": 0,
					"labelCode": "YES"
				},
				{
					"value": 1,
					"labelCode": "NO"
				},
				{
					"value": 0,
					"labelCode": "IDK"
				}
			],
			"qNum": 67
		},
		"IsHandWriteStyle": {
			"answers": [
				{
					"value": 0,
					"labelCode": "YES"
				},
				{
					"value": 1,
					"labelCode": "NO"
				},
				{
					"value": 0,
					"labelCode": "IDK"
				}
			],
			"qNum": 68
		},
		"IsPencilHold": {
			"answers": [
				{
					"value": 0,
					"labelCode": "YES"
				},
				{
					"value": 1,
					"labelCode": "NO"
				},
				{
					"value": 0,
					"labelCode": "IDK"
				}
			],
			"qNum": 69
		},
		"IsTireFactor": {
			"answers": [
				{
					"value": 1,
					"labelCode": "YES"
				},
				{
					"value": 0,
					"labelCode": "NO"
				},
				{
					"value": 1,
					"labelCode": "IDK"
				}
			],
			"qNum": 70
		},
		"IsCommonSpelling": {
			"answers": [
				{
					"value": 1,
					"labelCode": "YES"
				},
				{
					"value": 0,
					"labelCode": "NO"
				},
				{
					"value": 1,
					"labelCode": "IDK"
				}
			],
			"qNum": 71,
			"tooltip": true
		},
		"IsSevereSpelling": {
			"answers": [
				{
					"value": 1,
					"labelCode": "YES"
				},
				{
					"value": 0,
					"labelCode": "NO"
				},
				{
					"value": 1,
					"labelCode": "IDK"
				}
			],
			"qNum": 72,
			"tooltip": true
		},
		"IsMessageOrganised": {
			"answers": [
				{
					"value": 0,
					"labelCode": "YES"
				},
				{
					"value": 1,
					"labelCode": "NO"
				},
				{
					"value": 0,
					"labelCode": "IDK"
				}
			],
			"qNum": 73
		},
		"IsLinguisticHigh": {
			"answers": [
				{
					"value": 0,
					"labelCode": "YES"
				},
				{
					"value": 1,
					"labelCode": "NO"
				},
				{
					"value": 0,
					"labelCode": "IDK"
				}
			],
			"qNum": 74
		},
		"IsWritePace": {
			"answers": [
				{
					"value": 0,
					"labelCode": "YES"
				},
				{
					"value": 1,
					"labelCode": "NO"
				},
				{
					"value": 0,
					"labelCode": "IDK"
				}
			],
			"qNum": 75
		},
		"IsOpenQuestion": {
			"answers": [
				{
					"value": 0,
					"labelCode": "YES"
				},
				{
					"value": 1,
					"labelCode": "NO"
				},
				{
					"value": 0,
					"labelCode": "IDK"
				}
			],
			"qNum": 76,
			"tooltip": true
		},
		"IsClosedQuestion": {
			"answers": [
				{
					"value": 0,
					"labelCode": "YES"
				},
				{
					"value": 1,
					"labelCode": "NO"
				},
				{
					"value": 0,
					"labelCode": "IDK"
				}
			],
			"qNum": 77
		}
	},
	"9": {
		"Cooperation": {
			"answers": [
				{
					"value": 1,
					"labelCode": "HARD"
				},
				{
					"value": 0,
					"labelCode": "ATA"
				},
				{
					"value": 0,
					"labelCode": "STG"
				}
			],
			"qNum": 78
		},
		"PeerContact": {
			"answers": [
				{
					"value": 1,
					"labelCode": "HARD"
				},
				{
					"value": 0,
					"labelCode": "ATA"
				},
				{
					"value": 0,
					"labelCode": "STG"
				}
			],
			"qNum": 79
		},
		"SocialEtiquette": {
			"answers": [
				{
					"value": 1,
					"labelCode": "HARD"
				},
				{
					"value": 0,
					"labelCode": "ATA"
				},
				{
					"value": 0,
					"labelCode": "STG"
				}
			],
			"qNum": 80
		},
		"AdultsRelations": {
			"answers": [
				{
					"value": 1,
					"labelCode": "HARD"
				},
				{
					"value": 0,
					"labelCode": "ATA"
				},
				{
					"value": 0,
					"labelCode": "STG"
				}
			],
			"qNum": 81
		},
		"ClassAttendance": {
			"answers": [
				{
					"value": 1,
					"labelCode": "HARD"
				},
				{
					"value": 0,
					"labelCode": "ATA"
				},
				{
					"value": 0,
					"labelCode": "STG"
				},
				{
					"value": 0,
					"labelCode": "IDK"
				}
			],
			"qNum": 82
		},
		"ClassParticipate": {
			"answers": [
				{
					"value": 1,
					"labelCode": "HARD"
				},
				{
					"value": 0,
					"labelCode": "ATA"
				},
				{
					"value": 0,
					"labelCode": "STG"
				},
				{
					"value": 0,
					"labelCode": "IDK"
				}
			],
			"qNum": 83
		},
		"ChangeCope": {
			"answers": [
				{
					"value": 1,
					"labelCode": "HARD"
				},
				{
					"value": 0,
					"labelCode": "ATA"
				},
				{
					"value": 0,
					"labelCode": "STG"
				},
				{
					"value": 0,
					"labelCode": "IDK"
				}
			],
			"qNum": 84
		},
		"Anxiety": {
			"answers": [
				{
					"value": 1,
					"labelCode": "HARD"
				},
				{
					"value": 0,
					"labelCode": "ATA"
				},
				{
					"value": 0,
					"labelCode": "STG"
				},
				{
					"value": 0,
					"labelCode": "IDK"
				}
			],
			"qNum": 85
		},
		"FailureCope": {
			"answers": [
				{
					"value": 1,
					"labelCode": "HARD"
				},
				{
					"value": 0,
					"labelCode": "ATA"
				},
				{
					"value": 0,
					"labelCode": "STG"
				},
				{
					"value": 0,
					"labelCode": "IDK"
				}
			],
			"qNum": 86
		},
		"FrustrationThreshold": {
			"answers": [
				{
					"value": 1,
					"labelCode": "HARD"
				},
				{
					"value": 0,
					"labelCode": "ATA"
				},
				{
					"value": 0,
					"labelCode": "STG"
				},
				{
					"value": 0,
					"labelCode": "IDK"
				}
			],
			"qNum": 87
		},
		"SelfConfident": {
			"answers": [
				{
					"value": 1,
					"labelCode": "HARD"
				},
				{
					"value": 0,
					"labelCode": "ATA"
				},
				{
					"value": 0,
					"labelCode": "STG"
				}
			],
			"qNum": 88
		},
		"LearningMotivated": {
			"answers": [
				{
					"value": 1,
					"labelCode": "HARD"
				},
				{
					"value": 0,
					"labelCode": "ATA"
				},
				{
					"value": 0,
					"labelCode": "STG"
				}
			],
			"qNum": 89
		},
		"ClassEquipment": {
			"answers": [
				{
					"value": 1,
					"labelCode": "HARD"
				},
				{
					"value": 0,
					"labelCode": "ATA"
				},
				{
					"value": 0,
					"labelCode": "STG"
				},
				{
					"value": 0,
					"labelCode": "IDK"
				}
			],
			"qNum": 90
		},
		"EnvironmentOrganize": {
			"answers": [
				{
					"value": 1,
					"labelCode": "HARD"
				},
				{
					"value": 0,
					"labelCode": "ATA"
				},
				{
					"value": 0,
					"labelCode": "STG"
				}
			],
			"qNum": 91
		},
		"TaskOrganize": {
			"answers": [
				{
					"value": 1,
					"labelCode": "HARD"
				},
				{
					"value": 0,
					"labelCode": "ATA"
				},
				{
					"value": 0,
					"labelCode": "STG"
				},
				{
					"value": 0,
					"labelCode": "IDK"
				}
			],
			"qNum": 92
		},
		"AfterHoursLearn": {
			"answers": [
				{
					"value": 1,
					"labelCode": "YES"
				},
				{
					"value": 0,
					"labelCode": "NO"
				}
			],
			"qNum": 93
		},
		"ClassWorkIndependant": {
			"answers": [
				{
					"value": 1,
					"labelCode": "HARD"
				},
				{
					"value": 0,
					"labelCode": "ATA"
				},
				{
					"value": 0,
					"labelCode": "STG"
				},
				{
					"value": 0,
					"labelCode": "IDK"
				}
			],
			"qNum": 94
		},
		"Achievements": {
			"answers": [
				{
					"value": 1,
					"labelCode": "HARD"
				},
				{
					"value": 0,
					"labelCode": "ATA"
				},
				{
					"value": 0,
					"labelCode": "STG"
				},
				{
					"value": 1,
					"labelCode": "IDK"
				}
			],
			"qNum": 95
		}
	},
	"10": {
		"IsAuditoryMultiple": {
			"answers": [
				{
					"value": 0,
					"labelCode": "0"
				},
				{
					"value": 1,
					"labelCode": "1"
				},
				{
					"value": 2,
					"labelCode": "2"
				},
				{
					"value": 3,
					"labelCode": "3"
				},
				{
					"value": null,
					"labelCode": "IDK"
				}
			],
			"qNum": 96
		},
		"IsOpticMultiple": {
			"answers": [
				{
					"value": 0,
					"labelCode": "0"
				},
				{
					"value": 1,
					"labelCode": "1"
				},
				{
					"value": 2,
					"labelCode": "2"
				},
				{
					"value": 3,
					"labelCode": "3"
				},
				{
					"value": null,
					"labelCode": "IDK"
				}
			],
			"qNum": 967
		},
		"IsOpticFocusMultiple": {
			"answers": [
				{
					"value": 0,
					"labelCode": "0"
				},
				{
					"value": 1,
					"labelCode": "1"
				},
				{
					"value": 2,
					"labelCode": "2"
				},
				{
					"value": 3,
					"labelCode": "3"
				},
				{
					"value": null,
					"labelCode": "IDK"
				}
			],
			"qNum": 98
		}
	}
}