import Vue from 'vue';
import Notifications from 'vue-notification';

Vue.use(Notifications);

export default {
  notify(group, title, text, duration) {
    if(!duration) duration = 3000
    Vue.notify({
      group,
      title,
      text,
      duration
    });
  },
  noTitle(group, text, duration) {
    if(!duration) duration = 3000
    Vue.notify({
      group,
      text,
      duration
    });
  }
};
