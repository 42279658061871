<template>
  <div class="footer">
    <div>
      <router-link
        to="/tos"
        class="student-link footer-link"
      > {{ $t('navbar.tos') }} </router-link>
    </div>
    <div>
      <span> פותח במסגרת - </span>
      <a
        class="footer-link"
        href="https://edstart.education.gov.il/"
        target="_blank"
      > <span> EdStart</span></a>
      <span>. </span>
      <span> כל הזכויות שמורות </span>
      <span> -  Edvise </span>
      <span> ©</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer'
};
</script>

<style lang="scss" scoped>
.footer {
  width: 100%;
  max-width: 1366px;
  height: 56px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  font-size: 12px;
  position: fixed;
  bottom: 0;
}
.footer-link {
  text-decoration: underline black;
  color: #2c3e50;
}
.footer {
  direction: rtl;
}
</style>
