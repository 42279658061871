<template lang="pug">
.section-container
  contactus-sidebar
  //.content
  .collapsable-text-items-list(style="width:100%")
    .container
      div
        .edvise-page-title()  שאלות ותשובות
        .accordion
          .accordion-item(v-for='(dataItem , index) of qna' :key='index')
            .flex
              .ul-btn.pointer(:class="{'pls-btn': !dataItem.isOpen, 'mns-btn': dataItem.isOpen}" @click="$set(dataItem, 'isOpen', !dataItem.isOpen)")
              a(dir='rtl' @click='dataItem.isOpen = !dataItem.isOpen') {{ dataItem.question}}
            .content(v-show="dataItem.isOpen")
              p(v-html='dataItem.answer')
</template>

<script>
import HomeQNAStrings from '@/locales/home-qna.js';
import ContactusSidebar from "@/components/ContactusSidebar";
export default {
  name: 'QNA',
  components: {'contactus-sidebar': ContactusSidebar},
  data() {
    return {
      qna: [],
      openIndex: false
    };
  },
  mounted() {
    console.log(HomeQNAStrings);
    this.qna = HomeQNAStrings.qna.map(x=>{x.isOpen = false; return x;});
  }
};
</script>
<style lang="scss" scoped>
.section-container {
  direction: rtl;
  //height: calc(100% - 56px);
  justify-content: stretch;
  display: flex;
  width: 100%;
}
@import '@/assets/scss/style.scss';

*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

.original-checkbox {
  visibility: hidden;
  // position: relative;
}

.advice-checkbox-wrapper {
  position: relative;
}

.custom-checkbox {
  background-color: #fff;
  height: 17px;
  width: 17px;
  border: solid 1px #f3d5bd;
  border-radius: 3px;
  position: absolute;
  top: 11.5px;

  &.checked {
    background-image: url('../../assets/img/glyphicons/check.png');
    background-position: center;
    background-size: 50%;
  }
}

.container {
  margin: 0 auto;
  padding: 4rem 4rem 0 4rem;
  width: 50rem;
}

.btn-container {
  padding: 20px 0;
}

h1 {
  font-size: 3rem;
  color: $color-blue;
  padding: 1.5rem 0rem 2rem 1rem;
  margin: 0;
  text-align: right;
}
.ul-btn {
  margin-top: 13px;
  width: 30px;
  height: 30px;
}

.round-btn-blue {
  padding: 11px 20px;
  height: fit-content;
  width: fit-content;
}

.pls-btn {
  background-image: url('../../assets/img/glyphicons/plus@2x.png');
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: 5px 5px;
  cursor: pointer;
}
.mns-btn {
  background-image: url('../../assets/img/glyphicons/minus@2x.png');
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: 5px 13px;
  cursor: pointer;
}
.accordion a {
  text-align: right;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  padding: 1rem 0.5rem 1rem 1rem;
  color: $color-blue;
  font-size: 1.14rem;
  font-weight: 400;
}

.accordion a:hover,
.accordion a:hover::after {
  cursor: pointer;
  color: $color-darkblue;
}

.accordion a:hover::after {
  border: 1px solid $color-blue;
}
.accordion-item {
  border-bottom: 0.5px solid $color-blue;
}
// only first accordion item will have border top.
.accordion-item:first-of-type {
  border-top: 0.5px solid $color-blue;
}
.accordion a.active {
  color: $color-darkblue;
  border-bottom: 1px solid $color-darkblue;
}

.accordion a::after {
  font-family: $font-main;
  position: absolute;
  float: right;
  right: 1rem;
  font-size: 1rem;
  color: #7288a2;
  width: 50px;
  height: 50px;
  text-align: right;
}
.submission-p {
  font-family: $font-main;
  width: 200;
  font-size: 1.14rem;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: right;
  color: $color-blue;
}

.accordion a.active::after {
  font-family: 'Ionicons';
  color: $color-blue;
  // border: 1px solid $color-darkblue;
  text-align: right;
}
.accordion {
  //  overflow: hidden;
  height: 60vh;
  @media (max-height: 700px) {
    //height: 40vh;
  }
  overflow: auto;

  &.cancel-scroll {
    overflow: visible;
    height: auto;
  }
}

.smaller {
  height: 40vh;
  overflow: auto;
  padding-right: 40px;
}

.closed {
  text-align: right;
  opacity: 0;
  padding: 0 1rem;
  max-height: 0;
  border-bottom: 1px solid $color-blue;
  overflow: hidden;
  clear: both;
  // -webkit-transition: all 0.2s ease 0.15s;
  // -o-transition: all 0.2s ease 0.15s;
  // transition: all 0.2s ease 0.15s;
  :hover {
    color: $color-darkblue;
  }
}

.accordion .content p {
  font-size: 1.14rem;
  font-weight: 400;
  color: $color-blue;
  direction: rtl;
  text-align: right;
  white-space: pre-line;
}

.opened {
  text-align: right;
  opacity: 1;
  padding: 1rem;
  max-height: 100%;
  // -webkit-transition: all 0.5s ease 0.15s;
  // -o-transition: all 0.15s ease 0.15s;
  // transition: all 0.15s ease 0.15s;
  :hover {
    color: $color-darkblue;
  }
}

.advice-span {
  padding: 8px 20px;
  direction: rtl;
}

.edvise-input {
  padding-top: 5px;
  width: 100%;
  height: 10vh;
}

.textarea-container {
  text-align: right;
  padding-bottom: 3vh;

  textarea {
    margin-top: 1vh;
  }
}
</style>

<style lang="scss">
@import '@/assets/scss/style.scss';

.accordion .view {
  padding-right: 30px;
  width: calc(100% + 40px);

  ::-webkit-scrollbar-track {
    background-color: transperent;
  }
  ::-webkit-scrollbar {
    display: block;
    width: 4px;
    background-color: transperent;
  }
  ::-webkit-scrollbar-thumb {
    display: block;
    border-radius: 10px;
    background-color: $color-blue;
  }
}
@media only screen and (max-width: 768px) {
  .container{
    width: 100vw !important;
    padding: 30px 10px 0 10px !important;
    .accordion{
      width: auto !important;
      padding-right: 0 !important;
      height: auto;
      .ul-btn{
        margin-top: 7px;
      }
    }
  }
}
</style>
