<template lang="pug">
.section-container
  .sidebar
    .profile
      img(src="@/assets/img/anat.png", style="width: 130px;")
      .profile-text ענת אשור
        br
        |יזמת ומייסדת #[i Edvise]
        | מורת שילוב ומדריכה,
        | מאבחנת דידקטית ומלווה מורים והורים.
    .soon
      .header חדש! חדש!!
      .buttons
        //img(src="@/assets/img/soon.png")
        .btn
          span מחולל{{"\n"}}תח"י
        .btn
          span מחולל{{"\n"}}תל"א
  .edvise-container.content
    .approve
      | מערכת גפ"ן תוכנית מס' 7689
    img.logo-fixed-size(src="@/assets/img/biglogo.png", alt="edvise")
    div.center-content()
      .button-container
        button.button(@click="beginIdmLogin()") התחברות
        //button.button(@click="$router.push('/normal-login')") התחברות
        //#[br]למערכת
      .text
        .header חליפה אישית לצרכי התלמיד
        | #[i Edvise] ממפה את יכולות התלמיד
        br
        |ומייצרת עבורו תוכנית התערבות אישית
        // a(href="javascript:void(0)", @click="$refs.fullpage.api.moveTo('2')") בננה
      .button-container
        button.button(@click="$router.push('/purchase/schools')") מסלולי רכישה
      .button-container(v-if="scrWidth < 768")
        a.button(href="/qna") שאלות ותשובות
      .button-container(v-if="scrWidth < 768")
        a.button(href="#contactus") צור קשר
    div
      iframe#videoframe(src="https://www.youtube.com/embed/4K4TN6ydVNA",
        frameborder="0", allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture",
        allowfullscreen="allowfullscreen")
</template>

<script>
import devConfig from "@/config/dev-config";
import logger from "@/services/logger";
import generalService from "@/services/general-service";

export default {
  name: "main-section",
  data() {
    return {
      userData: this.$store.getters.getState,
      mockUserId: null,
      popUpErrorMsg: false,
      token: this.$store.getters.getToken,
      userType: null,
      mockIdmUsers: null,
      devConfig,
      scrWidth: window.innerWidth,
    };
  },
  computed: {
    getToken() {
      return this.$store.getters.getToken;
    },
    devMode() {
      return this.$store.getters.getDevMode;
    },
    getUserType: function () {
      let isTeacher = this.$store.getters.isTeacher;
      if (isTeacher) {
        return 'Teacher';
      } else {
        return 'Student';
      }
    }
  },
  created() {
    this.getMockIdmUsers();
    this.startEmailLogin();
  },
  mounted() {
    if (this.devMode) {
      // Dev Mode
      logger.debug('DEV MODE');

      if (this.isAutoLoginMode()) {
        // Automatic login will be executed now
      }
    } else {
      // Prod Mode
      logger.debug('PROD MODE');
    }

    this.userType = this.getUserType;

  },
  methods: {
    async beginIdmLogin(popupBlocked = false) {
      // If in PWA mode, then "window.matchMedia('(display-mode: standalone)').matches" will be true
      if (
          (this.devConfig.forceIdmLoginInPopup ||
              window.matchMedia('(display-mode: standalone)').matches) &&
          !popupBlocked
      ) {
        logger.debug('popup mode');
        const explicitRedirectUri = `${location.protocol}//${
            location.host
        }/login-popup-callback.html`;
        let result = await generalService.startOpenIdSession(
            explicitRedirectUri
        );
        if (!result.success) {
          logger.error('Could not connect to EDU identity management');
          // TODO: Show some visible error message
          return;
        }

        // TODO: Put this window in the center
        let loginPopupHandler = window.open(
            result.url,
            'loginPopup',
            'width=400,height=500'
        );

        // In case where popup was blocked - performing login in the same window
        // TODO: Wait a few seconds to allow popup unblock?
        if (!loginPopupHandler) {
          this.beginIdmLogin(true);
        }

        // Receving the login code from the child window and redirecting to idmcallback.
        // The 'login-popup-callback.html' is actually a route parameter, which will be passed to the server,
        // as an explicit redirect uri.
        window.addEventListener('message', evt => {
          if (evt.data && evt.data.type === 'finalizeLogin') {
            let redirectionQueryParams = evt.data.redirectionUrl.split('?')[1];
            window.location.href = `${location.protocol}//${
                location.host
            }/idmcallback/login-popup-callback.html?${redirectionQueryParams}`;
          }
        });
      } else {
        logger.debug('same window mode');

        const state = {
          clientRedirectUri: this.$route.query.redirectUri,
          studentTz: this.$route.query.studentTz,
          studentObjId: this.$route.query.studentObjId
        };

        // In this case the regular redirect URL from the IDM client settings on the server will be used
        let result = await generalService.startOpenIdSession(null, state);
        if (!result.success) {
          logger.error('Could not connect to EDU identity management');
          // TODO: Show some visible error message
          return;
        }
        window.location.href = result.url;
      }
    },
    async getLoginPromise(userData) {
      // console.log('userData:', userData);
      let data = await generalService.performMockLogin(userData);
      logger.debug(data);
      if (data.success) {
        if (data.token) {
          // Token returned
          await this.$store.dispatch('setLoginData', data);
          if (data.userType === 'T') {
            if (data.showTos && !this.devConfig.skipTos && !data.isSysAdmin) {
              this.$router.push('/tos');
            } else {
              this.$router.push('/teacher');
            }
          } else if (data.userType === 'S') {
            this.$router.push('/student');
          }
        } else {
          // No token back from server
          this.$store.dispatch('cleanState');
        }
      } else {
        alert(data.errorCode);
      }
    },
    startEmailLogin() {
      if (this.$route.query.fromEmail) {
        this.beginIdmLogin();
      }
    },
    getState() {
      return this.$store.getters.getState;
    },
    // isDevMode() {
    //   if (this.devConfig.currentMode === this.devConfig.options.devMode) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // },
    isAutoLoginMode() {
      if (this.devConfig.automaticLogin) {
        return true;
      } else {
        return false;
      }
    },
    getMockIdmUsers: async function () {
      let response = await generalService.getMockIdmJSON();
      if (response) {
        this.mockIdmUsers = response.idmMockUsers;
        // logger.debug(response);
      } else {
        logger.error('Error on getMockIdmUsers request.');
      }
    }
  }
}
</script>

<style scoped lang="scss">
.sidebar {
  width: 30%;
  min-width: 300px;
  height: 100%;
  background-color: #545871;
  color: #f6e4cc;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  -webkit-box-align: center;
  padding-top: 20px;
  .profile {
    padding: 0 5px;
    .profile-text{
      margin-right: 100px;
      border-right: 3px solid;
      text-align: right;
      padding: 10px 7px;
      white-space: pre-line;
    }
  }
  .soon{
    width: 100%;
    .header{
      padding: .35rem .7rem;
      //background: #f6e4cc;
      color: #ffe788;
      //border-radius: 8px;
      font-size: 2rem;
      font-weight: bold;
      width: fit-content;
      margin: auto;
    }
    .buttons{
      margin: .7rem 1rem 0 1rem;
      display: flex;
      justify-content: space-evenly;
      .btn{
        //border-radius: 8px;
        //border: 2px solid;
        //padding: 12px 10px;
        width: 45%;
        color: #545871;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 2rem;
        //display: inline-block;
        aspect-ratio: 1/1;
        background-image: url("~@/assets/img/soon.png");
        background-size: contain;
        span{
          white-space: pre-line;
        }
      }
    }
  }
}

.content{
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  display: flex;
  padding-right: 30px;
  width: -webkit-fill-available;
  position: relative;
  .approve{
    font-size: 1.4rem;
    font-weight: bolder;
    color: #860d0d;
    position: absolute;
    top: 5px;
    right: 10px;
  }
  img{
    max-height: 10rem;
  }
  .center-content{
    font-size: 2.14rem;
    line-height: 1.15;
    text-align: center;
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    .text{
      color: #860d0d;
      .header{
        font-size: 3rem;
        font-weight: bold;
        padding-bottom: 17px;
      }
    }
    .button{
      margin-top: 50px;
      background-color: #48cfae;
      padding: 10px 20px;
      border-radius: 8px;
      font-size: 1.6rem;
      font-weight: bold;
      height: 80px;
      display: flex;
      align-items: center;

    }
  }
  #videoframe{
    aspect-ratio: 16/9;
    height: 14rem;
  }
}
@media only screen and (max-width: 768px){
  .content{
    padding-right: 0;
    img{
      margin-top:35px;
      margin-bottom: 20px;
    }
    .center-content{
      margin-bottom: 20px;
    }
    .center-content{
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .text{
        margin-bottom: 10px;
      }
      .button-container:nth-child(1){
        order: 2;
      }
      .text{
        .header{
          font-size: 2.5rem;
        }
      }
      .button-container>.button{
        margin-top: 5px;
        //padding: 10px 20px;
        height: auto;
        color: inherit;
        text-decoration: none;
        width: 150px;
        text-align: center;
        display: block;
      }
      #videoframe{
        width: 100%;
        height: auto;
      }
    }
  }
}
</style>