module.exports = {
	"behavior": {
		"1": "להושיב בקרבת הלוח והמורה",
		"2": "לאפשר הפסקות להתאווררות",
		"3": "לגייס להקשבה רציפה בעזרת חיזוקים",
		"4": "בזמן מתן הוראות מילוליות יש לשים לב שקשוב/ה ושומע/ת",
		"7": "יצירת קשר עין, קריאה בשם ע\"מ לחזק הקשבה רציפה",
		"8": "לאפשר עיסוק בחפץ לא מסיח",
		"24": "לכוון לכתיבה במחברת חוויות אישית",
		"25": "לחזק חשיבה לפני דיבור ועשיה",
		"26": "לחזק תהליכים והשקעה לאו דווקא תוצאות",
		"28": "הסעות- בסוף היום לדאוג לצוות לתלמיד מהכיתה",
		"29": "תשומת לב לצורך לצאת לשירותים. לשאול האם יש צורך לצאת",
		"49": "לעזור להמליל רגשות ובקשות",
		"67": "מתן עידוד רב למסוגלות לעבודה בקצב מהיר יותר כאשר נתקלים בקצב איטי לפרגן על מה שנעשה",
		"68": "לאפשר זמן לחשיבה במטרה לעודד עבודה איכותית",
		"134": "לאחר ששואל/ת שאלות יש לענות ע\"י שאלה חוזרת (\"צריך לענות על הדף?\" = \"מה אמרתי שצריך לעשות?\")",
		"153": "להרגיע ע\"י הסבר בעיקר לפני שינויים ומעברים",
		"154": "יש צורך בליווי במעברים בין שיעורים ופעילויות",
		"155": "ליזום מתן תשומת לב אישית- מחמאה, חיבוק על הבוקר",
		"156": "ליצור קשר אישי חם, קשר של אמון ואהבה",
		"157": "מביע קושי ע\"י עייפות בשיעור. לעקוב ולהתעניין במצב",
		"158": "במידה ונפגע/ת לאפשר להירגע (שטיפת פנים) ולדאוג להתייחס לפגיעה בזמן אחר",
		"159": "תשומת לב שנעים וטוב לו/לה בביה\"ס",
		"160": "להתקשר אליו/ה ולהתעניין כשמחסיר/ה מביה\"ס",
		"161": "בבוקר יש לאפשר זמן להתאקלמות בכיתה",
		"162": "בתקופה הקרובה יש צורך ביחס חם ומחבק, חיזוק להגעה לביה\"ס בכל יום, התייחסות אישית והפחתה למינימום של עומס לימודי",
		"163": "להרגיע!!! מילים וטון מרגיע, שהכל בסדר!",
		"164": "להשתדל מאד לא להגיע למאבקי כוח. להעלים עין כשניתן (לא על חשבון שאר התלמידים)",
		"199": "יש להקפיד על גבולות ברורים מאד. להשתדל מאד לא להגיע למאבקי כוח.",
		"200": "כללים וגבולות ברורים",
		"201": "להתבטא במשפטים ותנועות גוף חד משמעיות, סמכות עליונה ללא עוררין",
		"202": "לא להוציא מהכיתה! לשלוח למנהל, סגנית, יועצת",
		"203": "לגייס עשיה ומוטיבציה ללמידה רצינית כשהמחיר בצידו (השתתפות בפעילות וכו)",
		"204": "דרישה לעשיה כמו כל הכתה ללא הנחות: הוצאת ציוד, ביצוע משימות, כתיבה ביומן.",
		"205": "לגשת במהלך השיעור לוודא עשיה – ללא דיון אלא ממקום של דרישה ברורה",
		"206": "לדרוש ביצוע משימות",
		"207": "מעקב אחר הגעה רציפה לביה\"ס ולשיעורים",
		"208": "הקפדה על הגעה בזמן לשיעור ",
		"209": "מעקב אחר נוכחות בשיעור ובביה\"ס",
		"t": "התנהגותי"
	},
	"classWork-personal-functioning": {
		"74": "לזרז לעבודה בקצב ממוצע",
		"75": "מידי פעם להפחית משימות כדי לאפשר סיום בין הראשונים",
		"195": "עידוד להשתתפות במשימות בהן התלמיד שולט",
		"196": "מידי פעם להתעניין אם מסתדר/ת ולהציע עזרה",
		"197": "במשימות בהן יש קושי זקוק/ה לתמיכה רגשית, עידוד וגיוס",
		"198": "תשומת לב להצבעה לבקשת עזרה במהלך שיעור או מבחן",
		"210": " לזמן פעילות מובנית עם תלמידים שבפוטנציאל יכול להיווצר קשר",
		"211": " תשומת לב לצורך בתיווך לשיתוף פעולה מיטבי",
		"212": " לאפשר עבודה עצמאית",
		"213": "במידה ומתערב/ת ללא צורך יש לשקף ולהדריך אות/ה",
		"214": "הגדרת תפקיד ברור בלמידה בזוג/ קבוצה",
		"215": "יצירת הזדמנויות ללמידה בזוג/ קבוצה",
		"216": " להושיב ליד תלמידים המעודדים למידה",
		"t": "עבודת כיתה - תפקוד אישיותי"
	},
	"empowerment": {
		"177": "מצטיין/ת במקצוע זה!! להעצים ולאתגר כמה שניתן",
		"178": "חיזוקים רבים על השקעה, רצינות ואחריות",
		"179": "לשקף במה טוב/ה ומצליח/ה כדי להרגיע ולחזק בטחון עצמי",
		"180": "מאוד שמח/ה כשמשבחים ומחזקים אותה על דברים חיוביים שעושה",
		"181": "לדאוג למצוא הזדמנויות לתת במה להמחזה",
		"182": "שמח/ה להעניק ולתת- לחלק תפקידים, אחריות",
		"183": "חיזוקים רבים! זקוק/ה לחיזוק שאהוב על כל הצוות",
		"184": "לזמן חוויות הצלחה ולחזק על כך",
		"185": "חיזוקים רבים על מאמץ והשקעה",
		"186": "חיזוקים חיוביים ופרסים ממריצים אות/ה",
		"187": "מתן תשומת לב אישית בכל בוקר (מחמאות, חוויות וכו)",
		"188": "חיזוק מוטיבציה והשקעה",
		"189": "העצמת מידות טובות והתנהגות נעימה",
		"190": "חיזוקים והעצמת הכוח האישי לאו דווקא בתחום הלימודי",
		"191": "להעצים את תחומי האחריות והבגרות האישית",
		"192": "לחזק שיתוף בידע עולם אישי",
		"193": "להקפיד לשים לב להתנהגות טובה לחזק ולשבח מאד",
		"194": "מתן חיזוקים ממוקדים ומדויקים",
		"t": "העצמה"
	},
	"organization": {
		"27": "לשים לב שלא מפספס/ת הודעות ותאריכי מבחנים",
		"36": "לשבח ולחזק יכולת התארגנות עם הציוד בזמן",
		"37": "להתקרב ולפנות באופן אישי בבקשה להוצאת ציוד ועבודה בכיתה",
		"38": "לדאוג לשולחן ריק מחפצים",
		"39": "עזרה בהתארגנות: ציוד על השולחן, ישיבה מאורגנת, פתיחת חומר לימודי",
		"40": "מעקב אחר הבאת ציוד. מתן עזרה ותיווך לשיפור המצב",
		"41": "תשומת לב להתארגנות בסביבה",
		"42": "מעקב אחר הכנסת הציוד לתיק (מחברות, חוברות עבודה, ספרים)",
		"43": "לתווך ולעקוב אחר התארגנות עם הציוד בתחילה ובסוף שיעור",
		"44": "הקפדה על ישיבה מסודרת ליד השולחן",
		"45": "תשומת לב להופעה חיצונית- איסוף השיער, בגדים",
		"46": "לפרט סדר יום וזמנים",
		"47": "עזרה באיסוף וארגון חומרי הלמידה",
		"48": "לעקוב ולדאוג להדבקת הדף במחברת",
		"236": "יצירת תומכים להתארגנות עם ציוד (מערכת שעות, רשימת מקצועות והציוד המדרש וכ')",
		"237": "חיזוקים להטמעת הסדר בציוד ( טבלאות מעקב, חיזוקים מילוליים וכו')",
		"t": "התארגנות"
	},
	"parentSharing": {
		"135": "ליצור קשר טוב עם ההורים של שיתוף פעולה חיובי",
		"136": "שמירה על קשר עם ההורים (שיחות, ס.מ.ס, מחברת קשר)",
		"137": "גיוס ההורים לשיתוף פעולה מסייע מאוד",
		"138": "עדכון קבוע של ההורים (ס.מ.ס, שיחה) על אי הכנת ש.ב או משימות בכיתה",
		"t": "שיתוף הורים"
	},
	"classWork-academic-functioning": {
		"87": "לאפשר קריאה דמומה",
		"88": "הקראת הטקסט והמשימות",
		"89": "תשומת לב לאיכות הקריאה, במידת הצורך יש להקריא",
		"90": "תשומת לב מרובה ליכולת הקריאה, נעזר/ת בהקראה",
		"91": "הקראת משימות ארוכות",
		"92": "תוספת זמן לקריאה עצמית",
		"93": "לחזור על הוראות כתובות בע\"פ כדי לאפשר התמקדות בעשיית המשימות",
		"94": "לאפשר קריאה עצמית בקול נמוך",
		"95": "קריאה קולית בעזרת בן זוג",
		"99": "עריכת סיכומי ביניים וסיכום סופי לנושא הנלמד",
		"101": "מיקוד בהקניית פעולות מתמטיות",
		"102": "תיווך להבנת המשימות",
		"103": "הפחתת משימות והתמקדות בעיקר",
		"104": "תשומת לב לקושי בהבנה ומתן תיווך לפני שמפסיק/ה להתמודד",
		"105": "להתמקד במשימות ברמה הראשונית כדי לחזק הבנה",
		"106": "תיווך להבנת משימות מורכבות",
		"107": "כשהתשובות לא נכונות לכוון לקריאה נוספת ולתיקון. לבחור מעט משימות כדי לא לייאש.",
		"108": "הכוונה במציאת מראה מקום",
		"109": "תיווך לארגון תשובה",
		"110": "לוודא תשובות נכונות",
		"111": "בתחילת המשימה לתווך ולאפשר התמודדות עצמית",
		"112": "תשומת לב להבנת משפטים מורכבים והוראות מורכבות",
		"113": "קושי בהתמודדות עם משימה חדשה, יש צורך בזמן נוסף להבנה",
		"114": "הפחתת משימות במידת הצורך",
		"115": "שאילת שאלות מכוונות לפני ותוך כדי קריאה",
		"116": "לאפשר השתתפות ע\"י גיוון בעבודה- להצביע, למתוח קו, משחק",
		"117": "לאפשר משימות חלופיות כגון: ציור, מרכז למידה וכו",
		"118": "תשומת לב שלא נתקע/ת בביצוע משימות",
		"125": "להפחית משימות כתיבה, הכתבה והעתקה. לצלם או לעזור להעתיק",
		"126": "כתב קטן מאוד מאוד. לא להתייחס לכך יותר מידי",
		"127": "לעודד התחלת כתיבה. לאחר זמן להציע עזרה",
		"128": "לשים לב לכתיבה נכונה תוך הקפדה על ארגון ורווחים",
		"129": "מעתיק/ה וכותב/ת בהתאם לזמן הניתן. אם לא מסיימ/ת לא להעיר על כך",
		"130": "הקניית שורשים ומשפחות מילים",
		"131": "תיווך להעתקה נכונה מהלוח",
		"132": "תשומת לב לצורך בתיווך בזמן גזירה, הדבקה וצביעה",
		"133": "לא להעיר על שגיאות כתיב",
		"228": "מיקוד לביצוע משימות פתוחות",
		"229": "הקניית כלים להתמודדות עם שאלות סגורות",
		"230": "מתן אפשרות למענה מתומצת של הנקודות המרכזיות",
		"233": "שימוש במדגש לאיתור והדגשת פרטים רלוונטיים בטקסט",
		"234": "תוך כדי ההקראה לכתוב על הלוח פרטים משמעותיים מהטקסט",
		"235": " הצמדת מחוון לאיסוף פרטים משמעותיים תוך כדי האזנה לטקסט",
		"t": "עבודת כיתה - תפקודים אקדמיים"
	},
	"classWork-learning-process": {
		"9": "בתחילת כל שיעור למקד לנושא השיעור והמיקום בספר הנלמד",
		"10": "חלוקת משימות למקטעים",
		"11": "קושי בהתמודדות עם משימה חדשה, יש צורך בזמן נוסף להבנה",
		"12": "תשומת לב להתחלת משימה עם כל הכיתה",
		"13": "תוספת זמן לתרגול והתנסות ע\"מ להגיע להישגים טובים",
		"14": "יש צורך בהסבר הוראות באופן אישי- למיקוד ותשומת לב",
		"15": "כדי להקל על ההתמצאות יש להצמיד סימניות בספרים/ חוברות/ יומן",
		"16": "יש לגוון בסגנונות הלמידה (יצירה, צביעה, מחשב)",
		"17": "בזמן עבודת כיתה לשים לב לעשייה, לעודד ללמידה בשמחה וברוגע",
		"18": "תשומת לב מרובה להקשבה ולביצוע מטלות",
		"19": "הקפדה על הפעלת בקרה בסוף משימה",
		"20": "לגייס לעבודה איכותית ולחזק על איכות ביצוע המשימה",
		"21": "לאפשר ציור, משחק בסיום ביצוע משימות מוגדרות",
		"22": "בזמן הקניה תשומת לב מרובה לרמת הריכוז ולמעקב רצוף אחר הלמידה",
		"23": "להגדיר מראש משימה לביצוע (את תקראי את הפסקה השניה, את/ה תענה על שאלה השלישית וכו)",
		"52": "בהשתתפות בע\"פ יש להיעזר בשאלות סגורות שהתשובה עליהן כן/ לא",
		"53": "לחזק השתתפות בע\"פ",
		"54": "יש לחזק השתתפות בע\"פ כשיודע/ת ושולט/ת בנלמד",
		"55": "מתן אפשרות למענה בע\"פ",
		"56": "המורה יתחיל את התשובה והתלמיד ימשיך לבד",
		"57": "תשומת לב להבנת משמעות ופירושי מילים",
		"58": "לפנות בעדינות בשאלות ממוקדות וסגורות- תשובות של כן/ לא.",
		"59": "מיקוד לביצוע משימות סגורות (הקפה בעיגול, מתיחת קו וכו')",
		"60": " במילה חדשה ולא מוכרת יש לחזור עליה ולהסביר אותה מספר פעמים בשיעור",
		"61": "לאתר ולהקנות מילים מרכזיות בנושא הנלמד",
		"62": "הגדלת הכתב לטקסטים הנלמדים",
		"63": "שימוש בדפי עבודה מובנים ומסודרים",
		"64": "שימוש בכלי ארגון חזותיים- טבלה, תרשים וכו",
		"65": "על הלוח- כתיבת עיקרי משימות, כותרות ומהלכים",
		"66": "בעבודת כיתה לבודד את השאלות ע\"י דף חלק.",
		"80": "שימוש בהקשרים ותומכים סיפוריים",
		"81": "ניתן להיעזר במספר חזרות כדי לרכוש ידע חדש",
		"82": "שינון משפר מאוד את השליטה בנלמד",
		"83": "שאלות מוגדרות על מידע מצומצם",
		"84": "שימוש בתומכי זיכרון וכרטיסי ניווט",
		"100": "הצמדת תומכי זיכרון לאותיות ולאותיות ניקוד",
		"119": "הפסקות ומעברים ברורים בין נושאים ומקצועות שונים",
		"120": "מיקוד למשימות סגורות- הקפה בעיגול, מתיחת קו וכו",
		"121": "הדגשת קשרים בין פרטים, הבנת התהליך עד להסקת מסקנות",
		"122": "לכתוב על הלוח מילים חדשות וחשובות",
		"148": "חיזוק עבודה עצמאית",
		"149": "ביצוע עצמאי של משימה אחת מוגדרת ורק לאחר מכן פניה לבקשת עזרה מהמורה",
		"150": "לכוון להתמודדות עצמית- לקרוא שוב, לנסות לבד. לעזור להתמקד מה לא הבין/ה (לא לקבל אמירות כוללות \"לא הבנתי כלום\")",
		"151": "לחזק עבודה עצמאית כדי לעקוב אחר היכולות",
		"152": "הפחתת משימות במטרה לעודד ולחזק עבודה עצמאית",
		"231": "תיווך לבחירת דרכים וכלים יעילים להתמודדות עם הנדרש במשימה",
		"t": "עבודת כיתה - תהליכי למידה"
	},
	"tests": {
		"5": "תשומת לב לאיכות התשובות במשימות ובמבחנים",
		"6": "תשומת לב לעבודה בזמן המבחן",
		"30": "לעדכן הורים לקראת המבחן בהודעת ס.מ.ס",
		"31": "תשומת לב ללמידה לקראת מבחן",
		"32": "עזרה בארגון ותכנון הזמן ללמידה לקראת מבחן",
		"33": "מתן תזכורת ללמידה לקראת מבחן",
		"34": "לעדכן הורים על אופן הבחינה- הפחתת חומר, מבחן סגור",
		"35": "לעדכן את הסייעת על מבחן מתקרב",
		"50": "מבחן סגור (אמריקאי, תרמילון, מתיחת קווים)",
		"51": "לשלב מספר שאלות סגורות",
		"69": "הפחתת חומר/ מס' שאלות",
		"70": "שכתוב תוך כדי המבחן",
		"71": "במידה ולא מסיים/ת את המבחן בזמן, יש לשכתב את התשובות",
		"72": "מתחיל/ה לעבוד לבד, כשמתעייף/ת ניתן לשכתב או לאפשר מענה בע\"פ",
		"73": "לאפשר להשלים את המבחן בבית",
		"76": "יש לאפשר שימוש במחשבון",
		"77": "יש לאפשר שימוש במילונית",
		"78": "הערכה חלופית",
		"79": "היעזרות בחומר פתוח",
		"85": "תשומת לב לקריאה נכונה",
		"86": "הקראת השאלות",
		"89": "תשומת לב לאיכות הקריאה, במידת הצורך יש להקריא",
		"96": "תשומת לב להבנת כל חלקי ההוראה",
		"97": "לעזור בארגון החומר ובהתמקדות בעיקר",
		"98": "דף חזרה מובנה בכלי ארגון חזותיים",
		"123": "לוודא שהמורה מבין/ה את התשובות הכתובות",
		"124": "תוספת זמן מוגדרת",
		"165": "מתן בחירה מרובה במבחן",
		"166": "לדאוג לציונים גבוהים – להעלות ציון על השקעה והתמדה.",
		"167": "עדכון המחנך/ת בציון הסופי (יחסית לרמת הכיתה)",
		"168": "לחזק מאוד על התגייסות ללמידה לקראת מבחן",
		"169": "להדגיש ולשקף את הקשר בין ההשקעה להצלחה",
		"170": "מתן ציון מתוך המשימות שענה עליהן בזמן המבחן",
		"171": "חיזוקים מילוליים על הצלחות במבחנים",
		"172": "לזמן הצלחה כדי לחזק על ההתגייסות ללמידה",
		"173": "לדאוג לחווית הצלחה במבחנים",
		"174": "מיקוד מספר משימות חובה ואח\"כ לאפשר להמשיך לפי היכולת",
		"175": "התחלת המבחן עם כולם בכיתה ולאפשר לסיים בבית",
		"176": "הרגעה וחיזוק תחושת המסוגלות",
		"232": "הפחתת חומר",
		"t": "מבחנים"
	},
	"homework": {
		"139": "תשומת לב ליכולת הכנת שיעורי בית",
		"140": "הגדרת משך זמן הכנת שיעורי בית",
		"141": "הפחתת משימות למינימום",
		"142": "לבחור משימות עיקריות מתוך החומר שלא הספיק/ה בכתה ולסמן אותם בבירור כשיעורי בית.",
		"143": "לגוון בשיעורי בית מאתגרים",
		"144": "חיזוקים מילוליים על הכנת שיעורי בית",
		"145": "מעקב אחר כתיבת שיעורי בית ביומן",
		"146": "הפחתת משימות ועבודות",
		"147": "מיקוד למספר משימות חובה בהתאם ליכולת התלמיד",
		"t": "שיעורי בית"
	},
	"upToDate": {
		"217": "השפעה על יכולת האיזון ושיווי המשקל ושמיעת הוראות במרחב הפתוח",
		"218": "יש להושיב כשהאוזן התקינה פונה למורה",
		"219": "בזמן דיבור יש להקפיד על שפתיים חשופות במלואן",
		"220": "כשנעזרים במצגת יש לעמוד ליד המסך כדי שהשפתיים יהיו מוארות",
		"221": "תשומת לב להרכבת משקפיים בזמן השיעור",
		"222": "לאחר זמן ישנו קושי במיקוד הראיה וקיים קושי משמעותי ביכולת הקריאה",
		"t": "עדכון"
	},
	"neurological": {
		"223": "עידוד והסבר להורים לביצוע המלצות הנוירולוג",
		"224": "התייעצות חוזרת עם הנוירולוג להתאמת מינון וסוג הטיפול",
		"t": "נוירולוגי"
	},
	"hearing": {
		"225": "עידוד והסבר להורים לביצוע ההמלצות",
		"t": "שמיעה"
	},
	"eyeSight": {
		"226": "עידוד והסבר להורים לביצוע ההמלצות",
		"t": "ראיה"
	},
	"focusSight": {
		"227": "עידוד והסבר להורים לביצוע ההמלצות",
		"t": "מיקוד ראיה"
	},
	"referrals": {
		"322": "בדיקת ראיה+ מיקוד ראיה",
		"323": "בדיקת שמיעה",
		"324": "הפניה לבדיקת עיבוד שמיעתי",
		"325": "הפניה להוראה מתקנת",
		"326": "הפניה לקלינאית תקשורת",
		"327": "הפניה לריפוי בעיסוק",
		"328": "ההפניה לפסיכולוג לבחינת הצורך בטיפול רגשי",
		"329": "הפניה לאבחון נוירולוגי",
		"330": "הפניה להדרכת הורים",
		"331": "הפניה למורת שילוב",
		"332": "התייעצות עם פסיכולוגית בי\"ס",
		"333": "הפניה לתרפיה- כישורים חברתיים",
		"t": "הפניות"
	}
}