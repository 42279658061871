<template>
  <div class="dialog">
    <v-layout>
      <v-dialog
        v-model="dialog"
        persistent
        :max-width="maxWidth"
      >
        <v-card>
          <!-- <div v-if="title.length >= 1">
            <span 
              style="padding: 1em; direction: rtl;text-align: right;"
            > {{ title[0] }} </span>
            <br>
            <span
              style="padding: 1em; direction: rtl;text-align: right;"
            > {{ title[1] }} </span>
          </div>
          <div v-else> -->
          <h2
            style="padding: 1em; direction: rtl;"
          > {{ title }} </h2>
          <!-- </div> -->
          <v-card-text >
            <div class="content" v-html="content"></div>
          </v-card-text>
  
          <v-card-actions>
  
            <button
              flat="flat"
              @click="decline"
            >
              {{ cancel }}
            </button>

            <button
              flat="flat"
              @click="agree"
            >
              {{ confirm }}
            </button>
  
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </div>
</template>

<script>
export default {
  name: 'Dialog',
  // props: ['title', 'content', 'confirm', 'cancel', 'maxWidth'],
  data() {
    return {
      dialog: false,
      title: '',
      content: '',
      confirm: '',
      cancel: '',
      maxWidth: '',
      resolve: null,
      reject: null
    };
  },
  methods: {
    open(title, content, confirm, cancel, maxWidth) {
      this.dialog = true;
      this.title = title || '';
      this.content = content || '';
      this.maxWidth = maxWidth || '20em';
      this.confirm = confirm || this.$t('confirm') || 'confirm';
      this.cancel = cancel || this.$t('decline') || 'declie';

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree() {
      this.resolve(true);
      this.dialog = false;
    },
    decline() {
      this.resolve(false);
      this.dialog = false;
    }
  }
};
</script>

<style lang="scss">
@import '@/assets/scss/style.scss';

.theme--light.v-card {
  padding: 20px;
  background-color: $color-peach !important;
}

.content{
  text-align: right;
}

.v-dialog {
  border-radius: 9px !important;
  max-width: 30em;
  font-size: 1.2rem;
}

.dialog {
  font-family: 'OpenSansHebrew-regular';
  /* direction: rtl; */
  text-align: right;

  .v-dialog__content.v-dialog__content--active {
    background-color: rgba(0, 0, 0, 0.3);
  }

  .v-card__text {
    padding-right: 2em;
  }
}

.v-card__actions {
  display: flex;
  justify-content: center;

  button {
    width: 117px;
    height: 44px;
    margin: 0 10px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    font-family: $font-main;
    font-size: 16px;
    background-color: $color-blue;
    color: #fff;
    border-radius: 45px;
    display: inline-block;
    padding: 10px 30px 10px 30px;
    cursor: pointer;

    &:nth-child(1) {
      background-color: transparent;
      color: $color-blue;
      border-radius: 30px;
      border: 1px solid $color-blue;
    }
  }

  button:hover {
    background-color: $color-lightblue;
  }
}
</style>
