<template lang="pug">
.nav-bar(style="z-index: 5; position: relative;")
  //position: sticky;
  .toggle-screen(ref='toggleScreen')
  .flex.align-center.flex-row-reverse
    .nav-mobile(v-if="!$store.getters['teacher/isSysAdmin']" ref='navMobile')
      .bar1
      .bar2
      .bar3
    .flex-row-reverse.flex.align-center.ul-wrapper
      h1
        img.app-logo(v-if="$store.getters['teacher/isSysAdmin']" src='../assets/img/logo-desktop.png')
        router-link(v-if="!$store.getters['teacher/isSysAdmin']" to='/teacher/search-student')
          img.app-logo(src='../assets/img/logo-desktop.png', :class="{hide: mobile && $route.path === '/'}")
      ul.nav-desktop.flex.inline-list(v-if="$route.meta.fullpageapi")
        //li
          a(@click='purchaseMenu=!purchaseMenu', :class="{active: $route.path.startsWith('/purchase')}")  מסלולי רכישה
          div
            ul.dropdown-menu(:class="{show: purchaseMenu}")
              li
                .link(@click="()=>{$router.push('/purchase/schools'); purchaseMenu=false}") בתי ספר
              li
                .link(@click="()=>{$router.push('/purchase/parents'); purchaseMenu=false}") הורים
              li.last
                .link(@click="()=>{$router.push('/purchase/others'); purchaseMenu=false}") מתיאות, רשויות, מכונים פרטיים
        li
          a(@click='$router.push("/qna")',
            :class="{active: $route.path === '/qna'}")  שאלות ותשובות
        .separator
        li
          a(@click='goTo("contactus")',
            :class="{active: $route.path === '/' && currentPage === 'contactus'}")  צור קשר
        li
          a(@click='goTo("products")',
            :class="{active: ($route.path === '/' && currentPage === 'products') || $route.path.startsWith('/products')}")  מוצרים
        li
          a(@click='goTo("reviews")',
            :class="{active: $route.path === '/' && currentPage === 'reviews'}")  חווית משתמשים
        li
          a(@click='goTo("about")', :class="{active: $route.path === '/' && currentPage === 'about'}")  אודות
        li
          a(@click='goTo("")', :class="{active: $route.path==='/' && currentPage === ''}")  בית
      ul.nav-desktop.flex.inline-list(v-if="!$store.getters['teacher/isSysAdmin']" v-show="!$route.meta.fullpageapi", ref='navDesktop' arial-label='Main navigation bar')
        li(v-if='toDisplay.teacherLink')
          router-link(to='/teacher/qna')  {{ $t('navbar.qna') }}
        li(v-if='toDisplay.contactLink')
          router-link(to='/contact')  {{ $t('navbar.contact') }}
        li(v-if='toDisplay.teacherLink')
          router-link.knowledge-base(to='/teacher/knowledge-base')  {{ $t('navbar.knowledgeBase') }}
        li(v-if='toDisplay.teacherLink')
          router-link.student-link(to='/teacher/new-student')  {{ $t('navbar.newStudent') }}
        li(v-if='toDisplay.teacherLink')
          router-link.student-link(to='/teacher/search-student')  {{ $t('navbar.searchStudent') }}
        li(v-if='devMode')
          router-link(to='/teacher/new-student/edit-profile-form?section=2')  new profile-form
        li(v-if='devMode')
          router-link(to='/teacher/search-student/view-profile-form?section=2')  view profile-form
      li.logout-btn-container(v-if='isDevEnv')
        button(@click="$store.commit('setDevMode')")  toggle DevMode
      li.logout-btn-container(v-if='toDisplay.logoutLink && devMode')
        button(@click='logoutIdm')  Logout
      ul.nav-desktop.flex.inline-list(v-if="$store.getters['teacher/isSysAdmin']")
        li
          router-link.knowledge-base(to='/admin/schools-management')  {{ $t('navbar.schools-management') }}
        li
          router-link.knowledge-base(to='/admin/register-teacher')  {{ $t('navbar.register-teacher') }}
        li
          router-link.knowledge-base(v-if="$store.getters['teacher/getSelectedStudent']" to='/admin/view-profile-form?section=0')  {{ $t('navbar.studntProfileForm') }}
        li.logout-btn-container(v-if='isDevEnv')
          button(@click="$store.commit('setDevMode')")  toggle DevMode
</template>

<script>
import generalService from '../services/general-service.js';
import devConfig from '@/config/dev-config';

export default {
  name: 'NavBar',
  // toDisplay: { item to display (links in nav, list, etc..): boolean }
  props: {
    toDisplay: {
      type: Object,
      required: true
    }
  }, // all links must be included in toDisplay in every iteretion of the navbar!!
  data() {
    return {
      navBarMobile: false,
      schools: [],
      activeSchoolName: null,
      currentPage: null,
      purchaseMenu: false,
      productMenu: false,
      mobile: window.innerWidth < 768,
    };
  },
  computed: {
    isDevEnv() {
      return process.env.NODE_ENV === 'development';
    },
    devMode() {
      return this.$store.getters.getDevMode;
    },
    fullName() {
      return this.$store.getters.getFullName;
    },
    getActiveSchool() {
      return this.$store.getters['teacher/getActiveSchool'];
    },
    isTeacher() {
      return this.$store.getters.isTeacher;
    }
  },
  mounted() {
    window.EventsBus.$on('updateRoute', (page)=>{
      this.currentPage = page
    });
  },
  watch: {
    getActiveSchool() {
      this.getActiveSchoolName();
    },
    '$route.path': {
      handler: function(page) {
        if(this.$route.path === '/'){
          this.currentPage = location.hash.replace('#','')
        }else{
          this.currentPage = null
        }
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    this.getScools();
    this.getActiveSchoolName();
  },
  methods: {
    goTo(page){
      if(this.$route.path === '/'){
        EventsBus.$emit('moveFullpage', page);
      }else{
        this.$router.push('/#'+page)
      }
      this.currentPage = page;
    },
    getScools() {
      this.schools = this.$store.getters['teacher/getSchoolList'];
    },
    getActiveSchoolName() {
      let activeSchool = this.$store.getters['teacher/getActiveSchool'];
      if (activeSchool) {
        this.activeSchoolName = activeSchool.schoolName;
      }
    },

    toggleNavMobile() {
      this.$refs.navMobile.classList.toggle('change');
      this.$refs.navDesktop.classList.toggle('open');
      this.$refs.toggleScreen.classList.toggle('show-div');
    },
    toggleDropDown() {
      this.$refs.dropBtn.classList.toggle('drop-block');
    },
    async logoutIdm() {
      await generalService.logoutIdm();
      this.$router.push('/');
    },
    navBarSchoolSwitch: async function(school) {
      let token = this.$store.getters.getToken;

      if (school) {
        // Selection is valid, go on
        let data = { token: token, semelMosad: school.semelMosad };
        let newToken = await this.$store.dispatch('teacher/switchSchool', data);
        if (newToken) {
          this.$store.commit('setToken', newToken);
          this.toggleDropDown();
          this.$router.push('/teacher/');
        }
      } else {
        // Selection is invalid. warn user
      }
    }
  }
};
</script>

<style lang="scss" scoped>
li {
  display: block;
  transition-duration: 1s;
}
ul li div{
  //position: absolute;
  //width: 85px;
  direction: rtl;
  ul{
    background-color: #f6e4cc;
    border-radius: 4px;
    padding: 0 5px !important;
    border: 1px solid;
    margin-top: 5px !important;
    //padding-left: 40px;
    li{
      padding: 5px !important;
      padding-left: 40px !important;
      .link{
        cursor: pointer;
      }
    }
    li:not(.last){
      border-bottom: 1px solid;
    }
    li.last{
      padding-left: 5px !important;
    }
  }
}
ul li div ul {
  visibility: hidden;
  opacity: 0;
  position: fixed;
  transition: all 0.5s ease;
  margin-top: 1rem;
  display: none;
}
//ul li:hover > div > ul,

.show,
ul li div ul:hover {
  visibility: visible;
  opacity: 1;
  display: block;
}

ul li div ul li {
  clear: both;
  width: 100%;
  padding: 5px;
}
.separator{
  background-color: #50546e;
  width: 2px;
  margin: 0 5px;
  height: calc( 1rem + 10px + 10px );
}
</style>

<style scoped lang="scss">
@import '@/assets/scss/style.scss';

.ul-wrapper {
  max-height: 56px;
  height: 56px;
  width: 100%;
}

.drop-block {
  display: block !important;
}

.full-name {
  float: left;
  // color: white;
  padding-left: 14px;
}

.dropdown {
  overflow: hidden;
  display: none;
}

.dropdown .dropbtn {
  width: 180px;
  text-align: left;
  font-size: 17px;
  border: none;
  outline: none;
  // color: white;
  padding: 14px 16px;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
  cursor: pointer;
}

.dropdown-content {
  background-color: #e03939;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 0;

  background-color: #222222;
  display: none; // hide until menu is clicked
  list-style: none; // replace with %styled-list
  margin: 0; // replace with %styled-list
  padding: 0; // replace with %styled-list
  position: absolute;
  width: 100%;
  max-width: 220px;
}

.dropdown-content a {
  float: none;
  // color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.nav-bar {
  background-color: #fff;
  // padding: 5px;
  color: $color-blue;
  height: 56px;
  overflow: hidden;
  font-family: $font-main;
  .nav-desktop{
    align-items: center;
  }
  .app-logo {
    height: 39px;
  }

  a {
    // color: #333;
    text-decoration: none;
    color: $color-blue;
  }

  h1 {
    text-decoration: none;
    margin: 0 10px;
    // font-size: 1.4em;
    font-weight: bold;
    font-family: 'openSans', 'Geneva', 'Tahoma', 'sans-serif';
  }

  ul {
    margin: 0px;
    padding-left: 5px;
    li {
      padding: 0 3px;
      font-size: 14px;
      //height: -webkit-fill-available;
      display: flex;
      align-items: flex-end;

      a {
        text-decoration: none;
        padding: 10px 20px;
        border-radius: 8px
      ;
        background-color: #f6e4cc;
        //padding-bottom: 10px;
      }

      button {
        font-family: 'Roboto', 'OpenSans', 'Geneva', 'Tahoma', 'sans-serif';
        font-weight: bold;
        border-color: #fff;

        &:hover {
          background-color: rgba(0, 0, 0, 0.5);
        }
      }
    }
  }
}
.nav-mobile {
  display: none;
}
.toggle-screen {
  display: none;
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  background: rgba(42, 42, 42, 0.9);
  z-index: 0;
  opacity: 0.9;
  visibility: hidden;
  cursor: pointer;
  transition: opacity 0.5s;
}
@media (max-width: 768px) {
  .nav-bar {
    background-color: $color-lightpeach;
  }

  .ul-wrapper {
    flex-direction: row;
  }

  .dropdown {
    display: inherit;
  }

  .dropdown-content {
    width: 100vw;
    max-width: 100%;
    right: 0;
    //  max-width=
  }

  .app-logo {
    //display: none;
    //content: url('../assets/img/logo-mobile-header.png');
  }
  .hide{
    display: none;
  }

  .show-div {
    z-index: 1;
    visibility: visible;
    opacity: 1;
    display: block;
  }
  .nav-bar ul.nav-desktop {
    margin: 0;
    z-index: 1;
    padding-top: 20px;
    flex-direction: column;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    background: rgba(84, 88, 113, 0.9);
    border-left: 1px solid rgba(100, 100, 100, 0.5);
    min-width: 270px;
    transform: translate(100%, 0);
    transition: all 0.8s;

    &.open {
      transform: translate(0, 0);
    }

    li {
      justify-content: flex-end;
      align-items: center;
      font-size: 22px;

      &.logout-btn-container {
        display: none;
      }

      a {
        color: $color-lightpeach;
        // border-bottom: 2px solid black;
        // text-decoration: dotted;
        display: block;
        // line-height: 60px;
        text-align: right;
      }
    }

    li a:hover {
      // color: #999;
    }
  }

  /* nav-mobile button */

  .nav-mobile {
    z-index: 3;
    cursor: pointer;
    display: block;
    float: right;
    padding: 10px;
  }

  .bar1,
  .bar2,
  .bar3 {
    width: 35px;
    height: 5px;
    background-color: $color-blue;
    margin: 6px 0;
    transition: 0.4s;
  }

  //&.change .bar1,
  //&.change .bar2,
  //&.change .bar3 {
  //  background-color: $color-lightpeach;
  //}

  /* Rotate first bar */
  .change .bar1 {
    -webkit-transform: rotate(-45deg) translate(-9px, 6px);
    transform: rotate(-45deg) translate(-9px, 6px);
  }

  /* Fade out the second bar */
  .change .bar2 {
    opacity: 0;
  }

  /* Rotate last bar */
  .change .bar3 {
    -webkit-transform: rotate(45deg) translate(-8px, -8px);
    transform: rotate(45deg) translate(-8px, -8px);
  }
}

@media (min-width: 768px) {
  ul li a.active,
  ul li .router-link-exact-active:not(.cancel-router-link-active),
  ul li .router-link-active.student-link:not(.cancel-router-link-active),
  ul li .router-link-active.knowledge-base:not(.cancel-router-link-active) {
    //height: 46px;
    //background-color: $color-lightpeach;
    ///* display: flex; */
    //padding-top: 15px;
    //border-radius: 15px 15px 0 0;
    border: 2px solid;
  }

}
</style>
