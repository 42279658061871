<template lang="pug">
  .section-container
    contactus-sidebar
    .content
      .contact.flex.justify-center
        .container(v-if="formSent")
          .map-title תודה רבה
          .map-text פנייתך נשלחה בהצלחה.
        .contact-container(v-else)
          .edvise-page-title צור קשר
          form.form-content(ref="form")
            .input-container.placeholder
              div
                label.edvise-input-label.contact-label(for="name") שם מלא
              input#name.edvise-input.contact-input(v-model="formData.lastName", type="text", placeholder="שם מלא", required)
            .input-container
              div
                label.edvise-input-label.contact-label(for="phone") מספר טלפון
              input#phone.edvise-input.contact-input(v-model="formData.phone", type="tel", placeholder="מספר טלפון", required)
            .input-container
              div
                label.edvise-input-label.contact-label(for="email") כתובת דוא"ל
              input#email.edvise-input.contact-input(v-model="formData.email", type="email", required)
            .input-container
              .textarea-container
                div
                  label.edvise-input-label.contact-label(for="message") הודעה
                textarea#message.edvise-input.contact-input(v-model="formData.message", maxlength="5000", required)
            .send-btn
              button.search-btn.round-btn-blue(type='submit', @click.prevent="sendMail")  שלח


</template>

<script>
import ContactusSidebar from "@/components/ContactusSidebar";
import generalService from "@/services/general-service";
import Notifications from "@/services/notifications";

export default {
  name: "contactus-section",
  components: {'contactus-sidebar': ContactusSidebar},
  data: ()=>({
    formSent: false,
    formData: {}
  }),
  methods: {
    async sendMail() {
      if(!this.$refs.form.checkValidity()){
        this.$refs.form.reportValidity()
        return
      }
      let formattedMailData = {
        email: 'דוא"ל: '+this.formData.email,
        phone: 'טלפון: '+this.formData.phone,
        name: 'שם השולח: '+this.formData.name,
        content: 'הודעה: '+this.formData.message
      };
      try{
        let response = await generalService.sendContactUsMail(formattedMailData);
        if (response.success) {
          this.formSent = true;
        } else {
          Notifications.notify('error', 'קרתה שגיאה בעת שליחת המייל', 'אנא נסו שנית מאוחר יותר או צרו קשר טלפונית', 8000);
        }
      }catch (e) {
        Notifications.notify('error', 'קרתה שגיאה בעת שליחת המייל', 'אנא נסו שנית מאוחר יותר או צרו קשר טלפונית', 8000);
      }
    },
  }
}
</script>

<style scoped lang="scss">
@media (max-width: 768px) {
  .content{
    margin-bottom: 40px;
  }
}

.content{
  direction: rtl;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: calc(100vh - 56px);
  width: 100%;
  .contact{
    align-items: center;
  }
  .contact-container{
    width: 50%;
    align-items: center;
    .input-container{
      width: 100%;
      text-align: right;
      padding-top: 10px;
      input, textarea{
        width: 100%;
      }
      textarea{
        height: 9rem;
        padding: 5px 10px;
      }
    }
    .send-btn{
      padding-top: 10px;
      button{
        float: left;
        height: 42px;
        width: 120px;
      }
    }
  }
}
</style>