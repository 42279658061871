module.exports = {
	"IsRestless": {
		"HARD": "DOWN",
		"ATA": "UP",
		"IDK": "DOWN"
	},
	"IsDayDream": {
		"HARD": "DOWN",
		"ATA": "UP",
		"IDK": "DOWN"
	},
	"IsImpulsive": {
		"HARD": "DOWN",
		"ATA": "UP",
		"IDK": "NULL"
	},
	"IsSlowToAct": {
		"HARD": "DOWN",
		"ATA": "UP",
		"IDK": "NULL"
	},
	"IsAssociative": {
		"HARD": "DOWN",
		"ATA": "UP",
		"IDK": "NULL"
	},
	"IsVentilation": {
		"HARD": "DOWN",
		"ATA": "UP",
		"IDK": "NULL"
	},
	"IsInfoFlood": {
		"HARD": "DOWN",
		"ATA": "UP",
		"IDK": "NULL"
	},
	"IsActiveWorkingMemory": {
		"NO": "DOWN",
		"YES": "UP",
		"IDK": "NULL"
	},
	"IsNeuroDiagnosed": {
		"NO": "NULL",
		"IDK": "NULL",
		"YES_CARED": "UP",
		"YES_UNCARED": "DOWN"
	},
	"ByHeartParticipation": {
		"HARD": "DOWN",
		"ATA": "UP",
		"STG": "UP"
	},
	"Vocabulary": {
		"HARD": "DOWN",
		"ATA": "UP",
		"STG": "UP"
	},
	"WordRecall": {
		"HARD": "DOWN",
		"ATA": "UP",
		"STG": "UP"
	},
	"AutoQuickNaming": {
		"NO": "DOWN",
		"YES": "UP",
		"IDK": "NULL"
	},
	"PhonologicStream": {
		"NO": "DOWN",
		"YES": "UP",
		"IDK": "NULL"
	},
	"SemanticStream": {
		"NO": "DOWN",
		"YES": "UP",
		"IDK": "NULL"
	},
	"ContextualMemoryImmediate": {
		"NO": "DOWN",
		"YES": "UP",
		"IDK": "NULL"
	},
	"ContextualMemoryPostponed": {
		"NO": "DOWN",
		"YES": "UP",
		"IDK": "NULL"
	},
	"SingletonMemoryImmediate": {
		"NO": "DOWN",
		"YES": "UP",
		"IDK": "NULL"
	},
	"SingletonMemoryPostponed": {
		"NO": "DOWN",
		"YES": "UP",
		"IDK": "NULL"
	},
	"MemoryIdentification": {
		"NO": "DOWN",
		"YES": "UP",
		"IDK": "NULL"
	},
	"MemoryLearnRepetitionCurve": {
		"NO": "DOWN",
		"YES": "UP",
		"IDK": "NULL"
	},
	"RetroProActiveMemory": {
		"NO": "UP",
		"YES": "DOWN",
		"IDK": "NULL"
	},
	"VisualPerformanceNoMotor": {
		"NO": "DOWN",
		"YES": "UP",
		"IDK": "NULL"
	},
	"VisioMotoricIntegration": {
		"NO": "DOWN",
		"YES": "UP",
		"IDK": "NULL"
	},
	"VisualIdentifyGen": {
		"NO": "DOWN",
		"YES": "UP",
		"IDK": "NULL"
	},
	"VisualIdentifyPostponed": {
		"NO": "DOWN",
		"YES": "UP",
		"IDK": "NULL"
	},
	"VisualIdentifyImmediate": {
		"NO": "DOWN",
		"YES": "UP",
		"IDK": "NULL"
	},
	"GestaltPerception": {
		"NO": "DOWN",
		"YES": "UP",
		"IDK": "NULL"
	},
	"DetailsPerception": {
		"NO": "DOWN",
		"YES": "UP",
		"IDK": "NULL"
	},
	"AbstractNonVerbalThinking": {
		"NO": "DOWN",
		"YES": "UP",
		"IDK": "NULL"
	},
	"NonAbstractVerbalThinking": {
		"YES": "UP",
		"NO": "DOWN"
	},
	"IsGeneralIntl": {
		"ABN_LOW": "DOWN",
		"IDK": "NULL",
		"AVG_HIGH_ABV": "UP"
	},
	"IsVerbalCrystal": {
		"ABN_LOW": "DOWN",
		"IDK": "NULL",
		"AVG_HIGH_ABV": "UP"
	},
	"IsDoerFluid": {
		"ABN_LOW": "DOWN",
		"IDK": "NULL",
		"AVG_HIGH_ABV": "UP"
	},
	"IsPhonologicAwarness": {
		"IDK": "NULL",
		"NO": "DOWN",
		"YES": "UP"
	},
	"AudibleTextPace": {
		"NO": "DOWN",
		"YES": "UP"
	},
	"IsTextReadAccurate": {
		"NO": "DOWN",
		"YES": "UP"
	},
	"VowelsAndConstants": {
		"IDK": "NULL",
		"NO": "DOWN",
		"YES": "UP"
	},
	"Nonword": {
		"NO": "DOWN",
		"YES": "UP"
	},
	"MeaningfulWords": {
		"NO": "DOWN",
		"YES": "UP"
	},
	"WritingTemplate": {
		"IDK": "NULL",
		"NO": "DOWN",
		"YES": "UP"
	},
	"SilentTextPace": {
		"NO": "DOWN",
		"YES": "UP"
	},
	"ListeningCompInformativeTextHigh": {
		"IDK": "NULL",
		"NO": "DOWN",
		"YES": "UP"
	},
	"ListeningCompInformativeTextLow": {
		"IDK": "NULL",
		"NO": "DOWN",
		"YES": "UP"
	},
	"ListeningCompStoryTextHigh": {
		"IDK": "NULL",
		"NO": "DOWN",
		"YES": "UP"
	},
	"ListeningCompStoryTextLow": {
		"IDK": "NULL",
		"NO": "DOWN",
		"YES": "UP"
	},
	"HearingCompInformativeTextHigh": {
		"IDK": "NULL",
		"NO": "DOWN",
		"YES": "UP"
	},
	"HearingCompInformativeTextLow": {
		"IDK": "NULL",
		"NO": "DOWN",
		"YES": "UP"
	},
	"HearingCompStoryTextHigh": {
		"IDK": "NULL",
		"NO": "DOWN",
		"YES": "UP"
	},
	"HearingCompStoryTextLow": {
		"IDK": "NULL",
		"NO": "DOWN",
		"YES": "UP"
	},
	"IsPageOrganised": {
		"IDK": "NULL",
		"NO": "DOWN",
		"YES": "UP"
	},
	"IsHandWriteStyle": {
		"IDK": "NULL",
		"NO": "DOWN",
		"YES": "UP"
	},
	"IsPencilHold": {
		"IDK": "NULL",
		"NO": "DOWN",
		"YES": "UP"
	},
	"IsTireFactor": {
		"IDK": "NULL",
		"NO": "UP",
		"YES": "DOWN"
	},
	"IsCommonSpelling": {
		"IDK": "NULL",
		"NO": "UP",
		"YES": "DOWN"
	},
	"IsSevereSpelling": {
		"IDK": "NULL",
		"NO": "UP",
		"YES": "DOWN"
	},
	"IsMessageOrganised": {
		"IDK": "NULL",
		"NO": "DOWN",
		"YES": "UP"
	},
	"IsLinguisticHigh": {
		"IDK": "NULL",
		"NO": "DOWN",
		"YES": "UP"
	},
	"IsWritePace": {
		"IDK": "NULL",
		"NO": "DOWN",
		"YES": "UP"
	},
	"IsOpenQuestion": {
		"IDK": "NULL",
		"NO": "DOWN",
		"YES": "UP"
	},
	"IsClosedQuestion": {
		"IDK": "NULL",
		"NO": "DOWN",
		"YES": "UP"
	},
	"Cooperation": {
		"HARD": "DOWN",
		"ATA": "UP",
		"STG": "UP"
	},
	"PeerContact": {
		"HARD": "DOWN",
		"ATA": "UP",
		"STG": "UP"
	},
	"SocialEtiquette": {
		"HARD": "DOWN",
		"ATA": "UP",
		"STG": "UP"
	},
	"AdultsRelations": {
		"HARD": "DOWN",
		"ATA": "UP",
		"STG": "UP"
	},
	"ClassAttendance": {
		"HARD": "DOWN",
		"ATA": "UP",
		"STG": "UP",
		"IDK": "NULL"
	},
	"ClassParticipate": {
		"HARD": "DOWN",
		"ATA": "UP",
		"STG": "UP",
		"IDK": "NULL"
	},
	"ChangeCope": {
		"HARD": "DOWN",
		"ATA": "UP",
		"STG": "UP",
		"IDK": "NULL"
	},
	"Anxiety": {
		"HARD": "DOWN",
		"ATA": "UP",
		"STG": "UP",
		"IDK": "NULL"
	},
	"FailureCope": {
		"HARD": "DOWN",
		"ATA": "UP",
		"STG": "UP",
		"IDK": "NULL"
	},
	"FrustrationThreshold": {
		"HARD": "DOWN",
		"ATA": "UP",
		"STG": "UP",
		"IDK": "NULL"
	},
	"SelfConfident": {
		"HARD": "DOWN",
		"ATA": "UP",
		"STG": "UP"
	},
	"LearningMotivated": {
		"HARD": "DOWN",
		"ATA": "UP",
		"STG": "UP",
		"IDK": "NULL"
	},
	"ClassEquipment": {
		"HARD": "DOWN",
		"ATA": "UP",
		"STG": "UP",
		"IDK": "NULL"
	},
	"EnvironmentOrganize": {
		"HARD": "DOWN",
		"ATA": "UP",
		"STG": "UP"
	},
	"TaskOrganize": {
		"HARD": "DOWN",
		"ATA": "UP",
		"STG": "UP",
		"IDK": "NULL"
	},
	"AfterHoursLearn": {
		"NO": "DOWN",
		"YES": "UP"
	},
	"ClassWorkIndependant": {
		"HARD": "DOWN",
		"ATA": "UP",
		"STG": "UP",
		"IDK": "NULL"
	},
	"Achievements": {
		"HARD": "DOWN",
		"ATA": "UP",
		"STG": "UP",
		"IDK": "NULL"
	}
}