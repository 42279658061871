<template lang="pug">
.student-card
  .student-card-title(v-if="config.NAV.advisor") {{ $t('theStudent') }}
  .student-tz-cotainer
    span.edit-btn.round-btn-transparent(v-if="!config.NAV.advisor", @click="navigateToEditStudent") {{ $t('editBtn') }}
      img(src="@/assets/img/glyphicons/edit-white.png")
    span.student-name {{ studentName() }}
  .student-school-class(style="line-height: initial;")
    span.student-tz {{ studentTz() }}
    br
    span {{ schoolName() }}
      | ,&nbsp;
    span {{ schoolClass() }}
  .student-school-class
    span(v-if="$store.getters['teacher/isSysAdmin']", :title="$t('studentCode')") {{ studentObjId() }}
</template>

<script>
import semelMosadList from '@/config/semel-mosad.json5';
import classGradesJson from '@/locales/class-grade.json';

export default {
  props: {
    config: {
      type: Object,
      required: true
    }
  },
  methods: {
    schoolClass() {
      if (!this.$store.getters['teacher/getSelectedStudent']) {
        return '';
      }
      let { classGrade, classNumber } = this.$store.getters[
        'teacher/getSelectedStudent'
      ].schoolClass;
      return classGrade + "' " + classNumber;
    },
    schoolName() {
      if (!this.$store.getters['teacher/getSelectedStudent']) {
        return '';
      }
      let { schoolClass } = this.$store.getters['teacher/getSelectedStudent'];
      if (schoolClass) {
        return (
          schoolClass.schoolName ||
          this.findSchoolName(schoolClass.semelMosad) ||
          schoolClass.semelMosad
        );
      }
    },
    findSchoolName(semelMosad) {
      const school = semelMosadList.find(
        schoolObj => schoolObj.semelMosad == semelMosad
      );
      if (school) {
        return school.schoolName;
      }
      return false;
    },
    studentName(){
      const student = this.$store.getters['teacher/getSelectedStudent'];
      return `${student.lastName} ${student.firstName}`
    },
    studentTz() {
      return this.$store.getters['teacher/getSearchedTz'];
    },
    studentObjId() {
      return this.$store.getters['teacher/getSelectedStudent']._id;
    },
    navigateToEditStudent() {
      const studentDetails = this.$store.getters['teacher/getSelectedStudent'];
      const classGrade = Number(
        classGradesJson.find(
          classGradeObj =>
            classGradeObj.letter === studentDetails.schoolClass.classGrade
        ).number
      );
      this.$store.commit('teacher/setEditStudent', {
        semelMosad: studentDetails.schoolClass.semelMosad,
        classNumber: studentDetails.schoolClass.classNumber,
        classGrade,
        firstName: studentDetails.firstName,
        lastName: studentDetails.lastName,
        studentObjId: studentDetails._id
      });
      this.$router.push('/teacher/search-student/edit-student');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/style.scss';

.student-card {
  text-align: right;

  .edit-btn {
    float: left;
  }
}

.student-card-title {
  font-size: $font-size-footer;
  color: $color-lightblue;
  // line-height: 36px;
  padding-bottom: 2em;
}

.student-name {
  font-family: $font-main-bold;
  font-weight: bold;
}

.student-school-class,
.student-tz-cotainer {
  font-size: $font-size-txt;
  color: $color-white;
  line-height: $modularSideBarLineHeight;
}

.student-school-class {
  direction: rtl;
}
</style>
