import Vue from 'vue';
import Router from 'vue-router';
import Login from './views/Login.vue';
import MockLogin from './views/MockLogin.vue';
import TeacherView from './views/Teacher';
import LayoutWithSidebar from '@/components/LayoutWithSidebar';
import IdmCallback from './views/IdmCallback.vue';
import CollapsableTextItemsList from '@/views/CollapsableTextItemsList';
import devConfig from '@/config/dev-config';
import store from './store';
import About from "@/views/home/About";
import Products from "@/views/home/Products";
import Pricing from "@/views/home/Pricing";
import QNA from "@/views/home/QNA";
import Generator from "@/views/Generator";
import NormalLogin from "@/views/NormalLogin.vue";

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: NormalLogin,
    },
    {
      path: '/idmcallback',
      name: 'Idmcallback',
      component: IdmCallback
    },
    {
      path: '/normal-login',
      name: 'normal-login',
      component: NormalLogin
    },
    {
      path: '/register',
      name: 'RegisterTeacher',
      component: () =>
          import(/* webpackChunkName: 'ExcelUpload' */ './components/RegisterTeacher.vue')
    },
    {
      path: '/idmcallback/:declaredPathSuffixForIdm',
      // name: 'idmcallback',
      component: IdmCallback,
      props: true
    },
    // {
    //   path: '/about',
    //   name: 'about',
    //   // route level code-splitting
    //   // this generates a separate chunk (about.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () =>
    //     import(/* webpackChunkName: 'about' */ './views/About.vue')
    // },
    {
      path: '/tos',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: LayoutWithSidebar,
      children: [
        {
          path: '/',
          name: 'tos',
          component: () =>
            import(/* webpackChunkName: 'tos' */ './views/TermsOfService.vue')
        }
      ]
    },
    // {
    //   path: '/generator',
    //   component: LayoutWithSidebar,
    //   children: [
    //     {
    //       path: '/',
    //       name: 'generator',
    //       component: import(/* webpackChunkName: 'teacher' */ './views/Generator')
    //     }
    //   ]
    // },
    {
      path: '/contact',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: LayoutWithSidebar,
      children: [
        {
          path: '/',
          name: 'contact',
          component: () =>
            import(/* webpackChunkName: 'contact' */ './views/Contact.vue')
        }
      ]
    },
    {
      path: '/teacher',
      component: TeacherView,
      beforeEnter: (to, from, next) => {
        let token = store.getters.getToken;
        let isTeacher = store.getters.isTeacher;
        if (token && isTeacher) {
          if (store.getters['teacher/isSysAdmin']) {
            return next('/admin/view-profile-form?section=0');
          }
          if (!store.getters['teacher/getDidAcceptTos']){
            next('/tos')
          }
          next();
        } else {
          next('/');
        }
      },
      children: [
        {
          path: '/teacher/search-student',
          component: LayoutWithSidebar,
          children: [
            {
              path: '/',
              name: 'searchStudent',
              component: () =>
                import(/* webpackChunkName: 'teacher' */ './components/teacher/SearchStudent')
            },
            {
              path: '/teacher/search-student/edit-student/',
              component: () =>
                import(/* webpackChunkName: 'teacher' */ './components/teacher/RegisterStudent')
            },
            {
              path: '/teacher/search-student/view-profile-form/',
              component: () =>
                import(/* webpackChunkName: 'teacher' */ './components/profile-form/ProfileForm')
            },
            {
              path: '/teacher/search-student/edit-profile-form/',
              component: () =>
                import(/* webpackChunkName: 'teacher' */ './components/profile-form/ProfileForm')
            },
            {
              path: '/teacher/search-student/view-advice/',
              component: () =>
                import(/* webpackChunkName: 'teacher' */ './components/ViewAdvice.vue')
            },
            {
              path: '/teacher/search-student/generator',
              component: () =>
                  import(/* webpackChunkName: 'teacher' */ './views/Generator.vue')
            },
            {
              path: '/teacher/search-student/alt-advice/',
              component: () =>
                import(/* webpackChunkName: 'teacher' */ './components/ViewAltAdvice.vue')
            },
            {
              path: '/teacher/search-student/map/',
              component: () =>
                import(/* webpackChunkName: 'teacher' */ './components/Map.vue')
            },
            {
              path: '/teacher/search-student/knowledge-base',
              component: CollapsableTextItemsList
            }
          ]
        },
        {
          path: '/teacher/new-student',
          component: LayoutWithSidebar,
          children: [
            {
              path: '/',
              name: 'newStudent',
              component: () =>
                import(/* webpackChunkName: 'teacher' */ './components/teacher/RegisterStudent')
            },
            {
              path: '/teacher/new-student/edit-profile-form/',
              component: () =>
                import(/* webpackChunkName: 'teacher' */ './components/profile-form/ProfileForm')
            },
            {
              path: '/teacher/new-student/knowledge-base',
              component: CollapsableTextItemsList
            }
          ]
        },
        {
          path: '/',
          redirect: '/teacher/search-student'
        },
        {
          path: '/teacher/knowledge-base',
          component: LayoutWithSidebar,
          children: [
            {
              path: '/',
              name: 'knowledgeBase',
              component: CollapsableTextItemsList
            }
          ]
        },
        {
          path: '/teacher/qna',
          component: LayoutWithSidebar,
          children: [
            {
              path: '/',
              name: 'qna',
              component: CollapsableTextItemsList
            }
          ]
        }
      ]
    },
    {
      path: '/admin',
      component: LayoutWithSidebar,
      beforeEnter: (to, from, next) => {
        if (store.getters.getToken && store.getters['teacher/isSysAdmin']) {
          next();
        } else {
          next('/');
        }
      },
      children: [
        {
          path: '/admin/schools-management',
          name: 'SchoolsManagement',
          component: () => import('./components/admin/SchoolsManagement.vue')
        },
        {
          path: '/admin/register-teacher',
          name: 'RegisterTeacher',
          component: () =>
            import(/* webpackChunkName: 'ExcelUpload' */ './components/RegisterTeacher.vue')
        },
        {
          path: '/admin/view-profile-form/',
          beforeEnter: (to, from, next) => {
            if (!store.getters['teacher/getSelectedStudent']) {
              next('/admin/register-teacher');
            } else {
              next();
            }
          },
          component: () =>
            import(/* webpackChunkName: 'teacher' */ './components/profile-form/ProfileForm')
        },
        {
          path: '/admin/generator',
          component: () =>
              import(/* webpackChunkName: 'teacher' */ './views/Generator.vue')
        },
        {
          path: '/admin/view-advice/',
          component: () =>
            import(/* webpackChunkName: 'teacher' */ './components/ViewAdvice')
        },
        {
          path: '/admin/alt-advice/',
          component: () =>
            import(/* webpackChunkName: 'teacher' */ './components/ViewAltAdvice.vue')
        },
        {
          path: '/admin/edit-advice/',
          component: CollapsableTextItemsList
        },
        {
          path: '/admin/finish/',
          component: () =>
            import(/* webpackChunkName: 'teacher' */ './components/FinishSection')
        }
      ]
    },
    {
      path: '/map',
      name: 'map',
      component: () =>
        import(/* webpackChunkName: 'teacher' */ './components/Map')
    },
    {
      path: '*',
      name: '404',

      component: () =>
        import(/* webpackChunkName: 'student' */ './views/404.vue')
    }
  ]
});
