import httpService from './http-service';
import apiConfig from './app-api';
import stubs from '../util/stubs';
import store from '../store';
import logger from './logger';

export default {
  // This is temporary, until IDM is in place
  performMockLogin: async function(userData) {
    const response = await httpService.post(
      apiConfig.getMockLoginUrl(),
      { userData },
      {
        headers: { 'Content-Type': 'application/json' }
      }
    );
    if (response) {
      return response.data;
    } else {
      return response;
    }
  },
  openIdLogin: async function(callBackUrl, explicitRedirectUri = null) {
    logger.debug({ callBackUrl });
    const response = await httpService.post(
      apiConfig.getIdmLoginUrl(),
      explicitRedirectUri
        ? { redirectedUrl: callBackUrl, explicitRedirectUri }
        : { redirectedUrl: callBackUrl },
      { headers: { 'Content-Type': 'application/json' } }
    );
    if (response) {
      return response.data;
    } else {
      return response;
    }
  },
  normalLogin: async function(tz, password) {
    const response = await httpService.post(
        apiConfig.getNormalLoginUrl(),
        { tz, password },
        { headers: { 'Content-Type': 'application/json' } }
    );
    if (response) {
      return response.data;
    } else {
      return response;
    }
  },
  logoutIdm: async function() {
    // let logoutUrl = `${idmConfig.logoutURL}${window.location.origin}`;
    let logoutUrl = `${store.state.logoutUriPrefix}${window.location.origin}`;
    await store.dispatch('cleanState');
    window.location.href = logoutUrl;
  },
  startOpenIdSession: async function(
    explicitRedirectUri = null,
    stateDataToPreserve = null
  ) {
    let requestBody = {};
    if (explicitRedirectUri) {
      requestBody.explicitRedirectUri = explicitRedirectUri;
    }
    if (stateDataToPreserve) {
      requestBody.stateDataToPreserve = btoa(
        JSON.stringify(stateDataToPreserve)
      );
    }

    const response = await httpService.post(
      apiConfig.getStartOpenIdSessionUrl(),
      requestBody,
      { headers: { 'Content-Type': 'application/json' } }
    );
    logger.debug('response data from login: ');
    logger.debug(response.data);
    if (response) {
      return response.data;
    } else {
      return response;
    }
  },
  sendContactUsMail: async function(mailData) {
    const response = await httpService.post(
      apiConfig.getMockMailUrl(),
      mailData,
      {
        headers: { 'Content-Type': 'application/json' }
      }
    );
    if (response) {
      return response.data;
    } else {
      return response;
    }
  },
  sendTosConfirmation: async function(token) {
    const response = await httpService.post(
      apiConfig.getMockAcceptTosUrl(),
        {},
      {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token
        }
      }
    );
    if (response) {
      return response.data;
    } else {
      return response;
    }
  },
  getMockIdmJSON: async function() {
    const response = await httpService.get(apiConfig.getMockIdmJSON(), null, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    if (response) {
      return response.data;
    } else {
      return response;
    }
  }
};
