import { render, staticRenderFns } from "./NormalLogin.vue?vue&type=template&id=33286216&scoped=true&lang=pug"
import script from "./NormalLogin.vue?vue&type=script&lang=js"
export * from "./NormalLogin.vue?vue&type=script&lang=js"
import style0 from "./NormalLogin.vue?vue&type=style&index=0&id=33286216&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33286216",
  null
  
)

export default component.exports