import _ from 'lodash';
import studentService from '../services/student-service';
import logger from '@/services/logger.js';

const moduleName = 'student';
const setStudentName = `${moduleName}/setStudentName`;

const api = {
  mut: {
    setFullName: setStudentName
  },
  act: {
    setFullName: setStudentName
  }
};

export default {
  namespaced: true,
  state: {
    firstName: null,
    lastName: null,
    schoolName: null
  },
  mutations: {
    setStudentName(state, payload) {
      state.firstName = payload.firstName;
      state.lastName = payload.lastName;
    },
    setSchoolName(state, schoolName) {
      state.schoolName = schoolName;
    },
    setDataItems(state, dataItems) {
      logger.debug('Student item collected!');
    }
  },
  actions: {
    populateSessionData(context, payload) {
      context.commit('setStudentName', payload);
      context.commit('setSchoolName', payload.schoolName);
    },
    setDataItems: async function(context, token) {
      let response = await studentService.getStudentDummyData(token);
      if (response.data) {
        context.commit('setDataItems', response.data);
      }
    },
    cleanModuleState: function(context) {
      context.state.firstName = null;
      context.state.lastName = null;
      context.state.schoolName = null;
    }
  },
  api
};
